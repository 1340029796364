import React from "react";
import { Divider, IconButton } from "@mui/material";
import {
  Wrap,
  Container,
  LogoBox,
  ButtonWrap,
  FooterButton,
  IconWrap,
  RowWrap,
  InfoTypo,
  FooterDivider,
  NoticeWrap,
  WebBtnBox,
  MobileBtnBox,
} from "./styles";
import Image from "next/image";
import { Changsoft, Youtube, Naver, Instagram, HiddenAnchor } from "components";
import { useTranslation } from "next-i18next";
interface PropType {
  handleClick(val: string): void;
}
export const Footer = (props: PropType) => {
  const { handleClick } = props;
  const { t } = useTranslation("common");
  return (
    <Wrap container>
      <Container>
        <LogoBox>
          <Image
            src="/HomeImages/BuilderhubFooterLogo.png"
            alt="BuilderhubFooterLogo"
            width={216}
            height={34}
          />
        </LogoBox>
        <RowWrap>
          <NoticeWrap>
            <WebBtnBox>
              <FooterButton onClick={() => handleClick("notice")} disableRipple>
                공지사항
              </FooterButton>
              <FooterDivider orientation="vertical" variant="middle" flexItem />
              <FooterButton
                onClick={() => handleClick("inquiry")}
                disableRipple
              >
                1:1 온라인 문의
              </FooterButton>
            </WebBtnBox>
            <MobileBtnBox>
              <FooterButton onClick={() => handleClick("notice")} disableRipple>
                고객센터
              </FooterButton>
            </MobileBtnBox>
            <FooterDivider orientation="vertical" variant="middle" flexItem />
            <FooterButton
              disableRipple
              onClick={() => handleClick("notification")}
            >
              이용 전 안내사항
            </FooterButton>
          </NoticeWrap>
        </RowWrap>
        <RowWrap>
          <InfoTypo>
            {/* 상호명 : (주)창소프트아이앤아이 ㅣ 대표자명 : 김은석 */}
            {t("companyName")}
            <br />
            {/* 사업장소재지 : 서울특별시 강남구 테헤란로103길 8-7, 2~4F (삼성동) */}
            {t("companyAddr")}
            <br />
            {/* 사업자등록번호: 220-87-61198 ㅣ 전화번호: 02-563-1328 */}
            {t("companyRegist")}
          </InfoTypo>
          <HiddenAnchor href="http://chang-soft.com" target="_blank">
            창소프트아이앤아이
          </HiddenAnchor>
          <HiddenAnchor
            href="https://www.youtube.com/@builderhub"
            target="_blank"
          >
            창소프트아이앤아이 YouTube
          </HiddenAnchor>
          <HiddenAnchor
            href="https://www.instagram.com/builderhub_official/"
            target="_blank"
          >
            빌더허브 인스타그램
          </HiddenAnchor>
          <IconWrap>
            <IconButton onClick={() => handleClick("homepage")}>
              <Changsoft />
            </IconButton>
            <IconButton onClick={() => handleClick("instagram")}>
              <Instagram />
            </IconButton>
            <IconButton onClick={() => handleClick("naver")}>
              <Naver />
            </IconButton>
            <IconButton onClick={() => handleClick("youtube")}>
              <Youtube />
            </IconButton>
          </IconWrap>
        </RowWrap>
        <Divider sx={{ background: "#EBE7FD" }} />
        <RowWrap>
          <InfoTypo>{`ⓒ${new Date().getFullYear()} ChangSoft I&I. All Rights Reserved.`}</InfoTypo>
          <ButtonWrap>
            <FooterButton disableRipple onClick={() => handleClick("type1")}>
              {t("terms")}
            </FooterButton>
            <FooterDivider orientation="vertical" variant="middle" flexItem />
            <FooterButton disableRipple onClick={() => handleClick("type2")}>
              {t("privacy")}
            </FooterButton>
          </ButtonWrap>
        </RowWrap>
      </Container>
    </Wrap>
  );
};
