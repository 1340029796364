import moment from "moment";
import type {} from "iamport-typings";
import { getRuntimeVariable } from "@builderhub/env";

type Response = {
  error_code: number | null;
  error_msg: string | null;
  imp_uid: string;
  merchant_uid: string;
  pg_provider: string;
  pg_type: string;
  success: boolean;
};

export async function getIdentityAsync(
  redirectUrl = "/signup/identity",
): Promise<Response> {
  const merchant_uid = `bhplf-${moment().format("YYYYMMDDHHmmssSSS")}`;
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    IMP?.certification(
      {
        merchant_uid,
        m_redirect_url: `${getRuntimeVariable(
          "NEXT_PUBLIC_HOST_URL",
        )}${redirectUrl}`,
      },
      function (rsp: Response) {
        if (rsp.success) {
          return resolve(rsp);
        }
        return reject(rsp);
      },
    );
  });
}
