import ThirdPartyEmailPassword from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { getApiDomain } from "context";
import SessionWebJs from "supertokens-web-js/recipe/session";
import {
  AppInfoUserInput,
  SuperTokensConfig,
} from "supertokens-web-js/lib/build/types";
import { getRuntimeVariable } from "@builderhub/env";

const appInfo: AppInfoUserInput = {
  appName: "builderhub-auth",
  apiDomain: getApiDomain(),
  apiBasePath: "/auth",
};

const domain = getRuntimeVariable("NEXT_PUBLIC_DOMAIN");

export const getConfig = (): SuperTokensConfig => {
  return {
    appInfo,
    recipeList: [
      ThirdPartyEmailPassword.init(),
      SessionWebJs.init({
        tokenTransferMethod: "cookie",
        sessionTokenBackendDomain: domain,
        sessionTokenFrontendDomain: domain,
      }),
    ],
  };
};
