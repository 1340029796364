import { colors, theme } from "@builderhub/mui-theme";
import { Box, Typography, styled } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

export const HeaderRow = styled(TableRow)(() => ({
  background: "#FCFCFC",
  borderRadius: 8,
  th: {
    border: 0,
    fontSize: 14,
    color: colors.gray._60,
    letterSpacing: -0.3,
  },
}));
export const BodyRow = styled(TableRow)(() => ({
  borderBottom: `1px solid ${colors.gray._10}`,
  td: {
    border: 0,
    fontSize: 16,
    color: colors.gray._100,
    letterSpacing: -0.3,
  },
  ":hover": {
    cursor: "pointer",
  },
}));

export const CustomBody = styled(TableBody)(() => ({
  "tr:last-of-type": {
    border: 0,
  },
}));
export const EmptyBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "80px 16px",
  gap: 16,
  [theme.breakpoints.down("lg")]: {
    padding: "80px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "80px 20px",
  },
}));
export const EmptyTypo = styled(Typography)(() => ({
  fontSize: 14,
  color: colors.gray._60,
  letterSpacing: -0.3,
}));

export const Tag = styled(Box)(() => ({
  borderRadius: 4,
  padding: "0px 6px",
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: -0.3,
  width: "fit-content",
  height: 22,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ImportantBox = styled(Tag)(() => ({
  background: "#F77",
  color: colors.white,
  fontSize: 12,
  minWidth: 34,
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
  },
}));

export const NewBox = styled(Tag)(() => ({
  background: "#F5F3FE",
  color: colors.purple._60,
  fontSize: 12,
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
  },
}));
export const TableBox = styled(Box)(() => ({
  display: "flex",
  // justifyContent: "center"
}));
export const TitleBox = styled(Box)(() => ({
  width: "fit-content",
  maxWidth: 800,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  [theme.breakpoints.down("lg")]: {
    width: "55vw",
  },
}));
export const MobileDate = styled(Typography)(() => ({
  color: colors.gray._40,
  fontSize: 10,
  letterSpacing: -0.3,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
}));
