import * as React from "react";
import { SVGProps } from "react";

export const PersonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.52433 21H18.4756C20.186 21 21 20.514 21 19.4449C21 16.8985 17.5896 13.2149 12.0051 13.2149C6.41041 13.2149 3 16.8985 3 19.4449C3 20.514 3.81397 21 5.52433 21ZM5.04007 19.5324C4.77218 19.5324 4.65884 19.4644 4.65884 19.2603C4.65884 17.6663 7.2759 14.6825 12.0051 14.6825C16.7241 14.6825 19.3412 17.6663 19.3412 19.2603C19.3412 19.4644 19.2381 19.5324 18.9702 19.5324H5.04007ZM12.0051 12.0097C14.4574 12.0097 16.4562 9.94926 16.4562 7.44168C16.4562 4.95356 14.4677 3 12.0051 3C9.56325 3 7.55409 4.99244 7.55409 7.46113C7.56439 9.95896 9.55296 12.0097 12.0051 12.0097ZM12.0051 10.5421C10.5009 10.5421 9.21291 9.18142 9.21291 7.46113C9.21291 5.76998 10.4803 4.4676 12.0051 4.4676C13.5404 4.4676 14.7974 5.75054 14.7974 7.44168C14.7974 9.16197 13.5197 10.5421 12.0051 10.5421Z"
      fill="#A8A7AF"
    />
  </svg>
);
