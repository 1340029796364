export const MenuPaperArrow = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 0L17.6603 14.3182H0.339746L9 0Z" fill="white" />
  </svg>
);
