import React from "react";
import { useSnackbar } from "notistack";

export function useGetSnackbar() {
  const { enqueueSnackbar } = useSnackbar();

  const getSnackbar = React.useCallback(
    (errorString: string, variant: "success" | "warning" | "error") => {
      enqueueSnackbar(`${errorString}`, {
        variant: variant,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    },
    [enqueueSnackbar],
  );

  return { getSnackbar };
}
