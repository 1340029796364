import * as React from "react";

export const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      className="point"
      d="M11.9985 22C17.4809 22 22 17.4733 22 12C22 6.51988 17.474 2 11.9916 2C6.51918 2 2 6.51988 2 12C2 17.4733 6.52605 22 11.9985 22Z"
      fill="#D4D3D7"
    />
    <path
      d="M11.3606 17.2359C11.093 17.2359 10.8802 17.1261 10.6784 16.8565L7.90798 13.4815C7.78981 13.3192 7.71387 13.1394 7.71387 12.9557C7.71387 12.5953 7.99483 12.305 8.34296 12.305C8.57224 12.305 8.74594 12.3791 8.9376 12.6343L11.3293 15.6258L16.014 8.07635C16.1666 7.84575 16.3716 7.71204 16.5766 7.71204C16.9177 7.71204 17.2377 7.96514 17.2377 8.33014C17.2377 8.50031 17.1431 8.68406 17.0524 8.84388L12.0147 16.8565C11.848 17.1086 11.6251 17.2359 11.3606 17.2359Z"
      fill="white"
    />
  </svg>
);

export const SquareCheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.7778 2H4.22222C3 2 2 3 2 4.22222V19.7778C2 21 3 22 4.22222 22H19.7778C21 22 22 21 22 19.7778V4.22222C22 3 21 2 19.7778 2ZM10.5667 16.7667C10.1333 17.2 9.43333 17.2 9 16.7667L5.01111 12.7778C4.57778 12.3444 4.57778 11.6444 5.01111 11.2111C5.44444 10.7778 6.14444 10.7778 6.57778 11.2111L9.77778 14.4111L17.4222 6.76667C17.8556 6.33333 18.5556 6.33333 18.9889 6.76667C19.4222 7.2 19.4222 7.9 18.9889 8.33333L10.5667 16.7667Z"
      fill="#E9E9E9"
    />
  </svg>
);
