import { useRouter } from "next/router";
import { SubMain } from "./SubMain";
import { MainPresenter } from "./Main";
import { getRuntimeVariable } from "@builderhub/env";

export const Main = () => {
  const router = useRouter();
  const goCuration = () => {
    router.push(
      `${getRuntimeVariable("NEXT_PUBLIC_CURATION_URL")}/project/tester`,
    );
  };
  const goCustomer = () => {
    router.push(`${getRuntimeVariable("NEXT_PUBLIC_CUSTOMER_URL")}`);
  };
  const goChangSoft = () => window.open("http://chang-soft.com/");
  const goInstagram = () =>
    window.open("https://www.instagram.com/builderhub_official/");

  const goNaver = () => window.open("https://blog.naver.com/builderhub ");

  const goYoutube = () => window.open("https://www.youtube.com/@builderhub");

  return (
    <>
      <MainPresenter
        goCuration={goCuration}
        goCustomer={goCustomer}
        goChangSoft={goChangSoft}
        goInstagram={goInstagram}
        goNaver={goNaver}
        goYoutube={goYoutube}
      />
      <SubMain />
    </>
  );
};
