import { SVGProps } from "react";

export const InstagramColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_5514_3580)">
      <path
        d="M19.5169 8H12.483C10.0071 8 8 10.0071 8 12.483V19.5162C8 21.9922 10.0071 23.9992 12.483 23.9992H19.5162C21.9921 23.9992 23.9992 21.9922 23.9992 19.5162V12.483C23.9992 10.0071 21.9921 8 19.5162 8H19.5169ZM22.5925 19.4334C22.5925 21.1712 21.1836 22.5794 19.4465 22.5794H12.5789C10.8411 22.5794 9.43294 21.1705 9.43294 19.4334V12.5666C9.43294 10.8288 10.8419 9.42057 12.5789 9.42057H19.4458C21.1836 9.42057 22.5917 10.8295 22.5917 12.5666V19.4334H22.5925Z"
        fill="url(#paint0_linear_5514_3580)"
      />
      <path
        d="M15.999 11.8965C13.7211 11.8965 11.875 13.7426 11.875 16.0205C11.875 18.2983 13.7211 20.1445 15.999 20.1445C18.2768 20.1445 20.1229 18.2983 20.1229 16.0205C20.1229 13.7426 18.2768 11.8965 15.999 11.8965ZM15.999 18.6914C14.5235 18.6914 13.3281 17.4952 13.3281 16.0205C13.3281 14.5457 14.5242 13.3496 15.999 13.3496C17.4737 13.3496 18.6699 14.5457 18.6699 16.0205C18.6699 17.4952 17.4737 18.6914 15.999 18.6914Z"
        fill="url(#paint1_linear_5514_3580)"
      />
      <path
        d="M20.4929 12.6512C21.0103 12.5516 21.3489 12.0514 21.2493 11.534C21.1497 11.0166 20.6495 10.678 20.1321 10.7776C19.6147 10.8772 19.2761 11.3774 19.3757 11.8948C19.4753 12.4122 19.9755 12.7508 20.4929 12.6512Z"
        fill="url(#paint2_linear_5514_3580)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5514_3580"
        x="-4"
        y="-4"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5514_3580"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5514_3580"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_5514_3580"
        x1="8.5"
        y1="8.5"
        x2="22.0001"
        y2="23.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7638F9" />
        <stop offset="0.25" stopColor="#D200C4" />
        <stop offset="0.49" stopColor="#FF0069" />
        <stop offset="0.75" stopColor="#FF7A00" />
        <stop offset="1" stopColor="#FFD500" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5514_3580"
        x1="9.5"
        y1="9.5"
        x2="22.5001"
        y2="23.4999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7638F9" />
        <stop offset="0.25" stopColor="#D200C4" />
        <stop offset="0.49" stopColor="#FF0069" />
        <stop offset="0.75" stopColor="#FF7A00" />
        <stop offset="1" stopColor="#FFD500" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5514_3580"
        x1="13.8766"
        y1="4.60071"
        x2="27.1804"
        y2="19.305"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7638F9" />
        <stop offset="0.25" stopColor="#D200C4" />
        <stop offset="0.49" stopColor="#FF0069" />
        <stop offset="0.75" stopColor="#FF7A00" />
        <stop offset="1" stopColor="#FFD500" />
      </linearGradient>
    </defs>
  </svg>
);

export const NaverColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_5514_3576)">
      <path
        d="M17.225 8V15.0642L12.7921 8H8V22H12.7757V14.9365L17.2093 22H22V8H17.225Z"
        fill="#38DF00"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5514_3576"
        x="-5"
        y="-5"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5514_3576"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5514_3576"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export const YoutubeColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="28"
    viewBox="0 0 35 28"
    fill="none"
    {...props}
  >
    <g filter="url(#filter0_d_5514_3407)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2142 8C9.71521 8 8.5 9.21521 8.5 10.7142V17.2858C8.5 18.7848 9.7152 20 11.2142 20H24.1078C25.6069 20 26.8221 18.7848 26.8221 17.2858V10.7142C26.8221 9.21521 25.6069 8 24.1078 8H11.2142ZM20.0945 14.4481C20.4654 14.2645 20.4654 13.7355 20.0945 13.5519L16.4718 11.7591C16.1394 11.5946 15.75 11.8364 15.75 12.2072V15.7928C15.75 16.1636 16.1394 16.4054 16.4718 16.2409L20.0945 14.4481Z"
        fill="#F04645"
      />
      <path
        className="point"
        d="M20.0945 13.5519C20.4654 13.7355 20.4654 14.2645 20.0945 14.4481L16.4718 16.2409C16.1394 16.4054 15.75 16.1636 15.75 15.7928L15.75 12.2072C15.75 11.8364 16.1394 11.5946 16.4718 11.7591L20.0945 13.5519Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5514_3407"
        x="-2"
        y="-6"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5514_3407"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5514_3407"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const ChangSoftColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="35"
    height="38"
    viewBox="0 0 35 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_5628_3756)">
      <path
        d="M22.5007 23.4165H19.3828V8L22.5007 11.0837V23.4165Z"
        fill="#0E59C4"
      />
      <path
        d="M15.2344 23.4171H18.3523V10.2744L15.2344 13.3577V23.4171Z"
        fill="#0931A9"
      />
      <path
        d="M26.5007 23.4156H23.3828V14.1602L26.5007 17.2439V23.4156Z"
        fill="#4F8DDC"
      />
      <path
        d="M11.3198 23.4094V17.7905L8.5 16.1982V25.0202L16.1623 29.3983V26.1762L11.3198 23.4094Z"
        fill="#0931A9"
      />
      <path
        d="M13.96 15.5141V12.292L9.13281 15.1136L11.9611 16.7106L13.96 15.5141Z"
        fill="#4F8DDC"
      />
      <path
        d="M25.5444 24.7285H20.0515L17.4258 26.2288V29.4455L25.5444 24.7285Z"
        fill="#0E59C4"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5628_3756"
        x="0.5"
        y="0"
        width="34"
        height="37.4453"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5628_3756"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5628_3756"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const SnsDivider = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width="2"
    height="18"
    viewBox="0 0 2 18"
    fill="none"
  >
    <path
      d="M1 1L0.999999 17"
      stroke="white"
      strokeOpacity="0.4"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
