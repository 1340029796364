import { GetInquiryListQuery, InquiryType } from "generated/types";
import { isNull, isUndefined } from "lodash";
import moment from "moment";

export const getInquiry = (
  data: GetInquiryListQuery | undefined,
  page: number,
) => {
  if (isNull(data) || isUndefined(data)) return defaultList;
  const { getMe } = data;
  if (isNull(getMe) || isUndefined(getMe)) return defaultList;
  const { InquiryAggregate, Inquiry } = getMe;

  const getTotalCount = () => {
    if (isNull(InquiryAggregate) || isUndefined(InquiryAggregate)) return 0;
    const { _count } = InquiryAggregate;
    if (isNull(_count) || isUndefined(_count)) return 0;
    const { id } = _count;
    return id;
  };
  const list = Inquiry.map((notice, index) => {
    const { id, uuid, title, createdAt, inquiryType, state } = notice;
    return {
      id,
      uuid,
      title,
      createdAt: moment(createdAt).format("YYYY.MM.DD"),
      inquiryType,
      state,
      num: getTotalCount() - (page * 20 + index),
    };
  });
  return {
    totalCount: getTotalCount(),
    list,
  };
};
export type GetInquiry = ReturnType<typeof getInquiry>;

export const defaultList = {
  totalCount: 0,
  list: [],
};
export interface ListType {
  id: number;
  uuid: string;
  title: string;
  createdAt: string;
  inquiryType: InquiryType;
  state: boolean;
  num: number;
}

export const getType = (type: InquiryType) => {
  switch (type) {
    case InquiryType.Service:
      return "서비스";
    case InquiryType.Modeling:
      return "모델링";
    case InquiryType.Etc:
      return "기타";
    case InquiryType.Suggestion:
      return "제안하기";
  }
};

export interface TablePropType {
  goDetail(uuid: string): void;
  list: ListType[];
}

export interface PresenterPropType {
  goRegist(): void;
  goDetail(uuid: string): void;
  list: ListType[];
  totalCount: number;
  page: number;
  handleSetPage(_event: React.ChangeEvent<unknown>, value: number): void;
}
