import * as React from "react";

export const MeritBox = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="884"
    height="312"
    viewBox="0 0 884 312"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_4727_925)">
      <mask id="path-1-inside-1_4727_925" fill="white">
        <path d="M32 56C32 33.9086 49.9086 16 72 16H812C834.091 16 852 33.9086 852 56V224C852 246.091 834.091 264 812 264H72C49.9086 264 32 246.091 32 224V56Z" />
      </mask>
      <path
        d="M32 56C32 33.9086 49.9086 16 72 16H812C834.091 16 852 33.9086 852 56V224C852 246.091 834.091 264 812 264H72C49.9086 264 32 246.091 32 224V56Z"
        fill="white"
      />
      <path
        d="M72 20H812V12H72V20ZM848 56V224H856V56H848ZM812 260H72V268H812V260ZM36 224V56H28V224H36ZM72 260C52.1178 260 36 243.882 36 224H28C28 248.301 47.6995 268 72 268V260ZM848 224C848 243.882 831.882 260 812 260V268C836.301 268 856 248.301 856 224H848ZM812 20C831.882 20 848 36.1178 848 56H856C856 31.6995 836.301 12 812 12V20ZM72 12C47.6995 12 28 31.6995 28 56H36C36 36.1178 52.1177 20 72 20V12Z"
        fill="#F2F2F2"
        mask="url(#path-1-inside-1_4727_925)"
      />
      <path
        d="M138.435 94.9404V103.64H146.835V94.8804H142.995C142.995 93.3204 143.275 92.0603 143.835 91.1003C144.395 90.1403 145.435 89.3804 146.955 88.8204V84.8604C145.715 85.0203 144.555 85.4003 143.475 86.0004C142.435 86.6004 141.515 87.3604 140.715 88.2804C139.955 89.1604 139.375 90.1804 138.975 91.3404C138.575 92.4604 138.395 93.6604 138.435 94.9404ZM150.315 94.9404V103.64H158.715V94.8804H154.875C154.875 93.3204 155.155 92.0603 155.715 91.1003C156.275 90.1403 157.315 89.3804 158.835 88.8204V84.8604C157.595 85.0203 156.435 85.4003 155.355 86.0004C154.275 86.6004 153.355 87.3604 152.595 88.2804C151.835 89.1604 151.255 90.1804 150.855 91.3404C150.455 92.4604 150.275 93.6604 150.315 94.9404Z"
        fill="#664AE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.955 88.82C145.435 89.38 144.395 90.14 143.835 91.1C143.4 91.8456 143.134 92.7723 143.037 93.88C143.009 94.1984 142.995 94.5317 142.995 94.88H146.835V103.64H138.435V94.94C138.395 93.66 138.575 92.46 138.975 91.34C139.375 90.18 139.955 89.16 140.715 88.28C141.515 87.36 142.435 86.6 143.475 86C144.26 85.5642 145.086 85.2444 145.955 85.0407C146.282 84.964 146.616 84.9038 146.955 84.86V88.82ZM138.032 91.0088C137.589 92.2492 137.394 93.5677 137.435 94.9553V104.64H147.835V93.88H144.041C144.135 92.9237 144.365 92.1755 144.699 91.6038C145.099 90.9185 145.902 90.2738 147.301 89.7583L147.955 89.5173V83.7227L146.827 83.8682C145.463 84.0442 144.182 84.4636 142.989 85.1258L142.982 85.1297L142.975 85.1338C141.838 85.79 140.832 86.6215 139.961 87.6238L139.958 87.6264C139.112 88.6065 138.471 89.7375 138.032 91.0088ZM158.835 88.82C157.315 89.38 156.275 90.14 155.715 91.1C155.28 91.8456 155.014 92.7723 154.917 93.88C154.889 94.1984 154.875 94.5317 154.875 94.88H158.715V103.64H150.315V94.94C150.275 93.66 150.455 92.46 150.855 91.34C151.255 90.18 151.835 89.16 152.595 88.28C153.355 87.36 154.275 86.6 155.355 86C156.14 85.5642 156.966 85.2444 157.835 85.0407C158.162 84.964 158.496 84.9038 158.835 84.86V88.82ZM151.831 87.6347C152.672 86.6189 153.688 85.7824 154.869 85.1258C156.062 84.4636 157.343 84.0442 158.707 83.8682L159.835 83.7227V89.5173L159.181 89.7583C157.782 90.2738 156.979 90.9185 156.579 91.6038C156.245 92.1755 156.015 92.9237 155.921 93.88H159.715V104.64H149.315V94.9553C149.274 93.5677 149.469 92.2492 149.912 91.0089C150.349 89.7411 150.988 88.6129 151.831 87.6347Z"
        fill="#F2F2F2"
      />
      <path
        d="M738.345 93.4998V84.7998H729.945V93.5598H733.785C733.785 95.0798 733.485 96.3398 732.885 97.3398C732.325 98.2998 731.305 99.0598 729.825 99.6198V103.58C731.105 103.38 732.265 103 733.305 102.44C734.385 101.84 735.305 101.1 736.065 100.22C736.825 99.3398 737.405 98.3398 737.805 97.2198C738.245 96.0598 738.425 94.8198 738.345 93.4998ZM726.465 93.4998V84.7998H718.065V93.5598H721.905C721.905 95.0798 721.605 96.3398 721.005 97.3398C720.445 98.2998 719.425 99.0598 717.945 99.6198V103.58C719.225 103.38 720.385 103 721.425 102.44C722.505 101.84 723.425 101.1 724.185 100.22C724.945 99.2998 725.525 98.2798 725.925 97.1598C726.365 95.9998 726.545 94.7798 726.465 93.4998Z"
        fill="#664AE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M729.825 99.6198C731.305 99.0598 732.325 98.2998 732.885 97.3398C733.348 96.568 733.633 95.6413 733.738 94.5598C733.77 94.24 733.785 93.9067 733.785 93.5598H729.945V84.7998H738.345V93.4998C738.425 94.8198 738.245 96.0598 737.805 97.2198C737.405 98.3398 736.825 99.3398 736.065 100.22C735.305 101.1 734.385 101.84 733.305 102.44C732.543 102.85 731.716 103.164 730.825 103.381C730.501 103.46 730.167 103.526 729.825 103.58V99.6198ZM738.744 97.5653C738.303 98.7952 737.663 99.9002 736.822 100.873C735.981 101.848 734.967 102.661 733.791 103.314L733.779 103.32C732.631 103.939 731.361 104.352 729.98 104.568L728.825 104.748V98.929L729.471 98.6845C730.826 98.172 731.617 97.529 732.022 96.8359L732.028 96.8253C732.388 96.2255 732.632 95.4785 732.733 94.5598H728.945V83.7998H739.345V93.4702C739.428 94.9074 739.231 96.2763 738.744 97.5653ZM717.945 99.6198C719.425 99.0598 720.445 98.2998 721.005 97.3398C721.468 96.568 721.753 95.6413 721.858 94.5598C721.89 94.24 721.905 93.9067 721.905 93.5598H718.065V84.7998H726.465V93.4998C726.545 94.7798 726.365 95.9998 725.925 97.1598C725.525 98.2798 724.945 99.2998 724.185 100.22C723.425 101.1 722.505 101.84 721.425 102.44C720.663 102.85 719.836 103.164 718.945 103.381C718.621 103.46 718.287 103.526 717.945 103.58V99.6198ZM726.864 97.5053C727.35 96.2203 727.549 94.8716 727.465 93.4694V83.7998H717.065V94.5598H720.853C720.752 95.4785 720.508 96.2255 720.148 96.8253L720.142 96.8359C719.737 97.529 718.946 98.172 717.591 98.6845L716.945 98.929V104.748L718.1 104.568C719.481 104.352 720.751 103.939 721.899 103.32L721.911 103.314C723.087 102.661 724.101 101.848 724.942 100.873L724.949 100.865L724.956 100.857C725.788 99.85 726.425 98.7314 726.864 97.5053Z"
        fill="#F2F2F2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4727_925"
        x="0"
        y="0"
        width="884"
        height="312"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="16" />
        <feGaussianBlur stdDeviation="16" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.960784 0 0 0 0 0.952941 0 0 0 0 0.996078 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4727_925"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4727_925"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default MeritBox;
