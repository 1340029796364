import * as React from "react";
import { SVGProps } from "react";

export const ExitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.79839 19.493H13.5417C15.051 19.493 15.8021 18.7658 15.8021 17.3042V14.0594H14.6281V17.2832C14.6281 17.9825 14.2417 18.3672 13.4834 18.3672H5.85672C5.09842 18.3672 4.71198 17.9825 4.71198 17.2832V6.71679C4.71198 6.01748 5.09842 5.62587 5.85672 5.62587H13.4834C14.2417 5.62587 14.6281 6.01748 14.6281 6.71679V9.93358H15.8021V6.6958C15.8021 5.24126 15.051 4.5 13.5417 4.5H5.79839C4.28909 4.5 3.53809 5.24126 3.53809 6.6958V17.3042C3.53809 18.7658 4.28909 19.493 5.79839 19.493Z"
      fill="#A8A7AF"
    />
    <path
      d="M10.2831 12.5481H17.7713L18.865 12.5061L18.3473 12.9817L17.1734 14.0376C17.0567 14.1355 16.9984 14.2824 16.9984 14.4152C16.9984 14.7089 17.2098 14.9257 17.5088 14.9257C17.6619 14.9257 17.7785 14.8698 17.8952 14.7649L20.2722 12.4012C20.418 12.2614 20.4617 12.1355 20.4617 11.9957C20.4617 11.8488 20.418 11.7299 20.2722 11.5901L17.8952 9.22641C17.7785 9.12154 17.6619 9.05859 17.5088 9.05859C17.2098 9.05859 16.9984 9.26837 16.9984 9.56207C16.9984 9.70192 17.0567 9.84879 17.1734 9.94668L18.3473 11.0096L18.865 11.4852L17.7713 11.4362H10.2831C9.97688 11.4362 9.72168 11.695 9.72168 11.9957C9.72168 12.2963 9.97688 12.5481 10.2831 12.5481Z"
      fill="#A8A7AF"
    />
  </svg>
);
