import TableCell from "@mui/material/TableCell";
import {
  BodyRow,
  CustomBody,
  ImportantBox,
  NewBox,
  MobileDate,
  TitleBox,
} from "./styles";
import Box from "@mui/material/Box";
import { TablePropType } from "./types";

export const TableBody = (props: TablePropType) => {
  const { goDetail, list } = props;
  return (
    <CustomBody>
      {list.map((row) => {
        const { id, uuid, title, createdAt, views, isNew, important, num } =
          row;
        return (
          <BodyRow
            key={id}
            onClick={() => {
              goDetail(uuid);
            }}
          >
            <TableCell align="center">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {important ? <ImportantBox>중요</ImportantBox> : num}
              </Box>
            </TableCell>
            <TableCell align="left">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "space-between",
                    lg: "flex-start",
                  },
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Box>
                  <TitleBox>{title}</TitleBox>
                  <MobileDate>{createdAt}</MobileDate>
                </Box>
                {isNew && <NewBox>NEW</NewBox>}
              </Box>
            </TableCell>
            <TableCell
              align="center"
              width={144}
              sx={{ display: { xs: "none", sm: "table-cell" } }}
            >
              {createdAt}
            </TableCell>
            <TableCell
              align="center"
              width={144}
              sx={{ display: { xs: "none", lg: "table-cell" }, minWidth: 144 }}
            >
              {views}
            </TableCell>
          </BodyRow>
        );
      })}
    </CustomBody>
  );
};
