import React from "react";
import { CustomBar } from "components";
import { FlexDiv, CursorDiv } from "../styles";
import { GnbSignInUpTypo } from "./styles";
import { useTranslation } from "next-i18next";
import { RightBoxPropType } from "./types";

export const RightBox = (props: RightBoxPropType) => {
  const { authenticated, goMyPage, goSignOut, goSignIn, goSignUp } = props;
  const { t } = useTranslation("common");
  return (
    <FlexDiv>
      {authenticated ? (
        <FlexDiv>
          <GnbSignInUpTypo onClick={goMyPage}>마이페이지</GnbSignInUpTypo>
          <CustomBar style={{ marginLeft: 16, marginRight: 16 }} />
          <GnbSignInUpTypo onClick={goSignOut}>로그아웃</GnbSignInUpTypo>
        </FlexDiv>
      ) : (
        <CursorDiv sx={{ mr: 4 }}>
          <GnbSignInUpTypo onClick={goSignIn}>{t("signIn")}</GnbSignInUpTypo>
          <CustomBar style={{ marginLeft: 16, marginRight: 16 }} />
          <GnbSignInUpTypo onClick={goSignUp}>{t("signUp")}</GnbSignInUpTypo>
        </CursorDiv>
      )}
    </FlexDiv>
  );
};
