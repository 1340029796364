import React from "react";
import { TabBox, Wrap } from "./styles";
import { Notice } from "./Notice";
import { Inquiry } from "./Inquiry";
interface PropType {
  type: string;
  name: string;
}
export const HelpContainer = (props: PropType) => {
  const { type, name } = props;
  return (
    <TabBox>
      <Wrap>
        {type === "notice" ? (
          <Notice type={type} />
        ) : (
          <Inquiry type={type} name={name} />
        )}
      </Wrap>
    </TabBox>
  );
};
