import * as React from "react";
import { SVGProps } from "react";

export const BuilderhubLogo1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="163"
    height="22"
    viewBox="0 0 163 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6175 0H3.3825C1.5144 0 0 1.5144 0 3.3825V18.6175C0 20.4856 1.5144 22 3.3825 22H18.6175C20.4856 22 22 20.4856 22 18.6175V3.3825C22 1.5144 20.4856 0 18.6175 0ZM7.22324 5.35355H4.95602C4.78895 5.35355 4.65352 5.48898 4.65352 5.65605V16.3474C4.65352 16.5145 4.78895 16.6499 4.95602 16.6499H7.22324C7.3903 16.6499 7.52574 16.5145 7.52574 16.3474V5.65605C7.52574 5.48898 7.3903 5.35355 7.22324 5.35355ZM16.0694 14.6268H18.1044L18.1166 14.6238C18.2846 14.6238 18.4191 14.7613 18.4191 14.9263V16.344C18.4191 16.5121 18.2816 16.6465 18.1166 16.6465H8.60158C8.43352 16.6465 8.29908 16.509 8.29908 16.344V14.9201C8.29908 14.7521 8.43658 14.6176 8.60158 14.6176H11.981C12.5707 14.6176 13.023 14.4954 13.3377 14.254C13.8602 13.8538 13.9213 13.0654 13.7471 12.4757C13.6371 12.0999 13.2521 11.776 12.8977 11.6385C12.5769 11.5163 12.2285 11.4857 11.8924 11.4857H8.59852C8.43047 11.4857 8.29602 11.3482 8.29602 11.1832V9.97015C8.29602 9.80209 8.43352 9.66765 8.59852 9.66765H11.1377C11.7121 9.66765 12.2132 9.66765 12.5035 9.43237C12.9252 9.09015 12.8977 8.63181 12.8977 8.63181C12.8977 8.2407 12.7571 7.93515 12.4821 7.71209C12.2071 7.48903 11.8282 7.37903 11.3485 7.37903H8.59547C8.42741 7.37903 8.29297 7.24153 8.29297 7.07653V5.65265C8.29297 5.48459 8.43047 5.35015 8.59547 5.35015H12.1155C12.861 5.35015 13.5027 5.46626 14.0374 5.69542C14.5721 5.92459 14.9816 6.25153 15.2688 6.6732C15.4369 6.92376 15.556 7.20792 15.6263 7.50126C15.8066 8.25598 15.7027 9.34987 14.9266 9.75015C14.919 9.75393 14.9094 9.75902 14.8984 9.76488C14.838 9.7969 14.7347 9.85167 14.6882 9.83876C15.6202 10.0924 16.3535 10.6729 16.6835 11.5926C16.983 12.4268 16.9494 13.4107 16.4605 14.1715C16.4085 14.254 16.3535 14.3304 16.2894 14.4007C16.2806 14.4102 16.2663 14.4282 16.249 14.45C16.1942 14.5191 16.1088 14.6268 16.0694 14.6268Z"
      fill="#664AE3"
    />
    <path
      d="M37.2268 11.0031C36.6565 10.6617 35.985 10.4526 35.2161 10.3795V10.3377C35.7901 10.2575 36.3063 10.052 36.7576 9.72095C37.2088 9.38995 37.5662 8.97882 37.8297 8.48755C38.0896 7.99627 38.2232 7.47713 38.2232 6.93011V6.90921C38.2232 6.12178 38.0282 5.43539 37.6348 4.84656C37.2413 4.25773 36.689 3.80478 35.9742 3.48424C35.2594 3.16369 34.422 3.00342 33.4581 3.00342H27V18.6126H33.4906C34.6422 18.6126 35.6277 18.4349 36.4507 18.083C37.2738 17.7311 37.9055 17.2259 38.3495 16.5674C38.7935 15.9124 39.0174 15.1249 39.0174 14.2086V14.1877C39.0174 13.4943 38.8621 12.8776 38.5517 12.3306C38.2412 11.7871 37.8008 11.3411 37.2305 10.9997L37.2268 11.0031ZM32.848 4.98244C33.7613 4.98244 34.4653 5.17407 34.9598 5.55734C35.458 5.9406 35.7071 6.4911 35.7071 7.21233V7.23324C35.7071 8.0137 35.4147 8.61298 34.8335 9.03108C34.2523 9.44919 33.4076 9.65824 32.2993 9.65824H29.5089V4.98244H32.848V4.98244ZM36.4363 14.0936C36.4363 14.9229 36.1403 15.5535 35.5518 15.9855C34.9598 16.4176 34.1043 16.6336 32.9852 16.6336H29.5089V11.5258H32.8047C33.6025 11.5258 34.2739 11.6198 34.8118 11.8115C35.3497 12.0031 35.754 12.2853 36.0284 12.6616C36.3027 13.0379 36.4363 13.5048 36.4363 14.0692V14.0901V14.0936Z"
      fill="#664AE3"
    />
    <path
      d="M51.7493 12.9335C51.7493 13.6895 51.594 14.3515 51.28 14.9194C50.9659 15.4874 50.5111 15.9264 49.9118 16.2434C49.3126 16.5605 48.587 16.7208 47.7242 16.7208C46.8615 16.7208 46.1359 16.5605 45.533 16.2434C44.9302 15.9264 44.4717 15.4839 44.154 14.9194C43.8364 14.3515 43.6775 13.693 43.6775 12.9335V3H41.165V13.1286C41.165 14.2679 41.4286 15.2713 41.9592 16.1354C42.4899 16.9995 43.2443 17.672 44.2226 18.1528C45.2009 18.6336 46.3669 18.874 47.7206 18.874C49.0743 18.874 50.2403 18.6336 51.2186 18.1493C52.1969 17.665 52.9514 16.9925 53.4784 16.1319C54.0054 15.2713 54.269 14.2679 54.269 13.1286V3H51.7457V12.9335H51.7493Z"
      fill="#664AE3"
    />
    <path d="M59.7527 3H57.2402V18.6127H59.7527V3Z" fill="#664AE3" />
    <path
      d="M65.2469 3H62.7344V18.6127H72.7916V16.5152H65.2469V3Z"
      fill="#664AE3"
    />
    <path
      d="M84.7583 3.91628C83.6175 3.30654 82.2458 3.00342 80.6394 3.00342H74.9321V18.6126H80.6394C82.253 18.6126 83.632 18.306 84.7691 17.6858C85.9098 17.0691 86.7798 16.1737 87.3863 15.003C87.9927 13.8323 88.296 12.4212 88.296 10.7767V10.7558C88.296 9.11122 87.9927 7.71057 87.3827 6.5573C86.7726 5.40403 85.899 4.52253 84.7583 3.91279V3.91628ZM85.7293 10.8045C85.7293 12.031 85.5236 13.0693 85.112 13.9194C84.7005 14.7696 84.0977 15.4176 83.3071 15.8566C82.5165 16.2956 81.5455 16.5151 80.4047 16.5151H77.4446V5.10091H80.4047C81.5491 5.10091 82.5129 5.32041 83.3035 5.75593C84.0904 6.19146 84.6933 6.83255 85.1084 7.6827C85.5236 8.52936 85.7293 9.56416 85.7293 10.7836V10.8045V10.8045Z"
      fill="#664AE3"
    />
    <path
      d="M90.6929 18.7387H100.963V16.6412H93.2054V11.8051H100.548V9.77034H93.2054V5.22695H100.963V3.12598H90.6929V18.7387Z"
      fill="#664AE3"
    />
    <path
      d="M114.385 10.7111C114.941 9.91668 115.219 8.98988 115.219 7.93069V7.90978C115.219 6.92724 114.998 6.08058 114.562 5.36632C114.125 4.65206 113.504 4.10155 112.695 3.71132C111.887 3.32109 110.934 3.12598 109.836 3.12598H103.548V18.7387H106.06V12.7563H109.515L112.811 18.7387H115.713L112.092 12.3765C113.064 12.0595 113.829 11.5055 114.385 10.7111V10.7111ZM109.591 10.7529H106.06V5.16075H109.515C110.479 5.16075 111.241 5.40813 111.796 5.90288C112.352 6.39764 112.63 7.07357 112.63 7.93069V7.95159C112.63 8.84703 112.363 9.5369 111.836 10.0247C111.306 10.5125 110.558 10.7564 109.594 10.7564L109.591 10.7529Z"
      fill="#664AE3"
    />
    <path
      d="M129.399 9.72505H121.103V3.12598H118.591V18.7387H121.103V11.826H129.399V18.7387H131.922V3.12598H129.399V9.72505Z"
      fill="#664AE3"
    />
    <path
      d="M145.485 13.0594C145.485 13.8155 145.329 14.4775 145.015 15.0454C144.701 15.6133 144.246 16.0524 143.647 16.3694C143.048 16.6865 142.319 16.8468 141.46 16.8468C140.6 16.8468 139.871 16.6865 139.268 16.3694C138.666 16.0524 138.207 15.6099 137.889 15.0454C137.572 14.4775 137.413 13.819 137.413 13.0594V3.12598H134.9V13.2545C134.9 14.3939 135.164 15.3973 135.695 16.2614C136.225 17.1255 136.98 17.7979 137.958 18.2788C138.936 18.7596 140.102 19 141.456 19C142.81 19 143.976 18.7596 144.954 18.2753C145.932 17.791 146.687 17.1185 147.214 16.2579C147.741 15.3973 148.004 14.3939 148.004 13.2545V3.12598H145.481V13.0594H145.485Z"
      fill="#664AE3"
    />
    <path
      d="M162.53 12.4601C162.22 11.9165 161.78 11.4706 161.209 11.1291C160.639 10.7877 159.967 10.5786 159.199 10.5054V10.4636C159.773 10.3835 160.289 10.1779 160.74 9.84693C161.191 9.51593 161.549 9.1048 161.812 8.61352C162.076 8.12225 162.206 7.60311 162.206 7.05609V7.03518C162.206 6.24775 162.011 5.56137 161.617 4.97254C161.224 4.38371 160.671 3.93076 159.957 3.61021C159.242 3.28967 158.404 3.12939 157.441 3.12939H150.982V18.7386H157.473C158.625 18.7386 159.61 18.5609 160.433 18.209C161.256 17.8571 161.888 17.3519 162.332 16.6934C162.776 16.0384 163 15.2509 163 14.3346V14.3137C163 13.6203 162.845 13.0036 162.534 12.4566L162.53 12.4601ZM156.83 5.10842C157.744 5.10842 158.448 5.30005 158.942 5.68331C159.44 6.06657 159.689 6.61708 159.689 7.33831V7.35921C159.689 8.13967 159.397 8.73895 158.816 9.15706C158.235 9.57516 157.39 9.78421 156.282 9.78421H153.491V5.10842H156.83V5.10842ZM160.419 14.2196C160.419 15.0488 160.123 15.6795 159.534 16.1115C158.942 16.5436 158.087 16.7596 156.968 16.7596H153.491V11.6517H156.787C157.585 11.6517 158.256 11.7458 158.794 11.9374C159.332 12.1291 159.736 12.4113 160.011 12.7876C160.285 13.1639 160.419 13.6308 160.419 14.1952V14.2161V14.2196Z"
      fill="#664AE3"
    />
  </svg>
);
