import React from "react";
import {
  CustomizedChip,
  MainTypo,
  SubTypo,
  ImgBox,
  Wrap,
  Container,
  ReverseBox,
  ContentBox,
  LeftInfo,
  RightInfo,
} from "./styles";
import Image from "next/image";
import { useTranslation } from "next-i18next";
export const Strength = () => {
  const { t } = useTranslation("common");
  return (
    <Wrap>
      <Container>
        <ReverseBox>
          <LeftInfo>
            <CustomizedChip label={t("BIMbased")} />
            <MainTypo m="20px 0">
              {t("BIMmain1")}
              <br />
              {t("BIMmain2")}
              <br />
              {t("BIMmain3")}
            </MainTypo>
            <SubTypo>
              {t("BIMsub1")}
              <br />
              {t("BIMsub2")}
            </SubTypo>
          </LeftInfo>
          <ImgBox>
            <Image
              width={504}
              height={400}
              src="/main/content1.png"
              alt="content1"
            />
          </ImgBox>
        </ReverseBox>
        <ContentBox>
          <ImgBox>
            <Image
              width={504}
              height={400}
              src="/main/content2.png"
              alt="content2"
            />
          </ImgBox>
          <RightInfo>
            <CustomizedChip label={t("reasonableCost")} />
            <MainTypo m="20px 0">
              {t("optimalPrice")}
              <br />
              {t("optimalResult")}
            </MainTypo>
            <SubTypo
              sx={{
                textAlign: { mobile: "center", tablet: "left" },
              }}
            >
              {t("fourteenYears")}
              <br />
              {t("fourteenYears2")}
            </SubTypo>
          </RightInfo>
        </ContentBox>
        <ReverseBox>
          <LeftInfo>
            <CustomizedChip label={t("modelViewer")} />
            <MainTypo m="20px 0">
              {t("myBuilding")}
              <br />
              {t("preview")}
            </MainTypo>
            <SubTypo>
              {t("2dDrawings")}
              <br />
              {t("2dDrawings2")}
            </SubTypo>
          </LeftInfo>
          <ImgBox>
            <Image
              width={504}
              height={400}
              src="/main/content3.png"
              alt="content3"
            />
          </ImgBox>
        </ReverseBox>
      </Container>
    </Wrap>
  );
};
