import * as React from "react";

export const UploadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.42125 11.5714C4 10.4026 4 9.23377 4 8.42054C4 5.71027 6.62391 3 9.86067 3C12.0299 3 13.924 4.09007 14.9373 5.71027H15.7213C18.1489 5.71027 20.4286 7.28571 20.4286 9.23377C20.4286 10.013 20.4286 10.7922 20.0073 11.5714"
      stroke="#664AE3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2051 13.9824L11.5335 11.4856L8.86185 13.9824"
      stroke="#664AE3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5332 12.0293L11.5332 19.1722"
      stroke="#664AE3"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
export const UploadFileIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="4" y="2" width="12" height="16" rx="2" fill="#F2F2F2" />
    <path
      d="M10 2H15C15.5523 2 16 2.44772 16 3V8H10.5C10.2239 8 10 7.77614 10 7.5V2Z"
      fill="url(#paint0_linear_4811_1719)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4811_1719"
        x1="10.5"
        y1="7.75"
        x2="13.75"
        y2="4.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBDBDB" stopOpacity="0.5" />
        <stop offset="1" stopColor="#666666" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
export const UploadFileIconActive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="4" y="2" width="12" height="16" rx="2" fill="#EBE7FD" />
    <path
      d="M10 2H15C15.5523 2 16 2.44772 16 3V8H10.5C10.2239 8 10 7.77614 10 7.5V2Z"
      fill="url(#paint0_linear_4811_2090)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4811_2090"
        x1="10.5"
        y1="7.75"
        x2="13.75"
        y2="4.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A291EE" stopOpacity="0.5" />
        <stop offset="1" stopColor="#A291EE" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const DeleteFileIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5035_357)">
      <path
        d="M17.6032 6.27225C17.2522 5.92125 16.6852 5.92125 16.3342 6.27225L11.9333 10.6643L7.53225 6.26325C7.18125 5.91225 6.61425 5.91225 6.26325 6.26325C5.91225 6.61425 5.91225 7.18125 6.26325 7.53225L10.6643 11.9333L6.26325 16.3342C5.91225 16.6852 5.91225 17.2522 6.26325 17.6032C6.61425 17.9542 7.18125 17.9542 7.53225 17.6032L11.9333 13.2023L16.3342 17.6032C16.6852 17.9542 17.2522 17.9542 17.6032 17.6032C17.9542 17.2522 17.9542 16.6852 17.6032 16.3342L13.2023 11.9333L17.6032 7.53225C17.9452 7.19025 17.9452 6.61425 17.6032 6.27225Z"
        fill="#DBDBDB"
      />
    </g>
    <defs>
      <clipPath id="clip0_5035_357">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DownloadFileIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4811_2096)">
      <path
        d="M3.91119 17.8142H14.0888C15.9433 17.8142 16.875 16.9877 16.875 15.3508V7.42039C16.875 5.78347 15.9433 4.95703 14.0888 4.95703H11.6131V6.23637H14.0622C14.9406 6.23637 15.4464 6.66548 15.4464 7.49192V15.2793C15.4464 16.1057 14.9406 16.5348 14.0622 16.5348H3.92894C3.04162 16.5348 2.55359 16.1057 2.55359 15.2793V7.49192C2.55359 6.66548 3.04162 6.23637 3.92894 6.23637H6.38681V4.95703H3.91119C2.05669 4.95703 1.125 5.78347 1.125 7.42039V15.3508C1.125 16.9877 2.05669 17.8142 3.91119 17.8142Z"
        fill="#664AE3"
      />
      <path
        d="M8.99679 12.5357C9.18078 12.5357 9.3297 12.4856 9.50489 12.3186L12.4659 9.58762C12.5974 9.46233 12.6762 9.32871 12.6762 9.15331C12.6762 8.81093 12.3958 8.56873 12.0367 8.56873C11.8615 8.56873 11.6863 8.63552 11.5636 8.76915L10.232 10.1137L9.64506 10.7067L9.69763 9.45399V0.651424C9.69763 0.300657 9.37352 0 8.99679 0C8.62011 0 8.3047 0.300657 8.3047 0.651424V9.45399L8.35727 10.7067L7.76157 10.1137L6.43875 8.76915C6.31612 8.63552 6.12339 8.56873 5.95694 8.56873C5.58899 8.56873 5.32617 8.81093 5.32617 9.15331C5.32617 9.32871 5.39626 9.46233 5.52766 9.58762L8.48869 12.3186C8.67268 12.4856 8.8216 12.5357 8.99679 12.5357Z"
        fill="#664AE3"
      />
    </g>
    <defs>
      <clipPath id="clip0_4811_2096">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
