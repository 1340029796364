import * as React from "react";
import { SVGProps } from "react";

export const RightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.96993 1.41391C5.15555 1.25923 5.43142 1.28431 5.58611 1.46993L10.1945 7.00001L5.58611 12.5301C5.43142 12.7157 5.15555 12.7408 4.96993 12.5861C4.78431 12.4314 4.75923 12.1556 4.91391 11.9699L9.05551 7.00001L4.91391 2.03009C4.75923 1.84447 4.78431 1.5686 4.96993 1.41391Z"
      fill="#DBDBDB"
    />
  </svg>
);
