import React from "react";
import { Wrapper, Wrap, LogoDiv } from "./styles";
import Image from "next/image";

export const ClientList = () => {
  return (
    <Wrapper>
      <Wrap>
        <LogoDiv>
          <Image src={"/main/client/dl.png"} width={140} height={70} alt="dl" />
        </LogoDiv>
        <LogoDiv>
          <Image
            src={"/main/client/woomi.png"}
            width={140}
            height={70}
            alt="woomi"
          />
        </LogoDiv>
        <LogoDiv>
          <Image src={"/main/client/hd.png"} width={140} height={70} alt="sk" />
        </LogoDiv>
        <LogoDiv>
          <Image
            src={"/main/client/halla.png"}
            width={140}
            height={70}
            alt="halla"
          />
        </LogoDiv>
        <LogoDiv>
          <Image
            src={"/main/client/hanhwa.png"}
            width={140}
            height={70}
            alt="hanhwa"
          />
        </LogoDiv>
      </Wrap>
      <Wrap>
        <LogoDiv>
          <Image
            src={"/main/client/daewoo.png"}
            width={140}
            height={70}
            alt="daewoo"
          />
        </LogoDiv>
        <LogoDiv>
          <Image
            src={"/main/client/kolong.png"}
            width={140}
            height={70}
            alt="kolong"
          />
        </LogoDiv>
        <LogoDiv>
          <Image
            src={"/main/client/dongbu.png"}
            width={140}
            height={70}
            alt="dongbu"
          />
        </LogoDiv>
        <LogoDiv>
          <Image
            src={"/main/client/ssg.png"}
            width={140}
            height={70}
            alt="ssg"
          />
        </LogoDiv>
        <LogoDiv>
          <Image
            src={"/main/client/hanyang.png"}
            width={140}
            height={70}
            alt="hanyang"
          />
        </LogoDiv>
      </Wrap>
    </Wrapper>
  );
};
