import { PropType } from "../types";
import {
  ScrollBox,
  SnsBtn,
  DividerBox,
  SnsIconBtn,
  PcBox,
  MobileBox,
} from "./styles";
import {
  ChangSoftColor,
  InstagramColor,
  NaverColor,
  SnsDivider,
  YoutubeColor,
} from "components";

export const SnsBox = (props: Omit<PropType, "goCustomer" | "goCuration">) => {
  const { goChangSoft, goInstagram, goNaver, goYoutube } = props;
  return (
    <ScrollBox>
      <MobileBox>
        <SnsIconBtn onClick={goChangSoft}>
          <ChangSoftColor />
        </SnsIconBtn>
        <SnsIconBtn onClick={goInstagram}>
          <InstagramColor />
        </SnsIconBtn>
        <SnsIconBtn onClick={goNaver}>
          <NaverColor />
        </SnsIconBtn>
        <SnsIconBtn onClick={goYoutube}>
          <YoutubeColor />
        </SnsIconBtn>
      </MobileBox>
      <PcBox>
        <SnsBtn startIcon={<ChangSoftColor />} onClick={goChangSoft}>
          창소프트
        </SnsBtn>
        <DividerBox>
          <SnsDivider />
        </DividerBox>
        <SnsBtn startIcon={<InstagramColor />} onClick={goInstagram}>
          인스타그램
        </SnsBtn>
        <DividerBox>
          <SnsDivider />
        </DividerBox>
        <SnsBtn startIcon={<NaverColor />} onClick={goNaver}>
          블로그
        </SnsBtn>
        <DividerBox>
          <SnsDivider />
        </DividerBox>
        <SnsBtn startIcon={<YoutubeColor />} onClick={goYoutube}>
          유튜브
        </SnsBtn>
      </PcBox>
    </ScrollBox>
  );
};
