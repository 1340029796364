import { colors, theme } from "@builderhub/mui-theme";
import { Box, Button, Pagination, Typography, styled } from "@mui/material";

export const HeaderBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 32,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 16,
  },
}));

export const Title = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 700,
  letterSpacing: -0.3,
  color: colors.gray._100,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));

export const RegBtn = styled(Button)(() => ({
  padding: "7px 16px",
  borderRadius: 8,
  background: colors.purple._60,
  color: colors.white,
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: -0.3,
  ":hover": {
    background: colors.purple._80,
  },
  [theme.breakpoints.down("sm")]: {
    padding: "4px 12px",
    fontSize: 12,
  },
}));
export const StateBox = styled(Box)((props: { state: string }) => ({
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: -0.3,
  padding: "6px 10px",
  borderRadius: 4,
  color: props.state === "false" ? "#F77" : "#359DFF",
  background: props.state === "false" ? "#FFF1F1" : "#EBF5FF",
  minWidth: 72,
  display: "flex",
  justifyContent: "center",
}));

export const CustomPagination = styled(Pagination)(() => ({
  color: colors.gray._20,
  button: {
    color: colors.gray._40,
    fontSize: 14,
  },
  "button:first-of-type": {
    color: colors.gray._50,
  },
  "button:last-of-type": {
    color: colors.gray._50,
  },
  "& .Mui-selected": {
    color: `${colors.purple._60} !important`,
    background: `none !important`,
    ":hover": {
      background: `none !important`,
    },
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 56,
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: 32,
    padding: "24px 48px",
  },
}));
export const PaginationBox = styled(Box)(() => ({
  width: "100%",
  marginTop: 32,
  marginBottom: 80,
  display: "flex",
  justifyContent: "center",
}));
export const TitleBox = styled(Box)(() => ({
  width: 700,
  minWidth: 200,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  [theme.breakpoints.down("lg")]: {
    width: "45vw",
  },
  [theme.breakpoints.down("md")]: {
    width: "45vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "55vw",
  },
}));
export const MobileDate = styled(Typography)(() => ({
  color: colors.gray._40,
  fontSize: 10,
  letterSpacing: -0.3,
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "flex",
  },
}));
