import TableCell from "@mui/material/TableCell";
import { BodyRow, CustomBody } from "../Notice/styles";
import Box from "@mui/material/Box";
import { TablePropType, getType } from "./types";
import { StateBox, TitleBox, MobileDate } from "./styles";

export const TableBody = (props: TablePropType) => {
  const { goDetail, list } = props;
  return (
    <CustomBody>
      {list.map((row) => {
        const { id, uuid, title, createdAt, inquiryType, state, num } = row;
        return (
          <BodyRow
            key={id}
            onClick={() => {
              goDetail(uuid);
            }}
          >
            <TableCell
              align="center"
              sx={{
                display: { xs: "none", lg: "table-cell" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {num}
              </Box>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                display: { xs: "none", sm: "table-cell" },
                justifyContent: "center",
                alignItems: "center",
                minWidth: 144,
              }}
            >
              {getType(inquiryType)}
            </TableCell>
            <TableCell align="left">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "space-between",
                    lg: "flex-start",
                  },
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Box>
                  <TitleBox>{title}</TitleBox>
                  <MobileDate>{createdAt}</MobileDate>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              align="center"
              width={144}
              sx={{ display: { xs: "none", md: "table-cell" } }}
            >
              {createdAt}
            </TableCell>
            <TableCell align="center" width={144}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StateBox state={state.toString()}>
                  {state ? "답변 완료" : "접수 완료"}
                </StateBox>
              </Box>
            </TableCell>
          </BodyRow>
        );
      })}
    </CustomBody>
  );
};
