import * as React from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { EmptyBox, EmptyTypo } from "./styles";
import { EmptyListIcon } from "components/Icons";
import {
  useGetNoticeListLazyQuery,
  useIncrementViewMutation,
} from "generated/types";
import { ListType, defaultList, getNotice } from "./types";
import { useRouter } from "next/router";
import { TableBody } from "./TableBody";
import { Head as TableHead } from "./TableHead";
import { PaginationBox, CustomPagination } from "../Inquiry/styles";

export const Notice = (props: { type: string }) => {
  const { type } = props;
  const [list, setList] = React.useState<ListType[]>(defaultList.list);
  const [page, setPage] = React.useState(0);
  const router = useRouter();
  const [getList, { data }] = useGetNoticeListLazyQuery({
    fetchPolicy: "network-only",
    onCompleted(data) {
      setList(getNotice(data, page).list);
    },
  });
  const [increment] = useIncrementViewMutation({ fetchPolicy: "network-only" });
  const goDetail = (uuid: string) => {
    increment({ variables: { where: { uuid } } });
    router.push(`${type}/${uuid}`);
  };
  const handleSetPage = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };
  React.useEffect(() => {
    getList({ variables: { take: 20, skip: page * 20 } });
  }, [getList, page]);
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead />
          <TableBody goDetail={goDetail} list={list} />
        </Table>
      </TableContainer>
      {list.length === 0 ? (
        <EmptyBox>
          <EmptyListIcon />
          <EmptyTypo>등록된 공지사항이 없어요.</EmptyTypo>
        </EmptyBox>
      ) : (
        <PaginationBox>
          <CustomPagination
            count={Math.ceil(getNotice(data, page).totalCount / 20)}
            page={page + 1}
            onChange={handleSetPage}
          />
        </PaginationBox>
      )}
    </div>
  );
};
