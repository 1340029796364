import * as React from "react";
import Session from "supertokens-web-js/recipe/session";
import { useAppDispatch, setMyInfo, setAuth } from "store";
import { useGetMyInfoQuery } from "generated/types";
import { getMyInfoData } from "./Types";
import { createContext, useContext, useEffect } from "react";
import { useRouter } from "next/router";

const Context = createContext({});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const dispatch = useAppDispatch();
  useGetMyInfoQuery({
    onCompleted(data) {
      dispatch(setAuth({ authenticated: true }));
      dispatch(setMyInfo(getMyInfoData(data)));
    },
  });
  useEffect(() => {
    if (router.asPath.includes("/content")) return;
    Session.doesSessionExist().then((exist) => {
      if (!exist) {
      }
    });
  }, [dispatch, router.pathname, router]);
  return <Context.Provider value={{}}>{children}</Context.Provider>;
}

export const useSessionAuth = () => useContext(Context);

export default AuthProvider;
