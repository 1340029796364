import { CustomerRole, IdentityGender, MemberGroup } from "generated/types";
import { Cookies } from "react-cookie";
const cookie = new Cookies();

export enum Provider {
  email = "email",
  naver = "naver",
  kakao = "kakao",
  google = "google",
}
export interface AuthState extends IdentityParams {
  /**
   * userId
   */
  username: string | null;
  /**
   * Customer ID
   */
  customerId: number | null;
  authenticated: boolean;
  loading: boolean;
  /**
   * 로그인 상태 유지(체크하지 않은 경우 브라우저 종료할 때 localStorage 삭제
   */
  rememberUser: boolean;
  email: string;
  password: string;
  /**
   * 이메일 중복 체크
   */
  /**
   *멤버 그룹
   */
  group: MemberGroup;
  /**
   * 일반적으로 Cognito와 연결이 되지 않을 때 발생할 것으로 thunk의 rejected 값을 저장
   */
  error: boolean;
  code: string;
  message: string;
  emailVerified: boolean;
  poolId: string;

  /**
   * 이미 본인인증을 한 경우
   */
  isNotHaveUniqueKey: boolean;
  /**
   * Customer data: 소속
   */
  organization: string;
  /**
   * Customer data: 분야
   */
  field: CustomerRole;
  /**
   * Customer data: 기타 분야 직접 입력
   */
  fieldName: string;
  memberId: string;
  memberHash: string;
  createdNewUser: boolean;
  partnerId: number | null | undefined;
  forgotPassword: {
    error: {
      status: boolean;
      message: string;
    };
    success: {
      status: boolean;
      message: string;
    };
  };
}

export interface IdentityParams {
  /**
   * 본인인증 uniqueId: impUid
   */
  identity: string;
  /**
   * 본인인증 phone
   */
  phone: string;
  /**
   * 본인인증 gender
   */
  gender: IdentityGender | null;
  /**
   * 본인인증 name
   */
  name: string;
  /**
   * 본인인증 birth
   */
  birth: string | null;
  /**
   * Identity IMP uid
   */
  impUid: string | null;
  /**
   * Identity process success
   */
  success: boolean;
  /**
   * PostSignUp success
   */
  isPostSignedUp: boolean;
}

export const initialState: AuthState = {
  username: null,
  authenticated: false,
  customerId: null,
  loading: false,
  /**
   * 쿠키값으로 로그인 상태 저장값을 가져와서 초기값으로 설정
   */
  rememberUser: cookie.get("bhplf-auth-remember") === "true",
  identity: "",
  name: "",
  email: "",
  password: "",
  phone: "",
  gender: IdentityGender.Male,
  group: MemberGroup.Customer,
  birth: null,
  error: false,
  code: "",
  message: "",
  emailVerified: false,
  poolId: "",
  impUid: null,
  success: false,
  isNotHaveUniqueKey: true,
  isPostSignedUp: false,
  organization: "",
  field: CustomerRole.Design,
  fieldName: "",
  createdNewUser: false,
  memberId: "",
  memberHash: "",
  partnerId: null,
  forgotPassword: {
    error: {
      status: false,
      message: "",
    },
    success: {
      status: false,
      message: "",
    },
  },
};
