import * as React from "react";
import { SVGProps } from "react";

export const ArrowRightLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="68"
    viewBox="0 0 29 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.999999 1L27 34L0.999999 67"
      stroke="#A8A7AF"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1451_1637ArrowRight)">
      <path
        d="M7.73984 5.58855C7.41484 5.91355 7.41484 6.43855 7.73984 6.76355L10.9732 9.99688L7.73984 13.2302C7.41484 13.5552 7.41484 14.0802 7.73984 14.4052C8.06484 14.7302 8.58984 14.7302 8.91484 14.4052L12.7398 10.5802C13.0648 10.2552 13.0648 9.73022 12.7398 9.40522L8.91484 5.58022C8.59818 5.26355 8.06484 5.26355 7.73984 5.58855Z"
        fill={props.color ? props.color : "#A8A7AF"}
      />
    </g>
    <defs>
      <clipPath id="clip0_1451_1637ArrowRight">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ArrowRightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.5 2L9.5 7L5.5 12" stroke="white" strokeLinecap="round" />
  </svg>
);

export const DownArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 6L8 10L13 6"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
