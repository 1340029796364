import * as React from "react";

export const EmptyListIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M43.0051 71.2831C61.708 71.2831 76.8697 56.1218 76.8697 37.4194C76.8697 18.717 61.708 3.55566 43.0051 3.55566C24.3023 3.55566 9.14062 18.717 9.14062 37.4194C9.14062 56.1218 24.3023 71.2831 43.0051 71.2831Z"
      fill="#F9F9F9"
    />
    <path
      d="M35.9436 21.1506L5.52415 30.8994C3.7167 31.4787 2.72105 33.4134 3.30032 35.2208L15.1765 72.2767C15.7558 74.0841 17.6906 75.0797 19.4981 74.5005L49.9175 64.7517C51.725 64.1724 52.7206 62.2376 52.1413 60.4302L40.2651 23.3744C39.6859 21.567 37.751 20.5713 35.9436 21.1506Z"
      fill="#D4D3D7"
    />
    <path
      d="M35.8625 20.5324L5.4431 30.2812C3.63564 30.8605 2.64 32.7953 3.21926 34.6027L15.0955 71.6585C15.6747 73.4659 17.6096 74.4616 19.417 73.8823L49.8365 64.1335C51.6439 63.5542 52.6396 61.6195 52.0603 59.8121L40.1841 22.7562C39.6048 20.9488 37.67 19.9532 35.8625 20.5324Z"
      fill="#EBEBEB"
    />
    <path
      d="M52.1411 59.7899L40.2673 22.7358C39.6881 20.9258 37.7525 19.9315 35.9425 20.5107L26.0814 23.6721L24.5707 58.0233C24.4886 59.9202 25.9559 61.5275 27.8529 61.6095L52.0735 62.6762C52.2521 62.2032 52.3342 61.6916 52.2859 61.1703C52.3293 60.7214 52.2859 60.2532 52.1363 59.7947L52.1411 59.7899Z"
      fill="#A8A7AF"
    />
    <path
      d="M59.429 14.8662H27.4855C25.5875 14.8662 24.0488 16.4048 24.0488 18.3028V57.2152C24.0488 59.1131 25.5875 60.6517 27.4855 60.6517H59.429C61.327 60.6517 62.8656 59.1131 62.8656 57.2152V18.3028C62.8656 16.4048 61.327 14.8662 59.429 14.8662Z"
      fill="#D4D3D7"
    />
    <path
      d="M59.4514 14.5234H27.5079C25.6099 14.5234 24.0713 16.062 24.0713 17.96V56.8724C24.0713 58.7704 25.6099 60.309 27.5079 60.309H59.4514C61.3494 60.309 62.8881 58.7704 62.8881 56.8724V17.96C62.8881 16.062 61.3494 14.5234 59.4514 14.5234Z"
      fill="white"
    />
    <rect
      x="29.4121"
      y="21.79"
      width="27.8876"
      height="2.1452"
      rx="1.0726"
      fill="#EBEBEB"
    />
    <rect
      x="29.4121"
      y="29.2979"
      width="27.8876"
      height="2.1452"
      rx="1.0726"
      fill="#EBEBEB"
    />
    <rect
      x="29.4121"
      y="36.8066"
      width="27.8876"
      height="2.1452"
      rx="1.0726"
      fill="#EBEBEB"
    />
    <rect
      x="29.4121"
      y="44.3145"
      width="16.089"
      height="2.1452"
      rx="1.0726"
      fill="#EBEBEB"
    />
  </svg>
);
export default EmptyListIcon;
