import { ImageLoader } from "next/image";
import { getRuntimeVariable } from "@builderhub/env";

const imageLoader: ImageLoader = ({ src, width, quality }) => {
  if (getRuntimeVariable("NODE_ENV") === "production") {
    const originUrl = src.startsWith("http")
      ? // CDN
        encodeURIComponent(`${src}?w=${width}&q=${quality || 75}`)
      : // static folder: this should start with `/`
        encodeURIComponent(
          `${getRuntimeVariable("NEXT_PUBLIC_HOST_URL")}${src}?w=${width}&q=${
            quality || 75
          }`,
        );
    const url = new URL(
      `_next/image?url=${originUrl}&w=${width}&q=${quality || 75}`,
      getRuntimeVariable("NEXT_PUBLIC_IMAGE_OPT_URL"),
    );
    return url.href;
  }
  return src;
};

export default imageLoader;

// https://optimg.dev.platform.builderhub.io/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FBackground.75487d16e3e495b8d22608ef54a916d9.png&w=1920&q=75
