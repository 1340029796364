import React from "react";
import { SVGProps } from "react";

export const DefaultAvatar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.5 16C31.5 24.5604 24.5604 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C24.5604 0.5 31.5 7.43959 31.5 16Z"
      fill="#EBE7FD"
      stroke="#B4A7F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.36896 9H11.1343C11.338 9 11.5032 9.16519 11.5032 9.36896V22.4091C11.5032 22.6129 11.338 22.7781 11.1343 22.7781H8.36896C8.16519 22.7781 8 22.6129 8 22.4091V9.36896C8 9.16519 8.16519 9 8.36896 9ZM22.134 20.3147H24.616L24.6309 20.3109C24.8359 20.3109 24.9999 20.4786 24.9999 20.6799V22.4091C24.9999 22.6141 24.8322 22.7781 24.6309 22.7781H13.0256C12.8206 22.7781 12.6566 22.6104 12.6566 22.4091V20.6724C12.6566 20.4675 12.8243 20.3035 13.0256 20.3035H17.1475C17.8667 20.3035 18.4183 20.1544 18.8022 19.86C19.4395 19.3718 19.514 18.4102 19.3016 17.691C19.1674 17.2326 18.6978 16.8375 18.2655 16.6698C17.8742 16.5207 17.4493 16.4835 17.0394 16.4835H13.0219C12.8169 16.4835 12.6529 16.3158 12.6529 16.1145V14.635C12.6529 14.43 12.8206 14.266 13.0219 14.266H16.1189C16.8195 14.266 17.4307 14.266 17.7847 13.979C18.299 13.5616 18.2655 13.0026 18.2655 13.0026C18.2655 12.5256 18.0941 12.1529 17.7587 11.8808C17.4232 11.6088 16.9611 11.4746 16.376 11.4746H13.0181C12.8132 11.4746 12.6492 11.3069 12.6492 11.1057V9.36896C12.6492 9.16398 12.8169 9 13.0181 9H17.3114C18.2208 9 19.0034 9.14162 19.6556 9.42113C20.3078 9.70064 20.8072 10.0994 21.1575 10.6137C21.3625 10.9193 21.5078 11.2659 21.5936 11.6237C21.8134 12.5442 21.6867 13.8784 20.7401 14.3666C20.7309 14.3712 20.7192 14.3775 20.7057 14.3846C20.6321 14.4237 20.5061 14.4905 20.4494 14.4747C21.5861 14.784 22.4806 15.4921 22.8831 16.6139C23.2483 17.6313 23.2073 18.8314 22.611 19.7594C22.5476 19.86 22.4806 19.9532 22.4023 20.0389C22.3916 20.0505 22.3742 20.0724 22.3531 20.099C22.2863 20.1833 22.1821 20.3147 22.134 20.3147Z"
      fill="#836DE9"
    />
  </svg>
);

// export const ShareDafaultAvatar=()=>(

// )

export const ShareUnAuthAvatar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.5 16C31.5 24.5604 24.5604 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C24.5604 0.5 31.5 7.43959 31.5 16Z"
      fill="#EBE7FD"
      stroke="#B4A7F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.36896 9H11.1343C11.338 9 11.5032 9.16519 11.5032 9.36896V22.4091C11.5032 22.6129 11.338 22.7781 11.1343 22.7781H8.36896C8.16519 22.7781 8 22.6129 8 22.4091V9.36896C8 9.16519 8.16519 9 8.36896 9ZM22.134 20.3147H24.616L24.6309 20.3109C24.8359 20.3109 24.9999 20.4786 24.9999 20.6799V22.4091C24.9999 22.6141 24.8322 22.7781 24.6309 22.7781H13.0256C12.8206 22.7781 12.6566 22.6104 12.6566 22.4091V20.6724C12.6566 20.4675 12.8243 20.3035 13.0256 20.3035H17.1475C17.8667 20.3035 18.4183 20.1544 18.8022 19.86C19.4395 19.3718 19.514 18.4102 19.3016 17.691C19.1674 17.2326 18.6978 16.8375 18.2655 16.6698C17.8742 16.5207 17.4493 16.4835 17.0394 16.4835H13.0219C12.8169 16.4835 12.6529 16.3158 12.6529 16.1145V14.635C12.6529 14.43 12.8206 14.266 13.0219 14.266H16.1189C16.8195 14.266 17.4307 14.266 17.7847 13.979C18.299 13.5616 18.2655 13.0026 18.2655 13.0026C18.2655 12.5256 18.0941 12.1529 17.7587 11.8808C17.4232 11.6088 16.9611 11.4746 16.376 11.4746H13.0181C12.8132 11.4746 12.6492 11.3069 12.6492 11.1057V9.36896C12.6492 9.16398 12.8169 9 13.0181 9H17.3114C18.2208 9 19.0034 9.14162 19.6556 9.42113C20.3078 9.70064 20.8072 10.0994 21.1575 10.6137C21.3625 10.9193 21.5079 11.2659 21.5936 11.6237C21.8134 12.5442 21.6867 13.8784 20.7401 14.3666C20.7309 14.3712 20.7192 14.3775 20.7057 14.3846C20.6321 14.4237 20.5061 14.4905 20.4494 14.4747C21.5861 14.784 22.4806 15.4921 22.8831 16.6139C23.2483 17.6313 23.2073 18.8314 22.611 19.7594C22.5476 19.86 22.4806 19.9532 22.4023 20.0389C22.3916 20.0505 22.3742 20.0724 22.3531 20.099C22.2863 20.1833 22.1821 20.3147 22.134 20.3147Z"
      fill="#836DE9"
    />
  </svg>
);
export const ShareUnSignAvatar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.5 16C31.5 24.5604 24.5604 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C24.5604 0.5 31.5 7.43959 31.5 16Z"
      fill="#F2F2F2"
      stroke="#D4D3D7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.36896 9H11.1343C11.338 9 11.5032 9.16519 11.5032 9.36896V22.4091C11.5032 22.6129 11.338 22.7781 11.1343 22.7781H8.36896C8.16519 22.7781 8 22.6129 8 22.4091V9.36896C8 9.16519 8.16519 9 8.36896 9ZM22.134 20.3147H24.616L24.6309 20.3109C24.8359 20.3109 24.9999 20.4786 24.9999 20.6799V22.4091C24.9999 22.6141 24.8322 22.7781 24.6309 22.7781H13.0256C12.8206 22.7781 12.6566 22.6104 12.6566 22.4091V20.6724C12.6566 20.4675 12.8243 20.3035 13.0256 20.3035H17.1475C17.8667 20.3035 18.4183 20.1544 18.8022 19.86C19.4395 19.3718 19.514 18.4102 19.3016 17.691C19.1674 17.2326 18.6978 16.8375 18.2655 16.6698C17.8742 16.5207 17.4493 16.4835 17.0394 16.4835H13.0219C12.8169 16.4835 12.6529 16.3158 12.6529 16.1145V14.635C12.6529 14.43 12.8206 14.266 13.0219 14.266H16.1189C16.8195 14.266 17.4307 14.266 17.7847 13.979C18.299 13.5616 18.2655 13.0026 18.2655 13.0026C18.2655 12.5256 18.0941 12.1529 17.7587 11.8808C17.4232 11.6088 16.9611 11.4746 16.376 11.4746H13.0181C12.8132 11.4746 12.6492 11.3069 12.6492 11.1057V9.36896C12.6492 9.16398 12.8169 9 13.0181 9H17.3114C18.2208 9 19.0034 9.14162 19.6556 9.42113C20.3078 9.70064 20.8072 10.0994 21.1575 10.6137C21.3625 10.9193 21.5078 11.2659 21.5936 11.6237C21.8134 12.5442 21.6867 13.8784 20.7401 14.3666C20.7309 14.3712 20.7192 14.3775 20.7057 14.3846C20.6321 14.4237 20.5061 14.4905 20.4494 14.4747C21.5861 14.784 22.4806 15.4921 22.8831 16.6139C23.2483 17.6313 23.2073 18.8314 22.611 19.7594C22.5476 19.86 22.4806 19.9532 22.4023 20.0389C22.3916 20.0505 22.3742 20.0724 22.3531 20.099C22.2863 20.1833 22.1821 20.3147 22.134 20.3147Z"
      fill="#D4D3D7"
    />
  </svg>
);
