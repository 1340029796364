/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { colors, theme } from "@builderhub/mui-theme";
import { styled, Grid, Box, Typography, Button, Divider } from "@mui/material";

export const Wrap = styled(Grid)(() => ({
  width: "100%",
  minWidth: 360,
  display: "flex",
  justifyContent: "center",
  color: colors.white,
  background: colors.purple._60,
  borderTop: `1px solid ${colors.gray._20}`,
}));

export const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: 1200,
  padding: "32px 16px",
  gap: 32,
  [theme.breakpoints.down("lg")]: {
    padding: "32px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "24px 20px",
    gap: 12,
  },
}));

export const LogoBox = styled(Box)(() => ({
  [theme.breakpoints.down("sm")]: {
    img: {
      width: 120,
      height: 20,
    },
  },
}));

export const RowWrap = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
    flexDirection: "column",
    gap: 16,
  },
}));
export const InfoTypo = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: -0.3,
  lineHeight: "20px",
}));

export const RowWrapBordered = styled(Grid)(() => ({
  width: "100%",
  padding: "30px 0",
}));

export const ButtonWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    height: 14,
  },
}));

export const FooterButton = styled(Button)(() => ({
  color: colors.white,
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: -0.3,
  padding: "4px 12px",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 500,
    padding: 0,
    minWidth: 0,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
  },
}));
export const FooterDivider = styled(Divider)(() => ({
  display: "flex",
  background: "#EBE7FD",
  height: 16,
  width: 2,
  marginLeft: 8,
  marginRight: 8,
  borderRadius: 10,
  [theme.breakpoints.down("md")]: {
    height: 12,
    marginLeft: 12,
    marginRight: 12,
  },
  [theme.breakpoints.down("sm")]: {
    height: 8,
    marginTop: 3,
  },
}));

export const IconWrap = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 16,
  svg: {
    width: 64,
    height: 64,
    cursor: "pointer",
  },
  button: {
    padding: 0,
  },
  [theme.breakpoints.down("lg")]: {
    gap: 12,
    justifyContent: "flex-start",
    svg: {
      width: 48,
      height: 48,
    },
  },
  [theme.breakpoints.down("sm")]: {
    svg: {
      width: 36,
      height: 36,
    },
  },
}));
export const NoticeWrap = styled(ButtonWrap)(() => ({
  gap: 32,
  hr: { marginLeft: 0, marginRight: 0 },
  button: {
    padding: 0,
    fontSize: 16,
  },
  [theme.breakpoints.down("sm")]: {
    gap: 16,
    button: { fontSize: 12 },
  },
}));
export const WebBtnBox = styled(Box)(() => ({
  display: "contents",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
export const MobileBtnBox = styled(Box)(() => ({
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
}));
