import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FooterContainer } from "./Footer";
import { Wrap } from "./styles";
import { Main } from "./Main";
import { Ending } from "./Ending";
import { ClientList } from "./ClientList";
import { Merit } from "./Merit";
import { Strength } from "./Strength";
import { Partners } from "./Partners";
export function Home() {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <Wrap>
      <Main />
      <Strength />
      <Merit />
      <ClientList />
      <Partners />
      <Ending />
      <FooterContainer />
    </Wrap>
  );
}

// export const getStaticProps: GetStaticProps = async (ctx) => {
//   return {
//     props: {
//       ...(await serverSideTranslations(ctx.locale, ["common", "footer"])),
//       // Will be passed to the page component as props
//     },
//   };
// };

// export const getStaticProps: GetStaticProps = async (ctx) => {
//   return {
//     props: {
//       ...(await serverSideTranslations(ctx.locale ?? "ko", ["common"])),
//     },
//   };
// };
