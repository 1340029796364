import * as React from "react";
import {
  styled,
  Dialog,
  DialogContent,
  Typography,
  Button,
  Fade,
} from "@mui/material";
import { colors } from "@builderhub/mui-theme";
import Image from "next/image";
import Script from "next/script";

interface PropType {
  showPrompt: boolean;
  handleClose(): void;
  cancel(): void;
  certificate(): void;
}

export function CertificationDialog(props: PropType) {
  const { showPrompt, handleClose, cancel, certificate } = props;

  React.useEffect(() => {
    const { IMP } = window;
    IMP?.init("imp13102778");
  }, []);
  return (
    <>
      <Script
        src="https://code.jquery.com/jquery-3.2.1.min.js"
        integrity="sha256-hwg4gsxgFZhOsEEamdOYGBf13FyQuiTwlAQgxVSNgt4="
        crossOrigin="anonymous"
      />
      <Script
        type="text/javascript"
        src="https://cdn.iamport.kr/js/iamport.payment-1.2.1.js"
        onLoad={() => {
          const { IMP } = window;
          IMP?.init("imp13102778");
        }}
      />
      <CustomDialog onClose={handleClose} open={showPrompt}>
        <Fade in={showPrompt} timeout={900}>
          <Content>
            <Image
              src="/auth/CertificationIcon.png"
              alt="CertificationIcon"
              width={100}
              height={100}
            />
            <TypoDiv>
              <Title>본인 인증이 필요해요!</Title>
              <SubTitle>
                견적 서비스 이용을 위해서는
                <br /> 본인인증을 완료해주세요.
              </SubTitle>
            </TypoDiv>
            <ButtonDiv>
              <CancelBtn variant="contained" onClick={cancel}>
                다음에 하기
              </CancelBtn>
              <CertificateBtn variant="contained" onClick={certificate}>
                본인인증 하기
              </CertificateBtn>
            </ButtonDiv>
          </Content>
        </Fade>
      </CustomDialog>
    </>
  );
}

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: 12,
    minWidth: 370,
  },
}));
const Content = styled(DialogContent)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px 37px 44px 37px",
  textAlign: "center",
}));

const TypoDiv = styled(`div`)(() => ({
  marginTop: 52,
}));
const Title = styled(Typography)(() => ({
  fontWeight: 500,
  color: colors.gray._100,
  fontSize: 24,
}));
const SubTitle = styled(Typography)(() => ({
  color: colors.gray._60,
  marginTop: 12,
  fontSize: 16,
}));
const ButtonDiv = styled(`div`)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginTop: 39,
}));
const CancelBtn = styled(Button)(() => ({
  borderRadius: 4,
  width: 140,
  height: 40,
  backgroundColor: colors.gray._20,
  fontWeight: 500,
  fontSize: 16,
  color: colors.white,
  boxShadow: "none",
  ":hover": {
    background: colors.gray._30,
  },
}));

const CertificateBtn = styled(Button)(() => ({
  borderRadius: 4,
  width: 140,
  height: 40,
  background: colors.purple._60,
  fontWeight: 500,
  fontSize: 16,
  color: colors.white,
  boxShadow: "none",
  ":hover": {
    background: colors.purple._80,
  },
}));
