// /**
//  * If the code is "NotAuthorizedException", return "이메일 주소 혹은 비밀번호가 맞지 않습니다."; otherwise, return the
//  * message
//  * @param {string | undefined} code - The error code returned by the service.
//  * @param {string | undefined} message - The error message returned by the API.
//  * @returns A function that takes two parameters, code and message, and returns a string.
//  */
// export function messageInKO(
//   code: string | undefined,
//   message: string | undefined,
// ) {
//   if (code === "NotAuthorizedException") {
//     return "입력된 아이디 혹은 비밀번호가 올바르지 않습니다.";
//   }
//   if (code === "UserNotConfirmedException") {
//     return "이메일 인증이 필요합니다.";
//   }
//   if (code === "UserNotFoundException") {
//     return "입력된 정보와 일치하는 계정이 없습니다.";
//   }
//   return message || "";
// }

import { CustomerRole, GetMyInfoQuery } from "generated/types";

// export function messageInKOChangePassword(
//   code: string | undefined,
//   message: string | undefined,
// ) {
//   if (code === "NotAuthorizedException") {
//     return "기존 비밀번호가 일치하지 않습니다.";
//   }
//   if (code === "UserNotConfirmedException") {
//     return "이메일 인증이 필요합니다.";
//   }
//   if (code === "UserNotFoundException") {
//     return "이메일을 확인해 주세요. 사용자가 없습니다.";
//   }
//   return message || "";
// }

export const errorMessageKO = (message: string | undefined) => {
  if (message === "FIELD_ERROR") {
    return "입력된 아이디 혹은 비밀번호가 올바르지 않습니다.";
  }
  if (message === "WRONG_CREDENTIALS_ERROR")
    // return "입력된 정보와 일치하는 계정이 없습니다.";
    return "비밀번호가 틀렸습니다. 다시 시도하거나 비밀번호를 재설정하세요.";
  return "";
};
// /**
//  * If the code is "NotAuthorizedException", return "이메일 주소 혹은 비밀번호가 맞지 않습니다."; otherwise, return the
//  * message
//  * @param {string | undefined} code - The error code returned by the service.
//  * @param {string | undefined} message - The error message returned by the API.
//  * @returns A function that takes two parameters, code and message, and returns a string.
//  */
// export function messageInKO(
//   code: string | undefined,
//   message: string | undefined,
// ) {
//   if (code === "NotAuthorizedException") {
//     // return "입력된 아이디 혹은 비밀번호가 올바르지 않습니다.";
//     return "비밀번호가 틀립니다.";
//   }
//   if (code === "UserNotConfirmedException") {
//     return "이메일 인증이 필요합니다.";
//   }
//   if (code === "UserNotFoundException") {
//     return "비밀번호가 틀립니다.";
//   }
//   return message || "";
// };

export function getMyInfoData(data: GetMyInfoQuery | undefined) {
  if (!data) return defaultGetMyInfoData;
  const { me } = data;
  if (!me)
    return {
      ...defaultGetMyInfoData,
      error: true,
      message: "로그인 되지 않았습니다.",
    };
  const { customer, identity, email, userId, userHash, partnerId } = me;
  if (!customer)
    return {
      ...defaultGetMyInfoData,
      error: true,
      message: "고객 사용자가 아닙니다.",
    };
  const { name: organization, role, fieldName, id: customerId } = customer;
  return {
    error: false,
    message: "",
    name: identity?.name || "",
    email,
    phone: identity?.phoneNumber || "",
    organization: organization || "",
    field: role,
    fieldName: fieldName || "",
    customerId,
    identity: identity?.uniqueKey || "",
    userId: userId || "",
    userHash: userHash || "",
    partnerId,
  };
}

const defaultGetMyInfoData = {
  error: false,
  message: "",
  name: "",
  email: "",
  phone: "",
  organization: "",
  field: CustomerRole.Design,
  fieldName: "",
  customerId: null,
  identity: "",
  userId: "",
  userHash: "",
  partnerId: null,
};

export type GetMyInfoData = ReturnType<typeof getMyInfoData>;
