/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { colors, theme } from "@builderhub/mui-theme";
import { Box, Button, Typography, styled } from "@mui/material";

export const Wrap = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  position: "relative",
}));

export const StyledVideo = styled("video")(() => ({
  width: "100%",
  height: "100%",
  maxHeight: "100vh",
  minHeight: "100vh",
  minWidth: "100vw",
  objectFit: "cover",
  [theme.breakpoints.down("lg")]: {
    minHeight: "100vh",
    maxHeight: "100vh",
  },
}));
export const LogoBox = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,
}));
export const LogoTypo = styled(Typography)(() => ({
  fontSize: 20,
  letterSpacing: -0.3,
  color: colors.gray._80,
}));
export const Logo = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 16,
  ".logo": { height: "100%" },
  [theme.breakpoints.down("sm")]: {
    gap: 8,
    ".logo": { height: 32, width: 32, margin: 0 },
    ".name": { height: 18, width: "auto" },
  },
}));

export const TextBox = styled(Box)(() => ({
  width: "100%",
  background: colors.grayScale.offWhite,
  padding: "160px 16px",
  [theme.breakpoints.down("lg")]: {
    padding: "96px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "96px 20px",
  },
}));
export const TextWrap = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
  gap: 32,
  [theme.breakpoints.down("lg")]: {
    gap: 20,
  },
  [theme.breakpoints.down("sm")]: {
    gap: 16,
  },
}));
export const MainTypo = styled(Box)(() => ({
  fontSize: 32,
  fontWeight: 700,
  color: colors.gray._100,
  span: { color: colors.purple._60 },
  // br: { display: "none" },
  p: {
    fontSize: 28,
    fontWeight: 400,
    color: colors.gray._80,
    margin: 0,
    marginBottom: 8,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 24,
    p: { fontSize: 20 },
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
    br: { display: "block" },
    p: { fontSize: 16 },
  },
}));
export const SubTypo = styled(Typography)(() => ({
  fontSize: 24,
  color: colors.gray._70,
  letterSpacing: -0.3,
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
}));

export const BtnBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "28px",
  marginTop: 64,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
    gap: "1em",
  },
}));
const Btn = styled(Button)(() => ({
  padding: `10px 20px`,
  fontSize: 16,
  fontWeight: 700,
  borderRadius: "0.5em",
  boxShadow: `0px 4px 16px 0px rgba(102, 74, 227, 0.15)`,
  width: 224,
  height: 64,
  ":hover": { background: "none" },
  [theme.breakpoints.down("sm")]: {
    width: 200,
    fontSize: "0.875rem",
    borderRadius: "0.25rem",
  },
}));
export const CustomerBtn = styled(Btn)(() => ({
  background: colors.white,
  color: colors.purple._60,
  border: `2px solid ${colors.purple._60}`,
  ":hover": {
    border: `2px solid ${colors.purple._80}`,
    boxShadow: `0px 2px 8px 0px rgba(102, 74, 227, 0.15)`,
    background: colors.gray._10,
  },
}));
export const CurationBtn = styled(Btn)(() => ({
  background: colors.purple._60,
  color: colors.white,
  boxShadow: "0px 4px 16px 0px rgba(102, 74, 227, 0.30)",
  ":hover": {
    background: colors.purple._80,
    boxShadow: `0px 2px 8px 0px rgba(102, 74, 227, 0.30)`,
  },
}));
