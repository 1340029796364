import React from "react";
import { SVGProps } from "react";

export const GoodIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4811_1738)">
      <path
        d="M17.1317 11.0763L18.1365 8.14375C18.5013 7.07875 17.6209 6 16.3895 6H15.8048C15.1939 6 14.6217 6.27625 14.2831 6.73625L10.6412 11.6762C10.5528 11.7975 10.5044 11.9387 10.5044 12.0837V23.2638C10.5044 23.67 10.8679 23.9988 11.3171 23.9988H21.1519C22.4165 23.9988 23.4918 23.1625 23.6812 22.0325L24.9403 14.5225C25.1752 13.1225 23.9769 11.8612 22.411 11.8612H17.7716C17.3196 11.8612 16.9976 11.465 17.1303 11.075L17.1317 11.0763Z"
        fill="#D4D3D7"
      />
      <path
        d="M8.4505 11.8625H6.81269C6.36385 11.8625 6 12.1916 6 12.5975V23.265C6 23.671 6.36385 24 6.81269 24H8.4505C8.89933 24 9.26319 23.671 9.26319 23.265V12.5975C9.26319 12.1916 8.89933 11.8625 8.4505 11.8625Z"
        fill="#D4D3D7"
      />
    </g>
    <defs>
      <clipPath id="clip0_4811_1738">
        <rect
          width="19.9385"
          height="18"
          fill="white"
          transform="translate(6 6)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const BadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4811_1739)">
      <path
        d="M17.1317 20.9237L18.1365 23.8562C18.5013 24.9212 17.6209 26 16.3895 26L15.8048 26C15.1939 26 14.6217 25.7237 14.2831 25.2637L10.6412 20.3238C10.5528 20.2025 10.5044 20.0613 10.5044 19.9163L10.5044 8.73625C10.5044 8.33 10.8679 8.00125 11.3171 8.00125L21.1519 8.00125C22.4165 8.00125 23.4918 8.8375 23.6812 9.9675L24.9403 17.4775C25.1752 18.8775 23.9769 20.1387 22.411 20.1387L17.7716 20.1387C17.3196 20.1387 16.9976 20.535 17.1303 20.925L17.1317 20.9237Z"
        fill="#D4D3D7"
      />
      <path
        d="M8.4505 20.1375L6.81269 20.1375C6.36385 20.1375 6 19.8084 6 19.4025L6 8.73495C6 8.32902 6.36385 7.99995 6.81269 7.99995L8.4505 7.99995C8.89933 7.99995 9.26318 8.32902 9.26318 8.73495L9.26319 19.4025C9.26319 19.8084 8.89933 20.1375 8.4505 20.1375Z"
        fill="#D4D3D7"
      />
    </g>
    <defs>
      <clipPath id="clip0_4811_1739">
        <rect
          width="19.9385"
          height="18"
          fill="white"
          transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 6 26)"
        />
      </clipPath>
    </defs>
  </svg>
);
