import { useUpdateMeMutation } from "generated/types";
import moment from "moment";
import React from "react";
import { useAppSelector } from "store";

export function useUpdateMe(onCompleted?: { (): void }) {
  const authState = useAppSelector((state) => state.auth);
  const [updateMe] = useUpdateMeMutation({
    onCompleted() {
      onCompleted ? onCompleted() : console.log();
    },
    onError(e) {
      console.log(e);
    },
  });
  React.useEffect(() => {
    const { name, gender, birth, phone, identity, success } = authState;
    if (success && identity) {
      updateMe({
        variables: {
          data: {
            identity: {
              connectOrCreate: {
                where: { uniqueKey: identity },
                create: {
                  uniqueKey: identity,
                  name: name,
                  birth: moment(birth).toISOString(),
                  phoneNumber: phone,
                  verified: true,
                  gender,
                },
              },
            },
          },
        },
      });
    }
  }, [authState, updateMe]);
}
