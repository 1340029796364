import { colors, theme } from "@builderhub/mui-theme";
import { Box, Button, IconButton, styled } from "@mui/material";

export const ScrollBox = styled(Box)(() => ({
  position: "absolute",
  bottom: 48,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column-reverse",
  },
}));

export const SnsBtn = styled(Button)(() => ({
  color: colors.white,
  borderRadius: 8,
  width: 144,
  height: 48,
  padding: "12px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textShadow: "0px 0px 8px rgba(0, 9, 87, 0.60)",
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: -0.3,
  svg: {
    filter: "drop-shadow(0px 0px 8px #000)",
  },
  ":not(:hover)": {
    "svg path": {
      fill: colors.white,
    },
    ".point": {
      fill: colors.black,
    },
  },
  ":hover": {
    background: "rgba(38, 35, 56, 0.50)",
    ".point": {
      fill: colors.white,
    },
  },
}));
export const DividerBox = styled(Box)(() => ({
  display: "flex",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const SnsIconBtn = styled(IconButton)(() => ({
  background: "rgba(255, 255, 255, 0.20)",
  width: 48,
  height: 48,
  svg: {
    filter: "drop-shadow(0px 0px 8px #000)",
  },
  ":not(:hover)": {
    "svg path": {
      fill: colors.white,
    },
    ".point": {
      fill: colors.black,
    },
  },
  ":hover": {
    background: "rgba(38, 35, 56, 0.50)",
    ".point": {
      fill: colors.white,
    },
  },
}));
export const PcBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 32,
  visibility: "visible",
  [theme.breakpoints.down("lg")]: {
    visibility: "hidden",
  },
}));
export const MobileBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 28,
  visibility: "hidden",
  [theme.breakpoints.down("lg")]: {
    visibility: "visible",
  },
}));
