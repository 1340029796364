import * as React from "react";
import { SVGProps } from "react";

export const ClearIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_225_1603)">
      <path
        d="M2.26109 17.7353C2.61423 18.0781 3.19944 18.0781 3.5425 17.7353L10 11.2818L16.4575 17.7353C16.8006 18.0781 17.3959 18.0882 17.7389 17.7353C18.082 17.3823 18.082 16.8076 17.7389 16.4647L11.2814 10.0012L17.7389 3.54779C18.082 3.20496 18.0921 2.62011 17.7389 2.27727C17.3858 1.92435 16.8006 1.92435 16.4575 2.27727L10 8.73072L3.5425 2.27727C3.19944 1.92435 2.60414 1.91427 2.26109 2.27727C1.91803 2.63019 1.91803 3.20496 2.26109 3.54779L8.7186 10.0012L2.26109 16.4647C1.91803 16.8076 1.90794 17.3924 2.26109 17.7353Z"
        fill="#A8A7AF"
      />
    </g>
    <defs>
      <clipPath id="clip0_225_1603">
        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);
