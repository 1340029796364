"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRuntimeVariable = void 0;
var getRuntimeVariable = function (key) {
    if (typeof window !== "undefined")
        return window.__ENV ? window.__ENV[key] : undefined;
    if (typeof process === "undefined")
        return undefined;
    return process.env[key];
};
exports.getRuntimeVariable = getRuntimeVariable;
