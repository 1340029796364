import React from "react";
import { SnackbarProvider } from "notistack";
import { Loader } from "components";
import CssBaseline from "@mui/material/CssBaseline";
import { ApolloClientProvider } from "../ApolloProvider";
import { AuthProvider } from "../AuthProvider";
import { BuilderhubThemeProvider, colors } from "@builderhub/mui-theme";

export function RootProvider({ children }: { children: React.ReactNode }) {
  return (
    <React.Suspense fallback={<Loader />}>
      <ApolloClientProvider>
        <BuilderhubThemeProvider>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            style={{
              backgroundColor: colors.purple._60,
              padding: "8px 40px",
              borderRadius: 8,
            }}
          >
            <AuthProvider>{children}</AuthProvider>
          </SnackbarProvider>
        </BuilderhubThemeProvider>
      </ApolloClientProvider>
    </React.Suspense>
  );
}
