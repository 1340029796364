import { colors } from "@builderhub/mui-theme";
import {
  Wrap,
  StyledVideo,
  LogoBox,
  LogoTypo,
  Logo,
  BtnBox,
  CustomerBtn,
  CurationBtn,
} from "./styles";
import { BuilderhubLogo, BuilderhubLogoIcon, HiddenAnchor } from "components";
import { useTranslation } from "next-i18next";
import { PropType } from "./types";
import { SnsBox } from "./Sns";
import { getRuntimeVariable } from "@builderhub/env";

export const MainPresenter = (props: PropType) => {
  const { t } = useTranslation("common");
  const { goCuration, goCustomer, ...rest } = props;
  return (
    <Wrap>
      <StyledVideo
        id="video"
        muted
        preload="preload"
        poster="/main/start.jpg"
        autoPlay
        loop
        playsInline
      >
        <source src="/main/home_main.mp4" type="video/mp4" />,
      </StyledVideo>
      <LogoBox>
        <LogoTypo>{t("Archi3D")}</LogoTypo>
        <Logo>
          <BuilderhubLogoIcon width={54} height={54} className="logo" />
          <BuilderhubLogo
            fill={colors.gray._100}
            height={32}
            width={267}
            className="name"
          />
        </Logo>
        <BtnBox>
          <HiddenAnchor
            href={getRuntimeVariable("NEXT_PUBLIC_CUSTOMER_URL")}
            target="_self"
          >
            {t("smartInteg")}
          </HiddenAnchor>
          <CustomerBtn onClick={goCustomer}>{t("smartInteg")}</CustomerBtn>
          <HiddenAnchor
            href={`${getRuntimeVariable(
              "NEXT_PUBLIC_CURATION_URL",
            )}/project/tester`}
            target="_self"
          >
            {t("explore3D")}
          </HiddenAnchor>
          <CurationBtn onClick={goCuration}>{t("explore3D")}</CurationBtn>
        </BtnBox>
      </LogoBox>
      <SnsBox {...rest} />
    </Wrap>
  );
};
