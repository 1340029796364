import * as React from "react";
import { SVGProps } from "react";

export const BuilderhubLogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ? props.width : 22}
    height={props.height ? props.height : 22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.6176 0H3.38251C1.5144 0 0 1.5144 0 3.3825V18.6175C0 20.4856 1.5144 22 3.38251 22H18.6176C20.4857 22 22.0001 20.4856 22.0001 18.6175V3.3825C22.0001 1.5144 20.4857 0 18.6176 0Z"
      fill="#6649E2"
    />
    <path
      d="M7.22598 5.36011H4.95875C4.79168 5.36011 4.65625 5.49554 4.65625 5.66261V16.354C4.65625 16.5211 4.79168 16.6565 4.95875 16.6565H7.22598C7.39305 16.6565 7.52848 16.5211 7.52848 16.354V5.66261C7.52848 5.49554 7.39305 5.36011 7.22598 5.36011Z"
      fill="white"
    />
    <path
      d="M18.1132 14.6368H16.0782C16.1301 14.6368 16.2615 14.4504 16.2982 14.4107C16.3623 14.3404 16.4173 14.264 16.4693 14.1815C16.9582 13.4207 16.9918 12.4368 16.6923 11.6026C16.3623 10.6829 15.629 10.1023 14.6971 9.84872C14.7521 9.864 14.8865 9.78455 14.9354 9.76011C15.7115 9.35983 15.8154 8.26594 15.6351 7.51122C15.5648 7.21788 15.4457 6.93372 15.2776 6.68316C14.9904 6.2615 14.5809 5.93455 14.0462 5.70538C13.5115 5.47622 12.8698 5.36011 12.1243 5.36011H8.60426C8.43926 5.36011 8.30176 5.49455 8.30176 5.66261V7.0865C8.30176 7.2515 8.4362 7.389 8.60426 7.389H11.3573C11.837 7.389 12.2159 7.499 12.4909 7.72205C12.7659 7.94511 12.9065 8.25066 12.9065 8.64177C12.9065 8.64177 12.934 9.10011 12.5123 9.44233C12.222 9.67761 11.7209 9.67761 11.1465 9.67761H8.60731C8.44231 9.67761 8.30481 9.81205 8.30481 9.98011V11.1932C8.30481 11.3582 8.43926 11.4957 8.60731 11.4957H11.9012C12.2373 11.4957 12.5857 11.5262 12.9065 11.6484C13.2609 11.7859 13.6459 12.1098 13.7559 12.4857C13.9301 13.0754 13.869 13.8637 13.3465 14.264C13.0318 14.5054 12.5796 14.6276 11.9898 14.6276H8.61037C8.44537 14.6276 8.30787 14.7621 8.30787 14.9301V16.354C8.30787 16.519 8.44231 16.6565 8.61037 16.6565H18.1254C18.2904 16.6565 18.4279 16.5221 18.4279 16.354V14.9362C18.4279 14.7712 18.2935 14.6337 18.1254 14.6337L18.1132 14.6368Z"
      fill="white"
    />
  </svg>
);

export const BuilderhubLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ? props.width : 135}
    height={props.height ? props.height : 16}
    viewBox="0 0 135 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.198 8.06709C9.62921 7.72293 8.95966 7.51222 8.19292 7.43847V7.39633C8.76528 7.31556 9.28004 7.10836 9.73 6.77474C10.18 6.44111 10.5363 6.02671 10.7991 5.53154C11.0583 5.03637 11.1915 4.51311 11.1915 3.96175V3.94068C11.1915 3.147 10.9971 2.45517 10.6047 1.86167C10.2124 1.26817 9.66161 0.81163 8.94886 0.48854C8.23612 0.165451 7.40099 0.00390625 6.43987 0.00390625H0V15.737H6.47227C7.62057 15.737 8.60329 15.5579 9.42402 15.2032C10.2448 14.8485 10.8747 14.3392 11.3175 13.6755C11.7602 13.0153 11.9834 12.2216 11.9834 11.298V11.2769C11.9834 10.5781 11.8286 9.95646 11.519 9.40511C11.2095 8.85726 10.7703 8.40774 10.2016 8.06358L10.198 8.06709ZM5.83152 1.99863C6.74224 1.99863 7.44419 2.19178 7.93735 2.57809C8.4341 2.96439 8.68248 3.51926 8.68248 4.24621V4.26728C8.68248 5.05393 8.39091 5.65797 7.81136 6.07939C7.2318 6.50081 6.38947 6.71152 5.28437 6.71152H2.50179V1.99863H5.83152V1.99863ZM9.40963 11.1821C9.40963 12.0179 9.11445 12.6536 8.5277 13.089C7.93735 13.5245 7.08422 13.7422 5.96831 13.7422H2.50179V8.59387H5.78832C6.58386 8.59387 7.2534 8.68869 7.78976 8.88184C8.32611 9.07499 8.72928 9.35945 9.00286 9.73873C9.27643 10.118 9.40963 10.5886 9.40963 11.1575V11.1786V11.1821Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M24.6832 10.0123C24.6832 10.7743 24.5285 11.4416 24.2153 12.014C23.9021 12.5864 23.4485 13.0289 22.851 13.3485C22.2534 13.6681 21.5299 13.8296 20.6696 13.8296C19.8092 13.8296 19.0857 13.6681 18.4845 13.3485C17.8834 13.0289 17.4262 12.5829 17.1095 12.014C16.7927 11.4416 16.6343 10.7778 16.6343 10.0123V0H14.1289V10.2089C14.1289 11.3573 14.3917 12.3687 14.9208 13.2396C15.45 14.1106 16.2023 14.7884 17.1779 15.273C18.1534 15.7576 19.3161 15.9999 20.666 15.9999C22.0159 15.9999 23.1786 15.7576 24.1541 15.2695C25.1296 14.7813 25.8819 14.1036 26.4075 13.2361C26.9331 12.3687 27.1958 11.3573 27.1958 10.2089V0H24.6796V10.0123H24.6832Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M32.6623 0H30.1562V15.7366H32.6623V0Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M38.1382 0H35.6328V15.7366H45.6616V13.6224H38.1382V0Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M57.5953 0.924009C56.4578 0.309437 55.0899 0.00390625 53.488 0.00390625H47.7969V15.737H53.488C55.0971 15.737 56.4722 15.4279 57.6061 14.8028C58.7436 14.1812 59.6111 13.2787 60.2159 12.0987C60.8206 10.9187 61.123 9.49641 61.123 7.83882V7.81775C61.123 6.16016 60.8206 4.7484 60.2123 3.58598C59.6039 2.42357 58.7328 1.53507 57.5953 0.920497V0.924009ZM58.5636 7.86692C58.5636 9.10309 58.3584 10.1496 57.948 11.0065C57.5377 11.8634 56.9365 12.5166 56.1482 12.9591C55.3599 13.4016 54.3915 13.6228 53.254 13.6228H50.3023V2.11803H53.254C54.3951 2.11803 55.3563 2.33928 56.1446 2.77826C56.9293 3.21724 57.5305 3.86342 57.9444 4.72031C58.3584 5.57369 58.5636 6.6167 58.5636 7.84585V7.86692V7.86692Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M63.5117 15.7366H73.7529V13.6224H66.0171V8.748H73.3389V6.69708H66.0171V2.11764H73.7529V0H63.5117V15.7366Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M87.1383 7.64528C87.6927 6.84458 87.9699 5.91043 87.9699 4.84283V4.82176C87.9699 3.83142 87.7503 2.97804 87.3147 2.25811C86.8792 1.53819 86.26 0.983315 85.4537 0.589989C84.6473 0.196663 83.697 0 82.6027 0H76.332V15.7366H78.8374V9.70673H82.2823L85.5689 15.7366H88.463L84.8525 9.32394C85.8209 9.00436 86.584 8.44598 87.1383 7.64528V7.64528ZM82.3579 7.68742H78.8374V2.05091H82.2823C83.2435 2.05091 84.003 2.30026 84.5574 2.79894C85.1117 3.29762 85.3889 3.97892 85.3889 4.84283V4.8639C85.3889 5.76644 85.1225 6.46179 84.5969 6.95344C84.0678 7.4451 83.3227 7.69093 82.3615 7.69093L82.3579 7.68742Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M101.492 6.65143H93.2202V0H90.7148V15.7366H93.2202V8.76907H101.492V15.7366H104.009V0H101.492V6.65143Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M117.531 10.0123C117.531 10.7743 117.376 11.4416 117.063 12.014C116.75 12.5864 116.296 13.0289 115.699 13.3485C115.101 13.6681 114.374 13.8296 113.517 13.8296C112.66 13.8296 111.933 13.6681 111.332 13.3485C110.731 13.0289 110.274 12.5829 109.957 12.014C109.64 11.4416 109.482 10.7778 109.482 10.0123V0H106.977V10.2089C106.977 11.3573 107.239 12.3687 107.768 13.2396C108.298 14.1106 109.05 14.7884 110.026 15.273C111.001 15.7576 112.164 15.9999 113.514 15.9999C114.864 15.9999 116.026 15.7576 117.002 15.2695C117.977 14.7813 118.73 14.1036 119.255 13.2361C119.781 12.3687 120.043 11.3573 120.043 10.2089V0H117.527V10.0123H117.531Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M134.531 9.40862C134.221 8.86077 133.782 8.41125 133.214 8.06709C132.645 7.72293 131.975 7.51222 131.209 7.43847V7.39633C131.781 7.31556 132.296 7.10836 132.746 6.77474C133.196 6.44111 133.552 6.02671 133.815 5.53154C134.074 5.03637 134.207 4.51311 134.207 3.96175V3.94068C134.207 3.147 134.013 2.45517 133.62 1.86167C133.228 1.26817 132.677 0.81163 131.964 0.48854C131.252 0.165451 130.417 0.00390625 129.455 0.00390625H123.016V15.737H129.488C130.636 15.737 131.619 15.5579 132.44 15.2032C133.26 14.8485 133.89 14.3392 134.333 13.6755C134.776 13.0153 134.999 12.2216 134.999 11.298V11.2769C134.999 10.5781 134.844 9.95646 134.535 9.40511L134.531 9.40862ZM128.847 1.99863C129.758 1.99863 130.46 2.19178 130.953 2.57809C131.45 2.96439 131.698 3.51926 131.698 4.24621V4.26728C131.698 5.05393 131.407 5.65797 130.827 6.07939C130.247 6.50081 129.405 6.71152 128.3 6.71152H125.517V1.99863H128.847V1.99863ZM132.425 11.1821C132.425 12.0179 132.13 12.6536 131.543 13.089C130.953 13.5245 130.1 13.7422 128.984 13.7422H125.517V8.59387H128.804C129.599 8.59387 130.269 8.68869 130.805 8.88184C131.342 9.07499 131.745 9.35945 132.018 9.73873C132.292 10.118 132.425 10.5886 132.425 11.1575V11.1786V11.1821Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
  </svg>
);
export const PartnerLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="104"
    height="16"
    viewBox="0 0 104 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.20839 10.4455H5.8104C6.82355 10.4455 7.71184 10.235 8.47528 9.81405C9.24585 9.38595 9.84875 8.79019 10.284 8.02676C10.7192 7.25619 10.9368 6.36789 10.9368 5.36187V5.34047C10.9368 4.33445 10.7192 3.45329 10.284 2.69699C9.84875 1.93356 9.24585 1.34136 8.47528 0.920401C7.71184 0.492308 6.82355 0.278261 5.8104 0.278261H1.20839V2.301H5.21106C6.25276 2.301 7.059 2.56856 7.62979 3.10368C8.20058 3.6388 8.48598 4.39153 8.48598 5.36187V5.38328C8.48598 6.33935 8.20058 7.08852 7.62979 7.63077C7.059 8.16589 6.25276 8.43344 5.21106 8.43344H1.20839V10.4455ZM-0.000976562 15.7217H2.40705V0.278261H-0.000976562V15.7217Z"
      fill="#664AE3"
    />
    <path
      d="M10.3839 15.7217H12.9096L17.2548 2.86823H17.9397V0.278261H15.9919L10.3839 15.7217ZM13.1023 11.5478H21.4822L20.7865 9.6214H13.7979L13.1023 11.5478ZM21.6641 15.7217H24.1899L18.5926 0.278261H17.3297V2.86823L21.6641 15.7217Z"
      fill="#664AE3"
    />
    <path
      d="M25.6062 15.7217H28.0142V9.79264H31.1928C31.2142 9.79264 31.2356 9.79264 31.257 9.79264C31.2784 9.79264 31.2998 9.79264 31.3212 9.79264L34.4677 15.7217H37.2396L33.7827 9.42876C34.7103 9.10769 35.4416 8.55474 35.9767 7.7699C36.5118 6.98506 36.7794 6.06823 36.7794 5.0194V4.99799C36.7794 4.03478 36.5689 3.2 36.148 2.49365C35.727 1.78729 35.1312 1.24147 34.3607 0.856187C33.5972 0.470903 32.6875 0.278261 31.6316 0.278261H25.6062V15.7217ZM28.0142 7.82341V2.2903H31.3212C32.2416 2.2903 32.9658 2.53645 33.4938 3.02876C34.0289 3.51394 34.2965 4.18462 34.2965 5.0408V5.06221C34.2965 5.9398 34.0432 6.62118 33.5366 7.10635C33.03 7.58439 32.3165 7.82341 31.3961 7.82341H28.0142Z"
      fill="#664AE3"
    />
    <path
      d="M42.68 15.7217H45.0773V2.35452H49.8291V0.278261H37.9281V2.35452H42.68V15.7217Z"
      fill="#664AE3"
    />
    <path
      d="M51.4916 15.7217H53.8782V4.33445H54.9484L52.5725 2.34381L61.8942 15.7217H64.0989V0.278261H61.723V11.6441H60.6528L63.0287 13.6348L53.7069 0.278261H51.4916V15.7217Z"
      fill="#664AE3"
    />
    <path
      d="M66.6817 15.7217H76.4958V13.6455H69.0898V8.86154H76.0998V6.8495H69.0898V2.35452H76.4958V0.278261H66.6817V15.7217Z"
      fill="#664AE3"
    />
    <path
      d="M78.6826 15.7217H81.0907V9.79264H84.2693C84.2907 9.79264 84.3121 9.79264 84.3335 9.79264C84.3549 9.79264 84.3763 9.79264 84.3977 9.79264L87.5442 15.7217H90.3161L86.8592 9.42876C87.7868 9.10769 88.5181 8.55474 89.0532 7.7699C89.5883 6.98506 89.8559 6.06823 89.8559 5.0194V4.99799C89.8559 4.03478 89.6454 3.2 89.2244 2.49365C88.8035 1.78729 88.2077 1.24147 87.4372 0.856187C86.6737 0.470903 85.764 0.278261 84.7081 0.278261H78.6826V15.7217ZM81.0907 7.82341V2.2903H84.3977C85.3181 2.2903 86.0423 2.53645 86.5703 3.02876C87.1054 3.51394 87.3729 4.18462 87.3729 5.0408V5.06221C87.3729 5.9398 87.1197 6.62118 86.6131 7.10635C86.1065 7.58439 85.393 7.82341 84.4726 7.82341H81.0907Z"
      fill="#664AE3"
    />
    <path
      d="M97.1692 16C98.3892 16 99.438 15.8109 100.316 15.4328C101.2 15.0546 101.878 14.5195 102.349 13.8274C102.827 13.1282 103.066 12.3041 103.066 11.3552V11.3445C103.066 10.5596 102.906 9.8854 102.585 9.32174C102.271 8.75808 101.778 8.28718 101.108 7.90903C100.437 7.53088 99.5665 7.22765 98.4962 6.99933L96.816 6.64615C95.8456 6.43924 95.1357 6.15028 94.6862 5.77926C94.2367 5.40825 94.012 4.93378 94.012 4.35585V4.34515C94.012 3.88852 94.1404 3.49253 94.3972 3.15719C94.6541 2.82185 95.018 2.56143 95.4889 2.37592C95.9598 2.18328 96.5092 2.08696 97.137 2.08696C97.7578 2.08696 98.3 2.17971 98.7638 2.36522C99.2276 2.55072 99.6021 2.81115 99.8876 3.14649C100.173 3.48183 100.351 3.88852 100.423 4.36656L100.433 4.50569H102.777L102.766 4.33445C102.709 3.47826 102.442 2.72553 101.964 2.07625C101.486 1.41984 100.836 0.909699 100.016 0.545819C99.2026 0.18194 98.2572 0 97.1799 0C96.0882 0 95.1214 0.189075 94.2795 0.567224C93.4376 0.938239 92.7776 1.45909 92.2996 2.12977C91.8215 2.80045 91.579 3.57815 91.5718 4.46288V4.47358C91.5718 5.60803 91.9393 6.532 92.6742 7.24548C93.4162 7.95897 94.5471 8.47982 96.0668 8.80803L97.7471 9.1612C98.7745 9.38239 99.5094 9.67848 99.9518 10.0495C100.401 10.4205 100.626 10.9128 100.626 11.5264V11.5371C100.626 12.0152 100.487 12.4326 100.209 12.7893C99.9375 13.146 99.5486 13.4243 99.0421 13.6241C98.5426 13.8167 97.9504 13.913 97.2655 13.913C96.5805 13.913 95.9705 13.8203 95.4354 13.6348C94.9074 13.4421 94.4829 13.1746 94.1618 12.8321C93.8407 12.4896 93.6445 12.0829 93.5732 11.612L93.5518 11.4836H91.2079L91.2187 11.6334C91.2829 12.5324 91.5683 13.3101 92.0748 13.9666C92.5814 14.6158 93.2664 15.1188 94.1297 15.4756C95.0001 15.8252 96.0133 16 97.1692 16Z"
      fill="#664AE3"
    />
  </svg>
);
export const PartnerLogoApp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ? props.width : 90}
    height={props.height ? props.height : 14}
    viewBox="0 0 90 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.05604 9.1398H5.07459C5.9593 9.1398 6.73497 8.95563 7.40161 8.58729C8.07449 8.21271 8.60095 7.69142 8.981 7.02341C9.36105 6.34916 9.55107 5.57191 9.55107 4.69164V4.67291C9.55107 3.79264 9.36105 3.02163 8.981 2.35987C8.60095 1.69186 8.07449 1.17369 7.40161 0.805351C6.73497 0.430769 5.9593 0.243478 5.07459 0.243478H1.05604V2.01338H4.55124C5.46087 2.01338 6.1649 2.24749 6.66332 2.71572C7.16175 3.18395 7.41096 3.84259 7.41096 4.69164V4.71037C7.41096 5.54693 7.16175 6.20245 6.66332 6.67692C6.1649 7.14515 5.46087 7.37926 4.55124 7.37926H1.05604V9.1398ZM0 13.7565H2.10273V0.243478H0V13.7565Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M9.06822 13.7565H11.2738L15.068 2.5097H15.6661V0.243478H13.9652L9.06822 13.7565ZM11.442 10.1043H18.7595L18.152 8.41873H12.0494L11.442 10.1043ZM18.9183 13.7565H21.1239L16.2362 0.243478H15.1334V2.5097L18.9183 13.7565Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M22.3606 13.7565H24.4633V8.56856H27.2389C27.2576 8.56856 27.2763 8.56856 27.295 8.56856C27.3137 8.56856 27.3324 8.56856 27.3511 8.56856L30.0986 13.7565H32.5191L29.5005 8.25017C30.3105 7.96923 30.9491 7.4854 31.4164 6.79866C31.8836 6.11193 32.1173 5.3097 32.1173 4.39197V4.37324C32.1173 3.53043 31.9335 2.8 31.5659 2.18194C31.1983 1.56388 30.6781 1.08629 30.0052 0.749164C29.3385 0.41204 28.5442 0.243478 27.6221 0.243478H22.3606V13.7565ZM24.4633 6.84549V2.00401H27.3511C28.1548 2.00401 28.7872 2.2194 29.2482 2.65017C29.7155 3.07469 29.9491 3.66154 29.9491 4.4107V4.42943C29.9491 5.19732 29.7279 5.79353 29.2856 6.21806C28.8432 6.63634 28.2202 6.84549 27.4165 6.84549H24.4633Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M37.2697 13.7565H39.3631V2.0602H43.5125V0.243478H33.1203V2.0602H37.2697V13.7565Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M44.9642 13.7565H47.0482V3.79264H47.9828L45.9081 2.05084L54.048 13.7565H55.9731V0.243478H53.8984V10.1886H52.9639L55.0386 11.9304L46.8987 0.243478H44.9642V13.7565Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M58.2285 13.7565H66.7983V11.9398H60.3312V7.75385H66.4525V5.99331H60.3312V2.0602H66.7983V0.243478H58.2285V13.7565Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M68.7079 13.7565H70.8106V8.56856H73.5862C73.6049 8.56856 73.6236 8.56856 73.6423 8.56856C73.661 8.56856 73.6797 8.56856 73.6984 8.56856L76.446 13.7565H78.8664L75.8478 8.25017C76.6578 7.96923 77.2964 7.4854 77.7637 6.79866C78.2309 6.11193 78.4646 5.3097 78.4646 4.39197V4.37324C78.4646 3.53043 78.2808 2.8 77.9132 2.18194C77.5456 1.56388 77.0254 1.08629 76.3525 0.749164C75.6859 0.41204 74.8915 0.243478 73.9694 0.243478H68.7079V13.7565ZM70.8106 6.84549V2.00401H73.6984C74.5021 2.00401 75.1345 2.2194 75.5955 2.65017C76.0628 3.07469 76.2964 3.66154 76.2964 4.4107V4.42943C76.2964 5.19732 76.0752 5.79353 75.6329 6.21806C75.1905 6.63634 74.5675 6.84549 73.7638 6.84549H70.8106Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
    <path
      d="M84.8506 14C85.916 14 86.8319 13.8346 87.5982 13.5037C88.3708 13.1728 88.9627 12.7046 89.3739 12.099C89.7913 11.4872 90 10.7661 90 9.93579V9.92642C90 9.23969 89.8598 8.64972 89.5795 8.15652C89.3053 7.66332 88.8754 7.25128 88.2898 6.9204C87.7041 6.58952 86.944 6.32419 86.0095 6.12441L84.5422 5.81538C83.6949 5.63434 83.075 5.38149 82.6825 5.05686C82.29 4.73222 82.0937 4.31706 82.0937 3.81137V3.80201C82.0937 3.40245 82.2059 3.05596 82.4302 2.76254C82.6545 2.46912 82.9722 2.24125 83.3834 2.07893C83.7946 1.91037 84.2743 1.82609 84.8226 1.82609C85.3646 1.82609 85.8381 1.90725 86.2431 2.06957C86.6481 2.23188 86.9752 2.45975 87.2244 2.75318C87.4736 3.0466 87.6294 3.40245 87.6917 3.82074L87.701 3.94247H89.7477L89.7383 3.79264C89.6885 3.04348 89.4548 2.38484 89.0374 1.81672C88.62 1.24236 88.053 0.795987 87.3365 0.477592C86.6263 0.159197 85.8008 0 84.86 0C83.9068 0 83.0625 0.16544 82.3274 0.496321C81.5922 0.820959 81.0159 1.2767 80.5985 1.86355C80.181 2.45039 79.9692 3.13088 79.963 3.90502V3.91438C79.963 4.90702 80.2838 5.7155 80.9255 6.3398C81.5735 6.9641 82.561 7.41984 83.8881 7.70702L85.3553 8.01605C86.2525 8.20959 86.8942 8.46867 87.2805 8.79331C87.673 9.11795 87.8692 9.54872 87.8692 10.0856V10.095C87.8692 10.5133 87.7477 10.8785 87.5048 11.1906C87.268 11.5028 86.9285 11.7463 86.4861 11.9211C86.05 12.0896 85.5329 12.1739 84.9348 12.1739C84.3366 12.1739 83.804 12.0928 83.3367 11.9304C82.8756 11.7619 82.5049 11.5278 82.2246 11.2281C81.9442 10.9284 81.7729 10.5726 81.7106 10.1605L81.6919 10.0482H79.6452L79.6546 10.1793C79.7106 10.9659 79.9598 11.6464 80.4022 12.2207C80.8446 12.7889 81.4427 13.229 82.1965 13.5411C82.9566 13.847 83.8413 14 84.8506 14Z"
      fill={props.fill ? props.fill : "#605E6B"}
    />
  </svg>
);

export const CustomBar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="2"
    height="13"
    viewBox="0 0 2 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 12C0.5 12.2761 0.723858 12.5 1 12.5C1.27614 12.5 1.5 12.2761 1.5 12H0.5ZM0.5 0V12H1.5V0H0.5Z"
      fill="#DBDBDB"
    />
  </svg>
);
export const CustomDot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="2" cy="2" r="2" fill="#664AE3" />
  </svg>
);

export const VerticalDotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="4"
    height="20"
    viewBox="0 0 4 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="2" cy="18" r="2" transform="rotate(-90 2 18)" fill="#D4D3D7" />
    <circle cx="2" cy="10" r="2" transform="rotate(-90 2 10)" fill="#D4D3D7" />
    <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#D4D3D7" />
  </svg>
);

export const VerticalBarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="2"
    height="162"
    viewBox="0 0 2 162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1L0.999994 161" stroke="#DBDBDB" strokeLinecap="round" />
  </svg>
);

export const HorizontalBarDotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="4"
    viewBox="0 0 20 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="2" cy="2" r="2" fill="#D4D3D7" />
    <circle cx="10" cy="2" r="2" fill="#D4D3D7" />
    <circle cx="18" cy="2" r="2" fill="#D4D3D7" />
  </svg>
);
