import { Box, BoxProps, styled } from "@mui/material";

const Wrapper = styled(Box)(() => ({
  width: 0,
  height: 0,
  a: { textDecoration: "none", display: "none" },
  display: "none",
}));

interface HiddenAnchor extends BoxProps {
  href: string;
  target?: string;
}

export function HiddenAnchor(props: HiddenAnchor): JSX.Element {
  const { children, href, target, ...restProps } = props;
  return (
    <Wrapper {...restProps}>
      <a href={href} target={target}>
        {children}
      </a>
    </Wrapper>
  );
}
