import * as React from "react";
import { SVGProps } from "react";

export const DrawerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3_2208)">
      <g clipPath="url(#clip1_3_2208)">
        <path
          d="M2.59318 17.0001H17.3989C17.7311 17.0001 18 16.5366 18 15.9505C18 15.3779 17.7311 14.928 17.3989 14.928H2.59318C2.261 14.928 2 15.3779 2 15.9505C2 16.5366 2.261 17.0001 2.59318 17.0001ZM8.19318 11.0429H17.3989C17.7311 11.0429 18 10.5931 18 10.0206C18 9.43439 17.7311 8.97091 17.3989 8.97091H8.19318C7.861 8.97091 7.6 9.43439 7.6 10.0206C7.6 10.5931 7.861 11.0429 8.19318 11.0429ZM2.59318 5.09944H17.3989C17.7311 5.09944 18 4.64958 18 4.07704C18 3.5045 17.7311 3.04102 17.3989 3.04102H2.59318C2.261 3.04102 2 3.5045 2 4.07704C2 4.64958 2.261 5.09944 2.59318 5.09944Z"
          fill="#7D7B88"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3_2208">
        <rect width="16" height="14" fill="white" transform="translate(2 3)" />
      </clipPath>
      <clipPath id="clip1_3_2208">
        <rect width="16" height="14" fill="white" transform="translate(2 3)" />
      </clipPath>
    </defs>
  </svg>
);
