import React from "react";
import { HiddenAnchor } from "components";
import { FlexDiv } from "../styles";
import { CustomerBtn, HelpBtn } from "./styles";
import { useTranslation } from "next-i18next";
import { LeftBoxPropType } from "./types";
import { getRuntimeVariable } from "@builderhub/env";

export const LeftBox = (props: LeftBoxPropType) => {
  const { goPartner, goCustomer, goHelp, path } = props;

  const { t } = useTranslation("common");
  return (
    <FlexDiv>
      <HiddenAnchor
        href={getRuntimeVariable("NEXT_PUBLIC_CUSTOMER_URL")}
        target="_self"
      >
        {t("smartInteg")}
      </HiddenAnchor>
      <CustomerBtn onClick={goCustomer}>{t("smartInteg")}</CustomerBtn>
      <HiddenAnchor
        href={getRuntimeVariable("NEXT_PUBLIC_PARTNER_URL")}
        target="_self"
      >
        {t("partners")}
      </HiddenAnchor>
      <CustomerBtn onClick={goPartner}>{t("partners")}</CustomerBtn>
      <HelpBtn path={path} onClick={goHelp}>
        고객센터
      </HelpBtn>
    </FlexDiv>
  );
};
