import * as React from "react";
import { SVGProps } from "react";

export const LeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 12.334C6 12.1361 6.07 11.954 6.21 11.8036L10.8514 6.57145C11.0054 6.39731 11.1524 6.33398 11.3204 6.33398C11.6634 6.33398 11.9294 6.61894 11.9294 7.01472C11.9294 7.2047 11.8735 7.38676 11.7614 7.5134L10.1933 9.31815L7.41411 12.1836L7.2741 11.7878L9.52826 11.6295H18.384C18.748 11.6295 19 11.9224 19 12.334C19 12.7456 18.748 13.0385 18.384 13.0385H9.52826L7.2741 12.8801L7.41411 12.4923L10.1933 15.3498L11.7614 17.1545C11.8735 17.2733 11.9294 17.4632 11.9294 17.6532C11.9294 18.049 11.6634 18.334 11.3204 18.334C11.1524 18.334 11.0054 18.2627 10.8654 18.1123L6.21 12.8643C6.07 12.7139 6 12.5319 6 12.334Z"
      fill="#7D7B88"
    />
  </svg>
);
