import { ArrowRight } from "components";
import {
  Container,
  Wrap,
  PartnerTypo,
  BannerWrap,
  LogoBox,
  LogoText,
} from "./styles";
import Image from "next/image";
import { useTranslation } from "next-i18next";

export const Partners = () => {
  const { t } = useTranslation("common");
  return (
    <Container>
      <Wrap>
        <PartnerTypo>{t("bhPartners")}</PartnerTypo>
        <BannerWrap>
          <LogoBox
            onClick={() =>
              window.open("https://hcorestore.hyundai-steel.com/", "_blank")
            }
          >
            <Image
              src={"/main/partners/hcore.png"}
              width={320}
              height={112}
              alt="hcore"
            />
            <LogoText>
              {t("visitHyundai")} <ArrowRight color="#0086BA" />
            </LogoText>
          </LogoBox>
        </BannerWrap>
      </Wrap>
    </Container>
  );
};
