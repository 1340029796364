import React from "react";
import { TopWrapper, MeritTypo, MeritTypoEnglish, SvgBox } from "./styles";
import { MeritBox } from "components";
import { useTranslation } from "next-i18next";
export const MeritTop = () => {
  const { t, i18n } = useTranslation("common");
  const { language } = i18n;
  return (
    <TopWrapper data-aos="fade-up">
      <SvgBox>
        <MeritBox />
        {language.includes("ko") ? (
          <MeritTypo>
            {t("bigCorp")}
            <br />
            {t("builderhub")} <span>{t("anshim")}</span>
            {t("hago")}
          </MeritTypo>
        ) : (
          <MeritTypoEnglish>
            {t("bigCorp")}
            <br />
            {t("hago")}
            <span>{t("anshim")} </span>
            <br />
            {t("builderhub")}
          </MeritTypoEnglish>
        )}
      </SvgBox>
    </TopWrapper>
  );
};
