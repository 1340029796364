import { TextBox, TextWrap, MainTypo, SubTypo } from "./styles";
import { useTranslation } from "next-i18next";

export const SubMain = () => {
  const { t, i18n } = useTranslation("common");
  const { language } = i18n;
  return (
    <TextBox>
      <TextWrap>
        <MainTypo>
          {language.includes("ko") ? (
            <>
              <p>{t("reliable1")}</p>
              <span>&quot;{t("reliableStrong")}&quot;</span>
              {t("reliable3")}
            </>
          ) : (
            <>
              <p>{t("reliable1")}</p>
              {t("reliable3")}
              <span> &quot;{t("reliableStrong")}&quot;</span>
            </>
          )}
        </MainTypo>
        <SubTypo>
          {t("complexRebar")}
          <br />
          {t("BOQ")}
        </SubTypo>
      </TextWrap>
    </TextBox>
  );
};
