import React from "react";
import {
  Wrapper,
  MiddleWrapper,
  MiddleContent,
  TitleTypo,
  SubTypo,
} from "./styles";
import { MeritTop } from "./MeritTop";
import { useTranslation } from "next-i18next";

export const Merit = () => {
  const { t } = useTranslation("common");
  return (
    <Wrapper>
      <MeritTop />
      <MiddleWrapper data-aos="fade-up">
        <MiddleContent>
          <TitleTypo>{t("replace2D")}</TitleTypo>
          <SubTypo>{t("replace2D2")}</SubTypo>
        </MiddleContent>
      </MiddleWrapper>
    </Wrapper>
  );
};
