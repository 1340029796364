/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { colors, theme } from "@builderhub/mui-theme";
import { Container, styled, Box, Typography } from "@mui/material";

///Merit
export const Wrapper = styled(Container)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: 1200,
  padding: "224px 16px 0",
  [theme.breakpoints.down("lg")]: {
    padding: "144px 40px 0",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "128px 20px 0",
  },
}));

export const MiddleWrapper = styled("div")(() => ({
  width: "90%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    img: {
      width: 102,
      height: 51,
    },
  },
  [theme.breakpoints.down("sm")]: {
    img: {
      width: 160,
      height: 80,
    },
  },
}));

export const LogoTypo = styled(Typography)(() => ({
  fontSize: 12,
  color: colors.gray._60,
  marginTop: 48,
  [theme.breakpoints.down("lg")]: {
    marginTop: 16,
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: 0,
  },
}));

export const TitleTypo = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 24,
  color: colors.gray._60,
  [theme.breakpoints.down("lg")]: {
    fontSize: 18,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));
export const SubTypo = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 32,
  color: colors.purple._60,
  marginTop: 12,
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    fontSize: 24,
    marginTop: 4,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
}));

/// MeritTop
export const TopWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  position: "relative",
  zIndex: 1,
  maxWidth: 820,
  padding: "0px 20px",
}));

/// MeritPapers
export const PaperWrap = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: 52,
  gap: 20,
  [theme.breakpoints.down("lg")]: {
    marginTop: 32,
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: 32,
  },
}));

export const MiddleContent = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 52,
  [theme.breakpoints.down("lg")]: {
    marginTop: 32,
  },
}));

export const PaperWrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const NumberBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 32,
  background: colors.purple._60,
  width: "fit-content",
  borderRadius: 8,
  marginBottom: -16,
  position: "relative",
  padding: "0 20px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 12px",
  },
}));
export const Number = styled(Typography)(() => ({
  fontSize: 20,
  color: colors.white,
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    fontSize: 12,
  },
}));
export const CardTypo = styled(Typography)(() => ({
  fontSize: 24,
  color: colors.gray._100,
  [theme.breakpoints.down("lg")]: {
    fontSize: 20,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));

export const PointTypo = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 24,
  color: colors.purple._60,
  whiteSpace: "pre-line",
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    fontSize: 20,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));

export const StyledPaper = styled(`div`)(() => ({
  width: "100%",
  maxWidth: 324,
  minHeight: 252,
  border: "4px solid #EFF0F0",
  borderRadius: 28,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: colors.white,
  boxShadow: "0px 12px 30px 5px rgba(235,235,255,1)",
  [theme.breakpoints.down("lg")]: {
    minHeight: 280,
    borderRadius: 18,
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: 140,
    // maxWidth: "100%",
    borderRadius: 12,
  },
}));
export const SvgBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    svg: {
      width: 600,
      height: 280,
    },
  },
  [theme.breakpoints.down("md")]: {
    svg: {
      width: 500,
      height: 255,
    },
  },
  [theme.breakpoints.down("sm")]: {
    svg: {
      width: 320,
      height: 160,
    },
  },
}));

export const MeritTypo = styled(Typography)(() => ({
  fontSize: 32,
  color: colors.gray._100,
  fontWeight: 700,
  letterSpacing: -0.3,
  span: {
    color: colors.purple._60,
  },
  position: "absolute",
  paddingBottom: 18,
  [theme.breakpoints.down("lg")]: {
    fontSize: 20,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
}));

export const MeritTypoEnglish = styled(Typography)(() => ({
  fontSize: 28,
  color: colors.gray._100,
  fontWeight: 700,
  letterSpacing: -0.3,
  span: {
    color: colors.purple._60,
  },
  position: "absolute",
  paddingBottom: 18,
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 8,
  },
}));
