import { colors } from "@builderhub/mui-theme";
import { Avatar, Box, Typography, styled } from "@mui/material";

export const FlexDiv = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));
export const CursorDiv = styled(FlexDiv)(() => ({
  cursor: "pointer",
}));
export const UserName = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
  color: colors.gray._60,
  letterSpacing: -0.3,
}));

export const ContentBox = styled(Box)(() => ({
  padding: 0,
  width: "100%",
  height: "auto",
  minHeight: "100vh",
}));

export const InfoAvatar = styled(Avatar)((props: { open: boolean }) => ({
  width: 32,
  height: 32,
  background: colors.gray._10,
  color: colors.gray._60,
  border: props.open
    ? `2px solid ${colors.purple._60}`
    : `1px solid ${colors.purple._10}`,
  fontWeight: 700,
  fontSize: 16,
  ":active": { border: `2px solid ${colors.purple._60} ` },
}));
