import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { HeaderRow } from "../Notice/styles";

export const Head = () => {
  return (
    <TableHead>
      <HeaderRow>
        <TableCell align="center" width={100}>
          번호
        </TableCell>
        <TableCell align="left">제목</TableCell>
        <TableCell
          align="center"
          width={144}
          sx={{ display: { xs: "none", sm: "table-cell" } }}
        >
          작성일
        </TableCell>
        <TableCell
          align="center"
          width={144}
          sx={{ display: { xs: "none", lg: "table-cell" } }}
        >
          조회수
        </TableCell>
      </HeaderRow>
    </TableHead>
  );
};
