import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any; }
  /** The `Byte` scalar type represents byte value as a Buffer */
  Bytes: { input: any; output: any; }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** An arbitrary-precision Decimal type */
  Decimal: { input: any; output: any; }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: { input: any; output: any; }
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: any; output: any; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

/** Access Log */
export interface AccessLog {
  __typename?: 'AccessLog';
  accessType: AccessType;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  thirdPartyId?: Maybe<ThirdPartyId>;
  thirdPartyUserId?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Member>;
  userId?: Maybe<Scalars['Int']['output']>;
}

export interface AccessLogAvgAggregateOutputType {
  __typename?: 'AccessLogAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
}

export interface AccessLogAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
}

export interface AccessLogCountAggregateOutputType {
  __typename?: 'AccessLogCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  accessType: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['Int']['output'];
  thirdPartyId: Scalars['Int']['output'];
  thirdPartyUserId: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
}

export interface AccessLogCountOrderByAggregateInput {
  accessType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  thirdPartyId?: InputMaybe<SortOrder>;
  thirdPartyUserId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
}

export interface AccessLogCreateInput {
  accessType: AccessType;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<MemberCreateNestedOneWithoutAccessLogInput>;
}

export interface AccessLogCreateManyInput {
  accessType: AccessType;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
}

export interface AccessLogCreateManyUserInput {
  accessType: AccessType;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
}

export interface AccessLogCreateManyUserInputEnvelope {
  data: AccessLogCreateManyUserInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface AccessLogCreateNestedManyWithoutUserInput {
  connect?: InputMaybe<Array<InputMaybe<AccessLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<AccessLogCreateOrConnectWithoutUserInput>>>;
  create?: InputMaybe<Array<InputMaybe<AccessLogCreateWithoutUserInput>>>;
  createMany?: InputMaybe<AccessLogCreateManyUserInputEnvelope>;
}

export interface AccessLogCreateOrConnectWithoutUserInput {
  create: AccessLogUncheckedCreateWithoutUserInput;
  where: AccessLogWhereUniqueInput;
}

export interface AccessLogCreateWithoutUserInput {
  accessType: AccessType;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
}

export interface AccessLogListRelationFilter {
  every?: InputMaybe<AccessLogWhereInput>;
  none?: InputMaybe<AccessLogWhereInput>;
  some?: InputMaybe<AccessLogWhereInput>;
}

export interface AccessLogMaxAggregateOutputType {
  __typename?: 'AccessLogMaxAggregateOutputType';
  accessType?: Maybe<AccessType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  thirdPartyId?: Maybe<ThirdPartyId>;
  thirdPartyUserId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
}

export interface AccessLogMaxOrderByAggregateInput {
  accessType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  thirdPartyId?: InputMaybe<SortOrder>;
  thirdPartyUserId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
}

export interface AccessLogMinAggregateOutputType {
  __typename?: 'AccessLogMinAggregateOutputType';
  accessType?: Maybe<AccessType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  thirdPartyId?: Maybe<ThirdPartyId>;
  thirdPartyUserId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
}

export interface AccessLogMinOrderByAggregateInput {
  accessType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  thirdPartyId?: InputMaybe<SortOrder>;
  thirdPartyUserId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
}

export interface AccessLogOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface AccessLogOrderByWithAggregationInput {
  _avg?: InputMaybe<AccessLogAvgOrderByAggregateInput>;
  _count?: InputMaybe<AccessLogCountOrderByAggregateInput>;
  _max?: InputMaybe<AccessLogMaxOrderByAggregateInput>;
  _min?: InputMaybe<AccessLogMinOrderByAggregateInput>;
  _sum?: InputMaybe<AccessLogSumOrderByAggregateInput>;
  accessType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  thirdPartyId?: InputMaybe<SortOrderInput>;
  thirdPartyUserId?: InputMaybe<SortOrderInput>;
  userId?: InputMaybe<SortOrderInput>;
}

export interface AccessLogOrderByWithRelationInput {
  accessType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  thirdPartyId?: InputMaybe<SortOrderInput>;
  thirdPartyUserId?: InputMaybe<SortOrderInput>;
  user?: InputMaybe<MemberOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrderInput>;
}

export enum AccessLogScalarFieldEnum {
  AccessType = 'accessType',
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  Message = 'message',
  ThirdPartyId = 'thirdPartyId',
  ThirdPartyUserId = 'thirdPartyUserId',
  UserId = 'userId'
}

export interface AccessLogScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<AccessLogScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<AccessLogScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<AccessLogScalarWhereInput>>>;
  accessType?: InputMaybe<EnumAccessTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  message?: InputMaybe<StringFilter>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdNullableFilter>;
  thirdPartyUserId?: InputMaybe<StringNullableFilter>;
  userId?: InputMaybe<IntNullableFilter>;
}

export interface AccessLogScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<AccessLogScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<AccessLogScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<AccessLogScalarWhereWithAggregatesInput>>>;
  accessType?: InputMaybe<EnumAccessTypeWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  message?: InputMaybe<StringWithAggregatesFilter>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdNullableWithAggregatesFilter>;
  thirdPartyUserId?: InputMaybe<StringNullableWithAggregatesFilter>;
  userId?: InputMaybe<IntNullableWithAggregatesFilter>;
}

export interface AccessLogSumAggregateOutputType {
  __typename?: 'AccessLogSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
}

export interface AccessLogSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
}

export interface AccessLogUncheckedCreateInput {
  accessType: AccessType;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
}

export interface AccessLogUncheckedCreateNestedManyWithoutUserInput {
  connect?: InputMaybe<Array<InputMaybe<AccessLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<AccessLogCreateOrConnectWithoutUserInput>>>;
  create?: InputMaybe<Array<InputMaybe<AccessLogCreateWithoutUserInput>>>;
  createMany?: InputMaybe<AccessLogCreateManyUserInputEnvelope>;
}

export interface AccessLogUncheckedCreateWithoutUserInput {
  accessType: AccessType;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
}

export interface AccessLogUncheckedUpdateInput {
  accessType?: InputMaybe<EnumAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<NullableEnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface AccessLogUncheckedUpdateManyInput {
  accessType?: InputMaybe<EnumAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<NullableEnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface AccessLogUncheckedUpdateManyWithoutAccessLogInput {
  accessType?: InputMaybe<EnumAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<NullableEnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface AccessLogUncheckedUpdateManyWithoutUserNestedInput {
  connect?: InputMaybe<Array<InputMaybe<AccessLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<AccessLogCreateOrConnectWithoutUserInput>>>;
  create?: InputMaybe<Array<InputMaybe<AccessLogCreateWithoutUserInput>>>;
  createMany?: InputMaybe<AccessLogCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<AccessLogWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<AccessLogScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<AccessLogWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<AccessLogWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<AccessLogUpdateWithWhereUniqueWithoutUserInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<AccessLogUpdateManyWithWhereWithoutUserInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<AccessLogUpsertWithWhereUniqueWithoutUserInput>>>;
}

export interface AccessLogUncheckedUpdateWithoutUserInput {
  accessType?: InputMaybe<EnumAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<NullableEnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface AccessLogUpdateInput {
  accessType?: InputMaybe<EnumAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<NullableEnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user?: InputMaybe<MemberUpdateOneWithoutAccessLogNestedInput>;
}

export interface AccessLogUpdateManyMutationInput {
  accessType?: InputMaybe<EnumAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<NullableEnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface AccessLogUpdateManyWithWhereWithoutUserInput {
  data: AccessLogUncheckedUpdateManyWithoutAccessLogInput;
  where: AccessLogScalarWhereInput;
}

export interface AccessLogUpdateManyWithoutUserNestedInput {
  connect?: InputMaybe<Array<InputMaybe<AccessLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<AccessLogCreateOrConnectWithoutUserInput>>>;
  create?: InputMaybe<Array<InputMaybe<AccessLogCreateWithoutUserInput>>>;
  createMany?: InputMaybe<AccessLogCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<AccessLogWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<AccessLogScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<AccessLogWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<AccessLogWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<AccessLogUpdateWithWhereUniqueWithoutUserInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<AccessLogUpdateManyWithWhereWithoutUserInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<AccessLogUpsertWithWhereUniqueWithoutUserInput>>>;
}

export interface AccessLogUpdateWithWhereUniqueWithoutUserInput {
  data: AccessLogUncheckedUpdateWithoutUserInput;
  where: AccessLogWhereUniqueInput;
}

export interface AccessLogUpdateWithoutUserInput {
  accessType?: InputMaybe<EnumAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<NullableEnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface AccessLogUpsertWithWhereUniqueWithoutUserInput {
  create: AccessLogUncheckedCreateWithoutUserInput;
  update: AccessLogUncheckedUpdateWithoutUserInput;
  where: AccessLogWhereUniqueInput;
}

export interface AccessLogWhereInput {
  AND?: InputMaybe<Array<InputMaybe<AccessLogWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<AccessLogWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<AccessLogWhereInput>>>;
  accessType?: InputMaybe<EnumAccessTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  message?: InputMaybe<StringFilter>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdNullableFilter>;
  thirdPartyUserId?: InputMaybe<StringNullableFilter>;
  user?: InputMaybe<MemberWhereInput>;
  userId?: InputMaybe<IntNullableFilter>;
}

export interface AccessLogWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export enum AccessType {
  Signin = 'SIGNIN',
  Signup = 'SIGNUP',
  Try = 'TRY'
}

/** Address */
export interface Address {
  __typename?: 'Address';
  /** Admin relation list */
  Admin: Array<Admin>;
  Architect: Array<Architect>;
  Construction: Array<Construction>;
  /** Customer relation list */
  Customer: Array<Customer>;
  GeneralConstruction: Array<GeneralConstruction>;
  Modeler: Array<Modeler>;
  Project: Array<Project>;
  SpaceDesign: Array<SpaceDesign>;
  Supplier: Array<Supplier>;
  _count: AddressCountOutputType;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** Detail address from user input */
  detail?: Maybe<Scalars['String']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** General road address from API */
  roadAddress: Scalars['String']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique ID */
  uuid: Scalars['String']['output'];
  /** Zone code */
  zoneCode: Scalars['String']['output'];
}


/** Address */
export type AddressAdminArgs = {
  cursor?: InputMaybe<AdminWhereUniqueInput>;
  distinct?: InputMaybe<AdminScalarFieldEnum>;
  orderBy?: InputMaybe<AdminOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminWhereInput>;
};


/** Address */
export type AddressArchitectArgs = {
  cursor?: InputMaybe<ArchitectWhereUniqueInput>;
  distinct?: InputMaybe<ArchitectScalarFieldEnum>;
  orderBy?: InputMaybe<ArchitectOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectWhereInput>;
};


/** Address */
export type AddressConstructionArgs = {
  cursor?: InputMaybe<ConstructionWhereUniqueInput>;
  distinct?: InputMaybe<ConstructionScalarFieldEnum>;
  orderBy?: InputMaybe<ConstructionOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ConstructionWhereInput>;
};


/** Address */
export type AddressCustomerArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<CustomerScalarFieldEnum>;
  orderBy?: InputMaybe<CustomerOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


/** Address */
export type AddressGeneralConstructionArgs = {
  cursor?: InputMaybe<GeneralConstructionWhereUniqueInput>;
  distinct?: InputMaybe<GeneralConstructionScalarFieldEnum>;
  orderBy?: InputMaybe<GeneralConstructionOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GeneralConstructionWhereInput>;
};


/** Address */
export type AddressModelerArgs = {
  cursor?: InputMaybe<ModelerWhereUniqueInput>;
  distinct?: InputMaybe<ModelerScalarFieldEnum>;
  orderBy?: InputMaybe<ModelerOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelerWhereInput>;
};


/** Address */
export type AddressProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<ProjectScalarFieldEnum>;
  orderBy?: InputMaybe<ProjectOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


/** Address */
export type AddressSpaceDesignArgs = {
  cursor?: InputMaybe<SpaceDesignWhereUniqueInput>;
  distinct?: InputMaybe<SpaceDesignScalarFieldEnum>;
  orderBy?: InputMaybe<SpaceDesignOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpaceDesignWhereInput>;
};


/** Address */
export type AddressSupplierArgs = {
  cursor?: InputMaybe<SupplierWhereUniqueInput>;
  distinct?: InputMaybe<SupplierScalarFieldEnum>;
  orderBy?: InputMaybe<SupplierOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SupplierWhereInput>;
};

export interface AddressAvgAggregateOutputType {
  __typename?: 'AddressAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
}

export interface AddressAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface AddressCountAggregateOutputType {
  __typename?: 'AddressCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  detail: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  roadAddress: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  zoneCode: Scalars['Int']['output'];
}

export interface AddressCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  detail?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  roadAddress?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  zoneCode?: InputMaybe<SortOrder>;
}

export interface AddressCountOutputType {
  __typename?: 'AddressCountOutputType';
  Admin: Scalars['Int']['output'];
  Architect: Scalars['Int']['output'];
  Construction: Scalars['Int']['output'];
  Customer: Scalars['Int']['output'];
  GeneralConstruction: Scalars['Int']['output'];
  Modeler: Scalars['Int']['output'];
  Project: Scalars['Int']['output'];
  SpaceDesign: Scalars['Int']['output'];
  Supplier: Scalars['Int']['output'];
}

export interface AddressCreateInput {
  Admin?: InputMaybe<AdminCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressCreateNestedOneWithoutAdminInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutAdminInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutAdminInput>;
}

export interface AddressCreateNestedOneWithoutArchitectInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutArchitectInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutArchitectInput>;
}

export interface AddressCreateNestedOneWithoutConstructionInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutConstructionInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutConstructionInput>;
}

export interface AddressCreateNestedOneWithoutCustomerInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutCustomerInput>;
}

export interface AddressCreateNestedOneWithoutGeneralConstructionInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutGeneralConstructionInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutGeneralConstructionInput>;
}

export interface AddressCreateNestedOneWithoutModelerInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutModelerInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutModelerInput>;
}

export interface AddressCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutProjectInput>;
}

export interface AddressCreateNestedOneWithoutSpaceDesignInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutSpaceDesignInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutSpaceDesignInput>;
}

export interface AddressCreateNestedOneWithoutSupplierInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutSupplierInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutSupplierInput>;
}

export interface AddressCreateOrConnectWithoutAdminInput {
  create: AddressUncheckedCreateWithoutAdminInput;
  where: AddressWhereUniqueInput;
}

export interface AddressCreateOrConnectWithoutArchitectInput {
  create: AddressUncheckedCreateWithoutArchitectInput;
  where: AddressWhereUniqueInput;
}

export interface AddressCreateOrConnectWithoutConstructionInput {
  create: AddressUncheckedCreateWithoutConstructionInput;
  where: AddressWhereUniqueInput;
}

export interface AddressCreateOrConnectWithoutCustomerInput {
  create: AddressUncheckedCreateWithoutCustomerInput;
  where: AddressWhereUniqueInput;
}

export interface AddressCreateOrConnectWithoutGeneralConstructionInput {
  create: AddressUncheckedCreateWithoutGeneralConstructionInput;
  where: AddressWhereUniqueInput;
}

export interface AddressCreateOrConnectWithoutModelerInput {
  create: AddressUncheckedCreateWithoutModelerInput;
  where: AddressWhereUniqueInput;
}

export interface AddressCreateOrConnectWithoutProjectInput {
  create: AddressUncheckedCreateWithoutProjectInput;
  where: AddressWhereUniqueInput;
}

export interface AddressCreateOrConnectWithoutSpaceDesignInput {
  create: AddressUncheckedCreateWithoutSpaceDesignInput;
  where: AddressWhereUniqueInput;
}

export interface AddressCreateOrConnectWithoutSupplierInput {
  create: AddressUncheckedCreateWithoutSupplierInput;
  where: AddressWhereUniqueInput;
}

export interface AddressCreateWithoutAdminInput {
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressCreateWithoutArchitectInput {
  Admin?: InputMaybe<AdminCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressCreateWithoutConstructionInput {
  Admin?: InputMaybe<AdminCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressCreateWithoutCustomerInput {
  Admin?: InputMaybe<AdminCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressCreateWithoutGeneralConstructionInput {
  Admin?: InputMaybe<AdminCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressCreateWithoutModelerInput {
  Admin?: InputMaybe<AdminCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressCreateWithoutProjectInput {
  Admin?: InputMaybe<AdminCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressCreateWithoutSpaceDesignInput {
  Admin?: InputMaybe<AdminCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressCreateWithoutSupplierInput {
  Admin?: InputMaybe<AdminCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressMaxAggregateOutputType {
  __typename?: 'AddressMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  detail?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  roadAddress?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  zoneCode?: Maybe<Scalars['String']['output']>;
}

export interface AddressMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  detail?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  roadAddress?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  zoneCode?: InputMaybe<SortOrder>;
}

export interface AddressMinAggregateOutputType {
  __typename?: 'AddressMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  detail?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  roadAddress?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  zoneCode?: Maybe<Scalars['String']['output']>;
}

export interface AddressMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  detail?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  roadAddress?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  zoneCode?: InputMaybe<SortOrder>;
}

export interface AddressOrderByWithAggregationInput {
  _avg?: InputMaybe<AddressAvgOrderByAggregateInput>;
  _count?: InputMaybe<AddressCountOrderByAggregateInput>;
  _max?: InputMaybe<AddressMaxOrderByAggregateInput>;
  _min?: InputMaybe<AddressMinOrderByAggregateInput>;
  _sum?: InputMaybe<AddressSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  detail?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  roadAddress?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  zoneCode?: InputMaybe<SortOrder>;
}

export interface AddressOrderByWithRelationInput {
  Admin?: InputMaybe<AdminOrderByRelationAggregateInput>;
  Architect?: InputMaybe<ArchitectOrderByRelationAggregateInput>;
  Construction?: InputMaybe<ConstructionOrderByRelationAggregateInput>;
  Customer?: InputMaybe<CustomerOrderByRelationAggregateInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionOrderByRelationAggregateInput>;
  Modeler?: InputMaybe<ModelerOrderByRelationAggregateInput>;
  Project?: InputMaybe<ProjectOrderByRelationAggregateInput>;
  SpaceDesign?: InputMaybe<SpaceDesignOrderByRelationAggregateInput>;
  Supplier?: InputMaybe<SupplierOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  detail?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  roadAddress?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  zoneCode?: InputMaybe<SortOrder>;
}

export interface AddressRelationFilter {
  is?: InputMaybe<AddressWhereInput>;
  isNot?: InputMaybe<AddressWhereInput>;
}

export enum AddressScalarFieldEnum {
  CreatedAt = 'createdAt',
  Detail = 'detail',
  Id = 'id',
  RoadAddress = 'roadAddress',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  ZoneCode = 'zoneCode'
}

export interface AddressScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<AddressScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<AddressScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<AddressScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  detail?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  roadAddress?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  zoneCode?: InputMaybe<StringWithAggregatesFilter>;
}

export interface AddressSumAggregateOutputType {
  __typename?: 'AddressSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
}

export interface AddressSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface AddressUncheckedCreateInput {
  Admin?: InputMaybe<AdminUncheckedCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerUncheckedCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressUncheckedCreateWithoutAdminInput {
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerUncheckedCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressUncheckedCreateWithoutArchitectInput {
  Admin?: InputMaybe<AdminUncheckedCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerUncheckedCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressUncheckedCreateWithoutConstructionInput {
  Admin?: InputMaybe<AdminUncheckedCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerUncheckedCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressUncheckedCreateWithoutCustomerInput {
  Admin?: InputMaybe<AdminUncheckedCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressUncheckedCreateWithoutGeneralConstructionInput {
  Admin?: InputMaybe<AdminUncheckedCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerUncheckedCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressUncheckedCreateWithoutModelerInput {
  Admin?: InputMaybe<AdminUncheckedCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerUncheckedCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressUncheckedCreateWithoutProjectInput {
  Admin?: InputMaybe<AdminUncheckedCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerUncheckedCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressUncheckedCreateWithoutSpaceDesignInput {
  Admin?: InputMaybe<AdminUncheckedCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerUncheckedCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutAddressInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressUncheckedCreateWithoutSupplierInput {
  Admin?: InputMaybe<AdminUncheckedCreateNestedManyWithoutAddressInput>;
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutAddressInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Customer?: InputMaybe<CustomerUncheckedCreateNestedManyWithoutAddressInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutAddressInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutAddressInput>;
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutAddressInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutAddressInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  roadAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  zoneCode: Scalars['String']['input'];
}

export interface AddressUncheckedUpdateInput {
  Admin?: InputMaybe<AdminUncheckedUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUncheckedUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUncheckedUpdateWithoutAdminInput {
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUncheckedUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUncheckedUpdateWithoutArchitectInput {
  Admin?: InputMaybe<AdminUncheckedUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUncheckedUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUncheckedUpdateWithoutConstructionInput {
  Admin?: InputMaybe<AdminUncheckedUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUncheckedUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUncheckedUpdateWithoutCustomerInput {
  Admin?: InputMaybe<AdminUncheckedUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUncheckedUpdateWithoutGeneralConstructionInput {
  Admin?: InputMaybe<AdminUncheckedUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUncheckedUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUncheckedUpdateWithoutModelerInput {
  Admin?: InputMaybe<AdminUncheckedUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUncheckedUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUncheckedUpdateWithoutProjectInput {
  Admin?: InputMaybe<AdminUncheckedUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUncheckedUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUncheckedUpdateWithoutSpaceDesignInput {
  Admin?: InputMaybe<AdminUncheckedUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUncheckedUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUncheckedUpdateWithoutSupplierInput {
  Admin?: InputMaybe<AdminUncheckedUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUncheckedUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpdateInput {
  Admin?: InputMaybe<AdminUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpdateOneRequiredWithoutProjectNestedInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutProjectInput>;
  update?: InputMaybe<AddressUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<AddressUpsertWithoutProjectInput>;
}

export interface AddressUpdateOneWithoutAdminNestedInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutAdminInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutAdminInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AddressUncheckedUpdateWithoutAdminInput>;
  upsert?: InputMaybe<AddressUpsertWithoutAdminInput>;
}

export interface AddressUpdateOneWithoutArchitectNestedInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutArchitectInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutArchitectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AddressUncheckedUpdateWithoutArchitectInput>;
  upsert?: InputMaybe<AddressUpsertWithoutArchitectInput>;
}

export interface AddressUpdateOneWithoutConstructionNestedInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutConstructionInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutConstructionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AddressUncheckedUpdateWithoutConstructionInput>;
  upsert?: InputMaybe<AddressUpsertWithoutConstructionInput>;
}

export interface AddressUpdateOneWithoutCustomerNestedInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutCustomerInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AddressUncheckedUpdateWithoutCustomerInput>;
  upsert?: InputMaybe<AddressUpsertWithoutCustomerInput>;
}

export interface AddressUpdateOneWithoutGeneralConstructionNestedInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutGeneralConstructionInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutGeneralConstructionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AddressUncheckedUpdateWithoutGeneralConstructionInput>;
  upsert?: InputMaybe<AddressUpsertWithoutGeneralConstructionInput>;
}

export interface AddressUpdateOneWithoutModelerNestedInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutModelerInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutModelerInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AddressUncheckedUpdateWithoutModelerInput>;
  upsert?: InputMaybe<AddressUpsertWithoutModelerInput>;
}

export interface AddressUpdateOneWithoutSpaceDesignNestedInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutSpaceDesignInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutSpaceDesignInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AddressUncheckedUpdateWithoutSpaceDesignInput>;
  upsert?: InputMaybe<AddressUpsertWithoutSpaceDesignInput>;
}

export interface AddressUpdateOneWithoutSupplierNestedInput {
  connect?: InputMaybe<AddressWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AddressCreateOrConnectWithoutSupplierInput>;
  create?: InputMaybe<AddressUncheckedCreateWithoutSupplierInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AddressUncheckedUpdateWithoutSupplierInput>;
  upsert?: InputMaybe<AddressUpsertWithoutSupplierInput>;
}

export interface AddressUpdateWithoutAdminInput {
  Architect?: InputMaybe<ArchitectUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpdateWithoutArchitectInput {
  Admin?: InputMaybe<AdminUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpdateWithoutConstructionInput {
  Admin?: InputMaybe<AdminUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpdateWithoutCustomerInput {
  Admin?: InputMaybe<AdminUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpdateWithoutGeneralConstructionInput {
  Admin?: InputMaybe<AdminUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpdateWithoutModelerInput {
  Admin?: InputMaybe<AdminUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpdateWithoutProjectInput {
  Admin?: InputMaybe<AdminUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpdateWithoutSpaceDesignInput {
  Admin?: InputMaybe<AdminUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUpdateManyWithoutAddressNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpdateWithoutSupplierInput {
  Admin?: InputMaybe<AdminUpdateManyWithoutAddressNestedInput>;
  Architect?: InputMaybe<ArchitectUpdateManyWithoutAddressNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutAddressNestedInput>;
  Customer?: InputMaybe<CustomerUpdateManyWithoutAddressNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutAddressNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutAddressNestedInput>;
  Project?: InputMaybe<ProjectUpdateManyWithoutAddressNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutAddressNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  roadAddress?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  zoneCode?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface AddressUpsertWithoutAdminInput {
  create: AddressUncheckedCreateWithoutAdminInput;
  update: AddressUncheckedUpdateWithoutAdminInput;
}

export interface AddressUpsertWithoutArchitectInput {
  create: AddressUncheckedCreateWithoutArchitectInput;
  update: AddressUncheckedUpdateWithoutArchitectInput;
}

export interface AddressUpsertWithoutConstructionInput {
  create: AddressUncheckedCreateWithoutConstructionInput;
  update: AddressUncheckedUpdateWithoutConstructionInput;
}

export interface AddressUpsertWithoutCustomerInput {
  create: AddressUncheckedCreateWithoutCustomerInput;
  update: AddressUncheckedUpdateWithoutCustomerInput;
}

export interface AddressUpsertWithoutGeneralConstructionInput {
  create: AddressUncheckedCreateWithoutGeneralConstructionInput;
  update: AddressUncheckedUpdateWithoutGeneralConstructionInput;
}

export interface AddressUpsertWithoutModelerInput {
  create: AddressUncheckedCreateWithoutModelerInput;
  update: AddressUncheckedUpdateWithoutModelerInput;
}

export interface AddressUpsertWithoutProjectInput {
  create: AddressUncheckedCreateWithoutProjectInput;
  update: AddressUncheckedUpdateWithoutProjectInput;
}

export interface AddressUpsertWithoutSpaceDesignInput {
  create: AddressUncheckedCreateWithoutSpaceDesignInput;
  update: AddressUncheckedUpdateWithoutSpaceDesignInput;
}

export interface AddressUpsertWithoutSupplierInput {
  create: AddressUncheckedCreateWithoutSupplierInput;
  update: AddressUncheckedUpdateWithoutSupplierInput;
}

export interface AddressWhereInput {
  AND?: InputMaybe<Array<InputMaybe<AddressWhereInput>>>;
  Admin?: InputMaybe<AdminListRelationFilter>;
  Architect?: InputMaybe<ArchitectListRelationFilter>;
  Construction?: InputMaybe<ConstructionListRelationFilter>;
  Customer?: InputMaybe<CustomerListRelationFilter>;
  GeneralConstruction?: InputMaybe<GeneralConstructionListRelationFilter>;
  Modeler?: InputMaybe<ModelerListRelationFilter>;
  NOT?: InputMaybe<Array<InputMaybe<AddressWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<AddressWhereInput>>>;
  Project?: InputMaybe<ProjectListRelationFilter>;
  SpaceDesign?: InputMaybe<SpaceDesignListRelationFilter>;
  Supplier?: InputMaybe<SupplierListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  detail?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  roadAddress?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  zoneCode?: InputMaybe<StringFilter>;
}

export interface AddressWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** Administrator: 운영어드민, Super user */
export interface Admin {
  __typename?: 'Admin';
  _count: AdminCountOutputType;
  /** Admin user address */
  address?: Maybe<Address>;
  /** Admin user addressId */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Member */
  member: Array<Member>;
  /** Name */
  name?: Maybe<Scalars['String']['output']>;
  /** Admin user role */
  role: AdminRole;
  /** Admin user member status */
  status: MemberStatus;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}


/** Administrator: 운영어드민, Super user */
export type AdminMemberArgs = {
  cursor?: InputMaybe<MemberWhereUniqueInput>;
  distinct?: InputMaybe<MemberScalarFieldEnum>;
  orderBy?: InputMaybe<MemberOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberWhereInput>;
};

export interface AdminAvgAggregateOutputType {
  __typename?: 'AdminAvgAggregateOutputType';
  addressId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

export interface AdminAvgOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
}

export interface AdminCountAggregateOutputType {
  __typename?: 'AdminCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  addressId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface AdminCountOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface AdminCountOutputType {
  __typename?: 'AdminCountOutputType';
  member: Scalars['Int']['output'];
}

export interface AdminCreateInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutAdminInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  member?: InputMaybe<MemberCreateNestedManyWithoutAdministratorInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<AdminRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface AdminCreateManyAddressInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<AdminRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface AdminCreateManyAddressInputEnvelope {
  data: AdminCreateManyAddressInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface AdminCreateManyInput {
  addressId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<AdminRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface AdminCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<AdminWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<AdminCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<AdminCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<AdminCreateManyAddressInputEnvelope>;
}

export interface AdminCreateNestedOneWithoutMemberInput {
  connect?: InputMaybe<AdminWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdminCreateOrConnectWithoutMemberInput>;
  create?: InputMaybe<AdminUncheckedCreateWithoutMemberInput>;
}

export interface AdminCreateOrConnectWithoutAddressInput {
  create: AdminUncheckedCreateWithoutAddressInput;
  where: AdminWhereUniqueInput;
}

export interface AdminCreateOrConnectWithoutMemberInput {
  create: AdminUncheckedCreateWithoutMemberInput;
  where: AdminWhereUniqueInput;
}

export interface AdminCreateWithoutAddressInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  member?: InputMaybe<MemberCreateNestedManyWithoutAdministratorInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<AdminRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface AdminCreateWithoutMemberInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutAdminInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<AdminRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface AdminListRelationFilter {
  every?: InputMaybe<AdminWhereInput>;
  none?: InputMaybe<AdminWhereInput>;
  some?: InputMaybe<AdminWhereInput>;
}

export interface AdminMaxAggregateOutputType {
  __typename?: 'AdminMaxAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<AdminRole>;
  status?: Maybe<MemberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface AdminMaxOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface AdminMinAggregateOutputType {
  __typename?: 'AdminMinAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<AdminRole>;
  status?: Maybe<MemberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface AdminMinOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface AdminOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface AdminOrderByWithAggregationInput {
  _avg?: InputMaybe<AdminAvgOrderByAggregateInput>;
  _count?: InputMaybe<AdminCountOrderByAggregateInput>;
  _max?: InputMaybe<AdminMaxOrderByAggregateInput>;
  _min?: InputMaybe<AdminMinOrderByAggregateInput>;
  _sum?: InputMaybe<AdminSumOrderByAggregateInput>;
  addressId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface AdminOrderByWithRelationInput {
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  addressId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface AdminRelationFilter {
  is?: InputMaybe<AdminWhereInput>;
  isNot?: InputMaybe<AdminWhereInput>;
}

export enum AdminRole {
  Operator = 'OPERATOR',
  SuperUser = 'SUPER_USER',
  SystemUser = 'SYSTEM_USER'
}

export enum AdminScalarFieldEnum {
  AddressId = 'addressId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Role = 'role',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export interface AdminScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<AdminScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<AdminScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<AdminScalarWhereInput>>>;
  addressId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumAdminRoleFilter>;
  status?: InputMaybe<EnumMemberStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface AdminScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<AdminScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<AdminScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<AdminScalarWhereWithAggregatesInput>>>;
  addressId?: InputMaybe<IntNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumAdminRoleWithAggregatesFilter>;
  status?: InputMaybe<EnumMemberStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

/** 어드민 Schema (for Paljs) */
export interface AdminSchema {
  __typename?: 'AdminSchema';
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID String CUID */
  id: Scalars['Int']['output'];
  /** Schema */
  schema: Scalars['Json']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}

export interface AdminSchemaAvgAggregateOutputType {
  __typename?: 'AdminSchemaAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
}

export interface AdminSchemaAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface AdminSchemaCountAggregateOutputType {
  __typename?: 'AdminSchemaCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  schema: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface AdminSchemaCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  schema?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface AdminSchemaCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  schema: Scalars['Json']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface AdminSchemaCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  schema: Scalars['Json']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface AdminSchemaMaxAggregateOutputType {
  __typename?: 'AdminSchemaMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface AdminSchemaMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface AdminSchemaMinAggregateOutputType {
  __typename?: 'AdminSchemaMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface AdminSchemaMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface AdminSchemaOrderByWithAggregationInput {
  _avg?: InputMaybe<AdminSchemaAvgOrderByAggregateInput>;
  _count?: InputMaybe<AdminSchemaCountOrderByAggregateInput>;
  _max?: InputMaybe<AdminSchemaMaxOrderByAggregateInput>;
  _min?: InputMaybe<AdminSchemaMinOrderByAggregateInput>;
  _sum?: InputMaybe<AdminSchemaSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  schema?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface AdminSchemaOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  schema?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export enum AdminSchemaScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Schema = 'schema',
  UpdatedAt = 'updatedAt'
}

export interface AdminSchemaScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<AdminSchemaScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<AdminSchemaScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<AdminSchemaScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  schema?: InputMaybe<JsonWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface AdminSchemaSumAggregateOutputType {
  __typename?: 'AdminSchemaSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
}

export interface AdminSchemaSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface AdminSchemaUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  schema: Scalars['Json']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface AdminSchemaUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  schema?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminSchemaUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  schema?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminSchemaUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  schema?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminSchemaUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  schema?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminSchemaWhereInput {
  AND?: InputMaybe<Array<InputMaybe<AdminSchemaWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<AdminSchemaWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<AdminSchemaWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  schema?: InputMaybe<JsonFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface AdminSchemaWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export interface AdminSumAggregateOutputType {
  __typename?: 'AdminSumAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

export interface AdminSumOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
}

export interface AdminUncheckedCreateInput {
  addressId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutAdministratorInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<AdminRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface AdminUncheckedCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<AdminWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<AdminCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<AdminCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<AdminCreateManyAddressInputEnvelope>;
}

export interface AdminUncheckedCreateWithoutAddressInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutAdministratorInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<AdminRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface AdminUncheckedCreateWithoutMemberInput {
  addressId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<AdminRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface AdminUncheckedUpdateInput {
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutAdministratorNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumAdminRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminUncheckedUpdateManyInput {
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumAdminRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminUncheckedUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<AdminWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<AdminCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<AdminCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<AdminCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<AdminWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<AdminScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<AdminWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<AdminWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<AdminUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<AdminUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<AdminUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface AdminUncheckedUpdateManyWithoutAdminInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumAdminRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminUncheckedUpdateWithoutAddressInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutAdministratorNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumAdminRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminUncheckedUpdateWithoutMemberInput {
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumAdminRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminUpdateInput {
  address?: InputMaybe<AddressUpdateOneWithoutAdminNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutAdministratorNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumAdminRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumAdminRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminUpdateManyWithWhereWithoutAddressInput {
  data: AdminUncheckedUpdateManyWithoutAdminInput;
  where: AdminScalarWhereInput;
}

export interface AdminUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<AdminWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<AdminCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<AdminCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<AdminCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<AdminWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<AdminScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<AdminWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<AdminWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<AdminUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<AdminUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<AdminUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface AdminUpdateOneWithoutMemberNestedInput {
  connect?: InputMaybe<AdminWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdminCreateOrConnectWithoutMemberInput>;
  create?: InputMaybe<AdminUncheckedCreateWithoutMemberInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<AdminUncheckedUpdateWithoutMemberInput>;
  upsert?: InputMaybe<AdminUpsertWithoutMemberInput>;
}

export interface AdminUpdateWithWhereUniqueWithoutAddressInput {
  data: AdminUncheckedUpdateWithoutAddressInput;
  where: AdminWhereUniqueInput;
}

export interface AdminUpdateWithoutAddressInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutAdministratorNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumAdminRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminUpdateWithoutMemberInput {
  address?: InputMaybe<AddressUpdateOneWithoutAdminNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumAdminRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface AdminUpsertWithWhereUniqueWithoutAddressInput {
  create: AdminUncheckedCreateWithoutAddressInput;
  update: AdminUncheckedUpdateWithoutAddressInput;
  where: AdminWhereUniqueInput;
}

export interface AdminUpsertWithoutMemberInput {
  create: AdminUncheckedCreateWithoutMemberInput;
  update: AdminUncheckedUpdateWithoutMemberInput;
}

export interface AdminWhereInput {
  AND?: InputMaybe<Array<InputMaybe<AdminWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<AdminWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<AdminWhereInput>>>;
  address?: InputMaybe<AddressWhereInput>;
  addressId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  member?: InputMaybe<MemberListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumAdminRoleFilter>;
  status?: InputMaybe<EnumMemberStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface AdminWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export interface AggregateAccessLog {
  __typename?: 'AggregateAccessLog';
  _avg?: Maybe<AccessLogAvgAggregateOutputType>;
  _count?: Maybe<AccessLogCountAggregateOutputType>;
  _max?: Maybe<AccessLogMaxAggregateOutputType>;
  _min?: Maybe<AccessLogMinAggregateOutputType>;
  _sum?: Maybe<AccessLogSumAggregateOutputType>;
}

export interface AggregateAddress {
  __typename?: 'AggregateAddress';
  _avg?: Maybe<AddressAvgAggregateOutputType>;
  _count?: Maybe<AddressCountAggregateOutputType>;
  _max?: Maybe<AddressMaxAggregateOutputType>;
  _min?: Maybe<AddressMinAggregateOutputType>;
  _sum?: Maybe<AddressSumAggregateOutputType>;
}

export interface AggregateAdmin {
  __typename?: 'AggregateAdmin';
  _avg?: Maybe<AdminAvgAggregateOutputType>;
  _count?: Maybe<AdminCountAggregateOutputType>;
  _max?: Maybe<AdminMaxAggregateOutputType>;
  _min?: Maybe<AdminMinAggregateOutputType>;
  _sum?: Maybe<AdminSumAggregateOutputType>;
}

export interface AggregateAdminSchema {
  __typename?: 'AggregateAdminSchema';
  _avg?: Maybe<AdminSchemaAvgAggregateOutputType>;
  _count?: Maybe<AdminSchemaCountAggregateOutputType>;
  _max?: Maybe<AdminSchemaMaxAggregateOutputType>;
  _min?: Maybe<AdminSchemaMinAggregateOutputType>;
  _sum?: Maybe<AdminSchemaSumAggregateOutputType>;
}

export interface AggregateArchitect {
  __typename?: 'AggregateArchitect';
  _avg?: Maybe<ArchitectAvgAggregateOutputType>;
  _count?: Maybe<ArchitectCountAggregateOutputType>;
  _max?: Maybe<ArchitectMaxAggregateOutputType>;
  _min?: Maybe<ArchitectMinAggregateOutputType>;
  _sum?: Maybe<ArchitectSumAggregateOutputType>;
}

export interface AggregateArchitectureSrw {
  __typename?: 'AggregateArchitectureSRW';
  _avg?: Maybe<ArchitectureSrwAvgAggregateOutputType>;
  _count?: Maybe<ArchitectureSrwCountAggregateOutputType>;
  _max?: Maybe<ArchitectureSrwMaxAggregateOutputType>;
  _min?: Maybe<ArchitectureSrwMinAggregateOutputType>;
  _sum?: Maybe<ArchitectureSrwSumAggregateOutputType>;
}

export interface AggregateBoqUpdate {
  __typename?: 'AggregateBOQUpdate';
  _avg?: Maybe<BoqUpdateAvgAggregateOutputType>;
  _count?: Maybe<BoqUpdateCountAggregateOutputType>;
  _max?: Maybe<BoqUpdateMaxAggregateOutputType>;
  _min?: Maybe<BoqUpdateMinAggregateOutputType>;
  _sum?: Maybe<BoqUpdateSumAggregateOutputType>;
}

export interface AggregateBlueprint {
  __typename?: 'AggregateBlueprint';
  _avg?: Maybe<BlueprintAvgAggregateOutputType>;
  _count?: Maybe<BlueprintCountAggregateOutputType>;
  _max?: Maybe<BlueprintMaxAggregateOutputType>;
  _min?: Maybe<BlueprintMinAggregateOutputType>;
  _sum?: Maybe<BlueprintSumAggregateOutputType>;
}

export interface AggregateBlueprintComment {
  __typename?: 'AggregateBlueprintComment';
  _avg?: Maybe<BlueprintCommentAvgAggregateOutputType>;
  _count?: Maybe<BlueprintCommentCountAggregateOutputType>;
  _max?: Maybe<BlueprintCommentMaxAggregateOutputType>;
  _min?: Maybe<BlueprintCommentMinAggregateOutputType>;
  _sum?: Maybe<BlueprintCommentSumAggregateOutputType>;
}

export interface AggregateBoQItem {
  __typename?: 'AggregateBoQItem';
  _avg?: Maybe<BoQItemAvgAggregateOutputType>;
  _count?: Maybe<BoQItemCountAggregateOutputType>;
  _max?: Maybe<BoQItemMaxAggregateOutputType>;
  _min?: Maybe<BoQItemMinAggregateOutputType>;
  _sum?: Maybe<BoQItemSumAggregateOutputType>;
}

export interface AggregateCashReceipt {
  __typename?: 'AggregateCashReceipt';
  _avg?: Maybe<CashReceiptAvgAggregateOutputType>;
  _count?: Maybe<CashReceiptCountAggregateOutputType>;
  _max?: Maybe<CashReceiptMaxAggregateOutputType>;
  _min?: Maybe<CashReceiptMinAggregateOutputType>;
  _sum?: Maybe<CashReceiptSumAggregateOutputType>;
}

export interface AggregateCivilWorkSrw {
  __typename?: 'AggregateCivilWorkSRW';
  _avg?: Maybe<CivilWorkSrwAvgAggregateOutputType>;
  _count?: Maybe<CivilWorkSrwCountAggregateOutputType>;
  _max?: Maybe<CivilWorkSrwMaxAggregateOutputType>;
  _min?: Maybe<CivilWorkSrwMinAggregateOutputType>;
  _sum?: Maybe<CivilWorkSrwSumAggregateOutputType>;
}

export interface AggregateCommunicationSrw {
  __typename?: 'AggregateCommunicationSRW';
  _avg?: Maybe<CommunicationSrwAvgAggregateOutputType>;
  _count?: Maybe<CommunicationSrwCountAggregateOutputType>;
  _max?: Maybe<CommunicationSrwMaxAggregateOutputType>;
  _min?: Maybe<CommunicationSrwMinAggregateOutputType>;
  _sum?: Maybe<CommunicationSrwSumAggregateOutputType>;
}

export interface AggregateConstruction {
  __typename?: 'AggregateConstruction';
  _avg?: Maybe<ConstructionAvgAggregateOutputType>;
  _count?: Maybe<ConstructionCountAggregateOutputType>;
  _max?: Maybe<ConstructionMaxAggregateOutputType>;
  _min?: Maybe<ConstructionMinAggregateOutputType>;
  _sum?: Maybe<ConstructionSumAggregateOutputType>;
}

export interface AggregateCustomer {
  __typename?: 'AggregateCustomer';
  _avg?: Maybe<CustomerAvgAggregateOutputType>;
  _count?: Maybe<CustomerCountAggregateOutputType>;
  _max?: Maybe<CustomerMaxAggregateOutputType>;
  _min?: Maybe<CustomerMinAggregateOutputType>;
  _sum?: Maybe<CustomerSumAggregateOutputType>;
}

export interface AggregateElectricalSrw {
  __typename?: 'AggregateElectricalSRW';
  _avg?: Maybe<ElectricalSrwAvgAggregateOutputType>;
  _count?: Maybe<ElectricalSrwCountAggregateOutputType>;
  _max?: Maybe<ElectricalSrwMaxAggregateOutputType>;
  _min?: Maybe<ElectricalSrwMinAggregateOutputType>;
  _sum?: Maybe<ElectricalSrwSumAggregateOutputType>;
}

export interface AggregateEventLogs {
  __typename?: 'AggregateEventLogs';
  _avg?: Maybe<EventLogsAvgAggregateOutputType>;
  _count?: Maybe<EventLogsCountAggregateOutputType>;
  _max?: Maybe<EventLogsMaxAggregateOutputType>;
  _min?: Maybe<EventLogsMinAggregateOutputType>;
  _sum?: Maybe<EventLogsSumAggregateOutputType>;
}

export interface AggregateEventResult {
  __typename?: 'AggregateEventResult';
  _avg?: Maybe<EventResultAvgAggregateOutputType>;
  _count?: Maybe<EventResultCountAggregateOutputType>;
  _max?: Maybe<EventResultMaxAggregateOutputType>;
  _min?: Maybe<EventResultMinAggregateOutputType>;
  _sum?: Maybe<EventResultSumAggregateOutputType>;
}

export interface AggregateFireProtectionSrw {
  __typename?: 'AggregateFireProtectionSRW';
  _avg?: Maybe<FireProtectionSrwAvgAggregateOutputType>;
  _count?: Maybe<FireProtectionSrwCountAggregateOutputType>;
  _max?: Maybe<FireProtectionSrwMaxAggregateOutputType>;
  _min?: Maybe<FireProtectionSrwMinAggregateOutputType>;
  _sum?: Maybe<FireProtectionSrwSumAggregateOutputType>;
}

export interface AggregateGeneralConstruction {
  __typename?: 'AggregateGeneralConstruction';
  _avg?: Maybe<GeneralConstructionAvgAggregateOutputType>;
  _count?: Maybe<GeneralConstructionCountAggregateOutputType>;
  _max?: Maybe<GeneralConstructionMaxAggregateOutputType>;
  _min?: Maybe<GeneralConstructionMinAggregateOutputType>;
  _sum?: Maybe<GeneralConstructionSumAggregateOutputType>;
}

export interface AggregateIdentity {
  __typename?: 'AggregateIdentity';
  _avg?: Maybe<IdentityAvgAggregateOutputType>;
  _count?: Maybe<IdentityCountAggregateOutputType>;
  _max?: Maybe<IdentityMaxAggregateOutputType>;
  _min?: Maybe<IdentityMinAggregateOutputType>;
  _sum?: Maybe<IdentitySumAggregateOutputType>;
}

export interface AggregateInquiry {
  __typename?: 'AggregateInquiry';
  _avg?: Maybe<InquiryAvgAggregateOutputType>;
  _count?: Maybe<InquiryCountAggregateOutputType>;
  _max?: Maybe<InquiryMaxAggregateOutputType>;
  _min?: Maybe<InquiryMinAggregateOutputType>;
  _sum?: Maybe<InquirySumAggregateOutputType>;
}

export interface AggregateInspectionRejected {
  __typename?: 'AggregateInspectionRejected';
  _avg?: Maybe<InspectionRejectedAvgAggregateOutputType>;
  _count?: Maybe<InspectionRejectedCountAggregateOutputType>;
  _max?: Maybe<InspectionRejectedMaxAggregateOutputType>;
  _min?: Maybe<InspectionRejectedMinAggregateOutputType>;
  _sum?: Maybe<InspectionRejectedSumAggregateOutputType>;
}

export interface AggregateKakaoMessageTemplate {
  __typename?: 'AggregateKakaoMessageTemplate';
  _avg?: Maybe<KakaoMessageTemplateAvgAggregateOutputType>;
  _count?: Maybe<KakaoMessageTemplateCountAggregateOutputType>;
  _max?: Maybe<KakaoMessageTemplateMaxAggregateOutputType>;
  _min?: Maybe<KakaoMessageTemplateMinAggregateOutputType>;
  _sum?: Maybe<KakaoMessageTemplateSumAggregateOutputType>;
}

export interface AggregateKakaoSendLog {
  __typename?: 'AggregateKakaoSendLog';
  _avg?: Maybe<KakaoSendLogAvgAggregateOutputType>;
  _count?: Maybe<KakaoSendLogCountAggregateOutputType>;
  _max?: Maybe<KakaoSendLogMaxAggregateOutputType>;
  _min?: Maybe<KakaoSendLogMinAggregateOutputType>;
  _sum?: Maybe<KakaoSendLogSumAggregateOutputType>;
}

export interface AggregateLandscapingSrw {
  __typename?: 'AggregateLandscapingSRW';
  _avg?: Maybe<LandscapingSrwAvgAggregateOutputType>;
  _count?: Maybe<LandscapingSrwCountAggregateOutputType>;
  _max?: Maybe<LandscapingSrwMaxAggregateOutputType>;
  _min?: Maybe<LandscapingSrwMinAggregateOutputType>;
  _sum?: Maybe<LandscapingSrwSumAggregateOutputType>;
}

export interface AggregateMaterialPrice {
  __typename?: 'AggregateMaterialPrice';
  _avg?: Maybe<MaterialPriceAvgAggregateOutputType>;
  _count?: Maybe<MaterialPriceCountAggregateOutputType>;
  _max?: Maybe<MaterialPriceMaxAggregateOutputType>;
  _min?: Maybe<MaterialPriceMinAggregateOutputType>;
  _sum?: Maybe<MaterialPriceSumAggregateOutputType>;
}

export interface AggregateMechanicalSrw {
  __typename?: 'AggregateMechanicalSRW';
  _avg?: Maybe<MechanicalSrwAvgAggregateOutputType>;
  _count?: Maybe<MechanicalSrwCountAggregateOutputType>;
  _max?: Maybe<MechanicalSrwMaxAggregateOutputType>;
  _min?: Maybe<MechanicalSrwMinAggregateOutputType>;
  _sum?: Maybe<MechanicalSrwSumAggregateOutputType>;
}

export interface AggregateMember {
  __typename?: 'AggregateMember';
  _avg?: Maybe<MemberAvgAggregateOutputType>;
  _count?: Maybe<MemberCountAggregateOutputType>;
  _max?: Maybe<MemberMaxAggregateOutputType>;
  _min?: Maybe<MemberMinAggregateOutputType>;
  _sum?: Maybe<MemberSumAggregateOutputType>;
}

export interface AggregateMemberEvent {
  __typename?: 'AggregateMemberEvent';
  _avg?: Maybe<MemberEventAvgAggregateOutputType>;
  _count?: Maybe<MemberEventCountAggregateOutputType>;
  _max?: Maybe<MemberEventMaxAggregateOutputType>;
  _min?: Maybe<MemberEventMinAggregateOutputType>;
  _sum?: Maybe<MemberEventSumAggregateOutputType>;
}

export interface AggregateMilestoneWorkItem {
  __typename?: 'AggregateMilestoneWorkItem';
  _avg?: Maybe<MilestoneWorkItemAvgAggregateOutputType>;
  _count?: Maybe<MilestoneWorkItemCountAggregateOutputType>;
  _max?: Maybe<MilestoneWorkItemMaxAggregateOutputType>;
  _min?: Maybe<MilestoneWorkItemMinAggregateOutputType>;
  _sum?: Maybe<MilestoneWorkItemSumAggregateOutputType>;
}

export interface AggregateModelProperty {
  __typename?: 'AggregateModelProperty';
  _avg?: Maybe<ModelPropertyAvgAggregateOutputType>;
  _count?: Maybe<ModelPropertyCountAggregateOutputType>;
  _max?: Maybe<ModelPropertyMaxAggregateOutputType>;
  _min?: Maybe<ModelPropertyMinAggregateOutputType>;
  _sum?: Maybe<ModelPropertySumAggregateOutputType>;
}

export interface AggregateModeler {
  __typename?: 'AggregateModeler';
  _avg?: Maybe<ModelerAvgAggregateOutputType>;
  _count?: Maybe<ModelerCountAggregateOutputType>;
  _max?: Maybe<ModelerMaxAggregateOutputType>;
  _min?: Maybe<ModelerMinAggregateOutputType>;
  _sum?: Maybe<ModelerSumAggregateOutputType>;
}

export interface AggregateNotice {
  __typename?: 'AggregateNotice';
  _avg?: Maybe<NoticeAvgAggregateOutputType>;
  _count?: Maybe<NoticeCountAggregateOutputType>;
  _max?: Maybe<NoticeMaxAggregateOutputType>;
  _min?: Maybe<NoticeMinAggregateOutputType>;
  _sum?: Maybe<NoticeSumAggregateOutputType>;
}

export interface AggregateNoticeComment {
  __typename?: 'AggregateNoticeComment';
  _avg?: Maybe<NoticeCommentAvgAggregateOutputType>;
  _count?: Maybe<NoticeCommentCountAggregateOutputType>;
  _max?: Maybe<NoticeCommentMaxAggregateOutputType>;
  _min?: Maybe<NoticeCommentMinAggregateOutputType>;
  _sum?: Maybe<NoticeCommentSumAggregateOutputType>;
}

export interface AggregateNoticeTopic {
  __typename?: 'AggregateNoticeTopic';
  _avg?: Maybe<NoticeTopicAvgAggregateOutputType>;
  _count?: Maybe<NoticeTopicCountAggregateOutputType>;
  _max?: Maybe<NoticeTopicMaxAggregateOutputType>;
  _min?: Maybe<NoticeTopicMinAggregateOutputType>;
  _sum?: Maybe<NoticeTopicSumAggregateOutputType>;
}

export interface AggregatePartner {
  __typename?: 'AggregatePartner';
  _avg?: Maybe<PartnerAvgAggregateOutputType>;
  _count?: Maybe<PartnerCountAggregateOutputType>;
  _max?: Maybe<PartnerMaxAggregateOutputType>;
  _min?: Maybe<PartnerMinAggregateOutputType>;
  _sum?: Maybe<PartnerSumAggregateOutputType>;
}

export interface AggregatePayTransaction {
  __typename?: 'AggregatePayTransaction';
  _avg?: Maybe<PayTransactionAvgAggregateOutputType>;
  _count?: Maybe<PayTransactionCountAggregateOutputType>;
  _max?: Maybe<PayTransactionMaxAggregateOutputType>;
  _min?: Maybe<PayTransactionMinAggregateOutputType>;
  _sum?: Maybe<PayTransactionSumAggregateOutputType>;
}

export interface AggregatePersonnel {
  __typename?: 'AggregatePersonnel';
  _avg?: Maybe<PersonnelAvgAggregateOutputType>;
  _count?: Maybe<PersonnelCountAggregateOutputType>;
  _max?: Maybe<PersonnelMaxAggregateOutputType>;
  _min?: Maybe<PersonnelMinAggregateOutputType>;
  _sum?: Maybe<PersonnelSumAggregateOutputType>;
}

export interface AggregateProcessSimulation {
  __typename?: 'AggregateProcessSimulation';
  _avg?: Maybe<ProcessSimulationAvgAggregateOutputType>;
  _count?: Maybe<ProcessSimulationCountAggregateOutputType>;
  _max?: Maybe<ProcessSimulationMaxAggregateOutputType>;
  _min?: Maybe<ProcessSimulationMinAggregateOutputType>;
  _sum?: Maybe<ProcessSimulationSumAggregateOutputType>;
}

export interface AggregateProcessTaskCell {
  __typename?: 'AggregateProcessTaskCell';
  _avg?: Maybe<ProcessTaskCellAvgAggregateOutputType>;
  _count?: Maybe<ProcessTaskCellCountAggregateOutputType>;
  _max?: Maybe<ProcessTaskCellMaxAggregateOutputType>;
  _min?: Maybe<ProcessTaskCellMinAggregateOutputType>;
  _sum?: Maybe<ProcessTaskCellSumAggregateOutputType>;
}

export interface AggregateProfile {
  __typename?: 'AggregateProfile';
  _avg?: Maybe<ProfileAvgAggregateOutputType>;
  _count?: Maybe<ProfileCountAggregateOutputType>;
  _max?: Maybe<ProfileMaxAggregateOutputType>;
  _min?: Maybe<ProfileMinAggregateOutputType>;
  _sum?: Maybe<ProfileSumAggregateOutputType>;
}

export interface AggregateProject {
  __typename?: 'AggregateProject';
  _avg?: Maybe<ProjectAvgAggregateOutputType>;
  _count?: Maybe<ProjectCountAggregateOutputType>;
  _max?: Maybe<ProjectMaxAggregateOutputType>;
  _min?: Maybe<ProjectMinAggregateOutputType>;
  _sum?: Maybe<ProjectSumAggregateOutputType>;
}

export interface AggregateProjectAsset {
  __typename?: 'AggregateProjectAsset';
  _avg?: Maybe<ProjectAssetAvgAggregateOutputType>;
  _count?: Maybe<ProjectAssetCountAggregateOutputType>;
  _max?: Maybe<ProjectAssetMaxAggregateOutputType>;
  _min?: Maybe<ProjectAssetMinAggregateOutputType>;
  _sum?: Maybe<ProjectAssetSumAggregateOutputType>;
}

export interface AggregateProjectBoQ {
  __typename?: 'AggregateProjectBoQ';
  _avg?: Maybe<ProjectBoQAvgAggregateOutputType>;
  _count?: Maybe<ProjectBoQCountAggregateOutputType>;
  _max?: Maybe<ProjectBoQMaxAggregateOutputType>;
  _min?: Maybe<ProjectBoQMinAggregateOutputType>;
  _sum?: Maybe<ProjectBoQSumAggregateOutputType>;
}

export interface AggregateProjectBoQFiles {
  __typename?: 'AggregateProjectBoQFiles';
  _avg?: Maybe<ProjectBoQFilesAvgAggregateOutputType>;
  _count?: Maybe<ProjectBoQFilesCountAggregateOutputType>;
  _max?: Maybe<ProjectBoQFilesMaxAggregateOutputType>;
  _min?: Maybe<ProjectBoQFilesMinAggregateOutputType>;
  _sum?: Maybe<ProjectBoQFilesSumAggregateOutputType>;
}

export interface AggregateProjectFloorInfo {
  __typename?: 'AggregateProjectFloorInfo';
  _avg?: Maybe<ProjectFloorInfoAvgAggregateOutputType>;
  _count?: Maybe<ProjectFloorInfoCountAggregateOutputType>;
  _max?: Maybe<ProjectFloorInfoMaxAggregateOutputType>;
  _min?: Maybe<ProjectFloorInfoMinAggregateOutputType>;
  _sum?: Maybe<ProjectFloorInfoSumAggregateOutputType>;
}

export interface AggregateProjectHistory {
  __typename?: 'AggregateProjectHistory';
  _avg?: Maybe<ProjectHistoryAvgAggregateOutputType>;
  _count?: Maybe<ProjectHistoryCountAggregateOutputType>;
  _max?: Maybe<ProjectHistoryMaxAggregateOutputType>;
  _min?: Maybe<ProjectHistoryMinAggregateOutputType>;
  _sum?: Maybe<ProjectHistorySumAggregateOutputType>;
}

export interface AggregateProjectMilestone {
  __typename?: 'AggregateProjectMilestone';
  _avg?: Maybe<ProjectMilestoneAvgAggregateOutputType>;
  _count?: Maybe<ProjectMilestoneCountAggregateOutputType>;
  _max?: Maybe<ProjectMilestoneMaxAggregateOutputType>;
  _min?: Maybe<ProjectMilestoneMinAggregateOutputType>;
  _sum?: Maybe<ProjectMilestoneSumAggregateOutputType>;
}

export interface AggregateReply {
  __typename?: 'AggregateReply';
  _avg?: Maybe<ReplyAvgAggregateOutputType>;
  _count?: Maybe<ReplyCountAggregateOutputType>;
  _max?: Maybe<ReplyMaxAggregateOutputType>;
  _min?: Maybe<ReplyMinAggregateOutputType>;
  _sum?: Maybe<ReplySumAggregateOutputType>;
}

export interface AggregateShareProjectByMember {
  __typename?: 'AggregateShareProjectByMember';
  _avg?: Maybe<ShareProjectByMemberAvgAggregateOutputType>;
  _count?: Maybe<ShareProjectByMemberCountAggregateOutputType>;
  _max?: Maybe<ShareProjectByMemberMaxAggregateOutputType>;
  _min?: Maybe<ShareProjectByMemberMinAggregateOutputType>;
  _sum?: Maybe<ShareProjectByMemberSumAggregateOutputType>;
}

export interface AggregateShareRatioWork {
  __typename?: 'AggregateShareRatioWork';
  _avg?: Maybe<ShareRatioWorkAvgAggregateOutputType>;
  _count?: Maybe<ShareRatioWorkCountAggregateOutputType>;
  _max?: Maybe<ShareRatioWorkMaxAggregateOutputType>;
  _min?: Maybe<ShareRatioWorkMinAggregateOutputType>;
  _sum?: Maybe<ShareRatioWorkSumAggregateOutputType>;
}

export interface AggregateSharedProject {
  __typename?: 'AggregateSharedProject';
  _avg?: Maybe<SharedProjectAvgAggregateOutputType>;
  _count?: Maybe<SharedProjectCountAggregateOutputType>;
  _max?: Maybe<SharedProjectMaxAggregateOutputType>;
  _min?: Maybe<SharedProjectMinAggregateOutputType>;
  _sum?: Maybe<SharedProjectSumAggregateOutputType>;
}

export interface AggregateSharedProjectByMember {
  __typename?: 'AggregateSharedProjectByMember';
  _avg?: Maybe<SharedProjectByMemberAvgAggregateOutputType>;
  _count?: Maybe<SharedProjectByMemberCountAggregateOutputType>;
  _max?: Maybe<SharedProjectByMemberMaxAggregateOutputType>;
  _min?: Maybe<SharedProjectByMemberMinAggregateOutputType>;
  _sum?: Maybe<SharedProjectByMemberSumAggregateOutputType>;
}

export interface AggregateSharedProjectToken {
  __typename?: 'AggregateSharedProjectToken';
  _avg?: Maybe<SharedProjectTokenAvgAggregateOutputType>;
  _count?: Maybe<SharedProjectTokenCountAggregateOutputType>;
  _max?: Maybe<SharedProjectTokenMaxAggregateOutputType>;
  _min?: Maybe<SharedProjectTokenMinAggregateOutputType>;
  _sum?: Maybe<SharedProjectTokenSumAggregateOutputType>;
}

export interface AggregateSpaceDesign {
  __typename?: 'AggregateSpaceDesign';
  _avg?: Maybe<SpaceDesignAvgAggregateOutputType>;
  _count?: Maybe<SpaceDesignCountAggregateOutputType>;
  _max?: Maybe<SpaceDesignMaxAggregateOutputType>;
  _min?: Maybe<SpaceDesignMinAggregateOutputType>;
  _sum?: Maybe<SpaceDesignSumAggregateOutputType>;
}

export interface AggregateStandardBoQ {
  __typename?: 'AggregateStandardBoQ';
  _avg?: Maybe<StandardBoQAvgAggregateOutputType>;
  _count?: Maybe<StandardBoQCountAggregateOutputType>;
  _max?: Maybe<StandardBoQMaxAggregateOutputType>;
  _min?: Maybe<StandardBoQMinAggregateOutputType>;
  _sum?: Maybe<StandardBoQSumAggregateOutputType>;
}

export interface AggregateStandardBoQFiles {
  __typename?: 'AggregateStandardBoQFiles';
  _avg?: Maybe<StandardBoQFilesAvgAggregateOutputType>;
  _count?: Maybe<StandardBoQFilesCountAggregateOutputType>;
  _max?: Maybe<StandardBoQFilesMaxAggregateOutputType>;
  _min?: Maybe<StandardBoQFilesMinAggregateOutputType>;
  _sum?: Maybe<StandardBoQFilesSumAggregateOutputType>;
}

export interface AggregateSupplier {
  __typename?: 'AggregateSupplier';
  _avg?: Maybe<SupplierAvgAggregateOutputType>;
  _count?: Maybe<SupplierCountAggregateOutputType>;
  _max?: Maybe<SupplierMaxAggregateOutputType>;
  _min?: Maybe<SupplierMinAggregateOutputType>;
  _sum?: Maybe<SupplierSumAggregateOutputType>;
}

export interface AggregateTask {
  __typename?: 'AggregateTask';
  _avg?: Maybe<TaskAvgAggregateOutputType>;
  _count?: Maybe<TaskCountAggregateOutputType>;
  _max?: Maybe<TaskMaxAggregateOutputType>;
  _min?: Maybe<TaskMinAggregateOutputType>;
  _sum?: Maybe<TaskSumAggregateOutputType>;
}

export interface AggregateVatInvoice {
  __typename?: 'AggregateVatInvoice';
  _avg?: Maybe<VatInvoiceAvgAggregateOutputType>;
  _count?: Maybe<VatInvoiceCountAggregateOutputType>;
  _max?: Maybe<VatInvoiceMaxAggregateOutputType>;
  _min?: Maybe<VatInvoiceMinAggregateOutputType>;
  _sum?: Maybe<VatInvoiceSumAggregateOutputType>;
}

export interface AggregateWithdrawReason {
  __typename?: 'AggregateWithdrawReason';
  _count?: Maybe<WithdrawReasonCountAggregateOutputType>;
  _max?: Maybe<WithdrawReasonMaxAggregateOutputType>;
  _min?: Maybe<WithdrawReasonMinAggregateOutputType>;
}

export enum AlertMessageType {
  Channelio = 'CHANNELIO',
  Etc = 'ETC',
  Slack = 'SLACK'
}

/** BuilderHub 특성 데이터 */
export interface AllModelProperty {
  __typename?: 'AllModelProperty';
  BOQCode: Scalars['String']['output'];
  BarsetIds?: Maybe<Scalars['Int']['output']>;
  BoQItem: Array<Maybe<BoQItem>>;
  BuildingCategory?: Maybe<Scalars['String']['output']>;
  BuildingName: Scalars['String']['output'];
  BuildingType?: Maybe<Scalars['String']['output']>;
  ConcSpec?: Maybe<Scalars['String']['output']>;
  ConcreteType?: Maybe<Scalars['String']['output']>;
  DataType: Scalars['String']['output'];
  Diameter?: Maybe<Scalars['Int']['output']>;
  FileName?: Maybe<Scalars['String']['output']>;
  FinItemID?: Maybe<Scalars['Int']['output']>;
  FloorCategory?: Maybe<Scalars['String']['output']>;
  FloorName: Scalars['String']['output'];
  FloorNum?: Maybe<Scalars['Int']['output']>;
  FloorType?: Maybe<Scalars['String']['output']>;
  FormType?: Maybe<Scalars['String']['output']>;
  Formula?: Maybe<Scalars['String']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  MaterialSubcategory?: Maybe<Scalars['String']['output']>;
  Member?: Maybe<Scalars['String']['output']>;
  MemberName?: Maybe<Scalars['String']['output']>;
  MemberType?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  ObjectIds?: Maybe<Scalars['Int']['output']>;
  RebarGrade?: Maybe<Scalars['String']['output']>;
  RebarType?: Maybe<Scalars['String']['output']>;
  Result: Scalars['Float']['output'];
  RoomId?: Maybe<Scalars['Int']['output']>;
  RoomName?: Maybe<Scalars['String']['output']>;
  RoomType?: Maybe<Scalars['String']['output']>;
  Zone?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['String']['output'];
}

/** API 키 생성을 위한 Payload */
export interface ApiKeyPayload {
  /** App ID 고유 ID */
  appId: Scalars['String']['input'];
  /** App 환경 */
  env: Env;
  /** 키 만료일 지정 */
  expiredAt: Scalars['Date']['input'];
  /** 앱 서비스 이름 eg. AWS Lambda */
  serviceName: Scalars['String']['input'];
}

export interface ApiKeyType {
  __typename?: 'ApiKeyType';
  /** API Access Key */
  appKey: Scalars['String']['output'];
  /** API Access Secret Key */
  secretKey: Scalars['String']['output'];
}

/** Partner: 건축설계사 */
export interface Architect {
  __typename?: 'Architect';
  _count: ArchitectCountOutputType;
  /** Partner user address */
  address?: Maybe<Address>;
  addressDetail?: Maybe<Scalars['String']['output']>;
  /** Partner user addressId */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** Avatar URL */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** Partner businessNumber (사업자등록번호) */
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Name */
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partner: Partner;
  partnerId: Scalars['Int']['output'];
  partnerStatus: PartnerRegistrationStatus;
  profile: Array<Profile>;
  /** 사업자등록증 사진키 */
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail: Scalars['String']['output'];
  responsibilityName: Scalars['String']['output'];
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  /** Partner user role */
  role: PartnerRole;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}


/** Partner: 건축설계사 */
export type ArchitectProfileArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<ProfileScalarFieldEnum>;
  orderBy?: InputMaybe<ProfileOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};

export interface ArchitectAvgAggregateOutputType {
  __typename?: 'ArchitectAvgAggregateOutputType';
  addressId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  partnerId?: Maybe<Scalars['Float']['output']>;
}

export interface ArchitectAvgOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface ArchitectCountAggregateOutputType {
  __typename?: 'ArchitectCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  addressDetail: Scalars['Int']['output'];
  addressId: Scalars['Int']['output'];
  avatarUrl: Scalars['Int']['output'];
  businessNumber: Scalars['Int']['output'];
  companyContact: Scalars['Int']['output'];
  companyDescription: Scalars['Int']['output'];
  companyWebsite: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  ownerName: Scalars['Int']['output'];
  partnerId: Scalars['Int']['output'];
  partnerStatus: Scalars['Int']['output'];
  registrationPhotoKey: Scalars['Int']['output'];
  responsibilityEmail: Scalars['Int']['output'];
  responsibilityName: Scalars['Int']['output'];
  responsibilityPosition: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface ArchitectCountOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ArchitectCountOutputType {
  __typename?: 'ArchitectCountOutputType';
  profile: Scalars['Int']['output'];
}

export interface ArchitectCreateInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutArchitectInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutArchitectInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutArchitectInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ArchitectCreateManyAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ArchitectCreateManyAddressInputEnvelope {
  data: ArchitectCreateManyAddressInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ArchitectCreateManyInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ArchitectCreateManyPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ArchitectCreateManyPartnerInputEnvelope {
  data: ArchitectCreateManyPartnerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ArchitectCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ArchitectCreateManyAddressInputEnvelope>;
}

export interface ArchitectCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ArchitectCreateManyPartnerInputEnvelope>;
}

export interface ArchitectCreateNestedOneWithoutProfileInput {
  connect?: InputMaybe<ArchitectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArchitectCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<ArchitectUncheckedCreateWithoutProfileInput>;
}

export interface ArchitectCreateOrConnectWithoutAddressInput {
  create: ArchitectUncheckedCreateWithoutAddressInput;
  where: ArchitectWhereUniqueInput;
}

export interface ArchitectCreateOrConnectWithoutPartnerInput {
  create: ArchitectUncheckedCreateWithoutPartnerInput;
  where: ArchitectWhereUniqueInput;
}

export interface ArchitectCreateOrConnectWithoutProfileInput {
  create: ArchitectUncheckedCreateWithoutProfileInput;
  where: ArchitectWhereUniqueInput;
}

export interface ArchitectCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutArchitectInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutArchitectInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ArchitectCreateWithoutPartnerInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutArchitectInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutArchitectInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ArchitectCreateWithoutProfileInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutArchitectInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutArchitectInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ArchitectListRelationFilter {
  every?: InputMaybe<ArchitectWhereInput>;
  none?: InputMaybe<ArchitectWhereInput>;
  some?: InputMaybe<ArchitectWhereInput>;
}

export interface ArchitectMaxAggregateOutputType {
  __typename?: 'ArchitectMaxAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface ArchitectMaxOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ArchitectMinAggregateOutputType {
  __typename?: 'ArchitectMinAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface ArchitectMinOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ArchitectOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ArchitectOrderByWithAggregationInput {
  _avg?: InputMaybe<ArchitectAvgOrderByAggregateInput>;
  _count?: InputMaybe<ArchitectCountOrderByAggregateInput>;
  _max?: InputMaybe<ArchitectMaxOrderByAggregateInput>;
  _min?: InputMaybe<ArchitectMinOrderByAggregateInput>;
  _sum?: InputMaybe<ArchitectSumOrderByAggregateInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ArchitectOrderByWithRelationInput {
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partner?: InputMaybe<PartnerOrderByWithRelationInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByRelationAggregateInput>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ArchitectRelationFilter {
  is?: InputMaybe<ArchitectWhereInput>;
  isNot?: InputMaybe<ArchitectWhereInput>;
}

export enum ArchitectScalarFieldEnum {
  AddressDetail = 'addressDetail',
  AddressId = 'addressId',
  AvatarUrl = 'avatarUrl',
  BusinessNumber = 'businessNumber',
  CompanyContact = 'companyContact',
  CompanyDescription = 'companyDescription',
  CompanyWebsite = 'companyWebsite',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OwnerName = 'ownerName',
  PartnerId = 'partnerId',
  PartnerStatus = 'partnerStatus',
  RegistrationPhotoKey = 'registrationPhotoKey',
  ResponsibilityEmail = 'responsibilityEmail',
  ResponsibilityName = 'responsibilityName',
  ResponsibilityPosition = 'responsibilityPosition',
  Role = 'role',
  UpdatedAt = 'updatedAt'
}

export interface ArchitectScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ArchitectScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ArchitectScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ArchitectScalarWhereInput>>>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface ArchitectScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ArchitectScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ArchitectScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ArchitectScalarWhereWithAggregatesInput>>>;
  addressDetail?: InputMaybe<StringNullableWithAggregatesFilter>;
  addressId?: InputMaybe<IntNullableWithAggregatesFilter>;
  avatarUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  businessNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyContact?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyDescription?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyWebsite?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  ownerName?: InputMaybe<StringNullableWithAggregatesFilter>;
  partnerId?: InputMaybe<IntWithAggregatesFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusWithAggregatesFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableWithAggregatesFilter>;
  responsibilityEmail?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityName?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityPosition?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumPartnerRoleWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface ArchitectSumAggregateOutputType {
  __typename?: 'ArchitectSumAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
}

export interface ArchitectSumOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface ArchitectUncheckedCreateInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutArchitectInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ArchitectUncheckedCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ArchitectCreateManyAddressInputEnvelope>;
}

export interface ArchitectUncheckedCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ArchitectCreateManyPartnerInputEnvelope>;
}

export interface ArchitectUncheckedCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutArchitectInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ArchitectUncheckedCreateWithoutPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutArchitectInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ArchitectUncheckedCreateWithoutProfileInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ArchitectUncheckedUpdateInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutArchitectNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ArchitectUncheckedUpdateManyInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ArchitectUncheckedUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ArchitectCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ArchitectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ArchitectUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ArchitectUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ArchitectUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface ArchitectUncheckedUpdateManyWithoutArchitectInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ArchitectUncheckedUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ArchitectCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ArchitectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ArchitectUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ArchitectUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ArchitectUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface ArchitectUncheckedUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutArchitectNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ArchitectUncheckedUpdateWithoutPartnerInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutArchitectNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ArchitectUncheckedUpdateWithoutProfileInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ArchitectUpdateInput {
  address?: InputMaybe<AddressUpdateOneWithoutArchitectNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutArchitectNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutArchitectNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ArchitectUpdateManyMutationInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ArchitectUpdateManyWithWhereWithoutAddressInput {
  data: ArchitectUncheckedUpdateManyWithoutArchitectInput;
  where: ArchitectScalarWhereInput;
}

export interface ArchitectUpdateManyWithWhereWithoutPartnerInput {
  data: ArchitectUncheckedUpdateManyWithoutArchitectInput;
  where: ArchitectScalarWhereInput;
}

export interface ArchitectUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ArchitectCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ArchitectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ArchitectUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ArchitectUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ArchitectUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface ArchitectUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ArchitectCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ArchitectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ArchitectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ArchitectUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ArchitectUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ArchitectUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface ArchitectUpdateOneWithoutProfileNestedInput {
  connect?: InputMaybe<ArchitectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ArchitectCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<ArchitectUncheckedCreateWithoutProfileInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ArchitectUncheckedUpdateWithoutProfileInput>;
  upsert?: InputMaybe<ArchitectUpsertWithoutProfileInput>;
}

export interface ArchitectUpdateWithWhereUniqueWithoutAddressInput {
  data: ArchitectUncheckedUpdateWithoutAddressInput;
  where: ArchitectWhereUniqueInput;
}

export interface ArchitectUpdateWithWhereUniqueWithoutPartnerInput {
  data: ArchitectUncheckedUpdateWithoutPartnerInput;
  where: ArchitectWhereUniqueInput;
}

export interface ArchitectUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutArchitectNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutArchitectNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ArchitectUpdateWithoutPartnerInput {
  address?: InputMaybe<AddressUpdateOneWithoutArchitectNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutArchitectNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ArchitectUpdateWithoutProfileInput {
  address?: InputMaybe<AddressUpdateOneWithoutArchitectNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutArchitectNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ArchitectUpsertWithWhereUniqueWithoutAddressInput {
  create: ArchitectUncheckedCreateWithoutAddressInput;
  update: ArchitectUncheckedUpdateWithoutAddressInput;
  where: ArchitectWhereUniqueInput;
}

export interface ArchitectUpsertWithWhereUniqueWithoutPartnerInput {
  create: ArchitectUncheckedCreateWithoutPartnerInput;
  update: ArchitectUncheckedUpdateWithoutPartnerInput;
  where: ArchitectWhereUniqueInput;
}

export interface ArchitectUpsertWithoutProfileInput {
  create: ArchitectUncheckedCreateWithoutProfileInput;
  update: ArchitectUncheckedUpdateWithoutProfileInput;
}

export interface ArchitectWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ArchitectWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ArchitectWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ArchitectWhereInput>>>;
  address?: InputMaybe<AddressWhereInput>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partner?: InputMaybe<PartnerWhereInput>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  profile?: InputMaybe<ProfileListRelationFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface ArchitectWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export enum ArchitecturalWorkCategory {
  AggregateCostAndTransportationCost = 'aggregateCostAndTransportationCost',
  CarpentryAndPlumbingWork = 'carpentryAndPlumbingWork',
  DismantlingAndDemolitionWork = 'dismantlingAndDemolitionWork',
  ExcavationWork = 'excavationWork',
  FoundationWork = 'foundationWork',
  MasonryWork = 'masonryWork',
  MetalWork = 'metalWork',
  OtherWork = 'otherWork',
  PaintingWork = 'paintingWork',
  PlasteringWork = 'plasteringWork',
  QualityTestCost = 'qualityTestCost',
  ReinforcedConcreteWork = 'reinforcedConcreteWork',
  SteelFrameWork = 'steelFrameWork',
  Stonework = 'stonework',
  TileWork = 'tileWork',
  WasteDisposalCost = 'wasteDisposalCost',
  WaterproofingWork = 'waterproofingWork',
  WindowAndGlassWork = 'windowAndGlassWork',
  WorkByProducts = 'workByProducts'
}

/** Architectural Share ratio of Work(건축공사 점유율): '가설공사' '기초공사' '철근콘크리트공사' '철골공사' '조적공사' '방수공사' '석공사' '타일공사' '금속공사(지붕및홈통공사 포함)' '미장공사' '창호및유리공사' '목공사및수장공사(인테리어공사 포함)' '도장공사' '해체및철거공사' '골재비및운반비' '작업부산물' '품질시험비' '기타공사(건축)' '폐기물처리비' */
export interface ArchitectureSrw {
  __typename?: 'ArchitectureSRW';
  ShareRatioWork: Array<ShareRatioWork>;
  _count: ArchitectureSrwCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  mainWork: MainWorkCategory;
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  work: ArchitecturalWorkCategory;
  workName: Scalars['String']['output'];
}


/** Architectural Share ratio of Work(건축공사 점유율): '가설공사' '기초공사' '철근콘크리트공사' '철골공사' '조적공사' '방수공사' '석공사' '타일공사' '금속공사(지붕및홈통공사 포함)' '미장공사' '창호및유리공사' '목공사및수장공사(인테리어공사 포함)' '도장공사' '해체및철거공사' '골재비및운반비' '작업부산물' '품질시험비' '기타공사(건축)' '폐기물처리비' */
export type ArchitectureSrwShareRatioWorkArgs = {
  cursor?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  distinct?: InputMaybe<ShareRatioWorkScalarFieldEnum>;
  orderBy?: InputMaybe<ShareRatioWorkOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};

export interface ArchitectureSrwAvgAggregateOutputType {
  __typename?: 'ArchitectureSRWAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface ArchitectureSrwAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface ArchitectureSrwCountAggregateOutputType {
  __typename?: 'ArchitectureSRWCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  mainWork: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  work: Scalars['Int']['output'];
  workName: Scalars['Int']['output'];
}

export interface ArchitectureSrwCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface ArchitectureSrwCountOutputType {
  __typename?: 'ArchitectureSRWCountOutputType';
  ShareRatioWork: Scalars['Int']['output'];
}

export interface ArchitectureSrwCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedManyWithoutArchitectureInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: ArchitecturalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface ArchitectureSrwCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: ArchitecturalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface ArchitectureSrwCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectureSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectureSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface ArchitectureSrwCreateOrConnectWithoutShareRatioWorkInput {
  create: ArchitectureSrwUncheckedCreateWithoutShareRatioWorkInput;
  where: ArchitectureSrwWhereUniqueInput;
}

export interface ArchitectureSrwCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: ArchitecturalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface ArchitectureSrwListRelationFilter {
  every?: InputMaybe<ArchitectureSrwWhereInput>;
  none?: InputMaybe<ArchitectureSrwWhereInput>;
  some?: InputMaybe<ArchitectureSrwWhereInput>;
}

export interface ArchitectureSrwMaxAggregateOutputType {
  __typename?: 'ArchitectureSRWMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<ArchitecturalWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface ArchitectureSrwMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface ArchitectureSrwMinAggregateOutputType {
  __typename?: 'ArchitectureSRWMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<ArchitecturalWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface ArchitectureSrwMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface ArchitectureSrwOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ArchitectureSrwOrderByWithAggregationInput {
  _avg?: InputMaybe<ArchitectureSrwAvgOrderByAggregateInput>;
  _count?: InputMaybe<ArchitectureSrwCountOrderByAggregateInput>;
  _max?: InputMaybe<ArchitectureSrwMaxOrderByAggregateInput>;
  _min?: InputMaybe<ArchitectureSrwMinOrderByAggregateInput>;
  _sum?: InputMaybe<ArchitectureSrwSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface ArchitectureSrwOrderByWithRelationInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export enum ArchitectureSrwScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  MainWork = 'mainWork',
  Order = 'order',
  ShareRatio = 'shareRatio',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  Work = 'work',
  WorkName = 'workName'
}

export interface ArchitectureSrwScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ArchitectureSrwScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ArchitectureSrwScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ArchitectureSrwScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumArchitecturalWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface ArchitectureSrwScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ArchitectureSrwScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ArchitectureSrwScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ArchitectureSrwScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  shareRatio?: InputMaybe<FloatWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  work?: InputMaybe<EnumArchitecturalWorkCategoryWithAggregatesFilter>;
  workName?: InputMaybe<StringWithAggregatesFilter>;
}

export interface ArchitectureSrwSumAggregateOutputType {
  __typename?: 'ArchitectureSRWSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface ArchitectureSrwSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface ArchitectureSrwUncheckedCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedCreateNestedManyWithoutArchitectureInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: ArchitecturalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface ArchitectureSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectureSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectureSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface ArchitectureSrwUncheckedCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: ArchitecturalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface ArchitectureSrwUncheckedUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedUpdateManyWithoutArchitectureNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumArchitecturalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ArchitectureSrwUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumArchitecturalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ArchitectureSrwUncheckedUpdateManyWithoutArchitectureInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumArchitecturalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ArchitectureSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectureSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectureSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ArchitectureSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ArchitectureSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ArchitectureSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ArchitectureSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface ArchitectureSrwUncheckedUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumArchitecturalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ArchitectureSrwUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUpdateManyWithoutArchitectureNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumArchitecturalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ArchitectureSrwUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumArchitecturalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ArchitectureSrwUpdateManyWithWhereWithoutShareRatioWorkInput {
  data: ArchitectureSrwUncheckedUpdateManyWithoutArchitectureInput;
  where: ArchitectureSrwScalarWhereInput;
}

export interface ArchitectureSrwUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ArchitectureSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ArchitectureSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ArchitectureSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ArchitectureSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ArchitectureSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ArchitectureSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface ArchitectureSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput {
  data: ArchitectureSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: ArchitectureSrwWhereUniqueInput;
}

export interface ArchitectureSrwUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumArchitecturalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ArchitectureSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput {
  create: ArchitectureSrwUncheckedCreateWithoutShareRatioWorkInput;
  update: ArchitectureSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: ArchitectureSrwWhereUniqueInput;
}

export interface ArchitectureSrwWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ArchitectureSrwWhereInput>>>;
  ShareRatioWork?: InputMaybe<ShareRatioWorkListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumArchitecturalWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface ArchitectureSrwWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** AWS Cognito, API Key 생성 등 */
export interface Authentication {
  __typename?: 'Authentication';
  /** API 키 생성 Root 사용자만 가능 */
  apiKeyGen?: Maybe<ApiKeyType>;
  /** 회원가입 후 이메일 인증 완료 Member 데이터 생성 */
  postConfirmation?: Maybe<Member>;
}


/** AWS Cognito, API Key 생성 등 */
export type AuthenticationApiKeyGenArgs = {
  input: ApiKeyPayload;
};


/** AWS Cognito, API Key 생성 등 */
export type AuthenticationPostConfirmationArgs = {
  data: MemberCreateInput;
};

/** BOQ Update Log */
export interface BoqUpdate {
  __typename?: 'BOQUpdate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  log: Scalars['Json']['output'];
  newStandardId: Scalars['Int']['output'];
  oldStandardId: Scalars['Int']['output'];
}

export interface BoqUpdateAvgAggregateOutputType {
  __typename?: 'BOQUpdateAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  newStandardId?: Maybe<Scalars['Float']['output']>;
  oldStandardId?: Maybe<Scalars['Float']['output']>;
}

export interface BoqUpdateAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  newStandardId?: InputMaybe<SortOrder>;
  oldStandardId?: InputMaybe<SortOrder>;
}

export interface BoqUpdateCountAggregateOutputType {
  __typename?: 'BOQUpdateCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  log: Scalars['Int']['output'];
  newStandardId: Scalars['Int']['output'];
  oldStandardId: Scalars['Int']['output'];
}

export interface BoqUpdateCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  log?: InputMaybe<SortOrder>;
  newStandardId?: InputMaybe<SortOrder>;
  oldStandardId?: InputMaybe<SortOrder>;
}

export interface BoqUpdateCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  log: Scalars['Json']['input'];
  newStandardId: Scalars['Int']['input'];
  oldStandardId: Scalars['Int']['input'];
}

export interface BoqUpdateCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  log: Scalars['Json']['input'];
  newStandardId: Scalars['Int']['input'];
  oldStandardId: Scalars['Int']['input'];
}

export interface BoqUpdateMaxAggregateOutputType {
  __typename?: 'BOQUpdateMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  newStandardId?: Maybe<Scalars['Int']['output']>;
  oldStandardId?: Maybe<Scalars['Int']['output']>;
}

export interface BoqUpdateMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  newStandardId?: InputMaybe<SortOrder>;
  oldStandardId?: InputMaybe<SortOrder>;
}

export interface BoqUpdateMinAggregateOutputType {
  __typename?: 'BOQUpdateMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  newStandardId?: Maybe<Scalars['Int']['output']>;
  oldStandardId?: Maybe<Scalars['Int']['output']>;
}

export interface BoqUpdateMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  newStandardId?: InputMaybe<SortOrder>;
  oldStandardId?: InputMaybe<SortOrder>;
}

export interface BoqUpdateOrderByWithAggregationInput {
  _avg?: InputMaybe<BoqUpdateAvgOrderByAggregateInput>;
  _count?: InputMaybe<BoqUpdateCountOrderByAggregateInput>;
  _max?: InputMaybe<BoqUpdateMaxOrderByAggregateInput>;
  _min?: InputMaybe<BoqUpdateMinOrderByAggregateInput>;
  _sum?: InputMaybe<BoqUpdateSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  log?: InputMaybe<SortOrder>;
  newStandardId?: InputMaybe<SortOrder>;
  oldStandardId?: InputMaybe<SortOrder>;
}

export interface BoqUpdateOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  log?: InputMaybe<SortOrder>;
  newStandardId?: InputMaybe<SortOrder>;
  oldStandardId?: InputMaybe<SortOrder>;
}

export enum BoqUpdateScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Log = 'log',
  NewStandardId = 'newStandardId',
  OldStandardId = 'oldStandardId'
}

export interface BoqUpdateScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<BoqUpdateScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<BoqUpdateScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BoqUpdateScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  log?: InputMaybe<JsonWithAggregatesFilter>;
  newStandardId?: InputMaybe<IntWithAggregatesFilter>;
  oldStandardId?: InputMaybe<IntWithAggregatesFilter>;
}

export interface BoqUpdateSumAggregateOutputType {
  __typename?: 'BOQUpdateSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  newStandardId?: Maybe<Scalars['Int']['output']>;
  oldStandardId?: Maybe<Scalars['Int']['output']>;
}

export interface BoqUpdateSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  newStandardId?: InputMaybe<SortOrder>;
  oldStandardId?: InputMaybe<SortOrder>;
}

export interface BoqUpdateUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  log: Scalars['Json']['input'];
  newStandardId: Scalars['Int']['input'];
  oldStandardId: Scalars['Int']['input'];
}

export interface BoqUpdateUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  newStandardId?: InputMaybe<IntFieldUpdateOperationsInput>;
  oldStandardId?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface BoqUpdateUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  newStandardId?: InputMaybe<IntFieldUpdateOperationsInput>;
  oldStandardId?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface BoqUpdateUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  newStandardId?: InputMaybe<IntFieldUpdateOperationsInput>;
  oldStandardId?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface BoqUpdateUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  newStandardId?: InputMaybe<IntFieldUpdateOperationsInput>;
  oldStandardId?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface BoqUpdateWhereInput {
  AND?: InputMaybe<Array<InputMaybe<BoqUpdateWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<BoqUpdateWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BoqUpdateWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  log?: InputMaybe<JsonFilter>;
  newStandardId?: InputMaybe<IntFilter>;
  oldStandardId?: InputMaybe<IntFilter>;
}

export interface BoqUpdateWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export interface BatchPayload {
  __typename?: 'BatchPayload';
  count: Scalars['Int']['output'];
}

export interface BigIntFieldUpdateOperationsInput {
  decrement?: InputMaybe<Scalars['BigInt']['input']>;
  divide?: InputMaybe<Scalars['BigInt']['input']>;
  increment?: InputMaybe<Scalars['BigInt']['input']>;
  multiply?: InputMaybe<Scalars['BigInt']['input']>;
  set?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface BigIntFilter {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
}

export interface BigIntNullableFilter {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
}

export interface BigIntNullableWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBigIntNullableFilter>;
  _min?: InputMaybe<NestedBigIntNullableFilter>;
  _sum?: InputMaybe<NestedBigIntNullableFilter>;
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
}

export interface BigIntWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBigIntFilter>;
  _min?: InputMaybe<NestedBigIntFilter>;
  _sum?: InputMaybe<NestedBigIntFilter>;
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
}

/** Blueprint */
export interface Blueprint {
  __typename?: 'Blueprint';
  BlueprintComment: Array<BlueprintComment>;
  _count: BlueprintCountOutputType;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** Blueprint description */
  description?: Maybe<Scalars['String']['output']>;
  /** 도서 타입 */
  documentType: DocumentType;
  /** Filename */
  filename: Scalars['String']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  originalFileInfo: OriginalFileInfo;
  /** Blueprint passed or rejected */
  passed: Scalars['Boolean']['output'];
  presignedDownloadUrl?: Maybe<Scalars['String']['output']>;
  /** Related project */
  project: Project;
  /** Related project ID */
  projectId: Scalars['Int']['output'];
  /** Blueprint upload status */
  status: UploadStatus;
  /** 도서 제목 */
  title?: Maybe<Scalars['String']['output']>;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Blueprint file URL (AWS S3) */
  url: Scalars['String']['output'];
  /** Unique ID */
  uuid: Scalars['String']['output'];
}


/** Blueprint */
export type BlueprintBlueprintCommentArgs = {
  cursor?: InputMaybe<BlueprintCommentWhereUniqueInput>;
  distinct?: InputMaybe<BlueprintCommentScalarFieldEnum>;
  orderBy?: InputMaybe<BlueprintCommentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlueprintCommentWhereInput>;
};

export interface BlueprintAvgAggregateOutputType {
  __typename?: 'BlueprintAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  projectId?: Maybe<Scalars['Float']['output']>;
}

export interface BlueprintAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

/** 설계도서 코멘트 */
export interface BlueprintComment {
  __typename?: 'BlueprintComment';
  author: Member;
  blueprint: Blueprint;
  blueprintId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
}

export interface BlueprintCommentAvgAggregateOutputType {
  __typename?: 'BlueprintCommentAvgAggregateOutputType';
  blueprintId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  memberId?: Maybe<Scalars['Float']['output']>;
}

export interface BlueprintCommentAvgOrderByAggregateInput {
  blueprintId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface BlueprintCommentCountAggregateOutputType {
  __typename?: 'BlueprintCommentCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  blueprintId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface BlueprintCommentCountOrderByAggregateInput {
  blueprintId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface BlueprintCommentCreateInput {
  author: MemberCreateNestedOneWithoutBlueprintCommentInput;
  blueprint: BlueprintCreateNestedOneWithoutBlueprintCommentInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface BlueprintCommentCreateManyAuthorInput {
  blueprintId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface BlueprintCommentCreateManyAuthorInputEnvelope {
  data: BlueprintCommentCreateManyAuthorInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface BlueprintCommentCreateManyBlueprintInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  memberId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface BlueprintCommentCreateManyBlueprintInputEnvelope {
  data: BlueprintCommentCreateManyBlueprintInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface BlueprintCommentCreateManyInput {
  blueprintId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  memberId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface BlueprintCommentCreateNestedManyWithoutAuthorInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<BlueprintCommentCreateManyAuthorInputEnvelope>;
}

export interface BlueprintCommentCreateNestedManyWithoutBlueprintInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateOrConnectWithoutBlueprintInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateWithoutBlueprintInput>>>;
  createMany?: InputMaybe<BlueprintCommentCreateManyBlueprintInputEnvelope>;
}

export interface BlueprintCommentCreateOrConnectWithoutAuthorInput {
  create: BlueprintCommentUncheckedCreateWithoutAuthorInput;
  where: BlueprintCommentWhereUniqueInput;
}

export interface BlueprintCommentCreateOrConnectWithoutBlueprintInput {
  create: BlueprintCommentUncheckedCreateWithoutBlueprintInput;
  where: BlueprintCommentWhereUniqueInput;
}

export interface BlueprintCommentCreateWithoutAuthorInput {
  blueprint: BlueprintCreateNestedOneWithoutBlueprintCommentInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface BlueprintCommentCreateWithoutBlueprintInput {
  author: MemberCreateNestedOneWithoutBlueprintCommentInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface BlueprintCommentListRelationFilter {
  every?: InputMaybe<BlueprintCommentWhereInput>;
  none?: InputMaybe<BlueprintCommentWhereInput>;
  some?: InputMaybe<BlueprintCommentWhereInput>;
}

export interface BlueprintCommentMaxAggregateOutputType {
  __typename?: 'BlueprintCommentMaxAggregateOutputType';
  blueprintId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface BlueprintCommentMaxOrderByAggregateInput {
  blueprintId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface BlueprintCommentMinAggregateOutputType {
  __typename?: 'BlueprintCommentMinAggregateOutputType';
  blueprintId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface BlueprintCommentMinOrderByAggregateInput {
  blueprintId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface BlueprintCommentOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface BlueprintCommentOrderByWithAggregationInput {
  _avg?: InputMaybe<BlueprintCommentAvgOrderByAggregateInput>;
  _count?: InputMaybe<BlueprintCommentCountOrderByAggregateInput>;
  _max?: InputMaybe<BlueprintCommentMaxOrderByAggregateInput>;
  _min?: InputMaybe<BlueprintCommentMinOrderByAggregateInput>;
  _sum?: InputMaybe<BlueprintCommentSumOrderByAggregateInput>;
  blueprintId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface BlueprintCommentOrderByWithRelationInput {
  author?: InputMaybe<MemberOrderByWithRelationInput>;
  blueprint?: InputMaybe<BlueprintOrderByWithRelationInput>;
  blueprintId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export enum BlueprintCommentScalarFieldEnum {
  BlueprintId = 'blueprintId',
  CreatedAt = 'createdAt',
  Id = 'id',
  MemberId = 'memberId',
  UpdatedAt = 'updatedAt'
}

export interface BlueprintCommentScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarWhereInput>>>;
  blueprintId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  memberId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface BlueprintCommentScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarWhereWithAggregatesInput>>>;
  blueprintId?: InputMaybe<IntWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  memberId?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface BlueprintCommentSumAggregateOutputType {
  __typename?: 'BlueprintCommentSumAggregateOutputType';
  blueprintId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
}

export interface BlueprintCommentSumOrderByAggregateInput {
  blueprintId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface BlueprintCommentUncheckedCreateInput {
  blueprintId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  memberId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<BlueprintCommentCreateManyAuthorInputEnvelope>;
}

export interface BlueprintCommentUncheckedCreateNestedManyWithoutBlueprintInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateOrConnectWithoutBlueprintInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateWithoutBlueprintInput>>>;
  createMany?: InputMaybe<BlueprintCommentCreateManyBlueprintInputEnvelope>;
}

export interface BlueprintCommentUncheckedCreateWithoutAuthorInput {
  blueprintId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface BlueprintCommentUncheckedCreateWithoutBlueprintInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  memberId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface BlueprintCommentUncheckedUpdateInput {
  blueprintId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface BlueprintCommentUncheckedUpdateManyInput {
  blueprintId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<BlueprintCommentCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<BlueprintCommentUpdateWithWhereUniqueWithoutAuthorInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<BlueprintCommentUpdateManyWithWhereWithoutAuthorInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<BlueprintCommentUpsertWithWhereUniqueWithoutAuthorInput>>>;
}

export interface BlueprintCommentUncheckedUpdateManyWithoutBlueprintCommentInput {
  blueprintId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface BlueprintCommentUncheckedUpdateManyWithoutBlueprintNestedInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateOrConnectWithoutBlueprintInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateWithoutBlueprintInput>>>;
  createMany?: InputMaybe<BlueprintCommentCreateManyBlueprintInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<BlueprintCommentUpdateWithWhereUniqueWithoutBlueprintInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<BlueprintCommentUpdateManyWithWhereWithoutBlueprintInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<BlueprintCommentUpsertWithWhereUniqueWithoutBlueprintInput>>>;
}

export interface BlueprintCommentUncheckedUpdateWithoutAuthorInput {
  blueprintId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface BlueprintCommentUncheckedUpdateWithoutBlueprintInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface BlueprintCommentUpdateInput {
  author?: InputMaybe<MemberUpdateOneRequiredWithoutBlueprintCommentNestedInput>;
  blueprint?: InputMaybe<BlueprintUpdateOneRequiredWithoutBlueprintCommentNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface BlueprintCommentUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface BlueprintCommentUpdateManyWithWhereWithoutAuthorInput {
  data: BlueprintCommentUncheckedUpdateManyWithoutBlueprintCommentInput;
  where: BlueprintCommentScalarWhereInput;
}

export interface BlueprintCommentUpdateManyWithWhereWithoutBlueprintInput {
  data: BlueprintCommentUncheckedUpdateManyWithoutBlueprintCommentInput;
  where: BlueprintCommentScalarWhereInput;
}

export interface BlueprintCommentUpdateManyWithoutAuthorNestedInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<BlueprintCommentCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<BlueprintCommentUpdateWithWhereUniqueWithoutAuthorInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<BlueprintCommentUpdateManyWithWhereWithoutAuthorInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<BlueprintCommentUpsertWithWhereUniqueWithoutAuthorInput>>>;
}

export interface BlueprintCommentUpdateManyWithoutBlueprintNestedInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateOrConnectWithoutBlueprintInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCommentCreateWithoutBlueprintInput>>>;
  createMany?: InputMaybe<BlueprintCommentCreateManyBlueprintInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<BlueprintCommentUpdateWithWhereUniqueWithoutBlueprintInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<BlueprintCommentUpdateManyWithWhereWithoutBlueprintInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<BlueprintCommentUpsertWithWhereUniqueWithoutBlueprintInput>>>;
}

export interface BlueprintCommentUpdateWithWhereUniqueWithoutAuthorInput {
  data: BlueprintCommentUncheckedUpdateWithoutAuthorInput;
  where: BlueprintCommentWhereUniqueInput;
}

export interface BlueprintCommentUpdateWithWhereUniqueWithoutBlueprintInput {
  data: BlueprintCommentUncheckedUpdateWithoutBlueprintInput;
  where: BlueprintCommentWhereUniqueInput;
}

export interface BlueprintCommentUpdateWithoutAuthorInput {
  blueprint?: InputMaybe<BlueprintUpdateOneRequiredWithoutBlueprintCommentNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface BlueprintCommentUpdateWithoutBlueprintInput {
  author?: InputMaybe<MemberUpdateOneRequiredWithoutBlueprintCommentNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface BlueprintCommentUpsertWithWhereUniqueWithoutAuthorInput {
  create: BlueprintCommentUncheckedCreateWithoutAuthorInput;
  update: BlueprintCommentUncheckedUpdateWithoutAuthorInput;
  where: BlueprintCommentWhereUniqueInput;
}

export interface BlueprintCommentUpsertWithWhereUniqueWithoutBlueprintInput {
  create: BlueprintCommentUncheckedCreateWithoutBlueprintInput;
  update: BlueprintCommentUncheckedUpdateWithoutBlueprintInput;
  where: BlueprintCommentWhereUniqueInput;
}

export interface BlueprintCommentWhereInput {
  AND?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BlueprintCommentWhereInput>>>;
  author?: InputMaybe<MemberWhereInput>;
  blueprint?: InputMaybe<BlueprintWhereInput>;
  blueprintId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  memberId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface BlueprintCommentWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export interface BlueprintCountAggregateOutputType {
  __typename?: 'BlueprintCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  documentType: Scalars['Int']['output'];
  filename: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  passed: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface BlueprintCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  documentType?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  passed?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface BlueprintCountOutputType {
  __typename?: 'BlueprintCountOutputType';
  BlueprintComment: Scalars['Int']['output'];
}

export interface BlueprintCreateInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutBlueprintInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentType: DocumentType;
  filename: Scalars['String']['input'];
  passed?: InputMaybe<Scalars['Boolean']['input']>;
  project: ProjectCreateNestedOneWithoutBlueprintsInput;
  status: UploadStatus;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BlueprintCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentType: DocumentType;
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  passed?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['Int']['input'];
  status: UploadStatus;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BlueprintCreateManyProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentType: DocumentType;
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  passed?: InputMaybe<Scalars['Boolean']['input']>;
  status: UploadStatus;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BlueprintCreateManyProjectInputEnvelope {
  data: BlueprintCreateManyProjectInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface BlueprintCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<BlueprintCreateManyProjectInputEnvelope>;
}

export interface BlueprintCreateNestedOneWithoutBlueprintCommentInput {
  connect?: InputMaybe<BlueprintWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlueprintCreateOrConnectWithoutBlueprintCommentInput>;
  create?: InputMaybe<BlueprintUncheckedCreateWithoutBlueprintCommentInput>;
}

export interface BlueprintCreateOrConnectWithoutBlueprintCommentInput {
  create: BlueprintUncheckedCreateWithoutBlueprintCommentInput;
  where: BlueprintWhereUniqueInput;
}

export interface BlueprintCreateOrConnectWithoutProjectInput {
  create: BlueprintUncheckedCreateWithoutProjectInput;
  where: BlueprintWhereUniqueInput;
}

export interface BlueprintCreateWithoutBlueprintCommentInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentType: DocumentType;
  filename: Scalars['String']['input'];
  passed?: InputMaybe<Scalars['Boolean']['input']>;
  project: ProjectCreateNestedOneWithoutBlueprintsInput;
  status: UploadStatus;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BlueprintCreateWithoutProjectInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutBlueprintInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentType: DocumentType;
  filename: Scalars['String']['input'];
  passed?: InputMaybe<Scalars['Boolean']['input']>;
  status: UploadStatus;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BlueprintListRelationFilter {
  every?: InputMaybe<BlueprintWhereInput>;
  none?: InputMaybe<BlueprintWhereInput>;
  some?: InputMaybe<BlueprintWhereInput>;
}

export interface BlueprintMaxAggregateOutputType {
  __typename?: 'BlueprintMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<DocumentType>;
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  passed?: Maybe<Scalars['Boolean']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<UploadStatus>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface BlueprintMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  documentType?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  passed?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface BlueprintMinAggregateOutputType {
  __typename?: 'BlueprintMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<DocumentType>;
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  passed?: Maybe<Scalars['Boolean']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<UploadStatus>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface BlueprintMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  documentType?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  passed?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface BlueprintOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface BlueprintOrderByWithAggregationInput {
  _avg?: InputMaybe<BlueprintAvgOrderByAggregateInput>;
  _count?: InputMaybe<BlueprintCountOrderByAggregateInput>;
  _max?: InputMaybe<BlueprintMaxOrderByAggregateInput>;
  _min?: InputMaybe<BlueprintMinOrderByAggregateInput>;
  _sum?: InputMaybe<BlueprintSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  documentType?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  passed?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface BlueprintOrderByWithRelationInput {
  BlueprintComment?: InputMaybe<BlueprintCommentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  documentType?: InputMaybe<SortOrder>;
  filename?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  passed?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface BlueprintRelationFilter {
  is?: InputMaybe<BlueprintWhereInput>;
  isNot?: InputMaybe<BlueprintWhereInput>;
}

export enum BlueprintScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  DocumentType = 'documentType',
  Filename = 'filename',
  Id = 'id',
  Passed = 'passed',
  ProjectId = 'projectId',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  Uuid = 'uuid'
}

export interface BlueprintScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<BlueprintScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<BlueprintScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BlueprintScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  documentType?: InputMaybe<EnumDocumentTypeFilter>;
  filename?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  passed?: InputMaybe<BoolFilter>;
  projectId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumUploadStatusFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface BlueprintScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<BlueprintScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<BlueprintScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BlueprintScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  documentType?: InputMaybe<EnumDocumentTypeWithAggregatesFilter>;
  filename?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  passed?: InputMaybe<BoolWithAggregatesFilter>;
  projectId?: InputMaybe<IntWithAggregatesFilter>;
  status?: InputMaybe<EnumUploadStatusWithAggregatesFilter>;
  title?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  url?: InputMaybe<StringWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface BlueprintSumAggregateOutputType {
  __typename?: 'BlueprintSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
}

export interface BlueprintSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface BlueprintUncheckedCreateInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutBlueprintInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentType: DocumentType;
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  passed?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['Int']['input'];
  status: UploadStatus;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BlueprintUncheckedCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<BlueprintCreateManyProjectInputEnvelope>;
}

export interface BlueprintUncheckedCreateWithoutBlueprintCommentInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentType: DocumentType;
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  passed?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['Int']['input'];
  status: UploadStatus;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BlueprintUncheckedCreateWithoutProjectInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutBlueprintInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentType: DocumentType;
  filename: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  passed?: InputMaybe<Scalars['Boolean']['input']>;
  status: UploadStatus;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BlueprintUncheckedUpdateInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutBlueprintNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentType?: InputMaybe<EnumDocumentTypeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  passed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUploadStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BlueprintUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentType?: InputMaybe<EnumDocumentTypeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  passed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUploadStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BlueprintUncheckedUpdateManyWithoutBlueprintsInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentType?: InputMaybe<EnumDocumentTypeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  passed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUploadStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BlueprintUncheckedUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<BlueprintCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<BlueprintWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<BlueprintScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<BlueprintWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<BlueprintWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<BlueprintUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<BlueprintUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<BlueprintUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface BlueprintUncheckedUpdateWithoutBlueprintCommentInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentType?: InputMaybe<EnumDocumentTypeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  passed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUploadStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BlueprintUncheckedUpdateWithoutProjectInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutBlueprintNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentType?: InputMaybe<EnumDocumentTypeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  passed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUploadStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BlueprintUpdateInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutBlueprintNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentType?: InputMaybe<EnumDocumentTypeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  passed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutBlueprintsNestedInput>;
  status?: InputMaybe<EnumUploadStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BlueprintUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentType?: InputMaybe<EnumDocumentTypeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  passed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUploadStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BlueprintUpdateManyWithWhereWithoutProjectInput {
  data: BlueprintUncheckedUpdateManyWithoutBlueprintsInput;
  where: BlueprintScalarWhereInput;
}

export interface BlueprintUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<BlueprintWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BlueprintCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<BlueprintCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<BlueprintCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<BlueprintWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<BlueprintScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<BlueprintWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<BlueprintWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<BlueprintUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<BlueprintUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<BlueprintUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface BlueprintUpdateOneRequiredWithoutBlueprintCommentNestedInput {
  connect?: InputMaybe<BlueprintWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BlueprintCreateOrConnectWithoutBlueprintCommentInput>;
  create?: InputMaybe<BlueprintUncheckedCreateWithoutBlueprintCommentInput>;
  update?: InputMaybe<BlueprintUncheckedUpdateWithoutBlueprintCommentInput>;
  upsert?: InputMaybe<BlueprintUpsertWithoutBlueprintCommentInput>;
}

export interface BlueprintUpdateWithWhereUniqueWithoutProjectInput {
  data: BlueprintUncheckedUpdateWithoutProjectInput;
  where: BlueprintWhereUniqueInput;
}

export interface BlueprintUpdateWithoutBlueprintCommentInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentType?: InputMaybe<EnumDocumentTypeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  passed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutBlueprintsNestedInput>;
  status?: InputMaybe<EnumUploadStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BlueprintUpdateWithoutProjectInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutBlueprintNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  documentType?: InputMaybe<EnumDocumentTypeFieldUpdateOperationsInput>;
  filename?: InputMaybe<StringFieldUpdateOperationsInput>;
  passed?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUploadStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BlueprintUpsertWithWhereUniqueWithoutProjectInput {
  create: BlueprintUncheckedCreateWithoutProjectInput;
  update: BlueprintUncheckedUpdateWithoutProjectInput;
  where: BlueprintWhereUniqueInput;
}

export interface BlueprintUpsertWithoutBlueprintCommentInput {
  create: BlueprintUncheckedCreateWithoutBlueprintCommentInput;
  update: BlueprintUncheckedUpdateWithoutBlueprintCommentInput;
}

export interface BlueprintWhereInput {
  AND?: InputMaybe<Array<InputMaybe<BlueprintWhereInput>>>;
  BlueprintComment?: InputMaybe<BlueprintCommentListRelationFilter>;
  NOT?: InputMaybe<Array<InputMaybe<BlueprintWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BlueprintWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  documentType?: InputMaybe<EnumDocumentTypeFilter>;
  filename?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  passed?: InputMaybe<BoolFilter>;
  project?: InputMaybe<ProjectWhereInput>;
  projectId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumUploadStatusFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface BlueprintWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** 빌더허브 표준내역서 */
export interface BoQItem {
  __typename?: 'BoQItem';
  ProjectBoQ: Array<ProjectBoQ>;
  StandardBoQ: Array<StandardBoQ>;
  _count: BoQItemCountOutputType;
  category: Scalars['String']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dataFrom: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  laborPrice?: Maybe<Scalars['Int']['output']>;
  materialPrice?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  overheadCost?: Maybe<Scalars['Int']['output']>;
  revision: Scalars['Int']['output'];
  spec?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Int']['output']>;
  unit: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
}


/** 빌더허브 표준내역서 */
export type BoQItemProjectBoQArgs = {
  cursor?: InputMaybe<ProjectBoQWhereUniqueInput>;
  distinct?: InputMaybe<ProjectBoQScalarFieldEnum>;
  orderBy?: InputMaybe<ProjectBoQOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectBoQWhereInput>;
};


/** 빌더허브 표준내역서 */
export type BoQItemStandardBoQArgs = {
  cursor?: InputMaybe<StandardBoQWhereUniqueInput>;
  distinct?: InputMaybe<StandardBoQScalarFieldEnum>;
  orderBy?: InputMaybe<StandardBoQOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardBoQWhereInput>;
};

export interface BoQItemAvgAggregateOutputType {
  __typename?: 'BoQItemAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  laborPrice?: Maybe<Scalars['Float']['output']>;
  materialPrice?: Maybe<Scalars['Float']['output']>;
  overheadCost?: Maybe<Scalars['Float']['output']>;
  revision?: Maybe<Scalars['Float']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
}

export interface BoQItemAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  laborPrice?: InputMaybe<SortOrder>;
  materialPrice?: InputMaybe<SortOrder>;
  overheadCost?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  totalPrice?: InputMaybe<SortOrder>;
}

export interface BoQItemCodeRevisionCompoundUniqueInput {
  code: Scalars['String']['input'];
  revision: Scalars['Int']['input'];
}

export interface BoQItemCountAggregateOutputType {
  __typename?: 'BoQItemCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  category: Scalars['Int']['output'];
  code: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  dataFrom: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  laborPrice: Scalars['Int']['output'];
  materialPrice: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  overheadCost: Scalars['Int']['output'];
  revision: Scalars['Int']['output'];
  spec: Scalars['Int']['output'];
  totalPrice: Scalars['Int']['output'];
  unit: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface BoQItemCountOrderByAggregateInput {
  category?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dataFrom?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  laborPrice?: InputMaybe<SortOrder>;
  materialPrice?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  overheadCost?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  spec?: InputMaybe<SortOrder>;
  totalPrice?: InputMaybe<SortOrder>;
  unit?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface BoQItemCountOutputType {
  __typename?: 'BoQItemCountOutputType';
  ProjectBoQ: Scalars['Int']['output'];
  StandardBoQ: Scalars['Int']['output'];
}

export interface BoQItemCreateInput {
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedManyWithoutBoQItemInput>;
  StandardBoQ?: InputMaybe<StandardBoQCreateNestedManyWithoutBoQItemInput>;
  category: Scalars['String']['input'];
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dataFrom: Scalars['String']['input'];
  laborPrice?: InputMaybe<Scalars['Int']['input']>;
  materialPrice?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  overheadCost?: InputMaybe<Scalars['Int']['input']>;
  revision: Scalars['Int']['input'];
  spec?: InputMaybe<Scalars['String']['input']>;
  totalPrice?: InputMaybe<Scalars['Int']['input']>;
  unit: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BoQItemCreateManyInput {
  category: Scalars['String']['input'];
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dataFrom: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  laborPrice?: InputMaybe<Scalars['Int']['input']>;
  materialPrice?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  overheadCost?: InputMaybe<Scalars['Int']['input']>;
  revision: Scalars['Int']['input'];
  spec?: InputMaybe<Scalars['String']['input']>;
  totalPrice?: InputMaybe<Scalars['Int']['input']>;
  unit: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BoQItemCreateNestedManyWithoutProjectBoQInput {
  connect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BoQItemCreateOrConnectWithoutProjectBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<BoQItemCreateWithoutProjectBoQInput>>>;
}

export interface BoQItemCreateNestedManyWithoutStandardBoQInput {
  connect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BoQItemCreateOrConnectWithoutStandardBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<BoQItemCreateWithoutStandardBoQInput>>>;
}

export interface BoQItemCreateOrConnectWithoutProjectBoQInput {
  create: BoQItemUncheckedCreateWithoutProjectBoQInput;
  where: BoQItemWhereUniqueInput;
}

export interface BoQItemCreateOrConnectWithoutStandardBoQInput {
  create: BoQItemUncheckedCreateWithoutStandardBoQInput;
  where: BoQItemWhereUniqueInput;
}

export interface BoQItemCreateWithoutProjectBoQInput {
  StandardBoQ?: InputMaybe<StandardBoQCreateNestedManyWithoutBoQItemInput>;
  category: Scalars['String']['input'];
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dataFrom: Scalars['String']['input'];
  laborPrice?: InputMaybe<Scalars['Int']['input']>;
  materialPrice?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  overheadCost?: InputMaybe<Scalars['Int']['input']>;
  revision: Scalars['Int']['input'];
  spec?: InputMaybe<Scalars['String']['input']>;
  totalPrice?: InputMaybe<Scalars['Int']['input']>;
  unit: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BoQItemCreateWithoutStandardBoQInput {
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedManyWithoutBoQItemInput>;
  category: Scalars['String']['input'];
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dataFrom: Scalars['String']['input'];
  laborPrice?: InputMaybe<Scalars['Int']['input']>;
  materialPrice?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  overheadCost?: InputMaybe<Scalars['Int']['input']>;
  revision: Scalars['Int']['input'];
  spec?: InputMaybe<Scalars['String']['input']>;
  totalPrice?: InputMaybe<Scalars['Int']['input']>;
  unit: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BoQItemListRelationFilter {
  every?: InputMaybe<BoQItemWhereInput>;
  none?: InputMaybe<BoQItemWhereInput>;
  some?: InputMaybe<BoQItemWhereInput>;
}

export interface BoQItemMaxAggregateOutputType {
  __typename?: 'BoQItemMaxAggregateOutputType';
  category?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dataFrom?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  laborPrice?: Maybe<Scalars['Int']['output']>;
  materialPrice?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  overheadCost?: Maybe<Scalars['Int']['output']>;
  revision?: Maybe<Scalars['Int']['output']>;
  spec?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface BoQItemMaxOrderByAggregateInput {
  category?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dataFrom?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  laborPrice?: InputMaybe<SortOrder>;
  materialPrice?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  overheadCost?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  spec?: InputMaybe<SortOrder>;
  totalPrice?: InputMaybe<SortOrder>;
  unit?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface BoQItemMinAggregateOutputType {
  __typename?: 'BoQItemMinAggregateOutputType';
  category?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dataFrom?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  laborPrice?: Maybe<Scalars['Int']['output']>;
  materialPrice?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  overheadCost?: Maybe<Scalars['Int']['output']>;
  revision?: Maybe<Scalars['Int']['output']>;
  spec?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface BoQItemMinOrderByAggregateInput {
  category?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dataFrom?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  laborPrice?: InputMaybe<SortOrder>;
  materialPrice?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  overheadCost?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  spec?: InputMaybe<SortOrder>;
  totalPrice?: InputMaybe<SortOrder>;
  unit?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface BoQItemOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface BoQItemOrderByWithAggregationInput {
  _avg?: InputMaybe<BoQItemAvgOrderByAggregateInput>;
  _count?: InputMaybe<BoQItemCountOrderByAggregateInput>;
  _max?: InputMaybe<BoQItemMaxOrderByAggregateInput>;
  _min?: InputMaybe<BoQItemMinOrderByAggregateInput>;
  _sum?: InputMaybe<BoQItemSumOrderByAggregateInput>;
  category?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dataFrom?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  laborPrice?: InputMaybe<SortOrderInput>;
  materialPrice?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  overheadCost?: InputMaybe<SortOrderInput>;
  revision?: InputMaybe<SortOrder>;
  spec?: InputMaybe<SortOrderInput>;
  totalPrice?: InputMaybe<SortOrderInput>;
  unit?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface BoQItemOrderByWithRelationInput {
  ProjectBoQ?: InputMaybe<ProjectBoQOrderByRelationAggregateInput>;
  StandardBoQ?: InputMaybe<StandardBoQOrderByRelationAggregateInput>;
  category?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dataFrom?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  laborPrice?: InputMaybe<SortOrderInput>;
  materialPrice?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  overheadCost?: InputMaybe<SortOrderInput>;
  revision?: InputMaybe<SortOrder>;
  spec?: InputMaybe<SortOrderInput>;
  totalPrice?: InputMaybe<SortOrderInput>;
  unit?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface BoQItemParseResult {
  __typename?: 'BoQItemParseResult';
  BoQItem?: Maybe<Array<Maybe<BoQItem>>>;
  result: Scalars['Boolean']['output'];
}

export enum BoQItemScalarFieldEnum {
  Category = 'category',
  Code = 'code',
  CreatedAt = 'createdAt',
  DataFrom = 'dataFrom',
  Id = 'id',
  LaborPrice = 'laborPrice',
  MaterialPrice = 'materialPrice',
  Name = 'name',
  OverheadCost = 'overheadCost',
  Revision = 'revision',
  Spec = 'spec',
  TotalPrice = 'totalPrice',
  Unit = 'unit',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface BoQItemScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<BoQItemScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<BoQItemScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BoQItemScalarWhereInput>>>;
  category?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataFrom?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  laborPrice?: InputMaybe<IntNullableFilter>;
  materialPrice?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  overheadCost?: InputMaybe<IntNullableFilter>;
  revision?: InputMaybe<IntFilter>;
  spec?: InputMaybe<StringNullableFilter>;
  totalPrice?: InputMaybe<IntNullableFilter>;
  unit?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface BoQItemScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<BoQItemScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<BoQItemScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BoQItemScalarWhereWithAggregatesInput>>>;
  category?: InputMaybe<StringWithAggregatesFilter>;
  code?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  dataFrom?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  laborPrice?: InputMaybe<IntNullableWithAggregatesFilter>;
  materialPrice?: InputMaybe<IntNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  overheadCost?: InputMaybe<IntNullableWithAggregatesFilter>;
  revision?: InputMaybe<IntWithAggregatesFilter>;
  spec?: InputMaybe<StringNullableWithAggregatesFilter>;
  totalPrice?: InputMaybe<IntNullableWithAggregatesFilter>;
  unit?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface BoQItemSumAggregateOutputType {
  __typename?: 'BoQItemSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  laborPrice?: Maybe<Scalars['Int']['output']>;
  materialPrice?: Maybe<Scalars['Int']['output']>;
  overheadCost?: Maybe<Scalars['Int']['output']>;
  revision?: Maybe<Scalars['Int']['output']>;
  totalPrice?: Maybe<Scalars['Int']['output']>;
}

export interface BoQItemSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  laborPrice?: InputMaybe<SortOrder>;
  materialPrice?: InputMaybe<SortOrder>;
  overheadCost?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  totalPrice?: InputMaybe<SortOrder>;
}

export interface BoQItemUncheckedCreateInput {
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedManyWithoutBoQItemInput>;
  StandardBoQ?: InputMaybe<StandardBoQUncheckedCreateNestedManyWithoutBoQItemInput>;
  category: Scalars['String']['input'];
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dataFrom: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  laborPrice?: InputMaybe<Scalars['Int']['input']>;
  materialPrice?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  overheadCost?: InputMaybe<Scalars['Int']['input']>;
  revision: Scalars['Int']['input'];
  spec?: InputMaybe<Scalars['String']['input']>;
  totalPrice?: InputMaybe<Scalars['Int']['input']>;
  unit: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BoQItemUncheckedCreateNestedManyWithoutProjectBoQInput {
  connect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BoQItemCreateOrConnectWithoutProjectBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<BoQItemCreateWithoutProjectBoQInput>>>;
}

export interface BoQItemUncheckedCreateNestedManyWithoutStandardBoQInput {
  connect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BoQItemCreateOrConnectWithoutStandardBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<BoQItemCreateWithoutStandardBoQInput>>>;
}

export interface BoQItemUncheckedCreateWithoutProjectBoQInput {
  StandardBoQ?: InputMaybe<StandardBoQUncheckedCreateNestedManyWithoutBoQItemInput>;
  category: Scalars['String']['input'];
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dataFrom: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  laborPrice?: InputMaybe<Scalars['Int']['input']>;
  materialPrice?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  overheadCost?: InputMaybe<Scalars['Int']['input']>;
  revision: Scalars['Int']['input'];
  spec?: InputMaybe<Scalars['String']['input']>;
  totalPrice?: InputMaybe<Scalars['Int']['input']>;
  unit: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BoQItemUncheckedCreateWithoutStandardBoQInput {
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedManyWithoutBoQItemInput>;
  category: Scalars['String']['input'];
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dataFrom: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  laborPrice?: InputMaybe<Scalars['Int']['input']>;
  materialPrice?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  overheadCost?: InputMaybe<Scalars['Int']['input']>;
  revision: Scalars['Int']['input'];
  spec?: InputMaybe<Scalars['String']['input']>;
  totalPrice?: InputMaybe<Scalars['Int']['input']>;
  unit: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BoQItemUncheckedUpdateInput {
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateManyWithoutBoQItemNestedInput>;
  StandardBoQ?: InputMaybe<StandardBoQUncheckedUpdateManyWithoutBoQItemNestedInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataFrom?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  laborPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  materialPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheadCost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BoQItemUncheckedUpdateManyInput {
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataFrom?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  laborPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  materialPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheadCost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BoQItemUncheckedUpdateManyWithoutBoQItemInput {
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataFrom?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  laborPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  materialPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheadCost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BoQItemUncheckedUpdateManyWithoutProjectBoQNestedInput {
  connect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BoQItemCreateOrConnectWithoutProjectBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<BoQItemCreateWithoutProjectBoQInput>>>;
  delete?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<BoQItemScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<BoQItemUpdateWithWhereUniqueWithoutProjectBoQInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<BoQItemUpdateManyWithWhereWithoutProjectBoQInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<BoQItemUpsertWithWhereUniqueWithoutProjectBoQInput>>>;
}

export interface BoQItemUncheckedUpdateManyWithoutStandardBoQNestedInput {
  connect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BoQItemCreateOrConnectWithoutStandardBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<BoQItemCreateWithoutStandardBoQInput>>>;
  delete?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<BoQItemScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<BoQItemUpdateWithWhereUniqueWithoutStandardBoQInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<BoQItemUpdateManyWithWhereWithoutStandardBoQInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<BoQItemUpsertWithWhereUniqueWithoutStandardBoQInput>>>;
}

export interface BoQItemUncheckedUpdateWithoutProjectBoQInput {
  StandardBoQ?: InputMaybe<StandardBoQUncheckedUpdateManyWithoutBoQItemNestedInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataFrom?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  laborPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  materialPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheadCost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BoQItemUncheckedUpdateWithoutStandardBoQInput {
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateManyWithoutBoQItemNestedInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataFrom?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  laborPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  materialPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheadCost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BoQItemUpdateInput {
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateManyWithoutBoQItemNestedInput>;
  StandardBoQ?: InputMaybe<StandardBoQUpdateManyWithoutBoQItemNestedInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataFrom?: InputMaybe<StringFieldUpdateOperationsInput>;
  laborPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  materialPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheadCost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BoQItemUpdateManyMutationInput {
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataFrom?: InputMaybe<StringFieldUpdateOperationsInput>;
  laborPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  materialPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheadCost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BoQItemUpdateManyWithWhereWithoutProjectBoQInput {
  data: BoQItemUncheckedUpdateManyWithoutBoQItemInput;
  where: BoQItemScalarWhereInput;
}

export interface BoQItemUpdateManyWithWhereWithoutStandardBoQInput {
  data: BoQItemUncheckedUpdateManyWithoutBoQItemInput;
  where: BoQItemScalarWhereInput;
}

export interface BoQItemUpdateManyWithoutProjectBoQNestedInput {
  connect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BoQItemCreateOrConnectWithoutProjectBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<BoQItemCreateWithoutProjectBoQInput>>>;
  delete?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<BoQItemScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<BoQItemUpdateWithWhereUniqueWithoutProjectBoQInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<BoQItemUpdateManyWithWhereWithoutProjectBoQInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<BoQItemUpsertWithWhereUniqueWithoutProjectBoQInput>>>;
}

export interface BoQItemUpdateManyWithoutStandardBoQNestedInput {
  connect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<BoQItemCreateOrConnectWithoutStandardBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<BoQItemCreateWithoutStandardBoQInput>>>;
  delete?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<BoQItemScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<BoQItemWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<BoQItemUpdateWithWhereUniqueWithoutStandardBoQInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<BoQItemUpdateManyWithWhereWithoutStandardBoQInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<BoQItemUpsertWithWhereUniqueWithoutStandardBoQInput>>>;
}

export interface BoQItemUpdateWithWhereUniqueWithoutProjectBoQInput {
  data: BoQItemUncheckedUpdateWithoutProjectBoQInput;
  where: BoQItemWhereUniqueInput;
}

export interface BoQItemUpdateWithWhereUniqueWithoutStandardBoQInput {
  data: BoQItemUncheckedUpdateWithoutStandardBoQInput;
  where: BoQItemWhereUniqueInput;
}

export interface BoQItemUpdateWithoutProjectBoQInput {
  StandardBoQ?: InputMaybe<StandardBoQUpdateManyWithoutBoQItemNestedInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataFrom?: InputMaybe<StringFieldUpdateOperationsInput>;
  laborPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  materialPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheadCost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BoQItemUpdateWithoutStandardBoQInput {
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateManyWithoutBoQItemNestedInput>;
  category?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataFrom?: InputMaybe<StringFieldUpdateOperationsInput>;
  laborPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  materialPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  overheadCost?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  totalPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface BoQItemUpsertWithWhereUniqueWithoutProjectBoQInput {
  create: BoQItemUncheckedCreateWithoutProjectBoQInput;
  update: BoQItemUncheckedUpdateWithoutProjectBoQInput;
  where: BoQItemWhereUniqueInput;
}

export interface BoQItemUpsertWithWhereUniqueWithoutStandardBoQInput {
  create: BoQItemUncheckedCreateWithoutStandardBoQInput;
  update: BoQItemUncheckedUpdateWithoutStandardBoQInput;
  where: BoQItemWhereUniqueInput;
}

export interface BoQItemWhereInput {
  AND?: InputMaybe<Array<InputMaybe<BoQItemWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<BoQItemWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<BoQItemWhereInput>>>;
  ProjectBoQ?: InputMaybe<ProjectBoQListRelationFilter>;
  StandardBoQ?: InputMaybe<StandardBoQListRelationFilter>;
  category?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataFrom?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  laborPrice?: InputMaybe<IntNullableFilter>;
  materialPrice?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  overheadCost?: InputMaybe<IntNullableFilter>;
  revision?: InputMaybe<IntFilter>;
  spec?: InputMaybe<StringNullableFilter>;
  totalPrice?: InputMaybe<IntNullableFilter>;
  unit?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface BoQItemWhereUniqueInput {
  code_revision?: InputMaybe<BoQItemCodeRevisionCompoundUniqueInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface BoolFieldUpdateOperationsInput {
  set?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface BoolFilter {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
}

export interface BoolNullableFilter {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
}

export interface BoolNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
}

export interface BoolWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
}

export enum CsvType {
  Concrete = 'concrete',
  Formwork = 'formwork',
  Rebar = 'rebar'
}

export interface CashReceipt {
  __typename?: 'CashReceipt';
  /** 사업자등록번호 */
  businessNumber?: Maybe<Scalars['String']['output']>;
  /** 현금영수증카드번호 */
  cardNumber?: Maybe<Scalars['String']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** 발행 완료 여부 */
  issueStatus: CashReceiptStatus;
  /** 발행 타입 */
  issueType: CashReceiptType;
  /** 휴대폰번호 */
  phone?: Maybe<Scalars['String']['output']>;
  /** Project */
  project: Project;
  projectId: Scalars['Int']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Project Unique Key */
  uuid: Scalars['String']['output'];
}

export interface CashReceiptAvgAggregateOutputType {
  __typename?: 'CashReceiptAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  projectId?: Maybe<Scalars['Float']['output']>;
}

export interface CashReceiptAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface CashReceiptCountAggregateOutputType {
  __typename?: 'CashReceiptCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  businessNumber: Scalars['Int']['output'];
  cardNumber: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  issueStatus: Scalars['Int']['output'];
  issueType: Scalars['Int']['output'];
  phone: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface CashReceiptCountOrderByAggregateInput {
  businessNumber?: InputMaybe<SortOrder>;
  cardNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  issueStatus?: InputMaybe<SortOrder>;
  issueType?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface CashReceiptCreateInput {
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  issueStatus?: InputMaybe<CashReceiptStatus>;
  issueType?: InputMaybe<CashReceiptType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  project: ProjectCreateNestedOneWithoutCashReceiptInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CashReceiptCreateManyInput {
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  issueStatus?: InputMaybe<CashReceiptStatus>;
  issueType?: InputMaybe<CashReceiptType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CashReceiptCreateManyProjectInput {
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  issueStatus?: InputMaybe<CashReceiptStatus>;
  issueType?: InputMaybe<CashReceiptType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CashReceiptCreateManyProjectInputEnvelope {
  data: CashReceiptCreateManyProjectInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface CashReceiptCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<CashReceiptWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CashReceiptCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<CashReceiptCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<CashReceiptCreateManyProjectInputEnvelope>;
}

export interface CashReceiptCreateOrConnectWithoutProjectInput {
  create: CashReceiptUncheckedCreateWithoutProjectInput;
  where: CashReceiptWhereUniqueInput;
}

export interface CashReceiptCreateWithoutProjectInput {
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  issueStatus?: InputMaybe<CashReceiptStatus>;
  issueType?: InputMaybe<CashReceiptType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CashReceiptListRelationFilter {
  every?: InputMaybe<CashReceiptWhereInput>;
  none?: InputMaybe<CashReceiptWhereInput>;
  some?: InputMaybe<CashReceiptWhereInput>;
}

export interface CashReceiptMaxAggregateOutputType {
  __typename?: 'CashReceiptMaxAggregateOutputType';
  businessNumber?: Maybe<Scalars['String']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  issueStatus?: Maybe<CashReceiptStatus>;
  issueType?: Maybe<CashReceiptType>;
  phone?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface CashReceiptMaxOrderByAggregateInput {
  businessNumber?: InputMaybe<SortOrder>;
  cardNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  issueStatus?: InputMaybe<SortOrder>;
  issueType?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface CashReceiptMinAggregateOutputType {
  __typename?: 'CashReceiptMinAggregateOutputType';
  businessNumber?: Maybe<Scalars['String']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  issueStatus?: Maybe<CashReceiptStatus>;
  issueType?: Maybe<CashReceiptType>;
  phone?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface CashReceiptMinOrderByAggregateInput {
  businessNumber?: InputMaybe<SortOrder>;
  cardNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  issueStatus?: InputMaybe<SortOrder>;
  issueType?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface CashReceiptOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface CashReceiptOrderByWithAggregationInput {
  _avg?: InputMaybe<CashReceiptAvgOrderByAggregateInput>;
  _count?: InputMaybe<CashReceiptCountOrderByAggregateInput>;
  _max?: InputMaybe<CashReceiptMaxOrderByAggregateInput>;
  _min?: InputMaybe<CashReceiptMinOrderByAggregateInput>;
  _sum?: InputMaybe<CashReceiptSumOrderByAggregateInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  cardNumber?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  issueStatus?: InputMaybe<SortOrder>;
  issueType?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrderInput>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface CashReceiptOrderByWithRelationInput {
  businessNumber?: InputMaybe<SortOrderInput>;
  cardNumber?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  issueStatus?: InputMaybe<SortOrder>;
  issueType?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrderInput>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export enum CashReceiptScalarFieldEnum {
  BusinessNumber = 'businessNumber',
  CardNumber = 'cardNumber',
  CreatedAt = 'createdAt',
  Id = 'id',
  IssueStatus = 'issueStatus',
  IssueType = 'issueType',
  Phone = 'phone',
  ProjectId = 'projectId',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface CashReceiptScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<CashReceiptScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CashReceiptScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CashReceiptScalarWhereInput>>>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  cardNumber?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  issueStatus?: InputMaybe<EnumCashReceiptStatusFilter>;
  issueType?: InputMaybe<EnumCashReceiptTypeFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  projectId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface CashReceiptScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<CashReceiptScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CashReceiptScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CashReceiptScalarWhereWithAggregatesInput>>>;
  businessNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  cardNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  issueStatus?: InputMaybe<EnumCashReceiptStatusWithAggregatesFilter>;
  issueType?: InputMaybe<EnumCashReceiptTypeWithAggregatesFilter>;
  phone?: InputMaybe<StringNullableWithAggregatesFilter>;
  projectId?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export enum CashReceiptStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  NotIssued = 'NOT_ISSUED',
  Waiting = 'WAITING'
}

export interface CashReceiptSumAggregateOutputType {
  __typename?: 'CashReceiptSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
}

export interface CashReceiptSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export enum CashReceiptType {
  BusinessCard = 'BUSINESS_CARD',
  BusinessNumber = 'BUSINESS_NUMBER',
  IndividualCard = 'INDIVIDUAL_CARD',
  IndividualPhone = 'INDIVIDUAL_PHONE',
  NotIssued = 'NOT_ISSUED'
}

export interface CashReceiptUncheckedCreateInput {
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  issueStatus?: InputMaybe<CashReceiptStatus>;
  issueType?: InputMaybe<CashReceiptType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CashReceiptUncheckedCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<CashReceiptWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CashReceiptCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<CashReceiptCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<CashReceiptCreateManyProjectInputEnvelope>;
}

export interface CashReceiptUncheckedCreateWithoutProjectInput {
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  cardNumber?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  issueStatus?: InputMaybe<CashReceiptStatus>;
  issueType?: InputMaybe<CashReceiptType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CashReceiptUncheckedUpdateInput {
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cardNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  issueStatus?: InputMaybe<EnumCashReceiptStatusFieldUpdateOperationsInput>;
  issueType?: InputMaybe<EnumCashReceiptTypeFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CashReceiptUncheckedUpdateManyInput {
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cardNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  issueStatus?: InputMaybe<EnumCashReceiptStatusFieldUpdateOperationsInput>;
  issueType?: InputMaybe<EnumCashReceiptTypeFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CashReceiptUncheckedUpdateManyWithoutCashReceiptInput {
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cardNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  issueStatus?: InputMaybe<EnumCashReceiptStatusFieldUpdateOperationsInput>;
  issueType?: InputMaybe<EnumCashReceiptTypeFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CashReceiptUncheckedUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<CashReceiptWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CashReceiptCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<CashReceiptCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<CashReceiptCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<CashReceiptWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CashReceiptScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CashReceiptWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CashReceiptWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CashReceiptUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CashReceiptUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CashReceiptUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface CashReceiptUncheckedUpdateWithoutProjectInput {
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cardNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  issueStatus?: InputMaybe<EnumCashReceiptStatusFieldUpdateOperationsInput>;
  issueType?: InputMaybe<EnumCashReceiptTypeFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CashReceiptUpdateInput {
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cardNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  issueStatus?: InputMaybe<EnumCashReceiptStatusFieldUpdateOperationsInput>;
  issueType?: InputMaybe<EnumCashReceiptTypeFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCashReceiptNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CashReceiptUpdateManyMutationInput {
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cardNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  issueStatus?: InputMaybe<EnumCashReceiptStatusFieldUpdateOperationsInput>;
  issueType?: InputMaybe<EnumCashReceiptTypeFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CashReceiptUpdateManyWithWhereWithoutProjectInput {
  data: CashReceiptUncheckedUpdateManyWithoutCashReceiptInput;
  where: CashReceiptScalarWhereInput;
}

export interface CashReceiptUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<CashReceiptWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CashReceiptCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<CashReceiptCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<CashReceiptCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<CashReceiptWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CashReceiptScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CashReceiptWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CashReceiptWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CashReceiptUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CashReceiptUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CashReceiptUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface CashReceiptUpdateWithWhereUniqueWithoutProjectInput {
  data: CashReceiptUncheckedUpdateWithoutProjectInput;
  where: CashReceiptWhereUniqueInput;
}

export interface CashReceiptUpdateWithoutProjectInput {
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cardNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  issueStatus?: InputMaybe<EnumCashReceiptStatusFieldUpdateOperationsInput>;
  issueType?: InputMaybe<EnumCashReceiptTypeFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CashReceiptUpsertWithWhereUniqueWithoutProjectInput {
  create: CashReceiptUncheckedCreateWithoutProjectInput;
  update: CashReceiptUncheckedUpdateWithoutProjectInput;
  where: CashReceiptWhereUniqueInput;
}

export interface CashReceiptWhereInput {
  AND?: InputMaybe<Array<InputMaybe<CashReceiptWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CashReceiptWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CashReceiptWhereInput>>>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  cardNumber?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  issueStatus?: InputMaybe<EnumCashReceiptStatusFilter>;
  issueType?: InputMaybe<EnumCashReceiptTypeFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectWhereInput>;
  projectId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface CashReceiptWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CategoryCost {
  __typename?: 'CategoryCost';
  civil: Scalars['Float']['output'];
  communication: Scalars['Float']['output'];
  construction: Scalars['Float']['output'];
  electronics: Scalars['Float']['output'];
  facility: Scalars['Float']['output'];
  fire: Scalars['Float']['output'];
  landscape: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
}

export interface CategoryRatio {
  __typename?: 'CategoryRatio';
  civil: Scalars['Float']['output'];
  communication: Scalars['Float']['output'];
  construction: Scalars['Float']['output'];
  electronics: Scalars['Float']['output'];
  facility: Scalars['Float']['output'];
  fire: Scalars['Float']['output'];
  landscape: Scalars['Float']['output'];
}

export interface Certification {
  __typename?: 'Certification';
  /** 0이면 정상적인 조회, 0아닌 값이면 message를 확인해봐야 합니다 */
  code?: Maybe<Scalars['Int']['output']>;
  /** code값이 0이 아닐 때, '존재하지 않는 결제정보입니다'와 같은 오류 메세지를 포함합니다. */
  message?: Maybe<Scalars['String']['output']>;
  /** AWS Lambda `/identity`를 마이그레이션, 본인인증 정보의 메타 정보를 담음 */
  meta: CheckIdentity;
  response?: Maybe<CertificationAnnotation>;
}

/** https://api.iamport.kr/#!/certifications/getCertification */
export interface CertificationAnnotation {
  __typename?: 'CertificationAnnotation';
  /** 인증결과-생년월일 ISO8601 형식의 문자열. YYYY-MM-DD 10자리 문자열 */
  birthday?: Maybe<Scalars['String']['output']>;
  /** 인증결과-사용된 휴대폰번호의 통신사(신용카드 본인인증의 경우 해당사항없음). 통신사 사전승인이 이뤄지지 않으면 carrier 속성은 존재하지 않습니다. 통신사 사전승인이 필요하므로 cs@iamport.kr 로 다날 CPID 와 함께 사용승인 요청주시면 안내도와드리겠습니다. = ['SKT', 'KT', 'LGT', 'SKT_MVNO', 'KT_MVNO', 'LGT_MVNO'] */
  carrier?: Maybe<Scalars['String']['output']>;
  /** 인증성공여부 */
  certified?: Maybe<Scalars['Boolean']['output']>;
  /** 인증처리시각 UNIX timestamp */
  certified_at?: Maybe<Scalars['String']['output']>;
  /** 인증결과-외국인여부. true:외국인, false:내국인. 다날 본인인증서비스 계약시 외국인 구분기능 추가 요청을 해주셔야 사용이 가능합니다. */
  foreigner: Scalars['Boolean']['output'];
  /** nullable한 인증결과-외국인여부. true:외국인, false:내국인. 다날 본인인증서비스 계약시 외국인 구분기능 추가 요청을 해주셔야 사용이 가능합니다. */
  foreigner_v2?: Maybe<Scalars['Boolean']['output']>;
  /** 인증결과-성별. male:남성, female:여성 */
  gender?: Maybe<Scalars['String']['output']>;
  /** string 타입의 아임포트 SMS본인인증처리 고유번호 */
  imp_uid: Scalars['String']['output'];
  /** string 타입의 가맹점 주문번호 */
  merchant_uid?: Maybe<Scalars['String']['output']>;
  /** 인증결과-실명 */
  name?: Maybe<Scalars['String']['output']>;
  /** 본인인증 프로세스가 진행된 웹 페이지의 URL */
  origin?: Maybe<Scalars['String']['output']>;
  /** PG사 SMS본인인증결과 고유번호 */
  pg_id?: Maybe<Scalars['String']['output']>;
  /** SMS본인인증 제공 PG사 명칭. danal(다날) */
  pg_provider: Scalars['String']['output'];
  /** 인증결과-사용된 휴대폰번호(신용카드 본인인증의 경우 해당사항없음). 특수기호없이 숫자로만 구성된 휴대폰번호가 전달됨. 통신사 사전승인이 이뤄지지 않으면 phone 속성은 존재하지 않습니다. 통신사 사전승인이 필요하므로 cs@iamport.kr 로 다날 CPID 와 함께 사용승인 요청주시면 안내도와드리겠습니다. */
  phone?: Maybe<Scalars['String']['output']>;
  /** 가맹점 내 개인 고유구분 식별키(다날 매뉴얼 기준 DI). 본인인증 PG MID별로 할당되는 개인 식별키 */
  unique_in_site?: Maybe<Scalars['String']['output']>;
  /** 개인 고유구분 식별키(다날 매뉴얼 기준 CI) */
  unique_key?: Maybe<Scalars['String']['output']>;
}

/** AWS Lambda `/identity`를 마이그레이션, 본인인증 정보의 메타 정보를 담음 */
export interface CheckIdentity {
  __typename?: 'CheckIdentity';
  /** 이메일 주소 */
  email?: Maybe<Scalars['String']['output']>;
  /** 본인인증을 한 적이 있는지 판단 */
  isNotHaveUniqueKey: Scalars['Boolean']['output'];
}

export enum CivilWorkCategory {
  Earthwork = 'earthwork',
  ExcavationWork = 'excavationWork',
  GeosyntheticInstallationWork = 'geosyntheticInstallationWork',
  OtherWork = 'otherWork',
  PavementWork = 'pavementWork',
  SewageWork = 'sewageWork',
  StructuralWork = 'structuralWork',
  SupplyMaterialsCost = 'supplyMaterialsCost',
  TransportationCost = 'transportationCost',
  WaterSupplyWork = 'waterSupplyWork'
}

/** Civil work Share ratio of works(토목공사 점유율): '토공사' '우수공사' '오수공사' '상수도공사' '포장공사' '사급자재비' '운반비' '기타공사(토목)' '흙막이가시설공사' '구조물공사' */
export interface CivilWorkSrw {
  __typename?: 'CivilWorkSRW';
  ShareRatioWork: Array<ShareRatioWork>;
  _count: CivilWorkSrwCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  mainWork: MainWorkCategory;
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  work: CivilWorkCategory;
  workName: Scalars['String']['output'];
}


/** Civil work Share ratio of works(토목공사 점유율): '토공사' '우수공사' '오수공사' '상수도공사' '포장공사' '사급자재비' '운반비' '기타공사(토목)' '흙막이가시설공사' '구조물공사' */
export type CivilWorkSrwShareRatioWorkArgs = {
  cursor?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  distinct?: InputMaybe<ShareRatioWorkScalarFieldEnum>;
  orderBy?: InputMaybe<ShareRatioWorkOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};

export interface CivilWorkSrwAvgAggregateOutputType {
  __typename?: 'CivilWorkSRWAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface CivilWorkSrwAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface CivilWorkSrwCountAggregateOutputType {
  __typename?: 'CivilWorkSRWCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  mainWork: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  work: Scalars['Int']['output'];
  workName: Scalars['Int']['output'];
}

export interface CivilWorkSrwCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface CivilWorkSrwCountOutputType {
  __typename?: 'CivilWorkSRWCountOutputType';
  ShareRatioWork: Scalars['Int']['output'];
}

export interface CivilWorkSrwCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedManyWithoutCivilWorkInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: CivilWorkCategory;
  workName: Scalars['String']['input'];
}

export interface CivilWorkSrwCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: CivilWorkCategory;
  workName: Scalars['String']['input'];
}

export interface CivilWorkSrwCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CivilWorkSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<CivilWorkSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface CivilWorkSrwCreateOrConnectWithoutShareRatioWorkInput {
  create: CivilWorkSrwUncheckedCreateWithoutShareRatioWorkInput;
  where: CivilWorkSrwWhereUniqueInput;
}

export interface CivilWorkSrwCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: CivilWorkCategory;
  workName: Scalars['String']['input'];
}

export interface CivilWorkSrwListRelationFilter {
  every?: InputMaybe<CivilWorkSrwWhereInput>;
  none?: InputMaybe<CivilWorkSrwWhereInput>;
  some?: InputMaybe<CivilWorkSrwWhereInput>;
}

export interface CivilWorkSrwMaxAggregateOutputType {
  __typename?: 'CivilWorkSRWMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<CivilWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface CivilWorkSrwMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface CivilWorkSrwMinAggregateOutputType {
  __typename?: 'CivilWorkSRWMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<CivilWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface CivilWorkSrwMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface CivilWorkSrwOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface CivilWorkSrwOrderByWithAggregationInput {
  _avg?: InputMaybe<CivilWorkSrwAvgOrderByAggregateInput>;
  _count?: InputMaybe<CivilWorkSrwCountOrderByAggregateInput>;
  _max?: InputMaybe<CivilWorkSrwMaxOrderByAggregateInput>;
  _min?: InputMaybe<CivilWorkSrwMinOrderByAggregateInput>;
  _sum?: InputMaybe<CivilWorkSrwSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface CivilWorkSrwOrderByWithRelationInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export enum CivilWorkSrwScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  MainWork = 'mainWork',
  Order = 'order',
  ShareRatio = 'shareRatio',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  Work = 'work',
  WorkName = 'workName'
}

export interface CivilWorkSrwScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<CivilWorkSrwScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CivilWorkSrwScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CivilWorkSrwScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumCivilWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface CivilWorkSrwScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<CivilWorkSrwScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CivilWorkSrwScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CivilWorkSrwScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  shareRatio?: InputMaybe<FloatWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  work?: InputMaybe<EnumCivilWorkCategoryWithAggregatesFilter>;
  workName?: InputMaybe<StringWithAggregatesFilter>;
}

export interface CivilWorkSrwSumAggregateOutputType {
  __typename?: 'CivilWorkSRWSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface CivilWorkSrwSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface CivilWorkSrwUncheckedCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedCreateNestedManyWithoutCivilWorkInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: CivilWorkCategory;
  workName: Scalars['String']['input'];
}

export interface CivilWorkSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CivilWorkSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<CivilWorkSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface CivilWorkSrwUncheckedCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: CivilWorkCategory;
  workName: Scalars['String']['input'];
}

export interface CivilWorkSrwUncheckedUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedUpdateManyWithoutCivilWorkNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCivilWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CivilWorkSrwUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCivilWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CivilWorkSrwUncheckedUpdateManyWithoutCivilWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCivilWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CivilWorkSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CivilWorkSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<CivilWorkSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CivilWorkSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CivilWorkSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CivilWorkSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CivilWorkSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface CivilWorkSrwUncheckedUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCivilWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CivilWorkSrwUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUpdateManyWithoutCivilWorkNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCivilWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CivilWorkSrwUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCivilWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CivilWorkSrwUpdateManyWithWhereWithoutShareRatioWorkInput {
  data: CivilWorkSrwUncheckedUpdateManyWithoutCivilWorkInput;
  where: CivilWorkSrwScalarWhereInput;
}

export interface CivilWorkSrwUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CivilWorkSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<CivilWorkSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CivilWorkSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CivilWorkSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CivilWorkSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CivilWorkSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface CivilWorkSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput {
  data: CivilWorkSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: CivilWorkSrwWhereUniqueInput;
}

export interface CivilWorkSrwUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCivilWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CivilWorkSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput {
  create: CivilWorkSrwUncheckedCreateWithoutShareRatioWorkInput;
  update: CivilWorkSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: CivilWorkSrwWhereUniqueInput;
}

export interface CivilWorkSrwWhereInput {
  AND?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CivilWorkSrwWhereInput>>>;
  ShareRatioWork?: InputMaybe<ShareRatioWorkListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumCivilWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface CivilWorkSrwWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** Communication Share ratio of works(통신공사 점유율): '옥외통신설비공사' '통합배선설비공사' 'CATV 및 TV 설비공사' '방송설비공사' 'A/V설비공사' 'CCTV설비공사' '출입통제설비공사' '주차관제설비공사' '케이블트레이 및 덕트설치공사' '기타공사(통신)' */
export interface CommunicationSrw {
  __typename?: 'CommunicationSRW';
  ShareRatioWork: Array<ShareRatioWork>;
  _count: CommunicationSrwCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  mainWork: MainWorkCategory;
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  work: CommunicationWorkCategory;
  workName: Scalars['String']['output'];
}


/** Communication Share ratio of works(통신공사 점유율): '옥외통신설비공사' '통합배선설비공사' 'CATV 및 TV 설비공사' '방송설비공사' 'A/V설비공사' 'CCTV설비공사' '출입통제설비공사' '주차관제설비공사' '케이블트레이 및 덕트설치공사' '기타공사(통신)' */
export type CommunicationSrwShareRatioWorkArgs = {
  cursor?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  distinct?: InputMaybe<ShareRatioWorkScalarFieldEnum>;
  orderBy?: InputMaybe<ShareRatioWorkOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};

export interface CommunicationSrwAvgAggregateOutputType {
  __typename?: 'CommunicationSRWAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface CommunicationSrwAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface CommunicationSrwCountAggregateOutputType {
  __typename?: 'CommunicationSRWCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  mainWork: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  work: Scalars['Int']['output'];
  workName: Scalars['Int']['output'];
}

export interface CommunicationSrwCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface CommunicationSrwCountOutputType {
  __typename?: 'CommunicationSRWCountOutputType';
  ShareRatioWork: Scalars['Int']['output'];
}

export interface CommunicationSrwCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedManyWithoutCommunicationInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: CommunicationWorkCategory;
  workName: Scalars['String']['input'];
}

export interface CommunicationSrwCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: CommunicationWorkCategory;
  workName: Scalars['String']['input'];
}

export interface CommunicationSrwCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CommunicationSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<CommunicationSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface CommunicationSrwCreateOrConnectWithoutShareRatioWorkInput {
  create: CommunicationSrwUncheckedCreateWithoutShareRatioWorkInput;
  where: CommunicationSrwWhereUniqueInput;
}

export interface CommunicationSrwCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: CommunicationWorkCategory;
  workName: Scalars['String']['input'];
}

export interface CommunicationSrwListRelationFilter {
  every?: InputMaybe<CommunicationSrwWhereInput>;
  none?: InputMaybe<CommunicationSrwWhereInput>;
  some?: InputMaybe<CommunicationSrwWhereInput>;
}

export interface CommunicationSrwMaxAggregateOutputType {
  __typename?: 'CommunicationSRWMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<CommunicationWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface CommunicationSrwMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface CommunicationSrwMinAggregateOutputType {
  __typename?: 'CommunicationSRWMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<CommunicationWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface CommunicationSrwMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface CommunicationSrwOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface CommunicationSrwOrderByWithAggregationInput {
  _avg?: InputMaybe<CommunicationSrwAvgOrderByAggregateInput>;
  _count?: InputMaybe<CommunicationSrwCountOrderByAggregateInput>;
  _max?: InputMaybe<CommunicationSrwMaxOrderByAggregateInput>;
  _min?: InputMaybe<CommunicationSrwMinOrderByAggregateInput>;
  _sum?: InputMaybe<CommunicationSrwSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface CommunicationSrwOrderByWithRelationInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export enum CommunicationSrwScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  MainWork = 'mainWork',
  Order = 'order',
  ShareRatio = 'shareRatio',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  Work = 'work',
  WorkName = 'workName'
}

export interface CommunicationSrwScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<CommunicationSrwScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CommunicationSrwScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CommunicationSrwScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumCommunicationWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface CommunicationSrwScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<CommunicationSrwScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CommunicationSrwScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CommunicationSrwScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  shareRatio?: InputMaybe<FloatWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  work?: InputMaybe<EnumCommunicationWorkCategoryWithAggregatesFilter>;
  workName?: InputMaybe<StringWithAggregatesFilter>;
}

export interface CommunicationSrwSumAggregateOutputType {
  __typename?: 'CommunicationSRWSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface CommunicationSrwSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface CommunicationSrwUncheckedCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedCreateNestedManyWithoutCommunicationInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: CommunicationWorkCategory;
  workName: Scalars['String']['input'];
}

export interface CommunicationSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CommunicationSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<CommunicationSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface CommunicationSrwUncheckedCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: CommunicationWorkCategory;
  workName: Scalars['String']['input'];
}

export interface CommunicationSrwUncheckedUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedUpdateManyWithoutCommunicationNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCommunicationWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CommunicationSrwUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCommunicationWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CommunicationSrwUncheckedUpdateManyWithoutCommunicationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCommunicationWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CommunicationSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CommunicationSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<CommunicationSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CommunicationSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CommunicationSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CommunicationSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CommunicationSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface CommunicationSrwUncheckedUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCommunicationWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CommunicationSrwUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUpdateManyWithoutCommunicationNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCommunicationWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CommunicationSrwUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCommunicationWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CommunicationSrwUpdateManyWithWhereWithoutShareRatioWorkInput {
  data: CommunicationSrwUncheckedUpdateManyWithoutCommunicationInput;
  where: CommunicationSrwScalarWhereInput;
}

export interface CommunicationSrwUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CommunicationSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<CommunicationSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CommunicationSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CommunicationSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CommunicationSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CommunicationSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface CommunicationSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput {
  data: CommunicationSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: CommunicationSrwWhereUniqueInput;
}

export interface CommunicationSrwUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumCommunicationWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CommunicationSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput {
  create: CommunicationSrwUncheckedCreateWithoutShareRatioWorkInput;
  update: CommunicationSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: CommunicationSrwWhereUniqueInput;
}

export interface CommunicationSrwWhereInput {
  AND?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CommunicationSrwWhereInput>>>;
  ShareRatioWork?: InputMaybe<ShareRatioWorkListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumCommunicationWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface CommunicationSrwWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export enum CommunicationWorkCategory {
  CatvAndTvEquipmentInstallationWork = 'CATVAndTVEquipmentInstallationWork',
  CctvEquipmentInstallationWork = 'CCTVEquipmentInstallationWork',
  AccessControlSystemInstallationWork = 'accessControlSystemInstallationWork',
  AudioVisualEquipmentInstallationWork = 'audioVisualEquipmentInstallationWork',
  BroadcastingEquipmentInstallationWork = 'broadcastingEquipmentInstallationWork',
  CableTrayAndDuctInstallationWork = 'cableTrayAndDuctInstallationWork',
  IntegratedCablingSystemInstallationWork = 'integratedCablingSystemInstallationWork',
  OtherWork = 'otherWork',
  OutdoorCommunicationFacilityInstallationWork = 'outdoorCommunicationFacilityInstallationWork',
  ParkingControlSystemInstallationWork = 'parkingControlSystemInstallationWork'
}

/** Partner: 전문건설사 */
export interface Construction {
  __typename?: 'Construction';
  _count: ConstructionCountOutputType;
  /** Partner user address */
  address?: Maybe<Address>;
  addressDetail?: Maybe<Scalars['String']['output']>;
  /** Partner user addressId */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** Avatar URL */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** Partner businessNumber (사업자등록번호) */
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Name */
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partner: Partner;
  partnerId: Scalars['Int']['output'];
  partnerStatus: PartnerRegistrationStatus;
  profile: Array<Profile>;
  /** 사업자등록증 사진 키 */
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail: Scalars['String']['output'];
  responsibilityName: Scalars['String']['output'];
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  /** Partner user role */
  role: PartnerRole;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}


/** Partner: 전문건설사 */
export type ConstructionProfileArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<ProfileScalarFieldEnum>;
  orderBy?: InputMaybe<ProfileOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};

export interface ConstructionAvgAggregateOutputType {
  __typename?: 'ConstructionAvgAggregateOutputType';
  addressId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  partnerId?: Maybe<Scalars['Float']['output']>;
}

export interface ConstructionAvgOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface ConstructionCountAggregateOutputType {
  __typename?: 'ConstructionCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  addressDetail: Scalars['Int']['output'];
  addressId: Scalars['Int']['output'];
  avatarUrl: Scalars['Int']['output'];
  businessNumber: Scalars['Int']['output'];
  companyContact: Scalars['Int']['output'];
  companyDescription: Scalars['Int']['output'];
  companyWebsite: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  ownerName: Scalars['Int']['output'];
  partnerId: Scalars['Int']['output'];
  partnerStatus: Scalars['Int']['output'];
  registrationPhotoKey: Scalars['Int']['output'];
  responsibilityEmail: Scalars['Int']['output'];
  responsibilityName: Scalars['Int']['output'];
  responsibilityPosition: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface ConstructionCountOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ConstructionCountOutputType {
  __typename?: 'ConstructionCountOutputType';
  profile: Scalars['Int']['output'];
}

export interface ConstructionCreateInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutConstructionInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutConstructionInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ConstructionCreateManyAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ConstructionCreateManyAddressInputEnvelope {
  data: ConstructionCreateManyAddressInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ConstructionCreateManyInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ConstructionCreateManyPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ConstructionCreateManyPartnerInputEnvelope {
  data: ConstructionCreateManyPartnerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ConstructionCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ConstructionCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ConstructionCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ConstructionCreateManyAddressInputEnvelope>;
}

export interface ConstructionCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ConstructionCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ConstructionCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ConstructionCreateManyPartnerInputEnvelope>;
}

export interface ConstructionCreateNestedOneWithoutProfileInput {
  connect?: InputMaybe<ConstructionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConstructionCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<ConstructionUncheckedCreateWithoutProfileInput>;
}

export interface ConstructionCreateOrConnectWithoutAddressInput {
  create: ConstructionUncheckedCreateWithoutAddressInput;
  where: ConstructionWhereUniqueInput;
}

export interface ConstructionCreateOrConnectWithoutPartnerInput {
  create: ConstructionUncheckedCreateWithoutPartnerInput;
  where: ConstructionWhereUniqueInput;
}

export interface ConstructionCreateOrConnectWithoutProfileInput {
  create: ConstructionUncheckedCreateWithoutProfileInput;
  where: ConstructionWhereUniqueInput;
}

export interface ConstructionCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutConstructionInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ConstructionCreateWithoutPartnerInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutConstructionInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ConstructionCreateWithoutProfileInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutConstructionInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutConstructionInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ConstructionListRelationFilter {
  every?: InputMaybe<ConstructionWhereInput>;
  none?: InputMaybe<ConstructionWhereInput>;
  some?: InputMaybe<ConstructionWhereInput>;
}

export interface ConstructionMaxAggregateOutputType {
  __typename?: 'ConstructionMaxAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface ConstructionMaxOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ConstructionMinAggregateOutputType {
  __typename?: 'ConstructionMinAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface ConstructionMinOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ConstructionOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ConstructionOrderByWithAggregationInput {
  _avg?: InputMaybe<ConstructionAvgOrderByAggregateInput>;
  _count?: InputMaybe<ConstructionCountOrderByAggregateInput>;
  _max?: InputMaybe<ConstructionMaxOrderByAggregateInput>;
  _min?: InputMaybe<ConstructionMinOrderByAggregateInput>;
  _sum?: InputMaybe<ConstructionSumOrderByAggregateInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ConstructionOrderByWithRelationInput {
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partner?: InputMaybe<PartnerOrderByWithRelationInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByRelationAggregateInput>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ConstructionRelationFilter {
  is?: InputMaybe<ConstructionWhereInput>;
  isNot?: InputMaybe<ConstructionWhereInput>;
}

export enum ConstructionScalarFieldEnum {
  AddressDetail = 'addressDetail',
  AddressId = 'addressId',
  AvatarUrl = 'avatarUrl',
  BusinessNumber = 'businessNumber',
  CompanyContact = 'companyContact',
  CompanyDescription = 'companyDescription',
  CompanyWebsite = 'companyWebsite',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OwnerName = 'ownerName',
  PartnerId = 'partnerId',
  PartnerStatus = 'partnerStatus',
  RegistrationPhotoKey = 'registrationPhotoKey',
  ResponsibilityEmail = 'responsibilityEmail',
  ResponsibilityName = 'responsibilityName',
  ResponsibilityPosition = 'responsibilityPosition',
  Role = 'role',
  UpdatedAt = 'updatedAt'
}

export interface ConstructionScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ConstructionScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ConstructionScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ConstructionScalarWhereInput>>>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface ConstructionScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ConstructionScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ConstructionScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ConstructionScalarWhereWithAggregatesInput>>>;
  addressDetail?: InputMaybe<StringNullableWithAggregatesFilter>;
  addressId?: InputMaybe<IntNullableWithAggregatesFilter>;
  avatarUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  businessNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyContact?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyDescription?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyWebsite?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  ownerName?: InputMaybe<StringNullableWithAggregatesFilter>;
  partnerId?: InputMaybe<IntWithAggregatesFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusWithAggregatesFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableWithAggregatesFilter>;
  responsibilityEmail?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityName?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityPosition?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumPartnerRoleWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface ConstructionSumAggregateOutputType {
  __typename?: 'ConstructionSumAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
}

export interface ConstructionSumOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface ConstructionUncheckedCreateInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ConstructionUncheckedCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ConstructionCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ConstructionCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ConstructionCreateManyAddressInputEnvelope>;
}

export interface ConstructionUncheckedCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ConstructionCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ConstructionCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ConstructionCreateManyPartnerInputEnvelope>;
}

export interface ConstructionUncheckedCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ConstructionUncheckedCreateWithoutPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ConstructionUncheckedCreateWithoutProfileInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ConstructionUncheckedUpdateInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ConstructionUncheckedUpdateManyInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ConstructionUncheckedUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ConstructionCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ConstructionCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ConstructionCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ConstructionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ConstructionUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ConstructionUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ConstructionUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface ConstructionUncheckedUpdateManyWithoutConstructionInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ConstructionUncheckedUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ConstructionCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ConstructionCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ConstructionCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ConstructionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ConstructionUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ConstructionUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ConstructionUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface ConstructionUncheckedUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ConstructionUncheckedUpdateWithoutPartnerInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ConstructionUncheckedUpdateWithoutProfileInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ConstructionUpdateInput {
  address?: InputMaybe<AddressUpdateOneWithoutConstructionNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutConstructionNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ConstructionUpdateManyMutationInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ConstructionUpdateManyWithWhereWithoutAddressInput {
  data: ConstructionUncheckedUpdateManyWithoutConstructionInput;
  where: ConstructionScalarWhereInput;
}

export interface ConstructionUpdateManyWithWhereWithoutPartnerInput {
  data: ConstructionUncheckedUpdateManyWithoutConstructionInput;
  where: ConstructionScalarWhereInput;
}

export interface ConstructionUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ConstructionCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ConstructionCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ConstructionCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ConstructionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ConstructionUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ConstructionUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ConstructionUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface ConstructionUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ConstructionCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ConstructionCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ConstructionCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ConstructionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ConstructionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ConstructionUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ConstructionUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ConstructionUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface ConstructionUpdateOneWithoutProfileNestedInput {
  connect?: InputMaybe<ConstructionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ConstructionCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<ConstructionUncheckedCreateWithoutProfileInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ConstructionUncheckedUpdateWithoutProfileInput>;
  upsert?: InputMaybe<ConstructionUpsertWithoutProfileInput>;
}

export interface ConstructionUpdateWithWhereUniqueWithoutAddressInput {
  data: ConstructionUncheckedUpdateWithoutAddressInput;
  where: ConstructionWhereUniqueInput;
}

export interface ConstructionUpdateWithWhereUniqueWithoutPartnerInput {
  data: ConstructionUncheckedUpdateWithoutPartnerInput;
  where: ConstructionWhereUniqueInput;
}

export interface ConstructionUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutConstructionNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ConstructionUpdateWithoutPartnerInput {
  address?: InputMaybe<AddressUpdateOneWithoutConstructionNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ConstructionUpdateWithoutProfileInput {
  address?: InputMaybe<AddressUpdateOneWithoutConstructionNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutConstructionNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ConstructionUpsertWithWhereUniqueWithoutAddressInput {
  create: ConstructionUncheckedCreateWithoutAddressInput;
  update: ConstructionUncheckedUpdateWithoutAddressInput;
  where: ConstructionWhereUniqueInput;
}

export interface ConstructionUpsertWithWhereUniqueWithoutPartnerInput {
  create: ConstructionUncheckedCreateWithoutPartnerInput;
  update: ConstructionUncheckedUpdateWithoutPartnerInput;
  where: ConstructionWhereUniqueInput;
}

export interface ConstructionUpsertWithoutProfileInput {
  create: ConstructionUncheckedCreateWithoutProfileInput;
  update: ConstructionUncheckedUpdateWithoutProfileInput;
}

export interface ConstructionWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ConstructionWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ConstructionWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ConstructionWhereInput>>>;
  address?: InputMaybe<AddressWhereInput>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partner?: InputMaybe<PartnerWhereInput>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  profile?: InputMaybe<ProfileListRelationFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface ConstructionWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** Customer: 설계사, 건축주 */
export interface Customer {
  __typename?: 'Customer';
  PayTransaction: Array<PayTransaction>;
  _count: CustomerCountOutputType;
  /** User address */
  address?: Maybe<Address>;
  /** User addressId */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** Customer field */
  fieldName?: Maybe<Scalars['String']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** 세금계산서 */
  invoice: Array<VatInvoice>;
  /** Member */
  member: Array<Member>;
  /** Name */
  name?: Maybe<Scalars['String']['output']>;
  /** Owned project list (created by this user) */
  ownedProject: Array<Project>;
  projectAggregate: AggregateProject;
  /** Customer role, default Design */
  role: CustomerRole;
  /** 공유된 프로젝트 */
  sharedProject: Array<SharedProject>;
  /** User member status */
  status: MemberStatus;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique ID */
  uuid: Scalars['String']['output'];
}


/** Customer: 설계사, 건축주 */
export type CustomerPayTransactionArgs = {
  cursor?: InputMaybe<PayTransactionWhereUniqueInput>;
  distinct?: InputMaybe<PayTransactionScalarFieldEnum>;
  orderBy?: InputMaybe<PayTransactionOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PayTransactionWhereInput>;
};


/** Customer: 설계사, 건축주 */
export type CustomerInvoiceArgs = {
  cursor?: InputMaybe<VatInvoiceWhereUniqueInput>;
  distinct?: InputMaybe<VatInvoiceScalarFieldEnum>;
  orderBy?: InputMaybe<VatInvoiceOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VatInvoiceWhereInput>;
};


/** Customer: 설계사, 건축주 */
export type CustomerMemberArgs = {
  cursor?: InputMaybe<MemberWhereUniqueInput>;
  distinct?: InputMaybe<MemberScalarFieldEnum>;
  orderBy?: InputMaybe<MemberOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberWhereInput>;
};


/** Customer: 설계사, 건축주 */
export type CustomerOwnedProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<ProjectScalarFieldEnum>;
  orderBy?: InputMaybe<ProjectOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


/** Customer: 설계사, 건축주 */
export type CustomerProjectAggregateArgs = {
  where?: InputMaybe<ProjectWhereInput>;
};


/** Customer: 설계사, 건축주 */
export type CustomerSharedProjectArgs = {
  cursor?: InputMaybe<SharedProjectWhereUniqueInput>;
  distinct?: InputMaybe<SharedProjectScalarFieldEnum>;
  orderBy?: InputMaybe<SharedProjectOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectWhereInput>;
};

export interface CustomerAvgAggregateOutputType {
  __typename?: 'CustomerAvgAggregateOutputType';
  addressId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

export interface CustomerAvgOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
}

export interface CustomerCountAggregateOutputType {
  __typename?: 'CustomerCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  addressId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  fieldName: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface CustomerCountOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  fieldName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface CustomerCountOutputType {
  __typename?: 'CustomerCountOutputType';
  PayTransaction: Scalars['Int']['output'];
  invoice: Scalars['Int']['output'];
  member: Scalars['Int']['output'];
  ownedProject: Scalars['Int']['output'];
  sharedProject: Scalars['Int']['output'];
}

export interface CustomerCreateInput {
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutCustomerInput>;
  address?: InputMaybe<AddressCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  invoice?: InputMaybe<VatInvoiceCreateNestedManyWithoutCustomerInput>;
  member?: InputMaybe<MemberCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerCreateManyAddressInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<CustomerRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerCreateManyAddressInputEnvelope {
  data: CustomerCreateManyAddressInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface CustomerCreateManyInput {
  addressId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<CustomerRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<CustomerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CustomerCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<CustomerCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<CustomerCreateManyAddressInputEnvelope>;
}

export interface CustomerCreateNestedOneWithoutInvoiceInput {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutInvoiceInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutInvoiceInput>;
}

export interface CustomerCreateNestedOneWithoutMemberInput {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutMemberInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutMemberInput>;
}

export interface CustomerCreateNestedOneWithoutOwnedProjectInput {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutOwnedProjectInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutOwnedProjectInput>;
}

export interface CustomerCreateNestedOneWithoutPayTransactionInput {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutPayTransactionInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutPayTransactionInput>;
}

export interface CustomerCreateNestedOneWithoutSharedProjectInput {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutSharedProjectInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutSharedProjectInput>;
}

export interface CustomerCreateOrConnectWithoutAddressInput {
  create: CustomerUncheckedCreateWithoutAddressInput;
  where: CustomerWhereUniqueInput;
}

export interface CustomerCreateOrConnectWithoutInvoiceInput {
  create: CustomerUncheckedCreateWithoutInvoiceInput;
  where: CustomerWhereUniqueInput;
}

export interface CustomerCreateOrConnectWithoutMemberInput {
  create: CustomerUncheckedCreateWithoutMemberInput;
  where: CustomerWhereUniqueInput;
}

export interface CustomerCreateOrConnectWithoutOwnedProjectInput {
  create: CustomerUncheckedCreateWithoutOwnedProjectInput;
  where: CustomerWhereUniqueInput;
}

export interface CustomerCreateOrConnectWithoutPayTransactionInput {
  create: CustomerUncheckedCreateWithoutPayTransactionInput;
  where: CustomerWhereUniqueInput;
}

export interface CustomerCreateOrConnectWithoutSharedProjectInput {
  create: CustomerUncheckedCreateWithoutSharedProjectInput;
  where: CustomerWhereUniqueInput;
}

export interface CustomerCreateWithoutAddressInput {
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  invoice?: InputMaybe<VatInvoiceCreateNestedManyWithoutCustomerInput>;
  member?: InputMaybe<MemberCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerCreateWithoutInvoiceInput {
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutCustomerInput>;
  address?: InputMaybe<AddressCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  member?: InputMaybe<MemberCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerCreateWithoutMemberInput {
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutCustomerInput>;
  address?: InputMaybe<AddressCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  invoice?: InputMaybe<VatInvoiceCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerCreateWithoutOwnedProjectInput {
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutCustomerInput>;
  address?: InputMaybe<AddressCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  invoice?: InputMaybe<VatInvoiceCreateNestedManyWithoutCustomerInput>;
  member?: InputMaybe<MemberCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerCreateWithoutPayTransactionInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  invoice?: InputMaybe<VatInvoiceCreateNestedManyWithoutCustomerInput>;
  member?: InputMaybe<MemberCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerCreateWithoutSharedProjectInput {
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutCustomerInput>;
  address?: InputMaybe<AddressCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  invoice?: InputMaybe<VatInvoiceCreateNestedManyWithoutCustomerInput>;
  member?: InputMaybe<MemberCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerListRelationFilter {
  every?: InputMaybe<CustomerWhereInput>;
  none?: InputMaybe<CustomerWhereInput>;
  some?: InputMaybe<CustomerWhereInput>;
}

export interface CustomerMaxAggregateOutputType {
  __typename?: 'CustomerMaxAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<CustomerRole>;
  status?: Maybe<MemberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface CustomerMaxOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  fieldName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface CustomerMinAggregateOutputType {
  __typename?: 'CustomerMinAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fieldName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<CustomerRole>;
  status?: Maybe<MemberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface CustomerMinOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  fieldName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface CustomerOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface CustomerOrderByWithAggregationInput {
  _avg?: InputMaybe<CustomerAvgOrderByAggregateInput>;
  _count?: InputMaybe<CustomerCountOrderByAggregateInput>;
  _max?: InputMaybe<CustomerMaxOrderByAggregateInput>;
  _min?: InputMaybe<CustomerMinOrderByAggregateInput>;
  _sum?: InputMaybe<CustomerSumOrderByAggregateInput>;
  addressId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  fieldName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface CustomerOrderByWithRelationInput {
  PayTransaction?: InputMaybe<PayTransactionOrderByRelationAggregateInput>;
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  addressId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  fieldName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<VatInvoiceOrderByRelationAggregateInput>;
  member?: InputMaybe<MemberOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrderInput>;
  ownedProject?: InputMaybe<ProjectOrderByRelationAggregateInput>;
  role?: InputMaybe<SortOrder>;
  sharedProject?: InputMaybe<SharedProjectOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface CustomerRelationFilter {
  is?: InputMaybe<CustomerWhereInput>;
  isNot?: InputMaybe<CustomerWhereInput>;
}

export enum CustomerRole {
  Construction = 'Construction',
  Design = 'Design',
  Etc = 'Etc',
  Execution = 'Execution',
  NotSelected = 'NotSelected',
  SalesConstructionMaterials = 'SalesConstructionMaterials',
  SpecializedConstruction = 'SpecializedConstruction'
}

export enum CustomerScalarFieldEnum {
  AddressId = 'addressId',
  CreatedAt = 'createdAt',
  FieldName = 'fieldName',
  Id = 'id',
  Name = 'name',
  Role = 'role',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface CustomerScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<CustomerScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CustomerScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CustomerScalarWhereInput>>>;
  addressId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fieldName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumCustomerRoleFilter>;
  status?: InputMaybe<EnumMemberStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface CustomerScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<CustomerScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CustomerScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CustomerScalarWhereWithAggregatesInput>>>;
  addressId?: InputMaybe<IntNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  fieldName?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumCustomerRoleWithAggregatesFilter>;
  status?: InputMaybe<EnumMemberStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface CustomerSumAggregateOutputType {
  __typename?: 'CustomerSumAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

export interface CustomerSumOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
}

export interface CustomerUncheckedCreateInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutCustomerInput>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice?: InputMaybe<VatInvoiceUncheckedCreateNestedManyWithoutCustomerInput>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerUncheckedCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<CustomerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CustomerCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<CustomerCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<CustomerCreateManyAddressInputEnvelope>;
}

export interface CustomerUncheckedCreateWithoutAddressInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice?: InputMaybe<VatInvoiceUncheckedCreateNestedManyWithoutCustomerInput>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerUncheckedCreateWithoutInvoiceInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutCustomerInput>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerUncheckedCreateWithoutMemberInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutCustomerInput>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice?: InputMaybe<VatInvoiceUncheckedCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerUncheckedCreateWithoutOwnedProjectInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutCustomerInput>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice?: InputMaybe<VatInvoiceUncheckedCreateNestedManyWithoutCustomerInput>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerUncheckedCreateWithoutPayTransactionInput {
  addressId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice?: InputMaybe<VatInvoiceUncheckedCreateNestedManyWithoutCustomerInput>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutCustomerInput>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerUncheckedCreateWithoutSharedProjectInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutCustomerInput>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice?: InputMaybe<VatInvoiceUncheckedCreateNestedManyWithoutCustomerInput>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutCustomerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownedProject?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutOwnerInput>;
  role?: InputMaybe<CustomerRole>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface CustomerUncheckedUpdateInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutCustomerNestedInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUncheckedUpdateManyWithoutCustomerNestedInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUncheckedUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUncheckedUpdateManyInput {
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUncheckedUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<CustomerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CustomerCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<CustomerCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<CustomerCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<CustomerWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CustomerScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CustomerWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CustomerWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CustomerUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CustomerUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CustomerUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface CustomerUncheckedUpdateManyWithoutCustomerInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUncheckedUpdateWithoutAddressInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutCustomerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUncheckedUpdateManyWithoutCustomerNestedInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUncheckedUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUncheckedUpdateWithoutInvoiceInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutCustomerNestedInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUncheckedUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUncheckedUpdateWithoutMemberInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutCustomerNestedInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUncheckedUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUncheckedUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUncheckedUpdateWithoutOwnedProjectInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutCustomerNestedInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUncheckedUpdateManyWithoutCustomerNestedInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUncheckedUpdateWithoutPayTransactionInput {
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUncheckedUpdateManyWithoutCustomerNestedInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUncheckedUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUncheckedUpdateWithoutSharedProjectInput {
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutCustomerNestedInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUncheckedUpdateManyWithoutCustomerNestedInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUncheckedUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUpdateInput {
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutCustomerNestedInput>;
  address?: InputMaybe<AddressUpdateOneWithoutCustomerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUpdateManyWithoutCustomerNestedInput>;
  member?: InputMaybe<MemberUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUpdateManyWithWhereWithoutAddressInput {
  data: CustomerUncheckedUpdateManyWithoutCustomerInput;
  where: CustomerScalarWhereInput;
}

export interface CustomerUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<CustomerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<CustomerCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<CustomerCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<CustomerCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<CustomerWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<CustomerScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<CustomerWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<CustomerWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<CustomerUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<CustomerUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<CustomerUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface CustomerUpdateOneWithoutInvoiceNestedInput {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutInvoiceInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutInvoiceInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CustomerUncheckedUpdateWithoutInvoiceInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutInvoiceInput>;
}

export interface CustomerUpdateOneWithoutMemberNestedInput {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutMemberInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutMemberInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CustomerUncheckedUpdateWithoutMemberInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutMemberInput>;
}

export interface CustomerUpdateOneWithoutOwnedProjectNestedInput {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutOwnedProjectInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutOwnedProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CustomerUncheckedUpdateWithoutOwnedProjectInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutOwnedProjectInput>;
}

export interface CustomerUpdateOneWithoutPayTransactionNestedInput {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutPayTransactionInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutPayTransactionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CustomerUncheckedUpdateWithoutPayTransactionInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutPayTransactionInput>;
}

export interface CustomerUpdateOneWithoutSharedProjectNestedInput {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutSharedProjectInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutSharedProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CustomerUncheckedUpdateWithoutSharedProjectInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutSharedProjectInput>;
}

export interface CustomerUpdateWithWhereUniqueWithoutAddressInput {
  data: CustomerUncheckedUpdateWithoutAddressInput;
  where: CustomerWhereUniqueInput;
}

export interface CustomerUpdateWithoutAddressInput {
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutCustomerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUpdateManyWithoutCustomerNestedInput>;
  member?: InputMaybe<MemberUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUpdateWithoutInvoiceInput {
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutCustomerNestedInput>;
  address?: InputMaybe<AddressUpdateOneWithoutCustomerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUpdateWithoutMemberInput {
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutCustomerNestedInput>;
  address?: InputMaybe<AddressUpdateOneWithoutCustomerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUpdateWithoutOwnedProjectInput {
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutCustomerNestedInput>;
  address?: InputMaybe<AddressUpdateOneWithoutCustomerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUpdateManyWithoutCustomerNestedInput>;
  member?: InputMaybe<MemberUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUpdateWithoutPayTransactionInput {
  address?: InputMaybe<AddressUpdateOneWithoutCustomerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUpdateManyWithoutCustomerNestedInput>;
  member?: InputMaybe<MemberUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutCustomerNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUpdateWithoutSharedProjectInput {
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutCustomerNestedInput>;
  address?: InputMaybe<AddressUpdateOneWithoutCustomerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fieldName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<VatInvoiceUpdateManyWithoutCustomerNestedInput>;
  member?: InputMaybe<MemberUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownedProject?: InputMaybe<ProjectUpdateManyWithoutOwnerNestedInput>;
  role?: InputMaybe<EnumCustomerRoleFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface CustomerUpsertWithWhereUniqueWithoutAddressInput {
  create: CustomerUncheckedCreateWithoutAddressInput;
  update: CustomerUncheckedUpdateWithoutAddressInput;
  where: CustomerWhereUniqueInput;
}

export interface CustomerUpsertWithoutInvoiceInput {
  create: CustomerUncheckedCreateWithoutInvoiceInput;
  update: CustomerUncheckedUpdateWithoutInvoiceInput;
}

export interface CustomerUpsertWithoutMemberInput {
  create: CustomerUncheckedCreateWithoutMemberInput;
  update: CustomerUncheckedUpdateWithoutMemberInput;
}

export interface CustomerUpsertWithoutOwnedProjectInput {
  create: CustomerUncheckedCreateWithoutOwnedProjectInput;
  update: CustomerUncheckedUpdateWithoutOwnedProjectInput;
}

export interface CustomerUpsertWithoutPayTransactionInput {
  create: CustomerUncheckedCreateWithoutPayTransactionInput;
  update: CustomerUncheckedUpdateWithoutPayTransactionInput;
}

export interface CustomerUpsertWithoutSharedProjectInput {
  create: CustomerUncheckedCreateWithoutSharedProjectInput;
  update: CustomerUncheckedUpdateWithoutSharedProjectInput;
}

export interface CustomerWhereInput {
  AND?: InputMaybe<Array<InputMaybe<CustomerWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<CustomerWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<CustomerWhereInput>>>;
  PayTransaction?: InputMaybe<PayTransactionListRelationFilter>;
  address?: InputMaybe<AddressWhereInput>;
  addressId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fieldName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  invoice?: InputMaybe<VatInvoiceListRelationFilter>;
  member?: InputMaybe<MemberListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownedProject?: InputMaybe<ProjectListRelationFilter>;
  role?: InputMaybe<EnumCustomerRoleFilter>;
  sharedProject?: InputMaybe<SharedProjectListRelationFilter>;
  status?: InputMaybe<EnumMemberStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface CustomerWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface DateTimeFieldUpdateOperationsInput {
  set?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface DateTimeFilter {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
}

export interface DateTimeNullableFilter {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
}

export interface DateTimeNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
}

export interface DateTimeWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
}

export enum DocumentType {
  ArchitectureDocuments = 'architectureDocuments',
  Etc = 'etc',
  Modeling = 'modeling',
  StructuralDocuments = 'structuralDocuments'
}

export interface ElectricalSrw {
  __typename?: 'ElectricalSRW';
  ShareRatioWork: Array<ShareRatioWork>;
  _count: ElectricalSrwCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  mainWork: MainWorkCategory;
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  work: ElectricalWorkCategory;
  workName: Scalars['String']['output'];
}


export type ElectricalSrwShareRatioWorkArgs = {
  cursor?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  distinct?: InputMaybe<ShareRatioWorkScalarFieldEnum>;
  orderBy?: InputMaybe<ShareRatioWorkOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};

export interface ElectricalSrwAvgAggregateOutputType {
  __typename?: 'ElectricalSRWAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface ElectricalSrwAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface ElectricalSrwCountAggregateOutputType {
  __typename?: 'ElectricalSRWCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  mainWork: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  work: Scalars['Int']['output'];
  workName: Scalars['Int']['output'];
}

export interface ElectricalSrwCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface ElectricalSrwCountOutputType {
  __typename?: 'ElectricalSRWCountOutputType';
  ShareRatioWork: Scalars['Int']['output'];
}

export interface ElectricalSrwCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedManyWithoutElectricalInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: ElectricalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface ElectricalSrwCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: ElectricalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface ElectricalSrwCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ElectricalSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ElectricalSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface ElectricalSrwCreateOrConnectWithoutShareRatioWorkInput {
  create: ElectricalSrwUncheckedCreateWithoutShareRatioWorkInput;
  where: ElectricalSrwWhereUniqueInput;
}

export interface ElectricalSrwCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: ElectricalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface ElectricalSrwListRelationFilter {
  every?: InputMaybe<ElectricalSrwWhereInput>;
  none?: InputMaybe<ElectricalSrwWhereInput>;
  some?: InputMaybe<ElectricalSrwWhereInput>;
}

export interface ElectricalSrwMaxAggregateOutputType {
  __typename?: 'ElectricalSRWMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<ElectricalWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface ElectricalSrwMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface ElectricalSrwMinAggregateOutputType {
  __typename?: 'ElectricalSRWMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<ElectricalWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface ElectricalSrwMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface ElectricalSrwOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ElectricalSrwOrderByWithAggregationInput {
  _avg?: InputMaybe<ElectricalSrwAvgOrderByAggregateInput>;
  _count?: InputMaybe<ElectricalSrwCountOrderByAggregateInput>;
  _max?: InputMaybe<ElectricalSrwMaxOrderByAggregateInput>;
  _min?: InputMaybe<ElectricalSrwMinOrderByAggregateInput>;
  _sum?: InputMaybe<ElectricalSrwSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface ElectricalSrwOrderByWithRelationInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export enum ElectricalSrwScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  MainWork = 'mainWork',
  Order = 'order',
  ShareRatio = 'shareRatio',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  Work = 'work',
  WorkName = 'workName'
}

export interface ElectricalSrwScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ElectricalSrwScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ElectricalSrwScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ElectricalSrwScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumElectricalWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface ElectricalSrwScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ElectricalSrwScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ElectricalSrwScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ElectricalSrwScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  shareRatio?: InputMaybe<FloatWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  work?: InputMaybe<EnumElectricalWorkCategoryWithAggregatesFilter>;
  workName?: InputMaybe<StringWithAggregatesFilter>;
}

export interface ElectricalSrwSumAggregateOutputType {
  __typename?: 'ElectricalSRWSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface ElectricalSrwSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface ElectricalSrwUncheckedCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedCreateNestedManyWithoutElectricalInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: ElectricalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface ElectricalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ElectricalSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ElectricalSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface ElectricalSrwUncheckedCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: ElectricalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface ElectricalSrwUncheckedUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedUpdateManyWithoutElectricalNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumElectricalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ElectricalSrwUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumElectricalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ElectricalSrwUncheckedUpdateManyWithoutElectricalInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumElectricalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ElectricalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ElectricalSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ElectricalSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ElectricalSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ElectricalSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ElectricalSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ElectricalSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface ElectricalSrwUncheckedUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumElectricalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ElectricalSrwUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUpdateManyWithoutElectricalNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumElectricalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ElectricalSrwUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumElectricalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ElectricalSrwUpdateManyWithWhereWithoutShareRatioWorkInput {
  data: ElectricalSrwUncheckedUpdateManyWithoutElectricalInput;
  where: ElectricalSrwScalarWhereInput;
}

export interface ElectricalSrwUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ElectricalSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ElectricalSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ElectricalSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ElectricalSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ElectricalSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ElectricalSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface ElectricalSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput {
  data: ElectricalSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: ElectricalSrwWhereUniqueInput;
}

export interface ElectricalSrwUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumElectricalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ElectricalSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput {
  create: ElectricalSrwUncheckedCreateWithoutShareRatioWorkInput;
  update: ElectricalSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: ElectricalSrwWhereUniqueInput;
}

export interface ElectricalSrwWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ElectricalSrwWhereInput>>>;
  ShareRatioWork?: InputMaybe<ShareRatioWorkListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumElectricalWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface ElectricalSrwWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export enum ElectricalWorkCategory {
  HvacSystemInstallationWork = 'HVACSystemInstallationWork',
  CableTrayAndDuctInstallationWork = 'cableTrayAndDuctInstallationWork',
  GroundingAndLightningProtectionFacilityInstallationWork = 'groundingAndLightningProtectionFacilityInstallationWork',
  HeatTransferEquipmentInstallationWork = 'heatTransferEquipmentInstallationWork',
  LightingAndPowerControlEquipmentInstallationWork = 'lightingAndPowerControlEquipmentInstallationWork',
  LightingInstallationWork = 'lightingInstallationWork',
  OtherWork = 'otherWork',
  OutdoorSecurityLightingEquipmentInstallationWork = 'outdoorSecurityLightingEquipmentInstallationWork',
  PowerEquipmentInstallationWork = 'powerEquipmentInstallationWork',
  PowerLineInstallationWork = 'powerLineInstallationWork',
  PowerSupplyAndTransformationEquipmentInstallationWork = 'powerSupplyAndTransformationEquipmentInstallationWork',
  RemoteMeteringEquipmentInstallationWork = 'remoteMeteringEquipmentInstallationWork'
}

export interface Enum {
  __typename?: 'Enum';
  fields: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
}

export interface EnumAccessTypeFieldUpdateOperationsInput {
  set?: InputMaybe<AccessType>;
}

export interface EnumAccessTypeFilter {
  equals?: InputMaybe<AccessType>;
  in?: InputMaybe<Array<InputMaybe<AccessType>>>;
  not?: InputMaybe<NestedEnumAccessTypeFilter>;
  notIn?: InputMaybe<Array<InputMaybe<AccessType>>>;
}

export interface EnumAccessTypeWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAccessTypeFilter>;
  _min?: InputMaybe<NestedEnumAccessTypeFilter>;
  equals?: InputMaybe<AccessType>;
  in?: InputMaybe<Array<InputMaybe<AccessType>>>;
  not?: InputMaybe<NestedEnumAccessTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<AccessType>>>;
}

export interface EnumAdminRoleFieldUpdateOperationsInput {
  set?: InputMaybe<AdminRole>;
}

export interface EnumAdminRoleFilter {
  equals?: InputMaybe<AdminRole>;
  in?: InputMaybe<Array<InputMaybe<AdminRole>>>;
  not?: InputMaybe<NestedEnumAdminRoleFilter>;
  notIn?: InputMaybe<Array<InputMaybe<AdminRole>>>;
}

export interface EnumAdminRoleWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAdminRoleFilter>;
  _min?: InputMaybe<NestedEnumAdminRoleFilter>;
  equals?: InputMaybe<AdminRole>;
  in?: InputMaybe<Array<InputMaybe<AdminRole>>>;
  not?: InputMaybe<NestedEnumAdminRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<AdminRole>>>;
}

export interface EnumArchitecturalWorkCategoryFieldUpdateOperationsInput {
  set?: InputMaybe<ArchitecturalWorkCategory>;
}

export interface EnumArchitecturalWorkCategoryFilter {
  equals?: InputMaybe<ArchitecturalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<ArchitecturalWorkCategory>>>;
  not?: InputMaybe<NestedEnumArchitecturalWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ArchitecturalWorkCategory>>>;
}

export interface EnumArchitecturalWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumArchitecturalWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumArchitecturalWorkCategoryFilter>;
  equals?: InputMaybe<ArchitecturalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<ArchitecturalWorkCategory>>>;
  not?: InputMaybe<NestedEnumArchitecturalWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ArchitecturalWorkCategory>>>;
}

export interface EnumCashReceiptStatusFieldUpdateOperationsInput {
  set?: InputMaybe<CashReceiptStatus>;
}

export interface EnumCashReceiptStatusFilter {
  equals?: InputMaybe<CashReceiptStatus>;
  in?: InputMaybe<Array<InputMaybe<CashReceiptStatus>>>;
  not?: InputMaybe<NestedEnumCashReceiptStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CashReceiptStatus>>>;
}

export interface EnumCashReceiptStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCashReceiptStatusFilter>;
  _min?: InputMaybe<NestedEnumCashReceiptStatusFilter>;
  equals?: InputMaybe<CashReceiptStatus>;
  in?: InputMaybe<Array<InputMaybe<CashReceiptStatus>>>;
  not?: InputMaybe<NestedEnumCashReceiptStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CashReceiptStatus>>>;
}

export interface EnumCashReceiptTypeFieldUpdateOperationsInput {
  set?: InputMaybe<CashReceiptType>;
}

export interface EnumCashReceiptTypeFilter {
  equals?: InputMaybe<CashReceiptType>;
  in?: InputMaybe<Array<InputMaybe<CashReceiptType>>>;
  not?: InputMaybe<NestedEnumCashReceiptTypeFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CashReceiptType>>>;
}

export interface EnumCashReceiptTypeWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCashReceiptTypeFilter>;
  _min?: InputMaybe<NestedEnumCashReceiptTypeFilter>;
  equals?: InputMaybe<CashReceiptType>;
  in?: InputMaybe<Array<InputMaybe<CashReceiptType>>>;
  not?: InputMaybe<NestedEnumCashReceiptTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CashReceiptType>>>;
}

export interface EnumCivilWorkCategoryFieldUpdateOperationsInput {
  set?: InputMaybe<CivilWorkCategory>;
}

export interface EnumCivilWorkCategoryFilter {
  equals?: InputMaybe<CivilWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<CivilWorkCategory>>>;
  not?: InputMaybe<NestedEnumCivilWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CivilWorkCategory>>>;
}

export interface EnumCivilWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCivilWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumCivilWorkCategoryFilter>;
  equals?: InputMaybe<CivilWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<CivilWorkCategory>>>;
  not?: InputMaybe<NestedEnumCivilWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CivilWorkCategory>>>;
}

export interface EnumCommunicationWorkCategoryFieldUpdateOperationsInput {
  set?: InputMaybe<CommunicationWorkCategory>;
}

export interface EnumCommunicationWorkCategoryFilter {
  equals?: InputMaybe<CommunicationWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<CommunicationWorkCategory>>>;
  not?: InputMaybe<NestedEnumCommunicationWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CommunicationWorkCategory>>>;
}

export interface EnumCommunicationWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCommunicationWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumCommunicationWorkCategoryFilter>;
  equals?: InputMaybe<CommunicationWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<CommunicationWorkCategory>>>;
  not?: InputMaybe<NestedEnumCommunicationWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CommunicationWorkCategory>>>;
}

export interface EnumCustomerRoleFieldUpdateOperationsInput {
  set?: InputMaybe<CustomerRole>;
}

export interface EnumCustomerRoleFilter {
  equals?: InputMaybe<CustomerRole>;
  in?: InputMaybe<Array<InputMaybe<CustomerRole>>>;
  not?: InputMaybe<NestedEnumCustomerRoleFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CustomerRole>>>;
}

export interface EnumCustomerRoleWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCustomerRoleFilter>;
  _min?: InputMaybe<NestedEnumCustomerRoleFilter>;
  equals?: InputMaybe<CustomerRole>;
  in?: InputMaybe<Array<InputMaybe<CustomerRole>>>;
  not?: InputMaybe<NestedEnumCustomerRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CustomerRole>>>;
}

export interface EnumDocumentTypeFieldUpdateOperationsInput {
  set?: InputMaybe<DocumentType>;
}

export interface EnumDocumentTypeFilter {
  equals?: InputMaybe<DocumentType>;
  in?: InputMaybe<Array<InputMaybe<DocumentType>>>;
  not?: InputMaybe<NestedEnumDocumentTypeFilter>;
  notIn?: InputMaybe<Array<InputMaybe<DocumentType>>>;
}

export interface EnumDocumentTypeWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumDocumentTypeFilter>;
  _min?: InputMaybe<NestedEnumDocumentTypeFilter>;
  equals?: InputMaybe<DocumentType>;
  in?: InputMaybe<Array<InputMaybe<DocumentType>>>;
  not?: InputMaybe<NestedEnumDocumentTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<DocumentType>>>;
}

export interface EnumElectricalWorkCategoryFieldUpdateOperationsInput {
  set?: InputMaybe<ElectricalWorkCategory>;
}

export interface EnumElectricalWorkCategoryFilter {
  equals?: InputMaybe<ElectricalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<ElectricalWorkCategory>>>;
  not?: InputMaybe<NestedEnumElectricalWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ElectricalWorkCategory>>>;
}

export interface EnumElectricalWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumElectricalWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumElectricalWorkCategoryFilter>;
  equals?: InputMaybe<ElectricalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<ElectricalWorkCategory>>>;
  not?: InputMaybe<NestedEnumElectricalWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ElectricalWorkCategory>>>;
}

export interface EnumFireProtectionWorkCategoryFieldUpdateOperationsInput {
  set?: InputMaybe<FireProtectionWorkCategory>;
}

export interface EnumFireProtectionWorkCategoryFilter {
  equals?: InputMaybe<FireProtectionWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<FireProtectionWorkCategory>>>;
  not?: InputMaybe<NestedEnumFireProtectionWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<FireProtectionWorkCategory>>>;
}

export interface EnumFireProtectionWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumFireProtectionWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumFireProtectionWorkCategoryFilter>;
  equals?: InputMaybe<FireProtectionWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<FireProtectionWorkCategory>>>;
  not?: InputMaybe<NestedEnumFireProtectionWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<FireProtectionWorkCategory>>>;
}

export interface EnumIdentityGenderNullableFilter {
  equals?: InputMaybe<IdentityGender>;
  in?: InputMaybe<Array<InputMaybe<IdentityGender>>>;
  not?: InputMaybe<NestedEnumIdentityGenderNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<IdentityGender>>>;
}

export interface EnumIdentityGenderNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumIdentityGenderNullableFilter>;
  _min?: InputMaybe<NestedEnumIdentityGenderNullableFilter>;
  equals?: InputMaybe<IdentityGender>;
  in?: InputMaybe<Array<InputMaybe<IdentityGender>>>;
  not?: InputMaybe<NestedEnumIdentityGenderNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<IdentityGender>>>;
}

export interface EnumInquiryTypeFieldUpdateOperationsInput {
  set?: InputMaybe<InquiryType>;
}

export interface EnumInquiryTypeFilter {
  equals?: InputMaybe<InquiryType>;
  in?: InputMaybe<Array<InputMaybe<InquiryType>>>;
  not?: InputMaybe<NestedEnumInquiryTypeFilter>;
  notIn?: InputMaybe<Array<InputMaybe<InquiryType>>>;
}

export interface EnumInquiryTypeWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInquiryTypeFilter>;
  _min?: InputMaybe<NestedEnumInquiryTypeFilter>;
  equals?: InputMaybe<InquiryType>;
  in?: InputMaybe<Array<InputMaybe<InquiryType>>>;
  not?: InputMaybe<NestedEnumInquiryTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<InquiryType>>>;
}

export interface EnumKakaoTemplateFieldUpdateOperationsInput {
  set?: InputMaybe<KakaoTemplate>;
}

export interface EnumKakaoTemplateFilter {
  equals?: InputMaybe<KakaoTemplate>;
  in?: InputMaybe<Array<InputMaybe<KakaoTemplate>>>;
  not?: InputMaybe<NestedEnumKakaoTemplateFilter>;
  notIn?: InputMaybe<Array<InputMaybe<KakaoTemplate>>>;
}

export interface EnumKakaoTemplateWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumKakaoTemplateFilter>;
  _min?: InputMaybe<NestedEnumKakaoTemplateFilter>;
  equals?: InputMaybe<KakaoTemplate>;
  in?: InputMaybe<Array<InputMaybe<KakaoTemplate>>>;
  not?: InputMaybe<NestedEnumKakaoTemplateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<KakaoTemplate>>>;
}

export interface EnumKeywordNullableListFilter {
  equals?: InputMaybe<Array<InputMaybe<Keyword>>>;
  has?: InputMaybe<Keyword>;
  hasEvery?: InputMaybe<Array<InputMaybe<Keyword>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Keyword>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface EnumLandscapingWorkCategoryFieldUpdateOperationsInput {
  set?: InputMaybe<LandscapingWorkCategory>;
}

export interface EnumLandscapingWorkCategoryFilter {
  equals?: InputMaybe<LandscapingWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<LandscapingWorkCategory>>>;
  not?: InputMaybe<NestedEnumLandscapingWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<LandscapingWorkCategory>>>;
}

export interface EnumLandscapingWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLandscapingWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumLandscapingWorkCategoryFilter>;
  equals?: InputMaybe<LandscapingWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<LandscapingWorkCategory>>>;
  not?: InputMaybe<NestedEnumLandscapingWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<LandscapingWorkCategory>>>;
}

export interface EnumMainWorkCategoryFieldUpdateOperationsInput {
  set?: InputMaybe<MainWorkCategory>;
}

export interface EnumMainWorkCategoryFilter {
  equals?: InputMaybe<MainWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<MainWorkCategory>>>;
  not?: InputMaybe<NestedEnumMainWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MainWorkCategory>>>;
}

export interface EnumMainWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMainWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumMainWorkCategoryFilter>;
  equals?: InputMaybe<MainWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<MainWorkCategory>>>;
  not?: InputMaybe<NestedEnumMainWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MainWorkCategory>>>;
}

export interface EnumMechanicalWorkCategoryFieldUpdateOperationsInput {
  set?: InputMaybe<MechanicalWorkCategory>;
}

export interface EnumMechanicalWorkCategoryFilter {
  equals?: InputMaybe<MechanicalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<MechanicalWorkCategory>>>;
  not?: InputMaybe<NestedEnumMechanicalWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MechanicalWorkCategory>>>;
}

export interface EnumMechanicalWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMechanicalWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumMechanicalWorkCategoryFilter>;
  equals?: InputMaybe<MechanicalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<MechanicalWorkCategory>>>;
  not?: InputMaybe<NestedEnumMechanicalWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MechanicalWorkCategory>>>;
}

export interface EnumMemberGroupNullableListFilter {
  equals?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  has?: InputMaybe<MemberGroup>;
  hasEvery?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  hasSome?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface EnumMemberStatusFieldUpdateOperationsInput {
  set?: InputMaybe<MemberStatus>;
}

export interface EnumMemberStatusFilter {
  equals?: InputMaybe<MemberStatus>;
  in?: InputMaybe<Array<InputMaybe<MemberStatus>>>;
  not?: InputMaybe<NestedEnumMemberStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MemberStatus>>>;
}

export interface EnumMemberStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMemberStatusFilter>;
  _min?: InputMaybe<NestedEnumMemberStatusFilter>;
  equals?: InputMaybe<MemberStatus>;
  in?: InputMaybe<Array<InputMaybe<MemberStatus>>>;
  not?: InputMaybe<NestedEnumMemberStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MemberStatus>>>;
}

export interface EnumMilestoneWorkFieldUpdateOperationsInput {
  set?: InputMaybe<MilestoneWork>;
}

export interface EnumMilestoneWorkFilter {
  equals?: InputMaybe<MilestoneWork>;
  in?: InputMaybe<Array<InputMaybe<MilestoneWork>>>;
  not?: InputMaybe<NestedEnumMilestoneWorkFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MilestoneWork>>>;
}

export interface EnumMilestoneWorkWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMilestoneWorkFilter>;
  _min?: InputMaybe<NestedEnumMilestoneWorkFilter>;
  equals?: InputMaybe<MilestoneWork>;
  in?: InputMaybe<Array<InputMaybe<MilestoneWork>>>;
  not?: InputMaybe<NestedEnumMilestoneWorkWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MilestoneWork>>>;
}

export interface EnumPartnerRegistrationStatusFieldUpdateOperationsInput {
  set?: InputMaybe<PartnerRegistrationStatus>;
}

export interface EnumPartnerRegistrationStatusFilter {
  equals?: InputMaybe<PartnerRegistrationStatus>;
  in?: InputMaybe<Array<InputMaybe<PartnerRegistrationStatus>>>;
  not?: InputMaybe<NestedEnumPartnerRegistrationStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<PartnerRegistrationStatus>>>;
}

export interface EnumPartnerRegistrationStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPartnerRegistrationStatusFilter>;
  _min?: InputMaybe<NestedEnumPartnerRegistrationStatusFilter>;
  equals?: InputMaybe<PartnerRegistrationStatus>;
  in?: InputMaybe<Array<InputMaybe<PartnerRegistrationStatus>>>;
  not?: InputMaybe<NestedEnumPartnerRegistrationStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<PartnerRegistrationStatus>>>;
}

export interface EnumPartnerRoleFieldUpdateOperationsInput {
  set?: InputMaybe<PartnerRole>;
}

export interface EnumPartnerRoleFilter {
  equals?: InputMaybe<PartnerRole>;
  in?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  not?: InputMaybe<NestedEnumPartnerRoleFilter>;
  notIn?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
}

export interface EnumPartnerRoleNullableListFilter {
  equals?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  has?: InputMaybe<PartnerRole>;
  hasEvery?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  hasSome?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface EnumPartnerRoleWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPartnerRoleFilter>;
  _min?: InputMaybe<NestedEnumPartnerRoleFilter>;
  equals?: InputMaybe<PartnerRole>;
  in?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  not?: InputMaybe<NestedEnumPartnerRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
}

export interface EnumProfileStatusFieldUpdateOperationsInput {
  set?: InputMaybe<ProfileStatus>;
}

export interface EnumProfileStatusFilter {
  equals?: InputMaybe<ProfileStatus>;
  in?: InputMaybe<Array<InputMaybe<ProfileStatus>>>;
  not?: InputMaybe<NestedEnumProfileStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProfileStatus>>>;
}

export interface EnumProfileStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProfileStatusFilter>;
  _min?: InputMaybe<NestedEnumProfileStatusFilter>;
  equals?: InputMaybe<ProfileStatus>;
  in?: InputMaybe<Array<InputMaybe<ProfileStatus>>>;
  not?: InputMaybe<NestedEnumProfileStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProfileStatus>>>;
}

export interface EnumProjectLevelNullableFilter {
  equals?: InputMaybe<ProjectLevel>;
  in?: InputMaybe<Array<InputMaybe<ProjectLevel>>>;
  not?: InputMaybe<NestedEnumProjectLevelNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectLevel>>>;
}

export interface EnumProjectLevelNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProjectLevelNullableFilter>;
  _min?: InputMaybe<NestedEnumProjectLevelNullableFilter>;
  equals?: InputMaybe<ProjectLevel>;
  in?: InputMaybe<Array<InputMaybe<ProjectLevel>>>;
  not?: InputMaybe<NestedEnumProjectLevelNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectLevel>>>;
}

export interface EnumProjectModeNullableFilter {
  equals?: InputMaybe<ProjectMode>;
  in?: InputMaybe<Array<InputMaybe<ProjectMode>>>;
  not?: InputMaybe<NestedEnumProjectModeNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectMode>>>;
}

export interface EnumProjectModeNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProjectModeNullableFilter>;
  _min?: InputMaybe<NestedEnumProjectModeNullableFilter>;
  equals?: InputMaybe<ProjectMode>;
  in?: InputMaybe<Array<InputMaybe<ProjectMode>>>;
  not?: InputMaybe<NestedEnumProjectModeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectMode>>>;
}

export interface EnumProjectOwnerTypeNullableFilter {
  equals?: InputMaybe<ProjectOwnerType>;
  in?: InputMaybe<Array<InputMaybe<ProjectOwnerType>>>;
  not?: InputMaybe<NestedEnumProjectOwnerTypeNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectOwnerType>>>;
}

export interface EnumProjectOwnerTypeNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProjectOwnerTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumProjectOwnerTypeNullableFilter>;
  equals?: InputMaybe<ProjectOwnerType>;
  in?: InputMaybe<Array<InputMaybe<ProjectOwnerType>>>;
  not?: InputMaybe<NestedEnumProjectOwnerTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectOwnerType>>>;
}

export interface EnumProjectPermissionFieldUpdateOperationsInput {
  set?: InputMaybe<ProjectPermission>;
}

export interface EnumProjectPermissionFilter {
  equals?: InputMaybe<ProjectPermission>;
  in?: InputMaybe<Array<InputMaybe<ProjectPermission>>>;
  not?: InputMaybe<NestedEnumProjectPermissionFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectPermission>>>;
}

export interface EnumProjectPermissionWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProjectPermissionFilter>;
  _min?: InputMaybe<NestedEnumProjectPermissionFilter>;
  equals?: InputMaybe<ProjectPermission>;
  in?: InputMaybe<Array<InputMaybe<ProjectPermission>>>;
  not?: InputMaybe<NestedEnumProjectPermissionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectPermission>>>;
}

export interface EnumProjectStatusFieldUpdateOperationsInput {
  set?: InputMaybe<ProjectStatus>;
}

export interface EnumProjectStatusFilter {
  equals?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
  not?: InputMaybe<NestedEnumProjectStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
}

export interface EnumProjectStatusNullableFilter {
  equals?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
  not?: InputMaybe<NestedEnumProjectStatusNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
}

export interface EnumProjectStatusNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProjectStatusNullableFilter>;
  _min?: InputMaybe<NestedEnumProjectStatusNullableFilter>;
  equals?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
  not?: InputMaybe<NestedEnumProjectStatusNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
}

export interface EnumProjectStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProjectStatusFilter>;
  _min?: InputMaybe<NestedEnumProjectStatusFilter>;
  equals?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
  not?: InputMaybe<NestedEnumProjectStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
}

export interface EnumProjectTypeNullableFilter {
  equals?: InputMaybe<ProjectType>;
  in?: InputMaybe<Array<InputMaybe<ProjectType>>>;
  not?: InputMaybe<NestedEnumProjectTypeNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectType>>>;
}

export interface EnumProjectTypeNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProjectTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumProjectTypeNullableFilter>;
  equals?: InputMaybe<ProjectType>;
  in?: InputMaybe<Array<InputMaybe<ProjectType>>>;
  not?: InputMaybe<NestedEnumProjectTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectType>>>;
}

export interface EnumReactionTypeNullableFilter {
  equals?: InputMaybe<ReactionType>;
  in?: InputMaybe<Array<InputMaybe<ReactionType>>>;
  not?: InputMaybe<NestedEnumReactionTypeNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ReactionType>>>;
}

export interface EnumReactionTypeNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumReactionTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumReactionTypeNullableFilter>;
  equals?: InputMaybe<ReactionType>;
  in?: InputMaybe<Array<InputMaybe<ReactionType>>>;
  not?: InputMaybe<NestedEnumReactionTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ReactionType>>>;
}

export interface EnumRejectionStatusFieldUpdateOperationsInput {
  set?: InputMaybe<RejectionStatus>;
}

export interface EnumRejectionStatusFilter {
  equals?: InputMaybe<RejectionStatus>;
  in?: InputMaybe<Array<InputMaybe<RejectionStatus>>>;
  not?: InputMaybe<NestedEnumRejectionStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<RejectionStatus>>>;
}

export interface EnumRejectionStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRejectionStatusFilter>;
  _min?: InputMaybe<NestedEnumRejectionStatusFilter>;
  equals?: InputMaybe<RejectionStatus>;
  in?: InputMaybe<Array<InputMaybe<RejectionStatus>>>;
  not?: InputMaybe<NestedEnumRejectionStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<RejectionStatus>>>;
}

export interface EnumTaskStatusFieldUpdateOperationsInput {
  set?: InputMaybe<TaskStatus>;
}

export interface EnumTaskStatusFilter {
  equals?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<InputMaybe<TaskStatus>>>;
  not?: InputMaybe<NestedEnumTaskStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<TaskStatus>>>;
}

export interface EnumTaskStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumTaskStatusFilter>;
  _min?: InputMaybe<NestedEnumTaskStatusFilter>;
  equals?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<InputMaybe<TaskStatus>>>;
  not?: InputMaybe<NestedEnumTaskStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<TaskStatus>>>;
}

export interface EnumThirdPartyIdFieldUpdateOperationsInput {
  set?: InputMaybe<ThirdPartyId>;
}

export interface EnumThirdPartyIdFilter {
  equals?: InputMaybe<ThirdPartyId>;
  in?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
  not?: InputMaybe<NestedEnumThirdPartyIdFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
}

export interface EnumThirdPartyIdNullableFilter {
  equals?: InputMaybe<ThirdPartyId>;
  in?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
  not?: InputMaybe<NestedEnumThirdPartyIdNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
}

export interface EnumThirdPartyIdNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumThirdPartyIdNullableFilter>;
  _min?: InputMaybe<NestedEnumThirdPartyIdNullableFilter>;
  equals?: InputMaybe<ThirdPartyId>;
  in?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
  not?: InputMaybe<NestedEnumThirdPartyIdNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
}

export interface EnumThirdPartyIdWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumThirdPartyIdFilter>;
  _min?: InputMaybe<NestedEnumThirdPartyIdFilter>;
  equals?: InputMaybe<ThirdPartyId>;
  in?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
  not?: InputMaybe<NestedEnumThirdPartyIdWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
}

export interface EnumUploadOptionFieldUpdateOperationsInput {
  set?: InputMaybe<UploadOption>;
}

export interface EnumUploadOptionFilter {
  equals?: InputMaybe<UploadOption>;
  in?: InputMaybe<Array<InputMaybe<UploadOption>>>;
  not?: InputMaybe<NestedEnumUploadOptionFilter>;
  notIn?: InputMaybe<Array<InputMaybe<UploadOption>>>;
}

export interface EnumUploadOptionWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUploadOptionFilter>;
  _min?: InputMaybe<NestedEnumUploadOptionFilter>;
  equals?: InputMaybe<UploadOption>;
  in?: InputMaybe<Array<InputMaybe<UploadOption>>>;
  not?: InputMaybe<NestedEnumUploadOptionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<UploadOption>>>;
}

export interface EnumUploadStatusFieldUpdateOperationsInput {
  set?: InputMaybe<UploadStatus>;
}

export interface EnumUploadStatusFilter {
  equals?: InputMaybe<UploadStatus>;
  in?: InputMaybe<Array<InputMaybe<UploadStatus>>>;
  not?: InputMaybe<NestedEnumUploadStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<UploadStatus>>>;
}

export interface EnumUploadStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUploadStatusFilter>;
  _min?: InputMaybe<NestedEnumUploadStatusFilter>;
  equals?: InputMaybe<UploadStatus>;
  in?: InputMaybe<Array<InputMaybe<UploadStatus>>>;
  not?: InputMaybe<NestedEnumUploadStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<UploadStatus>>>;
}

export enum Env {
  Dev = 'dev',
  Prod = 'prod'
}

/** 이벤트 로그(설문조사 등) */
export interface EventLogs {
  __typename?: 'EventLogs';
  _count: EventLogsCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  eventResult: Array<EventResult>;
  id: Scalars['Int']['output'];
  isCompleted: Scalars['Boolean']['output'];
  member: Member;
  memberEvent: MemberEvent;
  memberEventId: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
}


/** 이벤트 로그(설문조사 등) */
export type EventLogsEventResultArgs = {
  cursor?: InputMaybe<EventResultWhereUniqueInput>;
  distinct?: InputMaybe<EventResultScalarFieldEnum>;
  orderBy?: InputMaybe<EventResultOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventResultWhereInput>;
};

export interface EventLogsAvgAggregateOutputType {
  __typename?: 'EventLogsAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  memberEventId?: Maybe<Scalars['Float']['output']>;
  memberId?: Maybe<Scalars['Float']['output']>;
}

export interface EventLogsAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  memberEventId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface EventLogsCountAggregateOutputType {
  __typename?: 'EventLogsCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isCompleted: Scalars['Int']['output'];
  memberEventId: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
}

export interface EventLogsCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  memberEventId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface EventLogsCountOutputType {
  __typename?: 'EventLogsCountOutputType';
  eventResult: Scalars['Int']['output'];
}

export interface EventLogsCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventResult?: InputMaybe<EventResultCreateNestedManyWithoutEventLogInput>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  member: MemberCreateNestedOneWithoutEventLogsInput;
  memberEvent: MemberEventCreateNestedOneWithoutEventLogsInput;
}

export interface EventLogsCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberEventId: Scalars['Int']['input'];
  memberId: Scalars['Int']['input'];
}

export interface EventLogsCreateManyMemberEventInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberId: Scalars['Int']['input'];
}

export interface EventLogsCreateManyMemberEventInputEnvelope {
  data: EventLogsCreateManyMemberEventInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface EventLogsCreateManyMemberInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberEventId: Scalars['Int']['input'];
}

export interface EventLogsCreateManyMemberInputEnvelope {
  data: EventLogsCreateManyMemberInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface EventLogsCreateNestedManyWithoutMemberEventInput {
  connect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventLogsCreateOrConnectWithoutMemberEventInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventLogsCreateWithoutMemberEventInput>>>;
  createMany?: InputMaybe<EventLogsCreateManyMemberEventInputEnvelope>;
}

export interface EventLogsCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventLogsCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventLogsCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<EventLogsCreateManyMemberInputEnvelope>;
}

export interface EventLogsCreateNestedOneWithoutEventResultInput {
  connect?: InputMaybe<EventLogsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventLogsCreateOrConnectWithoutEventResultInput>;
  create?: InputMaybe<EventLogsUncheckedCreateWithoutEventResultInput>;
}

export interface EventLogsCreateOrConnectWithoutEventResultInput {
  create: EventLogsUncheckedCreateWithoutEventResultInput;
  where: EventLogsWhereUniqueInput;
}

export interface EventLogsCreateOrConnectWithoutMemberEventInput {
  create: EventLogsUncheckedCreateWithoutMemberEventInput;
  where: EventLogsWhereUniqueInput;
}

export interface EventLogsCreateOrConnectWithoutMemberInput {
  create: EventLogsUncheckedCreateWithoutMemberInput;
  where: EventLogsWhereUniqueInput;
}

export interface EventLogsCreateWithoutEventResultInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  member: MemberCreateNestedOneWithoutEventLogsInput;
  memberEvent: MemberEventCreateNestedOneWithoutEventLogsInput;
}

export interface EventLogsCreateWithoutMemberEventInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventResult?: InputMaybe<EventResultCreateNestedManyWithoutEventLogInput>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  member: MemberCreateNestedOneWithoutEventLogsInput;
}

export interface EventLogsCreateWithoutMemberInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventResult?: InputMaybe<EventResultCreateNestedManyWithoutEventLogInput>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberEvent: MemberEventCreateNestedOneWithoutEventLogsInput;
}

export interface EventLogsListRelationFilter {
  every?: InputMaybe<EventLogsWhereInput>;
  none?: InputMaybe<EventLogsWhereInput>;
  some?: InputMaybe<EventLogsWhereInput>;
}

export interface EventLogsMaxAggregateOutputType {
  __typename?: 'EventLogsMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  memberEventId?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
}

export interface EventLogsMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  memberEventId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface EventLogsMinAggregateOutputType {
  __typename?: 'EventLogsMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  memberEventId?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
}

export interface EventLogsMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  memberEventId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface EventLogsOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface EventLogsOrderByWithAggregationInput {
  _avg?: InputMaybe<EventLogsAvgOrderByAggregateInput>;
  _count?: InputMaybe<EventLogsCountOrderByAggregateInput>;
  _max?: InputMaybe<EventLogsMaxOrderByAggregateInput>;
  _min?: InputMaybe<EventLogsMinOrderByAggregateInput>;
  _sum?: InputMaybe<EventLogsSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  memberEventId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface EventLogsOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  eventResult?: InputMaybe<EventResultOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isCompleted?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberOrderByWithRelationInput>;
  memberEvent?: InputMaybe<MemberEventOrderByWithRelationInput>;
  memberEventId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface EventLogsRelationFilter {
  is?: InputMaybe<EventLogsWhereInput>;
  isNot?: InputMaybe<EventLogsWhereInput>;
}

export enum EventLogsScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsCompleted = 'isCompleted',
  MemberEventId = 'memberEventId',
  MemberId = 'memberId'
}

export interface EventLogsScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<EventLogsScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<EventLogsScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<EventLogsScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isCompleted?: InputMaybe<BoolFilter>;
  memberEventId?: InputMaybe<IntFilter>;
  memberId?: InputMaybe<IntFilter>;
}

export interface EventLogsScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<EventLogsScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<EventLogsScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<EventLogsScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  isCompleted?: InputMaybe<BoolWithAggregatesFilter>;
  memberEventId?: InputMaybe<IntWithAggregatesFilter>;
  memberId?: InputMaybe<IntWithAggregatesFilter>;
}

export interface EventLogsSumAggregateOutputType {
  __typename?: 'EventLogsSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  memberEventId?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
}

export interface EventLogsSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  memberEventId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface EventLogsUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventResult?: InputMaybe<EventResultUncheckedCreateNestedManyWithoutEventLogInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberEventId: Scalars['Int']['input'];
  memberId: Scalars['Int']['input'];
}

export interface EventLogsUncheckedCreateNestedManyWithoutMemberEventInput {
  connect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventLogsCreateOrConnectWithoutMemberEventInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventLogsCreateWithoutMemberEventInput>>>;
  createMany?: InputMaybe<EventLogsCreateManyMemberEventInputEnvelope>;
}

export interface EventLogsUncheckedCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventLogsCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventLogsCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<EventLogsCreateManyMemberInputEnvelope>;
}

export interface EventLogsUncheckedCreateWithoutEventResultInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberEventId: Scalars['Int']['input'];
  memberId: Scalars['Int']['input'];
}

export interface EventLogsUncheckedCreateWithoutMemberEventInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventResult?: InputMaybe<EventResultUncheckedCreateNestedManyWithoutEventLogInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberId: Scalars['Int']['input'];
}

export interface EventLogsUncheckedCreateWithoutMemberInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventResult?: InputMaybe<EventResultUncheckedCreateNestedManyWithoutEventLogInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberEventId: Scalars['Int']['input'];
}

export interface EventLogsUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventResult?: InputMaybe<EventResultUncheckedUpdateManyWithoutEventLogNestedInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberEventId?: InputMaybe<IntFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface EventLogsUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberEventId?: InputMaybe<IntFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface EventLogsUncheckedUpdateManyWithoutEventLogsInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberEventId?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface EventLogsUncheckedUpdateManyWithoutMemberEventNestedInput {
  connect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventLogsCreateOrConnectWithoutMemberEventInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventLogsCreateWithoutMemberEventInput>>>;
  createMany?: InputMaybe<EventLogsCreateManyMemberEventInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<EventLogsScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<EventLogsUpdateWithWhereUniqueWithoutMemberEventInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<EventLogsUpdateManyWithWhereWithoutMemberEventInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<EventLogsUpsertWithWhereUniqueWithoutMemberEventInput>>>;
}

export interface EventLogsUncheckedUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventLogsCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventLogsCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<EventLogsCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<EventLogsScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<EventLogsUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<EventLogsUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<EventLogsUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface EventLogsUncheckedUpdateWithoutEventResultInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberEventId?: InputMaybe<IntFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface EventLogsUncheckedUpdateWithoutMemberEventInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventResult?: InputMaybe<EventResultUncheckedUpdateManyWithoutEventLogNestedInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface EventLogsUncheckedUpdateWithoutMemberInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventResult?: InputMaybe<EventResultUncheckedUpdateManyWithoutEventLogNestedInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberEventId?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface EventLogsUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventResult?: InputMaybe<EventResultUpdateManyWithoutEventLogNestedInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateOneRequiredWithoutEventLogsNestedInput>;
  memberEvent?: InputMaybe<MemberEventUpdateOneRequiredWithoutEventLogsNestedInput>;
}

export interface EventLogsUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
}

export interface EventLogsUpdateManyWithWhereWithoutMemberEventInput {
  data: EventLogsUncheckedUpdateManyWithoutEventLogsInput;
  where: EventLogsScalarWhereInput;
}

export interface EventLogsUpdateManyWithWhereWithoutMemberInput {
  data: EventLogsUncheckedUpdateManyWithoutEventLogsInput;
  where: EventLogsScalarWhereInput;
}

export interface EventLogsUpdateManyWithoutMemberEventNestedInput {
  connect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventLogsCreateOrConnectWithoutMemberEventInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventLogsCreateWithoutMemberEventInput>>>;
  createMany?: InputMaybe<EventLogsCreateManyMemberEventInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<EventLogsScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<EventLogsUpdateWithWhereUniqueWithoutMemberEventInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<EventLogsUpdateManyWithWhereWithoutMemberEventInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<EventLogsUpsertWithWhereUniqueWithoutMemberEventInput>>>;
}

export interface EventLogsUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventLogsCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventLogsCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<EventLogsCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<EventLogsScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<EventLogsWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<EventLogsUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<EventLogsUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<EventLogsUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface EventLogsUpdateOneRequiredWithoutEventResultNestedInput {
  connect?: InputMaybe<EventLogsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventLogsCreateOrConnectWithoutEventResultInput>;
  create?: InputMaybe<EventLogsUncheckedCreateWithoutEventResultInput>;
  update?: InputMaybe<EventLogsUncheckedUpdateWithoutEventResultInput>;
  upsert?: InputMaybe<EventLogsUpsertWithoutEventResultInput>;
}

export interface EventLogsUpdateWithWhereUniqueWithoutMemberEventInput {
  data: EventLogsUncheckedUpdateWithoutMemberEventInput;
  where: EventLogsWhereUniqueInput;
}

export interface EventLogsUpdateWithWhereUniqueWithoutMemberInput {
  data: EventLogsUncheckedUpdateWithoutMemberInput;
  where: EventLogsWhereUniqueInput;
}

export interface EventLogsUpdateWithoutEventResultInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateOneRequiredWithoutEventLogsNestedInput>;
  memberEvent?: InputMaybe<MemberEventUpdateOneRequiredWithoutEventLogsNestedInput>;
}

export interface EventLogsUpdateWithoutMemberEventInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventResult?: InputMaybe<EventResultUpdateManyWithoutEventLogNestedInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateOneRequiredWithoutEventLogsNestedInput>;
}

export interface EventLogsUpdateWithoutMemberInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventResult?: InputMaybe<EventResultUpdateManyWithoutEventLogNestedInput>;
  isCompleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberEvent?: InputMaybe<MemberEventUpdateOneRequiredWithoutEventLogsNestedInput>;
}

export interface EventLogsUpsertWithWhereUniqueWithoutMemberEventInput {
  create: EventLogsUncheckedCreateWithoutMemberEventInput;
  update: EventLogsUncheckedUpdateWithoutMemberEventInput;
  where: EventLogsWhereUniqueInput;
}

export interface EventLogsUpsertWithWhereUniqueWithoutMemberInput {
  create: EventLogsUncheckedCreateWithoutMemberInput;
  update: EventLogsUncheckedUpdateWithoutMemberInput;
  where: EventLogsWhereUniqueInput;
}

export interface EventLogsUpsertWithoutEventResultInput {
  create: EventLogsUncheckedCreateWithoutEventResultInput;
  update: EventLogsUncheckedUpdateWithoutEventResultInput;
}

export interface EventLogsWhereInput {
  AND?: InputMaybe<Array<InputMaybe<EventLogsWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<EventLogsWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<EventLogsWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventResult?: InputMaybe<EventResultListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  isCompleted?: InputMaybe<BoolFilter>;
  member?: InputMaybe<MemberWhereInput>;
  memberEvent?: InputMaybe<MemberEventWhereInput>;
  memberEventId?: InputMaybe<IntFilter>;
  memberId?: InputMaybe<IntFilter>;
}

export interface EventLogsWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** 이벤트 로그 결과 (설문조서 결과 저장) */
export interface EventResult {
  __typename?: 'EventResult';
  blockId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  eventLog: EventLogs;
  eventLogsId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
}

export interface EventResultAvgAggregateOutputType {
  __typename?: 'EventResultAvgAggregateOutputType';
  eventLogsId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

export interface EventResultAvgOrderByAggregateInput {
  eventLogsId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
}

export interface EventResultCountAggregateOutputType {
  __typename?: 'EventResultCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  blockId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  eventLogsId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['Int']['output'];
}

export interface EventResultCountOrderByAggregateInput {
  blockId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  eventLogsId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
}

export interface EventResultCreateInput {
  blockId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventLog: EventLogsCreateNestedOneWithoutEventResultInput;
  message: Scalars['String']['input'];
}

export interface EventResultCreateManyEventLogInput {
  blockId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message: Scalars['String']['input'];
}

export interface EventResultCreateManyEventLogInputEnvelope {
  data: EventResultCreateManyEventLogInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface EventResultCreateManyInput {
  blockId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventLogsId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  message: Scalars['String']['input'];
}

export interface EventResultCreateNestedManyWithoutEventLogInput {
  connect?: InputMaybe<Array<InputMaybe<EventResultWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventResultCreateOrConnectWithoutEventLogInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventResultCreateWithoutEventLogInput>>>;
  createMany?: InputMaybe<EventResultCreateManyEventLogInputEnvelope>;
}

export interface EventResultCreateOrConnectWithoutEventLogInput {
  create: EventResultUncheckedCreateWithoutEventLogInput;
  where: EventResultWhereUniqueInput;
}

export interface EventResultCreateWithoutEventLogInput {
  blockId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  message: Scalars['String']['input'];
}

export interface EventResultListRelationFilter {
  every?: InputMaybe<EventResultWhereInput>;
  none?: InputMaybe<EventResultWhereInput>;
  some?: InputMaybe<EventResultWhereInput>;
}

export interface EventResultMaxAggregateOutputType {
  __typename?: 'EventResultMaxAggregateOutputType';
  blockId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  eventLogsId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
}

export interface EventResultMaxOrderByAggregateInput {
  blockId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  eventLogsId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
}

export interface EventResultMinAggregateOutputType {
  __typename?: 'EventResultMinAggregateOutputType';
  blockId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  eventLogsId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
}

export interface EventResultMinOrderByAggregateInput {
  blockId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  eventLogsId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
}

export interface EventResultOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface EventResultOrderByWithAggregationInput {
  _avg?: InputMaybe<EventResultAvgOrderByAggregateInput>;
  _count?: InputMaybe<EventResultCountOrderByAggregateInput>;
  _max?: InputMaybe<EventResultMaxOrderByAggregateInput>;
  _min?: InputMaybe<EventResultMinOrderByAggregateInput>;
  _sum?: InputMaybe<EventResultSumOrderByAggregateInput>;
  blockId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  eventLogsId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
}

export interface EventResultOrderByWithRelationInput {
  blockId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  eventLog?: InputMaybe<EventLogsOrderByWithRelationInput>;
  eventLogsId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
}

export enum EventResultScalarFieldEnum {
  BlockId = 'blockId',
  CreatedAt = 'createdAt',
  EventLogsId = 'eventLogsId',
  Id = 'id',
  Message = 'message'
}

export interface EventResultScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<EventResultScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<EventResultScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<EventResultScalarWhereInput>>>;
  blockId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventLogsId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  message?: InputMaybe<StringFilter>;
}

export interface EventResultScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<EventResultScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<EventResultScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<EventResultScalarWhereWithAggregatesInput>>>;
  blockId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  eventLogsId?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  message?: InputMaybe<StringWithAggregatesFilter>;
}

export interface EventResultSumAggregateOutputType {
  __typename?: 'EventResultSumAggregateOutputType';
  eventLogsId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

export interface EventResultSumOrderByAggregateInput {
  eventLogsId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
}

export interface EventResultUncheckedCreateInput {
  blockId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventLogsId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  message: Scalars['String']['input'];
}

export interface EventResultUncheckedCreateNestedManyWithoutEventLogInput {
  connect?: InputMaybe<Array<InputMaybe<EventResultWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventResultCreateOrConnectWithoutEventLogInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventResultCreateWithoutEventLogInput>>>;
  createMany?: InputMaybe<EventResultCreateManyEventLogInputEnvelope>;
}

export interface EventResultUncheckedCreateWithoutEventLogInput {
  blockId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message: Scalars['String']['input'];
}

export interface EventResultUncheckedUpdateInput {
  blockId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventLogsId?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface EventResultUncheckedUpdateManyInput {
  blockId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventLogsId?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface EventResultUncheckedUpdateManyWithoutEventLogNestedInput {
  connect?: InputMaybe<Array<InputMaybe<EventResultWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventResultCreateOrConnectWithoutEventLogInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventResultCreateWithoutEventLogInput>>>;
  createMany?: InputMaybe<EventResultCreateManyEventLogInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<EventResultWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<EventResultScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<EventResultWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<EventResultWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<EventResultUpdateWithWhereUniqueWithoutEventLogInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<EventResultUpdateManyWithWhereWithoutEventLogInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<EventResultUpsertWithWhereUniqueWithoutEventLogInput>>>;
}

export interface EventResultUncheckedUpdateManyWithoutEventResultInput {
  blockId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface EventResultUncheckedUpdateWithoutEventLogInput {
  blockId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface EventResultUpdateInput {
  blockId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventLog?: InputMaybe<EventLogsUpdateOneRequiredWithoutEventResultNestedInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface EventResultUpdateManyMutationInput {
  blockId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface EventResultUpdateManyWithWhereWithoutEventLogInput {
  data: EventResultUncheckedUpdateManyWithoutEventResultInput;
  where: EventResultScalarWhereInput;
}

export interface EventResultUpdateManyWithoutEventLogNestedInput {
  connect?: InputMaybe<Array<InputMaybe<EventResultWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<EventResultCreateOrConnectWithoutEventLogInput>>>;
  create?: InputMaybe<Array<InputMaybe<EventResultCreateWithoutEventLogInput>>>;
  createMany?: InputMaybe<EventResultCreateManyEventLogInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<EventResultWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<EventResultScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<EventResultWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<EventResultWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<EventResultUpdateWithWhereUniqueWithoutEventLogInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<EventResultUpdateManyWithWhereWithoutEventLogInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<EventResultUpsertWithWhereUniqueWithoutEventLogInput>>>;
}

export interface EventResultUpdateWithWhereUniqueWithoutEventLogInput {
  data: EventResultUncheckedUpdateWithoutEventLogInput;
  where: EventResultWhereUniqueInput;
}

export interface EventResultUpdateWithoutEventLogInput {
  blockId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface EventResultUpsertWithWhereUniqueWithoutEventLogInput {
  create: EventResultUncheckedCreateWithoutEventLogInput;
  update: EventResultUncheckedUpdateWithoutEventLogInput;
  where: EventResultWhereUniqueInput;
}

export interface EventResultWhereInput {
  AND?: InputMaybe<Array<InputMaybe<EventResultWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<EventResultWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<EventResultWhereInput>>>;
  blockId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventLog?: InputMaybe<EventLogsWhereInput>;
  eventLogsId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  message?: InputMaybe<StringFilter>;
}

export interface EventResultWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** Excel parsing */
export interface ExcelMutation {
  __typename?: 'ExcelMutation';
  updateBoQ?: Maybe<Scalars['Boolean']['output']>;
}


/** Excel parsing */
export type ExcelMutationUpdateBoQArgs = {
  fileKey: Scalars['String']['input'];
};

export interface Field {
  __typename?: 'Field';
  create: Scalars['Boolean']['output'];
  editor: Scalars['Boolean']['output'];
  filter: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isId: Scalars['Boolean']['output'];
  kind: KindEnum;
  list: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  read: Scalars['Boolean']['output'];
  relationField?: Maybe<Scalars['Boolean']['output']>;
  required: Scalars['Boolean']['output'];
  sort: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  unique: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
  upload: Scalars['Boolean']['output'];
}

/** FireProtection Share ratio of works(소방공사 점유율): '소화장비설치공사' '옥외소화배관공사' '옥내소화배관공사' '펌프실배관공사' '스프링클러배관공사' '소화가스배관공사' '제연덕트설치공사' '유도등설비공사' '자동화재탐지공사' '무선통신보조설비공사' '기타공사(소방)' '소화내진공사' */
export interface FireProtectionSrw {
  __typename?: 'FireProtectionSRW';
  ShareRatioWork: Array<ShareRatioWork>;
  _count: FireProtectionSrwCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  mainWork: MainWorkCategory;
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  work: FireProtectionWorkCategory;
  workName: Scalars['String']['output'];
}


/** FireProtection Share ratio of works(소방공사 점유율): '소화장비설치공사' '옥외소화배관공사' '옥내소화배관공사' '펌프실배관공사' '스프링클러배관공사' '소화가스배관공사' '제연덕트설치공사' '유도등설비공사' '자동화재탐지공사' '무선통신보조설비공사' '기타공사(소방)' '소화내진공사' */
export type FireProtectionSrwShareRatioWorkArgs = {
  cursor?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  distinct?: InputMaybe<ShareRatioWorkScalarFieldEnum>;
  orderBy?: InputMaybe<ShareRatioWorkOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};

export interface FireProtectionSrwAvgAggregateOutputType {
  __typename?: 'FireProtectionSRWAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface FireProtectionSrwAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface FireProtectionSrwCountAggregateOutputType {
  __typename?: 'FireProtectionSRWCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  mainWork: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  work: Scalars['Int']['output'];
  workName: Scalars['Int']['output'];
}

export interface FireProtectionSrwCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface FireProtectionSrwCountOutputType {
  __typename?: 'FireProtectionSRWCountOutputType';
  ShareRatioWork: Scalars['Int']['output'];
}

export interface FireProtectionSrwCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedManyWithoutFireProtectionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: FireProtectionWorkCategory;
  workName: Scalars['String']['input'];
}

export interface FireProtectionSrwCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: FireProtectionWorkCategory;
  workName: Scalars['String']['input'];
}

export interface FireProtectionSrwCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<FireProtectionSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<FireProtectionSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface FireProtectionSrwCreateOrConnectWithoutShareRatioWorkInput {
  create: FireProtectionSrwUncheckedCreateWithoutShareRatioWorkInput;
  where: FireProtectionSrwWhereUniqueInput;
}

export interface FireProtectionSrwCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: FireProtectionWorkCategory;
  workName: Scalars['String']['input'];
}

export interface FireProtectionSrwListRelationFilter {
  every?: InputMaybe<FireProtectionSrwWhereInput>;
  none?: InputMaybe<FireProtectionSrwWhereInput>;
  some?: InputMaybe<FireProtectionSrwWhereInput>;
}

export interface FireProtectionSrwMaxAggregateOutputType {
  __typename?: 'FireProtectionSRWMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<FireProtectionWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface FireProtectionSrwMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface FireProtectionSrwMinAggregateOutputType {
  __typename?: 'FireProtectionSRWMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<FireProtectionWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface FireProtectionSrwMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface FireProtectionSrwOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface FireProtectionSrwOrderByWithAggregationInput {
  _avg?: InputMaybe<FireProtectionSrwAvgOrderByAggregateInput>;
  _count?: InputMaybe<FireProtectionSrwCountOrderByAggregateInput>;
  _max?: InputMaybe<FireProtectionSrwMaxOrderByAggregateInput>;
  _min?: InputMaybe<FireProtectionSrwMinOrderByAggregateInput>;
  _sum?: InputMaybe<FireProtectionSrwSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface FireProtectionSrwOrderByWithRelationInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export enum FireProtectionSrwScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  MainWork = 'mainWork',
  Order = 'order',
  ShareRatio = 'shareRatio',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  Work = 'work',
  WorkName = 'workName'
}

export interface FireProtectionSrwScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<FireProtectionSrwScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<FireProtectionSrwScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<FireProtectionSrwScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumFireProtectionWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface FireProtectionSrwScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<FireProtectionSrwScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<FireProtectionSrwScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<FireProtectionSrwScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  shareRatio?: InputMaybe<FloatWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  work?: InputMaybe<EnumFireProtectionWorkCategoryWithAggregatesFilter>;
  workName?: InputMaybe<StringWithAggregatesFilter>;
}

export interface FireProtectionSrwSumAggregateOutputType {
  __typename?: 'FireProtectionSRWSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface FireProtectionSrwSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface FireProtectionSrwUncheckedCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedCreateNestedManyWithoutFireProtectionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: FireProtectionWorkCategory;
  workName: Scalars['String']['input'];
}

export interface FireProtectionSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<FireProtectionSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<FireProtectionSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface FireProtectionSrwUncheckedCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: FireProtectionWorkCategory;
  workName: Scalars['String']['input'];
}

export interface FireProtectionSrwUncheckedUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedUpdateManyWithoutFireProtectionNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumFireProtectionWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface FireProtectionSrwUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumFireProtectionWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface FireProtectionSrwUncheckedUpdateManyWithoutFireProtectionInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumFireProtectionWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface FireProtectionSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<FireProtectionSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<FireProtectionSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<FireProtectionSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<FireProtectionSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<FireProtectionSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<FireProtectionSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface FireProtectionSrwUncheckedUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumFireProtectionWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface FireProtectionSrwUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUpdateManyWithoutFireProtectionNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumFireProtectionWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface FireProtectionSrwUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumFireProtectionWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface FireProtectionSrwUpdateManyWithWhereWithoutShareRatioWorkInput {
  data: FireProtectionSrwUncheckedUpdateManyWithoutFireProtectionInput;
  where: FireProtectionSrwScalarWhereInput;
}

export interface FireProtectionSrwUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<FireProtectionSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<FireProtectionSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<FireProtectionSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<FireProtectionSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<FireProtectionSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<FireProtectionSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface FireProtectionSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput {
  data: FireProtectionSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: FireProtectionSrwWhereUniqueInput;
}

export interface FireProtectionSrwUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumFireProtectionWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface FireProtectionSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput {
  create: FireProtectionSrwUncheckedCreateWithoutShareRatioWorkInput;
  update: FireProtectionSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: FireProtectionSrwWhereUniqueInput;
}

export interface FireProtectionSrwWhereInput {
  AND?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<FireProtectionSrwWhereInput>>>;
  ShareRatioWork?: InputMaybe<ShareRatioWorkListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumFireProtectionWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface FireProtectionSrwWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export enum FireProtectionWorkCategory {
  AutomaticFireDetectionSystemInstallationWork = 'automaticFireDetectionSystemInstallationWork',
  FireProtectionEquipmentInstallationWork = 'fireProtectionEquipmentInstallationWork',
  FireResistantConstructionWork = 'fireResistantConstructionWork',
  FireSuppressionGasPipingWork = 'fireSuppressionGasPipingWork',
  IndoorFireSprinklerPipingWork = 'indoorFireSprinklerPipingWork',
  InductionLightInstallationWork = 'inductionLightInstallationWork',
  OtherWork = 'otherWork',
  OutdoorFireSprinklerPipingWork = 'outdoorFireSprinklerPipingWork',
  PumpRoomPipingWork = 'pumpRoomPipingWork',
  SmokeExtractionDuctOnstallationWork = 'smokeExtractionDuctOnstallationWork',
  SprinklerSystemPipingWork = 'sprinklerSystemPipingWork',
  WirelessCommunicationAuxiliaryEquipmentInstallationWork = 'wirelessCommunicationAuxiliaryEquipmentInstallationWork'
}

export interface FloatFieldUpdateOperationsInput {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
}

export interface FloatFilter {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
}

export interface FloatNullableFilter {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
}

export interface FloatNullableWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
}

export interface FloatWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
}

/** https://forge.autodesk.com/en/docs/oauth/v2/developers_guide/scopes/ A scope is a permission that is set on a token, a context in which that token may act. For example, a token with the data:read scope is permitted to read data within the Forge ecosystem and can be used on those endpoints that require that scope. Tokens without that scope would be denied access to such endpoints. (Individual endpoint reference pages list the required scopes.). These type will be converted to snake case and underscore string to colon */
export enum ForgeAuthScope {
  /** View your product and service accounts: For Product APIs, the application will be able to read the account data the end user has entitlements to. */
  AccountRead = 'account_read',
  /** Manage your product and service accounts: For Product APIs, the application will be able to update the account data the end user has entitlements to. */
  AccountWrite = 'account_write',
  /** Create new buckets: The application will be able to create an OSS bucket it will own. */
  BucketCreate = 'bucket_create',
  /** Delete your buckets: The application will be able to delete a bucket that it has permission to delete. */
  BucketDelete = 'bucket_delete',
  /** View your buckets: The application will be able to read the metadata and list contents for OSS buckets that it has access to. */
  BucketRead = 'bucket_read',
  /** Update your buckets: The application will be able to set permissions and entitlements for OSS buckets that it has permission to modify. */
  BucketUpdate = 'bucket_update',
  /** Author or execute your codes: The application will be able to author and execute code on behalf of the end user (e.g., scripts processed by the Design Automation API). */
  CodeAll = 'code_all',
  /** Write data: The application will be able to create data on behalf of the end user within the Autodesk ecosystem. */
  DataCreate = 'data_create',
  /** View your data: The application will be able to read all the end user's data (viewable and non-viewable) within the Autodesk ecosystem. */
  DataRead = 'data_read',
  /** Search across your data: The application will be able to search the end user's data within the Autodesk ecosystem. */
  DataSearch = 'data_search',
  /** Manage your data: The application will be able to create, update, and delete data on behalf of the end user within the Autodesk ecosystem. */
  DataWrite = 'data_write',
  /** View your profile info: 	The application will be able to read the end user's profile data (not including associated products and services). */
  UserProfileRead = 'userProfile_read',
  /** View your profile info: The application will be able to read the end user's profile data, including associated products and services. */
  UserRead = 'user_read',
  /** Manage your profile info: The application will be able to create, update, and delete the end user's profile data, including associated products and services. */
  UserWrite = 'user_write',
  /** View your viewable data: The application will only be able to read the end user's viewable data (e.g., PNG and SVF files) within the Autodesk ecosystem. */
  ViewablesRead = 'viewables_read'
}

/** Autodesk Forge Authentication API: https://forge.autodesk.com/en/docs/oauth/v1/developers_guide/ */
export interface ForgeAuthenticationQueries {
  __typename?: 'ForgeAuthenticationQueries';
  getForgeToken?: Maybe<ForgeToken>;
}


/** Autodesk Forge Authentication API: https://forge.autodesk.com/en/docs/oauth/v1/developers_guide/ */
export type ForgeAuthenticationQueriesGetForgeTokenArgs = {
  scopes?: InputMaybe<Array<InputMaybe<ForgeAuthScope>>>;
};

/** Created app-managed bucket response */
export interface ForgeBucket {
  __typename?: 'ForgeBucket';
  /** The key for the created bucket */
  bucketKey?: Maybe<Scalars['String']['output']>;
  /** Owner of the bucket */
  bucketOwner?: Maybe<Scalars['String']['output']>;
  /** Timestamp in epoch time */
  createdDate?: Maybe<Scalars['Date']['output']>;
  /** Array of objects representing the applications with access granted at bucket creation */
  permissions?: Maybe<Array<Maybe<ForgeBucketPermission>>>;
  /** Data retention policy. Acceptable values: `transient`, `temporary`, `persistent` https://forge.autodesk.com/en/docs/data/v2/overview/retention-policy/ */
  policyKey?: Maybe<Scalars['String']['output']>;
}

/** Return bucket details in JSON format if the caller is the owner of the bucket. A request by any other application will result in a response of 403 Forbidden. */
export interface ForgeBucketDetail {
  __typename?: 'ForgeBucketDetail';
  /** Bucket key */
  bucketKey: Scalars['String']['output'];
  /** Bucket owner */
  bucketOwner: Scalars['String']['output'];
  /** Timestamp in epoch time */
  createdDate: Scalars['BigInt']['output'];
  /** Representing applications with access granted at create time */
  permissions?: Maybe<Array<Maybe<ForgeBucketDetailPermission>>>;
  /** Policy values: `transient`, `temporary`, `persistent` */
  policyKey: Scalars['String']['output'];
}

/** Representing applications with access granted at create time */
export interface ForgeBucketDetailPermission {
  __typename?: 'ForgeBucketDetailPermission';
  /** Access values: `full`, `read` */
  access: Scalars['String']['output'];
  /** Application to grant access to */
  authId: Scalars['String']['output'];
}

/** List items representing the bucket */
export interface ForgeBucketListType {
  __typename?: 'ForgeBucketListType';
  items?: Maybe<Array<Maybe<ForgeBucketType>>>;
}

/** Created app-managed bucket permission */
export interface ForgeBucketPermission {
  __typename?: 'ForgeBucketPermission';
  /** Acceptable values: full, read */
  access?: Maybe<Scalars['String']['output']>;
  /** The application to grant access to */
  authId?: Maybe<Scalars['String']['output']>;
}

/** Items of representing the bucket */
export interface ForgeBucketType {
  __typename?: 'ForgeBucketType';
  /** Bucket key */
  bucketKey: Scalars['String']['output'];
  /** Timestamp in epoch time */
  createdDate: Scalars['BigInt']['output'];
  /** Policy values: `transient`, `temporary`, `persistent` */
  policyKey: Scalars['String']['output'];
}

export enum ForgeDataManagementKey {
  Persistent = 'persistent',
  Temporary = 'temporary',
  Transient = 'transient'
}

/** Autodesk Forge Data Management API */
export interface ForgeDataManagementMutation {
  __typename?: 'ForgeDataManagementMutation';
  /** Creates a bucket. Buckets are arbitrary spaces that are created by applications and are used to store objects for later retrieval. A bucket is owned by the application that creates it. https://forge.autodesk.com/en/docs/data/v2/reference/http/buckets-POST/ */
  createBucket?: Maybe<ForgeBucket>;
  /** Upload an object. If the specified object key already exists in the bucket, the uploaded content will overwrite the existing content for the bucket name/object key combination. https://forge.autodesk.com/en/docs/data/v2/reference/http/buckets-:bucketKey-objects-:objectKey-PUT/ */
  uploadObject?: Maybe<ForgeUploadItemResponse>;
}


/** Autodesk Forge Data Management API */
export type ForgeDataManagementMutationCreateBucketArgs = {
  bucketKey: Scalars['String']['input'];
  policyKey: ForgeDataManagementKey;
};


/** Autodesk Forge Data Management API */
export type ForgeDataManagementMutationUploadObjectArgs = {
  bucketKey: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  objectKey: Scalars['String']['input'];
};

/** Autodesk Forge Data Management API: https://forge.autodesk.com/en/docs/data/v2/reference/ */
export interface ForgeDataManagementQueries {
  __typename?: 'ForgeDataManagementQueries';
  /** Return bucket details in JSON format if the caller is the owner of the bucket. A request by any other application will result in a response of 403 Forbidden. https://forge.autodesk.com/en/docs/data/v2/reference/http/buckets-:bucketKey-details-GET/ */
  getBucketDetails?: Maybe<ForgeBucketDetail>;
  /** This endpoint will return the buckets owned by the application. This endpoint supports pagination. https://forge.autodesk.com/en/docs/data/v2/reference/http/buckets-GET/ */
  getBuckets?: Maybe<ForgeBucketListType>;
  /** List objects in a bucket. It is only available to the bucket creator. https://forge.autodesk.com/en/docs/data/v2/reference/http/buckets-:bucketKey-objects-GET/ */
  getObjects?: Maybe<GetForgeBucketObjectResponseList>;
}


/** Autodesk Forge Data Management API: https://forge.autodesk.com/en/docs/data/v2/reference/ */
export type ForgeDataManagementQueriesGetBucketDetailsArgs = {
  bucketKey: Scalars['String']['input'];
};


/** Autodesk Forge Data Management API: https://forge.autodesk.com/en/docs/data/v2/reference/ */
export type ForgeDataManagementQueriesGetBucketsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<ForgeRegion>;
  startAt?: InputMaybe<Scalars['String']['input']>;
};


/** Autodesk Forge Data Management API: https://forge.autodesk.com/en/docs/data/v2/reference/ */
export type ForgeDataManagementQueriesGetObjectsArgs = {
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  bucketKey: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  startAt?: InputMaybe<Scalars['String']['input']>;
};

/** Autodesk Forge Model Derivative API */
export interface ForgeModelDerivativeMutation {
  __typename?: 'ForgeModelDerivativeMutation';
  /** Returns an up-to-date list of Forge-supported translations, that you can use to identify which types of derivatives are supported for each source file type. You can set this endpoint to only return the list of supported translations if they have been updated since a specified date.        See the Supported Translation Formats table for more details about supported translations.      Note that we are constantly adding new file formats to the list of Forge translations. */
  getFormats?: Maybe<ForgeModelFormatResponseType>;
  /**
   *
   *       https://forge.autodesk.com/en/docs/model-derivative/v2/reference/http/job-POST/
   *
   *       You can use this endpoint to do the following:
   *
   *
   *
   * 1. Translate a design from one format to another format.
   *
   * 2. Extract selected parts of a design and export the set of geometries in OBJ format.
   *
   * 3. Generate different-sized thumbnails.
   *
   *
   * Follow the step-by-step tutorials for detailed instructions on how to use this endpoint to translate files, extract geometries, and generate thumbnails.
   *
   * Information about the generated derivatives is logged to a manifest corresponding to the source design being translated. The manifest is typically created the first time you translate the source design. Thereafter it is updated each time this endpoint is used on the source design.
   *
   * Note that this endpoint is asynchronous and initiates a process that runs in the background. You need not keep the HTTP connection open until completion. Use the GET {urn}/manifest endpoint to check for the job’s completion status.
   *
   */
  translate?: Maybe<ForgeModelTranslateResponseType>;
}


/** Autodesk Forge Model Derivative API */
export type ForgeModelDerivativeMutationTranslateArgs = {
  input: ForgeModelTranslateInputType;
};

/** Autodesk Model Derivative API */
export interface ForgeModelDerivativeQueries {
  __typename?: 'ForgeModelDerivativeQueries';
  getManifest?: Maybe<ForgeModelGetManifestResponseType>;
  getMetadata?: Maybe<ForgeModelGetMetadataResponseType>;
}


/** Autodesk Model Derivative API */
export type ForgeModelDerivativeQueriesGetManifestArgs = {
  urn: Scalars['String']['input'];
};


/** Autodesk Model Derivative API */
export type ForgeModelDerivativeQueriesGetMetadataArgs = {
  urn: Scalars['String']['input'];
};

export interface ForgeModelFormatResponseType {
  __typename?: 'ForgeModelFormatResponseType';
  /** A dictionary object that contains a collection of key/value pairs, where each pair represents the target file format and the corresponding source file formats. */
  formats?: Maybe<Scalars['Json']['output']>;
}

export interface ForgeModelGetManifestResponseDerivatives {
  __typename?: 'ForgeModelGetManifestResponseDerivatives';
  /** An array of objects representing the generated files that are associated with a “derivative” (output type). For example, the derivative URN. */
  children?: Maybe<Array<Maybe<ForgeModelGetManifestResponseDerivativesChildren>>>;
  hasThumbnail?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  outputType: Scalars['String']['output'];
  progress: Scalars['String']['output'];
  status: Scalars['String']['output'];
}

export interface ForgeModelGetManifestResponseDerivativesChildren {
  __typename?: 'ForgeModelGetManifestResponseDerivativesChildren';
  guid: Scalars['String']['output'];
  isMasterView?: Maybe<Scalars['Boolean']['output']>;
  mime?: Maybe<Scalars['String']['output']>;
  phaseNames?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  urn?: Maybe<Scalars['String']['output']>;
}

export interface ForgeModelGetManifestResponseType {
  __typename?: 'ForgeModelGetManifestResponseType';
  derivatives?: Maybe<Array<Maybe<ForgeModelGetManifestResponseDerivatives>>>;
  hasThumbnail?: Maybe<Scalars['String']['output']>;
  progress: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
  urn: Scalars['String']['output'];
}

/**
 * Returns a list of model views (Viewables) in the source design specified by the 'urn' URI parameter. It also returns the ID that uniquely identifies the model view. You can use this ID with other metadata endpoints to obtain information about the objects within model view.
 *
 *   Most design applications like Fusion 360 and Inventor contain only one model view per design. However, some applications like Revit allow multiple model views (e.g., HVAC, architecture, perspective) per design.
 *
 *   Note You can retrieve metadata only from an input file that has been translated to SVF or SVF2.
 */
export interface ForgeModelGetMetadataResponseType {
  __typename?: 'ForgeModelGetMetadataResponseType';
  data?: Maybe<ForgeModelGetMetadataResponseTypeData>;
}

/** Envelope that contains the return data. */
export interface ForgeModelGetMetadataResponseTypeData {
  __typename?: 'ForgeModelGetMetadataResponseTypeData';
  /** An array of flat JSON objects representing metadata. */
  metadata: Array<Maybe<ForgeModelGetMetadataResponseTypeDataMetadata>>;
  /** The type of data that is returned. */
  type: Scalars['String']['output'];
}

/** metadata object. */
export interface ForgeModelGetMetadataResponseTypeDataMetadata {
  __typename?: 'ForgeModelGetMetadataResponseTypeDataMetadata';
  /** Unique identifier for the model view */
  guid: Scalars['String']['output'];
  /** Indicates whether the model view is a Master View. This attribute is present only if the model view is a Master View. Possible values: `true`: Model View is a Master View derived from a Revit file. */
  isMasterView?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the model view */
  name: Scalars['String']['output'];
  /** Type of model view. Possible values: `2d`, `3d` */
  role: Scalars['String']['output'];
}

export interface ForgeModelTranslateInputType {
  /** Group of inputs */
  input: ForgeModelTranslateInputTypeInput;
  /** Group of misc parameters */
  misc?: InputMaybe<ForgeModelTranslateInputTypeMisc>;
  /** Group of outputs */
  output: ForgeModelTranslateInputTypeOutput;
}

export interface ForgeModelTranslateInputTypeInput {
  /** Set this to `true` if the source file is compressed. If set to `true`, you need to define the `rootFilename` */
  compressedUrn?: InputMaybe<Scalars['Boolean']['input']>;
  /** The root filename of the compressed file. Mandatory if the `compressedUrn` is set to `true`. */
  rootFilename?: InputMaybe<Scalars['String']['input']>;
  /** The design URN; returned when uploading the file to Forge. The URN needs to be Base64 (URL Safe) encoded. http://www.freeformatter.com/base64-encoder.html */
  urn: Scalars['String']['input'];
}

export interface ForgeModelTranslateInputTypeMisc {
  /** The workflow id created for a webhook, used to listen to Model Derivative events. It needs to be no more than 36 chars, and only ASCII, decimal and hyphen are accepted. See the Creating a Webhook and Listening to Events tutorial for details. https://forge.autodesk.com/en/docs/webhooks/v1/tutorials/create-a-hook-model-derivative */
  workflow?: InputMaybe<Scalars['String']['input']>;
  /** A user-defined JSON object, which you can use to set some custom workflow information. It needs to be less than 1KB and will be ignored if `misc.workflow `parameter is not set. */
  workflowAttrubute?: InputMaybe<Scalars['Json']['input']>;
}

export interface ForgeModelTranslateInputTypeOutput {
  destination?: InputMaybe<ForgeModelTranslateInputTypeOutputDestination>;
  formats: Array<ForgeModelTranslateInputTypeOutputFormats>;
}

export interface ForgeModelTranslateInputTypeOutputDestination {
  /** Region in which to store outputs. Possible values: `US`, `EMEA`. By default, it is set to `US`. */
  region?: InputMaybe<Scalars['String']['input']>;
}

export interface ForgeModelTranslateInputTypeOutputFormats {
  /** Advanced options for `obj` output type */
  advanced?: InputMaybe<ForgeModelTranslateInputTypeOutputFormatsObjAdvanced>;
  /** The requested output types. Possible values: `dwg`, `fbx`, `ifc`, `iges`, `obj`, `step`, `stl`, `svf`, `svf2`, `thumbnail`. For a list of supported types, call the GET formats endpoint. https://forge.autodesk.com/en/docs/model-derivative/v2/reference/http/formats-GET */
  type: Scalars['String']['input'];
  /** Possible values: `2d`, `3d`. Conditional mandatory field check https://forge.autodesk.com/en/docs/model-derivative/v2/reference/http/job-POST/ */
  views?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface ForgeModelTranslateInputTypeOutputFormatsObjAdvanced {
  /** single (default): creates one OBJ file for all the input files (assembly file). multiple: creates a separate OBJ file for each object */
  exportFileStructure?: InputMaybe<Scalars['String']['input']>;
  /** Required for geometry extraction. The model view ID (guid). Currently only valid for 3d views. */
  modelGuid?: InputMaybe<Scalars['String']['input']>;
  /** Required for geometry extraction. List object ids to be translated. -1 will extract the entire model. Currently only valid for 3d views. */
  objectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /**
   * Translate models into different units; this causes the values to change. For example, from millimeters (10, 123, 31) to centimeters (1.0, 12.3, 3.1). If the source unit or the unit you are translating into is not supported, the values remain unchanged.
   *  Possible values:  `meter`, `decimeter`, `centimeter`, `millimeter`, `micrometer`, `nanometer`, `yard`, `foot`, `inch`, `mil`, `microinch`
   *  Note that this feature does not support F3D files.
   */
  unit?: InputMaybe<Scalars['String']['input']>;
}

export interface ForgeModelTranslateResponseAcceptedJobsType {
  __typename?: 'ForgeModelTranslateResponseAcceptedJobsType';
  output?: Maybe<Scalars['Json']['output']>;
}

export interface ForgeModelTranslateResponseType {
  __typename?: 'ForgeModelTranslateResponseType';
  acceptedJobs: ForgeModelTranslateResponseAcceptedJobsType;
  result: Scalars['String']['output'];
  urn: Scalars['String']['output'];
}

/** Autodesk Forge Mutations */
export interface ForgeMutations {
  __typename?: 'ForgeMutations';
  /** Data Management API: https://forge.autodesk.com/en/docs/data/v2/reference/ */
  dataManagement?: Maybe<ForgeDataManagementMutation>;
  /** Model Derivative API: https://forge.autodesk.com/en/docs/model-derivative/v2/developers_guide/overview/ */
  modelDerivative?: Maybe<ForgeModelDerivativeMutation>;
}

/** Autodesk Forge Query API */
export interface ForgeQueries {
  __typename?: 'ForgeQueries';
  /** Authentication (OAuth) API v1: https://forge.autodesk.com/en/docs/oauth/v1/developers_guide/ */
  authentication?: Maybe<ForgeAuthenticationQueries>;
  /** Data Management API: https://forge.autodesk.com/en/docs/data/v2/reference/ */
  dataManagement?: Maybe<ForgeDataManagementQueries>;
  /** Model Derivative API: https://forge.autodesk.com/en/docs/model-derivative/v2/developers_guide/overview/ */
  modelDerivative?: Maybe<ForgeModelDerivativeQueries>;
}

/** Forge region */
export enum ForgeRegion {
  Emea = 'EMEA',
  Us = 'US'
}

/** AutoDesk Forge 토큰 타입 */
export interface ForgeToken {
  __typename?: 'ForgeToken';
  access_token: Scalars['String']['output'];
  expires_in: Scalars['Int']['output'];
  refresh_token?: Maybe<Scalars['String']['output']>;
  token_type: Scalars['String']['output'];
}

export interface ForgeUploadItemResponse {
  __typename?: 'ForgeUploadItemResponse';
  bucketKey?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  objectId?: Maybe<Scalars['String']['output']>;
  /** Object Key URN Base64Url encoded. https://forge.autodesk.com/en/docs/model-derivative/v2/tutorials/translate-to-obj/task2-upload_source_file_to_oss/ We recommend using the unpadded option (RFC 6920), since it uses URL-safe alphabets. The following example shows a URN, its Base64-encoded form, and its URL safe Base64-encoded form */
  objectIdBase64Url?: Maybe<Scalars['String']['output']>;
  objectKey?: Maybe<Scalars['String']['output']>;
  sha1?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['BigInt']['output']>;
}

/** Partner:  종합건설사 */
export interface GeneralConstruction {
  __typename?: 'GeneralConstruction';
  _count: GeneralConstructionCountOutputType;
  /** Partner user address */
  address?: Maybe<Address>;
  addressDetail?: Maybe<Scalars['String']['output']>;
  /** Partner user addressId */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** Avatar URL */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** Partner businessNumber (사업자등록번호) */
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Name */
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partner: Partner;
  partnerId: Scalars['Int']['output'];
  partnerStatus: PartnerRegistrationStatus;
  profile: Array<Profile>;
  /** 사업자등록증 사진 키 */
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail: Scalars['String']['output'];
  responsibilityName: Scalars['String']['output'];
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  /** Partner user role */
  role: PartnerRole;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}


/** Partner:  종합건설사 */
export type GeneralConstructionProfileArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<ProfileScalarFieldEnum>;
  orderBy?: InputMaybe<ProfileOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};

export interface GeneralConstructionAvgAggregateOutputType {
  __typename?: 'GeneralConstructionAvgAggregateOutputType';
  addressId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  partnerId?: Maybe<Scalars['Float']['output']>;
}

export interface GeneralConstructionAvgOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface GeneralConstructionCountAggregateOutputType {
  __typename?: 'GeneralConstructionCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  addressDetail: Scalars['Int']['output'];
  addressId: Scalars['Int']['output'];
  avatarUrl: Scalars['Int']['output'];
  businessNumber: Scalars['Int']['output'];
  companyContact: Scalars['Int']['output'];
  companyDescription: Scalars['Int']['output'];
  companyWebsite: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  ownerName: Scalars['Int']['output'];
  partnerId: Scalars['Int']['output'];
  partnerStatus: Scalars['Int']['output'];
  registrationPhotoKey: Scalars['Int']['output'];
  responsibilityEmail: Scalars['Int']['output'];
  responsibilityName: Scalars['Int']['output'];
  responsibilityPosition: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface GeneralConstructionCountOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface GeneralConstructionCountOutputType {
  __typename?: 'GeneralConstructionCountOutputType';
  profile: Scalars['Int']['output'];
}

export interface GeneralConstructionCreateInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutGeneralConstructionInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutGeneralConstructionInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutGeneralConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface GeneralConstructionCreateManyAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface GeneralConstructionCreateManyAddressInputEnvelope {
  data: GeneralConstructionCreateManyAddressInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface GeneralConstructionCreateManyInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface GeneralConstructionCreateManyPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface GeneralConstructionCreateManyPartnerInputEnvelope {
  data: GeneralConstructionCreateManyPartnerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface GeneralConstructionCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<GeneralConstructionCreateManyAddressInputEnvelope>;
}

export interface GeneralConstructionCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<GeneralConstructionCreateManyPartnerInputEnvelope>;
}

export interface GeneralConstructionCreateNestedOneWithoutProfileInput {
  connect?: InputMaybe<GeneralConstructionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GeneralConstructionCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<GeneralConstructionUncheckedCreateWithoutProfileInput>;
}

export interface GeneralConstructionCreateOrConnectWithoutAddressInput {
  create: GeneralConstructionUncheckedCreateWithoutAddressInput;
  where: GeneralConstructionWhereUniqueInput;
}

export interface GeneralConstructionCreateOrConnectWithoutPartnerInput {
  create: GeneralConstructionUncheckedCreateWithoutPartnerInput;
  where: GeneralConstructionWhereUniqueInput;
}

export interface GeneralConstructionCreateOrConnectWithoutProfileInput {
  create: GeneralConstructionUncheckedCreateWithoutProfileInput;
  where: GeneralConstructionWhereUniqueInput;
}

export interface GeneralConstructionCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutGeneralConstructionInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutGeneralConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface GeneralConstructionCreateWithoutPartnerInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutGeneralConstructionInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutGeneralConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface GeneralConstructionCreateWithoutProfileInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutGeneralConstructionInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutGeneralConstructionInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface GeneralConstructionListRelationFilter {
  every?: InputMaybe<GeneralConstructionWhereInput>;
  none?: InputMaybe<GeneralConstructionWhereInput>;
  some?: InputMaybe<GeneralConstructionWhereInput>;
}

export interface GeneralConstructionMaxAggregateOutputType {
  __typename?: 'GeneralConstructionMaxAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface GeneralConstructionMaxOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface GeneralConstructionMinAggregateOutputType {
  __typename?: 'GeneralConstructionMinAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface GeneralConstructionMinOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface GeneralConstructionOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface GeneralConstructionOrderByWithAggregationInput {
  _avg?: InputMaybe<GeneralConstructionAvgOrderByAggregateInput>;
  _count?: InputMaybe<GeneralConstructionCountOrderByAggregateInput>;
  _max?: InputMaybe<GeneralConstructionMaxOrderByAggregateInput>;
  _min?: InputMaybe<GeneralConstructionMinOrderByAggregateInput>;
  _sum?: InputMaybe<GeneralConstructionSumOrderByAggregateInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface GeneralConstructionOrderByWithRelationInput {
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partner?: InputMaybe<PartnerOrderByWithRelationInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByRelationAggregateInput>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface GeneralConstructionRelationFilter {
  is?: InputMaybe<GeneralConstructionWhereInput>;
  isNot?: InputMaybe<GeneralConstructionWhereInput>;
}

export enum GeneralConstructionScalarFieldEnum {
  AddressDetail = 'addressDetail',
  AddressId = 'addressId',
  AvatarUrl = 'avatarUrl',
  BusinessNumber = 'businessNumber',
  CompanyContact = 'companyContact',
  CompanyDescription = 'companyDescription',
  CompanyWebsite = 'companyWebsite',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OwnerName = 'ownerName',
  PartnerId = 'partnerId',
  PartnerStatus = 'partnerStatus',
  RegistrationPhotoKey = 'registrationPhotoKey',
  ResponsibilityEmail = 'responsibilityEmail',
  ResponsibilityName = 'responsibilityName',
  ResponsibilityPosition = 'responsibilityPosition',
  Role = 'role',
  UpdatedAt = 'updatedAt'
}

export interface GeneralConstructionScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarWhereInput>>>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface GeneralConstructionScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarWhereWithAggregatesInput>>>;
  addressDetail?: InputMaybe<StringNullableWithAggregatesFilter>;
  addressId?: InputMaybe<IntNullableWithAggregatesFilter>;
  avatarUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  businessNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyContact?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyDescription?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyWebsite?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  ownerName?: InputMaybe<StringNullableWithAggregatesFilter>;
  partnerId?: InputMaybe<IntWithAggregatesFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusWithAggregatesFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableWithAggregatesFilter>;
  responsibilityEmail?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityName?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityPosition?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumPartnerRoleWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface GeneralConstructionSumAggregateOutputType {
  __typename?: 'GeneralConstructionSumAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
}

export interface GeneralConstructionSumOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface GeneralConstructionUncheckedCreateInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutGeneralConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface GeneralConstructionUncheckedCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<GeneralConstructionCreateManyAddressInputEnvelope>;
}

export interface GeneralConstructionUncheckedCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<GeneralConstructionCreateManyPartnerInputEnvelope>;
}

export interface GeneralConstructionUncheckedCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutGeneralConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface GeneralConstructionUncheckedCreateWithoutPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutGeneralConstructionInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface GeneralConstructionUncheckedCreateWithoutProfileInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface GeneralConstructionUncheckedUpdateInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutGeneralConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface GeneralConstructionUncheckedUpdateManyInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface GeneralConstructionUncheckedUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<GeneralConstructionCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<GeneralConstructionUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<GeneralConstructionUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<GeneralConstructionUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface GeneralConstructionUncheckedUpdateManyWithoutGeneralConstructionInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface GeneralConstructionUncheckedUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<GeneralConstructionCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<GeneralConstructionUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<GeneralConstructionUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<GeneralConstructionUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface GeneralConstructionUncheckedUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutGeneralConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface GeneralConstructionUncheckedUpdateWithoutPartnerInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutGeneralConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface GeneralConstructionUncheckedUpdateWithoutProfileInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface GeneralConstructionUpdateInput {
  address?: InputMaybe<AddressUpdateOneWithoutGeneralConstructionNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutGeneralConstructionNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutGeneralConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface GeneralConstructionUpdateManyMutationInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface GeneralConstructionUpdateManyWithWhereWithoutAddressInput {
  data: GeneralConstructionUncheckedUpdateManyWithoutGeneralConstructionInput;
  where: GeneralConstructionScalarWhereInput;
}

export interface GeneralConstructionUpdateManyWithWhereWithoutPartnerInput {
  data: GeneralConstructionUncheckedUpdateManyWithoutGeneralConstructionInput;
  where: GeneralConstructionScalarWhereInput;
}

export interface GeneralConstructionUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<GeneralConstructionCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<GeneralConstructionUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<GeneralConstructionUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<GeneralConstructionUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface GeneralConstructionUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<GeneralConstructionCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<GeneralConstructionCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<GeneralConstructionUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<GeneralConstructionUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<GeneralConstructionUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface GeneralConstructionUpdateOneWithoutProfileNestedInput {
  connect?: InputMaybe<GeneralConstructionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GeneralConstructionCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<GeneralConstructionUncheckedCreateWithoutProfileInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<GeneralConstructionUncheckedUpdateWithoutProfileInput>;
  upsert?: InputMaybe<GeneralConstructionUpsertWithoutProfileInput>;
}

export interface GeneralConstructionUpdateWithWhereUniqueWithoutAddressInput {
  data: GeneralConstructionUncheckedUpdateWithoutAddressInput;
  where: GeneralConstructionWhereUniqueInput;
}

export interface GeneralConstructionUpdateWithWhereUniqueWithoutPartnerInput {
  data: GeneralConstructionUncheckedUpdateWithoutPartnerInput;
  where: GeneralConstructionWhereUniqueInput;
}

export interface GeneralConstructionUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutGeneralConstructionNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutGeneralConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface GeneralConstructionUpdateWithoutPartnerInput {
  address?: InputMaybe<AddressUpdateOneWithoutGeneralConstructionNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutGeneralConstructionNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface GeneralConstructionUpdateWithoutProfileInput {
  address?: InputMaybe<AddressUpdateOneWithoutGeneralConstructionNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutGeneralConstructionNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface GeneralConstructionUpsertWithWhereUniqueWithoutAddressInput {
  create: GeneralConstructionUncheckedCreateWithoutAddressInput;
  update: GeneralConstructionUncheckedUpdateWithoutAddressInput;
  where: GeneralConstructionWhereUniqueInput;
}

export interface GeneralConstructionUpsertWithWhereUniqueWithoutPartnerInput {
  create: GeneralConstructionUncheckedCreateWithoutPartnerInput;
  update: GeneralConstructionUncheckedUpdateWithoutPartnerInput;
  where: GeneralConstructionWhereUniqueInput;
}

export interface GeneralConstructionUpsertWithoutProfileInput {
  create: GeneralConstructionUncheckedCreateWithoutProfileInput;
  update: GeneralConstructionUncheckedUpdateWithoutProfileInput;
}

export interface GeneralConstructionWhereInput {
  AND?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<GeneralConstructionWhereInput>>>;
  address?: InputMaybe<AddressWhereInput>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partner?: InputMaybe<PartnerWhereInput>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  profile?: InputMaybe<ProfileListRelationFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface GeneralConstructionWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export interface GetCostRatioInputType {
  frameCost: Scalars['Float']['input'];
  inputArea: Scalars['Int']['input'];
  isUnderground: Scalars['Boolean']['input'];
  noOfUnderground?: InputMaybe<Scalars['Int']['input']>;
}

export interface GetCostRatioResult {
  __typename?: 'GetCostRatioResult';
  categoryCost: CategoryCost;
  categoryRatio: CategoryRatio;
}

export interface GetForgeBucketObjectResponse {
  __typename?: 'GetForgeBucketObjectResponse';
  /** Bucket Key */
  bucketKey?: Maybe<Scalars['String']['output']>;
  /** URL to download the object */
  location?: Maybe<Scalars['String']['output']>;
  /** Next possible request */
  next?: Maybe<Scalars['String']['output']>;
  /** Object Key */
  objectId?: Maybe<Scalars['String']['output']>;
  /** Object Key URN Base64Url encoded. https://forge.autodesk.com/en/docs/model-derivative/v2/tutorials/translate-to-obj/task2-upload_source_file_to_oss/ We recommend using the unpadded option (RFC 6920), since it uses URL-safe alphabets. The following example shows a URN, its Base64-encoded form, and its URL safe Base64-encoded form */
  objectIdBase64Url?: Maybe<Scalars['String']['output']>;
  /** Object URN */
  objectKey?: Maybe<Scalars['String']['output']>;
  /** Object SHA1 */
  sha1?: Maybe<Scalars['String']['output']>;
  /** Object size */
  size?: Maybe<Scalars['BigInt']['output']>;
}

export interface GetForgeBucketObjectResponseList {
  __typename?: 'GetForgeBucketObjectResponseList';
  items?: Maybe<Array<Maybe<GetForgeBucketObjectResponse>>>;
}

/** Member identity verification data */
export interface Identity {
  __typename?: 'Identity';
  _count: IdentityCountOutputType;
  /** Birthdate */
  birth?: Maybe<Scalars['DateTime']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** Gender */
  gender?: Maybe<IdentityGender>;
  id: Scalars['Int']['output'];
  /** Member */
  member: Array<Member>;
  /** Full name */
  name: Scalars['String']['output'];
  /** PhoneNumber */
  phoneNumber: Scalars['String']['output'];
  /** Unique Key (uuid for not Korean) */
  uniqueKey: Scalars['String']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Verified (false for not Korean) */
  verified: Scalars['Boolean']['output'];
}


/** Member identity verification data */
export type IdentityMemberArgs = {
  cursor?: InputMaybe<MemberWhereUniqueInput>;
  distinct?: InputMaybe<MemberScalarFieldEnum>;
  orderBy?: InputMaybe<MemberOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberWhereInput>;
};

export interface IdentityAvgAggregateOutputType {
  __typename?: 'IdentityAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
}

export interface IdentityAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface IdentityCountAggregateOutputType {
  __typename?: 'IdentityCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  birth: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  gender: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  phoneNumber: Scalars['Int']['output'];
  uniqueKey: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  verified: Scalars['Int']['output'];
}

export interface IdentityCountOrderByAggregateInput {
  birth?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  uniqueKey?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verified?: InputMaybe<SortOrder>;
}

export interface IdentityCountOutputType {
  __typename?: 'IdentityCountOutputType';
  member: Scalars['Int']['output'];
}

export interface IdentityCreateInput {
  birth?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  gender?: InputMaybe<IdentityGender>;
  member?: InputMaybe<MemberCreateNestedManyWithoutIdentityInput>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  uniqueKey: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  verified: Scalars['Boolean']['input'];
}

export interface IdentityCreateManyInput {
  birth?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  gender?: InputMaybe<IdentityGender>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  uniqueKey: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  verified: Scalars['Boolean']['input'];
}

export interface IdentityCreateNestedOneWithoutMemberInput {
  connect?: InputMaybe<IdentityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IdentityCreateOrConnectWithoutMemberInput>;
  create?: InputMaybe<IdentityUncheckedCreateWithoutMemberInput>;
}

export interface IdentityCreateOrConnectWithoutMemberInput {
  create: IdentityUncheckedCreateWithoutMemberInput;
  where: IdentityWhereUniqueInput;
}

export interface IdentityCreateWithoutMemberInput {
  birth?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  gender?: InputMaybe<IdentityGender>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  uniqueKey: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  verified: Scalars['Boolean']['input'];
}

export enum IdentityGender {
  Female = 'female',
  Male = 'male',
  Unknown = 'unknown'
}

export interface IdentityMaxAggregateOutputType {
  __typename?: 'IdentityMaxAggregateOutputType';
  birth?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  gender?: Maybe<IdentityGender>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  uniqueKey?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
}

export interface IdentityMaxOrderByAggregateInput {
  birth?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  uniqueKey?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verified?: InputMaybe<SortOrder>;
}

export interface IdentityMinAggregateOutputType {
  __typename?: 'IdentityMinAggregateOutputType';
  birth?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  gender?: Maybe<IdentityGender>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  uniqueKey?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
}

export interface IdentityMinOrderByAggregateInput {
  birth?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  uniqueKey?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verified?: InputMaybe<SortOrder>;
}

export interface IdentityOrderByWithAggregationInput {
  _avg?: InputMaybe<IdentityAvgOrderByAggregateInput>;
  _count?: InputMaybe<IdentityCountOrderByAggregateInput>;
  _max?: InputMaybe<IdentityMaxOrderByAggregateInput>;
  _min?: InputMaybe<IdentityMinOrderByAggregateInput>;
  _sum?: InputMaybe<IdentitySumOrderByAggregateInput>;
  birth?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  uniqueKey?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verified?: InputMaybe<SortOrder>;
}

export interface IdentityOrderByWithRelationInput {
  birth?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  uniqueKey?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verified?: InputMaybe<SortOrder>;
}

export interface IdentityRelationFilter {
  is?: InputMaybe<IdentityWhereInput>;
  isNot?: InputMaybe<IdentityWhereInput>;
}

export enum IdentityScalarFieldEnum {
  Birth = 'birth',
  CreatedAt = 'createdAt',
  Gender = 'gender',
  Id = 'id',
  Name = 'name',
  PhoneNumber = 'phoneNumber',
  UniqueKey = 'uniqueKey',
  UpdatedAt = 'updatedAt',
  Verified = 'verified'
}

export interface IdentityScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<IdentityScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<IdentityScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<IdentityScalarWhereWithAggregatesInput>>>;
  birth?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  gender?: InputMaybe<EnumIdentityGenderNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  phoneNumber?: InputMaybe<StringWithAggregatesFilter>;
  uniqueKey?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  verified?: InputMaybe<BoolWithAggregatesFilter>;
}

export interface IdentitySumAggregateOutputType {
  __typename?: 'IdentitySumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
}

export interface IdentitySumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface IdentityUncheckedCreateInput {
  birth?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  gender?: InputMaybe<IdentityGender>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutIdentityInput>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  uniqueKey: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  verified: Scalars['Boolean']['input'];
}

export interface IdentityUncheckedCreateWithoutMemberInput {
  birth?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  gender?: InputMaybe<IdentityGender>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  uniqueKey: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  verified: Scalars['Boolean']['input'];
}

export interface IdentityUncheckedUpdateInput {
  birth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gender?: InputMaybe<NullableEnumIdentityGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutIdentityNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
}

export interface IdentityUncheckedUpdateManyInput {
  birth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gender?: InputMaybe<NullableEnumIdentityGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
}

export interface IdentityUncheckedUpdateWithoutMemberInput {
  birth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gender?: InputMaybe<NullableEnumIdentityGenderFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
}

export interface IdentityUpdateInput {
  birth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gender?: InputMaybe<NullableEnumIdentityGenderFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutIdentityNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
}

export interface IdentityUpdateManyMutationInput {
  birth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gender?: InputMaybe<NullableEnumIdentityGenderFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
}

export interface IdentityUpdateOneWithoutMemberNestedInput {
  connect?: InputMaybe<IdentityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IdentityCreateOrConnectWithoutMemberInput>;
  create?: InputMaybe<IdentityUncheckedCreateWithoutMemberInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<IdentityUncheckedUpdateWithoutMemberInput>;
  upsert?: InputMaybe<IdentityUpsertWithoutMemberInput>;
}

export interface IdentityUpdateWithoutMemberInput {
  birth?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  gender?: InputMaybe<NullableEnumIdentityGenderFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  verified?: InputMaybe<BoolFieldUpdateOperationsInput>;
}

export interface IdentityUpsertWithoutMemberInput {
  create: IdentityUncheckedCreateWithoutMemberInput;
  update: IdentityUncheckedUpdateWithoutMemberInput;
}

export interface IdentityWhereInput {
  AND?: InputMaybe<Array<InputMaybe<IdentityWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<IdentityWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<IdentityWhereInput>>>;
  birth?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  gender?: InputMaybe<EnumIdentityGenderNullableFilter>;
  id?: InputMaybe<IntFilter>;
  member?: InputMaybe<MemberListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  uniqueKey?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  verified?: InputMaybe<BoolFilter>;
}

export interface IdentityWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
}

export interface Inquiry {
  __typename?: 'Inquiry';
  content: Scalars['String']['output'];
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Int']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  inquiryType: InquiryType;
  member: Member;
  memberId: Scalars['Int']['output'];
  optInEmail: Scalars['Boolean']['output'];
  optInSms: Scalars['Boolean']['output'];
  originalFileName?: Maybe<Scalars['String']['output']>;
  reply?: Maybe<Reply>;
  /** 상태 false: 접수완료 true: 답변완료 */
  state: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  /** Unique ID */
  uuid: Scalars['String']['output'];
}

export interface InquiryAvgAggregateOutputType {
  __typename?: 'InquiryAvgAggregateOutputType';
  fileSize?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  memberId?: Maybe<Scalars['Float']['output']>;
}

export interface InquiryAvgOrderByAggregateInput {
  fileSize?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface InquiryCountAggregateOutputType {
  __typename?: 'InquiryCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  fileName: Scalars['Int']['output'];
  fileSize: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  inquiryType: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
  optInEmail: Scalars['Int']['output'];
  optInSms: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface InquiryCountOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  fileName?: InputMaybe<SortOrder>;
  fileSize?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inquiryType?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  optInEmail?: InputMaybe<SortOrder>;
  optInSms?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface InquiryCreateInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  inquiryType: InquiryType;
  member: MemberCreateNestedOneWithoutInquiryInput;
  optInEmail: Scalars['Boolean']['input'];
  optInSms: Scalars['Boolean']['input'];
  reply?: InputMaybe<ReplyCreateNestedOneWithoutInquiryInput>;
  state?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InquiryCreateManyInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inquiryType: InquiryType;
  memberId: Scalars['Int']['input'];
  optInEmail: Scalars['Boolean']['input'];
  optInSms: Scalars['Boolean']['input'];
  state?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InquiryCreateManyMemberInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inquiryType: InquiryType;
  optInEmail: Scalars['Boolean']['input'];
  optInSms: Scalars['Boolean']['input'];
  state?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InquiryCreateManyMemberInputEnvelope {
  data: InquiryCreateManyMemberInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface InquiryCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<InquiryWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<InquiryCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<InquiryCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<InquiryCreateManyMemberInputEnvelope>;
}

export interface InquiryCreateNestedOneWithoutReplyInput {
  connect?: InputMaybe<InquiryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InquiryCreateOrConnectWithoutReplyInput>;
  create?: InputMaybe<InquiryUncheckedCreateWithoutReplyInput>;
}

export interface InquiryCreateOrConnectWithoutMemberInput {
  create: InquiryUncheckedCreateWithoutMemberInput;
  where: InquiryWhereUniqueInput;
}

export interface InquiryCreateOrConnectWithoutReplyInput {
  create: InquiryUncheckedCreateWithoutReplyInput;
  where: InquiryWhereUniqueInput;
}

export interface InquiryCreateWithoutMemberInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  inquiryType: InquiryType;
  optInEmail: Scalars['Boolean']['input'];
  optInSms: Scalars['Boolean']['input'];
  reply?: InputMaybe<ReplyCreateNestedOneWithoutInquiryInput>;
  state?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InquiryCreateWithoutReplyInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  inquiryType: InquiryType;
  member: MemberCreateNestedOneWithoutInquiryInput;
  optInEmail: Scalars['Boolean']['input'];
  optInSms: Scalars['Boolean']['input'];
  state?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InquiryListRelationFilter {
  every?: InputMaybe<InquiryWhereInput>;
  none?: InputMaybe<InquiryWhereInput>;
  some?: InputMaybe<InquiryWhereInput>;
}

export interface InquiryMaxAggregateOutputType {
  __typename?: 'InquiryMaxAggregateOutputType';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inquiryType?: Maybe<InquiryType>;
  memberId?: Maybe<Scalars['Int']['output']>;
  optInEmail?: Maybe<Scalars['Boolean']['output']>;
  optInSms?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface InquiryMaxOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  fileName?: InputMaybe<SortOrder>;
  fileSize?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inquiryType?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  optInEmail?: InputMaybe<SortOrder>;
  optInSms?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface InquiryMinAggregateOutputType {
  __typename?: 'InquiryMinAggregateOutputType';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inquiryType?: Maybe<InquiryType>;
  memberId?: Maybe<Scalars['Int']['output']>;
  optInEmail?: Maybe<Scalars['Boolean']['output']>;
  optInSms?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface InquiryMinOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  fileName?: InputMaybe<SortOrder>;
  fileSize?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inquiryType?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  optInEmail?: InputMaybe<SortOrder>;
  optInSms?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface InquiryOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface InquiryOrderByWithAggregationInput {
  _avg?: InputMaybe<InquiryAvgOrderByAggregateInput>;
  _count?: InputMaybe<InquiryCountOrderByAggregateInput>;
  _max?: InputMaybe<InquiryMaxOrderByAggregateInput>;
  _min?: InputMaybe<InquiryMinOrderByAggregateInput>;
  _sum?: InputMaybe<InquirySumOrderByAggregateInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  fileName?: InputMaybe<SortOrderInput>;
  fileSize?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inquiryType?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  optInEmail?: InputMaybe<SortOrder>;
  optInSms?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
}

export interface InquiryOrderByWithRelationInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  fileName?: InputMaybe<SortOrderInput>;
  fileSize?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inquiryType?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberOrderByWithRelationInput>;
  memberId?: InputMaybe<SortOrder>;
  optInEmail?: InputMaybe<SortOrder>;
  optInSms?: InputMaybe<SortOrder>;
  reply?: InputMaybe<ReplyOrderByWithRelationInput>;
  state?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
}

export interface InquiryRelationFilter {
  is?: InputMaybe<InquiryWhereInput>;
  isNot?: InputMaybe<InquiryWhereInput>;
}

export enum InquiryScalarFieldEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  FileName = 'fileName',
  FileSize = 'fileSize',
  Id = 'id',
  InquiryType = 'inquiryType',
  MemberId = 'memberId',
  OptInEmail = 'optInEmail',
  OptInSms = 'optInSms',
  State = 'state',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  Uuid = 'uuid'
}

export interface InquiryScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<InquiryScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<InquiryScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<InquiryScalarWhereInput>>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fileName?: InputMaybe<StringNullableFilter>;
  fileSize?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  inquiryType?: InputMaybe<EnumInquiryTypeFilter>;
  memberId?: InputMaybe<IntFilter>;
  optInEmail?: InputMaybe<BoolFilter>;
  optInSms?: InputMaybe<BoolFilter>;
  state?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface InquiryScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<InquiryScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<InquiryScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<InquiryScalarWhereWithAggregatesInput>>>;
  content?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  fileName?: InputMaybe<StringNullableWithAggregatesFilter>;
  fileSize?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  inquiryType?: InputMaybe<EnumInquiryTypeWithAggregatesFilter>;
  memberId?: InputMaybe<IntWithAggregatesFilter>;
  optInEmail?: InputMaybe<BoolWithAggregatesFilter>;
  optInSms?: InputMaybe<BoolWithAggregatesFilter>;
  state?: InputMaybe<BoolWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  url?: InputMaybe<StringNullableWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface InquirySumAggregateOutputType {
  __typename?: 'InquirySumAggregateOutputType';
  fileSize?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
}

export interface InquirySumOrderByAggregateInput {
  fileSize?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export enum InquiryType {
  Etc = 'ETC',
  Modeling = 'MODELING',
  Service = 'SERVICE',
  Suggestion = 'SUGGESTION'
}

export interface InquiryUncheckedCreateInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inquiryType: InquiryType;
  memberId: Scalars['Int']['input'];
  optInEmail: Scalars['Boolean']['input'];
  optInSms: Scalars['Boolean']['input'];
  reply?: InputMaybe<ReplyUncheckedCreateNestedOneWithoutInquiryInput>;
  state?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InquiryUncheckedCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<InquiryWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<InquiryCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<InquiryCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<InquiryCreateManyMemberInputEnvelope>;
}

export interface InquiryUncheckedCreateWithoutMemberInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inquiryType: InquiryType;
  optInEmail: Scalars['Boolean']['input'];
  optInSms: Scalars['Boolean']['input'];
  reply?: InputMaybe<ReplyUncheckedCreateNestedOneWithoutInquiryInput>;
  state?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InquiryUncheckedCreateWithoutReplyInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inquiryType: InquiryType;
  memberId: Scalars['Int']['input'];
  optInEmail: Scalars['Boolean']['input'];
  optInSms: Scalars['Boolean']['input'];
  state?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InquiryUncheckedUpdateInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  inquiryType?: InputMaybe<EnumInquiryTypeFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  optInEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  optInSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reply?: InputMaybe<ReplyUncheckedUpdateOneWithoutInquiryNestedInput>;
  state?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InquiryUncheckedUpdateManyInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  inquiryType?: InputMaybe<EnumInquiryTypeFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  optInEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  optInSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  state?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InquiryUncheckedUpdateManyWithoutInquiryInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  inquiryType?: InputMaybe<EnumInquiryTypeFieldUpdateOperationsInput>;
  optInEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  optInSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  state?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InquiryUncheckedUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<InquiryWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<InquiryCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<InquiryCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<InquiryCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<InquiryWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<InquiryScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<InquiryWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<InquiryWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<InquiryUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<InquiryUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<InquiryUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface InquiryUncheckedUpdateWithoutMemberInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  inquiryType?: InputMaybe<EnumInquiryTypeFieldUpdateOperationsInput>;
  optInEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  optInSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reply?: InputMaybe<ReplyUncheckedUpdateOneWithoutInquiryNestedInput>;
  state?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InquiryUncheckedUpdateWithoutReplyInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  inquiryType?: InputMaybe<EnumInquiryTypeFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  optInEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  optInSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  state?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InquiryUpdateInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  inquiryType?: InputMaybe<EnumInquiryTypeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateOneRequiredWithoutInquiryNestedInput>;
  optInEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  optInSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reply?: InputMaybe<ReplyUpdateOneWithoutInquiryNestedInput>;
  state?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InquiryUpdateManyMutationInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  inquiryType?: InputMaybe<EnumInquiryTypeFieldUpdateOperationsInput>;
  optInEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  optInSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  state?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InquiryUpdateManyWithWhereWithoutMemberInput {
  data: InquiryUncheckedUpdateManyWithoutInquiryInput;
  where: InquiryScalarWhereInput;
}

export interface InquiryUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<InquiryWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<InquiryCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<InquiryCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<InquiryCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<InquiryWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<InquiryScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<InquiryWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<InquiryWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<InquiryUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<InquiryUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<InquiryUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface InquiryUpdateOneRequiredWithoutReplyNestedInput {
  connect?: InputMaybe<InquiryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InquiryCreateOrConnectWithoutReplyInput>;
  create?: InputMaybe<InquiryUncheckedCreateWithoutReplyInput>;
  update?: InputMaybe<InquiryUncheckedUpdateWithoutReplyInput>;
  upsert?: InputMaybe<InquiryUpsertWithoutReplyInput>;
}

export interface InquiryUpdateWithWhereUniqueWithoutMemberInput {
  data: InquiryUncheckedUpdateWithoutMemberInput;
  where: InquiryWhereUniqueInput;
}

export interface InquiryUpdateWithoutMemberInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  inquiryType?: InputMaybe<EnumInquiryTypeFieldUpdateOperationsInput>;
  optInEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  optInSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  reply?: InputMaybe<ReplyUpdateOneWithoutInquiryNestedInput>;
  state?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InquiryUpdateWithoutReplyInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileSize?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  inquiryType?: InputMaybe<EnumInquiryTypeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateOneRequiredWithoutInquiryNestedInput>;
  optInEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  optInSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  state?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InquiryUpsertWithWhereUniqueWithoutMemberInput {
  create: InquiryUncheckedCreateWithoutMemberInput;
  update: InquiryUncheckedUpdateWithoutMemberInput;
  where: InquiryWhereUniqueInput;
}

export interface InquiryUpsertWithoutReplyInput {
  create: InquiryUncheckedCreateWithoutReplyInput;
  update: InquiryUncheckedUpdateWithoutReplyInput;
}

export interface InquiryWhereInput {
  AND?: InputMaybe<Array<InputMaybe<InquiryWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<InquiryWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<InquiryWhereInput>>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fileName?: InputMaybe<StringNullableFilter>;
  fileSize?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  inquiryType?: InputMaybe<EnumInquiryTypeFilter>;
  member?: InputMaybe<MemberWhereInput>;
  memberId?: InputMaybe<IntFilter>;
  optInEmail?: InputMaybe<BoolFilter>;
  optInSms?: InputMaybe<BoolFilter>;
  reply?: InputMaybe<ReplyWhereInput>;
  state?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface InquiryWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** List of Rejected reason and files */
export interface InspectionRejected {
  __typename?: 'InspectionRejected';
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  docTypeArchiRejected: RejectionStatus;
  docTypeEtcRejected: RejectionStatus;
  docTypeModelRejected: RejectionStatus;
  docTypeStrucRejected: RejectionStatus;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Model service select */
  modelService: UploadOption;
  /** Project */
  project: Project;
  projectId: Scalars['Int']['output'];
  /** Blueprint reject reson */
  rejectedReason: Scalars['String']['output'];
  /** Unique ID */
  uuid: Scalars['String']['output'];
}

export interface InspectionRejectedAvgAggregateOutputType {
  __typename?: 'InspectionRejectedAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  projectId?: Maybe<Scalars['Float']['output']>;
}

export interface InspectionRejectedAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface InspectionRejectedCountAggregateOutputType {
  __typename?: 'InspectionRejectedCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  docTypeArchiRejected: Scalars['Int']['output'];
  docTypeEtcRejected: Scalars['Int']['output'];
  docTypeModelRejected: Scalars['Int']['output'];
  docTypeStrucRejected: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  modelService: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  rejectedReason: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface InspectionRejectedCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  docTypeArchiRejected?: InputMaybe<SortOrder>;
  docTypeEtcRejected?: InputMaybe<SortOrder>;
  docTypeModelRejected?: InputMaybe<SortOrder>;
  docTypeStrucRejected?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modelService?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  rejectedReason?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface InspectionRejectedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  docTypeArchiRejected?: InputMaybe<RejectionStatus>;
  docTypeEtcRejected?: InputMaybe<RejectionStatus>;
  docTypeModelRejected?: InputMaybe<RejectionStatus>;
  docTypeStrucRejected?: InputMaybe<RejectionStatus>;
  modelService?: InputMaybe<UploadOption>;
  project: ProjectCreateNestedOneWithoutInspectionRejectedInput;
  rejectedReason: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InspectionRejectedCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  docTypeArchiRejected?: InputMaybe<RejectionStatus>;
  docTypeEtcRejected?: InputMaybe<RejectionStatus>;
  docTypeModelRejected?: InputMaybe<RejectionStatus>;
  docTypeStrucRejected?: InputMaybe<RejectionStatus>;
  id?: InputMaybe<Scalars['Int']['input']>;
  modelService?: InputMaybe<UploadOption>;
  projectId: Scalars['Int']['input'];
  rejectedReason: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InspectionRejectedCreateManyProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  docTypeArchiRejected?: InputMaybe<RejectionStatus>;
  docTypeEtcRejected?: InputMaybe<RejectionStatus>;
  docTypeModelRejected?: InputMaybe<RejectionStatus>;
  docTypeStrucRejected?: InputMaybe<RejectionStatus>;
  id?: InputMaybe<Scalars['Int']['input']>;
  modelService?: InputMaybe<UploadOption>;
  rejectedReason: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InspectionRejectedCreateManyProjectInputEnvelope {
  data: InspectionRejectedCreateManyProjectInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface InspectionRejectedCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<InspectionRejectedCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<InspectionRejectedCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<InspectionRejectedCreateManyProjectInputEnvelope>;
}

export interface InspectionRejectedCreateOrConnectWithoutProjectInput {
  create: InspectionRejectedUncheckedCreateWithoutProjectInput;
  where: InspectionRejectedWhereUniqueInput;
}

export interface InspectionRejectedCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  docTypeArchiRejected?: InputMaybe<RejectionStatus>;
  docTypeEtcRejected?: InputMaybe<RejectionStatus>;
  docTypeModelRejected?: InputMaybe<RejectionStatus>;
  docTypeStrucRejected?: InputMaybe<RejectionStatus>;
  modelService?: InputMaybe<UploadOption>;
  rejectedReason: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InspectionRejectedListRelationFilter {
  every?: InputMaybe<InspectionRejectedWhereInput>;
  none?: InputMaybe<InspectionRejectedWhereInput>;
  some?: InputMaybe<InspectionRejectedWhereInput>;
}

export interface InspectionRejectedMaxAggregateOutputType {
  __typename?: 'InspectionRejectedMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  docTypeArchiRejected?: Maybe<RejectionStatus>;
  docTypeEtcRejected?: Maybe<RejectionStatus>;
  docTypeModelRejected?: Maybe<RejectionStatus>;
  docTypeStrucRejected?: Maybe<RejectionStatus>;
  id?: Maybe<Scalars['Int']['output']>;
  modelService?: Maybe<UploadOption>;
  projectId?: Maybe<Scalars['Int']['output']>;
  rejectedReason?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface InspectionRejectedMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  docTypeArchiRejected?: InputMaybe<SortOrder>;
  docTypeEtcRejected?: InputMaybe<SortOrder>;
  docTypeModelRejected?: InputMaybe<SortOrder>;
  docTypeStrucRejected?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modelService?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  rejectedReason?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface InspectionRejectedMinAggregateOutputType {
  __typename?: 'InspectionRejectedMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  docTypeArchiRejected?: Maybe<RejectionStatus>;
  docTypeEtcRejected?: Maybe<RejectionStatus>;
  docTypeModelRejected?: Maybe<RejectionStatus>;
  docTypeStrucRejected?: Maybe<RejectionStatus>;
  id?: Maybe<Scalars['Int']['output']>;
  modelService?: Maybe<UploadOption>;
  projectId?: Maybe<Scalars['Int']['output']>;
  rejectedReason?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface InspectionRejectedMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  docTypeArchiRejected?: InputMaybe<SortOrder>;
  docTypeEtcRejected?: InputMaybe<SortOrder>;
  docTypeModelRejected?: InputMaybe<SortOrder>;
  docTypeStrucRejected?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modelService?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  rejectedReason?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface InspectionRejectedOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface InspectionRejectedOrderByWithAggregationInput {
  _avg?: InputMaybe<InspectionRejectedAvgOrderByAggregateInput>;
  _count?: InputMaybe<InspectionRejectedCountOrderByAggregateInput>;
  _max?: InputMaybe<InspectionRejectedMaxOrderByAggregateInput>;
  _min?: InputMaybe<InspectionRejectedMinOrderByAggregateInput>;
  _sum?: InputMaybe<InspectionRejectedSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  docTypeArchiRejected?: InputMaybe<SortOrder>;
  docTypeEtcRejected?: InputMaybe<SortOrder>;
  docTypeModelRejected?: InputMaybe<SortOrder>;
  docTypeStrucRejected?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modelService?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  rejectedReason?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface InspectionRejectedOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  docTypeArchiRejected?: InputMaybe<SortOrder>;
  docTypeEtcRejected?: InputMaybe<SortOrder>;
  docTypeModelRejected?: InputMaybe<SortOrder>;
  docTypeStrucRejected?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modelService?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  rejectedReason?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export enum InspectionRejectedScalarFieldEnum {
  CreatedAt = 'createdAt',
  DocTypeArchiRejected = 'docTypeArchiRejected',
  DocTypeEtcRejected = 'docTypeEtcRejected',
  DocTypeModelRejected = 'docTypeModelRejected',
  DocTypeStrucRejected = 'docTypeStrucRejected',
  Id = 'id',
  ModelService = 'modelService',
  ProjectId = 'projectId',
  RejectedReason = 'rejectedReason',
  Uuid = 'uuid'
}

export interface InspectionRejectedScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<InspectionRejectedScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<InspectionRejectedScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<InspectionRejectedScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  docTypeArchiRejected?: InputMaybe<EnumRejectionStatusFilter>;
  docTypeEtcRejected?: InputMaybe<EnumRejectionStatusFilter>;
  docTypeModelRejected?: InputMaybe<EnumRejectionStatusFilter>;
  docTypeStrucRejected?: InputMaybe<EnumRejectionStatusFilter>;
  id?: InputMaybe<IntFilter>;
  modelService?: InputMaybe<EnumUploadOptionFilter>;
  projectId?: InputMaybe<IntFilter>;
  rejectedReason?: InputMaybe<StringFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface InspectionRejectedScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<InspectionRejectedScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<InspectionRejectedScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<InspectionRejectedScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  docTypeArchiRejected?: InputMaybe<EnumRejectionStatusWithAggregatesFilter>;
  docTypeEtcRejected?: InputMaybe<EnumRejectionStatusWithAggregatesFilter>;
  docTypeModelRejected?: InputMaybe<EnumRejectionStatusWithAggregatesFilter>;
  docTypeStrucRejected?: InputMaybe<EnumRejectionStatusWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  modelService?: InputMaybe<EnumUploadOptionWithAggregatesFilter>;
  projectId?: InputMaybe<IntWithAggregatesFilter>;
  rejectedReason?: InputMaybe<StringWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface InspectionRejectedSumAggregateOutputType {
  __typename?: 'InspectionRejectedSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
}

export interface InspectionRejectedSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface InspectionRejectedUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  docTypeArchiRejected?: InputMaybe<RejectionStatus>;
  docTypeEtcRejected?: InputMaybe<RejectionStatus>;
  docTypeModelRejected?: InputMaybe<RejectionStatus>;
  docTypeStrucRejected?: InputMaybe<RejectionStatus>;
  id?: InputMaybe<Scalars['Int']['input']>;
  modelService?: InputMaybe<UploadOption>;
  projectId: Scalars['Int']['input'];
  rejectedReason: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<InspectionRejectedCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<InspectionRejectedCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<InspectionRejectedCreateManyProjectInputEnvelope>;
}

export interface InspectionRejectedUncheckedCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  docTypeArchiRejected?: InputMaybe<RejectionStatus>;
  docTypeEtcRejected?: InputMaybe<RejectionStatus>;
  docTypeModelRejected?: InputMaybe<RejectionStatus>;
  docTypeStrucRejected?: InputMaybe<RejectionStatus>;
  id?: InputMaybe<Scalars['Int']['input']>;
  modelService?: InputMaybe<UploadOption>;
  rejectedReason: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface InspectionRejectedUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  docTypeArchiRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeEtcRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeModelRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeStrucRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InspectionRejectedUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  docTypeArchiRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeEtcRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeModelRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeStrucRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InspectionRejectedUncheckedUpdateManyWithoutInspectionRejectedInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  docTypeArchiRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeEtcRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeModelRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeStrucRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<InspectionRejectedCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<InspectionRejectedCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<InspectionRejectedCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<InspectionRejectedScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<InspectionRejectedUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<InspectionRejectedUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<InspectionRejectedUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface InspectionRejectedUncheckedUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  docTypeArchiRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeEtcRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeModelRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeStrucRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InspectionRejectedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  docTypeArchiRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeEtcRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeModelRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeStrucRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutInspectionRejectedNestedInput>;
  rejectedReason?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InspectionRejectedUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  docTypeArchiRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeEtcRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeModelRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeStrucRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InspectionRejectedUpdateManyWithWhereWithoutProjectInput {
  data: InspectionRejectedUncheckedUpdateManyWithoutInspectionRejectedInput;
  where: InspectionRejectedScalarWhereInput;
}

export interface InspectionRejectedUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<InspectionRejectedCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<InspectionRejectedCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<InspectionRejectedCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<InspectionRejectedScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<InspectionRejectedUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<InspectionRejectedUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<InspectionRejectedUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface InspectionRejectedUpdateWithWhereUniqueWithoutProjectInput {
  data: InspectionRejectedUncheckedUpdateWithoutProjectInput;
  where: InspectionRejectedWhereUniqueInput;
}

export interface InspectionRejectedUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  docTypeArchiRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeEtcRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeModelRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  docTypeStrucRejected?: InputMaybe<EnumRejectionStatusFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface InspectionRejectedUpsertWithWhereUniqueWithoutProjectInput {
  create: InspectionRejectedUncheckedCreateWithoutProjectInput;
  update: InspectionRejectedUncheckedUpdateWithoutProjectInput;
  where: InspectionRejectedWhereUniqueInput;
}

export interface InspectionRejectedWhereInput {
  AND?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<InspectionRejectedWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  docTypeArchiRejected?: InputMaybe<EnumRejectionStatusFilter>;
  docTypeEtcRejected?: InputMaybe<EnumRejectionStatusFilter>;
  docTypeModelRejected?: InputMaybe<EnumRejectionStatusFilter>;
  docTypeStrucRejected?: InputMaybe<EnumRejectionStatusFilter>;
  id?: InputMaybe<IntFilter>;
  modelService?: InputMaybe<EnumUploadOptionFilter>;
  project?: InputMaybe<ProjectWhereInput>;
  projectId?: InputMaybe<IntFilter>;
  rejectedReason?: InputMaybe<StringFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface InspectionRejectedWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface IntFieldUpdateOperationsInput {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
}

export interface IntFilter {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
}

export interface IntNullableFilter {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
}

export interface IntNullableWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
}

export interface IntWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
}

export interface JsonFilter {
  array_contains?: InputMaybe<Scalars['Json']['input']>;
  array_ends_with?: InputMaybe<Scalars['Json']['input']>;
  array_starts_with?: InputMaybe<Scalars['Json']['input']>;
  equals?: InputMaybe<Scalars['Json']['input']>;
  gt?: InputMaybe<Scalars['Json']['input']>;
  gte?: InputMaybe<Scalars['Json']['input']>;
  lt?: InputMaybe<Scalars['Json']['input']>;
  lte?: InputMaybe<Scalars['Json']['input']>;
  not?: InputMaybe<Scalars['Json']['input']>;
  path?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
}

export enum JsonNullValueFilter {
  AnyNull = 'AnyNull',
  DbNull = 'DbNull',
  JsonNull = 'JsonNull'
}

export enum JsonNullValueInput {
  JsonNull = 'JsonNull'
}

export interface JsonNullableFilter {
  array_contains?: InputMaybe<Scalars['Json']['input']>;
  array_ends_with?: InputMaybe<Scalars['Json']['input']>;
  array_starts_with?: InputMaybe<Scalars['Json']['input']>;
  equals?: InputMaybe<Scalars['Json']['input']>;
  gt?: InputMaybe<Scalars['Json']['input']>;
  gte?: InputMaybe<Scalars['Json']['input']>;
  lt?: InputMaybe<Scalars['Json']['input']>;
  lte?: InputMaybe<Scalars['Json']['input']>;
  not?: InputMaybe<Scalars['Json']['input']>;
  path?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
}

export interface JsonNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  array_contains?: InputMaybe<Scalars['Json']['input']>;
  array_ends_with?: InputMaybe<Scalars['Json']['input']>;
  array_starts_with?: InputMaybe<Scalars['Json']['input']>;
  equals?: InputMaybe<Scalars['Json']['input']>;
  gt?: InputMaybe<Scalars['Json']['input']>;
  gte?: InputMaybe<Scalars['Json']['input']>;
  lt?: InputMaybe<Scalars['Json']['input']>;
  lte?: InputMaybe<Scalars['Json']['input']>;
  not?: InputMaybe<Scalars['Json']['input']>;
  path?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
}

export interface JsonWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedJsonFilter>;
  _min?: InputMaybe<NestedJsonFilter>;
  array_contains?: InputMaybe<Scalars['Json']['input']>;
  array_ends_with?: InputMaybe<Scalars['Json']['input']>;
  array_starts_with?: InputMaybe<Scalars['Json']['input']>;
  equals?: InputMaybe<Scalars['Json']['input']>;
  gt?: InputMaybe<Scalars['Json']['input']>;
  gte?: InputMaybe<Scalars['Json']['input']>;
  lt?: InputMaybe<Scalars['Json']['input']>;
  lte?: InputMaybe<Scalars['Json']['input']>;
  not?: InputMaybe<Scalars['Json']['input']>;
  path?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
}

/** Kakao 알림톡 Template */
export interface KakaoMessageTemplate {
  __typename?: 'KakaoMessageTemplate';
  KakaoSendLog: Array<KakaoSendLog>;
  _count: KakaoMessageTemplateCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  templateCode: KakaoTemplate;
  templateContent: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
}


/** Kakao 알림톡 Template */
export type KakaoMessageTemplateKakaoSendLogArgs = {
  cursor?: InputMaybe<KakaoSendLogWhereUniqueInput>;
  distinct?: InputMaybe<KakaoSendLogScalarFieldEnum>;
  orderBy?: InputMaybe<KakaoSendLogOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KakaoSendLogWhereInput>;
};

export interface KakaoMessageTemplateAvgAggregateOutputType {
  __typename?: 'KakaoMessageTemplateAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
}

export interface KakaoMessageTemplateAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface KakaoMessageTemplateCountAggregateOutputType {
  __typename?: 'KakaoMessageTemplateCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  templateCode: Scalars['Int']['output'];
  templateContent: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface KakaoMessageTemplateCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  templateCode?: InputMaybe<SortOrder>;
  templateContent?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface KakaoMessageTemplateCountOutputType {
  __typename?: 'KakaoMessageTemplateCountOutputType';
  KakaoSendLog: Scalars['Int']['output'];
}

export interface KakaoMessageTemplateCreateInput {
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutKakaoMessageTemplateInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  templateCode: KakaoTemplate;
  templateContent: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface KakaoMessageTemplateCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  templateCode: KakaoTemplate;
  templateContent: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface KakaoMessageTemplateCreateNestedOneWithoutKakaoSendLogInput {
  connect?: InputMaybe<KakaoMessageTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KakaoMessageTemplateCreateOrConnectWithoutKakaoSendLogInput>;
  create?: InputMaybe<KakaoMessageTemplateUncheckedCreateWithoutKakaoSendLogInput>;
}

export interface KakaoMessageTemplateCreateOrConnectWithoutKakaoSendLogInput {
  create: KakaoMessageTemplateUncheckedCreateWithoutKakaoSendLogInput;
  where: KakaoMessageTemplateWhereUniqueInput;
}

export interface KakaoMessageTemplateCreateWithoutKakaoSendLogInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  templateCode: KakaoTemplate;
  templateContent: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface KakaoMessageTemplateMaxAggregateOutputType {
  __typename?: 'KakaoMessageTemplateMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  templateCode?: Maybe<KakaoTemplate>;
  templateContent?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface KakaoMessageTemplateMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  templateCode?: InputMaybe<SortOrder>;
  templateContent?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface KakaoMessageTemplateMinAggregateOutputType {
  __typename?: 'KakaoMessageTemplateMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  templateCode?: Maybe<KakaoTemplate>;
  templateContent?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface KakaoMessageTemplateMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  templateCode?: InputMaybe<SortOrder>;
  templateContent?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface KakaoMessageTemplateOrderByWithAggregationInput {
  _avg?: InputMaybe<KakaoMessageTemplateAvgOrderByAggregateInput>;
  _count?: InputMaybe<KakaoMessageTemplateCountOrderByAggregateInput>;
  _max?: InputMaybe<KakaoMessageTemplateMaxOrderByAggregateInput>;
  _min?: InputMaybe<KakaoMessageTemplateMinOrderByAggregateInput>;
  _sum?: InputMaybe<KakaoMessageTemplateSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  templateCode?: InputMaybe<SortOrder>;
  templateContent?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface KakaoMessageTemplateOrderByWithRelationInput {
  KakaoSendLog?: InputMaybe<KakaoSendLogOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  templateCode?: InputMaybe<SortOrder>;
  templateContent?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface KakaoMessageTemplateRelationFilter {
  is?: InputMaybe<KakaoMessageTemplateWhereInput>;
  isNot?: InputMaybe<KakaoMessageTemplateWhereInput>;
}

export enum KakaoMessageTemplateScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  TemplateCode = 'templateCode',
  TemplateContent = 'templateContent',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface KakaoMessageTemplateScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<KakaoMessageTemplateScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<KakaoMessageTemplateScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<KakaoMessageTemplateScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  templateCode?: InputMaybe<EnumKakaoTemplateWithAggregatesFilter>;
  templateContent?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface KakaoMessageTemplateSumAggregateOutputType {
  __typename?: 'KakaoMessageTemplateSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
}

export interface KakaoMessageTemplateSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface KakaoMessageTemplateUncheckedCreateInput {
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutKakaoMessageTemplateInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  templateCode: KakaoTemplate;
  templateContent: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface KakaoMessageTemplateUncheckedCreateWithoutKakaoSendLogInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  templateCode: KakaoTemplate;
  templateContent: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface KakaoMessageTemplateUncheckedUpdateInput {
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutKakaoMessageTemplateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
  templateContent?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface KakaoMessageTemplateUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
  templateContent?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface KakaoMessageTemplateUncheckedUpdateWithoutKakaoSendLogInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
  templateContent?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface KakaoMessageTemplateUpdateInput {
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutKakaoMessageTemplateNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
  templateContent?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface KakaoMessageTemplateUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
  templateContent?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface KakaoMessageTemplateUpdateOneRequiredWithoutKakaoSendLogNestedInput {
  connect?: InputMaybe<KakaoMessageTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<KakaoMessageTemplateCreateOrConnectWithoutKakaoSendLogInput>;
  create?: InputMaybe<KakaoMessageTemplateUncheckedCreateWithoutKakaoSendLogInput>;
  update?: InputMaybe<KakaoMessageTemplateUncheckedUpdateWithoutKakaoSendLogInput>;
  upsert?: InputMaybe<KakaoMessageTemplateUpsertWithoutKakaoSendLogInput>;
}

export interface KakaoMessageTemplateUpdateWithoutKakaoSendLogInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
  templateContent?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface KakaoMessageTemplateUpsertWithoutKakaoSendLogInput {
  create: KakaoMessageTemplateUncheckedCreateWithoutKakaoSendLogInput;
  update: KakaoMessageTemplateUncheckedUpdateWithoutKakaoSendLogInput;
}

export interface KakaoMessageTemplateWhereInput {
  AND?: InputMaybe<Array<InputMaybe<KakaoMessageTemplateWhereInput>>>;
  KakaoSendLog?: InputMaybe<KakaoSendLogListRelationFilter>;
  NOT?: InputMaybe<Array<InputMaybe<KakaoMessageTemplateWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<KakaoMessageTemplateWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  templateCode?: InputMaybe<EnumKakaoTemplateFilter>;
  templateContent?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface KakaoMessageTemplateWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  templateCode?: InputMaybe<KakaoTemplate>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** Kakao 알림톡 log */
export interface KakaoSendLog {
  __typename?: 'KakaoSendLog';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  kakaoMessageTemplate: KakaoMessageTemplate;
  kakaoMessageTemplateId: Scalars['Int']['output'];
  log: Scalars['Json']['output'];
  member: Member;
  memberId: Scalars['Int']['output'];
  phoneNumber: Scalars['String']['output'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Int']['output']>;
  projectStatus?: Maybe<ProjectStatus>;
  success: Scalars['Boolean']['output'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogAvgAggregateOutputType {
  __typename?: 'KakaoSendLogAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  kakaoMessageTemplateId?: Maybe<Scalars['Float']['output']>;
  memberId?: Maybe<Scalars['Float']['output']>;
  projectId?: Maybe<Scalars['Float']['output']>;
}

export interface KakaoSendLogAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  kakaoMessageTemplateId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface KakaoSendLogCountAggregateOutputType {
  __typename?: 'KakaoSendLogCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  kakaoMessageTemplateId: Scalars['Int']['output'];
  log: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
  phoneNumber: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  projectStatus: Scalars['Int']['output'];
  success: Scalars['Int']['output'];
  templateCode: Scalars['Int']['output'];
}

export interface KakaoSendLogCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kakaoMessageTemplateId?: InputMaybe<SortOrder>;
  log?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  projectStatus?: InputMaybe<SortOrder>;
  success?: InputMaybe<SortOrder>;
  templateCode?: InputMaybe<SortOrder>;
}

export interface KakaoSendLogCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  kakaoMessageTemplate: KakaoMessageTemplateCreateNestedOneWithoutKakaoSendLogInput;
  log: Scalars['Json']['input'];
  member: MemberCreateNestedOneWithoutKakaoSendLogInput;
  phoneNumber: Scalars['String']['input'];
  project?: InputMaybe<ProjectCreateNestedOneWithoutKakaoSendLogInput>;
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  kakaoMessageTemplateId: Scalars['Int']['input'];
  log: Scalars['Json']['input'];
  memberId: Scalars['Int']['input'];
  phoneNumber: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['Int']['input']>;
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogCreateManyKakaoMessageTemplateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  log: Scalars['Json']['input'];
  memberId: Scalars['Int']['input'];
  phoneNumber: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['Int']['input']>;
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogCreateManyKakaoMessageTemplateInputEnvelope {
  data: KakaoSendLogCreateManyKakaoMessageTemplateInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface KakaoSendLogCreateManyMemberInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  kakaoMessageTemplateId: Scalars['Int']['input'];
  log: Scalars['Json']['input'];
  phoneNumber: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['Int']['input']>;
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogCreateManyMemberInputEnvelope {
  data: KakaoSendLogCreateManyMemberInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface KakaoSendLogCreateManyProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  kakaoMessageTemplateId: Scalars['Int']['input'];
  log: Scalars['Json']['input'];
  memberId: Scalars['Int']['input'];
  phoneNumber: Scalars['String']['input'];
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogCreateManyProjectInputEnvelope {
  data: KakaoSendLogCreateManyProjectInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface KakaoSendLogCreateNestedManyWithoutKakaoMessageTemplateInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutKakaoMessageTemplateInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutKakaoMessageTemplateInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyKakaoMessageTemplateInputEnvelope>;
}

export interface KakaoSendLogCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyMemberInputEnvelope>;
}

export interface KakaoSendLogCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyProjectInputEnvelope>;
}

export interface KakaoSendLogCreateOrConnectWithoutKakaoMessageTemplateInput {
  create: KakaoSendLogUncheckedCreateWithoutKakaoMessageTemplateInput;
  where: KakaoSendLogWhereUniqueInput;
}

export interface KakaoSendLogCreateOrConnectWithoutMemberInput {
  create: KakaoSendLogUncheckedCreateWithoutMemberInput;
  where: KakaoSendLogWhereUniqueInput;
}

export interface KakaoSendLogCreateOrConnectWithoutProjectInput {
  create: KakaoSendLogUncheckedCreateWithoutProjectInput;
  where: KakaoSendLogWhereUniqueInput;
}

export interface KakaoSendLogCreateWithoutKakaoMessageTemplateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  log: Scalars['Json']['input'];
  member: MemberCreateNestedOneWithoutKakaoSendLogInput;
  phoneNumber: Scalars['String']['input'];
  project?: InputMaybe<ProjectCreateNestedOneWithoutKakaoSendLogInput>;
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogCreateWithoutMemberInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  kakaoMessageTemplate: KakaoMessageTemplateCreateNestedOneWithoutKakaoSendLogInput;
  log: Scalars['Json']['input'];
  phoneNumber: Scalars['String']['input'];
  project?: InputMaybe<ProjectCreateNestedOneWithoutKakaoSendLogInput>;
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  kakaoMessageTemplate: KakaoMessageTemplateCreateNestedOneWithoutKakaoSendLogInput;
  log: Scalars['Json']['input'];
  member: MemberCreateNestedOneWithoutKakaoSendLogInput;
  phoneNumber: Scalars['String']['input'];
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogListRelationFilter {
  every?: InputMaybe<KakaoSendLogWhereInput>;
  none?: InputMaybe<KakaoSendLogWhereInput>;
  some?: InputMaybe<KakaoSendLogWhereInput>;
}

export interface KakaoSendLogMaxAggregateOutputType {
  __typename?: 'KakaoSendLogMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  kakaoMessageTemplateId?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  projectStatus?: Maybe<ProjectStatus>;
  success?: Maybe<Scalars['Boolean']['output']>;
  templateCode?: Maybe<KakaoTemplate>;
}

export interface KakaoSendLogMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kakaoMessageTemplateId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  projectStatus?: InputMaybe<SortOrder>;
  success?: InputMaybe<SortOrder>;
  templateCode?: InputMaybe<SortOrder>;
}

export interface KakaoSendLogMinAggregateOutputType {
  __typename?: 'KakaoSendLogMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  kakaoMessageTemplateId?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  projectStatus?: Maybe<ProjectStatus>;
  success?: Maybe<Scalars['Boolean']['output']>;
  templateCode?: Maybe<KakaoTemplate>;
}

export interface KakaoSendLogMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kakaoMessageTemplateId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  projectStatus?: InputMaybe<SortOrder>;
  success?: InputMaybe<SortOrder>;
  templateCode?: InputMaybe<SortOrder>;
}

export interface KakaoSendLogOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface KakaoSendLogOrderByWithAggregationInput {
  _avg?: InputMaybe<KakaoSendLogAvgOrderByAggregateInput>;
  _count?: InputMaybe<KakaoSendLogCountOrderByAggregateInput>;
  _max?: InputMaybe<KakaoSendLogMaxOrderByAggregateInput>;
  _min?: InputMaybe<KakaoSendLogMinOrderByAggregateInput>;
  _sum?: InputMaybe<KakaoSendLogSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kakaoMessageTemplateId?: InputMaybe<SortOrder>;
  log?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrderInput>;
  projectStatus?: InputMaybe<SortOrderInput>;
  success?: InputMaybe<SortOrder>;
  templateCode?: InputMaybe<SortOrder>;
}

export interface KakaoSendLogOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  kakaoMessageTemplate?: InputMaybe<KakaoMessageTemplateOrderByWithRelationInput>;
  kakaoMessageTemplateId?: InputMaybe<SortOrder>;
  log?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberOrderByWithRelationInput>;
  memberId?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrderInput>;
  projectStatus?: InputMaybe<SortOrderInput>;
  success?: InputMaybe<SortOrder>;
  templateCode?: InputMaybe<SortOrder>;
}

export enum KakaoSendLogScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  KakaoMessageTemplateId = 'kakaoMessageTemplateId',
  Log = 'log',
  MemberId = 'memberId',
  PhoneNumber = 'phoneNumber',
  ProjectId = 'projectId',
  ProjectStatus = 'projectStatus',
  Success = 'success',
  TemplateCode = 'templateCode'
}

export interface KakaoSendLogScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  kakaoMessageTemplateId?: InputMaybe<IntFilter>;
  log?: InputMaybe<JsonFilter>;
  memberId?: InputMaybe<IntFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  projectId?: InputMaybe<IntNullableFilter>;
  projectStatus?: InputMaybe<EnumProjectStatusNullableFilter>;
  success?: InputMaybe<BoolFilter>;
  templateCode?: InputMaybe<EnumKakaoTemplateFilter>;
}

export interface KakaoSendLogScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  kakaoMessageTemplateId?: InputMaybe<IntWithAggregatesFilter>;
  log?: InputMaybe<JsonWithAggregatesFilter>;
  memberId?: InputMaybe<IntWithAggregatesFilter>;
  phoneNumber?: InputMaybe<StringWithAggregatesFilter>;
  projectId?: InputMaybe<IntNullableWithAggregatesFilter>;
  projectStatus?: InputMaybe<EnumProjectStatusNullableWithAggregatesFilter>;
  success?: InputMaybe<BoolWithAggregatesFilter>;
  templateCode?: InputMaybe<EnumKakaoTemplateWithAggregatesFilter>;
}

export interface KakaoSendLogSumAggregateOutputType {
  __typename?: 'KakaoSendLogSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  kakaoMessageTemplateId?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
}

export interface KakaoSendLogSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  kakaoMessageTemplateId?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface KakaoSendLogUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  kakaoMessageTemplateId: Scalars['Int']['input'];
  log: Scalars['Json']['input'];
  memberId: Scalars['Int']['input'];
  phoneNumber: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['Int']['input']>;
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogUncheckedCreateNestedManyWithoutKakaoMessageTemplateInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutKakaoMessageTemplateInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutKakaoMessageTemplateInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyKakaoMessageTemplateInputEnvelope>;
}

export interface KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyMemberInputEnvelope>;
}

export interface KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyProjectInputEnvelope>;
}

export interface KakaoSendLogUncheckedCreateWithoutKakaoMessageTemplateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  log: Scalars['Json']['input'];
  memberId: Scalars['Int']['input'];
  phoneNumber: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['Int']['input']>;
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogUncheckedCreateWithoutMemberInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  kakaoMessageTemplateId: Scalars['Int']['input'];
  log: Scalars['Json']['input'];
  phoneNumber: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['Int']['input']>;
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogUncheckedCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  kakaoMessageTemplateId: Scalars['Int']['input'];
  log: Scalars['Json']['input'];
  memberId: Scalars['Int']['input'];
  phoneNumber: Scalars['String']['input'];
  projectStatus?: InputMaybe<ProjectStatus>;
  success: Scalars['Boolean']['input'];
  templateCode: KakaoTemplate;
}

export interface KakaoSendLogUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  kakaoMessageTemplateId?: InputMaybe<IntFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectStatus?: InputMaybe<NullableEnumProjectStatusFieldUpdateOperationsInput>;
  success?: InputMaybe<BoolFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
}

export interface KakaoSendLogUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  kakaoMessageTemplateId?: InputMaybe<IntFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectStatus?: InputMaybe<NullableEnumProjectStatusFieldUpdateOperationsInput>;
  success?: InputMaybe<BoolFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
}

export interface KakaoSendLogUncheckedUpdateManyWithoutKakaoMessageTemplateNestedInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutKakaoMessageTemplateInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutKakaoMessageTemplateInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyKakaoMessageTemplateInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateWithWhereUniqueWithoutKakaoMessageTemplateInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateManyWithWhereWithoutKakaoMessageTemplateInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<KakaoSendLogUpsertWithWhereUniqueWithoutKakaoMessageTemplateInput>>>;
}

export interface KakaoSendLogUncheckedUpdateManyWithoutKakaoSendLogInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  kakaoMessageTemplateId?: InputMaybe<IntFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectStatus?: InputMaybe<NullableEnumProjectStatusFieldUpdateOperationsInput>;
  success?: InputMaybe<BoolFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
}

export interface KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<KakaoSendLogUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<KakaoSendLogUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface KakaoSendLogUncheckedUpdateWithoutKakaoMessageTemplateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectStatus?: InputMaybe<NullableEnumProjectStatusFieldUpdateOperationsInput>;
  success?: InputMaybe<BoolFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
}

export interface KakaoSendLogUncheckedUpdateWithoutMemberInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  kakaoMessageTemplateId?: InputMaybe<IntFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectStatus?: InputMaybe<NullableEnumProjectStatusFieldUpdateOperationsInput>;
  success?: InputMaybe<BoolFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
}

export interface KakaoSendLogUncheckedUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  kakaoMessageTemplateId?: InputMaybe<IntFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  projectStatus?: InputMaybe<NullableEnumProjectStatusFieldUpdateOperationsInput>;
  success?: InputMaybe<BoolFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
}

export interface KakaoSendLogUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  kakaoMessageTemplate?: InputMaybe<KakaoMessageTemplateUpdateOneRequiredWithoutKakaoSendLogNestedInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  member?: InputMaybe<MemberUpdateOneRequiredWithoutKakaoSendLogNestedInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneWithoutKakaoSendLogNestedInput>;
  projectStatus?: InputMaybe<NullableEnumProjectStatusFieldUpdateOperationsInput>;
  success?: InputMaybe<BoolFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
}

export interface KakaoSendLogUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  projectStatus?: InputMaybe<NullableEnumProjectStatusFieldUpdateOperationsInput>;
  success?: InputMaybe<BoolFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
}

export interface KakaoSendLogUpdateManyWithWhereWithoutKakaoMessageTemplateInput {
  data: KakaoSendLogUncheckedUpdateManyWithoutKakaoSendLogInput;
  where: KakaoSendLogScalarWhereInput;
}

export interface KakaoSendLogUpdateManyWithWhereWithoutMemberInput {
  data: KakaoSendLogUncheckedUpdateManyWithoutKakaoSendLogInput;
  where: KakaoSendLogScalarWhereInput;
}

export interface KakaoSendLogUpdateManyWithWhereWithoutProjectInput {
  data: KakaoSendLogUncheckedUpdateManyWithoutKakaoSendLogInput;
  where: KakaoSendLogScalarWhereInput;
}

export interface KakaoSendLogUpdateManyWithoutKakaoMessageTemplateNestedInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutKakaoMessageTemplateInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutKakaoMessageTemplateInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyKakaoMessageTemplateInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateWithWhereUniqueWithoutKakaoMessageTemplateInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateManyWithWhereWithoutKakaoMessageTemplateInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<KakaoSendLogUpsertWithWhereUniqueWithoutKakaoMessageTemplateInput>>>;
}

export interface KakaoSendLogUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<KakaoSendLogUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface KakaoSendLogUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<KakaoSendLogCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<KakaoSendLogCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<KakaoSendLogScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<KakaoSendLogUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<KakaoSendLogUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface KakaoSendLogUpdateWithWhereUniqueWithoutKakaoMessageTemplateInput {
  data: KakaoSendLogUncheckedUpdateWithoutKakaoMessageTemplateInput;
  where: KakaoSendLogWhereUniqueInput;
}

export interface KakaoSendLogUpdateWithWhereUniqueWithoutMemberInput {
  data: KakaoSendLogUncheckedUpdateWithoutMemberInput;
  where: KakaoSendLogWhereUniqueInput;
}

export interface KakaoSendLogUpdateWithWhereUniqueWithoutProjectInput {
  data: KakaoSendLogUncheckedUpdateWithoutProjectInput;
  where: KakaoSendLogWhereUniqueInput;
}

export interface KakaoSendLogUpdateWithoutKakaoMessageTemplateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  member?: InputMaybe<MemberUpdateOneRequiredWithoutKakaoSendLogNestedInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneWithoutKakaoSendLogNestedInput>;
  projectStatus?: InputMaybe<NullableEnumProjectStatusFieldUpdateOperationsInput>;
  success?: InputMaybe<BoolFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
}

export interface KakaoSendLogUpdateWithoutMemberInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  kakaoMessageTemplate?: InputMaybe<KakaoMessageTemplateUpdateOneRequiredWithoutKakaoSendLogNestedInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneWithoutKakaoSendLogNestedInput>;
  projectStatus?: InputMaybe<NullableEnumProjectStatusFieldUpdateOperationsInput>;
  success?: InputMaybe<BoolFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
}

export interface KakaoSendLogUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  kakaoMessageTemplate?: InputMaybe<KakaoMessageTemplateUpdateOneRequiredWithoutKakaoSendLogNestedInput>;
  log?: InputMaybe<Scalars['Json']['input']>;
  member?: InputMaybe<MemberUpdateOneRequiredWithoutKakaoSendLogNestedInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  projectStatus?: InputMaybe<NullableEnumProjectStatusFieldUpdateOperationsInput>;
  success?: InputMaybe<BoolFieldUpdateOperationsInput>;
  templateCode?: InputMaybe<EnumKakaoTemplateFieldUpdateOperationsInput>;
}

export interface KakaoSendLogUpsertWithWhereUniqueWithoutKakaoMessageTemplateInput {
  create: KakaoSendLogUncheckedCreateWithoutKakaoMessageTemplateInput;
  update: KakaoSendLogUncheckedUpdateWithoutKakaoMessageTemplateInput;
  where: KakaoSendLogWhereUniqueInput;
}

export interface KakaoSendLogUpsertWithWhereUniqueWithoutMemberInput {
  create: KakaoSendLogUncheckedCreateWithoutMemberInput;
  update: KakaoSendLogUncheckedUpdateWithoutMemberInput;
  where: KakaoSendLogWhereUniqueInput;
}

export interface KakaoSendLogUpsertWithWhereUniqueWithoutProjectInput {
  create: KakaoSendLogUncheckedCreateWithoutProjectInput;
  update: KakaoSendLogUncheckedUpdateWithoutProjectInput;
  where: KakaoSendLogWhereUniqueInput;
}

export interface KakaoSendLogWhereInput {
  AND?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<KakaoSendLogWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  kakaoMessageTemplate?: InputMaybe<KakaoMessageTemplateWhereInput>;
  kakaoMessageTemplateId?: InputMaybe<IntFilter>;
  log?: InputMaybe<JsonFilter>;
  member?: InputMaybe<MemberWhereInput>;
  memberId?: InputMaybe<IntFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  project?: InputMaybe<ProjectWhereInput>;
  projectId?: InputMaybe<IntNullableFilter>;
  projectStatus?: InputMaybe<EnumProjectStatusNullableFilter>;
  success?: InputMaybe<BoolFilter>;
  templateCode?: InputMaybe<EnumKakaoTemplateFilter>;
}

export interface KakaoSendLogWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export enum KakaoTemplate {
  CsResponded = 'CS_RESPONDED',
  ProjectInspectionNotPassed = 'PROJECT_INSPECTION_NOT_PASSED',
  ProjectInspectionPassed = 'PROJECT_INSPECTION_PASSED',
  ProjectModelingCompleted = 'PROJECT_MODELING_COMPLETED',
  ProjectPaymentCompleted = 'PROJECT_PAYMENT_COMPLETED',
  ProjectShared = 'PROJECT_SHARED',
  ProjectUnderInspection = 'PROJECT_UNDER_INSPECTION',
  ProjectWorkingOnModeling = 'PROJECT_WORKING_ON_MODELING',
  Test = 'TEST'
}

export enum Keyword {
  Bim_3DModelingCalculation = 'BIM_3DModelingCalculation',
  BrokerageAgencyLease = 'Brokerage_Agency_Lease',
  ConstructionDesign = 'ConstructionDesign',
  CraftsmanConstructionMachinery = 'Craftsman_ConstructionMachinery',
  EcoFriendlyMaterials = 'EcoFriendlyMaterials',
  Engineering = 'Engineering',
  FacilityManagementMaintenance = 'FacilityManagement_Maintenance',
  GeneralConstruction = 'GeneralConstruction',
  GeneralCommercialBuilding = 'General_CommercialBuilding',
  HomeOfficeFurnishing = 'Home_OfficeFurnishing',
  ImplementationBusinessFeasibilityReview = 'Implementation_BusinessFeasibilityReview',
  Interior = 'Interior',
  InteriorExteriorFinishingMaterials = 'Interior_ExteriorFinishingMaterials',
  IoTHomeAppliancesIndoorEnvironment = 'IoT_HomeAppliances_IndoorEnvironment',
  MajorBuildingMaterials = 'MajorBuildingMaterials',
  MetropolitanArea = 'MetropolitanArea',
  MultiGenerationalApartmentHousing = 'MultiGenerational_ApartmentHousing',
  NationwideRegion = 'NationwideRegion',
  PfFinanceInsuranceGuarantee = 'PF_Finance_Insurance_Guarantee',
  PmConsulting = 'PM_Consulting',
  PlanningDesign = 'PlanningDesign',
  PlatformSolution = 'Platform_Solution',
  ProfessionalConstruction = 'ProfessionalConstruction',
  RemodelingDemolition = 'Remodeling_Demolition',
  SalesMarketingPromotion = 'Sales_Marketing_Promotion',
  SpecialSpecialMaterials = 'Special_SpecialMaterials',
  SpecializationSpaceDesign = 'Specialization_SpaceDesign',
  StructuralDesign = 'StructuralDesign',
  SupervisionCm = 'Supervision_CM',
  TotalizationEstimation = 'Totalization_Estimation'
}

export enum KindEnum {
  Enum = 'enum',
  Object = 'object',
  Scalar = 'scalar'
}

/** Landscaping Share ratio of works(조경공사 점유율): '식재공사' '포장공사' '조경시설물' '기타공사(조경)' */
export interface LandscapingSrw {
  __typename?: 'LandscapingSRW';
  ShareRatioWork: Array<ShareRatioWork>;
  _count: LandscapingSrwCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  mainWork: MainWorkCategory;
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  work: LandscapingWorkCategory;
  workName: Scalars['String']['output'];
}


/** Landscaping Share ratio of works(조경공사 점유율): '식재공사' '포장공사' '조경시설물' '기타공사(조경)' */
export type LandscapingSrwShareRatioWorkArgs = {
  cursor?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  distinct?: InputMaybe<ShareRatioWorkScalarFieldEnum>;
  orderBy?: InputMaybe<ShareRatioWorkOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};

export interface LandscapingSrwAvgAggregateOutputType {
  __typename?: 'LandscapingSRWAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface LandscapingSrwAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface LandscapingSrwCountAggregateOutputType {
  __typename?: 'LandscapingSRWCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  mainWork: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  work: Scalars['Int']['output'];
  workName: Scalars['Int']['output'];
}

export interface LandscapingSrwCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface LandscapingSrwCountOutputType {
  __typename?: 'LandscapingSRWCountOutputType';
  ShareRatioWork: Scalars['Int']['output'];
}

export interface LandscapingSrwCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedManyWithoutLandscapingInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: LandscapingWorkCategory;
  workName: Scalars['String']['input'];
}

export interface LandscapingSrwCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: LandscapingWorkCategory;
  workName: Scalars['String']['input'];
}

export interface LandscapingSrwCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<LandscapingSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<LandscapingSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface LandscapingSrwCreateOrConnectWithoutShareRatioWorkInput {
  create: LandscapingSrwUncheckedCreateWithoutShareRatioWorkInput;
  where: LandscapingSrwWhereUniqueInput;
}

export interface LandscapingSrwCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: LandscapingWorkCategory;
  workName: Scalars['String']['input'];
}

export interface LandscapingSrwListRelationFilter {
  every?: InputMaybe<LandscapingSrwWhereInput>;
  none?: InputMaybe<LandscapingSrwWhereInput>;
  some?: InputMaybe<LandscapingSrwWhereInput>;
}

export interface LandscapingSrwMaxAggregateOutputType {
  __typename?: 'LandscapingSRWMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<LandscapingWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface LandscapingSrwMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface LandscapingSrwMinAggregateOutputType {
  __typename?: 'LandscapingSRWMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<LandscapingWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface LandscapingSrwMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface LandscapingSrwOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface LandscapingSrwOrderByWithAggregationInput {
  _avg?: InputMaybe<LandscapingSrwAvgOrderByAggregateInput>;
  _count?: InputMaybe<LandscapingSrwCountOrderByAggregateInput>;
  _max?: InputMaybe<LandscapingSrwMaxOrderByAggregateInput>;
  _min?: InputMaybe<LandscapingSrwMinOrderByAggregateInput>;
  _sum?: InputMaybe<LandscapingSrwSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface LandscapingSrwOrderByWithRelationInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export enum LandscapingSrwScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  MainWork = 'mainWork',
  Order = 'order',
  ShareRatio = 'shareRatio',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  Work = 'work',
  WorkName = 'workName'
}

export interface LandscapingSrwScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<LandscapingSrwScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<LandscapingSrwScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<LandscapingSrwScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumLandscapingWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface LandscapingSrwScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<LandscapingSrwScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<LandscapingSrwScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<LandscapingSrwScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  shareRatio?: InputMaybe<FloatWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  work?: InputMaybe<EnumLandscapingWorkCategoryWithAggregatesFilter>;
  workName?: InputMaybe<StringWithAggregatesFilter>;
}

export interface LandscapingSrwSumAggregateOutputType {
  __typename?: 'LandscapingSRWSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface LandscapingSrwSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface LandscapingSrwUncheckedCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedCreateNestedManyWithoutLandscapingInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: LandscapingWorkCategory;
  workName: Scalars['String']['input'];
}

export interface LandscapingSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<LandscapingSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<LandscapingSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface LandscapingSrwUncheckedCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: LandscapingWorkCategory;
  workName: Scalars['String']['input'];
}

export interface LandscapingSrwUncheckedUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedUpdateManyWithoutLandscapingNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumLandscapingWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface LandscapingSrwUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumLandscapingWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface LandscapingSrwUncheckedUpdateManyWithoutLandscapingInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumLandscapingWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface LandscapingSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<LandscapingSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<LandscapingSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<LandscapingSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<LandscapingSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<LandscapingSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<LandscapingSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface LandscapingSrwUncheckedUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumLandscapingWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface LandscapingSrwUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUpdateManyWithoutLandscapingNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumLandscapingWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface LandscapingSrwUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumLandscapingWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface LandscapingSrwUpdateManyWithWhereWithoutShareRatioWorkInput {
  data: LandscapingSrwUncheckedUpdateManyWithoutLandscapingInput;
  where: LandscapingSrwScalarWhereInput;
}

export interface LandscapingSrwUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<LandscapingSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<LandscapingSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<LandscapingSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<LandscapingSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<LandscapingSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<LandscapingSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface LandscapingSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput {
  data: LandscapingSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: LandscapingSrwWhereUniqueInput;
}

export interface LandscapingSrwUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumLandscapingWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface LandscapingSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput {
  create: LandscapingSrwUncheckedCreateWithoutShareRatioWorkInput;
  update: LandscapingSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: LandscapingSrwWhereUniqueInput;
}

export interface LandscapingSrwWhereInput {
  AND?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<LandscapingSrwWhereInput>>>;
  ShareRatioWork?: InputMaybe<ShareRatioWorkListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumLandscapingWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface LandscapingSrwWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export enum LandscapingWorkCategory {
  LandscapingFacilities = 'landscapingFacilities',
  OtherWork = 'otherWork',
  PlantingWork = 'plantingWork'
}

export enum MainWorkCategory {
  ArchitecturalWork = 'architecturalWork',
  CivilWork = 'civilWork',
  CommunicationWork = 'communicationWork',
  ElectricalWork = 'electricalWork',
  FireProtectionWork = 'fireProtectionWork',
  LandscapingWork = 'landscapingWork',
  MechanicalWork = 'mechanicalWork'
}

/** 물가정보지 물가정보 */
export interface MaterialPrice {
  __typename?: 'MaterialPrice';
  createdAt: Scalars['DateTime']['output'];
  detailCategory?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  mainCategory: Scalars['String']['output'];
  modelName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['String']['output'];
  priceIssuedMonth: Scalars['Int']['output'];
  priceIssuedYear: Scalars['Int']['output'];
  spec?: Maybe<Scalars['String']['output']>;
  subCategory?: Maybe<Scalars['String']['output']>;
  subSpec?: Maybe<Scalars['String']['output']>;
  unit: Scalars['String']['output'];
}

export interface MaterialPriceAvgAggregateOutputType {
  __typename?: 'MaterialPriceAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  priceIssuedMonth?: Maybe<Scalars['Float']['output']>;
  priceIssuedYear?: Maybe<Scalars['Float']['output']>;
}

export interface MaterialPriceAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  priceIssuedMonth?: InputMaybe<SortOrder>;
  priceIssuedYear?: InputMaybe<SortOrder>;
}

export interface MaterialPriceCountAggregateOutputType {
  __typename?: 'MaterialPriceCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  detailCategory: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  mainCategory: Scalars['Int']['output'];
  modelName: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  priceIssuedMonth: Scalars['Int']['output'];
  priceIssuedYear: Scalars['Int']['output'];
  spec: Scalars['Int']['output'];
  subCategory: Scalars['Int']['output'];
  subSpec: Scalars['Int']['output'];
  unit: Scalars['Int']['output'];
}

export interface MaterialPriceCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  detailCategory?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainCategory?: InputMaybe<SortOrder>;
  modelName?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceIssuedMonth?: InputMaybe<SortOrder>;
  priceIssuedYear?: InputMaybe<SortOrder>;
  spec?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  subSpec?: InputMaybe<SortOrder>;
  unit?: InputMaybe<SortOrder>;
}

export interface MaterialPriceCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detailCategory?: InputMaybe<Scalars['String']['input']>;
  mainCategory: Scalars['String']['input'];
  modelName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['String']['input'];
  priceIssuedMonth: Scalars['Int']['input'];
  priceIssuedYear: Scalars['Int']['input'];
  spec?: InputMaybe<Scalars['String']['input']>;
  subCategory?: InputMaybe<Scalars['String']['input']>;
  subSpec?: InputMaybe<Scalars['String']['input']>;
  unit: Scalars['String']['input'];
}

export interface MaterialPriceCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detailCategory?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainCategory: Scalars['String']['input'];
  modelName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['String']['input'];
  priceIssuedMonth: Scalars['Int']['input'];
  priceIssuedYear: Scalars['Int']['input'];
  spec?: InputMaybe<Scalars['String']['input']>;
  subCategory?: InputMaybe<Scalars['String']['input']>;
  subSpec?: InputMaybe<Scalars['String']['input']>;
  unit: Scalars['String']['input'];
}

export interface MaterialPriceMaxAggregateOutputType {
  __typename?: 'MaterialPriceMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  detailCategory?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainCategory?: Maybe<Scalars['String']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  priceIssuedMonth?: Maybe<Scalars['Int']['output']>;
  priceIssuedYear?: Maybe<Scalars['Int']['output']>;
  spec?: Maybe<Scalars['String']['output']>;
  subCategory?: Maybe<Scalars['String']['output']>;
  subSpec?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
}

export interface MaterialPriceMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  detailCategory?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainCategory?: InputMaybe<SortOrder>;
  modelName?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceIssuedMonth?: InputMaybe<SortOrder>;
  priceIssuedYear?: InputMaybe<SortOrder>;
  spec?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  subSpec?: InputMaybe<SortOrder>;
  unit?: InputMaybe<SortOrder>;
}

export interface MaterialPriceMinAggregateOutputType {
  __typename?: 'MaterialPriceMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  detailCategory?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainCategory?: Maybe<Scalars['String']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  priceIssuedMonth?: Maybe<Scalars['Int']['output']>;
  priceIssuedYear?: Maybe<Scalars['Int']['output']>;
  spec?: Maybe<Scalars['String']['output']>;
  subCategory?: Maybe<Scalars['String']['output']>;
  subSpec?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
}

export interface MaterialPriceMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  detailCategory?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainCategory?: InputMaybe<SortOrder>;
  modelName?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceIssuedMonth?: InputMaybe<SortOrder>;
  priceIssuedYear?: InputMaybe<SortOrder>;
  spec?: InputMaybe<SortOrder>;
  subCategory?: InputMaybe<SortOrder>;
  subSpec?: InputMaybe<SortOrder>;
  unit?: InputMaybe<SortOrder>;
}

export interface MaterialPriceOrderByWithAggregationInput {
  _avg?: InputMaybe<MaterialPriceAvgOrderByAggregateInput>;
  _count?: InputMaybe<MaterialPriceCountOrderByAggregateInput>;
  _max?: InputMaybe<MaterialPriceMaxOrderByAggregateInput>;
  _min?: InputMaybe<MaterialPriceMinOrderByAggregateInput>;
  _sum?: InputMaybe<MaterialPriceSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  detailCategory?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  mainCategory?: InputMaybe<SortOrder>;
  modelName?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceIssuedMonth?: InputMaybe<SortOrder>;
  priceIssuedYear?: InputMaybe<SortOrder>;
  spec?: InputMaybe<SortOrderInput>;
  subCategory?: InputMaybe<SortOrderInput>;
  subSpec?: InputMaybe<SortOrderInput>;
  unit?: InputMaybe<SortOrder>;
}

export interface MaterialPriceOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  detailCategory?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  mainCategory?: InputMaybe<SortOrder>;
  modelName?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceIssuedMonth?: InputMaybe<SortOrder>;
  priceIssuedYear?: InputMaybe<SortOrder>;
  spec?: InputMaybe<SortOrderInput>;
  subCategory?: InputMaybe<SortOrderInput>;
  subSpec?: InputMaybe<SortOrderInput>;
  unit?: InputMaybe<SortOrder>;
}

export enum MaterialPriceScalarFieldEnum {
  CreatedAt = 'createdAt',
  DetailCategory = 'detailCategory',
  Id = 'id',
  MainCategory = 'mainCategory',
  ModelName = 'modelName',
  Name = 'name',
  Price = 'price',
  PriceIssuedMonth = 'priceIssuedMonth',
  PriceIssuedYear = 'priceIssuedYear',
  Spec = 'spec',
  SubCategory = 'subCategory',
  SubSpec = 'subSpec',
  Unit = 'unit'
}

export interface MaterialPriceScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<MaterialPriceScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MaterialPriceScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MaterialPriceScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  detailCategory?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  mainCategory?: InputMaybe<StringWithAggregatesFilter>;
  modelName?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  price?: InputMaybe<StringWithAggregatesFilter>;
  priceIssuedMonth?: InputMaybe<IntWithAggregatesFilter>;
  priceIssuedYear?: InputMaybe<IntWithAggregatesFilter>;
  spec?: InputMaybe<StringNullableWithAggregatesFilter>;
  subCategory?: InputMaybe<StringNullableWithAggregatesFilter>;
  subSpec?: InputMaybe<StringNullableWithAggregatesFilter>;
  unit?: InputMaybe<StringWithAggregatesFilter>;
}

export interface MaterialPriceSumAggregateOutputType {
  __typename?: 'MaterialPriceSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  priceIssuedMonth?: Maybe<Scalars['Int']['output']>;
  priceIssuedYear?: Maybe<Scalars['Int']['output']>;
}

export interface MaterialPriceSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  priceIssuedMonth?: InputMaybe<SortOrder>;
  priceIssuedYear?: InputMaybe<SortOrder>;
}

export interface MaterialPriceUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  detailCategory?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainCategory: Scalars['String']['input'];
  modelName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['String']['input'];
  priceIssuedMonth: Scalars['Int']['input'];
  priceIssuedYear: Scalars['Int']['input'];
  spec?: InputMaybe<Scalars['String']['input']>;
  subCategory?: InputMaybe<Scalars['String']['input']>;
  subSpec?: InputMaybe<Scalars['String']['input']>;
  unit: Scalars['String']['input'];
}

export interface MaterialPriceUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detailCategory?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainCategory?: InputMaybe<StringFieldUpdateOperationsInput>;
  modelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<StringFieldUpdateOperationsInput>;
  priceIssuedMonth?: InputMaybe<IntFieldUpdateOperationsInput>;
  priceIssuedYear?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subCategory?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subSpec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MaterialPriceUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detailCategory?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainCategory?: InputMaybe<StringFieldUpdateOperationsInput>;
  modelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<StringFieldUpdateOperationsInput>;
  priceIssuedMonth?: InputMaybe<IntFieldUpdateOperationsInput>;
  priceIssuedYear?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subCategory?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subSpec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MaterialPriceUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detailCategory?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mainCategory?: InputMaybe<StringFieldUpdateOperationsInput>;
  modelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<StringFieldUpdateOperationsInput>;
  priceIssuedMonth?: InputMaybe<IntFieldUpdateOperationsInput>;
  priceIssuedYear?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subCategory?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subSpec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MaterialPriceUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  detailCategory?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mainCategory?: InputMaybe<StringFieldUpdateOperationsInput>;
  modelName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<StringFieldUpdateOperationsInput>;
  priceIssuedMonth?: InputMaybe<IntFieldUpdateOperationsInput>;
  priceIssuedYear?: InputMaybe<IntFieldUpdateOperationsInput>;
  spec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subCategory?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subSpec?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  unit?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MaterialPriceWhereInput {
  AND?: InputMaybe<Array<InputMaybe<MaterialPriceWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MaterialPriceWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MaterialPriceWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  detailCategory?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  mainCategory?: InputMaybe<StringFilter>;
  modelName?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<StringFilter>;
  priceIssuedMonth?: InputMaybe<IntFilter>;
  priceIssuedYear?: InputMaybe<IntFilter>;
  spec?: InputMaybe<StringNullableFilter>;
  subCategory?: InputMaybe<StringNullableFilter>;
  subSpec?: InputMaybe<StringNullableFilter>;
  unit?: InputMaybe<StringFilter>;
}

export interface MaterialPriceWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** Mechanical Share ratio of works(기계공사 점유율): '장비설치공사' '옥외배관공사' '기계실배관공사' '공조배관공사' '가스배관공사' '급수급탕배관공사' '오배수배관공사' '난방배관공사' '연도설치공사' '덕트설치공사' '우수처리시설공사' '위생기구설치공사' '바닥난방공사' '지열설비공사' '방음방진설치공사' '기타공사(기계)' '전력간선설비공사' '동력설비공사' '전등설비공사' '전열설비공사' '냉난방(환기)설비공사' '접지 및 피뢰 설비공사' '전기인입 및 수변전 설비공사' */
export interface MechanicalSrw {
  __typename?: 'MechanicalSRW';
  ShareRatioWork: Array<ShareRatioWork>;
  _count: MechanicalSrwCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  mainWork: MainWorkCategory;
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  work: MechanicalWorkCategory;
  workName: Scalars['String']['output'];
}


/** Mechanical Share ratio of works(기계공사 점유율): '장비설치공사' '옥외배관공사' '기계실배관공사' '공조배관공사' '가스배관공사' '급수급탕배관공사' '오배수배관공사' '난방배관공사' '연도설치공사' '덕트설치공사' '우수처리시설공사' '위생기구설치공사' '바닥난방공사' '지열설비공사' '방음방진설치공사' '기타공사(기계)' '전력간선설비공사' '동력설비공사' '전등설비공사' '전열설비공사' '냉난방(환기)설비공사' '접지 및 피뢰 설비공사' '전기인입 및 수변전 설비공사' */
export type MechanicalSrwShareRatioWorkArgs = {
  cursor?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  distinct?: InputMaybe<ShareRatioWorkScalarFieldEnum>;
  orderBy?: InputMaybe<ShareRatioWorkOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};

export interface MechanicalSrwAvgAggregateOutputType {
  __typename?: 'MechanicalSRWAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface MechanicalSrwAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface MechanicalSrwCountAggregateOutputType {
  __typename?: 'MechanicalSRWCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  mainWork: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  shareRatio: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  work: Scalars['Int']['output'];
  workName: Scalars['Int']['output'];
}

export interface MechanicalSrwCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface MechanicalSrwCountOutputType {
  __typename?: 'MechanicalSRWCountOutputType';
  ShareRatioWork: Scalars['Int']['output'];
}

export interface MechanicalSrwCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedManyWithoutMechanicalInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: MechanicalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface MechanicalSrwCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: MechanicalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface MechanicalSrwCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MechanicalSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<MechanicalSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface MechanicalSrwCreateOrConnectWithoutShareRatioWorkInput {
  create: MechanicalSrwUncheckedCreateWithoutShareRatioWorkInput;
  where: MechanicalSrwWhereUniqueInput;
}

export interface MechanicalSrwCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: MechanicalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface MechanicalSrwListRelationFilter {
  every?: InputMaybe<MechanicalSrwWhereInput>;
  none?: InputMaybe<MechanicalSrwWhereInput>;
  some?: InputMaybe<MechanicalSrwWhereInput>;
}

export interface MechanicalSrwMaxAggregateOutputType {
  __typename?: 'MechanicalSRWMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<MechanicalWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface MechanicalSrwMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface MechanicalSrwMinAggregateOutputType {
  __typename?: 'MechanicalSRWMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mainWork?: Maybe<MainWorkCategory>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  work?: Maybe<MechanicalWorkCategory>;
  workName?: Maybe<Scalars['String']['output']>;
}

export interface MechanicalSrwMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface MechanicalSrwOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface MechanicalSrwOrderByWithAggregationInput {
  _avg?: InputMaybe<MechanicalSrwAvgOrderByAggregateInput>;
  _count?: InputMaybe<MechanicalSrwCountOrderByAggregateInput>;
  _max?: InputMaybe<MechanicalSrwMaxOrderByAggregateInput>;
  _min?: InputMaybe<MechanicalSrwMinOrderByAggregateInput>;
  _sum?: InputMaybe<MechanicalSrwSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface MechanicalSrwOrderByWithRelationInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainWork?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  work?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export enum MechanicalSrwScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  MainWork = 'mainWork',
  Order = 'order',
  ShareRatio = 'shareRatio',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  Work = 'work',
  WorkName = 'workName'
}

export interface MechanicalSrwScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<MechanicalSrwScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MechanicalSrwScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MechanicalSrwScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumMechanicalWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface MechanicalSrwScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<MechanicalSrwScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MechanicalSrwScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MechanicalSrwScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryWithAggregatesFilter>;
  order?: InputMaybe<IntWithAggregatesFilter>;
  shareRatio?: InputMaybe<FloatWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  work?: InputMaybe<EnumMechanicalWorkCategoryWithAggregatesFilter>;
  workName?: InputMaybe<StringWithAggregatesFilter>;
}

export interface MechanicalSrwSumAggregateOutputType {
  __typename?: 'MechanicalSRWSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  shareRatio?: Maybe<Scalars['Float']['output']>;
}

export interface MechanicalSrwSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  shareRatio?: InputMaybe<SortOrder>;
}

export interface MechanicalSrwUncheckedCreateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedCreateNestedManyWithoutMechanicalInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: MechanicalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface MechanicalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MechanicalSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<MechanicalSrwCreateWithoutShareRatioWorkInput>>>;
}

export interface MechanicalSrwUncheckedCreateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainWork: MainWorkCategory;
  order?: InputMaybe<Scalars['Int']['input']>;
  shareRatio: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  work: MechanicalWorkCategory;
  workName: Scalars['String']['input'];
}

export interface MechanicalSrwUncheckedUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUncheckedUpdateManyWithoutMechanicalNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumMechanicalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MechanicalSrwUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumMechanicalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MechanicalSrwUncheckedUpdateManyWithoutMechanicalInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumMechanicalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MechanicalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MechanicalSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<MechanicalSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MechanicalSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MechanicalSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MechanicalSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MechanicalSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface MechanicalSrwUncheckedUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumMechanicalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MechanicalSrwUpdateInput {
  ShareRatioWork?: InputMaybe<ShareRatioWorkUpdateManyWithoutMechanicalNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumMechanicalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MechanicalSrwUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumMechanicalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MechanicalSrwUpdateManyWithWhereWithoutShareRatioWorkInput {
  data: MechanicalSrwUncheckedUpdateManyWithoutMechanicalInput;
  where: MechanicalSrwScalarWhereInput;
}

export interface MechanicalSrwUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MechanicalSrwCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<MechanicalSrwCreateWithoutShareRatioWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MechanicalSrwScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MechanicalSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MechanicalSrwUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MechanicalSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface MechanicalSrwUpdateWithWhereUniqueWithoutShareRatioWorkInput {
  data: MechanicalSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: MechanicalSrwWhereUniqueInput;
}

export interface MechanicalSrwUpdateWithoutShareRatioWorkInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  shareRatio?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  work?: InputMaybe<EnumMechanicalWorkCategoryFieldUpdateOperationsInput>;
  workName?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MechanicalSrwUpsertWithWhereUniqueWithoutShareRatioWorkInput {
  create: MechanicalSrwUncheckedCreateWithoutShareRatioWorkInput;
  update: MechanicalSrwUncheckedUpdateWithoutShareRatioWorkInput;
  where: MechanicalSrwWhereUniqueInput;
}

export interface MechanicalSrwWhereInput {
  AND?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MechanicalSrwWhereInput>>>;
  ShareRatioWork?: InputMaybe<ShareRatioWorkListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  mainWork?: InputMaybe<EnumMainWorkCategoryFilter>;
  order?: InputMaybe<IntFilter>;
  shareRatio?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  work?: InputMaybe<EnumMechanicalWorkCategoryFilter>;
  workName?: InputMaybe<StringFilter>;
}

export interface MechanicalSrwWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export enum MechanicalWorkCategory {
  AirConditioningPipingWork = 'airConditioningPipingWork',
  DrainagePipingWork = 'drainagePipingWork',
  DuctInstallationWork = 'ductInstallationWork',
  EquipmentInstallationWork = 'equipmentInstallationWork',
  FlueInstallationWork = 'flueInstallationWork',
  GasPipingWork = 'gasPipingWork',
  GeothermalFacilityInstallationWork = 'geothermalFacilityInstallationWork',
  HeatingPipingWork = 'heatingPipingWork',
  MechanicalRoomPipingWork = 'mechanicalRoomPipingWork',
  OtherWork = 'otherWork',
  OutdoorPipingWork = 'outdoorPipingWork',
  SanitaryFixtureInstallationWork = 'sanitaryFixtureInstallationWork',
  SewageTreatmentFacilityConstruction = 'sewageTreatmentFacilityConstruction',
  SoundproofingAndVibrationControlInstallationWork = 'soundproofingAndVibrationControlInstallationWork',
  UnderfloorHeatingInstallationWork = 'underfloorHeatingInstallationWork',
  WaterSupplyAndHotWaterPipingWork = 'waterSupplyAndHotWaterPipingWork'
}

/** Member */
export interface Member {
  __typename?: 'Member';
  BlueprintComment: Array<BlueprintComment>;
  EventLogs: Array<EventLogs>;
  Inquiry: Array<Inquiry>;
  InquiryAggregate: AggregateInquiry;
  KakaoSendLog: Array<KakaoSendLog>;
  _count: MemberCountOutputType;
  /** 접속 기록 */
  accessLog: Array<AccessLog>;
  /** 계정 탈퇴 */
  active: Scalars['Boolean']['output'];
  adminId?: Maybe<Scalars['Int']['output']>;
  /** Admin */
  administrator?: Maybe<Admin>;
  /** Avatar URL */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** Customer */
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']['output']>;
  /** 탈퇴 일시 */
  deactivedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Email */
  email: Scalars['String']['output'];
  /** Email verified */
  emailVerified: Scalars['Boolean']['output'];
  /** Member group */
  group: Array<MemberGroup>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Identity verification */
  identity?: Maybe<Identity>;
  /** Member name */
  name?: Maybe<Scalars['String']['output']>;
  noticeComment: Array<NoticeComment>;
  noticeTopic: Array<NoticeTopic>;
  /** Partner */
  partner?: Maybe<Partner>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  /** Phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Phone number verified */
  phoneNumberVerified: Scalars['Boolean']['output'];
  /** 멤버별 공유한 프로젝트 */
  shareProjectByMember: Array<ShareProjectByMember>;
  sharedProject: Array<SharedProject>;
  /** 멤버별 공유받은 프로젝트 */
  sharedProjectByMember: Array<SharedProjectByMember>;
  /** User member status */
  status: MemberStatus;
  /** 약관동의 이메일 수신 동의 */
  termsEmail: Scalars['Boolean']['output'];
  /** 약관동의 마케팅 정보 활용 동의 */
  termsMarketing: Scalars['Boolean']['output'];
  /** 약관동의 SMS 수신 동의 */
  termsSms: Scalars['Boolean']['output'];
  /** SuperTokens thirdParty */
  thirdPartyId: ThirdPartyId;
  /** SuperTokens thirdPartyUserId */
  thirdPartyUserId?: Maybe<Scalars['String']['output']>;
  /** Identity verified unique key */
  uniqueKey?: Maybe<Scalars['String']['output']>;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  userHash?: Maybe<Scalars['String']['output']>;
  /** SuperTokens user ID */
  userId: Scalars['String']['output'];
  /** Unique ID */
  uuid: Scalars['String']['output'];
  /** 탈퇴 사유 */
  withdrawReason?: Maybe<Scalars['String']['output']>;
}


/** Member */
export type MemberBlueprintCommentArgs = {
  cursor?: InputMaybe<BlueprintCommentWhereUniqueInput>;
  distinct?: InputMaybe<BlueprintCommentScalarFieldEnum>;
  orderBy?: InputMaybe<BlueprintCommentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlueprintCommentWhereInput>;
};


/** Member */
export type MemberEventLogsArgs = {
  cursor?: InputMaybe<EventLogsWhereUniqueInput>;
  distinct?: InputMaybe<EventLogsScalarFieldEnum>;
  orderBy?: InputMaybe<EventLogsOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventLogsWhereInput>;
};


/** Member */
export type MemberInquiryArgs = {
  cursor?: InputMaybe<InquiryWhereUniqueInput>;
  distinct?: InputMaybe<InquiryScalarFieldEnum>;
  orderBy?: InputMaybe<InquiryOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InquiryWhereInput>;
};


/** Member */
export type MemberInquiryAggregateArgs = {
  where?: InputMaybe<InquiryWhereInput>;
};


/** Member */
export type MemberKakaoSendLogArgs = {
  cursor?: InputMaybe<KakaoSendLogWhereUniqueInput>;
  distinct?: InputMaybe<KakaoSendLogScalarFieldEnum>;
  orderBy?: InputMaybe<KakaoSendLogOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KakaoSendLogWhereInput>;
};


/** Member */
export type MemberAccessLogArgs = {
  cursor?: InputMaybe<AccessLogWhereUniqueInput>;
  distinct?: InputMaybe<AccessLogScalarFieldEnum>;
  orderBy?: InputMaybe<AccessLogOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccessLogWhereInput>;
};


/** Member */
export type MemberNoticeCommentArgs = {
  cursor?: InputMaybe<NoticeCommentWhereUniqueInput>;
  distinct?: InputMaybe<NoticeCommentScalarFieldEnum>;
  orderBy?: InputMaybe<NoticeCommentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeCommentWhereInput>;
};


/** Member */
export type MemberNoticeTopicArgs = {
  cursor?: InputMaybe<NoticeTopicWhereUniqueInput>;
  distinct?: InputMaybe<NoticeTopicScalarFieldEnum>;
  orderBy?: InputMaybe<NoticeTopicOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeTopicWhereInput>;
};


/** Member */
export type MemberShareProjectByMemberArgs = {
  cursor?: InputMaybe<ShareProjectByMemberWhereUniqueInput>;
  distinct?: InputMaybe<ShareProjectByMemberScalarFieldEnum>;
  orderBy?: InputMaybe<ShareProjectByMemberOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareProjectByMemberWhereInput>;
};


/** Member */
export type MemberSharedProjectArgs = {
  cursor?: InputMaybe<SharedProjectWhereUniqueInput>;
  distinct?: InputMaybe<SharedProjectScalarFieldEnum>;
  orderBy?: InputMaybe<SharedProjectOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectWhereInput>;
};


/** Member */
export type MemberSharedProjectByMemberArgs = {
  cursor?: InputMaybe<SharedProjectByMemberWhereUniqueInput>;
  distinct?: InputMaybe<SharedProjectByMemberScalarFieldEnum>;
  orderBy?: InputMaybe<SharedProjectByMemberOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectByMemberWhereInput>;
};

export interface MemberAvgAggregateOutputType {
  __typename?: 'MemberAvgAggregateOutputType';
  adminId?: Maybe<Scalars['Float']['output']>;
  customerId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  partnerId?: Maybe<Scalars['Float']['output']>;
}

export interface MemberAvgOrderByAggregateInput {
  adminId?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface MemberCountAggregateOutputType {
  __typename?: 'MemberCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  active: Scalars['Int']['output'];
  adminId: Scalars['Int']['output'];
  avatarUrl: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  deactivedAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  emailVerified: Scalars['Int']['output'];
  group: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  partnerId: Scalars['Int']['output'];
  phoneNumber: Scalars['Int']['output'];
  phoneNumberVerified: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  termsEmail: Scalars['Int']['output'];
  termsMarketing: Scalars['Int']['output'];
  termsSms: Scalars['Int']['output'];
  thirdPartyId: Scalars['Int']['output'];
  thirdPartyUserId: Scalars['Int']['output'];
  uniqueKey: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  withdrawReason: Scalars['Int']['output'];
}

export interface MemberCountOrderByAggregateInput {
  active?: InputMaybe<SortOrder>;
  adminId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  deactivedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  group?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  phoneNumberVerified?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  termsEmail?: InputMaybe<SortOrder>;
  termsMarketing?: InputMaybe<SortOrder>;
  termsSms?: InputMaybe<SortOrder>;
  thirdPartyId?: InputMaybe<SortOrder>;
  thirdPartyUserId?: InputMaybe<SortOrder>;
  uniqueKey?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  withdrawReason?: InputMaybe<SortOrder>;
}

export interface MemberCountOutputType {
  __typename?: 'MemberCountOutputType';
  BlueprintComment: Scalars['Int']['output'];
  EventLogs: Scalars['Int']['output'];
  Inquiry: Scalars['Int']['output'];
  KakaoSendLog: Scalars['Int']['output'];
  accessLog: Scalars['Int']['output'];
  noticeComment: Scalars['Int']['output'];
  noticeTopic: Scalars['Int']['output'];
  shareProjectByMember: Scalars['Int']['output'];
  sharedProject: Scalars['Int']['output'];
  sharedProjectByMember: Scalars['Int']['output'];
}

export interface MemberCreateInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateManyAdministratorInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateManyAdministratorInputEnvelope {
  data: MemberCreateManyAdministratorInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MemberCreateManyCustomerInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateManyCustomerInputEnvelope {
  data: MemberCreateManyCustomerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MemberCreateManyIdentityInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateManyIdentityInputEnvelope {
  data: MemberCreateManyIdentityInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MemberCreateManyInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateManyPartnerInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateManyPartnerInputEnvelope {
  data: MemberCreateManyPartnerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MemberCreateNestedManyWithoutAdministratorInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutAdministratorInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutAdministratorInput>>>;
  createMany?: InputMaybe<MemberCreateManyAdministratorInputEnvelope>;
}

export interface MemberCreateNestedManyWithoutCustomerInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<MemberCreateManyCustomerInputEnvelope>;
}

export interface MemberCreateNestedManyWithoutIdentityInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutIdentityInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutIdentityInput>>>;
  createMany?: InputMaybe<MemberCreateManyIdentityInputEnvelope>;
}

export interface MemberCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<MemberCreateManyPartnerInputEnvelope>;
}

export interface MemberCreateNestedOneWithoutAccessLogInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutAccessLogInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutAccessLogInput>;
}

export interface MemberCreateNestedOneWithoutBlueprintCommentInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutBlueprintCommentInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutBlueprintCommentInput>;
}

export interface MemberCreateNestedOneWithoutEventLogsInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutEventLogsInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutEventLogsInput>;
}

export interface MemberCreateNestedOneWithoutInquiryInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutInquiryInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutInquiryInput>;
}

export interface MemberCreateNestedOneWithoutKakaoSendLogInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutKakaoSendLogInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutKakaoSendLogInput>;
}

export interface MemberCreateNestedOneWithoutNoticeCommentInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutNoticeCommentInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutNoticeCommentInput>;
}

export interface MemberCreateNestedOneWithoutNoticeTopicInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutNoticeTopicInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutNoticeTopicInput>;
}

export interface MemberCreateNestedOneWithoutShareProjectByMemberInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutShareProjectByMemberInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutShareProjectByMemberInput>;
}

export interface MemberCreateNestedOneWithoutSharedProjectByMemberInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutSharedProjectByMemberInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutSharedProjectByMemberInput>;
}

export interface MemberCreateNestedOneWithoutSharedProjectInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutSharedProjectInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutSharedProjectInput>;
}

export interface MemberCreateOrConnectWithoutAccessLogInput {
  create: MemberUncheckedCreateWithoutAccessLogInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutAdministratorInput {
  create: MemberUncheckedCreateWithoutAdministratorInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutBlueprintCommentInput {
  create: MemberUncheckedCreateWithoutBlueprintCommentInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutCustomerInput {
  create: MemberUncheckedCreateWithoutCustomerInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutEventLogsInput {
  create: MemberUncheckedCreateWithoutEventLogsInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutIdentityInput {
  create: MemberUncheckedCreateWithoutIdentityInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutInquiryInput {
  create: MemberUncheckedCreateWithoutInquiryInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutKakaoSendLogInput {
  create: MemberUncheckedCreateWithoutKakaoSendLogInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutNoticeCommentInput {
  create: MemberUncheckedCreateWithoutNoticeCommentInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutNoticeTopicInput {
  create: MemberUncheckedCreateWithoutNoticeTopicInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutPartnerInput {
  create: MemberUncheckedCreateWithoutPartnerInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutShareProjectByMemberInput {
  create: MemberUncheckedCreateWithoutShareProjectByMemberInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutSharedProjectByMemberInput {
  create: MemberUncheckedCreateWithoutSharedProjectByMemberInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateOrConnectWithoutSharedProjectInput {
  create: MemberUncheckedCreateWithoutSharedProjectInput;
  where: MemberWhereUniqueInput;
}

export interface MemberCreateWithoutAccessLogInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutAdministratorInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutBlueprintCommentInput {
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutCustomerInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutEventLogsInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutIdentityInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutInquiryInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutKakaoSendLogInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutNoticeCommentInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutNoticeTopicInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutPartnerInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutShareProjectByMemberInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutSharedProjectByMemberInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreateWithoutSharedProjectInput {
  BlueprintComment?: InputMaybe<BlueprintCommentCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  administrator?: InputMaybe<AdminCreateNestedOneWithoutMemberInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutMemberInput>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityCreateNestedOneWithoutMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicCreateNestedManyWithoutAuthorInput>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutMemberInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberCreategroupInput {
  set: MemberGroup;
}

/** 회원 이벤트: 이벤트  */
export interface MemberEvent {
  __typename?: 'MemberEvent';
  _count: MemberEventCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  eventKey: Scalars['String']['output'];
  eventLogs: Array<EventLogs>;
  id: Scalars['Int']['output'];
  previewUrl: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
}


/** 회원 이벤트: 이벤트  */
export type MemberEventEventLogsArgs = {
  cursor?: InputMaybe<EventLogsWhereUniqueInput>;
  distinct?: InputMaybe<EventLogsScalarFieldEnum>;
  orderBy?: InputMaybe<EventLogsOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventLogsWhereInput>;
};

export interface MemberEventAvgAggregateOutputType {
  __typename?: 'MemberEventAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
}

export interface MemberEventAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface MemberEventCountAggregateOutputType {
  __typename?: 'MemberEventCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  eventKey: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  previewUrl: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface MemberEventCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  eventKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  previewUrl?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface MemberEventCountOutputType {
  __typename?: 'MemberEventCountOutputType';
  eventLogs: Scalars['Int']['output'];
}

export interface MemberEventCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventKey: Scalars['String']['input'];
  eventLogs?: InputMaybe<EventLogsCreateNestedManyWithoutMemberEventInput>;
  previewUrl: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberEventCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventKey: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  previewUrl: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberEventCreateNestedOneWithoutEventLogsInput {
  connect?: InputMaybe<MemberEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberEventCreateOrConnectWithoutEventLogsInput>;
  create?: InputMaybe<MemberEventUncheckedCreateWithoutEventLogsInput>;
}

export interface MemberEventCreateOrConnectWithoutEventLogsInput {
  create: MemberEventUncheckedCreateWithoutEventLogsInput;
  where: MemberEventWhereUniqueInput;
}

export interface MemberEventCreateWithoutEventLogsInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventKey: Scalars['String']['input'];
  previewUrl: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberEventMaxAggregateOutputType {
  __typename?: 'MemberEventMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  eventKey?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface MemberEventMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  eventKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  previewUrl?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface MemberEventMinAggregateOutputType {
  __typename?: 'MemberEventMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  eventKey?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface MemberEventMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  eventKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  previewUrl?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface MemberEventOrderByWithAggregationInput {
  _avg?: InputMaybe<MemberEventAvgOrderByAggregateInput>;
  _count?: InputMaybe<MemberEventCountOrderByAggregateInput>;
  _max?: InputMaybe<MemberEventMaxOrderByAggregateInput>;
  _min?: InputMaybe<MemberEventMinOrderByAggregateInput>;
  _sum?: InputMaybe<MemberEventSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  eventKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  previewUrl?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface MemberEventOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  eventKey?: InputMaybe<SortOrder>;
  eventLogs?: InputMaybe<EventLogsOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  previewUrl?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface MemberEventRelationFilter {
  is?: InputMaybe<MemberEventWhereInput>;
  isNot?: InputMaybe<MemberEventWhereInput>;
}

export enum MemberEventScalarFieldEnum {
  CreatedAt = 'createdAt',
  EventKey = 'eventKey',
  Id = 'id',
  PreviewUrl = 'previewUrl',
  Uuid = 'uuid'
}

export interface MemberEventScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<MemberEventScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MemberEventScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MemberEventScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  eventKey?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  previewUrl?: InputMaybe<StringWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface MemberEventSumAggregateOutputType {
  __typename?: 'MemberEventSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
}

export interface MemberEventSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface MemberEventUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventKey: Scalars['String']['input'];
  eventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberEventInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  previewUrl: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberEventUncheckedCreateWithoutEventLogsInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  eventKey: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  previewUrl: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberEventUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberEventNestedInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  previewUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MemberEventUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  previewUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MemberEventUncheckedUpdateWithoutEventLogsInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  previewUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MemberEventUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  eventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberEventNestedInput>;
  previewUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MemberEventUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  previewUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MemberEventUpdateOneRequiredWithoutEventLogsNestedInput {
  connect?: InputMaybe<MemberEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberEventCreateOrConnectWithoutEventLogsInput>;
  create?: InputMaybe<MemberEventUncheckedCreateWithoutEventLogsInput>;
  update?: InputMaybe<MemberEventUncheckedUpdateWithoutEventLogsInput>;
  upsert?: InputMaybe<MemberEventUpsertWithoutEventLogsInput>;
}

export interface MemberEventUpdateWithoutEventLogsInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  previewUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface MemberEventUpsertWithoutEventLogsInput {
  create: MemberEventUncheckedCreateWithoutEventLogsInput;
  update: MemberEventUncheckedUpdateWithoutEventLogsInput;
}

export interface MemberEventWhereInput {
  AND?: InputMaybe<Array<InputMaybe<MemberEventWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MemberEventWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MemberEventWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventKey?: InputMaybe<StringFilter>;
  eventLogs?: InputMaybe<EventLogsListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  previewUrl?: InputMaybe<StringFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface MemberEventWhereUniqueInput {
  eventKey?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export enum MemberGroup {
  Administrator = 'ADMINISTRATOR',
  Customer = 'CUSTOMER',
  Partner = 'PARTNER'
}

export interface MemberListRelationFilter {
  every?: InputMaybe<MemberWhereInput>;
  none?: InputMaybe<MemberWhereInput>;
  some?: InputMaybe<MemberWhereInput>;
}

export interface MemberMaxAggregateOutputType {
  __typename?: 'MemberMaxAggregateOutputType';
  active?: Maybe<Scalars['Boolean']['output']>;
  adminId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  deactivedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberVerified?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<MemberStatus>;
  termsEmail?: Maybe<Scalars['Boolean']['output']>;
  termsMarketing?: Maybe<Scalars['Boolean']['output']>;
  termsSms?: Maybe<Scalars['Boolean']['output']>;
  thirdPartyId?: Maybe<ThirdPartyId>;
  thirdPartyUserId?: Maybe<Scalars['String']['output']>;
  uniqueKey?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  withdrawReason?: Maybe<Scalars['String']['output']>;
}

export interface MemberMaxOrderByAggregateInput {
  active?: InputMaybe<SortOrder>;
  adminId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  deactivedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  phoneNumberVerified?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  termsEmail?: InputMaybe<SortOrder>;
  termsMarketing?: InputMaybe<SortOrder>;
  termsSms?: InputMaybe<SortOrder>;
  thirdPartyId?: InputMaybe<SortOrder>;
  thirdPartyUserId?: InputMaybe<SortOrder>;
  uniqueKey?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  withdrawReason?: InputMaybe<SortOrder>;
}

export interface MemberMinAggregateOutputType {
  __typename?: 'MemberMinAggregateOutputType';
  active?: Maybe<Scalars['Boolean']['output']>;
  adminId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  deactivedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberVerified?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<MemberStatus>;
  termsEmail?: Maybe<Scalars['Boolean']['output']>;
  termsMarketing?: Maybe<Scalars['Boolean']['output']>;
  termsSms?: Maybe<Scalars['Boolean']['output']>;
  thirdPartyId?: Maybe<ThirdPartyId>;
  thirdPartyUserId?: Maybe<Scalars['String']['output']>;
  uniqueKey?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  withdrawReason?: Maybe<Scalars['String']['output']>;
}

export interface MemberMinOrderByAggregateInput {
  active?: InputMaybe<SortOrder>;
  adminId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  deactivedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  phoneNumberVerified?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  termsEmail?: InputMaybe<SortOrder>;
  termsMarketing?: InputMaybe<SortOrder>;
  termsSms?: InputMaybe<SortOrder>;
  thirdPartyId?: InputMaybe<SortOrder>;
  thirdPartyUserId?: InputMaybe<SortOrder>;
  uniqueKey?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  withdrawReason?: InputMaybe<SortOrder>;
}

export interface MemberOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface MemberOrderByWithAggregationInput {
  _avg?: InputMaybe<MemberAvgOrderByAggregateInput>;
  _count?: InputMaybe<MemberCountOrderByAggregateInput>;
  _max?: InputMaybe<MemberMaxOrderByAggregateInput>;
  _min?: InputMaybe<MemberMinOrderByAggregateInput>;
  _sum?: InputMaybe<MemberSumOrderByAggregateInput>;
  active?: InputMaybe<SortOrder>;
  adminId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrderInput>;
  deactivedAt?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  group?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrderInput>;
  phoneNumber?: InputMaybe<SortOrderInput>;
  phoneNumberVerified?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  termsEmail?: InputMaybe<SortOrder>;
  termsMarketing?: InputMaybe<SortOrder>;
  termsSms?: InputMaybe<SortOrder>;
  thirdPartyId?: InputMaybe<SortOrder>;
  thirdPartyUserId?: InputMaybe<SortOrderInput>;
  uniqueKey?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  withdrawReason?: InputMaybe<SortOrderInput>;
}

export interface MemberOrderByWithRelationInput {
  BlueprintComment?: InputMaybe<BlueprintCommentOrderByRelationAggregateInput>;
  EventLogs?: InputMaybe<EventLogsOrderByRelationAggregateInput>;
  Inquiry?: InputMaybe<InquiryOrderByRelationAggregateInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogOrderByRelationAggregateInput>;
  accessLog?: InputMaybe<AccessLogOrderByRelationAggregateInput>;
  active?: InputMaybe<SortOrder>;
  adminId?: InputMaybe<SortOrderInput>;
  administrator?: InputMaybe<AdminOrderByWithRelationInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrderInput>;
  deactivedAt?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  group?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identity?: InputMaybe<IdentityOrderByWithRelationInput>;
  name?: InputMaybe<SortOrderInput>;
  noticeComment?: InputMaybe<NoticeCommentOrderByRelationAggregateInput>;
  noticeTopic?: InputMaybe<NoticeTopicOrderByRelationAggregateInput>;
  partner?: InputMaybe<PartnerOrderByWithRelationInput>;
  partnerId?: InputMaybe<SortOrderInput>;
  phoneNumber?: InputMaybe<SortOrderInput>;
  phoneNumberVerified?: InputMaybe<SortOrder>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberOrderByRelationAggregateInput>;
  sharedProject?: InputMaybe<SharedProjectOrderByRelationAggregateInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  termsEmail?: InputMaybe<SortOrder>;
  termsMarketing?: InputMaybe<SortOrder>;
  termsSms?: InputMaybe<SortOrder>;
  thirdPartyId?: InputMaybe<SortOrder>;
  thirdPartyUserId?: InputMaybe<SortOrderInput>;
  uniqueKey?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  withdrawReason?: InputMaybe<SortOrderInput>;
}

export interface MemberRelationFilter {
  is?: InputMaybe<MemberWhereInput>;
  isNot?: InputMaybe<MemberWhereInput>;
}

export enum MemberScalarFieldEnum {
  Active = 'active',
  AdminId = 'adminId',
  AvatarUrl = 'avatarUrl',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  DeactivedAt = 'deactivedAt',
  Email = 'email',
  EmailVerified = 'emailVerified',
  Group = 'group',
  Id = 'id',
  Name = 'name',
  PartnerId = 'partnerId',
  PhoneNumber = 'phoneNumber',
  PhoneNumberVerified = 'phoneNumberVerified',
  Status = 'status',
  TermsEmail = 'termsEmail',
  TermsMarketing = 'termsMarketing',
  TermsSms = 'termsSms',
  ThirdPartyId = 'thirdPartyId',
  ThirdPartyUserId = 'thirdPartyUserId',
  UniqueKey = 'uniqueKey',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  Uuid = 'uuid',
  WithdrawReason = 'withdrawReason'
}

export interface MemberScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<MemberScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MemberScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MemberScalarWhereInput>>>;
  active?: InputMaybe<BoolFilter>;
  adminId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<IntNullableFilter>;
  deactivedAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  emailVerified?: InputMaybe<BoolFilter>;
  group?: InputMaybe<EnumMemberGroupNullableListFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<IntNullableFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  phoneNumberVerified?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumMemberStatusFilter>;
  termsEmail?: InputMaybe<BoolFilter>;
  termsMarketing?: InputMaybe<BoolFilter>;
  termsSms?: InputMaybe<BoolFilter>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFilter>;
  thirdPartyUserId?: InputMaybe<StringNullableFilter>;
  uniqueKey?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  uuid?: InputMaybe<StringFilter>;
  withdrawReason?: InputMaybe<StringNullableFilter>;
}

export interface MemberScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<MemberScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MemberScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MemberScalarWhereWithAggregatesInput>>>;
  active?: InputMaybe<BoolWithAggregatesFilter>;
  adminId?: InputMaybe<IntNullableWithAggregatesFilter>;
  avatarUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  customerId?: InputMaybe<IntNullableWithAggregatesFilter>;
  deactivedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  emailVerified?: InputMaybe<BoolWithAggregatesFilter>;
  group?: InputMaybe<EnumMemberGroupNullableListFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  partnerId?: InputMaybe<IntNullableWithAggregatesFilter>;
  phoneNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  phoneNumberVerified?: InputMaybe<BoolWithAggregatesFilter>;
  status?: InputMaybe<EnumMemberStatusWithAggregatesFilter>;
  termsEmail?: InputMaybe<BoolWithAggregatesFilter>;
  termsMarketing?: InputMaybe<BoolWithAggregatesFilter>;
  termsSms?: InputMaybe<BoolWithAggregatesFilter>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdWithAggregatesFilter>;
  thirdPartyUserId?: InputMaybe<StringNullableWithAggregatesFilter>;
  uniqueKey?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  withdrawReason?: InputMaybe<StringNullableWithAggregatesFilter>;
}

export enum MemberStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Removed = 'REMOVED'
}

export interface MemberSumAggregateOutputType {
  __typename?: 'MemberSumAggregateOutputType';
  adminId?: Maybe<Scalars['Int']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
}

export interface MemberSumOrderByAggregateInput {
  adminId?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface MemberThirdPartyIdThirdPartyUserIdCompoundUniqueInput {
  thirdPartyId: ThirdPartyId;
  thirdPartyUserId: Scalars['String']['input'];
}

export interface MemberUncheckedCreateInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateNestedManyWithoutAdministratorInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutAdministratorInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutAdministratorInput>>>;
  createMany?: InputMaybe<MemberCreateManyAdministratorInputEnvelope>;
}

export interface MemberUncheckedCreateNestedManyWithoutCustomerInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<MemberCreateManyCustomerInputEnvelope>;
}

export interface MemberUncheckedCreateNestedManyWithoutIdentityInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutIdentityInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutIdentityInput>>>;
  createMany?: InputMaybe<MemberCreateManyIdentityInputEnvelope>;
}

export interface MemberUncheckedCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<MemberCreateManyPartnerInputEnvelope>;
}

export interface MemberUncheckedCreateWithoutAccessLogInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutAdministratorInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutBlueprintCommentInput {
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutCustomerInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutEventLogsInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutIdentityInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutInquiryInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutKakaoSendLogInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutNoticeCommentInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutNoticeTopicInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutPartnerInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutShareProjectByMemberInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutSharedProjectByMemberInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedCreateWithoutSharedProjectInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedCreateNestedManyWithoutMemberInput>;
  Inquiry?: InputMaybe<InquiryUncheckedCreateNestedManyWithoutMemberInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutMemberInput>;
  accessLog?: InputMaybe<AccessLogUncheckedCreateNestedManyWithoutUserInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  adminId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deactivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput>;
  status?: InputMaybe<MemberStatus>;
  termsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  termsMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  termsSms?: InputMaybe<Scalars['Boolean']['input']>;
  thirdPartyId?: InputMaybe<ThirdPartyId>;
  thirdPartyUserId?: InputMaybe<Scalars['String']['input']>;
  uniqueKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
  withdrawReason?: InputMaybe<Scalars['String']['input']>;
}

export interface MemberUncheckedUpdateInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateManyInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateManyWithoutAdministratorNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutAdministratorInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutAdministratorInput>>>;
  createMany?: InputMaybe<MemberCreateManyAdministratorInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MemberUpdateWithWhereUniqueWithoutAdministratorInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MemberUpdateManyWithWhereWithoutAdministratorInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MemberUpsertWithWhereUniqueWithoutAdministratorInput>>>;
}

export interface MemberUncheckedUpdateManyWithoutCustomerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<MemberCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MemberUpdateWithWhereUniqueWithoutCustomerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MemberUpdateManyWithWhereWithoutCustomerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MemberUpsertWithWhereUniqueWithoutCustomerInput>>>;
}

export interface MemberUncheckedUpdateManyWithoutIdentityNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutIdentityInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutIdentityInput>>>;
  createMany?: InputMaybe<MemberCreateManyIdentityInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MemberUpdateWithWhereUniqueWithoutIdentityInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MemberUpdateManyWithWhereWithoutIdentityInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MemberUpsertWithWhereUniqueWithoutIdentityInput>>>;
}

export interface MemberUncheckedUpdateManyWithoutMemberInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<MemberCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MemberUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MemberUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MemberUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface MemberUncheckedUpdateWithoutAccessLogInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutAdministratorInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutBlueprintCommentInput {
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutCustomerInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutEventLogsInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutIdentityInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutInquiryInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutKakaoSendLogInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutNoticeCommentInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutNoticeTopicInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutPartnerInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutShareProjectByMemberInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutSharedProjectByMemberInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUncheckedUpdateWithoutSharedProjectInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUncheckedUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUncheckedUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUncheckedUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  adminId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uniqueKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateManyMutationInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateManyWithWhereWithoutAdministratorInput {
  data: MemberUncheckedUpdateManyWithoutMemberInput;
  where: MemberScalarWhereInput;
}

export interface MemberUpdateManyWithWhereWithoutCustomerInput {
  data: MemberUncheckedUpdateManyWithoutMemberInput;
  where: MemberScalarWhereInput;
}

export interface MemberUpdateManyWithWhereWithoutIdentityInput {
  data: MemberUncheckedUpdateManyWithoutMemberInput;
  where: MemberScalarWhereInput;
}

export interface MemberUpdateManyWithWhereWithoutPartnerInput {
  data: MemberUncheckedUpdateManyWithoutMemberInput;
  where: MemberScalarWhereInput;
}

export interface MemberUpdateManyWithoutAdministratorNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutAdministratorInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutAdministratorInput>>>;
  createMany?: InputMaybe<MemberCreateManyAdministratorInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MemberUpdateWithWhereUniqueWithoutAdministratorInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MemberUpdateManyWithWhereWithoutAdministratorInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MemberUpsertWithWhereUniqueWithoutAdministratorInput>>>;
}

export interface MemberUpdateManyWithoutCustomerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<MemberCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MemberUpdateWithWhereUniqueWithoutCustomerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MemberUpdateManyWithWhereWithoutCustomerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MemberUpsertWithWhereUniqueWithoutCustomerInput>>>;
}

export interface MemberUpdateManyWithoutIdentityNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutIdentityInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutIdentityInput>>>;
  createMany?: InputMaybe<MemberCreateManyIdentityInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MemberUpdateWithWhereUniqueWithoutIdentityInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MemberUpdateManyWithWhereWithoutIdentityInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MemberUpsertWithWhereUniqueWithoutIdentityInput>>>;
}

export interface MemberUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MemberCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<MemberCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<MemberCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MemberUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MemberUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MemberUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface MemberUpdateOneRequiredWithoutBlueprintCommentNestedInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutBlueprintCommentInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutBlueprintCommentInput>;
  update?: InputMaybe<MemberUncheckedUpdateWithoutBlueprintCommentInput>;
  upsert?: InputMaybe<MemberUpsertWithoutBlueprintCommentInput>;
}

export interface MemberUpdateOneRequiredWithoutEventLogsNestedInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutEventLogsInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutEventLogsInput>;
  update?: InputMaybe<MemberUncheckedUpdateWithoutEventLogsInput>;
  upsert?: InputMaybe<MemberUpsertWithoutEventLogsInput>;
}

export interface MemberUpdateOneRequiredWithoutInquiryNestedInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutInquiryInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutInquiryInput>;
  update?: InputMaybe<MemberUncheckedUpdateWithoutInquiryInput>;
  upsert?: InputMaybe<MemberUpsertWithoutInquiryInput>;
}

export interface MemberUpdateOneRequiredWithoutKakaoSendLogNestedInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutKakaoSendLogInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutKakaoSendLogInput>;
  update?: InputMaybe<MemberUncheckedUpdateWithoutKakaoSendLogInput>;
  upsert?: InputMaybe<MemberUpsertWithoutKakaoSendLogInput>;
}

export interface MemberUpdateOneRequiredWithoutNoticeCommentNestedInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutNoticeCommentInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutNoticeCommentInput>;
  update?: InputMaybe<MemberUncheckedUpdateWithoutNoticeCommentInput>;
  upsert?: InputMaybe<MemberUpsertWithoutNoticeCommentInput>;
}

export interface MemberUpdateOneRequiredWithoutNoticeTopicNestedInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutNoticeTopicInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutNoticeTopicInput>;
  update?: InputMaybe<MemberUncheckedUpdateWithoutNoticeTopicInput>;
  upsert?: InputMaybe<MemberUpsertWithoutNoticeTopicInput>;
}

export interface MemberUpdateOneRequiredWithoutShareProjectByMemberNestedInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutShareProjectByMemberInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutShareProjectByMemberInput>;
  update?: InputMaybe<MemberUncheckedUpdateWithoutShareProjectByMemberInput>;
  upsert?: InputMaybe<MemberUpsertWithoutShareProjectByMemberInput>;
}

export interface MemberUpdateOneWithoutAccessLogNestedInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutAccessLogInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutAccessLogInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<MemberUncheckedUpdateWithoutAccessLogInput>;
  upsert?: InputMaybe<MemberUpsertWithoutAccessLogInput>;
}

export interface MemberUpdateOneWithoutSharedProjectByMemberNestedInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutSharedProjectByMemberInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutSharedProjectByMemberInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<MemberUncheckedUpdateWithoutSharedProjectByMemberInput>;
  upsert?: InputMaybe<MemberUpsertWithoutSharedProjectByMemberInput>;
}

export interface MemberUpdateOneWithoutSharedProjectNestedInput {
  connect?: InputMaybe<MemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MemberCreateOrConnectWithoutSharedProjectInput>;
  create?: InputMaybe<MemberUncheckedCreateWithoutSharedProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<MemberUncheckedUpdateWithoutSharedProjectInput>;
  upsert?: InputMaybe<MemberUpsertWithoutSharedProjectInput>;
}

export interface MemberUpdateWithWhereUniqueWithoutAdministratorInput {
  data: MemberUncheckedUpdateWithoutAdministratorInput;
  where: MemberWhereUniqueInput;
}

export interface MemberUpdateWithWhereUniqueWithoutCustomerInput {
  data: MemberUncheckedUpdateWithoutCustomerInput;
  where: MemberWhereUniqueInput;
}

export interface MemberUpdateWithWhereUniqueWithoutIdentityInput {
  data: MemberUncheckedUpdateWithoutIdentityInput;
  where: MemberWhereUniqueInput;
}

export interface MemberUpdateWithWhereUniqueWithoutPartnerInput {
  data: MemberUncheckedUpdateWithoutPartnerInput;
  where: MemberWhereUniqueInput;
}

export interface MemberUpdateWithoutAccessLogInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutAdministratorInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutBlueprintCommentInput {
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutCustomerInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutEventLogsInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutIdentityInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutInquiryInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutKakaoSendLogInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutNoticeCommentInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutNoticeTopicInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutPartnerInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutShareProjectByMemberInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutSharedProjectByMemberInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdateWithoutSharedProjectInput {
  BlueprintComment?: InputMaybe<BlueprintCommentUpdateManyWithoutAuthorNestedInput>;
  EventLogs?: InputMaybe<EventLogsUpdateManyWithoutMemberNestedInput>;
  Inquiry?: InputMaybe<InquiryUpdateManyWithoutMemberNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutMemberNestedInput>;
  accessLog?: InputMaybe<AccessLogUpdateManyWithoutUserNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  administrator?: InputMaybe<AdminUpdateOneWithoutMemberNestedInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutMemberNestedInput>;
  deactivedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  group?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  identity?: InputMaybe<IdentityUpdateOneWithoutMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  noticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutAuthorNestedInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateManyWithoutAuthorNestedInput>;
  partner?: InputMaybe<PartnerUpdateOneWithoutMemberNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneNumberVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberUpdateManyWithoutMemberNestedInput>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberUpdateManyWithoutMemberNestedInput>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  termsEmail?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsMarketing?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsSms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFieldUpdateOperationsInput>;
  thirdPartyUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface MemberUpdategroupInput {
  push?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
  set?: InputMaybe<Array<InputMaybe<MemberGroup>>>;
}

export interface MemberUpsertWithWhereUniqueWithoutAdministratorInput {
  create: MemberUncheckedCreateWithoutAdministratorInput;
  update: MemberUncheckedUpdateWithoutAdministratorInput;
  where: MemberWhereUniqueInput;
}

export interface MemberUpsertWithWhereUniqueWithoutCustomerInput {
  create: MemberUncheckedCreateWithoutCustomerInput;
  update: MemberUncheckedUpdateWithoutCustomerInput;
  where: MemberWhereUniqueInput;
}

export interface MemberUpsertWithWhereUniqueWithoutIdentityInput {
  create: MemberUncheckedCreateWithoutIdentityInput;
  update: MemberUncheckedUpdateWithoutIdentityInput;
  where: MemberWhereUniqueInput;
}

export interface MemberUpsertWithWhereUniqueWithoutPartnerInput {
  create: MemberUncheckedCreateWithoutPartnerInput;
  update: MemberUncheckedUpdateWithoutPartnerInput;
  where: MemberWhereUniqueInput;
}

export interface MemberUpsertWithoutAccessLogInput {
  create: MemberUncheckedCreateWithoutAccessLogInput;
  update: MemberUncheckedUpdateWithoutAccessLogInput;
}

export interface MemberUpsertWithoutBlueprintCommentInput {
  create: MemberUncheckedCreateWithoutBlueprintCommentInput;
  update: MemberUncheckedUpdateWithoutBlueprintCommentInput;
}

export interface MemberUpsertWithoutEventLogsInput {
  create: MemberUncheckedCreateWithoutEventLogsInput;
  update: MemberUncheckedUpdateWithoutEventLogsInput;
}

export interface MemberUpsertWithoutInquiryInput {
  create: MemberUncheckedCreateWithoutInquiryInput;
  update: MemberUncheckedUpdateWithoutInquiryInput;
}

export interface MemberUpsertWithoutKakaoSendLogInput {
  create: MemberUncheckedCreateWithoutKakaoSendLogInput;
  update: MemberUncheckedUpdateWithoutKakaoSendLogInput;
}

export interface MemberUpsertWithoutNoticeCommentInput {
  create: MemberUncheckedCreateWithoutNoticeCommentInput;
  update: MemberUncheckedUpdateWithoutNoticeCommentInput;
}

export interface MemberUpsertWithoutNoticeTopicInput {
  create: MemberUncheckedCreateWithoutNoticeTopicInput;
  update: MemberUncheckedUpdateWithoutNoticeTopicInput;
}

export interface MemberUpsertWithoutShareProjectByMemberInput {
  create: MemberUncheckedCreateWithoutShareProjectByMemberInput;
  update: MemberUncheckedUpdateWithoutShareProjectByMemberInput;
}

export interface MemberUpsertWithoutSharedProjectByMemberInput {
  create: MemberUncheckedCreateWithoutSharedProjectByMemberInput;
  update: MemberUncheckedUpdateWithoutSharedProjectByMemberInput;
}

export interface MemberUpsertWithoutSharedProjectInput {
  create: MemberUncheckedCreateWithoutSharedProjectInput;
  update: MemberUncheckedUpdateWithoutSharedProjectInput;
}

export interface MemberWhereInput {
  AND?: InputMaybe<Array<InputMaybe<MemberWhereInput>>>;
  BlueprintComment?: InputMaybe<BlueprintCommentListRelationFilter>;
  EventLogs?: InputMaybe<EventLogsListRelationFilter>;
  Inquiry?: InputMaybe<InquiryListRelationFilter>;
  KakaoSendLog?: InputMaybe<KakaoSendLogListRelationFilter>;
  NOT?: InputMaybe<Array<InputMaybe<MemberWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MemberWhereInput>>>;
  accessLog?: InputMaybe<AccessLogListRelationFilter>;
  active?: InputMaybe<BoolFilter>;
  adminId?: InputMaybe<IntNullableFilter>;
  administrator?: InputMaybe<AdminWhereInput>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<IntNullableFilter>;
  deactivedAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  emailVerified?: InputMaybe<BoolFilter>;
  group?: InputMaybe<EnumMemberGroupNullableListFilter>;
  id?: InputMaybe<IntFilter>;
  identity?: InputMaybe<IdentityWhereInput>;
  name?: InputMaybe<StringNullableFilter>;
  noticeComment?: InputMaybe<NoticeCommentListRelationFilter>;
  noticeTopic?: InputMaybe<NoticeTopicListRelationFilter>;
  partner?: InputMaybe<PartnerWhereInput>;
  partnerId?: InputMaybe<IntNullableFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  phoneNumberVerified?: InputMaybe<BoolFilter>;
  shareProjectByMember?: InputMaybe<ShareProjectByMemberListRelationFilter>;
  sharedProject?: InputMaybe<SharedProjectListRelationFilter>;
  sharedProjectByMember?: InputMaybe<SharedProjectByMemberListRelationFilter>;
  status?: InputMaybe<EnumMemberStatusFilter>;
  termsEmail?: InputMaybe<BoolFilter>;
  termsMarketing?: InputMaybe<BoolFilter>;
  termsSms?: InputMaybe<BoolFilter>;
  thirdPartyId?: InputMaybe<EnumThirdPartyIdFilter>;
  thirdPartyUserId?: InputMaybe<StringNullableFilter>;
  uniqueKey?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  uuid?: InputMaybe<StringFilter>;
  withdrawReason?: InputMaybe<StringNullableFilter>;
}

export interface MemberWhereUniqueInput {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  thirdPartyId_thirdPartyUserId?: InputMaybe<MemberThirdPartyIdThirdPartyUserIdCompoundUniqueInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** GraphQL 메시지 타입 */
export interface Message {
  __typename?: 'Message';
  error: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
}

export enum MilestoneWork {
  Civil = 'CIVIL',
  Electrical = 'ELECTRICAL',
  Etc = 'ETC',
  Facility = 'FACILITY',
  Finish = 'FINISH',
  Frame = 'FRAME',
  FrameUnderground = 'FRAME_UNDERGROUND'
}

/** 간이 공정표 공정 항목 */
export interface MilestoneWorkItem {
  __typename?: 'MilestoneWorkItem';
  /** 공사금액 */
  constructionAmount?: Maybe<Scalars['BigInt']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** endDate */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  projectMilestone: ProjectMilestone;
  projectMilestoneId: Scalars['Int']['output'];
  /** startDate */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique Key */
  uuid: Scalars['String']['output'];
  /** work name */
  workName: MilestoneWork;
}

export interface MilestoneWorkItemAvgAggregateOutputType {
  __typename?: 'MilestoneWorkItemAvgAggregateOutputType';
  constructionAmount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  projectMilestoneId?: Maybe<Scalars['Float']['output']>;
}

export interface MilestoneWorkItemAvgOrderByAggregateInput {
  constructionAmount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectMilestoneId?: InputMaybe<SortOrder>;
}

export interface MilestoneWorkItemCountAggregateOutputType {
  __typename?: 'MilestoneWorkItemCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  constructionAmount: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  endDate: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  projectMilestoneId: Scalars['Int']['output'];
  startDate: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  workName: Scalars['Int']['output'];
}

export interface MilestoneWorkItemCountOrderByAggregateInput {
  constructionAmount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectMilestoneId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface MilestoneWorkItemCreateInput {
  constructionAmount?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  projectMilestone: ProjectMilestoneCreateNestedOneWithoutWorkItemInput;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  workName: MilestoneWork;
}

export interface MilestoneWorkItemCreateManyInput {
  constructionAmount?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  projectMilestoneId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  workName: MilestoneWork;
}

export interface MilestoneWorkItemCreateManyProjectMilestoneInput {
  constructionAmount?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  workName: MilestoneWork;
}

export interface MilestoneWorkItemCreateManyProjectMilestoneInputEnvelope {
  data: MilestoneWorkItemCreateManyProjectMilestoneInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MilestoneWorkItemCreateNestedManyWithoutProjectMilestoneInput {
  connect?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MilestoneWorkItemCreateOrConnectWithoutProjectMilestoneInput>>>;
  create?: InputMaybe<Array<InputMaybe<MilestoneWorkItemCreateWithoutProjectMilestoneInput>>>;
  createMany?: InputMaybe<MilestoneWorkItemCreateManyProjectMilestoneInputEnvelope>;
}

export interface MilestoneWorkItemCreateOrConnectWithoutProjectMilestoneInput {
  create: MilestoneWorkItemUncheckedCreateWithoutProjectMilestoneInput;
  where: MilestoneWorkItemWhereUniqueInput;
}

export interface MilestoneWorkItemCreateWithoutProjectMilestoneInput {
  constructionAmount?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  workName: MilestoneWork;
}

export interface MilestoneWorkItemListRelationFilter {
  every?: InputMaybe<MilestoneWorkItemWhereInput>;
  none?: InputMaybe<MilestoneWorkItemWhereInput>;
  some?: InputMaybe<MilestoneWorkItemWhereInput>;
}

export interface MilestoneWorkItemMaxAggregateOutputType {
  __typename?: 'MilestoneWorkItemMaxAggregateOutputType';
  constructionAmount?: Maybe<Scalars['BigInt']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectMilestoneId?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  workName?: Maybe<MilestoneWork>;
}

export interface MilestoneWorkItemMaxOrderByAggregateInput {
  constructionAmount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectMilestoneId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface MilestoneWorkItemMinAggregateOutputType {
  __typename?: 'MilestoneWorkItemMinAggregateOutputType';
  constructionAmount?: Maybe<Scalars['BigInt']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectMilestoneId?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  workName?: Maybe<MilestoneWork>;
}

export interface MilestoneWorkItemMinOrderByAggregateInput {
  constructionAmount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectMilestoneId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface MilestoneWorkItemOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface MilestoneWorkItemOrderByWithAggregationInput {
  _avg?: InputMaybe<MilestoneWorkItemAvgOrderByAggregateInput>;
  _count?: InputMaybe<MilestoneWorkItemCountOrderByAggregateInput>;
  _max?: InputMaybe<MilestoneWorkItemMaxOrderByAggregateInput>;
  _min?: InputMaybe<MilestoneWorkItemMinOrderByAggregateInput>;
  _sum?: InputMaybe<MilestoneWorkItemSumOrderByAggregateInput>;
  constructionAmount?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  projectMilestoneId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface MilestoneWorkItemOrderByWithRelationInput {
  constructionAmount?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  projectMilestone?: InputMaybe<ProjectMilestoneOrderByWithRelationInput>;
  projectMilestoneId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  workName?: InputMaybe<SortOrder>;
}

export interface MilestoneWorkItemProjectMilestoneIdWorkNameCompoundUniqueInput {
  projectMilestoneId: Scalars['Int']['input'];
  workName: MilestoneWork;
}

export enum MilestoneWorkItemScalarFieldEnum {
  ConstructionAmount = 'constructionAmount',
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Id = 'id',
  ProjectMilestoneId = 'projectMilestoneId',
  StartDate = 'startDate',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  WorkName = 'workName'
}

export interface MilestoneWorkItemScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<MilestoneWorkItemScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MilestoneWorkItemScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MilestoneWorkItemScalarWhereInput>>>;
  constructionAmount?: InputMaybe<BigIntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  projectMilestoneId?: InputMaybe<IntFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  workName?: InputMaybe<EnumMilestoneWorkFilter>;
}

export interface MilestoneWorkItemScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<MilestoneWorkItemScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MilestoneWorkItemScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MilestoneWorkItemScalarWhereWithAggregatesInput>>>;
  constructionAmount?: InputMaybe<BigIntNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  endDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  projectMilestoneId?: InputMaybe<IntWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  workName?: InputMaybe<EnumMilestoneWorkWithAggregatesFilter>;
}

export interface MilestoneWorkItemSumAggregateOutputType {
  __typename?: 'MilestoneWorkItemSumAggregateOutputType';
  constructionAmount?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectMilestoneId?: Maybe<Scalars['Int']['output']>;
}

export interface MilestoneWorkItemSumOrderByAggregateInput {
  constructionAmount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectMilestoneId?: InputMaybe<SortOrder>;
}

export interface MilestoneWorkItemUncheckedCreateInput {
  constructionAmount?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  projectMilestoneId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  workName: MilestoneWork;
}

export interface MilestoneWorkItemUncheckedCreateNestedManyWithoutProjectMilestoneInput {
  connect?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MilestoneWorkItemCreateOrConnectWithoutProjectMilestoneInput>>>;
  create?: InputMaybe<Array<InputMaybe<MilestoneWorkItemCreateWithoutProjectMilestoneInput>>>;
  createMany?: InputMaybe<MilestoneWorkItemCreateManyProjectMilestoneInputEnvelope>;
}

export interface MilestoneWorkItemUncheckedCreateWithoutProjectMilestoneInput {
  constructionAmount?: InputMaybe<Scalars['BigInt']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  workName: MilestoneWork;
}

export interface MilestoneWorkItemUncheckedUpdateInput {
  constructionAmount?: InputMaybe<NullableBigIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectMilestoneId?: InputMaybe<IntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  workName?: InputMaybe<EnumMilestoneWorkFieldUpdateOperationsInput>;
}

export interface MilestoneWorkItemUncheckedUpdateManyInput {
  constructionAmount?: InputMaybe<NullableBigIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectMilestoneId?: InputMaybe<IntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  workName?: InputMaybe<EnumMilestoneWorkFieldUpdateOperationsInput>;
}

export interface MilestoneWorkItemUncheckedUpdateManyWithoutProjectMilestoneNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MilestoneWorkItemCreateOrConnectWithoutProjectMilestoneInput>>>;
  create?: InputMaybe<Array<InputMaybe<MilestoneWorkItemCreateWithoutProjectMilestoneInput>>>;
  createMany?: InputMaybe<MilestoneWorkItemCreateManyProjectMilestoneInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MilestoneWorkItemScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MilestoneWorkItemUpdateWithWhereUniqueWithoutProjectMilestoneInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MilestoneWorkItemUpdateManyWithWhereWithoutProjectMilestoneInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MilestoneWorkItemUpsertWithWhereUniqueWithoutProjectMilestoneInput>>>;
}

export interface MilestoneWorkItemUncheckedUpdateManyWithoutWorkItemInput {
  constructionAmount?: InputMaybe<NullableBigIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  workName?: InputMaybe<EnumMilestoneWorkFieldUpdateOperationsInput>;
}

export interface MilestoneWorkItemUncheckedUpdateWithoutProjectMilestoneInput {
  constructionAmount?: InputMaybe<NullableBigIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  workName?: InputMaybe<EnumMilestoneWorkFieldUpdateOperationsInput>;
}

export interface MilestoneWorkItemUpdateInput {
  constructionAmount?: InputMaybe<NullableBigIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneRequiredWithoutWorkItemNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  workName?: InputMaybe<EnumMilestoneWorkFieldUpdateOperationsInput>;
}

export interface MilestoneWorkItemUpdateManyMutationInput {
  constructionAmount?: InputMaybe<NullableBigIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  workName?: InputMaybe<EnumMilestoneWorkFieldUpdateOperationsInput>;
}

export interface MilestoneWorkItemUpdateManyWithWhereWithoutProjectMilestoneInput {
  data: MilestoneWorkItemUncheckedUpdateManyWithoutWorkItemInput;
  where: MilestoneWorkItemScalarWhereInput;
}

export interface MilestoneWorkItemUpdateManyWithoutProjectMilestoneNestedInput {
  connect?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<MilestoneWorkItemCreateOrConnectWithoutProjectMilestoneInput>>>;
  create?: InputMaybe<Array<InputMaybe<MilestoneWorkItemCreateWithoutProjectMilestoneInput>>>;
  createMany?: InputMaybe<MilestoneWorkItemCreateManyProjectMilestoneInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<MilestoneWorkItemScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<MilestoneWorkItemUpdateWithWhereUniqueWithoutProjectMilestoneInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<MilestoneWorkItemUpdateManyWithWhereWithoutProjectMilestoneInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<MilestoneWorkItemUpsertWithWhereUniqueWithoutProjectMilestoneInput>>>;
}

export interface MilestoneWorkItemUpdateWithWhereUniqueWithoutProjectMilestoneInput {
  data: MilestoneWorkItemUncheckedUpdateWithoutProjectMilestoneInput;
  where: MilestoneWorkItemWhereUniqueInput;
}

export interface MilestoneWorkItemUpdateWithoutProjectMilestoneInput {
  constructionAmount?: InputMaybe<NullableBigIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  workName?: InputMaybe<EnumMilestoneWorkFieldUpdateOperationsInput>;
}

export interface MilestoneWorkItemUpsertWithWhereUniqueWithoutProjectMilestoneInput {
  create: MilestoneWorkItemUncheckedCreateWithoutProjectMilestoneInput;
  update: MilestoneWorkItemUncheckedUpdateWithoutProjectMilestoneInput;
  where: MilestoneWorkItemWhereUniqueInput;
}

export interface MilestoneWorkItemWhereInput {
  AND?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<MilestoneWorkItemWhereInput>>>;
  constructionAmount?: InputMaybe<BigIntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  projectMilestone?: InputMaybe<ProjectMilestoneWhereInput>;
  projectMilestoneId?: InputMaybe<IntFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  workName?: InputMaybe<EnumMilestoneWorkFilter>;
}

export interface MilestoneWorkItemWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  projectMilestoneId_workName?: InputMaybe<MilestoneWorkItemProjectMilestoneIdWorkNameCompoundUniqueInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface Model {
  __typename?: 'Model';
  create: Scalars['Boolean']['output'];
  delete: Scalars['Boolean']['output'];
  displayFields: Array<Scalars['String']['output']>;
  fields: Array<Field>;
  id: Scalars['String']['output'];
  idField: Scalars['String']['output'];
  name: Scalars['String']['output'];
  update: Scalars['Boolean']['output'];
}

/** CSV Parsed Model Property */
export interface ModelProperty {
  __typename?: 'ModelProperty';
  Project: Project;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['Int']['output'];
  /** json Url */
  jsonUrl?: Maybe<Scalars['String']['output']>;
  projectId: Scalars['Int']['output'];
  /** 특성 데이터 */
  rawJson?: Maybe<Scalars['Json']['output']>;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique ID */
  uuid: Scalars['String']['output'];
}

export interface ModelPropertyAvgAggregateOutputType {
  __typename?: 'ModelPropertyAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  projectId?: Maybe<Scalars['Float']['output']>;
}

export interface ModelPropertyAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface ModelPropertyCountAggregateOutputType {
  __typename?: 'ModelPropertyCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  jsonUrl: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  rawJson: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface ModelPropertyCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jsonUrl?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  rawJson?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ModelPropertyCreateInput {
  Project: ProjectCreateNestedOneWithoutModelPropertyInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  jsonUrl?: InputMaybe<Scalars['String']['input']>;
  rawJson?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ModelPropertyCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  jsonUrl?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['Int']['input'];
  rawJson?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ModelPropertyCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<ModelPropertyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelPropertyCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ModelPropertyUncheckedCreateWithoutProjectInput>;
}

export interface ModelPropertyCreateOrConnectWithoutProjectInput {
  create: ModelPropertyUncheckedCreateWithoutProjectInput;
  where: ModelPropertyWhereUniqueInput;
}

export interface ModelPropertyCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  jsonUrl?: InputMaybe<Scalars['String']['input']>;
  rawJson?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ModelPropertyMaxAggregateOutputType {
  __typename?: 'ModelPropertyMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  jsonUrl?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ModelPropertyMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jsonUrl?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ModelPropertyMinAggregateOutputType {
  __typename?: 'ModelPropertyMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  jsonUrl?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ModelPropertyMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jsonUrl?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ModelPropertyOrderByWithAggregationInput {
  _avg?: InputMaybe<ModelPropertyAvgOrderByAggregateInput>;
  _count?: InputMaybe<ModelPropertyCountOrderByAggregateInput>;
  _max?: InputMaybe<ModelPropertyMaxOrderByAggregateInput>;
  _min?: InputMaybe<ModelPropertyMinOrderByAggregateInput>;
  _sum?: InputMaybe<ModelPropertySumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jsonUrl?: InputMaybe<SortOrderInput>;
  projectId?: InputMaybe<SortOrder>;
  rawJson?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ModelPropertyOrderByWithRelationInput {
  Project?: InputMaybe<ProjectOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  jsonUrl?: InputMaybe<SortOrderInput>;
  projectId?: InputMaybe<SortOrder>;
  rawJson?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ModelPropertyRelationFilter {
  is?: InputMaybe<ModelPropertyWhereInput>;
  isNot?: InputMaybe<ModelPropertyWhereInput>;
}

export enum ModelPropertyScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  JsonUrl = 'jsonUrl',
  ProjectId = 'projectId',
  RawJson = 'rawJson',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface ModelPropertyScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ModelPropertyScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ModelPropertyScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ModelPropertyScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  jsonUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  projectId?: InputMaybe<IntWithAggregatesFilter>;
  rawJson?: InputMaybe<JsonNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface ModelPropertySumAggregateOutputType {
  __typename?: 'ModelPropertySumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
}

export interface ModelPropertySumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface ModelPropertyUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  jsonUrl?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['Int']['input'];
  rawJson?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ModelPropertyUncheckedCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<ModelPropertyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelPropertyCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ModelPropertyUncheckedCreateWithoutProjectInput>;
}

export interface ModelPropertyUncheckedCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  jsonUrl?: InputMaybe<Scalars['String']['input']>;
  rawJson?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ModelPropertyUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  jsonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  rawJson?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ModelPropertyUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  jsonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  rawJson?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<ModelPropertyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelPropertyCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ModelPropertyUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ModelPropertyUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ModelPropertyUpsertWithoutProjectInput>;
}

export interface ModelPropertyUncheckedUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  jsonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rawJson?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ModelPropertyUpdateInput {
  Project?: InputMaybe<ProjectUpdateOneRequiredWithoutModelPropertyNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  jsonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rawJson?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ModelPropertyUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  jsonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rawJson?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ModelPropertyUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<ModelPropertyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelPropertyCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ModelPropertyUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ModelPropertyUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ModelPropertyUpsertWithoutProjectInput>;
}

export interface ModelPropertyUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  jsonUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rawJson?: InputMaybe<Scalars['Json']['input']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ModelPropertyUpsertWithoutProjectInput {
  create: ModelPropertyUncheckedCreateWithoutProjectInput;
  update: ModelPropertyUncheckedUpdateWithoutProjectInput;
}

export interface ModelPropertyWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ModelPropertyWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ModelPropertyWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ModelPropertyWhereInput>>>;
  Project?: InputMaybe<ProjectWhereInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  jsonUrl?: InputMaybe<StringNullableFilter>;
  projectId?: InputMaybe<IntFilter>;
  rawJson?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ModelPropertyWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** Partner: 모델링 전문가 */
export interface Modeler {
  __typename?: 'Modeler';
  _count: ModelerCountOutputType;
  /** Partner user address */
  address?: Maybe<Address>;
  addressDetail?: Maybe<Scalars['String']['output']>;
  /** Partner user addressId */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** Avatar URL */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** Partner businessNumber (사업자등록번호) */
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Name */
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partner: Partner;
  partnerId: Scalars['Int']['output'];
  partnerStatus: PartnerRegistrationStatus;
  profile: Array<Profile>;
  /** 사업자등록증 사진 키 */
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail: Scalars['String']['output'];
  responsibilityName: Scalars['String']['output'];
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  /** Partner user role */
  role: PartnerRole;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}


/** Partner: 모델링 전문가 */
export type ModelerProfileArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<ProfileScalarFieldEnum>;
  orderBy?: InputMaybe<ProfileOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};

export interface ModelerAvgAggregateOutputType {
  __typename?: 'ModelerAvgAggregateOutputType';
  addressId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  partnerId?: Maybe<Scalars['Float']['output']>;
}

export interface ModelerAvgOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface ModelerCountAggregateOutputType {
  __typename?: 'ModelerCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  addressDetail: Scalars['Int']['output'];
  addressId: Scalars['Int']['output'];
  avatarUrl: Scalars['Int']['output'];
  businessNumber: Scalars['Int']['output'];
  companyContact: Scalars['Int']['output'];
  companyDescription: Scalars['Int']['output'];
  companyWebsite: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  ownerName: Scalars['Int']['output'];
  partnerId: Scalars['Int']['output'];
  partnerStatus: Scalars['Int']['output'];
  registrationPhotoKey: Scalars['Int']['output'];
  responsibilityEmail: Scalars['Int']['output'];
  responsibilityName: Scalars['Int']['output'];
  responsibilityPosition: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface ModelerCountOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ModelerCountOutputType {
  __typename?: 'ModelerCountOutputType';
  profile: Scalars['Int']['output'];
}

export interface ModelerCreateInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutModelerInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutModelerInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutModelerInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ModelerCreateManyAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ModelerCreateManyAddressInputEnvelope {
  data: ModelerCreateManyAddressInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ModelerCreateManyInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ModelerCreateManyPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ModelerCreateManyPartnerInputEnvelope {
  data: ModelerCreateManyPartnerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ModelerCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ModelerCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ModelerCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ModelerCreateManyAddressInputEnvelope>;
}

export interface ModelerCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ModelerCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ModelerCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ModelerCreateManyPartnerInputEnvelope>;
}

export interface ModelerCreateNestedOneWithoutProfileInput {
  connect?: InputMaybe<ModelerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelerCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<ModelerUncheckedCreateWithoutProfileInput>;
}

export interface ModelerCreateOrConnectWithoutAddressInput {
  create: ModelerUncheckedCreateWithoutAddressInput;
  where: ModelerWhereUniqueInput;
}

export interface ModelerCreateOrConnectWithoutPartnerInput {
  create: ModelerUncheckedCreateWithoutPartnerInput;
  where: ModelerWhereUniqueInput;
}

export interface ModelerCreateOrConnectWithoutProfileInput {
  create: ModelerUncheckedCreateWithoutProfileInput;
  where: ModelerWhereUniqueInput;
}

export interface ModelerCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutModelerInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutModelerInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ModelerCreateWithoutPartnerInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutModelerInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutModelerInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ModelerCreateWithoutProfileInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutModelerInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutModelerInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ModelerListRelationFilter {
  every?: InputMaybe<ModelerWhereInput>;
  none?: InputMaybe<ModelerWhereInput>;
  some?: InputMaybe<ModelerWhereInput>;
}

export interface ModelerMaxAggregateOutputType {
  __typename?: 'ModelerMaxAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface ModelerMaxOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ModelerMinAggregateOutputType {
  __typename?: 'ModelerMinAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface ModelerMinOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ModelerOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ModelerOrderByWithAggregationInput {
  _avg?: InputMaybe<ModelerAvgOrderByAggregateInput>;
  _count?: InputMaybe<ModelerCountOrderByAggregateInput>;
  _max?: InputMaybe<ModelerMaxOrderByAggregateInput>;
  _min?: InputMaybe<ModelerMinOrderByAggregateInput>;
  _sum?: InputMaybe<ModelerSumOrderByAggregateInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ModelerOrderByWithRelationInput {
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partner?: InputMaybe<PartnerOrderByWithRelationInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByRelationAggregateInput>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ModelerRelationFilter {
  is?: InputMaybe<ModelerWhereInput>;
  isNot?: InputMaybe<ModelerWhereInput>;
}

export enum ModelerScalarFieldEnum {
  AddressDetail = 'addressDetail',
  AddressId = 'addressId',
  AvatarUrl = 'avatarUrl',
  BusinessNumber = 'businessNumber',
  CompanyContact = 'companyContact',
  CompanyDescription = 'companyDescription',
  CompanyWebsite = 'companyWebsite',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OwnerName = 'ownerName',
  PartnerId = 'partnerId',
  PartnerStatus = 'partnerStatus',
  RegistrationPhotoKey = 'registrationPhotoKey',
  ResponsibilityEmail = 'responsibilityEmail',
  ResponsibilityName = 'responsibilityName',
  ResponsibilityPosition = 'responsibilityPosition',
  Role = 'role',
  UpdatedAt = 'updatedAt'
}

export interface ModelerScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ModelerScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ModelerScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ModelerScalarWhereInput>>>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface ModelerScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ModelerScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ModelerScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ModelerScalarWhereWithAggregatesInput>>>;
  addressDetail?: InputMaybe<StringNullableWithAggregatesFilter>;
  addressId?: InputMaybe<IntNullableWithAggregatesFilter>;
  avatarUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  businessNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyContact?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyDescription?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyWebsite?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  ownerName?: InputMaybe<StringNullableWithAggregatesFilter>;
  partnerId?: InputMaybe<IntWithAggregatesFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusWithAggregatesFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableWithAggregatesFilter>;
  responsibilityEmail?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityName?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityPosition?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumPartnerRoleWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface ModelerSumAggregateOutputType {
  __typename?: 'ModelerSumAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
}

export interface ModelerSumOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface ModelerUncheckedCreateInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutModelerInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ModelerUncheckedCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ModelerCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ModelerCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ModelerCreateManyAddressInputEnvelope>;
}

export interface ModelerUncheckedCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ModelerCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ModelerCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ModelerCreateManyPartnerInputEnvelope>;
}

export interface ModelerUncheckedCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutModelerInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ModelerUncheckedCreateWithoutPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutModelerInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ModelerUncheckedCreateWithoutProfileInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ModelerUncheckedUpdateInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutModelerNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ModelerUncheckedUpdateManyInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ModelerUncheckedUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ModelerCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ModelerCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ModelerCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ModelerScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ModelerUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ModelerUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ModelerUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface ModelerUncheckedUpdateManyWithoutModelerInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ModelerUncheckedUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ModelerCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ModelerCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ModelerCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ModelerScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ModelerUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ModelerUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ModelerUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface ModelerUncheckedUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutModelerNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ModelerUncheckedUpdateWithoutPartnerInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutModelerNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ModelerUncheckedUpdateWithoutProfileInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ModelerUpdateInput {
  address?: InputMaybe<AddressUpdateOneWithoutModelerNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutModelerNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutModelerNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ModelerUpdateManyMutationInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ModelerUpdateManyWithWhereWithoutAddressInput {
  data: ModelerUncheckedUpdateManyWithoutModelerInput;
  where: ModelerScalarWhereInput;
}

export interface ModelerUpdateManyWithWhereWithoutPartnerInput {
  data: ModelerUncheckedUpdateManyWithoutModelerInput;
  where: ModelerScalarWhereInput;
}

export interface ModelerUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ModelerCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ModelerCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ModelerCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ModelerScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ModelerUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ModelerUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ModelerUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface ModelerUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ModelerCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ModelerCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<ModelerCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ModelerScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ModelerWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ModelerUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ModelerUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ModelerUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface ModelerUpdateOneWithoutProfileNestedInput {
  connect?: InputMaybe<ModelerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModelerCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<ModelerUncheckedCreateWithoutProfileInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ModelerUncheckedUpdateWithoutProfileInput>;
  upsert?: InputMaybe<ModelerUpsertWithoutProfileInput>;
}

export interface ModelerUpdateWithWhereUniqueWithoutAddressInput {
  data: ModelerUncheckedUpdateWithoutAddressInput;
  where: ModelerWhereUniqueInput;
}

export interface ModelerUpdateWithWhereUniqueWithoutPartnerInput {
  data: ModelerUncheckedUpdateWithoutPartnerInput;
  where: ModelerWhereUniqueInput;
}

export interface ModelerUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutModelerNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutModelerNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ModelerUpdateWithoutPartnerInput {
  address?: InputMaybe<AddressUpdateOneWithoutModelerNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutModelerNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ModelerUpdateWithoutProfileInput {
  address?: InputMaybe<AddressUpdateOneWithoutModelerNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutModelerNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ModelerUpsertWithWhereUniqueWithoutAddressInput {
  create: ModelerUncheckedCreateWithoutAddressInput;
  update: ModelerUncheckedUpdateWithoutAddressInput;
  where: ModelerWhereUniqueInput;
}

export interface ModelerUpsertWithWhereUniqueWithoutPartnerInput {
  create: ModelerUncheckedCreateWithoutPartnerInput;
  update: ModelerUncheckedUpdateWithoutPartnerInput;
  where: ModelerWhereUniqueInput;
}

export interface ModelerUpsertWithoutProfileInput {
  create: ModelerUncheckedCreateWithoutProfileInput;
  update: ModelerUncheckedUpdateWithoutProfileInput;
}

export interface ModelerWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ModelerWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ModelerWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ModelerWhereInput>>>;
  address?: InputMaybe<AddressWhereInput>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partner?: InputMaybe<PartnerWhereInput>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  profile?: InputMaybe<ProfileListRelationFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface ModelerWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  /**
   * 적산 정보 목록 Parsing
   * @deprecated This function will not store data anymore, will only return true
   */
  EstimationParsing: Scalars['Boolean']['output'];
  /**
   * 물가정보 Parsing
   * @deprecated This function will not store data anymore, will only return true
   */
  MaterialPriceParsing: Scalars['Boolean']['output'];
  /** AWS Cognito, API Key 생성 등 */
  authentication?: Maybe<Authentication>;
  /** BOQ Standard 업데이트 */
  boqExcelUpdate?: Maybe<ExcelMutation>;
  /** 비밀번호 변경 */
  changePassword?: Maybe<NewPassStatus>;
  createMyInquiry: Inquiry;
  createMyProject: Project;
  /** Create New Partner / Required Signin and CUSTOMER permit */
  createNewPartner: Partner;
  createOneAccessLog: AccessLog;
  createOneAddress: Address;
  createOneAdmin: Admin;
  createOneAdminSchema: AdminSchema;
  createOneArchitect: Architect;
  createOneArchitectureSRW: ArchitectureSrw;
  createOneBOQUpdate: BoqUpdate;
  createOneBlueprint: Blueprint;
  createOneBlueprintComment: BlueprintComment;
  createOneBoQItem: BoQItem;
  createOneCashReceipt: CashReceipt;
  createOneCivilWorkSRW: CivilWorkSrw;
  createOneCommunicationSRW: CommunicationSrw;
  createOneConstruction: Construction;
  createOneCustomer: Customer;
  createOneElectricalSRW: ElectricalSrw;
  createOneEventLogs: EventLogs;
  createOneEventResult: EventResult;
  createOneFireProtectionSRW: FireProtectionSrw;
  createOneGeneralConstruction: GeneralConstruction;
  createOneIdentity: Identity;
  createOneInquiry: Inquiry;
  createOneInspectionRejected: InspectionRejected;
  createOneKakaoMessageTemplate: KakaoMessageTemplate;
  createOneKakaoSendLog: KakaoSendLog;
  createOneLandscapingSRW: LandscapingSrw;
  createOneMaterialPrice: MaterialPrice;
  createOneMechanicalSRW: MechanicalSrw;
  createOneMember: Member;
  createOneMemberEvent: MemberEvent;
  createOneMilestoneWorkItem: MilestoneWorkItem;
  createOneModelProperty: ModelProperty;
  createOneModeler: Modeler;
  createOneMyBlueprint: Blueprint;
  createOneNotice: Notice;
  createOneNoticeComment: NoticeComment;
  createOneNoticeTopic: NoticeTopic;
  createOnePartner: Partner;
  createOnePayTransaction: PayTransaction;
  createOnePayTransactionAPIOnly: PayTransaction;
  createOnePersonnel: Personnel;
  createOneProcessSimulation: ProcessSimulation;
  createOneProcessTaskCell: ProcessTaskCell;
  createOneProfile: Profile;
  createOneProject: Project;
  createOneProjectAsset: ProjectAsset;
  createOneProjectBoQ: ProjectBoQ;
  createOneProjectBoQFiles: ProjectBoQFiles;
  createOneProjectFloorInfo: ProjectFloorInfo;
  createOneProjectHistory: ProjectHistory;
  createOneProjectMilestone: ProjectMilestone;
  createOneReply: Reply;
  createOneShareProjectByMember: ShareProjectByMember;
  createOneShareRatioWork: ShareRatioWork;
  createOneSharedProject: SharedProject;
  createOneSharedProjectByMember: SharedProjectByMember;
  createOneSharedProjectToken: SharedProjectToken;
  createOneSpaceDesign: SpaceDesign;
  createOneStandardBoQ: StandardBoQ;
  createOneStandardBoQFiles: StandardBoQFiles;
  createOneSupplier: Supplier;
  createOneTask: Task;
  createOneVatInvoice: VatInvoice;
  createOneWithdrawReason: WithdrawReason;
  /** 멤버 탈퇴: 사용자는 disable 시키며, 멤버는 deactive함. 이후 스케쥴러는 일정기간이 지난 후 Member, Identity, Project등을 모두 삭제한다. */
  deactivateMe?: Maybe<Member>;
  deleteManyAccessLog: BatchPayload;
  deleteManyAddress: BatchPayload;
  deleteManyAdmin: BatchPayload;
  deleteManyAdminSchema: BatchPayload;
  deleteManyArchitect: BatchPayload;
  deleteManyArchitectureSRW: BatchPayload;
  deleteManyBOQUpdate: BatchPayload;
  deleteManyBlueprint: BatchPayload;
  deleteManyBlueprintComment: BatchPayload;
  deleteManyBoQItem: BatchPayload;
  deleteManyCashReceipt: BatchPayload;
  deleteManyCivilWorkSRW: BatchPayload;
  deleteManyCommunicationSRW: BatchPayload;
  deleteManyConstruction: BatchPayload;
  deleteManyCustomer: BatchPayload;
  deleteManyElectricalSRW: BatchPayload;
  deleteManyEventLogs: BatchPayload;
  deleteManyEventResult: BatchPayload;
  deleteManyFireProtectionSRW: BatchPayload;
  deleteManyGeneralConstruction: BatchPayload;
  deleteManyIdentity: BatchPayload;
  deleteManyInquiry: BatchPayload;
  deleteManyInspectionRejected: BatchPayload;
  deleteManyKakaoMessageTemplate: BatchPayload;
  deleteManyKakaoSendLog: BatchPayload;
  deleteManyLandscapingSRW: BatchPayload;
  deleteManyMaterialPrice: BatchPayload;
  deleteManyMechanicalSRW: BatchPayload;
  deleteManyMember: BatchPayload;
  deleteManyMemberEvent: BatchPayload;
  deleteManyMilestoneWorkItem: BatchPayload;
  deleteManyModelProperty: BatchPayload;
  deleteManyModeler: BatchPayload;
  deleteManyNotice: BatchPayload;
  deleteManyNoticeComment: BatchPayload;
  deleteManyNoticeTopic: BatchPayload;
  deleteManyPartner: BatchPayload;
  deleteManyPayTransaction: BatchPayload;
  deleteManyPersonnel: BatchPayload;
  deleteManyProcessSimulation: BatchPayload;
  deleteManyProcessTaskCell: BatchPayload;
  deleteManyProfile: BatchPayload;
  deleteManyProject: BatchPayload;
  deleteManyProjectAsset: BatchPayload;
  deleteManyProjectBoQ: BatchPayload;
  deleteManyProjectBoQFiles: BatchPayload;
  deleteManyProjectFloorInfo: BatchPayload;
  deleteManyProjectHistory: BatchPayload;
  deleteManyProjectMilestone: BatchPayload;
  deleteManyReply: BatchPayload;
  deleteManyShareProjectByMember: BatchPayload;
  deleteManyShareRatioWork: BatchPayload;
  deleteManySharedProject: BatchPayload;
  deleteManySharedProjectByMember: BatchPayload;
  deleteManySharedProjectToken: BatchPayload;
  deleteManySpaceDesign: BatchPayload;
  deleteManyStandardBoQ: BatchPayload;
  deleteManyStandardBoQFiles: BatchPayload;
  deleteManySupplier: BatchPayload;
  deleteManyTask: BatchPayload;
  deleteManyVatInvoice: BatchPayload;
  deleteManyWithdrawReason: BatchPayload;
  deleteOneAccessLog?: Maybe<AccessLog>;
  deleteOneAddress?: Maybe<Address>;
  deleteOneAdmin?: Maybe<Admin>;
  deleteOneAdminSchema?: Maybe<AdminSchema>;
  deleteOneArchitect?: Maybe<Architect>;
  deleteOneArchitectureSRW?: Maybe<ArchitectureSrw>;
  deleteOneBOQUpdate?: Maybe<BoqUpdate>;
  deleteOneBlueprint?: Maybe<Blueprint>;
  deleteOneBlueprintComment?: Maybe<BlueprintComment>;
  deleteOneBoQItem?: Maybe<BoQItem>;
  deleteOneCashReceipt?: Maybe<CashReceipt>;
  deleteOneCivilWorkSRW?: Maybe<CivilWorkSrw>;
  deleteOneCommunicationSRW?: Maybe<CommunicationSrw>;
  deleteOneConstruction?: Maybe<Construction>;
  deleteOneCustomer?: Maybe<Customer>;
  deleteOneElectricalSRW?: Maybe<ElectricalSrw>;
  deleteOneEventLogs?: Maybe<EventLogs>;
  deleteOneEventResult?: Maybe<EventResult>;
  deleteOneFireProtectionSRW?: Maybe<FireProtectionSrw>;
  deleteOneGeneralConstruction?: Maybe<GeneralConstruction>;
  deleteOneIdentity?: Maybe<Identity>;
  deleteOneInquiry?: Maybe<Inquiry>;
  deleteOneInspectionRejected?: Maybe<InspectionRejected>;
  deleteOneKakaoMessageTemplate?: Maybe<KakaoMessageTemplate>;
  deleteOneKakaoSendLog?: Maybe<KakaoSendLog>;
  deleteOneLandscapingSRW?: Maybe<LandscapingSrw>;
  deleteOneMaterialPrice?: Maybe<MaterialPrice>;
  deleteOneMechanicalSRW?: Maybe<MechanicalSrw>;
  deleteOneMember?: Maybe<Member>;
  deleteOneMemberEvent?: Maybe<MemberEvent>;
  deleteOneMilestoneWorkItem?: Maybe<MilestoneWorkItem>;
  deleteOneModelProperty?: Maybe<ModelProperty>;
  deleteOneModeler?: Maybe<Modeler>;
  deleteOneNotice?: Maybe<Notice>;
  deleteOneNoticeComment?: Maybe<NoticeComment>;
  deleteOneNoticeTopic?: Maybe<NoticeTopic>;
  deleteOnePartner?: Maybe<Partner>;
  deleteOnePayTransaction?: Maybe<PayTransaction>;
  deleteOnePersonnel?: Maybe<Personnel>;
  deleteOneProcessSimulation?: Maybe<ProcessSimulation>;
  deleteOneProcessTaskCell?: Maybe<ProcessTaskCell>;
  deleteOneProfile?: Maybe<Profile>;
  deleteOneProject?: Maybe<Project>;
  deleteOneProjectAsset?: Maybe<ProjectAsset>;
  deleteOneProjectBoQ?: Maybe<ProjectBoQ>;
  deleteOneProjectBoQFiles?: Maybe<ProjectBoQFiles>;
  deleteOneProjectFloorInfo?: Maybe<ProjectFloorInfo>;
  deleteOneProjectHistory?: Maybe<ProjectHistory>;
  deleteOneProjectMilestone?: Maybe<ProjectMilestone>;
  deleteOneReply?: Maybe<Reply>;
  deleteOneShareProjectByMember?: Maybe<ShareProjectByMember>;
  deleteOneShareRatioWork?: Maybe<ShareRatioWork>;
  deleteOneSharedProject?: Maybe<SharedProject>;
  deleteOneSharedProjectByMember?: Maybe<SharedProjectByMember>;
  deleteOneSharedProjectToken?: Maybe<SharedProjectToken>;
  deleteOneSpaceDesign?: Maybe<SpaceDesign>;
  deleteOneStandardBoQ?: Maybe<StandardBoQ>;
  deleteOneStandardBoQFiles?: Maybe<StandardBoQFiles>;
  deleteOneSupplier?: Maybe<Supplier>;
  deleteOneTask?: Maybe<Task>;
  deleteOneVatInvoice?: Maybe<VatInvoice>;
  deleteOneWithdrawReason?: Maybe<WithdrawReason>;
  devSeedProjects?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use downloadAll from Project Asset Instead */
  download?: Maybe<Scalars['String']['output']>;
  /** 공종별 내역서 Excel 파싱 */
  excel?: Maybe<ExcelMutation>;
  /** Autodesk Forge API 관련 Create, Update, Upload Mutations 모음 */
  forge?: Maybe<ForgeMutations>;
  /**
   * BoQ Parsing
   * @deprecated This function will not store data anymore, will only return true
   */
  parseBoQ?: Maybe<Scalars['Boolean']['output']>;
  /** Builderhub Standard BoQ Item Price Parsing */
  parseBoQItem?: Maybe<BoQItemParseResult>;
  presignedUploadWithMetadata?: Maybe<PresignedUploadWithMetadataReturn>;
  /** @deprecated CSV NOT USING */
  processModelProperty?: Maybe<Message>;
  /** 프로젝트 상태 변경 메시지 전송 */
  pushSMS?: Maybe<Scalars['Boolean']['output']>;
  reShareProject?: Maybe<ReSharedMessage>;
  /** 프로젝트 관련 알림톡 보내기 */
  sendProjectKakaoMessage?: Maybe<Array<Maybe<SendKakaoMessageResponse>>>;
  shareProject?: Maybe<SharedMessage>;
  unShareProject?: Maybe<SharedMessage>;
  updateField: Field;
  updateManyAccessLog: BatchPayload;
  updateManyAddress: BatchPayload;
  updateManyAdmin: BatchPayload;
  updateManyAdminSchema: BatchPayload;
  updateManyArchitect: BatchPayload;
  updateManyArchitectureSRW: BatchPayload;
  updateManyBOQUpdate: BatchPayload;
  updateManyBlueprint: BatchPayload;
  updateManyBlueprintComment: BatchPayload;
  updateManyBoQItem: BatchPayload;
  updateManyCashReceipt: BatchPayload;
  updateManyCivilWorkSRW: BatchPayload;
  updateManyCommunicationSRW: BatchPayload;
  updateManyConstruction: BatchPayload;
  updateManyCustomer: BatchPayload;
  updateManyElectricalSRW: BatchPayload;
  updateManyEventLogs: BatchPayload;
  updateManyEventResult: BatchPayload;
  updateManyFireProtectionSRW: BatchPayload;
  updateManyGeneralConstruction: BatchPayload;
  updateManyIdentity: BatchPayload;
  updateManyInquiry: BatchPayload;
  updateManyInspectionRejected: BatchPayload;
  updateManyKakaoMessageTemplate: BatchPayload;
  updateManyKakaoSendLog: BatchPayload;
  updateManyLandscapingSRW: BatchPayload;
  updateManyMaterialPrice: BatchPayload;
  updateManyMechanicalSRW: BatchPayload;
  updateManyMember: BatchPayload;
  updateManyMemberEvent: BatchPayload;
  updateManyMilestoneWorkItem: BatchPayload;
  updateManyModelProperty: BatchPayload;
  updateManyModeler: BatchPayload;
  updateManyMyBlueprint: BatchPayload;
  updateManyNotice: BatchPayload;
  updateManyNoticeComment: BatchPayload;
  updateManyNoticeTopic: BatchPayload;
  updateManyPartner: BatchPayload;
  updateManyPayTransaction: BatchPayload;
  updateManyPersonnel: BatchPayload;
  updateManyProcessSimulation: BatchPayload;
  updateManyProcessTaskCell: BatchPayload;
  updateManyProfile: BatchPayload;
  updateManyProject: BatchPayload;
  updateManyProjectAsset: BatchPayload;
  updateManyProjectBoQ: BatchPayload;
  updateManyProjectBoQFiles: BatchPayload;
  updateManyProjectFloorInfo: BatchPayload;
  updateManyProjectHistory: BatchPayload;
  updateManyProjectMilestone: BatchPayload;
  updateManyReply: BatchPayload;
  updateManyShareProjectByMember: BatchPayload;
  updateManyShareRatioWork: BatchPayload;
  updateManySharedProject: BatchPayload;
  updateManySharedProjectByMember: BatchPayload;
  updateManySharedProjectToken: BatchPayload;
  updateManySpaceDesign: BatchPayload;
  updateManyStandardBoQ: BatchPayload;
  updateManyStandardBoQFiles: BatchPayload;
  updateManySupplier: BatchPayload;
  updateManyTask: BatchPayload;
  updateManyVatInvoice: BatchPayload;
  updateManyWithdrawReason: BatchPayload;
  updateMe?: Maybe<Member>;
  updateModel: Model;
  updateMyInquiry: Inquiry;
  updateMyPartner: Partner;
  updateMyProject: Project;
  updateOneAccessLog: AccessLog;
  updateOneAddress: Address;
  updateOneAdmin: Admin;
  updateOneAdminSchema: AdminSchema;
  updateOneArchitect: Architect;
  updateOneArchitectureSRW: ArchitectureSrw;
  updateOneBOQUpdate: BoqUpdate;
  updateOneBlueprint: Blueprint;
  updateOneBlueprintComment: BlueprintComment;
  updateOneBoQItem: BoQItem;
  updateOneCashReceipt: CashReceipt;
  updateOneCivilWorkSRW: CivilWorkSrw;
  updateOneCommunicationSRW: CommunicationSrw;
  updateOneConstruction: Construction;
  updateOneCustomer: Customer;
  updateOneElectricalSRW: ElectricalSrw;
  updateOneEventLogs: EventLogs;
  updateOneEventResult: EventResult;
  updateOneFireProtectionSRW: FireProtectionSrw;
  updateOneGeneralConstruction: GeneralConstruction;
  updateOneIdentity: Identity;
  updateOneInquiry: Inquiry;
  updateOneInspectionRejected: InspectionRejected;
  updateOneKakaoMessageTemplate: KakaoMessageTemplate;
  updateOneKakaoSendLog: KakaoSendLog;
  updateOneLandscapingSRW: LandscapingSrw;
  updateOneMaterialPrice: MaterialPrice;
  updateOneMechanicalSRW: MechanicalSrw;
  updateOneMember: Member;
  updateOneMemberEvent: MemberEvent;
  updateOneMilestoneWorkItem: MilestoneWorkItem;
  updateOneModelProperty: ModelProperty;
  updateOneModeler: Modeler;
  updateOneNotice: Notice;
  updateOneNoticeComment: NoticeComment;
  updateOneNoticeTopic: NoticeTopic;
  updateOnePartner: Partner;
  updateOnePayTransaction: PayTransaction;
  updateOnePayTransactionAPIOnly: PayTransaction;
  updateOnePersonnel: Personnel;
  updateOneProcessSimulation: ProcessSimulation;
  updateOneProcessTaskCell: ProcessTaskCell;
  updateOneProfile: Profile;
  updateOneProject: Project;
  updateOneProjectAPIOnly: Project;
  updateOneProjectAsset: ProjectAsset;
  updateOneProjectBoQ: ProjectBoQ;
  updateOneProjectBoQFiles: ProjectBoQFiles;
  updateOneProjectFloorInfo: ProjectFloorInfo;
  updateOneProjectHistory: ProjectHistory;
  updateOneProjectMilestone: ProjectMilestone;
  updateOneReply: Reply;
  updateOneShareProjectByMember: ShareProjectByMember;
  updateOneShareRatioWork: ShareRatioWork;
  updateOneSharedProject: SharedProject;
  updateOneSharedProjectByMember: SharedProjectByMember;
  updateOneSharedProjectToken: SharedProjectToken;
  updateOneSpaceDesign: SpaceDesign;
  updateOneStandardBoQ: StandardBoQ;
  updateOneStandardBoQFiles: StandardBoQFiles;
  updateOneSupplier: Supplier;
  updateOneTask: Task;
  updateOneVatInvoice: VatInvoice;
  updateOneWithdrawReason: WithdrawReason;
  updateSharedProject?: Maybe<UpdateSharedMessage>;
  /** @deprecated Use presingerUpload instead */
  upload?: Maybe<Scalars['String']['output']>;
  /** @deprecated CSV파일을 Concrete, Rebar, Formwork로 각각 분리. `uploadCsvQ` */
  uploadCsv?: Maybe<Scalars['String']['output']>;
  /** @deprecated CSV NOT USING */
  uploadCsvQ?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use presignedUploadPublic instead */
  uploadPublic?: Maybe<Scalars['String']['output']>;
  uploadPublicPresigned?: Maybe<PresignedUploadPublicReturn>;
  upsertOneAccessLog: AccessLog;
  upsertOneAddress: Address;
  upsertOneAdmin: Admin;
  upsertOneAdminSchema: AdminSchema;
  upsertOneArchitect: Architect;
  upsertOneArchitectureSRW: ArchitectureSrw;
  upsertOneBOQUpdate: BoqUpdate;
  upsertOneBlueprint: Blueprint;
  upsertOneBlueprintComment: BlueprintComment;
  upsertOneBoQItem: BoQItem;
  upsertOneCashReceipt: CashReceipt;
  upsertOneCivilWorkSRW: CivilWorkSrw;
  upsertOneCommunicationSRW: CommunicationSrw;
  upsertOneConstruction: Construction;
  upsertOneCustomer: Customer;
  upsertOneElectricalSRW: ElectricalSrw;
  upsertOneEventLogs: EventLogs;
  upsertOneEventResult: EventResult;
  upsertOneFireProtectionSRW: FireProtectionSrw;
  upsertOneGeneralConstruction: GeneralConstruction;
  upsertOneIdentity: Identity;
  upsertOneInquiry: Inquiry;
  upsertOneInspectionRejected: InspectionRejected;
  upsertOneKakaoMessageTemplate: KakaoMessageTemplate;
  upsertOneKakaoSendLog: KakaoSendLog;
  upsertOneLandscapingSRW: LandscapingSrw;
  upsertOneMaterialPrice: MaterialPrice;
  upsertOneMechanicalSRW: MechanicalSrw;
  upsertOneMember: Member;
  upsertOneMemberEvent: MemberEvent;
  upsertOneMilestoneWorkItem: MilestoneWorkItem;
  upsertOneModelProperty: ModelProperty;
  upsertOneModeler: Modeler;
  upsertOneNotice: Notice;
  upsertOneNoticeComment: NoticeComment;
  upsertOneNoticeTopic: NoticeTopic;
  upsertOnePartner: Partner;
  upsertOnePayTransaction: PayTransaction;
  upsertOnePersonnel: Personnel;
  upsertOneProcessSimulation: ProcessSimulation;
  upsertOneProcessTaskCell: ProcessTaskCell;
  upsertOneProfile: Profile;
  upsertOneProject: Project;
  upsertOneProjectAsset: ProjectAsset;
  upsertOneProjectBoQ: ProjectBoQ;
  upsertOneProjectBoQFiles: ProjectBoQFiles;
  upsertOneProjectFloorInfo: ProjectFloorInfo;
  upsertOneProjectHistory: ProjectHistory;
  upsertOneProjectMilestone: ProjectMilestone;
  upsertOneReply: Reply;
  upsertOneShareProjectByMember: ShareProjectByMember;
  upsertOneShareRatioWork: ShareRatioWork;
  upsertOneSharedProject: SharedProject;
  upsertOneSharedProjectByMember: SharedProjectByMember;
  upsertOneSharedProjectToken: SharedProjectToken;
  upsertOneSpaceDesign: SpaceDesign;
  upsertOneStandardBoQ: StandardBoQ;
  upsertOneStandardBoQFiles: StandardBoQFiles;
  upsertOneSupplier: Supplier;
  upsertOneTask: Task;
  upsertOneVatInvoice: VatInvoice;
  upsertOneWithdrawReason: WithdrawReason;
}


export type MutationEstimationParsingArgs = {
  key: Scalars['String']['input'];
};


export type MutationMaterialPriceParsingArgs = {
  key: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  input: NewPassPayload;
};


export type MutationCreateMyInquiryArgs = {
  data: InquiryCreateInput;
};


export type MutationCreateMyProjectArgs = {
  data: ProjectCreateInput;
};


export type MutationCreateNewPartnerArgs = {
  data: PartnerCreateInput;
};


export type MutationCreateOneAccessLogArgs = {
  data: AccessLogCreateInput;
};


export type MutationCreateOneAddressArgs = {
  data: AddressCreateInput;
};


export type MutationCreateOneAdminArgs = {
  data: AdminCreateInput;
};


export type MutationCreateOneAdminSchemaArgs = {
  data: AdminSchemaCreateInput;
};


export type MutationCreateOneArchitectArgs = {
  data: ArchitectCreateInput;
};


export type MutationCreateOneArchitectureSrwArgs = {
  data: ArchitectureSrwCreateInput;
};


export type MutationCreateOneBoqUpdateArgs = {
  data: BoqUpdateCreateInput;
};


export type MutationCreateOneBlueprintArgs = {
  data: BlueprintCreateInput;
};


export type MutationCreateOneBlueprintCommentArgs = {
  data: BlueprintCommentCreateInput;
};


export type MutationCreateOneBoQItemArgs = {
  data: BoQItemCreateInput;
};


export type MutationCreateOneCashReceiptArgs = {
  data: CashReceiptCreateInput;
};


export type MutationCreateOneCivilWorkSrwArgs = {
  data: CivilWorkSrwCreateInput;
};


export type MutationCreateOneCommunicationSrwArgs = {
  data: CommunicationSrwCreateInput;
};


export type MutationCreateOneConstructionArgs = {
  data: ConstructionCreateInput;
};


export type MutationCreateOneCustomerArgs = {
  data: CustomerCreateInput;
};


export type MutationCreateOneElectricalSrwArgs = {
  data: ElectricalSrwCreateInput;
};


export type MutationCreateOneEventLogsArgs = {
  data: EventLogsCreateInput;
};


export type MutationCreateOneEventResultArgs = {
  data: EventResultCreateInput;
};


export type MutationCreateOneFireProtectionSrwArgs = {
  data: FireProtectionSrwCreateInput;
};


export type MutationCreateOneGeneralConstructionArgs = {
  data: GeneralConstructionCreateInput;
};


export type MutationCreateOneIdentityArgs = {
  data: IdentityCreateInput;
};


export type MutationCreateOneInquiryArgs = {
  data: InquiryCreateInput;
};


export type MutationCreateOneInspectionRejectedArgs = {
  data: InspectionRejectedCreateInput;
};


export type MutationCreateOneKakaoMessageTemplateArgs = {
  data: KakaoMessageTemplateCreateInput;
};


export type MutationCreateOneKakaoSendLogArgs = {
  data: KakaoSendLogCreateInput;
};


export type MutationCreateOneLandscapingSrwArgs = {
  data: LandscapingSrwCreateInput;
};


export type MutationCreateOneMaterialPriceArgs = {
  data: MaterialPriceCreateInput;
};


export type MutationCreateOneMechanicalSrwArgs = {
  data: MechanicalSrwCreateInput;
};


export type MutationCreateOneMemberArgs = {
  data: MemberCreateInput;
};


export type MutationCreateOneMemberEventArgs = {
  data: MemberEventCreateInput;
};


export type MutationCreateOneMilestoneWorkItemArgs = {
  data: MilestoneWorkItemCreateInput;
};


export type MutationCreateOneModelPropertyArgs = {
  data: ModelPropertyCreateInput;
};


export type MutationCreateOneModelerArgs = {
  data: ModelerCreateInput;
};


export type MutationCreateOneMyBlueprintArgs = {
  data: BlueprintCreateInput;
};


export type MutationCreateOneNoticeArgs = {
  data: NoticeCreateInput;
};


export type MutationCreateOneNoticeCommentArgs = {
  data: NoticeCommentCreateInput;
};


export type MutationCreateOneNoticeTopicArgs = {
  data: NoticeTopicCreateInput;
};


export type MutationCreateOnePartnerArgs = {
  data: PartnerCreateInput;
};


export type MutationCreateOnePayTransactionArgs = {
  data: PayTransactionCreateInput;
};


export type MutationCreateOnePayTransactionApiOnlyArgs = {
  data: PayTransactionCreateInput;
};


export type MutationCreateOnePersonnelArgs = {
  data: PersonnelCreateInput;
};


export type MutationCreateOneProcessSimulationArgs = {
  data: ProcessSimulationCreateInput;
};


export type MutationCreateOneProcessTaskCellArgs = {
  data: ProcessTaskCellCreateInput;
};


export type MutationCreateOneProfileArgs = {
  data: ProfileCreateInput;
};


export type MutationCreateOneProjectArgs = {
  data: ProjectCreateInput;
};


export type MutationCreateOneProjectAssetArgs = {
  data: ProjectAssetCreateInput;
};


export type MutationCreateOneProjectBoQArgs = {
  data: ProjectBoQCreateInput;
};


export type MutationCreateOneProjectBoQFilesArgs = {
  data: ProjectBoQFilesCreateInput;
};


export type MutationCreateOneProjectFloorInfoArgs = {
  data: ProjectFloorInfoCreateInput;
};


export type MutationCreateOneProjectHistoryArgs = {
  data: ProjectHistoryCreateInput;
};


export type MutationCreateOneProjectMilestoneArgs = {
  data: ProjectMilestoneCreateInput;
};


export type MutationCreateOneReplyArgs = {
  data: ReplyCreateInput;
};


export type MutationCreateOneShareProjectByMemberArgs = {
  data: ShareProjectByMemberCreateInput;
};


export type MutationCreateOneShareRatioWorkArgs = {
  data: ShareRatioWorkCreateInput;
};


export type MutationCreateOneSharedProjectArgs = {
  data: SharedProjectCreateInput;
};


export type MutationCreateOneSharedProjectByMemberArgs = {
  data: SharedProjectByMemberCreateInput;
};


export type MutationCreateOneSharedProjectTokenArgs = {
  data: SharedProjectTokenCreateInput;
};


export type MutationCreateOneSpaceDesignArgs = {
  data: SpaceDesignCreateInput;
};


export type MutationCreateOneStandardBoQArgs = {
  data: StandardBoQCreateInput;
};


export type MutationCreateOneStandardBoQFilesArgs = {
  data: StandardBoQFilesCreateInput;
};


export type MutationCreateOneSupplierArgs = {
  data: SupplierCreateInput;
};


export type MutationCreateOneTaskArgs = {
  data: TaskCreateInput;
};


export type MutationCreateOneVatInvoiceArgs = {
  data: VatInvoiceCreateInput;
};


export type MutationCreateOneWithdrawReasonArgs = {
  data: WithdrawReasonCreateInput;
};


export type MutationDeactivateMeArgs = {
  withdrawReason: Scalars['String']['input'];
};


export type MutationDeleteManyAccessLogArgs = {
  where?: InputMaybe<AccessLogWhereInput>;
};


export type MutationDeleteManyAddressArgs = {
  where?: InputMaybe<AddressWhereInput>;
};


export type MutationDeleteManyAdminArgs = {
  where?: InputMaybe<AdminWhereInput>;
};


export type MutationDeleteManyAdminSchemaArgs = {
  where?: InputMaybe<AdminSchemaWhereInput>;
};


export type MutationDeleteManyArchitectArgs = {
  where?: InputMaybe<ArchitectWhereInput>;
};


export type MutationDeleteManyArchitectureSrwArgs = {
  where?: InputMaybe<ArchitectureSrwWhereInput>;
};


export type MutationDeleteManyBoqUpdateArgs = {
  where?: InputMaybe<BoqUpdateWhereInput>;
};


export type MutationDeleteManyBlueprintArgs = {
  where?: InputMaybe<BlueprintWhereInput>;
};


export type MutationDeleteManyBlueprintCommentArgs = {
  where?: InputMaybe<BlueprintCommentWhereInput>;
};


export type MutationDeleteManyBoQItemArgs = {
  where?: InputMaybe<BoQItemWhereInput>;
};


export type MutationDeleteManyCashReceiptArgs = {
  where?: InputMaybe<CashReceiptWhereInput>;
};


export type MutationDeleteManyCivilWorkSrwArgs = {
  where?: InputMaybe<CivilWorkSrwWhereInput>;
};


export type MutationDeleteManyCommunicationSrwArgs = {
  where?: InputMaybe<CommunicationSrwWhereInput>;
};


export type MutationDeleteManyConstructionArgs = {
  where?: InputMaybe<ConstructionWhereInput>;
};


export type MutationDeleteManyCustomerArgs = {
  where?: InputMaybe<CustomerWhereInput>;
};


export type MutationDeleteManyElectricalSrwArgs = {
  where?: InputMaybe<ElectricalSrwWhereInput>;
};


export type MutationDeleteManyEventLogsArgs = {
  where?: InputMaybe<EventLogsWhereInput>;
};


export type MutationDeleteManyEventResultArgs = {
  where?: InputMaybe<EventResultWhereInput>;
};


export type MutationDeleteManyFireProtectionSrwArgs = {
  where?: InputMaybe<FireProtectionSrwWhereInput>;
};


export type MutationDeleteManyGeneralConstructionArgs = {
  where?: InputMaybe<GeneralConstructionWhereInput>;
};


export type MutationDeleteManyIdentityArgs = {
  where?: InputMaybe<IdentityWhereInput>;
};


export type MutationDeleteManyInquiryArgs = {
  where?: InputMaybe<InquiryWhereInput>;
};


export type MutationDeleteManyInspectionRejectedArgs = {
  where?: InputMaybe<InspectionRejectedWhereInput>;
};


export type MutationDeleteManyKakaoMessageTemplateArgs = {
  where?: InputMaybe<KakaoMessageTemplateWhereInput>;
};


export type MutationDeleteManyKakaoSendLogArgs = {
  where?: InputMaybe<KakaoSendLogWhereInput>;
};


export type MutationDeleteManyLandscapingSrwArgs = {
  where?: InputMaybe<LandscapingSrwWhereInput>;
};


export type MutationDeleteManyMaterialPriceArgs = {
  where?: InputMaybe<MaterialPriceWhereInput>;
};


export type MutationDeleteManyMechanicalSrwArgs = {
  where?: InputMaybe<MechanicalSrwWhereInput>;
};


export type MutationDeleteManyMemberArgs = {
  where?: InputMaybe<MemberWhereInput>;
};


export type MutationDeleteManyMemberEventArgs = {
  where?: InputMaybe<MemberEventWhereInput>;
};


export type MutationDeleteManyMilestoneWorkItemArgs = {
  where?: InputMaybe<MilestoneWorkItemWhereInput>;
};


export type MutationDeleteManyModelPropertyArgs = {
  where?: InputMaybe<ModelPropertyWhereInput>;
};


export type MutationDeleteManyModelerArgs = {
  where?: InputMaybe<ModelerWhereInput>;
};


export type MutationDeleteManyNoticeArgs = {
  where?: InputMaybe<NoticeWhereInput>;
};


export type MutationDeleteManyNoticeCommentArgs = {
  where?: InputMaybe<NoticeCommentWhereInput>;
};


export type MutationDeleteManyNoticeTopicArgs = {
  where?: InputMaybe<NoticeTopicWhereInput>;
};


export type MutationDeleteManyPartnerArgs = {
  where?: InputMaybe<PartnerWhereInput>;
};


export type MutationDeleteManyPayTransactionArgs = {
  where?: InputMaybe<PayTransactionWhereInput>;
};


export type MutationDeleteManyPersonnelArgs = {
  where?: InputMaybe<PersonnelWhereInput>;
};


export type MutationDeleteManyProcessSimulationArgs = {
  where?: InputMaybe<ProcessSimulationWhereInput>;
};


export type MutationDeleteManyProcessTaskCellArgs = {
  where?: InputMaybe<ProcessTaskCellWhereInput>;
};


export type MutationDeleteManyProfileArgs = {
  where?: InputMaybe<ProfileWhereInput>;
};


export type MutationDeleteManyProjectArgs = {
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationDeleteManyProjectAssetArgs = {
  where?: InputMaybe<ProjectAssetWhereInput>;
};


export type MutationDeleteManyProjectBoQArgs = {
  where?: InputMaybe<ProjectBoQWhereInput>;
};


export type MutationDeleteManyProjectBoQFilesArgs = {
  where?: InputMaybe<ProjectBoQFilesWhereInput>;
};


export type MutationDeleteManyProjectFloorInfoArgs = {
  where?: InputMaybe<ProjectFloorInfoWhereInput>;
};


export type MutationDeleteManyProjectHistoryArgs = {
  where?: InputMaybe<ProjectHistoryWhereInput>;
};


export type MutationDeleteManyProjectMilestoneArgs = {
  where?: InputMaybe<ProjectMilestoneWhereInput>;
};


export type MutationDeleteManyReplyArgs = {
  where?: InputMaybe<ReplyWhereInput>;
};


export type MutationDeleteManyShareProjectByMemberArgs = {
  where?: InputMaybe<ShareProjectByMemberWhereInput>;
};


export type MutationDeleteManyShareRatioWorkArgs = {
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};


export type MutationDeleteManySharedProjectArgs = {
  where?: InputMaybe<SharedProjectWhereInput>;
};


export type MutationDeleteManySharedProjectByMemberArgs = {
  where?: InputMaybe<SharedProjectByMemberWhereInput>;
};


export type MutationDeleteManySharedProjectTokenArgs = {
  where?: InputMaybe<SharedProjectTokenWhereInput>;
};


export type MutationDeleteManySpaceDesignArgs = {
  where?: InputMaybe<SpaceDesignWhereInput>;
};


export type MutationDeleteManyStandardBoQArgs = {
  where?: InputMaybe<StandardBoQWhereInput>;
};


export type MutationDeleteManyStandardBoQFilesArgs = {
  where?: InputMaybe<StandardBoQFilesWhereInput>;
};


export type MutationDeleteManySupplierArgs = {
  where?: InputMaybe<SupplierWhereInput>;
};


export type MutationDeleteManyTaskArgs = {
  where?: InputMaybe<TaskWhereInput>;
};


export type MutationDeleteManyVatInvoiceArgs = {
  where?: InputMaybe<VatInvoiceWhereInput>;
};


export type MutationDeleteManyWithdrawReasonArgs = {
  where?: InputMaybe<WithdrawReasonWhereInput>;
};


export type MutationDeleteOneAccessLogArgs = {
  where: AccessLogWhereUniqueInput;
};


export type MutationDeleteOneAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type MutationDeleteOneAdminArgs = {
  where: AdminWhereUniqueInput;
};


export type MutationDeleteOneAdminSchemaArgs = {
  where: AdminSchemaWhereUniqueInput;
};


export type MutationDeleteOneArchitectArgs = {
  where: ArchitectWhereUniqueInput;
};


export type MutationDeleteOneArchitectureSrwArgs = {
  where: ArchitectureSrwWhereUniqueInput;
};


export type MutationDeleteOneBoqUpdateArgs = {
  where: BoqUpdateWhereUniqueInput;
};


export type MutationDeleteOneBlueprintArgs = {
  where: BlueprintWhereUniqueInput;
};


export type MutationDeleteOneBlueprintCommentArgs = {
  where: BlueprintCommentWhereUniqueInput;
};


export type MutationDeleteOneBoQItemArgs = {
  where: BoQItemWhereUniqueInput;
};


export type MutationDeleteOneCashReceiptArgs = {
  where: CashReceiptWhereUniqueInput;
};


export type MutationDeleteOneCivilWorkSrwArgs = {
  where: CivilWorkSrwWhereUniqueInput;
};


export type MutationDeleteOneCommunicationSrwArgs = {
  where: CommunicationSrwWhereUniqueInput;
};


export type MutationDeleteOneConstructionArgs = {
  where: ConstructionWhereUniqueInput;
};


export type MutationDeleteOneCustomerArgs = {
  where: CustomerWhereUniqueInput;
};


export type MutationDeleteOneElectricalSrwArgs = {
  where: ElectricalSrwWhereUniqueInput;
};


export type MutationDeleteOneEventLogsArgs = {
  where: EventLogsWhereUniqueInput;
};


export type MutationDeleteOneEventResultArgs = {
  where: EventResultWhereUniqueInput;
};


export type MutationDeleteOneFireProtectionSrwArgs = {
  where: FireProtectionSrwWhereUniqueInput;
};


export type MutationDeleteOneGeneralConstructionArgs = {
  where: GeneralConstructionWhereUniqueInput;
};


export type MutationDeleteOneIdentityArgs = {
  where: IdentityWhereUniqueInput;
};


export type MutationDeleteOneInquiryArgs = {
  where: InquiryWhereUniqueInput;
};


export type MutationDeleteOneInspectionRejectedArgs = {
  where: InspectionRejectedWhereUniqueInput;
};


export type MutationDeleteOneKakaoMessageTemplateArgs = {
  where: KakaoMessageTemplateWhereUniqueInput;
};


export type MutationDeleteOneKakaoSendLogArgs = {
  where: KakaoSendLogWhereUniqueInput;
};


export type MutationDeleteOneLandscapingSrwArgs = {
  where: LandscapingSrwWhereUniqueInput;
};


export type MutationDeleteOneMaterialPriceArgs = {
  where: MaterialPriceWhereUniqueInput;
};


export type MutationDeleteOneMechanicalSrwArgs = {
  where: MechanicalSrwWhereUniqueInput;
};


export type MutationDeleteOneMemberArgs = {
  where: MemberWhereUniqueInput;
};


export type MutationDeleteOneMemberEventArgs = {
  where: MemberEventWhereUniqueInput;
};


export type MutationDeleteOneMilestoneWorkItemArgs = {
  where: MilestoneWorkItemWhereUniqueInput;
};


export type MutationDeleteOneModelPropertyArgs = {
  where: ModelPropertyWhereUniqueInput;
};


export type MutationDeleteOneModelerArgs = {
  where: ModelerWhereUniqueInput;
};


export type MutationDeleteOneNoticeArgs = {
  where: NoticeWhereUniqueInput;
};


export type MutationDeleteOneNoticeCommentArgs = {
  where: NoticeCommentWhereUniqueInput;
};


export type MutationDeleteOneNoticeTopicArgs = {
  where: NoticeTopicWhereUniqueInput;
};


export type MutationDeleteOnePartnerArgs = {
  where: PartnerWhereUniqueInput;
};


export type MutationDeleteOnePayTransactionArgs = {
  where: PayTransactionWhereUniqueInput;
};


export type MutationDeleteOnePersonnelArgs = {
  where: PersonnelWhereUniqueInput;
};


export type MutationDeleteOneProcessSimulationArgs = {
  where: ProcessSimulationWhereUniqueInput;
};


export type MutationDeleteOneProcessTaskCellArgs = {
  where: ProcessTaskCellWhereUniqueInput;
};


export type MutationDeleteOneProfileArgs = {
  where: ProfileWhereUniqueInput;
};


export type MutationDeleteOneProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type MutationDeleteOneProjectAssetArgs = {
  where: ProjectAssetWhereUniqueInput;
};


export type MutationDeleteOneProjectBoQArgs = {
  where: ProjectBoQWhereUniqueInput;
};


export type MutationDeleteOneProjectBoQFilesArgs = {
  where: ProjectBoQFilesWhereUniqueInput;
};


export type MutationDeleteOneProjectFloorInfoArgs = {
  where: ProjectFloorInfoWhereUniqueInput;
};


export type MutationDeleteOneProjectHistoryArgs = {
  where: ProjectHistoryWhereUniqueInput;
};


export type MutationDeleteOneProjectMilestoneArgs = {
  where: ProjectMilestoneWhereUniqueInput;
};


export type MutationDeleteOneReplyArgs = {
  where: ReplyWhereUniqueInput;
};


export type MutationDeleteOneShareProjectByMemberArgs = {
  where: ShareProjectByMemberWhereUniqueInput;
};


export type MutationDeleteOneShareRatioWorkArgs = {
  where: ShareRatioWorkWhereUniqueInput;
};


export type MutationDeleteOneSharedProjectArgs = {
  where: SharedProjectWhereUniqueInput;
};


export type MutationDeleteOneSharedProjectByMemberArgs = {
  where: SharedProjectByMemberWhereUniqueInput;
};


export type MutationDeleteOneSharedProjectTokenArgs = {
  where: SharedProjectTokenWhereUniqueInput;
};


export type MutationDeleteOneSpaceDesignArgs = {
  where: SpaceDesignWhereUniqueInput;
};


export type MutationDeleteOneStandardBoQArgs = {
  where: StandardBoQWhereUniqueInput;
};


export type MutationDeleteOneStandardBoQFilesArgs = {
  where: StandardBoQFilesWhereUniqueInput;
};


export type MutationDeleteOneSupplierArgs = {
  where: SupplierWhereUniqueInput;
};


export type MutationDeleteOneTaskArgs = {
  where: TaskWhereUniqueInput;
};


export type MutationDeleteOneVatInvoiceArgs = {
  where: VatInvoiceWhereUniqueInput;
};


export type MutationDeleteOneWithdrawReasonArgs = {
  where: WithdrawReasonWhereUniqueInput;
};


export type MutationDevSeedProjectsArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDownloadArgs = {
  projectUuid: Scalars['String']['input'];
};


export type MutationParseBoQArgs = {
  fileKey: Scalars['String']['input'];
  projectUuid: Scalars['String']['input'];
};


export type MutationParseBoQItemArgs = {
  fileKey: Scalars['String']['input'];
  testMode: Scalars['Boolean']['input'];
};


export type MutationPresignedUploadWithMetadataArgs = {
  fileKey: Scalars['String']['input'];
};


export type MutationProcessModelPropertyArgs = {
  projectId: Scalars['String']['input'];
};


export type MutationPushSmsArgs = {
  projectId: Scalars['Int']['input'];
};


export type MutationReShareProjectArgs = {
  projectId: Scalars['String']['input'];
  sharedId: Scalars['Int']['input'];
};


export type MutationSendProjectKakaoMessageArgs = {
  projectId: Scalars['String']['input'];
  template: KakaoTemplate;
};


export type MutationShareProjectArgs = {
  email: Scalars['String']['input'];
  permission: ProjectPermission;
  projectId: Scalars['String']['input'];
};


export type MutationUnShareProjectArgs = {
  email: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
  sharedProjectId: Scalars['Int']['input'];
};


export type MutationUpdateFieldArgs = {
  data: UpdateFieldInput;
  id: Scalars['String']['input'];
  modelId: Scalars['String']['input'];
};


export type MutationUpdateManyAccessLogArgs = {
  data: AccessLogUpdateManyMutationInput;
  where?: InputMaybe<AccessLogWhereInput>;
};


export type MutationUpdateManyAddressArgs = {
  data: AddressUpdateManyMutationInput;
  where?: InputMaybe<AddressWhereInput>;
};


export type MutationUpdateManyAdminArgs = {
  data: AdminUpdateManyMutationInput;
  where?: InputMaybe<AdminWhereInput>;
};


export type MutationUpdateManyAdminSchemaArgs = {
  data: AdminSchemaUpdateManyMutationInput;
  where?: InputMaybe<AdminSchemaWhereInput>;
};


export type MutationUpdateManyArchitectArgs = {
  data: ArchitectUpdateManyMutationInput;
  where?: InputMaybe<ArchitectWhereInput>;
};


export type MutationUpdateManyArchitectureSrwArgs = {
  data: ArchitectureSrwUpdateManyMutationInput;
  where?: InputMaybe<ArchitectureSrwWhereInput>;
};


export type MutationUpdateManyBoqUpdateArgs = {
  data: BoqUpdateUpdateManyMutationInput;
  where?: InputMaybe<BoqUpdateWhereInput>;
};


export type MutationUpdateManyBlueprintArgs = {
  data: BlueprintUpdateManyMutationInput;
  where?: InputMaybe<BlueprintWhereInput>;
};


export type MutationUpdateManyBlueprintCommentArgs = {
  data: BlueprintCommentUpdateManyMutationInput;
  where?: InputMaybe<BlueprintCommentWhereInput>;
};


export type MutationUpdateManyBoQItemArgs = {
  data: BoQItemUpdateManyMutationInput;
  where?: InputMaybe<BoQItemWhereInput>;
};


export type MutationUpdateManyCashReceiptArgs = {
  data: CashReceiptUpdateManyMutationInput;
  where?: InputMaybe<CashReceiptWhereInput>;
};


export type MutationUpdateManyCivilWorkSrwArgs = {
  data: CivilWorkSrwUpdateManyMutationInput;
  where?: InputMaybe<CivilWorkSrwWhereInput>;
};


export type MutationUpdateManyCommunicationSrwArgs = {
  data: CommunicationSrwUpdateManyMutationInput;
  where?: InputMaybe<CommunicationSrwWhereInput>;
};


export type MutationUpdateManyConstructionArgs = {
  data: ConstructionUpdateManyMutationInput;
  where?: InputMaybe<ConstructionWhereInput>;
};


export type MutationUpdateManyCustomerArgs = {
  data: CustomerUpdateManyMutationInput;
  where?: InputMaybe<CustomerWhereInput>;
};


export type MutationUpdateManyElectricalSrwArgs = {
  data: ElectricalSrwUpdateManyMutationInput;
  where?: InputMaybe<ElectricalSrwWhereInput>;
};


export type MutationUpdateManyEventLogsArgs = {
  data: EventLogsUpdateManyMutationInput;
  where?: InputMaybe<EventLogsWhereInput>;
};


export type MutationUpdateManyEventResultArgs = {
  data: EventResultUpdateManyMutationInput;
  where?: InputMaybe<EventResultWhereInput>;
};


export type MutationUpdateManyFireProtectionSrwArgs = {
  data: FireProtectionSrwUpdateManyMutationInput;
  where?: InputMaybe<FireProtectionSrwWhereInput>;
};


export type MutationUpdateManyGeneralConstructionArgs = {
  data: GeneralConstructionUpdateManyMutationInput;
  where?: InputMaybe<GeneralConstructionWhereInput>;
};


export type MutationUpdateManyIdentityArgs = {
  data: IdentityUpdateManyMutationInput;
  where?: InputMaybe<IdentityWhereInput>;
};


export type MutationUpdateManyInquiryArgs = {
  data: InquiryUpdateManyMutationInput;
  where?: InputMaybe<InquiryWhereInput>;
};


export type MutationUpdateManyInspectionRejectedArgs = {
  data: InspectionRejectedUpdateManyMutationInput;
  where?: InputMaybe<InspectionRejectedWhereInput>;
};


export type MutationUpdateManyKakaoMessageTemplateArgs = {
  data: KakaoMessageTemplateUpdateManyMutationInput;
  where?: InputMaybe<KakaoMessageTemplateWhereInput>;
};


export type MutationUpdateManyKakaoSendLogArgs = {
  data: KakaoSendLogUpdateManyMutationInput;
  where?: InputMaybe<KakaoSendLogWhereInput>;
};


export type MutationUpdateManyLandscapingSrwArgs = {
  data: LandscapingSrwUpdateManyMutationInput;
  where?: InputMaybe<LandscapingSrwWhereInput>;
};


export type MutationUpdateManyMaterialPriceArgs = {
  data: MaterialPriceUpdateManyMutationInput;
  where?: InputMaybe<MaterialPriceWhereInput>;
};


export type MutationUpdateManyMechanicalSrwArgs = {
  data: MechanicalSrwUpdateManyMutationInput;
  where?: InputMaybe<MechanicalSrwWhereInput>;
};


export type MutationUpdateManyMemberArgs = {
  data: MemberUpdateManyMutationInput;
  where?: InputMaybe<MemberWhereInput>;
};


export type MutationUpdateManyMemberEventArgs = {
  data: MemberEventUpdateManyMutationInput;
  where?: InputMaybe<MemberEventWhereInput>;
};


export type MutationUpdateManyMilestoneWorkItemArgs = {
  data: MilestoneWorkItemUpdateManyMutationInput;
  where?: InputMaybe<MilestoneWorkItemWhereInput>;
};


export type MutationUpdateManyModelPropertyArgs = {
  data: ModelPropertyUpdateManyMutationInput;
  where?: InputMaybe<ModelPropertyWhereInput>;
};


export type MutationUpdateManyModelerArgs = {
  data: ModelerUpdateManyMutationInput;
  where?: InputMaybe<ModelerWhereInput>;
};


export type MutationUpdateManyMyBlueprintArgs = {
  data: BlueprintUpdateManyMutationInput;
  where?: InputMaybe<BlueprintWhereInput>;
};


export type MutationUpdateManyNoticeArgs = {
  data: NoticeUpdateManyMutationInput;
  where?: InputMaybe<NoticeWhereInput>;
};


export type MutationUpdateManyNoticeCommentArgs = {
  data: NoticeCommentUpdateManyMutationInput;
  where?: InputMaybe<NoticeCommentWhereInput>;
};


export type MutationUpdateManyNoticeTopicArgs = {
  data: NoticeTopicUpdateManyMutationInput;
  where?: InputMaybe<NoticeTopicWhereInput>;
};


export type MutationUpdateManyPartnerArgs = {
  data: PartnerUpdateManyMutationInput;
  where?: InputMaybe<PartnerWhereInput>;
};


export type MutationUpdateManyPayTransactionArgs = {
  data: PayTransactionUpdateManyMutationInput;
  where?: InputMaybe<PayTransactionWhereInput>;
};


export type MutationUpdateManyPersonnelArgs = {
  data: PersonnelUpdateManyMutationInput;
  where?: InputMaybe<PersonnelWhereInput>;
};


export type MutationUpdateManyProcessSimulationArgs = {
  data: ProcessSimulationUpdateManyMutationInput;
  where?: InputMaybe<ProcessSimulationWhereInput>;
};


export type MutationUpdateManyProcessTaskCellArgs = {
  data: ProcessTaskCellUpdateManyMutationInput;
  where?: InputMaybe<ProcessTaskCellWhereInput>;
};


export type MutationUpdateManyProfileArgs = {
  data: ProfileUpdateManyMutationInput;
  where?: InputMaybe<ProfileWhereInput>;
};


export type MutationUpdateManyProjectArgs = {
  data: ProjectUpdateManyMutationInput;
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationUpdateManyProjectAssetArgs = {
  data: ProjectAssetUpdateManyMutationInput;
  where?: InputMaybe<ProjectAssetWhereInput>;
};


export type MutationUpdateManyProjectBoQArgs = {
  data: ProjectBoQUpdateManyMutationInput;
  where?: InputMaybe<ProjectBoQWhereInput>;
};


export type MutationUpdateManyProjectBoQFilesArgs = {
  data: ProjectBoQFilesUpdateManyMutationInput;
  where?: InputMaybe<ProjectBoQFilesWhereInput>;
};


export type MutationUpdateManyProjectFloorInfoArgs = {
  data: ProjectFloorInfoUpdateManyMutationInput;
  where?: InputMaybe<ProjectFloorInfoWhereInput>;
};


export type MutationUpdateManyProjectHistoryArgs = {
  data: ProjectHistoryUpdateManyMutationInput;
  where?: InputMaybe<ProjectHistoryWhereInput>;
};


export type MutationUpdateManyProjectMilestoneArgs = {
  data: ProjectMilestoneUpdateManyMutationInput;
  where?: InputMaybe<ProjectMilestoneWhereInput>;
};


export type MutationUpdateManyReplyArgs = {
  data: ReplyUpdateManyMutationInput;
  where?: InputMaybe<ReplyWhereInput>;
};


export type MutationUpdateManyShareProjectByMemberArgs = {
  data: ShareProjectByMemberUpdateManyMutationInput;
  where?: InputMaybe<ShareProjectByMemberWhereInput>;
};


export type MutationUpdateManyShareRatioWorkArgs = {
  data: ShareRatioWorkUpdateManyMutationInput;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};


export type MutationUpdateManySharedProjectArgs = {
  data: SharedProjectUpdateManyMutationInput;
  where?: InputMaybe<SharedProjectWhereInput>;
};


export type MutationUpdateManySharedProjectByMemberArgs = {
  data: SharedProjectByMemberUpdateManyMutationInput;
  where?: InputMaybe<SharedProjectByMemberWhereInput>;
};


export type MutationUpdateManySharedProjectTokenArgs = {
  data: SharedProjectTokenUpdateManyMutationInput;
  where?: InputMaybe<SharedProjectTokenWhereInput>;
};


export type MutationUpdateManySpaceDesignArgs = {
  data: SpaceDesignUpdateManyMutationInput;
  where?: InputMaybe<SpaceDesignWhereInput>;
};


export type MutationUpdateManyStandardBoQArgs = {
  data: StandardBoQUpdateManyMutationInput;
  where?: InputMaybe<StandardBoQWhereInput>;
};


export type MutationUpdateManyStandardBoQFilesArgs = {
  data: StandardBoQFilesUpdateManyMutationInput;
  where?: InputMaybe<StandardBoQFilesWhereInput>;
};


export type MutationUpdateManySupplierArgs = {
  data: SupplierUpdateManyMutationInput;
  where?: InputMaybe<SupplierWhereInput>;
};


export type MutationUpdateManyTaskArgs = {
  data: TaskUpdateManyMutationInput;
  where?: InputMaybe<TaskWhereInput>;
};


export type MutationUpdateManyVatInvoiceArgs = {
  data: VatInvoiceUpdateManyMutationInput;
  where?: InputMaybe<VatInvoiceWhereInput>;
};


export type MutationUpdateManyWithdrawReasonArgs = {
  data: WithdrawReasonUpdateManyMutationInput;
  where?: InputMaybe<WithdrawReasonWhereInput>;
};


export type MutationUpdateMeArgs = {
  data: MemberUpdateInput;
};


export type MutationUpdateModelArgs = {
  data: UpdateModelInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateMyInquiryArgs = {
  data: InquiryUpdateInput;
  log?: InputMaybe<Scalars['Boolean']['input']>;
  where: InquiryWhereUniqueInput;
};


export type MutationUpdateMyPartnerArgs = {
  data: PartnerUpdateInput;
};


export type MutationUpdateMyProjectArgs = {
  data: ProjectUpdateInput;
  log?: InputMaybe<Scalars['Boolean']['input']>;
  where: ProjectWhereUniqueInput;
};


export type MutationUpdateOneAccessLogArgs = {
  data: AccessLogUpdateInput;
  where: AccessLogWhereUniqueInput;
};


export type MutationUpdateOneAddressArgs = {
  data: AddressUpdateInput;
  where: AddressWhereUniqueInput;
};


export type MutationUpdateOneAdminArgs = {
  data: AdminUpdateInput;
  where: AdminWhereUniqueInput;
};


export type MutationUpdateOneAdminSchemaArgs = {
  data: AdminSchemaUpdateInput;
  where: AdminSchemaWhereUniqueInput;
};


export type MutationUpdateOneArchitectArgs = {
  data: ArchitectUpdateInput;
  where: ArchitectWhereUniqueInput;
};


export type MutationUpdateOneArchitectureSrwArgs = {
  data: ArchitectureSrwUpdateInput;
  where: ArchitectureSrwWhereUniqueInput;
};


export type MutationUpdateOneBoqUpdateArgs = {
  data: BoqUpdateUpdateInput;
  where: BoqUpdateWhereUniqueInput;
};


export type MutationUpdateOneBlueprintArgs = {
  data: BlueprintUpdateInput;
  where: BlueprintWhereUniqueInput;
};


export type MutationUpdateOneBlueprintCommentArgs = {
  data: BlueprintCommentUpdateInput;
  where: BlueprintCommentWhereUniqueInput;
};


export type MutationUpdateOneBoQItemArgs = {
  data: BoQItemUpdateInput;
  where: BoQItemWhereUniqueInput;
};


export type MutationUpdateOneCashReceiptArgs = {
  data: CashReceiptUpdateInput;
  where: CashReceiptWhereUniqueInput;
};


export type MutationUpdateOneCivilWorkSrwArgs = {
  data: CivilWorkSrwUpdateInput;
  where: CivilWorkSrwWhereUniqueInput;
};


export type MutationUpdateOneCommunicationSrwArgs = {
  data: CommunicationSrwUpdateInput;
  where: CommunicationSrwWhereUniqueInput;
};


export type MutationUpdateOneConstructionArgs = {
  data: ConstructionUpdateInput;
  where: ConstructionWhereUniqueInput;
};


export type MutationUpdateOneCustomerArgs = {
  data: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
};


export type MutationUpdateOneElectricalSrwArgs = {
  data: ElectricalSrwUpdateInput;
  where: ElectricalSrwWhereUniqueInput;
};


export type MutationUpdateOneEventLogsArgs = {
  data: EventLogsUpdateInput;
  where: EventLogsWhereUniqueInput;
};


export type MutationUpdateOneEventResultArgs = {
  data: EventResultUpdateInput;
  where: EventResultWhereUniqueInput;
};


export type MutationUpdateOneFireProtectionSrwArgs = {
  data: FireProtectionSrwUpdateInput;
  where: FireProtectionSrwWhereUniqueInput;
};


export type MutationUpdateOneGeneralConstructionArgs = {
  data: GeneralConstructionUpdateInput;
  where: GeneralConstructionWhereUniqueInput;
};


export type MutationUpdateOneIdentityArgs = {
  data: IdentityUpdateInput;
  where: IdentityWhereUniqueInput;
};


export type MutationUpdateOneInquiryArgs = {
  data: InquiryUpdateInput;
  where: InquiryWhereUniqueInput;
};


export type MutationUpdateOneInspectionRejectedArgs = {
  data: InspectionRejectedUpdateInput;
  where: InspectionRejectedWhereUniqueInput;
};


export type MutationUpdateOneKakaoMessageTemplateArgs = {
  data: KakaoMessageTemplateUpdateInput;
  where: KakaoMessageTemplateWhereUniqueInput;
};


export type MutationUpdateOneKakaoSendLogArgs = {
  data: KakaoSendLogUpdateInput;
  where: KakaoSendLogWhereUniqueInput;
};


export type MutationUpdateOneLandscapingSrwArgs = {
  data: LandscapingSrwUpdateInput;
  where: LandscapingSrwWhereUniqueInput;
};


export type MutationUpdateOneMaterialPriceArgs = {
  data: MaterialPriceUpdateInput;
  where: MaterialPriceWhereUniqueInput;
};


export type MutationUpdateOneMechanicalSrwArgs = {
  data: MechanicalSrwUpdateInput;
  where: MechanicalSrwWhereUniqueInput;
};


export type MutationUpdateOneMemberArgs = {
  data: MemberUpdateInput;
  where: MemberWhereUniqueInput;
};


export type MutationUpdateOneMemberEventArgs = {
  data: MemberEventUpdateInput;
  where: MemberEventWhereUniqueInput;
};


export type MutationUpdateOneMilestoneWorkItemArgs = {
  data: MilestoneWorkItemUpdateInput;
  where: MilestoneWorkItemWhereUniqueInput;
};


export type MutationUpdateOneModelPropertyArgs = {
  data: ModelPropertyUpdateInput;
  where: ModelPropertyWhereUniqueInput;
};


export type MutationUpdateOneModelerArgs = {
  data: ModelerUpdateInput;
  where: ModelerWhereUniqueInput;
};


export type MutationUpdateOneNoticeArgs = {
  data: NoticeUpdateInput;
  where: NoticeWhereUniqueInput;
};


export type MutationUpdateOneNoticeCommentArgs = {
  data: NoticeCommentUpdateInput;
  where: NoticeCommentWhereUniqueInput;
};


export type MutationUpdateOneNoticeTopicArgs = {
  data: NoticeTopicUpdateInput;
  where: NoticeTopicWhereUniqueInput;
};


export type MutationUpdateOnePartnerArgs = {
  data: PartnerUpdateInput;
  where: PartnerWhereUniqueInput;
};


export type MutationUpdateOnePayTransactionArgs = {
  data: PayTransactionUpdateInput;
  where: PayTransactionWhereUniqueInput;
};


export type MutationUpdateOnePayTransactionApiOnlyArgs = {
  data: PayTransactionUpdateInput;
  where: PayTransactionWhereUniqueInput;
};


export type MutationUpdateOnePersonnelArgs = {
  data: PersonnelUpdateInput;
  where: PersonnelWhereUniqueInput;
};


export type MutationUpdateOneProcessSimulationArgs = {
  data: ProcessSimulationUpdateInput;
  where: ProcessSimulationWhereUniqueInput;
};


export type MutationUpdateOneProcessTaskCellArgs = {
  data: ProcessTaskCellUpdateInput;
  where: ProcessTaskCellWhereUniqueInput;
};


export type MutationUpdateOneProfileArgs = {
  data: ProfileUpdateInput;
  where: ProfileWhereUniqueInput;
};


export type MutationUpdateOneProjectArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpdateOneProjectApiOnlyArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpdateOneProjectAssetArgs = {
  data: ProjectAssetUpdateInput;
  where: ProjectAssetWhereUniqueInput;
};


export type MutationUpdateOneProjectBoQArgs = {
  data: ProjectBoQUpdateInput;
  where: ProjectBoQWhereUniqueInput;
};


export type MutationUpdateOneProjectBoQFilesArgs = {
  data: ProjectBoQFilesUpdateInput;
  where: ProjectBoQFilesWhereUniqueInput;
};


export type MutationUpdateOneProjectFloorInfoArgs = {
  data: ProjectFloorInfoUpdateInput;
  where: ProjectFloorInfoWhereUniqueInput;
};


export type MutationUpdateOneProjectHistoryArgs = {
  data: ProjectHistoryUpdateInput;
  where: ProjectHistoryWhereUniqueInput;
};


export type MutationUpdateOneProjectMilestoneArgs = {
  data: ProjectMilestoneUpdateInput;
  where: ProjectMilestoneWhereUniqueInput;
};


export type MutationUpdateOneReplyArgs = {
  data: ReplyUpdateInput;
  where: ReplyWhereUniqueInput;
};


export type MutationUpdateOneShareProjectByMemberArgs = {
  data: ShareProjectByMemberUpdateInput;
  where: ShareProjectByMemberWhereUniqueInput;
};


export type MutationUpdateOneShareRatioWorkArgs = {
  data: ShareRatioWorkUpdateInput;
  where: ShareRatioWorkWhereUniqueInput;
};


export type MutationUpdateOneSharedProjectArgs = {
  data: SharedProjectUpdateInput;
  where: SharedProjectWhereUniqueInput;
};


export type MutationUpdateOneSharedProjectByMemberArgs = {
  data: SharedProjectByMemberUpdateInput;
  where: SharedProjectByMemberWhereUniqueInput;
};


export type MutationUpdateOneSharedProjectTokenArgs = {
  data: SharedProjectTokenUpdateInput;
  where: SharedProjectTokenWhereUniqueInput;
};


export type MutationUpdateOneSpaceDesignArgs = {
  data: SpaceDesignUpdateInput;
  where: SpaceDesignWhereUniqueInput;
};


export type MutationUpdateOneStandardBoQArgs = {
  data: StandardBoQUpdateInput;
  where: StandardBoQWhereUniqueInput;
};


export type MutationUpdateOneStandardBoQFilesArgs = {
  data: StandardBoQFilesUpdateInput;
  where: StandardBoQFilesWhereUniqueInput;
};


export type MutationUpdateOneSupplierArgs = {
  data: SupplierUpdateInput;
  where: SupplierWhereUniqueInput;
};


export type MutationUpdateOneTaskArgs = {
  data: TaskUpdateInput;
  where: TaskWhereUniqueInput;
};


export type MutationUpdateOneVatInvoiceArgs = {
  data: VatInvoiceUpdateInput;
  where: VatInvoiceWhereUniqueInput;
};


export type MutationUpdateOneWithdrawReasonArgs = {
  data: WithdrawReasonUpdateInput;
  where: WithdrawReasonWhereUniqueInput;
};


export type MutationUpdateSharedProjectArgs = {
  email: Scalars['String']['input'];
  permission: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
  sharedId: Scalars['Int']['input'];
};


export type MutationUploadArgs = {
  file: Scalars['Upload']['input'];
  fileKey: Scalars['String']['input'];
};


export type MutationUploadCsvArgs = {
  file: Scalars['Upload']['input'];
  fileKey: Scalars['String']['input'];
  projectUuid: Scalars['String']['input'];
};


export type MutationUploadCsvQArgs = {
  csvType: CsvType;
  file: Scalars['Upload']['input'];
  fileKey: Scalars['String']['input'];
  projectUuid: Scalars['String']['input'];
};


export type MutationUploadPublicArgs = {
  file: Scalars['Upload']['input'];
  fileKey: Scalars['String']['input'];
};


export type MutationUploadPublicPresignedArgs = {
  fileKey: Scalars['String']['input'];
};


export type MutationUpsertOneAccessLogArgs = {
  create: AccessLogCreateInput;
  update: AccessLogUpdateInput;
  where: AccessLogWhereUniqueInput;
};


export type MutationUpsertOneAddressArgs = {
  create: AddressCreateInput;
  update: AddressUpdateInput;
  where: AddressWhereUniqueInput;
};


export type MutationUpsertOneAdminArgs = {
  create: AdminCreateInput;
  update: AdminUpdateInput;
  where: AdminWhereUniqueInput;
};


export type MutationUpsertOneAdminSchemaArgs = {
  create: AdminSchemaCreateInput;
  update: AdminSchemaUpdateInput;
  where: AdminSchemaWhereUniqueInput;
};


export type MutationUpsertOneArchitectArgs = {
  create: ArchitectCreateInput;
  update: ArchitectUpdateInput;
  where: ArchitectWhereUniqueInput;
};


export type MutationUpsertOneArchitectureSrwArgs = {
  create: ArchitectureSrwCreateInput;
  update: ArchitectureSrwUpdateInput;
  where: ArchitectureSrwWhereUniqueInput;
};


export type MutationUpsertOneBoqUpdateArgs = {
  create: BoqUpdateCreateInput;
  update: BoqUpdateUpdateInput;
  where: BoqUpdateWhereUniqueInput;
};


export type MutationUpsertOneBlueprintArgs = {
  create: BlueprintCreateInput;
  update: BlueprintUpdateInput;
  where: BlueprintWhereUniqueInput;
};


export type MutationUpsertOneBlueprintCommentArgs = {
  create: BlueprintCommentCreateInput;
  update: BlueprintCommentUpdateInput;
  where: BlueprintCommentWhereUniqueInput;
};


export type MutationUpsertOneBoQItemArgs = {
  create: BoQItemCreateInput;
  update: BoQItemUpdateInput;
  where: BoQItemWhereUniqueInput;
};


export type MutationUpsertOneCashReceiptArgs = {
  create: CashReceiptCreateInput;
  update: CashReceiptUpdateInput;
  where: CashReceiptWhereUniqueInput;
};


export type MutationUpsertOneCivilWorkSrwArgs = {
  create: CivilWorkSrwCreateInput;
  update: CivilWorkSrwUpdateInput;
  where: CivilWorkSrwWhereUniqueInput;
};


export type MutationUpsertOneCommunicationSrwArgs = {
  create: CommunicationSrwCreateInput;
  update: CommunicationSrwUpdateInput;
  where: CommunicationSrwWhereUniqueInput;
};


export type MutationUpsertOneConstructionArgs = {
  create: ConstructionCreateInput;
  update: ConstructionUpdateInput;
  where: ConstructionWhereUniqueInput;
};


export type MutationUpsertOneCustomerArgs = {
  create: CustomerCreateInput;
  update: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
};


export type MutationUpsertOneElectricalSrwArgs = {
  create: ElectricalSrwCreateInput;
  update: ElectricalSrwUpdateInput;
  where: ElectricalSrwWhereUniqueInput;
};


export type MutationUpsertOneEventLogsArgs = {
  create: EventLogsCreateInput;
  update: EventLogsUpdateInput;
  where: EventLogsWhereUniqueInput;
};


export type MutationUpsertOneEventResultArgs = {
  create: EventResultCreateInput;
  update: EventResultUpdateInput;
  where: EventResultWhereUniqueInput;
};


export type MutationUpsertOneFireProtectionSrwArgs = {
  create: FireProtectionSrwCreateInput;
  update: FireProtectionSrwUpdateInput;
  where: FireProtectionSrwWhereUniqueInput;
};


export type MutationUpsertOneGeneralConstructionArgs = {
  create: GeneralConstructionCreateInput;
  update: GeneralConstructionUpdateInput;
  where: GeneralConstructionWhereUniqueInput;
};


export type MutationUpsertOneIdentityArgs = {
  create: IdentityCreateInput;
  update: IdentityUpdateInput;
  where: IdentityWhereUniqueInput;
};


export type MutationUpsertOneInquiryArgs = {
  create: InquiryCreateInput;
  update: InquiryUpdateInput;
  where: InquiryWhereUniqueInput;
};


export type MutationUpsertOneInspectionRejectedArgs = {
  create: InspectionRejectedCreateInput;
  update: InspectionRejectedUpdateInput;
  where: InspectionRejectedWhereUniqueInput;
};


export type MutationUpsertOneKakaoMessageTemplateArgs = {
  create: KakaoMessageTemplateCreateInput;
  update: KakaoMessageTemplateUpdateInput;
  where: KakaoMessageTemplateWhereUniqueInput;
};


export type MutationUpsertOneKakaoSendLogArgs = {
  create: KakaoSendLogCreateInput;
  update: KakaoSendLogUpdateInput;
  where: KakaoSendLogWhereUniqueInput;
};


export type MutationUpsertOneLandscapingSrwArgs = {
  create: LandscapingSrwCreateInput;
  update: LandscapingSrwUpdateInput;
  where: LandscapingSrwWhereUniqueInput;
};


export type MutationUpsertOneMaterialPriceArgs = {
  create: MaterialPriceCreateInput;
  update: MaterialPriceUpdateInput;
  where: MaterialPriceWhereUniqueInput;
};


export type MutationUpsertOneMechanicalSrwArgs = {
  create: MechanicalSrwCreateInput;
  update: MechanicalSrwUpdateInput;
  where: MechanicalSrwWhereUniqueInput;
};


export type MutationUpsertOneMemberArgs = {
  create: MemberCreateInput;
  update: MemberUpdateInput;
  where: MemberWhereUniqueInput;
};


export type MutationUpsertOneMemberEventArgs = {
  create: MemberEventCreateInput;
  update: MemberEventUpdateInput;
  where: MemberEventWhereUniqueInput;
};


export type MutationUpsertOneMilestoneWorkItemArgs = {
  create: MilestoneWorkItemCreateInput;
  update: MilestoneWorkItemUpdateInput;
  where: MilestoneWorkItemWhereUniqueInput;
};


export type MutationUpsertOneModelPropertyArgs = {
  create: ModelPropertyCreateInput;
  update: ModelPropertyUpdateInput;
  where: ModelPropertyWhereUniqueInput;
};


export type MutationUpsertOneModelerArgs = {
  create: ModelerCreateInput;
  update: ModelerUpdateInput;
  where: ModelerWhereUniqueInput;
};


export type MutationUpsertOneNoticeArgs = {
  create: NoticeCreateInput;
  update: NoticeUpdateInput;
  where: NoticeWhereUniqueInput;
};


export type MutationUpsertOneNoticeCommentArgs = {
  create: NoticeCommentCreateInput;
  update: NoticeCommentUpdateInput;
  where: NoticeCommentWhereUniqueInput;
};


export type MutationUpsertOneNoticeTopicArgs = {
  create: NoticeTopicCreateInput;
  update: NoticeTopicUpdateInput;
  where: NoticeTopicWhereUniqueInput;
};


export type MutationUpsertOnePartnerArgs = {
  create: PartnerCreateInput;
  update: PartnerUpdateInput;
  where: PartnerWhereUniqueInput;
};


export type MutationUpsertOnePayTransactionArgs = {
  create: PayTransactionCreateInput;
  update: PayTransactionUpdateInput;
  where: PayTransactionWhereUniqueInput;
};


export type MutationUpsertOnePersonnelArgs = {
  create: PersonnelCreateInput;
  update: PersonnelUpdateInput;
  where: PersonnelWhereUniqueInput;
};


export type MutationUpsertOneProcessSimulationArgs = {
  create: ProcessSimulationCreateInput;
  update: ProcessSimulationUpdateInput;
  where: ProcessSimulationWhereUniqueInput;
};


export type MutationUpsertOneProcessTaskCellArgs = {
  create: ProcessTaskCellCreateInput;
  update: ProcessTaskCellUpdateInput;
  where: ProcessTaskCellWhereUniqueInput;
};


export type MutationUpsertOneProfileArgs = {
  create: ProfileCreateInput;
  update: ProfileUpdateInput;
  where: ProfileWhereUniqueInput;
};


export type MutationUpsertOneProjectArgs = {
  create: ProjectCreateInput;
  update: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpsertOneProjectAssetArgs = {
  create: ProjectAssetCreateInput;
  update: ProjectAssetUpdateInput;
  where: ProjectAssetWhereUniqueInput;
};


export type MutationUpsertOneProjectBoQArgs = {
  create: ProjectBoQCreateInput;
  update: ProjectBoQUpdateInput;
  where: ProjectBoQWhereUniqueInput;
};


export type MutationUpsertOneProjectBoQFilesArgs = {
  create: ProjectBoQFilesCreateInput;
  update: ProjectBoQFilesUpdateInput;
  where: ProjectBoQFilesWhereUniqueInput;
};


export type MutationUpsertOneProjectFloorInfoArgs = {
  create: ProjectFloorInfoCreateInput;
  update: ProjectFloorInfoUpdateInput;
  where: ProjectFloorInfoWhereUniqueInput;
};


export type MutationUpsertOneProjectHistoryArgs = {
  create: ProjectHistoryCreateInput;
  update: ProjectHistoryUpdateInput;
  where: ProjectHistoryWhereUniqueInput;
};


export type MutationUpsertOneProjectMilestoneArgs = {
  create: ProjectMilestoneCreateInput;
  update: ProjectMilestoneUpdateInput;
  where: ProjectMilestoneWhereUniqueInput;
};


export type MutationUpsertOneReplyArgs = {
  create: ReplyCreateInput;
  update: ReplyUpdateInput;
  where: ReplyWhereUniqueInput;
};


export type MutationUpsertOneShareProjectByMemberArgs = {
  create: ShareProjectByMemberCreateInput;
  update: ShareProjectByMemberUpdateInput;
  where: ShareProjectByMemberWhereUniqueInput;
};


export type MutationUpsertOneShareRatioWorkArgs = {
  create: ShareRatioWorkCreateInput;
  update: ShareRatioWorkUpdateInput;
  where: ShareRatioWorkWhereUniqueInput;
};


export type MutationUpsertOneSharedProjectArgs = {
  create: SharedProjectCreateInput;
  update: SharedProjectUpdateInput;
  where: SharedProjectWhereUniqueInput;
};


export type MutationUpsertOneSharedProjectByMemberArgs = {
  create: SharedProjectByMemberCreateInput;
  update: SharedProjectByMemberUpdateInput;
  where: SharedProjectByMemberWhereUniqueInput;
};


export type MutationUpsertOneSharedProjectTokenArgs = {
  create: SharedProjectTokenCreateInput;
  update: SharedProjectTokenUpdateInput;
  where: SharedProjectTokenWhereUniqueInput;
};


export type MutationUpsertOneSpaceDesignArgs = {
  create: SpaceDesignCreateInput;
  update: SpaceDesignUpdateInput;
  where: SpaceDesignWhereUniqueInput;
};


export type MutationUpsertOneStandardBoQArgs = {
  create: StandardBoQCreateInput;
  update: StandardBoQUpdateInput;
  where: StandardBoQWhereUniqueInput;
};


export type MutationUpsertOneStandardBoQFilesArgs = {
  create: StandardBoQFilesCreateInput;
  update: StandardBoQFilesUpdateInput;
  where: StandardBoQFilesWhereUniqueInput;
};


export type MutationUpsertOneSupplierArgs = {
  create: SupplierCreateInput;
  update: SupplierUpdateInput;
  where: SupplierWhereUniqueInput;
};


export type MutationUpsertOneTaskArgs = {
  create: TaskCreateInput;
  update: TaskUpdateInput;
  where: TaskWhereUniqueInput;
};


export type MutationUpsertOneVatInvoiceArgs = {
  create: VatInvoiceCreateInput;
  update: VatInvoiceUpdateInput;
  where: VatInvoiceWhereUniqueInput;
};


export type MutationUpsertOneWithdrawReasonArgs = {
  create: WithdrawReasonCreateInput;
  update: WithdrawReasonUpdateInput;
  where: WithdrawReasonWhereUniqueInput;
};

export interface NestedBigIntFilter {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
}

export interface NestedBigIntNullableFilter {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
}

export interface NestedBigIntNullableWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBigIntNullableFilter>;
  _min?: InputMaybe<NestedBigIntNullableFilter>;
  _sum?: InputMaybe<NestedBigIntNullableFilter>;
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
}

export interface NestedBigIntWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBigIntFilter>;
  _min?: InputMaybe<NestedBigIntFilter>;
  _sum?: InputMaybe<NestedBigIntFilter>;
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
}

export interface NestedBoolFilter {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
}

export interface NestedBoolNullableFilter {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
}

export interface NestedBoolNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
}

export interface NestedBoolWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
}

export interface NestedDateTimeFilter {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
}

export interface NestedDateTimeNullableFilter {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
}

export interface NestedDateTimeNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
}

export interface NestedDateTimeWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
}

export interface NestedEnumAccessTypeFilter {
  equals?: InputMaybe<AccessType>;
  in?: InputMaybe<Array<InputMaybe<AccessType>>>;
  not?: InputMaybe<NestedEnumAccessTypeFilter>;
  notIn?: InputMaybe<Array<InputMaybe<AccessType>>>;
}

export interface NestedEnumAccessTypeWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAccessTypeFilter>;
  _min?: InputMaybe<NestedEnumAccessTypeFilter>;
  equals?: InputMaybe<AccessType>;
  in?: InputMaybe<Array<InputMaybe<AccessType>>>;
  not?: InputMaybe<NestedEnumAccessTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<AccessType>>>;
}

export interface NestedEnumAdminRoleFilter {
  equals?: InputMaybe<AdminRole>;
  in?: InputMaybe<Array<InputMaybe<AdminRole>>>;
  not?: InputMaybe<NestedEnumAdminRoleFilter>;
  notIn?: InputMaybe<Array<InputMaybe<AdminRole>>>;
}

export interface NestedEnumAdminRoleWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumAdminRoleFilter>;
  _min?: InputMaybe<NestedEnumAdminRoleFilter>;
  equals?: InputMaybe<AdminRole>;
  in?: InputMaybe<Array<InputMaybe<AdminRole>>>;
  not?: InputMaybe<NestedEnumAdminRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<AdminRole>>>;
}

export interface NestedEnumArchitecturalWorkCategoryFilter {
  equals?: InputMaybe<ArchitecturalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<ArchitecturalWorkCategory>>>;
  not?: InputMaybe<NestedEnumArchitecturalWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ArchitecturalWorkCategory>>>;
}

export interface NestedEnumArchitecturalWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumArchitecturalWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumArchitecturalWorkCategoryFilter>;
  equals?: InputMaybe<ArchitecturalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<ArchitecturalWorkCategory>>>;
  not?: InputMaybe<NestedEnumArchitecturalWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ArchitecturalWorkCategory>>>;
}

export interface NestedEnumCashReceiptStatusFilter {
  equals?: InputMaybe<CashReceiptStatus>;
  in?: InputMaybe<Array<InputMaybe<CashReceiptStatus>>>;
  not?: InputMaybe<NestedEnumCashReceiptStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CashReceiptStatus>>>;
}

export interface NestedEnumCashReceiptStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCashReceiptStatusFilter>;
  _min?: InputMaybe<NestedEnumCashReceiptStatusFilter>;
  equals?: InputMaybe<CashReceiptStatus>;
  in?: InputMaybe<Array<InputMaybe<CashReceiptStatus>>>;
  not?: InputMaybe<NestedEnumCashReceiptStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CashReceiptStatus>>>;
}

export interface NestedEnumCashReceiptTypeFilter {
  equals?: InputMaybe<CashReceiptType>;
  in?: InputMaybe<Array<InputMaybe<CashReceiptType>>>;
  not?: InputMaybe<NestedEnumCashReceiptTypeFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CashReceiptType>>>;
}

export interface NestedEnumCashReceiptTypeWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCashReceiptTypeFilter>;
  _min?: InputMaybe<NestedEnumCashReceiptTypeFilter>;
  equals?: InputMaybe<CashReceiptType>;
  in?: InputMaybe<Array<InputMaybe<CashReceiptType>>>;
  not?: InputMaybe<NestedEnumCashReceiptTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CashReceiptType>>>;
}

export interface NestedEnumCivilWorkCategoryFilter {
  equals?: InputMaybe<CivilWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<CivilWorkCategory>>>;
  not?: InputMaybe<NestedEnumCivilWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CivilWorkCategory>>>;
}

export interface NestedEnumCivilWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCivilWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumCivilWorkCategoryFilter>;
  equals?: InputMaybe<CivilWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<CivilWorkCategory>>>;
  not?: InputMaybe<NestedEnumCivilWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CivilWorkCategory>>>;
}

export interface NestedEnumCommunicationWorkCategoryFilter {
  equals?: InputMaybe<CommunicationWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<CommunicationWorkCategory>>>;
  not?: InputMaybe<NestedEnumCommunicationWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CommunicationWorkCategory>>>;
}

export interface NestedEnumCommunicationWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCommunicationWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumCommunicationWorkCategoryFilter>;
  equals?: InputMaybe<CommunicationWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<CommunicationWorkCategory>>>;
  not?: InputMaybe<NestedEnumCommunicationWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CommunicationWorkCategory>>>;
}

export interface NestedEnumCustomerRoleFilter {
  equals?: InputMaybe<CustomerRole>;
  in?: InputMaybe<Array<InputMaybe<CustomerRole>>>;
  not?: InputMaybe<NestedEnumCustomerRoleFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CustomerRole>>>;
}

export interface NestedEnumCustomerRoleWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumCustomerRoleFilter>;
  _min?: InputMaybe<NestedEnumCustomerRoleFilter>;
  equals?: InputMaybe<CustomerRole>;
  in?: InputMaybe<Array<InputMaybe<CustomerRole>>>;
  not?: InputMaybe<NestedEnumCustomerRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<CustomerRole>>>;
}

export interface NestedEnumDocumentTypeFilter {
  equals?: InputMaybe<DocumentType>;
  in?: InputMaybe<Array<InputMaybe<DocumentType>>>;
  not?: InputMaybe<NestedEnumDocumentTypeFilter>;
  notIn?: InputMaybe<Array<InputMaybe<DocumentType>>>;
}

export interface NestedEnumDocumentTypeWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumDocumentTypeFilter>;
  _min?: InputMaybe<NestedEnumDocumentTypeFilter>;
  equals?: InputMaybe<DocumentType>;
  in?: InputMaybe<Array<InputMaybe<DocumentType>>>;
  not?: InputMaybe<NestedEnumDocumentTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<DocumentType>>>;
}

export interface NestedEnumElectricalWorkCategoryFilter {
  equals?: InputMaybe<ElectricalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<ElectricalWorkCategory>>>;
  not?: InputMaybe<NestedEnumElectricalWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ElectricalWorkCategory>>>;
}

export interface NestedEnumElectricalWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumElectricalWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumElectricalWorkCategoryFilter>;
  equals?: InputMaybe<ElectricalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<ElectricalWorkCategory>>>;
  not?: InputMaybe<NestedEnumElectricalWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ElectricalWorkCategory>>>;
}

export interface NestedEnumFireProtectionWorkCategoryFilter {
  equals?: InputMaybe<FireProtectionWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<FireProtectionWorkCategory>>>;
  not?: InputMaybe<NestedEnumFireProtectionWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<FireProtectionWorkCategory>>>;
}

export interface NestedEnumFireProtectionWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumFireProtectionWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumFireProtectionWorkCategoryFilter>;
  equals?: InputMaybe<FireProtectionWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<FireProtectionWorkCategory>>>;
  not?: InputMaybe<NestedEnumFireProtectionWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<FireProtectionWorkCategory>>>;
}

export interface NestedEnumIdentityGenderNullableFilter {
  equals?: InputMaybe<IdentityGender>;
  in?: InputMaybe<Array<InputMaybe<IdentityGender>>>;
  not?: InputMaybe<NestedEnumIdentityGenderNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<IdentityGender>>>;
}

export interface NestedEnumIdentityGenderNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumIdentityGenderNullableFilter>;
  _min?: InputMaybe<NestedEnumIdentityGenderNullableFilter>;
  equals?: InputMaybe<IdentityGender>;
  in?: InputMaybe<Array<InputMaybe<IdentityGender>>>;
  not?: InputMaybe<NestedEnumIdentityGenderNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<IdentityGender>>>;
}

export interface NestedEnumInquiryTypeFilter {
  equals?: InputMaybe<InquiryType>;
  in?: InputMaybe<Array<InputMaybe<InquiryType>>>;
  not?: InputMaybe<NestedEnumInquiryTypeFilter>;
  notIn?: InputMaybe<Array<InputMaybe<InquiryType>>>;
}

export interface NestedEnumInquiryTypeWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumInquiryTypeFilter>;
  _min?: InputMaybe<NestedEnumInquiryTypeFilter>;
  equals?: InputMaybe<InquiryType>;
  in?: InputMaybe<Array<InputMaybe<InquiryType>>>;
  not?: InputMaybe<NestedEnumInquiryTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<InquiryType>>>;
}

export interface NestedEnumKakaoTemplateFilter {
  equals?: InputMaybe<KakaoTemplate>;
  in?: InputMaybe<Array<InputMaybe<KakaoTemplate>>>;
  not?: InputMaybe<NestedEnumKakaoTemplateFilter>;
  notIn?: InputMaybe<Array<InputMaybe<KakaoTemplate>>>;
}

export interface NestedEnumKakaoTemplateWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumKakaoTemplateFilter>;
  _min?: InputMaybe<NestedEnumKakaoTemplateFilter>;
  equals?: InputMaybe<KakaoTemplate>;
  in?: InputMaybe<Array<InputMaybe<KakaoTemplate>>>;
  not?: InputMaybe<NestedEnumKakaoTemplateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<KakaoTemplate>>>;
}

export interface NestedEnumLandscapingWorkCategoryFilter {
  equals?: InputMaybe<LandscapingWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<LandscapingWorkCategory>>>;
  not?: InputMaybe<NestedEnumLandscapingWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<LandscapingWorkCategory>>>;
}

export interface NestedEnumLandscapingWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumLandscapingWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumLandscapingWorkCategoryFilter>;
  equals?: InputMaybe<LandscapingWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<LandscapingWorkCategory>>>;
  not?: InputMaybe<NestedEnumLandscapingWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<LandscapingWorkCategory>>>;
}

export interface NestedEnumMainWorkCategoryFilter {
  equals?: InputMaybe<MainWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<MainWorkCategory>>>;
  not?: InputMaybe<NestedEnumMainWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MainWorkCategory>>>;
}

export interface NestedEnumMainWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMainWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumMainWorkCategoryFilter>;
  equals?: InputMaybe<MainWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<MainWorkCategory>>>;
  not?: InputMaybe<NestedEnumMainWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MainWorkCategory>>>;
}

export interface NestedEnumMechanicalWorkCategoryFilter {
  equals?: InputMaybe<MechanicalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<MechanicalWorkCategory>>>;
  not?: InputMaybe<NestedEnumMechanicalWorkCategoryFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MechanicalWorkCategory>>>;
}

export interface NestedEnumMechanicalWorkCategoryWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMechanicalWorkCategoryFilter>;
  _min?: InputMaybe<NestedEnumMechanicalWorkCategoryFilter>;
  equals?: InputMaybe<MechanicalWorkCategory>;
  in?: InputMaybe<Array<InputMaybe<MechanicalWorkCategory>>>;
  not?: InputMaybe<NestedEnumMechanicalWorkCategoryWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MechanicalWorkCategory>>>;
}

export interface NestedEnumMemberStatusFilter {
  equals?: InputMaybe<MemberStatus>;
  in?: InputMaybe<Array<InputMaybe<MemberStatus>>>;
  not?: InputMaybe<NestedEnumMemberStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MemberStatus>>>;
}

export interface NestedEnumMemberStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMemberStatusFilter>;
  _min?: InputMaybe<NestedEnumMemberStatusFilter>;
  equals?: InputMaybe<MemberStatus>;
  in?: InputMaybe<Array<InputMaybe<MemberStatus>>>;
  not?: InputMaybe<NestedEnumMemberStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MemberStatus>>>;
}

export interface NestedEnumMilestoneWorkFilter {
  equals?: InputMaybe<MilestoneWork>;
  in?: InputMaybe<Array<InputMaybe<MilestoneWork>>>;
  not?: InputMaybe<NestedEnumMilestoneWorkFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MilestoneWork>>>;
}

export interface NestedEnumMilestoneWorkWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMilestoneWorkFilter>;
  _min?: InputMaybe<NestedEnumMilestoneWorkFilter>;
  equals?: InputMaybe<MilestoneWork>;
  in?: InputMaybe<Array<InputMaybe<MilestoneWork>>>;
  not?: InputMaybe<NestedEnumMilestoneWorkWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<MilestoneWork>>>;
}

export interface NestedEnumPartnerRegistrationStatusFilter {
  equals?: InputMaybe<PartnerRegistrationStatus>;
  in?: InputMaybe<Array<InputMaybe<PartnerRegistrationStatus>>>;
  not?: InputMaybe<NestedEnumPartnerRegistrationStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<PartnerRegistrationStatus>>>;
}

export interface NestedEnumPartnerRegistrationStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPartnerRegistrationStatusFilter>;
  _min?: InputMaybe<NestedEnumPartnerRegistrationStatusFilter>;
  equals?: InputMaybe<PartnerRegistrationStatus>;
  in?: InputMaybe<Array<InputMaybe<PartnerRegistrationStatus>>>;
  not?: InputMaybe<NestedEnumPartnerRegistrationStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<PartnerRegistrationStatus>>>;
}

export interface NestedEnumPartnerRoleFilter {
  equals?: InputMaybe<PartnerRole>;
  in?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  not?: InputMaybe<NestedEnumPartnerRoleFilter>;
  notIn?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
}

export interface NestedEnumPartnerRoleWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPartnerRoleFilter>;
  _min?: InputMaybe<NestedEnumPartnerRoleFilter>;
  equals?: InputMaybe<PartnerRole>;
  in?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  not?: InputMaybe<NestedEnumPartnerRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
}

export interface NestedEnumProfileStatusFilter {
  equals?: InputMaybe<ProfileStatus>;
  in?: InputMaybe<Array<InputMaybe<ProfileStatus>>>;
  not?: InputMaybe<NestedEnumProfileStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProfileStatus>>>;
}

export interface NestedEnumProfileStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProfileStatusFilter>;
  _min?: InputMaybe<NestedEnumProfileStatusFilter>;
  equals?: InputMaybe<ProfileStatus>;
  in?: InputMaybe<Array<InputMaybe<ProfileStatus>>>;
  not?: InputMaybe<NestedEnumProfileStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProfileStatus>>>;
}

export interface NestedEnumProjectLevelNullableFilter {
  equals?: InputMaybe<ProjectLevel>;
  in?: InputMaybe<Array<InputMaybe<ProjectLevel>>>;
  not?: InputMaybe<NestedEnumProjectLevelNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectLevel>>>;
}

export interface NestedEnumProjectLevelNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProjectLevelNullableFilter>;
  _min?: InputMaybe<NestedEnumProjectLevelNullableFilter>;
  equals?: InputMaybe<ProjectLevel>;
  in?: InputMaybe<Array<InputMaybe<ProjectLevel>>>;
  not?: InputMaybe<NestedEnumProjectLevelNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectLevel>>>;
}

export interface NestedEnumProjectModeNullableFilter {
  equals?: InputMaybe<ProjectMode>;
  in?: InputMaybe<Array<InputMaybe<ProjectMode>>>;
  not?: InputMaybe<NestedEnumProjectModeNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectMode>>>;
}

export interface NestedEnumProjectModeNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProjectModeNullableFilter>;
  _min?: InputMaybe<NestedEnumProjectModeNullableFilter>;
  equals?: InputMaybe<ProjectMode>;
  in?: InputMaybe<Array<InputMaybe<ProjectMode>>>;
  not?: InputMaybe<NestedEnumProjectModeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectMode>>>;
}

export interface NestedEnumProjectOwnerTypeNullableFilter {
  equals?: InputMaybe<ProjectOwnerType>;
  in?: InputMaybe<Array<InputMaybe<ProjectOwnerType>>>;
  not?: InputMaybe<NestedEnumProjectOwnerTypeNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectOwnerType>>>;
}

export interface NestedEnumProjectOwnerTypeNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProjectOwnerTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumProjectOwnerTypeNullableFilter>;
  equals?: InputMaybe<ProjectOwnerType>;
  in?: InputMaybe<Array<InputMaybe<ProjectOwnerType>>>;
  not?: InputMaybe<NestedEnumProjectOwnerTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectOwnerType>>>;
}

export interface NestedEnumProjectPermissionFilter {
  equals?: InputMaybe<ProjectPermission>;
  in?: InputMaybe<Array<InputMaybe<ProjectPermission>>>;
  not?: InputMaybe<NestedEnumProjectPermissionFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectPermission>>>;
}

export interface NestedEnumProjectPermissionWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProjectPermissionFilter>;
  _min?: InputMaybe<NestedEnumProjectPermissionFilter>;
  equals?: InputMaybe<ProjectPermission>;
  in?: InputMaybe<Array<InputMaybe<ProjectPermission>>>;
  not?: InputMaybe<NestedEnumProjectPermissionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectPermission>>>;
}

export interface NestedEnumProjectStatusFilter {
  equals?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
  not?: InputMaybe<NestedEnumProjectStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
}

export interface NestedEnumProjectStatusNullableFilter {
  equals?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
  not?: InputMaybe<NestedEnumProjectStatusNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
}

export interface NestedEnumProjectStatusNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProjectStatusNullableFilter>;
  _min?: InputMaybe<NestedEnumProjectStatusNullableFilter>;
  equals?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
  not?: InputMaybe<NestedEnumProjectStatusNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
}

export interface NestedEnumProjectStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumProjectStatusFilter>;
  _min?: InputMaybe<NestedEnumProjectStatusFilter>;
  equals?: InputMaybe<ProjectStatus>;
  in?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
  not?: InputMaybe<NestedEnumProjectStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
}

export interface NestedEnumProjectTypeNullableFilter {
  equals?: InputMaybe<ProjectType>;
  in?: InputMaybe<Array<InputMaybe<ProjectType>>>;
  not?: InputMaybe<NestedEnumProjectTypeNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectType>>>;
}

export interface NestedEnumProjectTypeNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumProjectTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumProjectTypeNullableFilter>;
  equals?: InputMaybe<ProjectType>;
  in?: InputMaybe<Array<InputMaybe<ProjectType>>>;
  not?: InputMaybe<NestedEnumProjectTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ProjectType>>>;
}

export interface NestedEnumReactionTypeNullableFilter {
  equals?: InputMaybe<ReactionType>;
  in?: InputMaybe<Array<InputMaybe<ReactionType>>>;
  not?: InputMaybe<NestedEnumReactionTypeNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ReactionType>>>;
}

export interface NestedEnumReactionTypeNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumReactionTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumReactionTypeNullableFilter>;
  equals?: InputMaybe<ReactionType>;
  in?: InputMaybe<Array<InputMaybe<ReactionType>>>;
  not?: InputMaybe<NestedEnumReactionTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ReactionType>>>;
}

export interface NestedEnumRejectionStatusFilter {
  equals?: InputMaybe<RejectionStatus>;
  in?: InputMaybe<Array<InputMaybe<RejectionStatus>>>;
  not?: InputMaybe<NestedEnumRejectionStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<RejectionStatus>>>;
}

export interface NestedEnumRejectionStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRejectionStatusFilter>;
  _min?: InputMaybe<NestedEnumRejectionStatusFilter>;
  equals?: InputMaybe<RejectionStatus>;
  in?: InputMaybe<Array<InputMaybe<RejectionStatus>>>;
  not?: InputMaybe<NestedEnumRejectionStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<RejectionStatus>>>;
}

export interface NestedEnumTaskStatusFilter {
  equals?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<InputMaybe<TaskStatus>>>;
  not?: InputMaybe<NestedEnumTaskStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<TaskStatus>>>;
}

export interface NestedEnumTaskStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumTaskStatusFilter>;
  _min?: InputMaybe<NestedEnumTaskStatusFilter>;
  equals?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<InputMaybe<TaskStatus>>>;
  not?: InputMaybe<NestedEnumTaskStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<TaskStatus>>>;
}

export interface NestedEnumThirdPartyIdFilter {
  equals?: InputMaybe<ThirdPartyId>;
  in?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
  not?: InputMaybe<NestedEnumThirdPartyIdFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
}

export interface NestedEnumThirdPartyIdNullableFilter {
  equals?: InputMaybe<ThirdPartyId>;
  in?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
  not?: InputMaybe<NestedEnumThirdPartyIdNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
}

export interface NestedEnumThirdPartyIdNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumThirdPartyIdNullableFilter>;
  _min?: InputMaybe<NestedEnumThirdPartyIdNullableFilter>;
  equals?: InputMaybe<ThirdPartyId>;
  in?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
  not?: InputMaybe<NestedEnumThirdPartyIdNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
}

export interface NestedEnumThirdPartyIdWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumThirdPartyIdFilter>;
  _min?: InputMaybe<NestedEnumThirdPartyIdFilter>;
  equals?: InputMaybe<ThirdPartyId>;
  in?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
  not?: InputMaybe<NestedEnumThirdPartyIdWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<ThirdPartyId>>>;
}

export interface NestedEnumUploadOptionFilter {
  equals?: InputMaybe<UploadOption>;
  in?: InputMaybe<Array<InputMaybe<UploadOption>>>;
  not?: InputMaybe<NestedEnumUploadOptionFilter>;
  notIn?: InputMaybe<Array<InputMaybe<UploadOption>>>;
}

export interface NestedEnumUploadOptionWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUploadOptionFilter>;
  _min?: InputMaybe<NestedEnumUploadOptionFilter>;
  equals?: InputMaybe<UploadOption>;
  in?: InputMaybe<Array<InputMaybe<UploadOption>>>;
  not?: InputMaybe<NestedEnumUploadOptionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<UploadOption>>>;
}

export interface NestedEnumUploadStatusFilter {
  equals?: InputMaybe<UploadStatus>;
  in?: InputMaybe<Array<InputMaybe<UploadStatus>>>;
  not?: InputMaybe<NestedEnumUploadStatusFilter>;
  notIn?: InputMaybe<Array<InputMaybe<UploadStatus>>>;
}

export interface NestedEnumUploadStatusWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumUploadStatusFilter>;
  _min?: InputMaybe<NestedEnumUploadStatusFilter>;
  equals?: InputMaybe<UploadStatus>;
  in?: InputMaybe<Array<InputMaybe<UploadStatus>>>;
  not?: InputMaybe<NestedEnumUploadStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<UploadStatus>>>;
}

export interface NestedFloatFilter {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
}

export interface NestedFloatNullableFilter {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
}

export interface NestedFloatNullableWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
}

export interface NestedFloatWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
}

export interface NestedIntFilter {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
}

export interface NestedIntNullableFilter {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
}

export interface NestedIntNullableWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
}

export interface NestedIntWithAggregatesFilter {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
}

export interface NestedJsonFilter {
  array_contains?: InputMaybe<Scalars['Json']['input']>;
  array_ends_with?: InputMaybe<Scalars['Json']['input']>;
  array_starts_with?: InputMaybe<Scalars['Json']['input']>;
  equals?: InputMaybe<Scalars['Json']['input']>;
  gt?: InputMaybe<Scalars['Json']['input']>;
  gte?: InputMaybe<Scalars['Json']['input']>;
  lt?: InputMaybe<Scalars['Json']['input']>;
  lte?: InputMaybe<Scalars['Json']['input']>;
  not?: InputMaybe<Scalars['Json']['input']>;
  path?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
}

export interface NestedJsonNullableFilter {
  array_contains?: InputMaybe<Scalars['Json']['input']>;
  array_ends_with?: InputMaybe<Scalars['Json']['input']>;
  array_starts_with?: InputMaybe<Scalars['Json']['input']>;
  equals?: InputMaybe<Scalars['Json']['input']>;
  gt?: InputMaybe<Scalars['Json']['input']>;
  gte?: InputMaybe<Scalars['Json']['input']>;
  lt?: InputMaybe<Scalars['Json']['input']>;
  lte?: InputMaybe<Scalars['Json']['input']>;
  not?: InputMaybe<Scalars['Json']['input']>;
  path?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
}

export interface NestedStringFilter {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
}

export interface NestedStringNullableFilter {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
}

export interface NestedStringNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
}

export interface NestedStringWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
}

/** 비밀번호 변경을 위한 Payload */
export interface NewPassPayload {
  /** 새 비밀번호 */
  newPass: Scalars['String']['input'];
  /** 기존 비밀번호 */
  oldPass: Scalars['String']['input'];
}

export interface NewPassStatus {
  __typename?: 'NewPassStatus';
  /** Message */
  message: Scalars['String']['output'];
  /** OK or Error */
  status: Scalars['String']['output'];
}

export interface Notice {
  __typename?: 'Notice';
  content: Scalars['String']['output'];
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  important: Scalars['Boolean']['output'];
  originalFileName?: Maybe<Scalars['String']['output']>;
  /** 저장 상태 false: 임시저장 true: 완료  */
  storageStatus: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  /** Unique ID */
  uuid: Scalars['String']['output'];
  views: Scalars['Int']['output'];
}

export interface NoticeAvgAggregateOutputType {
  __typename?: 'NoticeAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
}

export interface NoticeAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  views?: InputMaybe<SortOrder>;
}

/** 공지사항 코멘트 */
export interface NoticeComment {
  __typename?: 'NoticeComment';
  /** author */
  author: Member;
  /** Content */
  content: Scalars['String']['output'];
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID String CUID */
  id: Scalars['String']['output'];
  memberId: Scalars['Int']['output'];
  /** 공지사항 */
  noticeTopic: NoticeTopic;
  noticeTopicId: Scalars['String']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}

export interface NoticeCommentAvgAggregateOutputType {
  __typename?: 'NoticeCommentAvgAggregateOutputType';
  memberId?: Maybe<Scalars['Float']['output']>;
}

export interface NoticeCommentAvgOrderByAggregateInput {
  memberId?: InputMaybe<SortOrder>;
}

export interface NoticeCommentCountAggregateOutputType {
  __typename?: 'NoticeCommentCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
  noticeTopicId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface NoticeCommentCountOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  noticeTopicId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface NoticeCommentCreateInput {
  author: MemberCreateNestedOneWithoutNoticeCommentInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  noticeTopic: NoticeTopicCreateNestedOneWithoutNoticeCommentInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeCommentCreateManyAuthorInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  noticeTopicId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeCommentCreateManyAuthorInputEnvelope {
  data: NoticeCommentCreateManyAuthorInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface NoticeCommentCreateManyInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['Int']['input'];
  noticeTopicId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeCommentCreateManyNoticeTopicInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeCommentCreateManyNoticeTopicInputEnvelope {
  data: NoticeCommentCreateManyNoticeTopicInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface NoticeCommentCreateNestedManyWithoutAuthorInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeCommentCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeCommentCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<NoticeCommentCreateManyAuthorInputEnvelope>;
}

export interface NoticeCommentCreateNestedManyWithoutNoticeTopicInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeCommentCreateOrConnectWithoutNoticeTopicInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeCommentCreateWithoutNoticeTopicInput>>>;
  createMany?: InputMaybe<NoticeCommentCreateManyNoticeTopicInputEnvelope>;
}

export interface NoticeCommentCreateOrConnectWithoutAuthorInput {
  create: NoticeCommentUncheckedCreateWithoutAuthorInput;
  where: NoticeCommentWhereUniqueInput;
}

export interface NoticeCommentCreateOrConnectWithoutNoticeTopicInput {
  create: NoticeCommentUncheckedCreateWithoutNoticeTopicInput;
  where: NoticeCommentWhereUniqueInput;
}

export interface NoticeCommentCreateWithoutAuthorInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  noticeTopic: NoticeTopicCreateNestedOneWithoutNoticeCommentInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeCommentCreateWithoutNoticeTopicInput {
  author: MemberCreateNestedOneWithoutNoticeCommentInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeCommentListRelationFilter {
  every?: InputMaybe<NoticeCommentWhereInput>;
  none?: InputMaybe<NoticeCommentWhereInput>;
  some?: InputMaybe<NoticeCommentWhereInput>;
}

export interface NoticeCommentMaxAggregateOutputType {
  __typename?: 'NoticeCommentMaxAggregateOutputType';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  noticeTopicId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface NoticeCommentMaxOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  noticeTopicId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface NoticeCommentMinAggregateOutputType {
  __typename?: 'NoticeCommentMinAggregateOutputType';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  noticeTopicId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface NoticeCommentMinOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  noticeTopicId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface NoticeCommentOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface NoticeCommentOrderByWithAggregationInput {
  _avg?: InputMaybe<NoticeCommentAvgOrderByAggregateInput>;
  _count?: InputMaybe<NoticeCommentCountOrderByAggregateInput>;
  _max?: InputMaybe<NoticeCommentMaxOrderByAggregateInput>;
  _min?: InputMaybe<NoticeCommentMinOrderByAggregateInput>;
  _sum?: InputMaybe<NoticeCommentSumOrderByAggregateInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  noticeTopicId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface NoticeCommentOrderByWithRelationInput {
  author?: InputMaybe<MemberOrderByWithRelationInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  noticeTopic?: InputMaybe<NoticeTopicOrderByWithRelationInput>;
  noticeTopicId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export enum NoticeCommentScalarFieldEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  Id = 'id',
  MemberId = 'memberId',
  NoticeTopicId = 'noticeTopicId',
  UpdatedAt = 'updatedAt'
}

export interface NoticeCommentScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<NoticeCommentScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<NoticeCommentScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<NoticeCommentScalarWhereInput>>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  memberId?: InputMaybe<IntFilter>;
  noticeTopicId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface NoticeCommentScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<NoticeCommentScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<NoticeCommentScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<NoticeCommentScalarWhereWithAggregatesInput>>>;
  content?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  memberId?: InputMaybe<IntWithAggregatesFilter>;
  noticeTopicId?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface NoticeCommentSumAggregateOutputType {
  __typename?: 'NoticeCommentSumAggregateOutputType';
  memberId?: Maybe<Scalars['Int']['output']>;
}

export interface NoticeCommentSumOrderByAggregateInput {
  memberId?: InputMaybe<SortOrder>;
}

export interface NoticeCommentUncheckedCreateInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['Int']['input'];
  noticeTopicId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeCommentUncheckedCreateNestedManyWithoutAuthorInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeCommentCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeCommentCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<NoticeCommentCreateManyAuthorInputEnvelope>;
}

export interface NoticeCommentUncheckedCreateNestedManyWithoutNoticeTopicInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeCommentCreateOrConnectWithoutNoticeTopicInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeCommentCreateWithoutNoticeTopicInput>>>;
  createMany?: InputMaybe<NoticeCommentCreateManyNoticeTopicInputEnvelope>;
}

export interface NoticeCommentUncheckedCreateWithoutAuthorInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  noticeTopicId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeCommentUncheckedCreateWithoutNoticeTopicInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeCommentUncheckedUpdateInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  noticeTopicId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeCommentUncheckedUpdateManyInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  noticeTopicId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeCommentUncheckedUpdateManyWithoutAuthorNestedInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeCommentCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeCommentCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<NoticeCommentCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<NoticeCommentScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<NoticeCommentUpdateWithWhereUniqueWithoutAuthorInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<NoticeCommentUpdateManyWithWhereWithoutAuthorInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<NoticeCommentUpsertWithWhereUniqueWithoutAuthorInput>>>;
}

export interface NoticeCommentUncheckedUpdateManyWithoutNoticeCommentInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  noticeTopicId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeCommentUncheckedUpdateManyWithoutNoticeTopicNestedInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeCommentCreateOrConnectWithoutNoticeTopicInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeCommentCreateWithoutNoticeTopicInput>>>;
  createMany?: InputMaybe<NoticeCommentCreateManyNoticeTopicInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<NoticeCommentScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<NoticeCommentUpdateWithWhereUniqueWithoutNoticeTopicInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<NoticeCommentUpdateManyWithWhereWithoutNoticeTopicInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<NoticeCommentUpsertWithWhereUniqueWithoutNoticeTopicInput>>>;
}

export interface NoticeCommentUncheckedUpdateWithoutAuthorInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  noticeTopicId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeCommentUncheckedUpdateWithoutNoticeTopicInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeCommentUpdateInput {
  author?: InputMaybe<MemberUpdateOneRequiredWithoutNoticeCommentNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateOneRequiredWithoutNoticeCommentNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeCommentUpdateManyMutationInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeCommentUpdateManyWithWhereWithoutAuthorInput {
  data: NoticeCommentUncheckedUpdateManyWithoutNoticeCommentInput;
  where: NoticeCommentScalarWhereInput;
}

export interface NoticeCommentUpdateManyWithWhereWithoutNoticeTopicInput {
  data: NoticeCommentUncheckedUpdateManyWithoutNoticeCommentInput;
  where: NoticeCommentScalarWhereInput;
}

export interface NoticeCommentUpdateManyWithoutAuthorNestedInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeCommentCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeCommentCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<NoticeCommentCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<NoticeCommentScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<NoticeCommentUpdateWithWhereUniqueWithoutAuthorInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<NoticeCommentUpdateManyWithWhereWithoutAuthorInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<NoticeCommentUpsertWithWhereUniqueWithoutAuthorInput>>>;
}

export interface NoticeCommentUpdateManyWithoutNoticeTopicNestedInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeCommentCreateOrConnectWithoutNoticeTopicInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeCommentCreateWithoutNoticeTopicInput>>>;
  createMany?: InputMaybe<NoticeCommentCreateManyNoticeTopicInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<NoticeCommentScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<NoticeCommentWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<NoticeCommentUpdateWithWhereUniqueWithoutNoticeTopicInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<NoticeCommentUpdateManyWithWhereWithoutNoticeTopicInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<NoticeCommentUpsertWithWhereUniqueWithoutNoticeTopicInput>>>;
}

export interface NoticeCommentUpdateWithWhereUniqueWithoutAuthorInput {
  data: NoticeCommentUncheckedUpdateWithoutAuthorInput;
  where: NoticeCommentWhereUniqueInput;
}

export interface NoticeCommentUpdateWithWhereUniqueWithoutNoticeTopicInput {
  data: NoticeCommentUncheckedUpdateWithoutNoticeTopicInput;
  where: NoticeCommentWhereUniqueInput;
}

export interface NoticeCommentUpdateWithoutAuthorInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  noticeTopic?: InputMaybe<NoticeTopicUpdateOneRequiredWithoutNoticeCommentNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeCommentUpdateWithoutNoticeTopicInput {
  author?: InputMaybe<MemberUpdateOneRequiredWithoutNoticeCommentNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeCommentUpsertWithWhereUniqueWithoutAuthorInput {
  create: NoticeCommentUncheckedCreateWithoutAuthorInput;
  update: NoticeCommentUncheckedUpdateWithoutAuthorInput;
  where: NoticeCommentWhereUniqueInput;
}

export interface NoticeCommentUpsertWithWhereUniqueWithoutNoticeTopicInput {
  create: NoticeCommentUncheckedCreateWithoutNoticeTopicInput;
  update: NoticeCommentUncheckedUpdateWithoutNoticeTopicInput;
  where: NoticeCommentWhereUniqueInput;
}

export interface NoticeCommentWhereInput {
  AND?: InputMaybe<Array<InputMaybe<NoticeCommentWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<NoticeCommentWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<NoticeCommentWhereInput>>>;
  author?: InputMaybe<MemberWhereInput>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  memberId?: InputMaybe<IntFilter>;
  noticeTopic?: InputMaybe<NoticeTopicWhereInput>;
  noticeTopicId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface NoticeCommentWhereUniqueInput {
  id?: InputMaybe<Scalars['String']['input']>;
}

export interface NoticeCountAggregateOutputType {
  __typename?: 'NoticeCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  important: Scalars['Int']['output'];
  storageStatus: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
}

export interface NoticeCountOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  important?: InputMaybe<SortOrder>;
  storageStatus?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  views?: InputMaybe<SortOrder>;
}

export interface NoticeCreateInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  important: Scalars['Boolean']['input'];
  storageStatus?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
}

export interface NoticeCreateManyInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  important: Scalars['Boolean']['input'];
  storageStatus?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
}

export interface NoticeMaxAggregateOutputType {
  __typename?: 'NoticeMaxAggregateOutputType';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  important?: Maybe<Scalars['Boolean']['output']>;
  storageStatus?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface NoticeMaxOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  important?: InputMaybe<SortOrder>;
  storageStatus?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  views?: InputMaybe<SortOrder>;
}

export interface NoticeMinAggregateOutputType {
  __typename?: 'NoticeMinAggregateOutputType';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  important?: Maybe<Scalars['Boolean']['output']>;
  storageStatus?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface NoticeMinOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  important?: InputMaybe<SortOrder>;
  storageStatus?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  views?: InputMaybe<SortOrder>;
}

export interface NoticeOrderByWithAggregationInput {
  _avg?: InputMaybe<NoticeAvgOrderByAggregateInput>;
  _count?: InputMaybe<NoticeCountOrderByAggregateInput>;
  _max?: InputMaybe<NoticeMaxOrderByAggregateInput>;
  _min?: InputMaybe<NoticeMinOrderByAggregateInput>;
  _sum?: InputMaybe<NoticeSumOrderByAggregateInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  important?: InputMaybe<SortOrder>;
  storageStatus?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
  views?: InputMaybe<SortOrder>;
}

export interface NoticeOrderByWithRelationInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  important?: InputMaybe<SortOrder>;
  storageStatus?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
  views?: InputMaybe<SortOrder>;
}

export enum NoticeScalarFieldEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  Id = 'id',
  Important = 'important',
  StorageStatus = 'storageStatus',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  Uuid = 'uuid',
  Views = 'views'
}

export interface NoticeScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<NoticeScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<NoticeScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<NoticeScalarWhereWithAggregatesInput>>>;
  content?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  important?: InputMaybe<BoolWithAggregatesFilter>;
  storageStatus?: InputMaybe<BoolWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  url?: InputMaybe<StringNullableWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  views?: InputMaybe<IntWithAggregatesFilter>;
}

export interface NoticeSumAggregateOutputType {
  __typename?: 'NoticeSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
}

export interface NoticeSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  views?: InputMaybe<SortOrder>;
}

/** 공지사항 */
export interface NoticeTopic {
  __typename?: 'NoticeTopic';
  /** 댓글 */
  NoticeComment: Array<NoticeComment>;
  _count: NoticeTopicCountOutputType;
  /** author */
  author: Member;
  /** Content */
  content: Scalars['String']['output'];
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID String CUID */
  id: Scalars['String']['output'];
  memberId: Scalars['Int']['output'];
  /** Title */
  title: Scalars['String']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}


/** 공지사항 */
export type NoticeTopicNoticeCommentArgs = {
  cursor?: InputMaybe<NoticeCommentWhereUniqueInput>;
  distinct?: InputMaybe<NoticeCommentScalarFieldEnum>;
  orderBy?: InputMaybe<NoticeCommentOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeCommentWhereInput>;
};

export interface NoticeTopicAvgAggregateOutputType {
  __typename?: 'NoticeTopicAvgAggregateOutputType';
  memberId?: Maybe<Scalars['Float']['output']>;
}

export interface NoticeTopicAvgOrderByAggregateInput {
  memberId?: InputMaybe<SortOrder>;
}

export interface NoticeTopicCountAggregateOutputType {
  __typename?: 'NoticeTopicCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface NoticeTopicCountOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface NoticeTopicCountOutputType {
  __typename?: 'NoticeTopicCountOutputType';
  NoticeComment: Scalars['Int']['output'];
}

export interface NoticeTopicCreateInput {
  NoticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutNoticeTopicInput>;
  author: MemberCreateNestedOneWithoutNoticeTopicInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeTopicCreateManyAuthorInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeTopicCreateManyAuthorInputEnvelope {
  data: NoticeTopicCreateManyAuthorInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface NoticeTopicCreateManyInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeTopicCreateNestedManyWithoutAuthorInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeTopicWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeTopicCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeTopicCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<NoticeTopicCreateManyAuthorInputEnvelope>;
}

export interface NoticeTopicCreateNestedOneWithoutNoticeCommentInput {
  connect?: InputMaybe<NoticeTopicWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NoticeTopicCreateOrConnectWithoutNoticeCommentInput>;
  create?: InputMaybe<NoticeTopicUncheckedCreateWithoutNoticeCommentInput>;
}

export interface NoticeTopicCreateOrConnectWithoutAuthorInput {
  create: NoticeTopicUncheckedCreateWithoutAuthorInput;
  where: NoticeTopicWhereUniqueInput;
}

export interface NoticeTopicCreateOrConnectWithoutNoticeCommentInput {
  create: NoticeTopicUncheckedCreateWithoutNoticeCommentInput;
  where: NoticeTopicWhereUniqueInput;
}

export interface NoticeTopicCreateWithoutAuthorInput {
  NoticeComment?: InputMaybe<NoticeCommentCreateNestedManyWithoutNoticeTopicInput>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeTopicCreateWithoutNoticeCommentInput {
  author: MemberCreateNestedOneWithoutNoticeTopicInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeTopicListRelationFilter {
  every?: InputMaybe<NoticeTopicWhereInput>;
  none?: InputMaybe<NoticeTopicWhereInput>;
  some?: InputMaybe<NoticeTopicWhereInput>;
}

export interface NoticeTopicMaxAggregateOutputType {
  __typename?: 'NoticeTopicMaxAggregateOutputType';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface NoticeTopicMaxOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface NoticeTopicMinAggregateOutputType {
  __typename?: 'NoticeTopicMinAggregateOutputType';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface NoticeTopicMinOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface NoticeTopicOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface NoticeTopicOrderByWithAggregationInput {
  _avg?: InputMaybe<NoticeTopicAvgOrderByAggregateInput>;
  _count?: InputMaybe<NoticeTopicCountOrderByAggregateInput>;
  _max?: InputMaybe<NoticeTopicMaxOrderByAggregateInput>;
  _min?: InputMaybe<NoticeTopicMinOrderByAggregateInput>;
  _sum?: InputMaybe<NoticeTopicSumOrderByAggregateInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface NoticeTopicOrderByWithRelationInput {
  NoticeComment?: InputMaybe<NoticeCommentOrderByRelationAggregateInput>;
  author?: InputMaybe<MemberOrderByWithRelationInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface NoticeTopicRelationFilter {
  is?: InputMaybe<NoticeTopicWhereInput>;
  isNot?: InputMaybe<NoticeTopicWhereInput>;
}

export enum NoticeTopicScalarFieldEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  Id = 'id',
  MemberId = 'memberId',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export interface NoticeTopicScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<NoticeTopicScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<NoticeTopicScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<NoticeTopicScalarWhereInput>>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  memberId?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface NoticeTopicScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<NoticeTopicScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<NoticeTopicScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<NoticeTopicScalarWhereWithAggregatesInput>>>;
  content?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  memberId?: InputMaybe<IntWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface NoticeTopicSumAggregateOutputType {
  __typename?: 'NoticeTopicSumAggregateOutputType';
  memberId?: Maybe<Scalars['Int']['output']>;
}

export interface NoticeTopicSumOrderByAggregateInput {
  memberId?: InputMaybe<SortOrder>;
}

export interface NoticeTopicUncheckedCreateInput {
  NoticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutNoticeTopicInput>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeTopicUncheckedCreateNestedManyWithoutAuthorInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeTopicWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeTopicCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeTopicCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<NoticeTopicCreateManyAuthorInputEnvelope>;
}

export interface NoticeTopicUncheckedCreateWithoutAuthorInput {
  NoticeComment?: InputMaybe<NoticeCommentUncheckedCreateNestedManyWithoutNoticeTopicInput>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeTopicUncheckedCreateWithoutNoticeCommentInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NoticeTopicUncheckedUpdateInput {
  NoticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutNoticeTopicNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeTopicUncheckedUpdateManyInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeTopicUncheckedUpdateManyWithoutAuthorNestedInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeTopicWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeTopicCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeTopicCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<NoticeTopicCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<NoticeTopicWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<NoticeTopicScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<NoticeTopicWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<NoticeTopicWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<NoticeTopicUpdateWithWhereUniqueWithoutAuthorInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<NoticeTopicUpdateManyWithWhereWithoutAuthorInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<NoticeTopicUpsertWithWhereUniqueWithoutAuthorInput>>>;
}

export interface NoticeTopicUncheckedUpdateManyWithoutNoticeTopicInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeTopicUncheckedUpdateWithoutAuthorInput {
  NoticeComment?: InputMaybe<NoticeCommentUncheckedUpdateManyWithoutNoticeTopicNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeTopicUncheckedUpdateWithoutNoticeCommentInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeTopicUpdateInput {
  NoticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutNoticeTopicNestedInput>;
  author?: InputMaybe<MemberUpdateOneRequiredWithoutNoticeTopicNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeTopicUpdateManyMutationInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeTopicUpdateManyWithWhereWithoutAuthorInput {
  data: NoticeTopicUncheckedUpdateManyWithoutNoticeTopicInput;
  where: NoticeTopicScalarWhereInput;
}

export interface NoticeTopicUpdateManyWithoutAuthorNestedInput {
  connect?: InputMaybe<Array<InputMaybe<NoticeTopicWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<NoticeTopicCreateOrConnectWithoutAuthorInput>>>;
  create?: InputMaybe<Array<InputMaybe<NoticeTopicCreateWithoutAuthorInput>>>;
  createMany?: InputMaybe<NoticeTopicCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<NoticeTopicWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<NoticeTopicScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<NoticeTopicWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<NoticeTopicWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<NoticeTopicUpdateWithWhereUniqueWithoutAuthorInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<NoticeTopicUpdateManyWithWhereWithoutAuthorInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<NoticeTopicUpsertWithWhereUniqueWithoutAuthorInput>>>;
}

export interface NoticeTopicUpdateOneRequiredWithoutNoticeCommentNestedInput {
  connect?: InputMaybe<NoticeTopicWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NoticeTopicCreateOrConnectWithoutNoticeCommentInput>;
  create?: InputMaybe<NoticeTopicUncheckedCreateWithoutNoticeCommentInput>;
  update?: InputMaybe<NoticeTopicUncheckedUpdateWithoutNoticeCommentInput>;
  upsert?: InputMaybe<NoticeTopicUpsertWithoutNoticeCommentInput>;
}

export interface NoticeTopicUpdateWithWhereUniqueWithoutAuthorInput {
  data: NoticeTopicUncheckedUpdateWithoutAuthorInput;
  where: NoticeTopicWhereUniqueInput;
}

export interface NoticeTopicUpdateWithoutAuthorInput {
  NoticeComment?: InputMaybe<NoticeCommentUpdateManyWithoutNoticeTopicNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeTopicUpdateWithoutNoticeCommentInput {
  author?: InputMaybe<MemberUpdateOneRequiredWithoutNoticeTopicNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface NoticeTopicUpsertWithWhereUniqueWithoutAuthorInput {
  create: NoticeTopicUncheckedCreateWithoutAuthorInput;
  update: NoticeTopicUncheckedUpdateWithoutAuthorInput;
  where: NoticeTopicWhereUniqueInput;
}

export interface NoticeTopicUpsertWithoutNoticeCommentInput {
  create: NoticeTopicUncheckedCreateWithoutNoticeCommentInput;
  update: NoticeTopicUncheckedUpdateWithoutNoticeCommentInput;
}

export interface NoticeTopicWhereInput {
  AND?: InputMaybe<Array<InputMaybe<NoticeTopicWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<NoticeTopicWhereInput>>>;
  NoticeComment?: InputMaybe<NoticeCommentListRelationFilter>;
  OR?: InputMaybe<Array<InputMaybe<NoticeTopicWhereInput>>>;
  author?: InputMaybe<MemberWhereInput>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  memberId?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface NoticeTopicWhereUniqueInput {
  id?: InputMaybe<Scalars['String']['input']>;
}

export interface NoticeUncheckedCreateInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  important: Scalars['Boolean']['input'];
  storageStatus?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
}

export interface NoticeUncheckedUpdateInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  important?: InputMaybe<BoolFieldUpdateOperationsInput>;
  storageStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  views?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface NoticeUncheckedUpdateManyInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  important?: InputMaybe<BoolFieldUpdateOperationsInput>;
  storageStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  views?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface NoticeUpdateInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  important?: InputMaybe<BoolFieldUpdateOperationsInput>;
  storageStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  views?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface NoticeUpdateManyMutationInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  important?: InputMaybe<BoolFieldUpdateOperationsInput>;
  storageStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  views?: InputMaybe<IntFieldUpdateOperationsInput>;
}

export interface NoticeWhereInput {
  AND?: InputMaybe<Array<InputMaybe<NoticeWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<NoticeWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<NoticeWhereInput>>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  important?: InputMaybe<BoolFilter>;
  storageStatus?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
  uuid?: InputMaybe<StringFilter>;
  views?: InputMaybe<IntFilter>;
}

export interface NoticeWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface NullableBigIntFieldUpdateOperationsInput {
  decrement?: InputMaybe<Scalars['BigInt']['input']>;
  divide?: InputMaybe<Scalars['BigInt']['input']>;
  increment?: InputMaybe<Scalars['BigInt']['input']>;
  multiply?: InputMaybe<Scalars['BigInt']['input']>;
  set?: InputMaybe<Scalars['BigInt']['input']>;
}

export interface NullableBoolFieldUpdateOperationsInput {
  set?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface NullableDateTimeFieldUpdateOperationsInput {
  set?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface NullableEnumIdentityGenderFieldUpdateOperationsInput {
  set?: InputMaybe<IdentityGender>;
}

export interface NullableEnumProjectLevelFieldUpdateOperationsInput {
  set?: InputMaybe<ProjectLevel>;
}

export interface NullableEnumProjectModeFieldUpdateOperationsInput {
  set?: InputMaybe<ProjectMode>;
}

export interface NullableEnumProjectOwnerTypeFieldUpdateOperationsInput {
  set?: InputMaybe<ProjectOwnerType>;
}

export interface NullableEnumProjectStatusFieldUpdateOperationsInput {
  set?: InputMaybe<ProjectStatus>;
}

export interface NullableEnumProjectTypeFieldUpdateOperationsInput {
  set?: InputMaybe<ProjectType>;
}

export interface NullableEnumReactionTypeFieldUpdateOperationsInput {
  set?: InputMaybe<ReactionType>;
}

export interface NullableEnumThirdPartyIdFieldUpdateOperationsInput {
  set?: InputMaybe<ThirdPartyId>;
}

export interface NullableFloatFieldUpdateOperationsInput {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
}

export interface NullableIntFieldUpdateOperationsInput {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
}

export enum NullableJsonNullValueInput {
  DbNull = 'DbNull',
  JsonNull = 'JsonNull'
}

export interface NullableStringFieldUpdateOperationsInput {
  set?: InputMaybe<Scalars['String']['input']>;
}

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export interface OpenSearchDocument {
  __typename?: 'OpenSearchDocument';
  BOQCode?: Maybe<Scalars['String']['output']>;
  BarsetIds?: Maybe<Scalars['Int']['output']>;
  BoQItem?: Maybe<BoQItem>;
  BuildingCategory?: Maybe<Scalars['String']['output']>;
  BuildingName?: Maybe<Scalars['String']['output']>;
  BuildingType?: Maybe<Scalars['String']['output']>;
  ConcreteType?: Maybe<Scalars['String']['output']>;
  DataType?: Maybe<Scalars['String']['output']>;
  Diameter?: Maybe<Scalars['Int']['output']>;
  FileName?: Maybe<Scalars['String']['output']>;
  FinItemID?: Maybe<Scalars['Int']['output']>;
  FloorCategory?: Maybe<Scalars['String']['output']>;
  FloorName?: Maybe<Scalars['String']['output']>;
  FloorNum?: Maybe<Scalars['Int']['output']>;
  FloorType?: Maybe<Scalars['String']['output']>;
  FormType?: Maybe<Scalars['String']['output']>;
  Formula?: Maybe<Scalars['String']['output']>;
  MaterialSubcategory?: Maybe<Scalars['String']['output']>;
  Member?: Maybe<Scalars['String']['output']>;
  MemberName?: Maybe<Scalars['String']['output']>;
  MemberType?: Maybe<Scalars['String']['output']>;
  ObjectIds?: Maybe<Scalars['Int']['output']>;
  RebarGrade?: Maybe<Scalars['String']['output']>;
  RebarType?: Maybe<Scalars['String']['output']>;
  Result?: Maybe<Scalars['Float']['output']>;
  Zone?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

export interface OriginalFileInfo {
  __typename?: 'OriginalFileInfo';
  fileName?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  lastModified?: Maybe<Scalars['DateTime']['output']>;
}

/** Partner: 건축설계사, 종합건설사, 전문건설사, 모델링 전문가, 자재업체 */
export interface Partner {
  __typename?: 'Partner';
  Architect: Array<Architect>;
  Construction: Array<Construction>;
  GeneralConstruction: Array<GeneralConstruction>;
  Modeler: Array<Modeler>;
  SpaceDesign: Array<SpaceDesign>;
  Supplier: Array<Supplier>;
  _count: PartnerCountOutputType;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Member */
  member: Array<Member>;
  role: Array<PartnerRole>;
  /** Partner user member status */
  status: MemberStatus;
  /** Partner tasks list */
  tasks: Array<Task>;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique ID */
  uuid: Scalars['String']['output'];
}


/** Partner: 건축설계사, 종합건설사, 전문건설사, 모델링 전문가, 자재업체 */
export type PartnerArchitectArgs = {
  cursor?: InputMaybe<ArchitectWhereUniqueInput>;
  distinct?: InputMaybe<ArchitectScalarFieldEnum>;
  orderBy?: InputMaybe<ArchitectOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectWhereInput>;
};


/** Partner: 건축설계사, 종합건설사, 전문건설사, 모델링 전문가, 자재업체 */
export type PartnerConstructionArgs = {
  cursor?: InputMaybe<ConstructionWhereUniqueInput>;
  distinct?: InputMaybe<ConstructionScalarFieldEnum>;
  orderBy?: InputMaybe<ConstructionOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ConstructionWhereInput>;
};


/** Partner: 건축설계사, 종합건설사, 전문건설사, 모델링 전문가, 자재업체 */
export type PartnerGeneralConstructionArgs = {
  cursor?: InputMaybe<GeneralConstructionWhereUniqueInput>;
  distinct?: InputMaybe<GeneralConstructionScalarFieldEnum>;
  orderBy?: InputMaybe<GeneralConstructionOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GeneralConstructionWhereInput>;
};


/** Partner: 건축설계사, 종합건설사, 전문건설사, 모델링 전문가, 자재업체 */
export type PartnerModelerArgs = {
  cursor?: InputMaybe<ModelerWhereUniqueInput>;
  distinct?: InputMaybe<ModelerScalarFieldEnum>;
  orderBy?: InputMaybe<ModelerOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelerWhereInput>;
};


/** Partner: 건축설계사, 종합건설사, 전문건설사, 모델링 전문가, 자재업체 */
export type PartnerSpaceDesignArgs = {
  cursor?: InputMaybe<SpaceDesignWhereUniqueInput>;
  distinct?: InputMaybe<SpaceDesignScalarFieldEnum>;
  orderBy?: InputMaybe<SpaceDesignOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpaceDesignWhereInput>;
};


/** Partner: 건축설계사, 종합건설사, 전문건설사, 모델링 전문가, 자재업체 */
export type PartnerSupplierArgs = {
  cursor?: InputMaybe<SupplierWhereUniqueInput>;
  distinct?: InputMaybe<SupplierScalarFieldEnum>;
  orderBy?: InputMaybe<SupplierOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SupplierWhereInput>;
};


/** Partner: 건축설계사, 종합건설사, 전문건설사, 모델링 전문가, 자재업체 */
export type PartnerMemberArgs = {
  cursor?: InputMaybe<MemberWhereUniqueInput>;
  distinct?: InputMaybe<MemberScalarFieldEnum>;
  orderBy?: InputMaybe<MemberOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberWhereInput>;
};


/** Partner: 건축설계사, 종합건설사, 전문건설사, 모델링 전문가, 자재업체 */
export type PartnerTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<TaskScalarFieldEnum>;
  orderBy?: InputMaybe<TaskOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};

export interface PartnerAvgAggregateOutputType {
  __typename?: 'PartnerAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
}

export interface PartnerAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface PartnerCountAggregateOutputType {
  __typename?: 'PartnerCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface PartnerCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface PartnerCountOutputType {
  __typename?: 'PartnerCountOutputType';
  Architect: Scalars['Int']['output'];
  Construction: Scalars['Int']['output'];
  GeneralConstruction: Scalars['Int']['output'];
  Modeler: Scalars['Int']['output'];
  SpaceDesign: Scalars['Int']['output'];
  Supplier: Scalars['Int']['output'];
  member: Scalars['Int']['output'];
  tasks: Scalars['Int']['output'];
}

export interface PartnerCreateInput {
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  member?: InputMaybe<MemberCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerCreateNestedOneWithoutArchitectInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutArchitectInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutArchitectInput>;
}

export interface PartnerCreateNestedOneWithoutConstructionInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutConstructionInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutConstructionInput>;
}

export interface PartnerCreateNestedOneWithoutGeneralConstructionInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutGeneralConstructionInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutGeneralConstructionInput>;
}

export interface PartnerCreateNestedOneWithoutMemberInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutMemberInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutMemberInput>;
}

export interface PartnerCreateNestedOneWithoutModelerInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutModelerInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutModelerInput>;
}

export interface PartnerCreateNestedOneWithoutSpaceDesignInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutSpaceDesignInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutSpaceDesignInput>;
}

export interface PartnerCreateNestedOneWithoutSupplierInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutSupplierInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutSupplierInput>;
}

export interface PartnerCreateNestedOneWithoutTasksInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutTasksInput>;
}

export interface PartnerCreateOrConnectWithoutArchitectInput {
  create: PartnerUncheckedCreateWithoutArchitectInput;
  where: PartnerWhereUniqueInput;
}

export interface PartnerCreateOrConnectWithoutConstructionInput {
  create: PartnerUncheckedCreateWithoutConstructionInput;
  where: PartnerWhereUniqueInput;
}

export interface PartnerCreateOrConnectWithoutGeneralConstructionInput {
  create: PartnerUncheckedCreateWithoutGeneralConstructionInput;
  where: PartnerWhereUniqueInput;
}

export interface PartnerCreateOrConnectWithoutMemberInput {
  create: PartnerUncheckedCreateWithoutMemberInput;
  where: PartnerWhereUniqueInput;
}

export interface PartnerCreateOrConnectWithoutModelerInput {
  create: PartnerUncheckedCreateWithoutModelerInput;
  where: PartnerWhereUniqueInput;
}

export interface PartnerCreateOrConnectWithoutSpaceDesignInput {
  create: PartnerUncheckedCreateWithoutSpaceDesignInput;
  where: PartnerWhereUniqueInput;
}

export interface PartnerCreateOrConnectWithoutSupplierInput {
  create: PartnerUncheckedCreateWithoutSupplierInput;
  where: PartnerWhereUniqueInput;
}

export interface PartnerCreateOrConnectWithoutTasksInput {
  create: PartnerUncheckedCreateWithoutTasksInput;
  where: PartnerWhereUniqueInput;
}

export interface PartnerCreateWithoutArchitectInput {
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  member?: InputMaybe<MemberCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerCreateWithoutConstructionInput {
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  member?: InputMaybe<MemberCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerCreateWithoutGeneralConstructionInput {
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  member?: InputMaybe<MemberCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerCreateWithoutMemberInput {
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerCreateWithoutModelerInput {
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  member?: InputMaybe<MemberCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerCreateWithoutSpaceDesignInput {
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  member?: InputMaybe<MemberCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerCreateWithoutSupplierInput {
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  member?: InputMaybe<MemberCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerCreateWithoutTasksInput {
  Architect?: InputMaybe<ArchitectCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  member?: InputMaybe<MemberCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerCreateroleInput {
  set: PartnerRole;
}

export interface PartnerMaxAggregateOutputType {
  __typename?: 'PartnerMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<MemberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface PartnerMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface PartnerMinAggregateOutputType {
  __typename?: 'PartnerMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<MemberStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface PartnerMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface PartnerOrderByWithAggregationInput {
  _avg?: InputMaybe<PartnerAvgOrderByAggregateInput>;
  _count?: InputMaybe<PartnerCountOrderByAggregateInput>;
  _max?: InputMaybe<PartnerMaxOrderByAggregateInput>;
  _min?: InputMaybe<PartnerMinOrderByAggregateInput>;
  _sum?: InputMaybe<PartnerSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface PartnerOrderByWithRelationInput {
  Architect?: InputMaybe<ArchitectOrderByRelationAggregateInput>;
  Construction?: InputMaybe<ConstructionOrderByRelationAggregateInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionOrderByRelationAggregateInput>;
  Modeler?: InputMaybe<ModelerOrderByRelationAggregateInput>;
  SpaceDesign?: InputMaybe<SpaceDesignOrderByRelationAggregateInput>;
  Supplier?: InputMaybe<SupplierOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberOrderByRelationAggregateInput>;
  role?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export enum PartnerRegistrationStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Standby = 'Standby'
}

export interface PartnerRelationFilter {
  is?: InputMaybe<PartnerWhereInput>;
  isNot?: InputMaybe<PartnerWhereInput>;
}

export enum PartnerRole {
  Architect = 'Architect',
  Construction = 'Construction',
  GeneralConstruction = 'GeneralConstruction',
  Modeler = 'Modeler',
  SpaceDesign = 'SpaceDesign',
  Supplier = 'Supplier'
}

export enum PartnerScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Role = 'role',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface PartnerScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<PartnerScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<PartnerScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<PartnerScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  role?: InputMaybe<EnumPartnerRoleNullableListFilter>;
  status?: InputMaybe<EnumMemberStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface PartnerSumAggregateOutputType {
  __typename?: 'PartnerSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
}

export interface PartnerSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface PartnerUncheckedCreateInput {
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerUncheckedCreateWithoutArchitectInput {
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerUncheckedCreateWithoutConstructionInput {
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerUncheckedCreateWithoutGeneralConstructionInput {
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerUncheckedCreateWithoutMemberInput {
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerUncheckedCreateWithoutModelerInput {
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerUncheckedCreateWithoutSpaceDesignInput {
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerUncheckedCreateWithoutSupplierInput {
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutPartnerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerUncheckedCreateWithoutTasksInput {
  Architect?: InputMaybe<ArchitectUncheckedCreateNestedManyWithoutPartnerInput>;
  Construction?: InputMaybe<ConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedCreateNestedManyWithoutPartnerInput>;
  Modeler?: InputMaybe<ModelerUncheckedCreateNestedManyWithoutPartnerInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedCreateNestedManyWithoutPartnerInput>;
  Supplier?: InputMaybe<SupplierUncheckedCreateNestedManyWithoutPartnerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<MemberUncheckedCreateNestedManyWithoutPartnerInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status: MemberStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface PartnerUncheckedUpdateInput {
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUncheckedUpdateWithoutArchitectInput {
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUncheckedUpdateWithoutConstructionInput {
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUncheckedUpdateWithoutGeneralConstructionInput {
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUncheckedUpdateWithoutMemberInput {
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUncheckedUpdateWithoutModelerInput {
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUncheckedUpdateWithoutSpaceDesignInput {
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUncheckedUpdateWithoutSupplierInput {
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUncheckedUpdateWithoutTasksInput {
  Architect?: InputMaybe<ArchitectUncheckedUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUncheckedUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUncheckedUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUncheckedUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUncheckedUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUncheckedUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUpdateInput {
  Architect?: InputMaybe<ArchitectUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUpdateOneRequiredWithoutArchitectNestedInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutArchitectInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutArchitectInput>;
  update?: InputMaybe<PartnerUncheckedUpdateWithoutArchitectInput>;
  upsert?: InputMaybe<PartnerUpsertWithoutArchitectInput>;
}

export interface PartnerUpdateOneRequiredWithoutConstructionNestedInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutConstructionInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutConstructionInput>;
  update?: InputMaybe<PartnerUncheckedUpdateWithoutConstructionInput>;
  upsert?: InputMaybe<PartnerUpsertWithoutConstructionInput>;
}

export interface PartnerUpdateOneRequiredWithoutGeneralConstructionNestedInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutGeneralConstructionInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutGeneralConstructionInput>;
  update?: InputMaybe<PartnerUncheckedUpdateWithoutGeneralConstructionInput>;
  upsert?: InputMaybe<PartnerUpsertWithoutGeneralConstructionInput>;
}

export interface PartnerUpdateOneRequiredWithoutModelerNestedInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutModelerInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutModelerInput>;
  update?: InputMaybe<PartnerUncheckedUpdateWithoutModelerInput>;
  upsert?: InputMaybe<PartnerUpsertWithoutModelerInput>;
}

export interface PartnerUpdateOneRequiredWithoutSpaceDesignNestedInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutSpaceDesignInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutSpaceDesignInput>;
  update?: InputMaybe<PartnerUncheckedUpdateWithoutSpaceDesignInput>;
  upsert?: InputMaybe<PartnerUpsertWithoutSpaceDesignInput>;
}

export interface PartnerUpdateOneRequiredWithoutSupplierNestedInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutSupplierInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutSupplierInput>;
  update?: InputMaybe<PartnerUncheckedUpdateWithoutSupplierInput>;
  upsert?: InputMaybe<PartnerUpsertWithoutSupplierInput>;
}

export interface PartnerUpdateOneWithoutMemberNestedInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutMemberInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutMemberInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<PartnerUncheckedUpdateWithoutMemberInput>;
  upsert?: InputMaybe<PartnerUpsertWithoutMemberInput>;
}

export interface PartnerUpdateOneWithoutTasksNestedInput {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<PartnerUncheckedCreateWithoutTasksInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<PartnerUncheckedUpdateWithoutTasksInput>;
  upsert?: InputMaybe<PartnerUpsertWithoutTasksInput>;
}

export interface PartnerUpdateWithoutArchitectInput {
  Construction?: InputMaybe<ConstructionUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUpdateWithoutConstructionInput {
  Architect?: InputMaybe<ArchitectUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUpdateWithoutGeneralConstructionInput {
  Architect?: InputMaybe<ArchitectUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUpdateWithoutMemberInput {
  Architect?: InputMaybe<ArchitectUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUpdateWithoutModelerInput {
  Architect?: InputMaybe<ArchitectUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUpdateWithoutSpaceDesignInput {
  Architect?: InputMaybe<ArchitectUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUpdateWithoutSupplierInput {
  Architect?: InputMaybe<ArchitectUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutPartnerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUpdateWithoutTasksInput {
  Architect?: InputMaybe<ArchitectUpdateManyWithoutPartnerNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateManyWithoutPartnerNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateManyWithoutPartnerNestedInput>;
  Modeler?: InputMaybe<ModelerUpdateManyWithoutPartnerNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateManyWithoutPartnerNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateManyWithoutPartnerNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateManyWithoutPartnerNestedInput>;
  role?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  status?: InputMaybe<EnumMemberStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface PartnerUpdateroleInput {
  push?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
  set?: InputMaybe<Array<InputMaybe<PartnerRole>>>;
}

export interface PartnerUpsertWithoutArchitectInput {
  create: PartnerUncheckedCreateWithoutArchitectInput;
  update: PartnerUncheckedUpdateWithoutArchitectInput;
}

export interface PartnerUpsertWithoutConstructionInput {
  create: PartnerUncheckedCreateWithoutConstructionInput;
  update: PartnerUncheckedUpdateWithoutConstructionInput;
}

export interface PartnerUpsertWithoutGeneralConstructionInput {
  create: PartnerUncheckedCreateWithoutGeneralConstructionInput;
  update: PartnerUncheckedUpdateWithoutGeneralConstructionInput;
}

export interface PartnerUpsertWithoutMemberInput {
  create: PartnerUncheckedCreateWithoutMemberInput;
  update: PartnerUncheckedUpdateWithoutMemberInput;
}

export interface PartnerUpsertWithoutModelerInput {
  create: PartnerUncheckedCreateWithoutModelerInput;
  update: PartnerUncheckedUpdateWithoutModelerInput;
}

export interface PartnerUpsertWithoutSpaceDesignInput {
  create: PartnerUncheckedCreateWithoutSpaceDesignInput;
  update: PartnerUncheckedUpdateWithoutSpaceDesignInput;
}

export interface PartnerUpsertWithoutSupplierInput {
  create: PartnerUncheckedCreateWithoutSupplierInput;
  update: PartnerUncheckedUpdateWithoutSupplierInput;
}

export interface PartnerUpsertWithoutTasksInput {
  create: PartnerUncheckedCreateWithoutTasksInput;
  update: PartnerUncheckedUpdateWithoutTasksInput;
}

export interface PartnerWhereInput {
  AND?: InputMaybe<Array<InputMaybe<PartnerWhereInput>>>;
  Architect?: InputMaybe<ArchitectListRelationFilter>;
  Construction?: InputMaybe<ConstructionListRelationFilter>;
  GeneralConstruction?: InputMaybe<GeneralConstructionListRelationFilter>;
  Modeler?: InputMaybe<ModelerListRelationFilter>;
  NOT?: InputMaybe<Array<InputMaybe<PartnerWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<PartnerWhereInput>>>;
  SpaceDesign?: InputMaybe<SpaceDesignListRelationFilter>;
  Supplier?: InputMaybe<SupplierListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  member?: InputMaybe<MemberListRelationFilter>;
  role?: InputMaybe<EnumPartnerRoleNullableListFilter>;
  status?: InputMaybe<EnumMemberStatusFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface PartnerWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** 결제 정보 (아임포트) */
export interface PayTransaction {
  __typename?: 'PayTransaction';
  /** Customer */
  Customer?: Maybe<Customer>;
  /** 프로젝트 정보 */
  Project?: Maybe<Project>;
  /** 결제 금액 */
  amount?: Maybe<Scalars['Int']['output']>;
  /** 주문자 주소 */
  buyerAddr?: Maybe<Scalars['String']['output']>;
  /** 주문자 이메일 */
  buyerEmail?: Maybe<Scalars['String']['output']>;
  /** 주문자명 */
  buyerName?: Maybe<Scalars['String']['output']>;
  /** 주문자 우편번호 */
  buyerPostcode?: Maybe<Scalars['String']['output']>;
  /** 주문자 전화번호 */
  buyerTel?: Maybe<Scalars['String']['output']>;
  /** 취소 금액 */
  cancelAmount?: Maybe<Scalars['Int']['output']>;
  /** 결제 취소 사유 */
  cancelReason?: Maybe<Scalars['String']['output']>;
  /** 결제 취소일 */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** 현금영수증 발행 여부 */
  cashReceiptIssued?: Maybe<Scalars['Boolean']['output']>;
  /** 결제 채널 */
  channel?: Maybe<Scalars['String']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['Int']['output']>;
  /** 고객 uid */
  customerUid?: Maybe<Scalars['String']['output']>;
  /** 결제 실패 사유 */
  failReason?: Maybe<Scalars['String']['output']>;
  /** 결제 실패일 */
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** 아임포트 고유 결제 번호 */
  impUid: Scalars['String']['output'];
  /** 아임포트 고유 주문 번호 */
  merchantUid?: Maybe<Scalars['String']['output']>;
  /** 주문명 */
  name?: Maybe<Scalars['String']['output']>;
  /** 결제 완료일 */
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  /** 결제 아이디 */
  paymentImpUid?: Maybe<Scalars['String']['output']>;
  /** PG사 승인번호 */
  pgTid?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  /** 영수증 URL */
  receiptUrl?: Maybe<Scalars['String']['output']>;
  /** 결제 생성일 */
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /** 결제 상태 */
  status?: Maybe<Scalars['String']['output']>;
  /** updatedAt */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Unique ID */
  uuid: Scalars['String']['output'];
  /** vbank 코드 */
  vbankCode?: Maybe<Scalars['String']['output']>;
  /** vbank 만료일 */
  vbankDate?: Maybe<Scalars['DateTime']['output']>;
  /** vbank 입금자명 */
  vbankHolder?: Maybe<Scalars['String']['output']>;
  /** vbank 생성일 */
  vbankIssuedAt?: Maybe<Scalars['DateTime']['output']>;
  /** vbank 입금 은행 */
  vbankName?: Maybe<Scalars['String']['output']>;
  /** vbank 입금 계좌번호 */
  vbankNum?: Maybe<Scalars['String']['output']>;
}

export interface PayTransactionAvgAggregateOutputType {
  __typename?: 'PayTransactionAvgAggregateOutputType';
  amount?: Maybe<Scalars['Float']['output']>;
  cancelAmount?: Maybe<Scalars['Float']['output']>;
  customerId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  projectId?: Maybe<Scalars['Float']['output']>;
}

export interface PayTransactionAvgOrderByAggregateInput {
  amount?: InputMaybe<SortOrder>;
  cancelAmount?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface PayTransactionCountAggregateOutputType {
  __typename?: 'PayTransactionCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  amount: Scalars['Int']['output'];
  buyerAddr: Scalars['Int']['output'];
  buyerEmail: Scalars['Int']['output'];
  buyerName: Scalars['Int']['output'];
  buyerPostcode: Scalars['Int']['output'];
  buyerTel: Scalars['Int']['output'];
  cancelAmount: Scalars['Int']['output'];
  cancelReason: Scalars['Int']['output'];
  cancelledAt: Scalars['Int']['output'];
  cashReceiptIssued: Scalars['Int']['output'];
  channel: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  customerUid: Scalars['Int']['output'];
  failReason: Scalars['Int']['output'];
  failedAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  impUid: Scalars['Int']['output'];
  merchantUid: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  paidAt: Scalars['Int']['output'];
  paymentImpUid: Scalars['Int']['output'];
  pgTid: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  receiptUrl: Scalars['Int']['output'];
  startedAt: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  vbankCode: Scalars['Int']['output'];
  vbankDate: Scalars['Int']['output'];
  vbankHolder: Scalars['Int']['output'];
  vbankIssuedAt: Scalars['Int']['output'];
  vbankName: Scalars['Int']['output'];
  vbankNum: Scalars['Int']['output'];
}

export interface PayTransactionCountOrderByAggregateInput {
  amount?: InputMaybe<SortOrder>;
  buyerAddr?: InputMaybe<SortOrder>;
  buyerEmail?: InputMaybe<SortOrder>;
  buyerName?: InputMaybe<SortOrder>;
  buyerPostcode?: InputMaybe<SortOrder>;
  buyerTel?: InputMaybe<SortOrder>;
  cancelAmount?: InputMaybe<SortOrder>;
  cancelReason?: InputMaybe<SortOrder>;
  cancelledAt?: InputMaybe<SortOrder>;
  cashReceiptIssued?: InputMaybe<SortOrder>;
  channel?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  customerUid?: InputMaybe<SortOrder>;
  failReason?: InputMaybe<SortOrder>;
  failedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  impUid?: InputMaybe<SortOrder>;
  merchantUid?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paidAt?: InputMaybe<SortOrder>;
  paymentImpUid?: InputMaybe<SortOrder>;
  pgTid?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  receiptUrl?: InputMaybe<SortOrder>;
  startedAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vbankCode?: InputMaybe<SortOrder>;
  vbankDate?: InputMaybe<SortOrder>;
  vbankHolder?: InputMaybe<SortOrder>;
  vbankIssuedAt?: InputMaybe<SortOrder>;
  vbankName?: InputMaybe<SortOrder>;
  vbankNum?: InputMaybe<SortOrder>;
}

export interface PayTransactionCreateInput {
  Customer?: InputMaybe<CustomerCreateNestedOneWithoutPayTransactionInput>;
  Project?: InputMaybe<ProjectCreateNestedOneWithoutPayTransactionInput>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  buyerAddr?: InputMaybe<Scalars['String']['input']>;
  buyerEmail?: InputMaybe<Scalars['String']['input']>;
  buyerName?: InputMaybe<Scalars['String']['input']>;
  buyerPostcode?: InputMaybe<Scalars['String']['input']>;
  buyerTel?: InputMaybe<Scalars['String']['input']>;
  cancelAmount?: InputMaybe<Scalars['Int']['input']>;
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['DateTime']['input']>;
  cashReceiptIssued?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerUid?: InputMaybe<Scalars['String']['input']>;
  failReason?: InputMaybe<Scalars['String']['input']>;
  failedAt?: InputMaybe<Scalars['DateTime']['input']>;
  impUid: Scalars['String']['input'];
  merchantUid?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paidAt?: InputMaybe<Scalars['DateTime']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  pgTid?: InputMaybe<Scalars['String']['input']>;
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vbankCode?: InputMaybe<Scalars['String']['input']>;
  vbankDate?: InputMaybe<Scalars['DateTime']['input']>;
  vbankHolder?: InputMaybe<Scalars['String']['input']>;
  vbankIssuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vbankName?: InputMaybe<Scalars['String']['input']>;
  vbankNum?: InputMaybe<Scalars['String']['input']>;
}

export interface PayTransactionCreateManyCustomerInput {
  amount?: InputMaybe<Scalars['Int']['input']>;
  buyerAddr?: InputMaybe<Scalars['String']['input']>;
  buyerEmail?: InputMaybe<Scalars['String']['input']>;
  buyerName?: InputMaybe<Scalars['String']['input']>;
  buyerPostcode?: InputMaybe<Scalars['String']['input']>;
  buyerTel?: InputMaybe<Scalars['String']['input']>;
  cancelAmount?: InputMaybe<Scalars['Int']['input']>;
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['DateTime']['input']>;
  cashReceiptIssued?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerUid?: InputMaybe<Scalars['String']['input']>;
  failReason?: InputMaybe<Scalars['String']['input']>;
  failedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impUid: Scalars['String']['input'];
  merchantUid?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paidAt?: InputMaybe<Scalars['DateTime']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  pgTid?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vbankCode?: InputMaybe<Scalars['String']['input']>;
  vbankDate?: InputMaybe<Scalars['DateTime']['input']>;
  vbankHolder?: InputMaybe<Scalars['String']['input']>;
  vbankIssuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vbankName?: InputMaybe<Scalars['String']['input']>;
  vbankNum?: InputMaybe<Scalars['String']['input']>;
}

export interface PayTransactionCreateManyCustomerInputEnvelope {
  data: PayTransactionCreateManyCustomerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface PayTransactionCreateManyInput {
  amount?: InputMaybe<Scalars['Int']['input']>;
  buyerAddr?: InputMaybe<Scalars['String']['input']>;
  buyerEmail?: InputMaybe<Scalars['String']['input']>;
  buyerName?: InputMaybe<Scalars['String']['input']>;
  buyerPostcode?: InputMaybe<Scalars['String']['input']>;
  buyerTel?: InputMaybe<Scalars['String']['input']>;
  cancelAmount?: InputMaybe<Scalars['Int']['input']>;
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['DateTime']['input']>;
  cashReceiptIssued?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  customerUid?: InputMaybe<Scalars['String']['input']>;
  failReason?: InputMaybe<Scalars['String']['input']>;
  failedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impUid: Scalars['String']['input'];
  merchantUid?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paidAt?: InputMaybe<Scalars['DateTime']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  pgTid?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vbankCode?: InputMaybe<Scalars['String']['input']>;
  vbankDate?: InputMaybe<Scalars['DateTime']['input']>;
  vbankHolder?: InputMaybe<Scalars['String']['input']>;
  vbankIssuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vbankName?: InputMaybe<Scalars['String']['input']>;
  vbankNum?: InputMaybe<Scalars['String']['input']>;
}

export interface PayTransactionCreateManyProjectInput {
  amount?: InputMaybe<Scalars['Int']['input']>;
  buyerAddr?: InputMaybe<Scalars['String']['input']>;
  buyerEmail?: InputMaybe<Scalars['String']['input']>;
  buyerName?: InputMaybe<Scalars['String']['input']>;
  buyerPostcode?: InputMaybe<Scalars['String']['input']>;
  buyerTel?: InputMaybe<Scalars['String']['input']>;
  cancelAmount?: InputMaybe<Scalars['Int']['input']>;
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['DateTime']['input']>;
  cashReceiptIssued?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  customerUid?: InputMaybe<Scalars['String']['input']>;
  failReason?: InputMaybe<Scalars['String']['input']>;
  failedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impUid: Scalars['String']['input'];
  merchantUid?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paidAt?: InputMaybe<Scalars['DateTime']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  pgTid?: InputMaybe<Scalars['String']['input']>;
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vbankCode?: InputMaybe<Scalars['String']['input']>;
  vbankDate?: InputMaybe<Scalars['DateTime']['input']>;
  vbankHolder?: InputMaybe<Scalars['String']['input']>;
  vbankIssuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vbankName?: InputMaybe<Scalars['String']['input']>;
  vbankNum?: InputMaybe<Scalars['String']['input']>;
}

export interface PayTransactionCreateManyProjectInputEnvelope {
  data: PayTransactionCreateManyProjectInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface PayTransactionCreateNestedManyWithoutCustomerInput {
  connect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PayTransactionCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<PayTransactionCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<PayTransactionCreateManyCustomerInputEnvelope>;
}

export interface PayTransactionCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PayTransactionCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<PayTransactionCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<PayTransactionCreateManyProjectInputEnvelope>;
}

export interface PayTransactionCreateOrConnectWithoutCustomerInput {
  create: PayTransactionUncheckedCreateWithoutCustomerInput;
  where: PayTransactionWhereUniqueInput;
}

export interface PayTransactionCreateOrConnectWithoutProjectInput {
  create: PayTransactionUncheckedCreateWithoutProjectInput;
  where: PayTransactionWhereUniqueInput;
}

export interface PayTransactionCreateWithoutCustomerInput {
  Project?: InputMaybe<ProjectCreateNestedOneWithoutPayTransactionInput>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  buyerAddr?: InputMaybe<Scalars['String']['input']>;
  buyerEmail?: InputMaybe<Scalars['String']['input']>;
  buyerName?: InputMaybe<Scalars['String']['input']>;
  buyerPostcode?: InputMaybe<Scalars['String']['input']>;
  buyerTel?: InputMaybe<Scalars['String']['input']>;
  cancelAmount?: InputMaybe<Scalars['Int']['input']>;
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['DateTime']['input']>;
  cashReceiptIssued?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerUid?: InputMaybe<Scalars['String']['input']>;
  failReason?: InputMaybe<Scalars['String']['input']>;
  failedAt?: InputMaybe<Scalars['DateTime']['input']>;
  impUid: Scalars['String']['input'];
  merchantUid?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paidAt?: InputMaybe<Scalars['DateTime']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  pgTid?: InputMaybe<Scalars['String']['input']>;
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vbankCode?: InputMaybe<Scalars['String']['input']>;
  vbankDate?: InputMaybe<Scalars['DateTime']['input']>;
  vbankHolder?: InputMaybe<Scalars['String']['input']>;
  vbankIssuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vbankName?: InputMaybe<Scalars['String']['input']>;
  vbankNum?: InputMaybe<Scalars['String']['input']>;
}

export interface PayTransactionCreateWithoutProjectInput {
  Customer?: InputMaybe<CustomerCreateNestedOneWithoutPayTransactionInput>;
  amount?: InputMaybe<Scalars['Int']['input']>;
  buyerAddr?: InputMaybe<Scalars['String']['input']>;
  buyerEmail?: InputMaybe<Scalars['String']['input']>;
  buyerName?: InputMaybe<Scalars['String']['input']>;
  buyerPostcode?: InputMaybe<Scalars['String']['input']>;
  buyerTel?: InputMaybe<Scalars['String']['input']>;
  cancelAmount?: InputMaybe<Scalars['Int']['input']>;
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['DateTime']['input']>;
  cashReceiptIssued?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerUid?: InputMaybe<Scalars['String']['input']>;
  failReason?: InputMaybe<Scalars['String']['input']>;
  failedAt?: InputMaybe<Scalars['DateTime']['input']>;
  impUid: Scalars['String']['input'];
  merchantUid?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paidAt?: InputMaybe<Scalars['DateTime']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  pgTid?: InputMaybe<Scalars['String']['input']>;
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vbankCode?: InputMaybe<Scalars['String']['input']>;
  vbankDate?: InputMaybe<Scalars['DateTime']['input']>;
  vbankHolder?: InputMaybe<Scalars['String']['input']>;
  vbankIssuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vbankName?: InputMaybe<Scalars['String']['input']>;
  vbankNum?: InputMaybe<Scalars['String']['input']>;
}

export interface PayTransactionListRelationFilter {
  every?: InputMaybe<PayTransactionWhereInput>;
  none?: InputMaybe<PayTransactionWhereInput>;
  some?: InputMaybe<PayTransactionWhereInput>;
}

export interface PayTransactionMaxAggregateOutputType {
  __typename?: 'PayTransactionMaxAggregateOutputType';
  amount?: Maybe<Scalars['Int']['output']>;
  buyerAddr?: Maybe<Scalars['String']['output']>;
  buyerEmail?: Maybe<Scalars['String']['output']>;
  buyerName?: Maybe<Scalars['String']['output']>;
  buyerPostcode?: Maybe<Scalars['String']['output']>;
  buyerTel?: Maybe<Scalars['String']['output']>;
  cancelAmount?: Maybe<Scalars['Int']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  cashReceiptIssued?: Maybe<Scalars['Boolean']['output']>;
  channel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  customerUid?: Maybe<Scalars['String']['output']>;
  failReason?: Maybe<Scalars['String']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  impUid?: Maybe<Scalars['String']['output']>;
  merchantUid?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  paymentImpUid?: Maybe<Scalars['String']['output']>;
  pgTid?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  receiptUrl?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  vbankCode?: Maybe<Scalars['String']['output']>;
  vbankDate?: Maybe<Scalars['DateTime']['output']>;
  vbankHolder?: Maybe<Scalars['String']['output']>;
  vbankIssuedAt?: Maybe<Scalars['DateTime']['output']>;
  vbankName?: Maybe<Scalars['String']['output']>;
  vbankNum?: Maybe<Scalars['String']['output']>;
}

export interface PayTransactionMaxOrderByAggregateInput {
  amount?: InputMaybe<SortOrder>;
  buyerAddr?: InputMaybe<SortOrder>;
  buyerEmail?: InputMaybe<SortOrder>;
  buyerName?: InputMaybe<SortOrder>;
  buyerPostcode?: InputMaybe<SortOrder>;
  buyerTel?: InputMaybe<SortOrder>;
  cancelAmount?: InputMaybe<SortOrder>;
  cancelReason?: InputMaybe<SortOrder>;
  cancelledAt?: InputMaybe<SortOrder>;
  cashReceiptIssued?: InputMaybe<SortOrder>;
  channel?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  customerUid?: InputMaybe<SortOrder>;
  failReason?: InputMaybe<SortOrder>;
  failedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  impUid?: InputMaybe<SortOrder>;
  merchantUid?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paidAt?: InputMaybe<SortOrder>;
  paymentImpUid?: InputMaybe<SortOrder>;
  pgTid?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  receiptUrl?: InputMaybe<SortOrder>;
  startedAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vbankCode?: InputMaybe<SortOrder>;
  vbankDate?: InputMaybe<SortOrder>;
  vbankHolder?: InputMaybe<SortOrder>;
  vbankIssuedAt?: InputMaybe<SortOrder>;
  vbankName?: InputMaybe<SortOrder>;
  vbankNum?: InputMaybe<SortOrder>;
}

export interface PayTransactionMinAggregateOutputType {
  __typename?: 'PayTransactionMinAggregateOutputType';
  amount?: Maybe<Scalars['Int']['output']>;
  buyerAddr?: Maybe<Scalars['String']['output']>;
  buyerEmail?: Maybe<Scalars['String']['output']>;
  buyerName?: Maybe<Scalars['String']['output']>;
  buyerPostcode?: Maybe<Scalars['String']['output']>;
  buyerTel?: Maybe<Scalars['String']['output']>;
  cancelAmount?: Maybe<Scalars['Int']['output']>;
  cancelReason?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  cashReceiptIssued?: Maybe<Scalars['Boolean']['output']>;
  channel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  customerUid?: Maybe<Scalars['String']['output']>;
  failReason?: Maybe<Scalars['String']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  impUid?: Maybe<Scalars['String']['output']>;
  merchantUid?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  paymentImpUid?: Maybe<Scalars['String']['output']>;
  pgTid?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  receiptUrl?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  vbankCode?: Maybe<Scalars['String']['output']>;
  vbankDate?: Maybe<Scalars['DateTime']['output']>;
  vbankHolder?: Maybe<Scalars['String']['output']>;
  vbankIssuedAt?: Maybe<Scalars['DateTime']['output']>;
  vbankName?: Maybe<Scalars['String']['output']>;
  vbankNum?: Maybe<Scalars['String']['output']>;
}

export interface PayTransactionMinOrderByAggregateInput {
  amount?: InputMaybe<SortOrder>;
  buyerAddr?: InputMaybe<SortOrder>;
  buyerEmail?: InputMaybe<SortOrder>;
  buyerName?: InputMaybe<SortOrder>;
  buyerPostcode?: InputMaybe<SortOrder>;
  buyerTel?: InputMaybe<SortOrder>;
  cancelAmount?: InputMaybe<SortOrder>;
  cancelReason?: InputMaybe<SortOrder>;
  cancelledAt?: InputMaybe<SortOrder>;
  cashReceiptIssued?: InputMaybe<SortOrder>;
  channel?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  customerUid?: InputMaybe<SortOrder>;
  failReason?: InputMaybe<SortOrder>;
  failedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  impUid?: InputMaybe<SortOrder>;
  merchantUid?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  paidAt?: InputMaybe<SortOrder>;
  paymentImpUid?: InputMaybe<SortOrder>;
  pgTid?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  receiptUrl?: InputMaybe<SortOrder>;
  startedAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vbankCode?: InputMaybe<SortOrder>;
  vbankDate?: InputMaybe<SortOrder>;
  vbankHolder?: InputMaybe<SortOrder>;
  vbankIssuedAt?: InputMaybe<SortOrder>;
  vbankName?: InputMaybe<SortOrder>;
  vbankNum?: InputMaybe<SortOrder>;
}

export interface PayTransactionOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface PayTransactionOrderByWithAggregationInput {
  _avg?: InputMaybe<PayTransactionAvgOrderByAggregateInput>;
  _count?: InputMaybe<PayTransactionCountOrderByAggregateInput>;
  _max?: InputMaybe<PayTransactionMaxOrderByAggregateInput>;
  _min?: InputMaybe<PayTransactionMinOrderByAggregateInput>;
  _sum?: InputMaybe<PayTransactionSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrderInput>;
  buyerAddr?: InputMaybe<SortOrderInput>;
  buyerEmail?: InputMaybe<SortOrderInput>;
  buyerName?: InputMaybe<SortOrderInput>;
  buyerPostcode?: InputMaybe<SortOrderInput>;
  buyerTel?: InputMaybe<SortOrderInput>;
  cancelAmount?: InputMaybe<SortOrderInput>;
  cancelReason?: InputMaybe<SortOrderInput>;
  cancelledAt?: InputMaybe<SortOrderInput>;
  cashReceiptIssued?: InputMaybe<SortOrderInput>;
  channel?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrderInput>;
  customerUid?: InputMaybe<SortOrderInput>;
  failReason?: InputMaybe<SortOrderInput>;
  failedAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  impUid?: InputMaybe<SortOrder>;
  merchantUid?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrderInput>;
  paidAt?: InputMaybe<SortOrderInput>;
  paymentImpUid?: InputMaybe<SortOrderInput>;
  pgTid?: InputMaybe<SortOrderInput>;
  projectId?: InputMaybe<SortOrderInput>;
  receiptUrl?: InputMaybe<SortOrderInput>;
  startedAt?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
  vbankCode?: InputMaybe<SortOrderInput>;
  vbankDate?: InputMaybe<SortOrderInput>;
  vbankHolder?: InputMaybe<SortOrderInput>;
  vbankIssuedAt?: InputMaybe<SortOrderInput>;
  vbankName?: InputMaybe<SortOrderInput>;
  vbankNum?: InputMaybe<SortOrderInput>;
}

export interface PayTransactionOrderByWithRelationInput {
  Customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  Project?: InputMaybe<ProjectOrderByWithRelationInput>;
  amount?: InputMaybe<SortOrderInput>;
  buyerAddr?: InputMaybe<SortOrderInput>;
  buyerEmail?: InputMaybe<SortOrderInput>;
  buyerName?: InputMaybe<SortOrderInput>;
  buyerPostcode?: InputMaybe<SortOrderInput>;
  buyerTel?: InputMaybe<SortOrderInput>;
  cancelAmount?: InputMaybe<SortOrderInput>;
  cancelReason?: InputMaybe<SortOrderInput>;
  cancelledAt?: InputMaybe<SortOrderInput>;
  cashReceiptIssued?: InputMaybe<SortOrderInput>;
  channel?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrderInput>;
  customerUid?: InputMaybe<SortOrderInput>;
  failReason?: InputMaybe<SortOrderInput>;
  failedAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  impUid?: InputMaybe<SortOrder>;
  merchantUid?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrderInput>;
  paidAt?: InputMaybe<SortOrderInput>;
  paymentImpUid?: InputMaybe<SortOrderInput>;
  pgTid?: InputMaybe<SortOrderInput>;
  projectId?: InputMaybe<SortOrderInput>;
  receiptUrl?: InputMaybe<SortOrderInput>;
  startedAt?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
  vbankCode?: InputMaybe<SortOrderInput>;
  vbankDate?: InputMaybe<SortOrderInput>;
  vbankHolder?: InputMaybe<SortOrderInput>;
  vbankIssuedAt?: InputMaybe<SortOrderInput>;
  vbankName?: InputMaybe<SortOrderInput>;
  vbankNum?: InputMaybe<SortOrderInput>;
}

export enum PayTransactionScalarFieldEnum {
  Amount = 'amount',
  BuyerAddr = 'buyerAddr',
  BuyerEmail = 'buyerEmail',
  BuyerName = 'buyerName',
  BuyerPostcode = 'buyerPostcode',
  BuyerTel = 'buyerTel',
  CancelAmount = 'cancelAmount',
  CancelReason = 'cancelReason',
  CancelledAt = 'cancelledAt',
  CashReceiptIssued = 'cashReceiptIssued',
  Channel = 'channel',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  CustomerUid = 'customerUid',
  FailReason = 'failReason',
  FailedAt = 'failedAt',
  Id = 'id',
  ImpUid = 'impUid',
  MerchantUid = 'merchantUid',
  Name = 'name',
  PaidAt = 'paidAt',
  PaymentImpUid = 'paymentImpUid',
  PgTid = 'pgTid',
  ProjectId = 'projectId',
  ReceiptUrl = 'receiptUrl',
  StartedAt = 'startedAt',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  VbankCode = 'vbankCode',
  VbankDate = 'vbankDate',
  VbankHolder = 'vbankHolder',
  VbankIssuedAt = 'vbankIssuedAt',
  VbankName = 'vbankName',
  VbankNum = 'vbankNum'
}

export interface PayTransactionScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<PayTransactionScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<PayTransactionScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<PayTransactionScalarWhereInput>>>;
  amount?: InputMaybe<IntNullableFilter>;
  buyerAddr?: InputMaybe<StringNullableFilter>;
  buyerEmail?: InputMaybe<StringNullableFilter>;
  buyerName?: InputMaybe<StringNullableFilter>;
  buyerPostcode?: InputMaybe<StringNullableFilter>;
  buyerTel?: InputMaybe<StringNullableFilter>;
  cancelAmount?: InputMaybe<IntNullableFilter>;
  cancelReason?: InputMaybe<StringNullableFilter>;
  cancelledAt?: InputMaybe<DateTimeNullableFilter>;
  cashReceiptIssued?: InputMaybe<BoolNullableFilter>;
  channel?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<IntNullableFilter>;
  customerUid?: InputMaybe<StringNullableFilter>;
  failReason?: InputMaybe<StringNullableFilter>;
  failedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  impUid?: InputMaybe<StringFilter>;
  merchantUid?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  paidAt?: InputMaybe<DateTimeNullableFilter>;
  paymentImpUid?: InputMaybe<StringNullableFilter>;
  pgTid?: InputMaybe<StringNullableFilter>;
  projectId?: InputMaybe<IntNullableFilter>;
  receiptUrl?: InputMaybe<StringNullableFilter>;
  startedAt?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  uuid?: InputMaybe<StringFilter>;
  vbankCode?: InputMaybe<StringNullableFilter>;
  vbankDate?: InputMaybe<DateTimeNullableFilter>;
  vbankHolder?: InputMaybe<StringNullableFilter>;
  vbankIssuedAt?: InputMaybe<DateTimeNullableFilter>;
  vbankName?: InputMaybe<StringNullableFilter>;
  vbankNum?: InputMaybe<StringNullableFilter>;
}

export interface PayTransactionScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<PayTransactionScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<PayTransactionScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<PayTransactionScalarWhereWithAggregatesInput>>>;
  amount?: InputMaybe<IntNullableWithAggregatesFilter>;
  buyerAddr?: InputMaybe<StringNullableWithAggregatesFilter>;
  buyerEmail?: InputMaybe<StringNullableWithAggregatesFilter>;
  buyerName?: InputMaybe<StringNullableWithAggregatesFilter>;
  buyerPostcode?: InputMaybe<StringNullableWithAggregatesFilter>;
  buyerTel?: InputMaybe<StringNullableWithAggregatesFilter>;
  cancelAmount?: InputMaybe<IntNullableWithAggregatesFilter>;
  cancelReason?: InputMaybe<StringNullableWithAggregatesFilter>;
  cancelledAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  cashReceiptIssued?: InputMaybe<BoolNullableWithAggregatesFilter>;
  channel?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  customerId?: InputMaybe<IntNullableWithAggregatesFilter>;
  customerUid?: InputMaybe<StringNullableWithAggregatesFilter>;
  failReason?: InputMaybe<StringNullableWithAggregatesFilter>;
  failedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  impUid?: InputMaybe<StringWithAggregatesFilter>;
  merchantUid?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  paidAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  paymentImpUid?: InputMaybe<StringNullableWithAggregatesFilter>;
  pgTid?: InputMaybe<StringNullableWithAggregatesFilter>;
  projectId?: InputMaybe<IntNullableWithAggregatesFilter>;
  receiptUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  startedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  status?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  vbankCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  vbankDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  vbankHolder?: InputMaybe<StringNullableWithAggregatesFilter>;
  vbankIssuedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  vbankName?: InputMaybe<StringNullableWithAggregatesFilter>;
  vbankNum?: InputMaybe<StringNullableWithAggregatesFilter>;
}

export interface PayTransactionSumAggregateOutputType {
  __typename?: 'PayTransactionSumAggregateOutputType';
  amount?: Maybe<Scalars['Int']['output']>;
  cancelAmount?: Maybe<Scalars['Int']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
}

export interface PayTransactionSumOrderByAggregateInput {
  amount?: InputMaybe<SortOrder>;
  cancelAmount?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface PayTransactionUncheckedCreateInput {
  amount?: InputMaybe<Scalars['Int']['input']>;
  buyerAddr?: InputMaybe<Scalars['String']['input']>;
  buyerEmail?: InputMaybe<Scalars['String']['input']>;
  buyerName?: InputMaybe<Scalars['String']['input']>;
  buyerPostcode?: InputMaybe<Scalars['String']['input']>;
  buyerTel?: InputMaybe<Scalars['String']['input']>;
  cancelAmount?: InputMaybe<Scalars['Int']['input']>;
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['DateTime']['input']>;
  cashReceiptIssued?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  customerUid?: InputMaybe<Scalars['String']['input']>;
  failReason?: InputMaybe<Scalars['String']['input']>;
  failedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impUid: Scalars['String']['input'];
  merchantUid?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paidAt?: InputMaybe<Scalars['DateTime']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  pgTid?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vbankCode?: InputMaybe<Scalars['String']['input']>;
  vbankDate?: InputMaybe<Scalars['DateTime']['input']>;
  vbankHolder?: InputMaybe<Scalars['String']['input']>;
  vbankIssuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vbankName?: InputMaybe<Scalars['String']['input']>;
  vbankNum?: InputMaybe<Scalars['String']['input']>;
}

export interface PayTransactionUncheckedCreateNestedManyWithoutCustomerInput {
  connect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PayTransactionCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<PayTransactionCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<PayTransactionCreateManyCustomerInputEnvelope>;
}

export interface PayTransactionUncheckedCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PayTransactionCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<PayTransactionCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<PayTransactionCreateManyProjectInputEnvelope>;
}

export interface PayTransactionUncheckedCreateWithoutCustomerInput {
  amount?: InputMaybe<Scalars['Int']['input']>;
  buyerAddr?: InputMaybe<Scalars['String']['input']>;
  buyerEmail?: InputMaybe<Scalars['String']['input']>;
  buyerName?: InputMaybe<Scalars['String']['input']>;
  buyerPostcode?: InputMaybe<Scalars['String']['input']>;
  buyerTel?: InputMaybe<Scalars['String']['input']>;
  cancelAmount?: InputMaybe<Scalars['Int']['input']>;
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['DateTime']['input']>;
  cashReceiptIssued?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerUid?: InputMaybe<Scalars['String']['input']>;
  failReason?: InputMaybe<Scalars['String']['input']>;
  failedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impUid: Scalars['String']['input'];
  merchantUid?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paidAt?: InputMaybe<Scalars['DateTime']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  pgTid?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vbankCode?: InputMaybe<Scalars['String']['input']>;
  vbankDate?: InputMaybe<Scalars['DateTime']['input']>;
  vbankHolder?: InputMaybe<Scalars['String']['input']>;
  vbankIssuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vbankName?: InputMaybe<Scalars['String']['input']>;
  vbankNum?: InputMaybe<Scalars['String']['input']>;
}

export interface PayTransactionUncheckedCreateWithoutProjectInput {
  amount?: InputMaybe<Scalars['Int']['input']>;
  buyerAddr?: InputMaybe<Scalars['String']['input']>;
  buyerEmail?: InputMaybe<Scalars['String']['input']>;
  buyerName?: InputMaybe<Scalars['String']['input']>;
  buyerPostcode?: InputMaybe<Scalars['String']['input']>;
  buyerTel?: InputMaybe<Scalars['String']['input']>;
  cancelAmount?: InputMaybe<Scalars['Int']['input']>;
  cancelReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['DateTime']['input']>;
  cashReceiptIssued?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  customerUid?: InputMaybe<Scalars['String']['input']>;
  failReason?: InputMaybe<Scalars['String']['input']>;
  failedAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  impUid: Scalars['String']['input'];
  merchantUid?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paidAt?: InputMaybe<Scalars['DateTime']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  pgTid?: InputMaybe<Scalars['String']['input']>;
  receiptUrl?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vbankCode?: InputMaybe<Scalars['String']['input']>;
  vbankDate?: InputMaybe<Scalars['DateTime']['input']>;
  vbankHolder?: InputMaybe<Scalars['String']['input']>;
  vbankIssuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vbankName?: InputMaybe<Scalars['String']['input']>;
  vbankNum?: InputMaybe<Scalars['String']['input']>;
}

export interface PayTransactionUncheckedUpdateInput {
  amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  buyerAddr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cancelReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelledAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cashReceiptIssued?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  customerUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  impUid?: InputMaybe<StringFieldUpdateOperationsInput>;
  merchantUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paidAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pgTid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  receiptUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vbankCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankIssuedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankNum?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface PayTransactionUncheckedUpdateManyInput {
  amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  buyerAddr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cancelReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelledAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cashReceiptIssued?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  customerUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  impUid?: InputMaybe<StringFieldUpdateOperationsInput>;
  merchantUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paidAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pgTid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  receiptUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vbankCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankIssuedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankNum?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface PayTransactionUncheckedUpdateManyWithoutCustomerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PayTransactionCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<PayTransactionCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<PayTransactionCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<PayTransactionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<PayTransactionUpdateWithWhereUniqueWithoutCustomerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<PayTransactionUpdateManyWithWhereWithoutCustomerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<PayTransactionUpsertWithWhereUniqueWithoutCustomerInput>>>;
}

export interface PayTransactionUncheckedUpdateManyWithoutPayTransactionInput {
  amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  buyerAddr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cancelReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelledAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cashReceiptIssued?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  impUid?: InputMaybe<StringFieldUpdateOperationsInput>;
  merchantUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paidAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pgTid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  receiptUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vbankCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankIssuedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankNum?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface PayTransactionUncheckedUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PayTransactionCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<PayTransactionCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<PayTransactionCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<PayTransactionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<PayTransactionUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<PayTransactionUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<PayTransactionUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface PayTransactionUncheckedUpdateWithoutCustomerInput {
  amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  buyerAddr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cancelReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelledAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cashReceiptIssued?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  impUid?: InputMaybe<StringFieldUpdateOperationsInput>;
  merchantUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paidAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pgTid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  receiptUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vbankCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankIssuedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankNum?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface PayTransactionUncheckedUpdateWithoutProjectInput {
  amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  buyerAddr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cancelReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelledAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cashReceiptIssued?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  customerUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  impUid?: InputMaybe<StringFieldUpdateOperationsInput>;
  merchantUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paidAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pgTid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiptUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vbankCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankIssuedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankNum?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface PayTransactionUpdateInput {
  Customer?: InputMaybe<CustomerUpdateOneWithoutPayTransactionNestedInput>;
  Project?: InputMaybe<ProjectUpdateOneWithoutPayTransactionNestedInput>;
  amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  buyerAddr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cancelReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelledAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cashReceiptIssued?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  impUid?: InputMaybe<StringFieldUpdateOperationsInput>;
  merchantUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paidAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pgTid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiptUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vbankCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankIssuedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankNum?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface PayTransactionUpdateManyMutationInput {
  amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  buyerAddr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cancelReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelledAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cashReceiptIssued?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  impUid?: InputMaybe<StringFieldUpdateOperationsInput>;
  merchantUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paidAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pgTid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiptUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vbankCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankIssuedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankNum?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface PayTransactionUpdateManyWithWhereWithoutCustomerInput {
  data: PayTransactionUncheckedUpdateManyWithoutPayTransactionInput;
  where: PayTransactionScalarWhereInput;
}

export interface PayTransactionUpdateManyWithWhereWithoutProjectInput {
  data: PayTransactionUncheckedUpdateManyWithoutPayTransactionInput;
  where: PayTransactionScalarWhereInput;
}

export interface PayTransactionUpdateManyWithoutCustomerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PayTransactionCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<PayTransactionCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<PayTransactionCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<PayTransactionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<PayTransactionUpdateWithWhereUniqueWithoutCustomerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<PayTransactionUpdateManyWithWhereWithoutCustomerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<PayTransactionUpsertWithWhereUniqueWithoutCustomerInput>>>;
}

export interface PayTransactionUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PayTransactionCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<PayTransactionCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<PayTransactionCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<PayTransactionScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<PayTransactionWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<PayTransactionUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<PayTransactionUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<PayTransactionUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface PayTransactionUpdateWithWhereUniqueWithoutCustomerInput {
  data: PayTransactionUncheckedUpdateWithoutCustomerInput;
  where: PayTransactionWhereUniqueInput;
}

export interface PayTransactionUpdateWithWhereUniqueWithoutProjectInput {
  data: PayTransactionUncheckedUpdateWithoutProjectInput;
  where: PayTransactionWhereUniqueInput;
}

export interface PayTransactionUpdateWithoutCustomerInput {
  Project?: InputMaybe<ProjectUpdateOneWithoutPayTransactionNestedInput>;
  amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  buyerAddr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cancelReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelledAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cashReceiptIssued?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  impUid?: InputMaybe<StringFieldUpdateOperationsInput>;
  merchantUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paidAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pgTid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiptUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vbankCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankIssuedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankNum?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface PayTransactionUpdateWithoutProjectInput {
  Customer?: InputMaybe<CustomerUpdateOneWithoutPayTransactionNestedInput>;
  amount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  buyerAddr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  buyerTel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelAmount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  cancelReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cancelledAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  cashReceiptIssued?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  channel?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  failedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  impUid?: InputMaybe<StringFieldUpdateOperationsInput>;
  merchantUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paidAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pgTid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  receiptUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vbankCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankHolder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankIssuedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  vbankName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vbankNum?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface PayTransactionUpsertWithWhereUniqueWithoutCustomerInput {
  create: PayTransactionUncheckedCreateWithoutCustomerInput;
  update: PayTransactionUncheckedUpdateWithoutCustomerInput;
  where: PayTransactionWhereUniqueInput;
}

export interface PayTransactionUpsertWithWhereUniqueWithoutProjectInput {
  create: PayTransactionUncheckedCreateWithoutProjectInput;
  update: PayTransactionUncheckedUpdateWithoutProjectInput;
  where: PayTransactionWhereUniqueInput;
}

export interface PayTransactionWhereInput {
  AND?: InputMaybe<Array<InputMaybe<PayTransactionWhereInput>>>;
  Customer?: InputMaybe<CustomerWhereInput>;
  NOT?: InputMaybe<Array<InputMaybe<PayTransactionWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<PayTransactionWhereInput>>>;
  Project?: InputMaybe<ProjectWhereInput>;
  amount?: InputMaybe<IntNullableFilter>;
  buyerAddr?: InputMaybe<StringNullableFilter>;
  buyerEmail?: InputMaybe<StringNullableFilter>;
  buyerName?: InputMaybe<StringNullableFilter>;
  buyerPostcode?: InputMaybe<StringNullableFilter>;
  buyerTel?: InputMaybe<StringNullableFilter>;
  cancelAmount?: InputMaybe<IntNullableFilter>;
  cancelReason?: InputMaybe<StringNullableFilter>;
  cancelledAt?: InputMaybe<DateTimeNullableFilter>;
  cashReceiptIssued?: InputMaybe<BoolNullableFilter>;
  channel?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<IntNullableFilter>;
  customerUid?: InputMaybe<StringNullableFilter>;
  failReason?: InputMaybe<StringNullableFilter>;
  failedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  impUid?: InputMaybe<StringFilter>;
  merchantUid?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  paidAt?: InputMaybe<DateTimeNullableFilter>;
  paymentImpUid?: InputMaybe<StringNullableFilter>;
  pgTid?: InputMaybe<StringNullableFilter>;
  projectId?: InputMaybe<IntNullableFilter>;
  receiptUrl?: InputMaybe<StringNullableFilter>;
  startedAt?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  uuid?: InputMaybe<StringFilter>;
  vbankCode?: InputMaybe<StringNullableFilter>;
  vbankDate?: InputMaybe<DateTimeNullableFilter>;
  vbankHolder?: InputMaybe<StringNullableFilter>;
  vbankIssuedAt?: InputMaybe<DateTimeNullableFilter>;
  vbankName?: InputMaybe<StringNullableFilter>;
  vbankNum?: InputMaybe<StringNullableFilter>;
}

export interface PayTransactionWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface Personnel {
  __typename?: 'Personnel';
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  introduce: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profile: Profile;
  profileId: Scalars['Int']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}

export interface PersonnelAvgAggregateOutputType {
  __typename?: 'PersonnelAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  profileId?: Maybe<Scalars['Float']['output']>;
}

export interface PersonnelAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
}

export interface PersonnelCountAggregateOutputType {
  __typename?: 'PersonnelCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  introduce: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  profileId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface PersonnelCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  introduce?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface PersonnelCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  introduce: Scalars['String']['input'];
  name: Scalars['String']['input'];
  profile: ProfileCreateNestedOneWithoutCorePersonnelInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface PersonnelCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  introduce: Scalars['String']['input'];
  name: Scalars['String']['input'];
  profileId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface PersonnelCreateManyProfileInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  introduce: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface PersonnelCreateManyProfileInputEnvelope {
  data: PersonnelCreateManyProfileInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface PersonnelCreateNestedManyWithoutProfileInput {
  connect?: InputMaybe<Array<InputMaybe<PersonnelWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PersonnelCreateOrConnectWithoutProfileInput>>>;
  create?: InputMaybe<Array<InputMaybe<PersonnelCreateWithoutProfileInput>>>;
  createMany?: InputMaybe<PersonnelCreateManyProfileInputEnvelope>;
}

export interface PersonnelCreateOrConnectWithoutProfileInput {
  create: PersonnelUncheckedCreateWithoutProfileInput;
  where: PersonnelWhereUniqueInput;
}

export interface PersonnelCreateWithoutProfileInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  introduce: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface PersonnelListRelationFilter {
  every?: InputMaybe<PersonnelWhereInput>;
  none?: InputMaybe<PersonnelWhereInput>;
  some?: InputMaybe<PersonnelWhereInput>;
}

export interface PersonnelMaxAggregateOutputType {
  __typename?: 'PersonnelMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  introduce?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profileId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface PersonnelMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  introduce?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface PersonnelMinAggregateOutputType {
  __typename?: 'PersonnelMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  introduce?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profileId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface PersonnelMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  introduce?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface PersonnelOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface PersonnelOrderByWithAggregationInput {
  _avg?: InputMaybe<PersonnelAvgOrderByAggregateInput>;
  _count?: InputMaybe<PersonnelCountOrderByAggregateInput>;
  _max?: InputMaybe<PersonnelMaxOrderByAggregateInput>;
  _min?: InputMaybe<PersonnelMinOrderByAggregateInput>;
  _sum?: InputMaybe<PersonnelSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  introduce?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface PersonnelOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  introduce?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByWithRelationInput>;
  profileId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export enum PersonnelScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Image = 'image',
  Introduce = 'introduce',
  Name = 'name',
  ProfileId = 'profileId',
  UpdatedAt = 'updatedAt'
}

export interface PersonnelScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<PersonnelScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<PersonnelScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<PersonnelScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  introduce?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  profileId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface PersonnelScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<PersonnelScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<PersonnelScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<PersonnelScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  image?: InputMaybe<StringNullableWithAggregatesFilter>;
  introduce?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  profileId?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface PersonnelSumAggregateOutputType {
  __typename?: 'PersonnelSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  profileId?: Maybe<Scalars['Int']['output']>;
}

export interface PersonnelSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  profileId?: InputMaybe<SortOrder>;
}

export interface PersonnelUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  introduce: Scalars['String']['input'];
  name: Scalars['String']['input'];
  profileId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface PersonnelUncheckedCreateNestedManyWithoutProfileInput {
  connect?: InputMaybe<Array<InputMaybe<PersonnelWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PersonnelCreateOrConnectWithoutProfileInput>>>;
  create?: InputMaybe<Array<InputMaybe<PersonnelCreateWithoutProfileInput>>>;
  createMany?: InputMaybe<PersonnelCreateManyProfileInputEnvelope>;
}

export interface PersonnelUncheckedCreateWithoutProfileInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  introduce: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface PersonnelUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  introduce?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  profileId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface PersonnelUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  introduce?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  profileId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface PersonnelUncheckedUpdateManyWithoutCorePersonnelInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  introduce?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface PersonnelUncheckedUpdateManyWithoutProfileNestedInput {
  connect?: InputMaybe<Array<InputMaybe<PersonnelWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PersonnelCreateOrConnectWithoutProfileInput>>>;
  create?: InputMaybe<Array<InputMaybe<PersonnelCreateWithoutProfileInput>>>;
  createMany?: InputMaybe<PersonnelCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<PersonnelWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<PersonnelScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<PersonnelWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<PersonnelWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<PersonnelUpdateWithWhereUniqueWithoutProfileInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<PersonnelUpdateManyWithWhereWithoutProfileInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<PersonnelUpsertWithWhereUniqueWithoutProfileInput>>>;
}

export interface PersonnelUncheckedUpdateWithoutProfileInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  introduce?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface PersonnelUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  introduce?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateOneRequiredWithoutCorePersonnelNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface PersonnelUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  introduce?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface PersonnelUpdateManyWithWhereWithoutProfileInput {
  data: PersonnelUncheckedUpdateManyWithoutCorePersonnelInput;
  where: PersonnelScalarWhereInput;
}

export interface PersonnelUpdateManyWithoutProfileNestedInput {
  connect?: InputMaybe<Array<InputMaybe<PersonnelWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<PersonnelCreateOrConnectWithoutProfileInput>>>;
  create?: InputMaybe<Array<InputMaybe<PersonnelCreateWithoutProfileInput>>>;
  createMany?: InputMaybe<PersonnelCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<PersonnelWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<PersonnelScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<PersonnelWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<PersonnelWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<PersonnelUpdateWithWhereUniqueWithoutProfileInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<PersonnelUpdateManyWithWhereWithoutProfileInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<PersonnelUpsertWithWhereUniqueWithoutProfileInput>>>;
}

export interface PersonnelUpdateWithWhereUniqueWithoutProfileInput {
  data: PersonnelUncheckedUpdateWithoutProfileInput;
  where: PersonnelWhereUniqueInput;
}

export interface PersonnelUpdateWithoutProfileInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  introduce?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface PersonnelUpsertWithWhereUniqueWithoutProfileInput {
  create: PersonnelUncheckedCreateWithoutProfileInput;
  update: PersonnelUncheckedUpdateWithoutProfileInput;
  where: PersonnelWhereUniqueInput;
}

export interface PersonnelWhereInput {
  AND?: InputMaybe<Array<InputMaybe<PersonnelWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<PersonnelWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<PersonnelWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  introduce?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  profile?: InputMaybe<ProfileWhereInput>;
  profileId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface PersonnelWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProcessSimulation {
  __typename?: 'ProcessSimulation';
  _count: ProcessSimulationCountOutputType;
  /** cell data */
  cellData: Array<ProcessTaskCell>;
  completionData?: Maybe<Scalars['String']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  project: Project;
  projectUuid: Scalars['String']['output'];
  startData?: Maybe<Scalars['String']['output']>;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Project Unique Key */
  uuid: Scalars['String']['output'];
}


export type ProcessSimulationCellDataArgs = {
  cursor?: InputMaybe<ProcessTaskCellWhereUniqueInput>;
  distinct?: InputMaybe<ProcessTaskCellScalarFieldEnum>;
  orderBy?: InputMaybe<ProcessTaskCellOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProcessTaskCellWhereInput>;
};

export interface ProcessSimulationAvgAggregateOutputType {
  __typename?: 'ProcessSimulationAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
}

export interface ProcessSimulationAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface ProcessSimulationCountAggregateOutputType {
  __typename?: 'ProcessSimulationCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  completionData: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  projectUuid: Scalars['Int']['output'];
  startData: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface ProcessSimulationCountOrderByAggregateInput {
  completionData?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectUuid?: InputMaybe<SortOrder>;
  startData?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProcessSimulationCountOutputType {
  __typename?: 'ProcessSimulationCountOutputType';
  cellData: Scalars['Int']['output'];
}

export interface ProcessSimulationCreateInput {
  cellData?: InputMaybe<ProcessTaskCellCreateNestedManyWithoutProcessSimulationInput>;
  completionData?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  project: ProjectCreateNestedOneWithoutProcessSimulationInput;
  startData?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProcessSimulationCreateManyInput {
  completionData?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  projectUuid: Scalars['String']['input'];
  startData?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProcessSimulationCreateNestedOneWithoutCellDataInput {
  connect?: InputMaybe<ProcessSimulationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProcessSimulationCreateOrConnectWithoutCellDataInput>;
  create?: InputMaybe<ProcessSimulationUncheckedCreateWithoutCellDataInput>;
}

export interface ProcessSimulationCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<ProcessSimulationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProcessSimulationCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProcessSimulationUncheckedCreateWithoutProjectInput>;
}

export interface ProcessSimulationCreateOrConnectWithoutCellDataInput {
  create: ProcessSimulationUncheckedCreateWithoutCellDataInput;
  where: ProcessSimulationWhereUniqueInput;
}

export interface ProcessSimulationCreateOrConnectWithoutProjectInput {
  create: ProcessSimulationUncheckedCreateWithoutProjectInput;
  where: ProcessSimulationWhereUniqueInput;
}

export interface ProcessSimulationCreateWithoutCellDataInput {
  completionData?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  project: ProjectCreateNestedOneWithoutProcessSimulationInput;
  startData?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProcessSimulationCreateWithoutProjectInput {
  cellData?: InputMaybe<ProcessTaskCellCreateNestedManyWithoutProcessSimulationInput>;
  completionData?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  startData?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProcessSimulationMaxAggregateOutputType {
  __typename?: 'ProcessSimulationMaxAggregateOutputType';
  completionData?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectUuid?: Maybe<Scalars['String']['output']>;
  startData?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProcessSimulationMaxOrderByAggregateInput {
  completionData?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectUuid?: InputMaybe<SortOrder>;
  startData?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProcessSimulationMinAggregateOutputType {
  __typename?: 'ProcessSimulationMinAggregateOutputType';
  completionData?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectUuid?: Maybe<Scalars['String']['output']>;
  startData?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProcessSimulationMinOrderByAggregateInput {
  completionData?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectUuid?: InputMaybe<SortOrder>;
  startData?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProcessSimulationOrderByWithAggregationInput {
  _avg?: InputMaybe<ProcessSimulationAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProcessSimulationCountOrderByAggregateInput>;
  _max?: InputMaybe<ProcessSimulationMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProcessSimulationMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProcessSimulationSumOrderByAggregateInput>;
  completionData?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectUuid?: InputMaybe<SortOrder>;
  startData?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProcessSimulationOrderByWithRelationInput {
  cellData?: InputMaybe<ProcessTaskCellOrderByRelationAggregateInput>;
  completionData?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectUuid?: InputMaybe<SortOrder>;
  startData?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProcessSimulationRelationFilter {
  is?: InputMaybe<ProcessSimulationWhereInput>;
  isNot?: InputMaybe<ProcessSimulationWhereInput>;
}

export enum ProcessSimulationScalarFieldEnum {
  CompletionData = 'completionData',
  CreatedAt = 'createdAt',
  Id = 'id',
  ProjectUuid = 'projectUuid',
  StartData = 'startData',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface ProcessSimulationScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ProcessSimulationScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProcessSimulationScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProcessSimulationScalarWhereWithAggregatesInput>>>;
  completionData?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  projectUuid?: InputMaybe<StringWithAggregatesFilter>;
  startData?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface ProcessSimulationSumAggregateOutputType {
  __typename?: 'ProcessSimulationSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
}

export interface ProcessSimulationSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface ProcessSimulationUncheckedCreateInput {
  cellData?: InputMaybe<ProcessTaskCellUncheckedCreateNestedManyWithoutProcessSimulationInput>;
  completionData?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  projectUuid: Scalars['String']['input'];
  startData?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<ProcessSimulationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProcessSimulationCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProcessSimulationUncheckedCreateWithoutProjectInput>;
}

export interface ProcessSimulationUncheckedCreateWithoutCellDataInput {
  completionData?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  projectUuid: Scalars['String']['input'];
  startData?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProcessSimulationUncheckedCreateWithoutProjectInput {
  cellData?: InputMaybe<ProcessTaskCellUncheckedCreateNestedManyWithoutProcessSimulationInput>;
  completionData?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  startData?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProcessSimulationUncheckedUpdateInput {
  cellData?: InputMaybe<ProcessTaskCellUncheckedUpdateManyWithoutProcessSimulationNestedInput>;
  completionData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectUuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  startData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProcessSimulationUncheckedUpdateManyInput {
  completionData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectUuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  startData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<ProcessSimulationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProcessSimulationCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProcessSimulationUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProcessSimulationUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ProcessSimulationUpsertWithoutProjectInput>;
}

export interface ProcessSimulationUncheckedUpdateWithoutCellDataInput {
  completionData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectUuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  startData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProcessSimulationUncheckedUpdateWithoutProjectInput {
  cellData?: InputMaybe<ProcessTaskCellUncheckedUpdateManyWithoutProcessSimulationNestedInput>;
  completionData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  startData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProcessSimulationUpdateInput {
  cellData?: InputMaybe<ProcessTaskCellUpdateManyWithoutProcessSimulationNestedInput>;
  completionData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutProcessSimulationNestedInput>;
  startData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProcessSimulationUpdateManyMutationInput {
  completionData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  startData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProcessSimulationUpdateOneWithoutCellDataNestedInput {
  connect?: InputMaybe<ProcessSimulationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProcessSimulationCreateOrConnectWithoutCellDataInput>;
  create?: InputMaybe<ProcessSimulationUncheckedCreateWithoutCellDataInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProcessSimulationUncheckedUpdateWithoutCellDataInput>;
  upsert?: InputMaybe<ProcessSimulationUpsertWithoutCellDataInput>;
}

export interface ProcessSimulationUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<ProcessSimulationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProcessSimulationCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProcessSimulationUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProcessSimulationUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ProcessSimulationUpsertWithoutProjectInput>;
}

export interface ProcessSimulationUpdateWithoutCellDataInput {
  completionData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutProcessSimulationNestedInput>;
  startData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProcessSimulationUpdateWithoutProjectInput {
  cellData?: InputMaybe<ProcessTaskCellUpdateManyWithoutProcessSimulationNestedInput>;
  completionData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  startData?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProcessSimulationUpsertWithoutCellDataInput {
  create: ProcessSimulationUncheckedCreateWithoutCellDataInput;
  update: ProcessSimulationUncheckedUpdateWithoutCellDataInput;
}

export interface ProcessSimulationUpsertWithoutProjectInput {
  create: ProcessSimulationUncheckedCreateWithoutProjectInput;
  update: ProcessSimulationUncheckedUpdateWithoutProjectInput;
}

export interface ProcessSimulationWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProcessSimulationWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProcessSimulationWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProcessSimulationWhereInput>>>;
  cellData?: InputMaybe<ProcessTaskCellListRelationFilter>;
  completionData?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  project?: InputMaybe<ProjectWhereInput>;
  projectUuid?: InputMaybe<StringFilter>;
  startData?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ProcessSimulationWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  projectUuid?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProcessTaskCell {
  __typename?: 'ProcessTaskCell';
  /** client cell id */
  cellId: Scalars['Int']['output'];
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** saved filter states */
  filterState?: Maybe<Scalars['Json']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  /** base64 string */
  onSitePicture?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Scalars['String']['output']>;
  processSimulation?: Maybe<ProcessSimulation>;
  processSimulationId?: Maybe<Scalars['Int']['output']>;
  /** Screenshot base64 string */
  screenShot?: Maybe<Scalars['String']['output']>;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Project Unique Key */
  uuid: Scalars['String']['output'];
  /**
   * saved view point and location
   * {
   * setEye: { x: number; y: number; z: number };
   * setTarget: { targetX: number; targetY: number; targetZ: number };
   * setVector: { x: number; y: number; z: number };
   * };
   */
  viewState?: Maybe<Scalars['Json']['output']>;
}

export interface ProcessTaskCellAvgAggregateOutputType {
  __typename?: 'ProcessTaskCellAvgAggregateOutputType';
  cellId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  processSimulationId?: Maybe<Scalars['Float']['output']>;
}

export interface ProcessTaskCellAvgOrderByAggregateInput {
  cellId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  processSimulationId?: InputMaybe<SortOrder>;
}

export interface ProcessTaskCellCountAggregateOutputType {
  __typename?: 'ProcessTaskCellCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  cellId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  filterState: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  memo: Scalars['Int']['output'];
  onSitePicture: Scalars['Int']['output'];
  plan: Scalars['Int']['output'];
  process: Scalars['Int']['output'];
  processSimulationId: Scalars['Int']['output'];
  screenShot: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  viewState: Scalars['Int']['output'];
}

export interface ProcessTaskCellCountOrderByAggregateInput {
  cellId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  filterState?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  onSitePicture?: InputMaybe<SortOrder>;
  plan?: InputMaybe<SortOrder>;
  process?: InputMaybe<SortOrder>;
  processSimulationId?: InputMaybe<SortOrder>;
  screenShot?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  viewState?: InputMaybe<SortOrder>;
}

export interface ProcessTaskCellCreateInput {
  cellId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  onSitePicture?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  process?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutCellDataInput>;
  screenShot?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellCreateManyInput {
  cellId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  onSitePicture?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  process?: InputMaybe<Scalars['String']['input']>;
  processSimulationId?: InputMaybe<Scalars['Int']['input']>;
  screenShot?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellCreateManyProcessSimulationInput {
  cellId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  onSitePicture?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  process?: InputMaybe<Scalars['String']['input']>;
  screenShot?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellCreateManyProcessSimulationInputEnvelope {
  data: ProcessTaskCellCreateManyProcessSimulationInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProcessTaskCellCreateNestedManyWithoutProcessSimulationInput {
  connect?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProcessTaskCellCreateOrConnectWithoutProcessSimulationInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProcessTaskCellCreateWithoutProcessSimulationInput>>>;
  createMany?: InputMaybe<ProcessTaskCellCreateManyProcessSimulationInputEnvelope>;
}

export interface ProcessTaskCellCreateOrConnectWithoutProcessSimulationInput {
  create: ProcessTaskCellUncheckedCreateWithoutProcessSimulationInput;
  where: ProcessTaskCellWhereUniqueInput;
}

export interface ProcessTaskCellCreateWithoutProcessSimulationInput {
  cellId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  onSitePicture?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  process?: InputMaybe<Scalars['String']['input']>;
  screenShot?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellListRelationFilter {
  every?: InputMaybe<ProcessTaskCellWhereInput>;
  none?: InputMaybe<ProcessTaskCellWhereInput>;
  some?: InputMaybe<ProcessTaskCellWhereInput>;
}

export interface ProcessTaskCellMaxAggregateOutputType {
  __typename?: 'ProcessTaskCellMaxAggregateOutputType';
  cellId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  onSitePicture?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Scalars['String']['output']>;
  processSimulationId?: Maybe<Scalars['Int']['output']>;
  screenShot?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProcessTaskCellMaxOrderByAggregateInput {
  cellId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  onSitePicture?: InputMaybe<SortOrder>;
  plan?: InputMaybe<SortOrder>;
  process?: InputMaybe<SortOrder>;
  processSimulationId?: InputMaybe<SortOrder>;
  screenShot?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProcessTaskCellMinAggregateOutputType {
  __typename?: 'ProcessTaskCellMinAggregateOutputType';
  cellId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  onSitePicture?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Scalars['String']['output']>;
  processSimulationId?: Maybe<Scalars['Int']['output']>;
  screenShot?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProcessTaskCellMinOrderByAggregateInput {
  cellId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  onSitePicture?: InputMaybe<SortOrder>;
  plan?: InputMaybe<SortOrder>;
  process?: InputMaybe<SortOrder>;
  processSimulationId?: InputMaybe<SortOrder>;
  screenShot?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProcessTaskCellOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ProcessTaskCellOrderByWithAggregationInput {
  _avg?: InputMaybe<ProcessTaskCellAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProcessTaskCellCountOrderByAggregateInput>;
  _max?: InputMaybe<ProcessTaskCellMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProcessTaskCellMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProcessTaskCellSumOrderByAggregateInput>;
  cellId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  filterState?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrderInput>;
  onSitePicture?: InputMaybe<SortOrderInput>;
  plan?: InputMaybe<SortOrderInput>;
  process?: InputMaybe<SortOrderInput>;
  processSimulationId?: InputMaybe<SortOrderInput>;
  screenShot?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  viewState?: InputMaybe<SortOrderInput>;
}

export interface ProcessTaskCellOrderByWithRelationInput {
  cellId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  filterState?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrderInput>;
  onSitePicture?: InputMaybe<SortOrderInput>;
  plan?: InputMaybe<SortOrderInput>;
  process?: InputMaybe<SortOrderInput>;
  processSimulation?: InputMaybe<ProcessSimulationOrderByWithRelationInput>;
  processSimulationId?: InputMaybe<SortOrderInput>;
  screenShot?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  viewState?: InputMaybe<SortOrderInput>;
}

export enum ProcessTaskCellScalarFieldEnum {
  CellId = 'cellId',
  CreatedAt = 'createdAt',
  FilterState = 'filterState',
  Id = 'id',
  Memo = 'memo',
  OnSitePicture = 'onSitePicture',
  Plan = 'plan',
  Process = 'process',
  ProcessSimulationId = 'processSimulationId',
  ScreenShot = 'screenShot',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  ViewState = 'viewState'
}

export interface ProcessTaskCellScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProcessTaskCellScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProcessTaskCellScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProcessTaskCellScalarWhereInput>>>;
  cellId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  filterState?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<IntFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  onSitePicture?: InputMaybe<StringNullableFilter>;
  plan?: InputMaybe<StringNullableFilter>;
  process?: InputMaybe<StringNullableFilter>;
  processSimulationId?: InputMaybe<IntNullableFilter>;
  screenShot?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  viewState?: InputMaybe<JsonNullableFilter>;
}

export interface ProcessTaskCellScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ProcessTaskCellScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProcessTaskCellScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProcessTaskCellScalarWhereWithAggregatesInput>>>;
  cellId?: InputMaybe<IntWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  filterState?: InputMaybe<JsonNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  memo?: InputMaybe<StringNullableWithAggregatesFilter>;
  onSitePicture?: InputMaybe<StringNullableWithAggregatesFilter>;
  plan?: InputMaybe<StringNullableWithAggregatesFilter>;
  process?: InputMaybe<StringNullableWithAggregatesFilter>;
  processSimulationId?: InputMaybe<IntNullableWithAggregatesFilter>;
  screenShot?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  viewState?: InputMaybe<JsonNullableWithAggregatesFilter>;
}

export interface ProcessTaskCellSumAggregateOutputType {
  __typename?: 'ProcessTaskCellSumAggregateOutputType';
  cellId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  processSimulationId?: Maybe<Scalars['Int']['output']>;
}

export interface ProcessTaskCellSumOrderByAggregateInput {
  cellId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  processSimulationId?: InputMaybe<SortOrder>;
}

export interface ProcessTaskCellUncheckedCreateInput {
  cellId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  onSitePicture?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  process?: InputMaybe<Scalars['String']['input']>;
  processSimulationId?: InputMaybe<Scalars['Int']['input']>;
  screenShot?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellUncheckedCreateNestedManyWithoutProcessSimulationInput {
  connect?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProcessTaskCellCreateOrConnectWithoutProcessSimulationInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProcessTaskCellCreateWithoutProcessSimulationInput>>>;
  createMany?: InputMaybe<ProcessTaskCellCreateManyProcessSimulationInputEnvelope>;
}

export interface ProcessTaskCellUncheckedCreateWithoutProcessSimulationInput {
  cellId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  onSitePicture?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  process?: InputMaybe<Scalars['String']['input']>;
  screenShot?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellUncheckedUpdateInput {
  cellId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  onSitePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  plan?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  process?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulationId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  screenShot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellUncheckedUpdateManyInput {
  cellId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  onSitePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  plan?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  process?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulationId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  screenShot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellUncheckedUpdateManyWithoutCellDataInput {
  cellId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  onSitePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  plan?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  process?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  screenShot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellUncheckedUpdateManyWithoutProcessSimulationNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProcessTaskCellCreateOrConnectWithoutProcessSimulationInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProcessTaskCellCreateWithoutProcessSimulationInput>>>;
  createMany?: InputMaybe<ProcessTaskCellCreateManyProcessSimulationInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProcessTaskCellScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProcessTaskCellUpdateWithWhereUniqueWithoutProcessSimulationInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProcessTaskCellUpdateManyWithWhereWithoutProcessSimulationInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProcessTaskCellUpsertWithWhereUniqueWithoutProcessSimulationInput>>>;
}

export interface ProcessTaskCellUncheckedUpdateWithoutProcessSimulationInput {
  cellId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  onSitePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  plan?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  process?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  screenShot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellUpdateInput {
  cellId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  onSitePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  plan?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  process?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutCellDataNestedInput>;
  screenShot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellUpdateManyMutationInput {
  cellId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  onSitePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  plan?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  process?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  screenShot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellUpdateManyWithWhereWithoutProcessSimulationInput {
  data: ProcessTaskCellUncheckedUpdateManyWithoutCellDataInput;
  where: ProcessTaskCellScalarWhereInput;
}

export interface ProcessTaskCellUpdateManyWithoutProcessSimulationNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProcessTaskCellCreateOrConnectWithoutProcessSimulationInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProcessTaskCellCreateWithoutProcessSimulationInput>>>;
  createMany?: InputMaybe<ProcessTaskCellCreateManyProcessSimulationInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProcessTaskCellScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProcessTaskCellUpdateWithWhereUniqueWithoutProcessSimulationInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProcessTaskCellUpdateManyWithWhereWithoutProcessSimulationInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProcessTaskCellUpsertWithWhereUniqueWithoutProcessSimulationInput>>>;
}

export interface ProcessTaskCellUpdateWithWhereUniqueWithoutProcessSimulationInput {
  data: ProcessTaskCellUncheckedUpdateWithoutProcessSimulationInput;
  where: ProcessTaskCellWhereUniqueInput;
}

export interface ProcessTaskCellUpdateWithoutProcessSimulationInput {
  cellId?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  filterState?: InputMaybe<Scalars['Json']['input']>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  onSitePicture?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  plan?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  process?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  screenShot?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  viewState?: InputMaybe<Scalars['Json']['input']>;
}

export interface ProcessTaskCellUpsertWithWhereUniqueWithoutProcessSimulationInput {
  create: ProcessTaskCellUncheckedCreateWithoutProcessSimulationInput;
  update: ProcessTaskCellUncheckedUpdateWithoutProcessSimulationInput;
  where: ProcessTaskCellWhereUniqueInput;
}

export interface ProcessTaskCellUuidCellIdCompoundUniqueInput {
  cellId: Scalars['Int']['input'];
  uuid: Scalars['String']['input'];
}

export interface ProcessTaskCellWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProcessTaskCellWhereInput>>>;
  cellId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  filterState?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<IntFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  onSitePicture?: InputMaybe<StringNullableFilter>;
  plan?: InputMaybe<StringNullableFilter>;
  process?: InputMaybe<StringNullableFilter>;
  processSimulation?: InputMaybe<ProcessSimulationWhereInput>;
  processSimulationId?: InputMaybe<IntNullableFilter>;
  screenShot?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  viewState?: InputMaybe<JsonNullableFilter>;
}

export interface ProcessTaskCellWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  uuid_cellId?: InputMaybe<ProcessTaskCellUuidCellIdCompoundUniqueInput>;
}

export interface Profile {
  __typename?: 'Profile';
  Architect?: Maybe<Architect>;
  Construction?: Maybe<Construction>;
  GeneralConstruction?: Maybe<GeneralConstruction>;
  ImageUrl?: Maybe<Scalars['String']['output']>;
  Modeler?: Maybe<Modeler>;
  SpaceDesign?: Maybe<SpaceDesign>;
  Supplier?: Maybe<Supplier>;
  _count: ProfileCountOutputType;
  architectId?: Maybe<Scalars['Int']['output']>;
  constructionId?: Maybe<Scalars['Int']['output']>;
  corePersonnel: Array<Personnel>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  establishment?: Maybe<Scalars['Int']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  generalConstructionId?: Maybe<Scalars['Int']['output']>;
  homepage?: Maybe<Scalars['String']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  isCorePersonnel: Scalars['Boolean']['output'];
  isLink: Scalars['Boolean']['output'];
  isStory: Scalars['Boolean']['output'];
  keyword: Array<Keyword>;
  modelerId?: Maybe<Scalars['Int']['output']>;
  naverBlog?: Maybe<Scalars['String']['output']>;
  reasonForRejection?: Maybe<Scalars['String']['output']>;
  spaceDesignId?: Maybe<Scalars['Int']['output']>;
  status: ProfileStatus;
  story?: Maybe<Scalars['String']['output']>;
  supplierId?: Maybe<Scalars['Int']['output']>;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique ID */
  uuid: Scalars['String']['output'];
  youtube?: Maybe<Scalars['String']['output']>;
}


export type ProfileCorePersonnelArgs = {
  cursor?: InputMaybe<PersonnelWhereUniqueInput>;
  distinct?: InputMaybe<PersonnelScalarFieldEnum>;
  orderBy?: InputMaybe<PersonnelOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PersonnelWhereInput>;
};

export interface ProfileAvgAggregateOutputType {
  __typename?: 'ProfileAvgAggregateOutputType';
  architectId?: Maybe<Scalars['Float']['output']>;
  constructionId?: Maybe<Scalars['Float']['output']>;
  establishment?: Maybe<Scalars['Float']['output']>;
  generalConstructionId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  modelerId?: Maybe<Scalars['Float']['output']>;
  spaceDesignId?: Maybe<Scalars['Float']['output']>;
  supplierId?: Maybe<Scalars['Float']['output']>;
}

export interface ProfileAvgOrderByAggregateInput {
  architectId?: InputMaybe<SortOrder>;
  constructionId?: InputMaybe<SortOrder>;
  establishment?: InputMaybe<SortOrder>;
  generalConstructionId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modelerId?: InputMaybe<SortOrder>;
  spaceDesignId?: InputMaybe<SortOrder>;
  supplierId?: InputMaybe<SortOrder>;
}

export interface ProfileCountAggregateOutputType {
  __typename?: 'ProfileCountAggregateOutputType';
  ImageUrl: Scalars['Int']['output'];
  _all: Scalars['Int']['output'];
  architectId: Scalars['Int']['output'];
  constructionId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  establishment: Scalars['Int']['output'];
  fileName: Scalars['Int']['output'];
  fileUrl: Scalars['Int']['output'];
  generalConstructionId: Scalars['Int']['output'];
  homepage: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  instagram: Scalars['Int']['output'];
  isCorePersonnel: Scalars['Int']['output'];
  isLink: Scalars['Int']['output'];
  isStory: Scalars['Int']['output'];
  keyword: Scalars['Int']['output'];
  modelerId: Scalars['Int']['output'];
  naverBlog: Scalars['Int']['output'];
  reasonForRejection: Scalars['Int']['output'];
  spaceDesignId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  story: Scalars['Int']['output'];
  supplierId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  youtube: Scalars['Int']['output'];
}

export interface ProfileCountOrderByAggregateInput {
  ImageUrl?: InputMaybe<SortOrder>;
  architectId?: InputMaybe<SortOrder>;
  constructionId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  establishment?: InputMaybe<SortOrder>;
  fileName?: InputMaybe<SortOrder>;
  fileUrl?: InputMaybe<SortOrder>;
  generalConstructionId?: InputMaybe<SortOrder>;
  homepage?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram?: InputMaybe<SortOrder>;
  isCorePersonnel?: InputMaybe<SortOrder>;
  isLink?: InputMaybe<SortOrder>;
  isStory?: InputMaybe<SortOrder>;
  keyword?: InputMaybe<SortOrder>;
  modelerId?: InputMaybe<SortOrder>;
  naverBlog?: InputMaybe<SortOrder>;
  reasonForRejection?: InputMaybe<SortOrder>;
  spaceDesignId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  story?: InputMaybe<SortOrder>;
  supplierId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  youtube?: InputMaybe<SortOrder>;
}

export interface ProfileCountOutputType {
  __typename?: 'ProfileCountOutputType';
  corePersonnel: Scalars['Int']['output'];
}

export interface ProfileCreateInput {
  Architect?: InputMaybe<ArchitectCreateNestedOneWithoutProfileInput>;
  Construction?: InputMaybe<ConstructionCreateNestedOneWithoutProfileInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedOneWithoutProfileInput>;
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  Modeler?: InputMaybe<ModelerCreateNestedOneWithoutProfileInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedOneWithoutProfileInput>;
  Supplier?: InputMaybe<SupplierCreateNestedOneWithoutProfileInput>;
  corePersonnel?: InputMaybe<PersonnelCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateManyArchitectInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateManyArchitectInputEnvelope {
  data: ProfileCreateManyArchitectInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProfileCreateManyConstructionInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateManyConstructionInputEnvelope {
  data: ProfileCreateManyConstructionInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProfileCreateManyGeneralConstructionInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateManyGeneralConstructionInputEnvelope {
  data: ProfileCreateManyGeneralConstructionInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProfileCreateManyInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateManyModelerInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateManyModelerInputEnvelope {
  data: ProfileCreateManyModelerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProfileCreateManySpaceDesignInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateManySpaceDesignInputEnvelope {
  data: ProfileCreateManySpaceDesignInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProfileCreateManySupplierInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateManySupplierInputEnvelope {
  data: ProfileCreateManySupplierInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProfileCreateNestedManyWithoutArchitectInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutArchitectInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutArchitectInput>>>;
  createMany?: InputMaybe<ProfileCreateManyArchitectInputEnvelope>;
}

export interface ProfileCreateNestedManyWithoutConstructionInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutConstructionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutConstructionInput>>>;
  createMany?: InputMaybe<ProfileCreateManyConstructionInputEnvelope>;
}

export interface ProfileCreateNestedManyWithoutGeneralConstructionInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutGeneralConstructionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutGeneralConstructionInput>>>;
  createMany?: InputMaybe<ProfileCreateManyGeneralConstructionInputEnvelope>;
}

export interface ProfileCreateNestedManyWithoutModelerInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutModelerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutModelerInput>>>;
  createMany?: InputMaybe<ProfileCreateManyModelerInputEnvelope>;
}

export interface ProfileCreateNestedManyWithoutSpaceDesignInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutSpaceDesignInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutSpaceDesignInput>>>;
  createMany?: InputMaybe<ProfileCreateManySpaceDesignInputEnvelope>;
}

export interface ProfileCreateNestedManyWithoutSupplierInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutSupplierInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutSupplierInput>>>;
  createMany?: InputMaybe<ProfileCreateManySupplierInputEnvelope>;
}

export interface ProfileCreateNestedOneWithoutCorePersonnelInput {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutCorePersonnelInput>;
  create?: InputMaybe<ProfileUncheckedCreateWithoutCorePersonnelInput>;
}

export interface ProfileCreateOrConnectWithoutArchitectInput {
  create: ProfileUncheckedCreateWithoutArchitectInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileCreateOrConnectWithoutConstructionInput {
  create: ProfileUncheckedCreateWithoutConstructionInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileCreateOrConnectWithoutCorePersonnelInput {
  create: ProfileUncheckedCreateWithoutCorePersonnelInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileCreateOrConnectWithoutGeneralConstructionInput {
  create: ProfileUncheckedCreateWithoutGeneralConstructionInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileCreateOrConnectWithoutModelerInput {
  create: ProfileUncheckedCreateWithoutModelerInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileCreateOrConnectWithoutSpaceDesignInput {
  create: ProfileUncheckedCreateWithoutSpaceDesignInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileCreateOrConnectWithoutSupplierInput {
  create: ProfileUncheckedCreateWithoutSupplierInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileCreateWithoutArchitectInput {
  Construction?: InputMaybe<ConstructionCreateNestedOneWithoutProfileInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedOneWithoutProfileInput>;
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  Modeler?: InputMaybe<ModelerCreateNestedOneWithoutProfileInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedOneWithoutProfileInput>;
  Supplier?: InputMaybe<SupplierCreateNestedOneWithoutProfileInput>;
  corePersonnel?: InputMaybe<PersonnelCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateWithoutConstructionInput {
  Architect?: InputMaybe<ArchitectCreateNestedOneWithoutProfileInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedOneWithoutProfileInput>;
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  Modeler?: InputMaybe<ModelerCreateNestedOneWithoutProfileInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedOneWithoutProfileInput>;
  Supplier?: InputMaybe<SupplierCreateNestedOneWithoutProfileInput>;
  corePersonnel?: InputMaybe<PersonnelCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateWithoutCorePersonnelInput {
  Architect?: InputMaybe<ArchitectCreateNestedOneWithoutProfileInput>;
  Construction?: InputMaybe<ConstructionCreateNestedOneWithoutProfileInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedOneWithoutProfileInput>;
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  Modeler?: InputMaybe<ModelerCreateNestedOneWithoutProfileInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedOneWithoutProfileInput>;
  Supplier?: InputMaybe<SupplierCreateNestedOneWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateWithoutGeneralConstructionInput {
  Architect?: InputMaybe<ArchitectCreateNestedOneWithoutProfileInput>;
  Construction?: InputMaybe<ConstructionCreateNestedOneWithoutProfileInput>;
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  Modeler?: InputMaybe<ModelerCreateNestedOneWithoutProfileInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedOneWithoutProfileInput>;
  Supplier?: InputMaybe<SupplierCreateNestedOneWithoutProfileInput>;
  corePersonnel?: InputMaybe<PersonnelCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateWithoutModelerInput {
  Architect?: InputMaybe<ArchitectCreateNestedOneWithoutProfileInput>;
  Construction?: InputMaybe<ConstructionCreateNestedOneWithoutProfileInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedOneWithoutProfileInput>;
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedOneWithoutProfileInput>;
  Supplier?: InputMaybe<SupplierCreateNestedOneWithoutProfileInput>;
  corePersonnel?: InputMaybe<PersonnelCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateWithoutSpaceDesignInput {
  Architect?: InputMaybe<ArchitectCreateNestedOneWithoutProfileInput>;
  Construction?: InputMaybe<ConstructionCreateNestedOneWithoutProfileInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedOneWithoutProfileInput>;
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  Modeler?: InputMaybe<ModelerCreateNestedOneWithoutProfileInput>;
  Supplier?: InputMaybe<SupplierCreateNestedOneWithoutProfileInput>;
  corePersonnel?: InputMaybe<PersonnelCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreateWithoutSupplierInput {
  Architect?: InputMaybe<ArchitectCreateNestedOneWithoutProfileInput>;
  Construction?: InputMaybe<ConstructionCreateNestedOneWithoutProfileInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionCreateNestedOneWithoutProfileInput>;
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  Modeler?: InputMaybe<ModelerCreateNestedOneWithoutProfileInput>;
  SpaceDesign?: InputMaybe<SpaceDesignCreateNestedOneWithoutProfileInput>;
  corePersonnel?: InputMaybe<PersonnelCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileCreatekeywordInput {
  set: Keyword;
}

export interface ProfileListRelationFilter {
  every?: InputMaybe<ProfileWhereInput>;
  none?: InputMaybe<ProfileWhereInput>;
  some?: InputMaybe<ProfileWhereInput>;
}

export interface ProfileMaxAggregateOutputType {
  __typename?: 'ProfileMaxAggregateOutputType';
  ImageUrl?: Maybe<Scalars['String']['output']>;
  architectId?: Maybe<Scalars['Int']['output']>;
  constructionId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  establishment?: Maybe<Scalars['Int']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  generalConstructionId?: Maybe<Scalars['Int']['output']>;
  homepage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  isCorePersonnel?: Maybe<Scalars['Boolean']['output']>;
  isLink?: Maybe<Scalars['Boolean']['output']>;
  isStory?: Maybe<Scalars['Boolean']['output']>;
  modelerId?: Maybe<Scalars['Int']['output']>;
  naverBlog?: Maybe<Scalars['String']['output']>;
  reasonForRejection?: Maybe<Scalars['String']['output']>;
  spaceDesignId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ProfileStatus>;
  story?: Maybe<Scalars['String']['output']>;
  supplierId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
}

export interface ProfileMaxOrderByAggregateInput {
  ImageUrl?: InputMaybe<SortOrder>;
  architectId?: InputMaybe<SortOrder>;
  constructionId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  establishment?: InputMaybe<SortOrder>;
  fileName?: InputMaybe<SortOrder>;
  fileUrl?: InputMaybe<SortOrder>;
  generalConstructionId?: InputMaybe<SortOrder>;
  homepage?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram?: InputMaybe<SortOrder>;
  isCorePersonnel?: InputMaybe<SortOrder>;
  isLink?: InputMaybe<SortOrder>;
  isStory?: InputMaybe<SortOrder>;
  modelerId?: InputMaybe<SortOrder>;
  naverBlog?: InputMaybe<SortOrder>;
  reasonForRejection?: InputMaybe<SortOrder>;
  spaceDesignId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  story?: InputMaybe<SortOrder>;
  supplierId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  youtube?: InputMaybe<SortOrder>;
}

export interface ProfileMinAggregateOutputType {
  __typename?: 'ProfileMinAggregateOutputType';
  ImageUrl?: Maybe<Scalars['String']['output']>;
  architectId?: Maybe<Scalars['Int']['output']>;
  constructionId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  establishment?: Maybe<Scalars['Int']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  generalConstructionId?: Maybe<Scalars['Int']['output']>;
  homepage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  isCorePersonnel?: Maybe<Scalars['Boolean']['output']>;
  isLink?: Maybe<Scalars['Boolean']['output']>;
  isStory?: Maybe<Scalars['Boolean']['output']>;
  modelerId?: Maybe<Scalars['Int']['output']>;
  naverBlog?: Maybe<Scalars['String']['output']>;
  reasonForRejection?: Maybe<Scalars['String']['output']>;
  spaceDesignId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ProfileStatus>;
  story?: Maybe<Scalars['String']['output']>;
  supplierId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
}

export interface ProfileMinOrderByAggregateInput {
  ImageUrl?: InputMaybe<SortOrder>;
  architectId?: InputMaybe<SortOrder>;
  constructionId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  establishment?: InputMaybe<SortOrder>;
  fileName?: InputMaybe<SortOrder>;
  fileUrl?: InputMaybe<SortOrder>;
  generalConstructionId?: InputMaybe<SortOrder>;
  homepage?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagram?: InputMaybe<SortOrder>;
  isCorePersonnel?: InputMaybe<SortOrder>;
  isLink?: InputMaybe<SortOrder>;
  isStory?: InputMaybe<SortOrder>;
  modelerId?: InputMaybe<SortOrder>;
  naverBlog?: InputMaybe<SortOrder>;
  reasonForRejection?: InputMaybe<SortOrder>;
  spaceDesignId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  story?: InputMaybe<SortOrder>;
  supplierId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  youtube?: InputMaybe<SortOrder>;
}

export interface ProfileOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ProfileOrderByWithAggregationInput {
  ImageUrl?: InputMaybe<SortOrderInput>;
  _avg?: InputMaybe<ProfileAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProfileCountOrderByAggregateInput>;
  _max?: InputMaybe<ProfileMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProfileMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProfileSumOrderByAggregateInput>;
  architectId?: InputMaybe<SortOrderInput>;
  constructionId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  establishment?: InputMaybe<SortOrderInput>;
  fileName?: InputMaybe<SortOrderInput>;
  fileUrl?: InputMaybe<SortOrderInput>;
  generalConstructionId?: InputMaybe<SortOrderInput>;
  homepage?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  instagram?: InputMaybe<SortOrderInput>;
  isCorePersonnel?: InputMaybe<SortOrder>;
  isLink?: InputMaybe<SortOrder>;
  isStory?: InputMaybe<SortOrder>;
  keyword?: InputMaybe<SortOrder>;
  modelerId?: InputMaybe<SortOrderInput>;
  naverBlog?: InputMaybe<SortOrderInput>;
  reasonForRejection?: InputMaybe<SortOrderInput>;
  spaceDesignId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  story?: InputMaybe<SortOrderInput>;
  supplierId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  youtube?: InputMaybe<SortOrderInput>;
}

export interface ProfileOrderByWithRelationInput {
  Architect?: InputMaybe<ArchitectOrderByWithRelationInput>;
  Construction?: InputMaybe<ConstructionOrderByWithRelationInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionOrderByWithRelationInput>;
  ImageUrl?: InputMaybe<SortOrderInput>;
  Modeler?: InputMaybe<ModelerOrderByWithRelationInput>;
  SpaceDesign?: InputMaybe<SpaceDesignOrderByWithRelationInput>;
  Supplier?: InputMaybe<SupplierOrderByWithRelationInput>;
  architectId?: InputMaybe<SortOrderInput>;
  constructionId?: InputMaybe<SortOrderInput>;
  corePersonnel?: InputMaybe<PersonnelOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  establishment?: InputMaybe<SortOrderInput>;
  fileName?: InputMaybe<SortOrderInput>;
  fileUrl?: InputMaybe<SortOrderInput>;
  generalConstructionId?: InputMaybe<SortOrderInput>;
  homepage?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  instagram?: InputMaybe<SortOrderInput>;
  isCorePersonnel?: InputMaybe<SortOrder>;
  isLink?: InputMaybe<SortOrder>;
  isStory?: InputMaybe<SortOrder>;
  keyword?: InputMaybe<SortOrder>;
  modelerId?: InputMaybe<SortOrderInput>;
  naverBlog?: InputMaybe<SortOrderInput>;
  reasonForRejection?: InputMaybe<SortOrderInput>;
  spaceDesignId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  story?: InputMaybe<SortOrderInput>;
  supplierId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  youtube?: InputMaybe<SortOrderInput>;
}

export interface ProfileRelationFilter {
  is?: InputMaybe<ProfileWhereInput>;
  isNot?: InputMaybe<ProfileWhereInput>;
}

export enum ProfileScalarFieldEnum {
  ImageUrl = 'ImageUrl',
  ArchitectId = 'architectId',
  ConstructionId = 'constructionId',
  CreatedAt = 'createdAt',
  Establishment = 'establishment',
  FileName = 'fileName',
  FileUrl = 'fileUrl',
  GeneralConstructionId = 'generalConstructionId',
  Homepage = 'homepage',
  Id = 'id',
  Instagram = 'instagram',
  IsCorePersonnel = 'isCorePersonnel',
  IsLink = 'isLink',
  IsStory = 'isStory',
  Keyword = 'keyword',
  ModelerId = 'modelerId',
  NaverBlog = 'naverBlog',
  ReasonForRejection = 'reasonForRejection',
  SpaceDesignId = 'spaceDesignId',
  Status = 'status',
  Story = 'story',
  SupplierId = 'supplierId',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  Youtube = 'youtube'
}

export interface ProfileScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  ImageUrl?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  architectId?: InputMaybe<IntNullableFilter>;
  constructionId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  establishment?: InputMaybe<IntNullableFilter>;
  fileName?: InputMaybe<StringNullableFilter>;
  fileUrl?: InputMaybe<StringNullableFilter>;
  generalConstructionId?: InputMaybe<IntNullableFilter>;
  homepage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  instagram?: InputMaybe<StringNullableFilter>;
  isCorePersonnel?: InputMaybe<BoolFilter>;
  isLink?: InputMaybe<BoolFilter>;
  isStory?: InputMaybe<BoolFilter>;
  keyword?: InputMaybe<EnumKeywordNullableListFilter>;
  modelerId?: InputMaybe<IntNullableFilter>;
  naverBlog?: InputMaybe<StringNullableFilter>;
  reasonForRejection?: InputMaybe<StringNullableFilter>;
  spaceDesignId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumProfileStatusFilter>;
  story?: InputMaybe<StringNullableFilter>;
  supplierId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  youtube?: InputMaybe<StringNullableFilter>;
}

export interface ProfileScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ProfileScalarWhereWithAggregatesInput>>>;
  ImageUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  NOT?: InputMaybe<Array<InputMaybe<ProfileScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProfileScalarWhereWithAggregatesInput>>>;
  architectId?: InputMaybe<IntNullableWithAggregatesFilter>;
  constructionId?: InputMaybe<IntNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  establishment?: InputMaybe<IntNullableWithAggregatesFilter>;
  fileName?: InputMaybe<StringNullableWithAggregatesFilter>;
  fileUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  generalConstructionId?: InputMaybe<IntNullableWithAggregatesFilter>;
  homepage?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  instagram?: InputMaybe<StringNullableWithAggregatesFilter>;
  isCorePersonnel?: InputMaybe<BoolWithAggregatesFilter>;
  isLink?: InputMaybe<BoolWithAggregatesFilter>;
  isStory?: InputMaybe<BoolWithAggregatesFilter>;
  keyword?: InputMaybe<EnumKeywordNullableListFilter>;
  modelerId?: InputMaybe<IntNullableWithAggregatesFilter>;
  naverBlog?: InputMaybe<StringNullableWithAggregatesFilter>;
  reasonForRejection?: InputMaybe<StringNullableWithAggregatesFilter>;
  spaceDesignId?: InputMaybe<IntNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumProfileStatusWithAggregatesFilter>;
  story?: InputMaybe<StringNullableWithAggregatesFilter>;
  supplierId?: InputMaybe<IntNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  youtube?: InputMaybe<StringNullableWithAggregatesFilter>;
}

export enum ProfileStatus {
  Approved = 'Approved',
  NotUsed = 'NotUsed',
  Rejected = 'Rejected',
  Standby = 'Standby',
  Temporary = 'Temporary'
}

export interface ProfileSumAggregateOutputType {
  __typename?: 'ProfileSumAggregateOutputType';
  architectId?: Maybe<Scalars['Int']['output']>;
  constructionId?: Maybe<Scalars['Int']['output']>;
  establishment?: Maybe<Scalars['Int']['output']>;
  generalConstructionId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  modelerId?: Maybe<Scalars['Int']['output']>;
  spaceDesignId?: Maybe<Scalars['Int']['output']>;
  supplierId?: Maybe<Scalars['Int']['output']>;
}

export interface ProfileSumOrderByAggregateInput {
  architectId?: InputMaybe<SortOrder>;
  constructionId?: InputMaybe<SortOrder>;
  establishment?: InputMaybe<SortOrder>;
  generalConstructionId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  modelerId?: InputMaybe<SortOrder>;
  spaceDesignId?: InputMaybe<SortOrder>;
  supplierId?: InputMaybe<SortOrder>;
}

export interface ProfileUncheckedCreateInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  corePersonnel?: InputMaybe<PersonnelUncheckedCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileUncheckedCreateNestedManyWithoutArchitectInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutArchitectInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutArchitectInput>>>;
  createMany?: InputMaybe<ProfileCreateManyArchitectInputEnvelope>;
}

export interface ProfileUncheckedCreateNestedManyWithoutConstructionInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutConstructionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutConstructionInput>>>;
  createMany?: InputMaybe<ProfileCreateManyConstructionInputEnvelope>;
}

export interface ProfileUncheckedCreateNestedManyWithoutGeneralConstructionInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutGeneralConstructionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutGeneralConstructionInput>>>;
  createMany?: InputMaybe<ProfileCreateManyGeneralConstructionInputEnvelope>;
}

export interface ProfileUncheckedCreateNestedManyWithoutModelerInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutModelerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutModelerInput>>>;
  createMany?: InputMaybe<ProfileCreateManyModelerInputEnvelope>;
}

export interface ProfileUncheckedCreateNestedManyWithoutSpaceDesignInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutSpaceDesignInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutSpaceDesignInput>>>;
  createMany?: InputMaybe<ProfileCreateManySpaceDesignInputEnvelope>;
}

export interface ProfileUncheckedCreateNestedManyWithoutSupplierInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutSupplierInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutSupplierInput>>>;
  createMany?: InputMaybe<ProfileCreateManySupplierInputEnvelope>;
}

export interface ProfileUncheckedCreateWithoutArchitectInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  corePersonnel?: InputMaybe<PersonnelUncheckedCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileUncheckedCreateWithoutConstructionInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  corePersonnel?: InputMaybe<PersonnelUncheckedCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileUncheckedCreateWithoutCorePersonnelInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileUncheckedCreateWithoutGeneralConstructionInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  corePersonnel?: InputMaybe<PersonnelUncheckedCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileUncheckedCreateWithoutModelerInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  corePersonnel?: InputMaybe<PersonnelUncheckedCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileUncheckedCreateWithoutSpaceDesignInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  corePersonnel?: InputMaybe<PersonnelUncheckedCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileUncheckedCreateWithoutSupplierInput {
  ImageUrl?: InputMaybe<Scalars['String']['input']>;
  architectId?: InputMaybe<Scalars['Int']['input']>;
  constructionId?: InputMaybe<Scalars['Int']['input']>;
  corePersonnel?: InputMaybe<PersonnelUncheckedCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  establishment?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  generalConstructionId?: InputMaybe<Scalars['Int']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  isCorePersonnel?: InputMaybe<Scalars['Boolean']['input']>;
  isLink?: InputMaybe<Scalars['Boolean']['input']>;
  isStory?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<Scalars['Int']['input']>;
  naverBlog?: InputMaybe<Scalars['String']['input']>;
  reasonForRejection?: InputMaybe<Scalars['String']['input']>;
  spaceDesignId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ProfileStatus>;
  story?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface ProfileUncheckedUpdateInput {
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  architectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  constructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  corePersonnel?: InputMaybe<PersonnelUncheckedUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  generalConstructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spaceDesignId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplierId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUncheckedUpdateManyInput {
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  architectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  constructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  generalConstructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spaceDesignId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplierId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUncheckedUpdateManyWithoutArchitectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutArchitectInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutArchitectInput>>>;
  createMany?: InputMaybe<ProfileCreateManyArchitectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutArchitectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutArchitectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutArchitectInput>>>;
}

export interface ProfileUncheckedUpdateManyWithoutConstructionNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutConstructionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutConstructionInput>>>;
  createMany?: InputMaybe<ProfileCreateManyConstructionInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutConstructionInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutConstructionInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutConstructionInput>>>;
}

export interface ProfileUncheckedUpdateManyWithoutGeneralConstructionNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutGeneralConstructionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutGeneralConstructionInput>>>;
  createMany?: InputMaybe<ProfileCreateManyGeneralConstructionInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutGeneralConstructionInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutGeneralConstructionInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutGeneralConstructionInput>>>;
}

export interface ProfileUncheckedUpdateManyWithoutModelerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutModelerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutModelerInput>>>;
  createMany?: InputMaybe<ProfileCreateManyModelerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutModelerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutModelerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutModelerInput>>>;
}

export interface ProfileUncheckedUpdateManyWithoutProfileInput {
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  generalConstructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spaceDesignId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplierId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUncheckedUpdateManyWithoutSpaceDesignNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutSpaceDesignInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutSpaceDesignInput>>>;
  createMany?: InputMaybe<ProfileCreateManySpaceDesignInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutSpaceDesignInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutSpaceDesignInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutSpaceDesignInput>>>;
}

export interface ProfileUncheckedUpdateManyWithoutSupplierNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutSupplierInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutSupplierInput>>>;
  createMany?: InputMaybe<ProfileCreateManySupplierInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutSupplierInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutSupplierInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutSupplierInput>>>;
}

export interface ProfileUncheckedUpdateWithoutArchitectInput {
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  corePersonnel?: InputMaybe<PersonnelUncheckedUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  generalConstructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spaceDesignId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplierId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUncheckedUpdateWithoutConstructionInput {
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  architectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  corePersonnel?: InputMaybe<PersonnelUncheckedUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  generalConstructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spaceDesignId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplierId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUncheckedUpdateWithoutCorePersonnelInput {
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  architectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  constructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  generalConstructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spaceDesignId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplierId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUncheckedUpdateWithoutGeneralConstructionInput {
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  architectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  constructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  corePersonnel?: InputMaybe<PersonnelUncheckedUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spaceDesignId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplierId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUncheckedUpdateWithoutModelerInput {
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  architectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  constructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  corePersonnel?: InputMaybe<PersonnelUncheckedUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  generalConstructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spaceDesignId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplierId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUncheckedUpdateWithoutSpaceDesignInput {
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  architectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  constructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  corePersonnel?: InputMaybe<PersonnelUncheckedUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  generalConstructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  supplierId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUncheckedUpdateWithoutSupplierInput {
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  architectId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  constructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  corePersonnel?: InputMaybe<PersonnelUncheckedUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  generalConstructionId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  modelerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spaceDesignId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUpdateInput {
  Architect?: InputMaybe<ArchitectUpdateOneWithoutProfileNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateOneWithoutProfileNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateOneWithoutProfileNestedInput>;
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Modeler?: InputMaybe<ModelerUpdateOneWithoutProfileNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateOneWithoutProfileNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateOneWithoutProfileNestedInput>;
  corePersonnel?: InputMaybe<PersonnelUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUpdateManyMutationInput {
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUpdateManyWithWhereWithoutArchitectInput {
  data: ProfileUncheckedUpdateManyWithoutProfileInput;
  where: ProfileScalarWhereInput;
}

export interface ProfileUpdateManyWithWhereWithoutConstructionInput {
  data: ProfileUncheckedUpdateManyWithoutProfileInput;
  where: ProfileScalarWhereInput;
}

export interface ProfileUpdateManyWithWhereWithoutGeneralConstructionInput {
  data: ProfileUncheckedUpdateManyWithoutProfileInput;
  where: ProfileScalarWhereInput;
}

export interface ProfileUpdateManyWithWhereWithoutModelerInput {
  data: ProfileUncheckedUpdateManyWithoutProfileInput;
  where: ProfileScalarWhereInput;
}

export interface ProfileUpdateManyWithWhereWithoutSpaceDesignInput {
  data: ProfileUncheckedUpdateManyWithoutProfileInput;
  where: ProfileScalarWhereInput;
}

export interface ProfileUpdateManyWithWhereWithoutSupplierInput {
  data: ProfileUncheckedUpdateManyWithoutProfileInput;
  where: ProfileScalarWhereInput;
}

export interface ProfileUpdateManyWithoutArchitectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutArchitectInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutArchitectInput>>>;
  createMany?: InputMaybe<ProfileCreateManyArchitectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutArchitectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutArchitectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutArchitectInput>>>;
}

export interface ProfileUpdateManyWithoutConstructionNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutConstructionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutConstructionInput>>>;
  createMany?: InputMaybe<ProfileCreateManyConstructionInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutConstructionInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutConstructionInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutConstructionInput>>>;
}

export interface ProfileUpdateManyWithoutGeneralConstructionNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutGeneralConstructionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutGeneralConstructionInput>>>;
  createMany?: InputMaybe<ProfileCreateManyGeneralConstructionInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutGeneralConstructionInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutGeneralConstructionInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutGeneralConstructionInput>>>;
}

export interface ProfileUpdateManyWithoutModelerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutModelerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutModelerInput>>>;
  createMany?: InputMaybe<ProfileCreateManyModelerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutModelerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutModelerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutModelerInput>>>;
}

export interface ProfileUpdateManyWithoutSpaceDesignNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutSpaceDesignInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutSpaceDesignInput>>>;
  createMany?: InputMaybe<ProfileCreateManySpaceDesignInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutSpaceDesignInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutSpaceDesignInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutSpaceDesignInput>>>;
}

export interface ProfileUpdateManyWithoutSupplierNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProfileCreateOrConnectWithoutSupplierInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProfileCreateWithoutSupplierInput>>>;
  createMany?: InputMaybe<ProfileCreateManySupplierInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProfileScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProfileWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProfileUpdateWithWhereUniqueWithoutSupplierInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProfileUpdateManyWithWhereWithoutSupplierInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProfileUpsertWithWhereUniqueWithoutSupplierInput>>>;
}

export interface ProfileUpdateOneRequiredWithoutCorePersonnelNestedInput {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutCorePersonnelInput>;
  create?: InputMaybe<ProfileUncheckedCreateWithoutCorePersonnelInput>;
  update?: InputMaybe<ProfileUncheckedUpdateWithoutCorePersonnelInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutCorePersonnelInput>;
}

export interface ProfileUpdateWithWhereUniqueWithoutArchitectInput {
  data: ProfileUncheckedUpdateWithoutArchitectInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpdateWithWhereUniqueWithoutConstructionInput {
  data: ProfileUncheckedUpdateWithoutConstructionInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpdateWithWhereUniqueWithoutGeneralConstructionInput {
  data: ProfileUncheckedUpdateWithoutGeneralConstructionInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpdateWithWhereUniqueWithoutModelerInput {
  data: ProfileUncheckedUpdateWithoutModelerInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpdateWithWhereUniqueWithoutSpaceDesignInput {
  data: ProfileUncheckedUpdateWithoutSpaceDesignInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpdateWithWhereUniqueWithoutSupplierInput {
  data: ProfileUncheckedUpdateWithoutSupplierInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpdateWithoutArchitectInput {
  Construction?: InputMaybe<ConstructionUpdateOneWithoutProfileNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateOneWithoutProfileNestedInput>;
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Modeler?: InputMaybe<ModelerUpdateOneWithoutProfileNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateOneWithoutProfileNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateOneWithoutProfileNestedInput>;
  corePersonnel?: InputMaybe<PersonnelUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUpdateWithoutConstructionInput {
  Architect?: InputMaybe<ArchitectUpdateOneWithoutProfileNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateOneWithoutProfileNestedInput>;
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Modeler?: InputMaybe<ModelerUpdateOneWithoutProfileNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateOneWithoutProfileNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateOneWithoutProfileNestedInput>;
  corePersonnel?: InputMaybe<PersonnelUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUpdateWithoutCorePersonnelInput {
  Architect?: InputMaybe<ArchitectUpdateOneWithoutProfileNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateOneWithoutProfileNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateOneWithoutProfileNestedInput>;
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Modeler?: InputMaybe<ModelerUpdateOneWithoutProfileNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateOneWithoutProfileNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateOneWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUpdateWithoutGeneralConstructionInput {
  Architect?: InputMaybe<ArchitectUpdateOneWithoutProfileNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateOneWithoutProfileNestedInput>;
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Modeler?: InputMaybe<ModelerUpdateOneWithoutProfileNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateOneWithoutProfileNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateOneWithoutProfileNestedInput>;
  corePersonnel?: InputMaybe<PersonnelUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUpdateWithoutModelerInput {
  Architect?: InputMaybe<ArchitectUpdateOneWithoutProfileNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateOneWithoutProfileNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateOneWithoutProfileNestedInput>;
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateOneWithoutProfileNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateOneWithoutProfileNestedInput>;
  corePersonnel?: InputMaybe<PersonnelUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUpdateWithoutSpaceDesignInput {
  Architect?: InputMaybe<ArchitectUpdateOneWithoutProfileNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateOneWithoutProfileNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateOneWithoutProfileNestedInput>;
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Modeler?: InputMaybe<ModelerUpdateOneWithoutProfileNestedInput>;
  Supplier?: InputMaybe<SupplierUpdateOneWithoutProfileNestedInput>;
  corePersonnel?: InputMaybe<PersonnelUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUpdateWithoutSupplierInput {
  Architect?: InputMaybe<ArchitectUpdateOneWithoutProfileNestedInput>;
  Construction?: InputMaybe<ConstructionUpdateOneWithoutProfileNestedInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionUpdateOneWithoutProfileNestedInput>;
  ImageUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Modeler?: InputMaybe<ModelerUpdateOneWithoutProfileNestedInput>;
  SpaceDesign?: InputMaybe<SpaceDesignUpdateOneWithoutProfileNestedInput>;
  corePersonnel?: InputMaybe<PersonnelUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  establishment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  fileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fileUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  homepage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCorePersonnel?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLink?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isStory?: InputMaybe<BoolFieldUpdateOperationsInput>;
  keyword?: InputMaybe<Array<InputMaybe<Keyword>>>;
  naverBlog?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  reasonForRejection?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProfileStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProfileUpdatekeywordInput {
  push?: InputMaybe<Array<InputMaybe<Keyword>>>;
  set?: InputMaybe<Array<InputMaybe<Keyword>>>;
}

export interface ProfileUpsertWithWhereUniqueWithoutArchitectInput {
  create: ProfileUncheckedCreateWithoutArchitectInput;
  update: ProfileUncheckedUpdateWithoutArchitectInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpsertWithWhereUniqueWithoutConstructionInput {
  create: ProfileUncheckedCreateWithoutConstructionInput;
  update: ProfileUncheckedUpdateWithoutConstructionInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpsertWithWhereUniqueWithoutGeneralConstructionInput {
  create: ProfileUncheckedCreateWithoutGeneralConstructionInput;
  update: ProfileUncheckedUpdateWithoutGeneralConstructionInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpsertWithWhereUniqueWithoutModelerInput {
  create: ProfileUncheckedCreateWithoutModelerInput;
  update: ProfileUncheckedUpdateWithoutModelerInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpsertWithWhereUniqueWithoutSpaceDesignInput {
  create: ProfileUncheckedCreateWithoutSpaceDesignInput;
  update: ProfileUncheckedUpdateWithoutSpaceDesignInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpsertWithWhereUniqueWithoutSupplierInput {
  create: ProfileUncheckedCreateWithoutSupplierInput;
  update: ProfileUncheckedUpdateWithoutSupplierInput;
  where: ProfileWhereUniqueInput;
}

export interface ProfileUpsertWithoutCorePersonnelInput {
  create: ProfileUncheckedCreateWithoutCorePersonnelInput;
  update: ProfileUncheckedUpdateWithoutCorePersonnelInput;
}

export interface ProfileWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProfileWhereInput>>>;
  Architect?: InputMaybe<ArchitectWhereInput>;
  Construction?: InputMaybe<ConstructionWhereInput>;
  GeneralConstruction?: InputMaybe<GeneralConstructionWhereInput>;
  ImageUrl?: InputMaybe<StringNullableFilter>;
  Modeler?: InputMaybe<ModelerWhereInput>;
  NOT?: InputMaybe<Array<InputMaybe<ProfileWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProfileWhereInput>>>;
  SpaceDesign?: InputMaybe<SpaceDesignWhereInput>;
  Supplier?: InputMaybe<SupplierWhereInput>;
  architectId?: InputMaybe<IntNullableFilter>;
  constructionId?: InputMaybe<IntNullableFilter>;
  corePersonnel?: InputMaybe<PersonnelListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  establishment?: InputMaybe<IntNullableFilter>;
  fileName?: InputMaybe<StringNullableFilter>;
  fileUrl?: InputMaybe<StringNullableFilter>;
  generalConstructionId?: InputMaybe<IntNullableFilter>;
  homepage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  instagram?: InputMaybe<StringNullableFilter>;
  isCorePersonnel?: InputMaybe<BoolFilter>;
  isLink?: InputMaybe<BoolFilter>;
  isStory?: InputMaybe<BoolFilter>;
  keyword?: InputMaybe<EnumKeywordNullableListFilter>;
  modelerId?: InputMaybe<IntNullableFilter>;
  naverBlog?: InputMaybe<StringNullableFilter>;
  reasonForRejection?: InputMaybe<StringNullableFilter>;
  spaceDesignId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumProfileStatusFilter>;
  story?: InputMaybe<StringNullableFilter>;
  supplierId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  youtube?: InputMaybe<StringNullableFilter>;
}

export interface ProfileWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** Project */
export interface Project {
  __typename?: 'Project';
  CashReceipt: Array<CashReceipt>;
  InspectionRejected: Array<InspectionRejected>;
  KakaoSendLog: Array<KakaoSendLog>;
  PayTransaction: Array<PayTransaction>;
  /** 프로젝트가 가지고 있는 표준 내역서 */
  ProjectBoQ?: Maybe<ProjectBoQ>;
  ProjectHistory: Array<ProjectHistory>;
  VatInvoice?: Maybe<VatInvoice>;
  _count: ProjectCountOutputType;
  /** 지상 연면적 */
  aboveGroundArea?: Maybe<Scalars['Float']['output']>;
  /** 지상층수 */
  aboveGroundFloors?: Maybe<Scalars['Int']['output']>;
  /** Project address */
  address: Address;
  addressId: Scalars['Int']['output'];
  /** 설계사 */
  architect?: Maybe<Scalars['String']['output']>;
  /** Blueprint list of this project */
  blueprints: Array<Blueprint>;
  /** Budget: 예산 */
  budget: Scalars['Float']['output'];
  /** 건축면적 */
  buildingArea?: Maybe<Scalars['Float']['output']>;
  /** 발주자 */
  client?: Maybe<Scalars['String']['output']>;
  /** 공사 기간 종료일 */
  constructionEndDate?: Maybe<Scalars['DateTime']['output']>;
  /** Construction type ETC: 기타 건축 유형 입력 */
  constructionEtc?: Maybe<Scalars['String']['output']>;
  /** Construction option: 건축 유형 옵션 선택 */
  constructionOption?: Maybe<Scalars['String']['output']>;
  /** 공사 기간 시작일 */
  constructionStartDate?: Maybe<Scalars['DateTime']['output']>;
  /** Construction type: 건축 유형 선택 */
  constructionType: Scalars['String']['output'];
  /** Related contact: 건축사, 모델러 연락처 */
  contact?: Maybe<Scalars['String']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** deletedAt */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Project description */
  description?: Maybe<Scalars['String']['output']>;
  /** Estimated completion date: 희망하는 완공시기 */
  desiredCompletionDate?: Maybe<Scalars['String']['output']>;
  /** 결제 예정 금액 */
  estimatedPayment?: Maybe<Scalars['Int']['output']>;
  /** 결제 금액 산정 일자 */
  estimatedPaymentDate?: Maybe<Scalars['DateTime']['output']>;
  /** 연면적 */
  floorArea?: Maybe<Scalars['Float']['output']>;
  /** Autodesk Forge URN string (deprecation) */
  forgeUrn?: Maybe<Scalars['String']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  /** isFavorite */
  isFavorite: Scalars['Boolean']['output'];
  /** 대지면적 */
  landArea?: Maybe<Scalars['Float']['output']>;
  /** 최고 높이 */
  maxHeight?: Maybe<Scalars['Int']['output']>;
  /** 최대 지하 면적 */
  maxUnderGroundArea?: Maybe<Scalars['Float']['output']>;
  /** 기계식 주차면수 */
  mechanicalParkingArea?: Maybe<Scalars['Int']['output']>;
  /** 참고사항 */
  memo?: Maybe<Scalars['String']['output']>;
  /** 모델 특성데이터 */
  modelProperty?: Maybe<ModelProperty>;
  modelPropertyId?: Maybe<Scalars['Int']['output']>;
  /** Option for Rebar model */
  modelRebarExcepted: Scalars['Boolean']['output'];
  /** Model service select */
  modelService: UploadOption;
  /** Customer who created this project */
  owner?: Maybe<Customer>;
  /** Member ID who created this project */
  ownerId?: Maybe<Scalars['Int']['output']>;
  /** Project Uploader Category */
  ownerType?: Maybe<ProjectOwnerType>;
  /** 자주식 주차면수 */
  parkingArea?: Maybe<Scalars['Int']['output']>;
  /** 결제 아이디 */
  paymentImpUid?: Maybe<Scalars['String']['output']>;
  processSimulation?: Maybe<ProcessSimulation>;
  /** Project assets */
  projectAsset?: Maybe<ProjectAsset>;
  projectAssetId?: Maybe<Scalars['Int']['output']>;
  /** Project Level */
  projectLevel?: Maybe<ProjectLevel>;
  projectMilestone?: Maybe<ProjectMilestone>;
  /** Project Mode */
  projectMode?: Maybe<ProjectMode>;
  /** Project Type */
  projectType?: Maybe<ProjectType>;
  /** Blueprint reject reson */
  rejectedReason?: Maybe<Scalars['String']['output']>;
  /** Requirement: 요구사항 */
  requirement?: Maybe<Scalars['String']['output']>;
  /** 프로젝트가 가지고 있는 전체 공종별 점유율 */
  shareRatioWork?: Maybe<ShareRatioWork>;
  shareRatioWorkId?: Maybe<Scalars['Int']['output']>;
  sharedProject: Array<SharedProject>;
  /** Project status */
  status: ProjectStatus;
  structure?: Maybe<Scalars['String']['output']>;
  structureInfo?: Maybe<Scalars['String']['output']>;
  /** Task list of this project */
  tasks: Array<Task>;
  /** Project title */
  title: Scalars['String']['output'];
  /** 지하 연면적 */
  underGroundArea?: Maybe<Scalars['Float']['output']>;
  /** 지하층수 */
  underGroundFloors?: Maybe<Scalars['Int']['output']>;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Project Unique Key */
  uuid: Scalars['String']['output'];
  vatInvoiceId?: Maybe<Scalars['Int']['output']>;
}


/** Project */
export type ProjectCashReceiptArgs = {
  cursor?: InputMaybe<CashReceiptWhereUniqueInput>;
  distinct?: InputMaybe<CashReceiptScalarFieldEnum>;
  orderBy?: InputMaybe<CashReceiptOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CashReceiptWhereInput>;
};


/** Project */
export type ProjectInspectionRejectedArgs = {
  cursor?: InputMaybe<InspectionRejectedWhereUniqueInput>;
  distinct?: InputMaybe<InspectionRejectedScalarFieldEnum>;
  orderBy?: InputMaybe<InspectionRejectedOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InspectionRejectedWhereInput>;
};


/** Project */
export type ProjectKakaoSendLogArgs = {
  cursor?: InputMaybe<KakaoSendLogWhereUniqueInput>;
  distinct?: InputMaybe<KakaoSendLogScalarFieldEnum>;
  orderBy?: InputMaybe<KakaoSendLogOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KakaoSendLogWhereInput>;
};


/** Project */
export type ProjectPayTransactionArgs = {
  cursor?: InputMaybe<PayTransactionWhereUniqueInput>;
  distinct?: InputMaybe<PayTransactionScalarFieldEnum>;
  orderBy?: InputMaybe<PayTransactionOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PayTransactionWhereInput>;
};


/** Project */
export type ProjectProjectHistoryArgs = {
  cursor?: InputMaybe<ProjectHistoryWhereUniqueInput>;
  distinct?: InputMaybe<ProjectHistoryScalarFieldEnum>;
  orderBy?: InputMaybe<ProjectHistoryOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectHistoryWhereInput>;
};


/** Project */
export type ProjectBlueprintsArgs = {
  cursor?: InputMaybe<BlueprintWhereUniqueInput>;
  distinct?: InputMaybe<BlueprintScalarFieldEnum>;
  orderBy?: InputMaybe<BlueprintOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlueprintWhereInput>;
};


/** Project */
export type ProjectSharedProjectArgs = {
  cursor?: InputMaybe<SharedProjectWhereUniqueInput>;
  distinct?: InputMaybe<SharedProjectScalarFieldEnum>;
  orderBy?: InputMaybe<SharedProjectOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectWhereInput>;
};


/** Project */
export type ProjectTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<TaskScalarFieldEnum>;
  orderBy?: InputMaybe<TaskOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};

export interface ProjectAsset {
  __typename?: 'ProjectAsset';
  Project: Project;
  _count: ProjectAssetCountOutputType;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  downloadAll?: Maybe<Scalars['String']['output']>;
  /** 공내역서 Excel 파일 */
  excelBlankBoQKey?: Maybe<Scalars['String']['output']>;
  /** BH-A 수량산출서 Excel 파일 */
  excelFinishBoQKey?: Maybe<Scalars['String']['output']>;
  /** BH-Q 수량산출서 Excel 파일 */
  excelFrameBoQKey?: Maybe<Scalars['String']['output']>;
  /** finish base NWC 파일 */
  finishBaseNwcUrl?: Maybe<Scalars['String']['output']>;
  /** finish glTF 파일 */
  finishGltfUrl?: Maybe<Scalars['String']['output']>;
  /** Floor name */
  floorInfo: Array<ProjectFloorInfo>;
  /** formwork NWC 파일 */
  formworkNwcUrl?: Maybe<Scalars['String']['output']>;
  /** 모델 glTF 파일 */
  gltfUrl?: Maybe<Scalars['String']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** 도면검토 보고서 */
  inspectionReportKey?: Maybe<Scalars['String']['output']>;
  presignedExcelBlankBoQDownloadUrl?: Maybe<Scalars['String']['output']>;
  presignedExcelFinishBoQDownloadUrl?: Maybe<Scalars['String']['output']>;
  presignedExcelFrameBoQDownloadUrl?: Maybe<Scalars['String']['output']>;
  presignedInspectReportDownloadUrl?: Maybe<Scalars['String']['output']>;
  presignedProductionFinishNwcDownloadUrl?: Maybe<Scalars['String']['output']>;
  presignedProductionFinishRvtDownloadUrl?: Maybe<Scalars['String']['output']>;
  presignedProductionFrameNwcDownloadUrl?: Maybe<Scalars['String']['output']>;
  presignedProductionFrameRvtDownloadUrl?: Maybe<Scalars['String']['output']>;
  /** 최종 산출물 마감 모델 파일 (NWC) */
  productionFinishNwcUrl?: Maybe<Scalars['String']['output']>;
  /** 최종 산출물 마감 모델 파일 (Revit) */
  productionFinishRvtUrl?: Maybe<Scalars['String']['output']>;
  /** 최종 산출물 골조 모델 파일 (NWC) */
  productionFrameNwcUrl?: Maybe<Scalars['String']['output']>;
  /** 최종 산출물 골조 모델 파일 (Revit) */
  productionFrameRvtUrl?: Maybe<Scalars['String']['output']>;
  projectId: Scalars['Int']['output'];
  /** rebar NWC 파일 */
  rebarNwcUrl?: Maybe<Scalars['String']['output']>;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Project Unique Key */
  uuid: Scalars['String']['output'];
}


export type ProjectAssetFloorInfoArgs = {
  cursor?: InputMaybe<ProjectFloorInfoWhereUniqueInput>;
  distinct?: InputMaybe<ProjectFloorInfoScalarFieldEnum>;
  orderBy?: InputMaybe<ProjectFloorInfoOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectFloorInfoWhereInput>;
};

export interface ProjectAssetAvgAggregateOutputType {
  __typename?: 'ProjectAssetAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  projectId?: Maybe<Scalars['Float']['output']>;
}

export interface ProjectAssetAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface ProjectAssetCountAggregateOutputType {
  __typename?: 'ProjectAssetCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  excelBlankBoQKey: Scalars['Int']['output'];
  excelFinishBoQKey: Scalars['Int']['output'];
  excelFrameBoQKey: Scalars['Int']['output'];
  finishBaseNwcUrl: Scalars['Int']['output'];
  finishGltfUrl: Scalars['Int']['output'];
  formworkNwcUrl: Scalars['Int']['output'];
  gltfUrl: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  inspectionReportKey: Scalars['Int']['output'];
  productionFinishNwcUrl: Scalars['Int']['output'];
  productionFinishRvtUrl: Scalars['Int']['output'];
  productionFrameNwcUrl: Scalars['Int']['output'];
  productionFrameRvtUrl: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  rebarNwcUrl: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface ProjectAssetCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  excelBlankBoQKey?: InputMaybe<SortOrder>;
  excelFinishBoQKey?: InputMaybe<SortOrder>;
  excelFrameBoQKey?: InputMaybe<SortOrder>;
  finishBaseNwcUrl?: InputMaybe<SortOrder>;
  finishGltfUrl?: InputMaybe<SortOrder>;
  formworkNwcUrl?: InputMaybe<SortOrder>;
  gltfUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inspectionReportKey?: InputMaybe<SortOrder>;
  productionFinishNwcUrl?: InputMaybe<SortOrder>;
  productionFinishRvtUrl?: InputMaybe<SortOrder>;
  productionFrameNwcUrl?: InputMaybe<SortOrder>;
  productionFrameRvtUrl?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  rebarNwcUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectAssetCountOutputType {
  __typename?: 'ProjectAssetCountOutputType';
  floorInfo: Scalars['Int']['output'];
}

export interface ProjectAssetCreateInput {
  Project: ProjectCreateNestedOneWithoutProjectAssetInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  excelBlankBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFinishBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFrameBoQKey?: InputMaybe<Scalars['String']['input']>;
  finishBaseNwcUrl?: InputMaybe<Scalars['String']['input']>;
  finishGltfUrl?: InputMaybe<Scalars['String']['input']>;
  floorInfo?: InputMaybe<ProjectFloorInfoCreateNestedManyWithoutProjectAssetInput>;
  formworkNwcUrl?: InputMaybe<Scalars['String']['input']>;
  gltfUrl?: InputMaybe<Scalars['String']['input']>;
  inspectionReportKey?: InputMaybe<Scalars['String']['input']>;
  productionFinishNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFinishRvtUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameRvtUrl?: InputMaybe<Scalars['String']['input']>;
  rebarNwcUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectAssetCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  excelBlankBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFinishBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFrameBoQKey?: InputMaybe<Scalars['String']['input']>;
  finishBaseNwcUrl?: InputMaybe<Scalars['String']['input']>;
  finishGltfUrl?: InputMaybe<Scalars['String']['input']>;
  formworkNwcUrl?: InputMaybe<Scalars['String']['input']>;
  gltfUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inspectionReportKey?: InputMaybe<Scalars['String']['input']>;
  productionFinishNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFinishRvtUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameRvtUrl?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['Int']['input'];
  rebarNwcUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectAssetCreateNestedOneWithoutFloorInfoInput {
  connect?: InputMaybe<ProjectAssetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectAssetCreateOrConnectWithoutFloorInfoInput>;
  create?: InputMaybe<ProjectAssetUncheckedCreateWithoutFloorInfoInput>;
}

export interface ProjectAssetCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<ProjectAssetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectAssetCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectAssetUncheckedCreateWithoutProjectInput>;
}

export interface ProjectAssetCreateOrConnectWithoutFloorInfoInput {
  create: ProjectAssetUncheckedCreateWithoutFloorInfoInput;
  where: ProjectAssetWhereUniqueInput;
}

export interface ProjectAssetCreateOrConnectWithoutProjectInput {
  create: ProjectAssetUncheckedCreateWithoutProjectInput;
  where: ProjectAssetWhereUniqueInput;
}

export interface ProjectAssetCreateWithoutFloorInfoInput {
  Project: ProjectCreateNestedOneWithoutProjectAssetInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  excelBlankBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFinishBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFrameBoQKey?: InputMaybe<Scalars['String']['input']>;
  finishBaseNwcUrl?: InputMaybe<Scalars['String']['input']>;
  finishGltfUrl?: InputMaybe<Scalars['String']['input']>;
  formworkNwcUrl?: InputMaybe<Scalars['String']['input']>;
  gltfUrl?: InputMaybe<Scalars['String']['input']>;
  inspectionReportKey?: InputMaybe<Scalars['String']['input']>;
  productionFinishNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFinishRvtUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameRvtUrl?: InputMaybe<Scalars['String']['input']>;
  rebarNwcUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectAssetCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  excelBlankBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFinishBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFrameBoQKey?: InputMaybe<Scalars['String']['input']>;
  finishBaseNwcUrl?: InputMaybe<Scalars['String']['input']>;
  finishGltfUrl?: InputMaybe<Scalars['String']['input']>;
  floorInfo?: InputMaybe<ProjectFloorInfoCreateNestedManyWithoutProjectAssetInput>;
  formworkNwcUrl?: InputMaybe<Scalars['String']['input']>;
  gltfUrl?: InputMaybe<Scalars['String']['input']>;
  inspectionReportKey?: InputMaybe<Scalars['String']['input']>;
  productionFinishNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFinishRvtUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameRvtUrl?: InputMaybe<Scalars['String']['input']>;
  rebarNwcUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectAssetMaxAggregateOutputType {
  __typename?: 'ProjectAssetMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  excelBlankBoQKey?: Maybe<Scalars['String']['output']>;
  excelFinishBoQKey?: Maybe<Scalars['String']['output']>;
  excelFrameBoQKey?: Maybe<Scalars['String']['output']>;
  finishBaseNwcUrl?: Maybe<Scalars['String']['output']>;
  finishGltfUrl?: Maybe<Scalars['String']['output']>;
  formworkNwcUrl?: Maybe<Scalars['String']['output']>;
  gltfUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inspectionReportKey?: Maybe<Scalars['String']['output']>;
  productionFinishNwcUrl?: Maybe<Scalars['String']['output']>;
  productionFinishRvtUrl?: Maybe<Scalars['String']['output']>;
  productionFrameNwcUrl?: Maybe<Scalars['String']['output']>;
  productionFrameRvtUrl?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  rebarNwcUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProjectAssetMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  excelBlankBoQKey?: InputMaybe<SortOrder>;
  excelFinishBoQKey?: InputMaybe<SortOrder>;
  excelFrameBoQKey?: InputMaybe<SortOrder>;
  finishBaseNwcUrl?: InputMaybe<SortOrder>;
  finishGltfUrl?: InputMaybe<SortOrder>;
  formworkNwcUrl?: InputMaybe<SortOrder>;
  gltfUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inspectionReportKey?: InputMaybe<SortOrder>;
  productionFinishNwcUrl?: InputMaybe<SortOrder>;
  productionFinishRvtUrl?: InputMaybe<SortOrder>;
  productionFrameNwcUrl?: InputMaybe<SortOrder>;
  productionFrameRvtUrl?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  rebarNwcUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectAssetMinAggregateOutputType {
  __typename?: 'ProjectAssetMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  excelBlankBoQKey?: Maybe<Scalars['String']['output']>;
  excelFinishBoQKey?: Maybe<Scalars['String']['output']>;
  excelFrameBoQKey?: Maybe<Scalars['String']['output']>;
  finishBaseNwcUrl?: Maybe<Scalars['String']['output']>;
  finishGltfUrl?: Maybe<Scalars['String']['output']>;
  formworkNwcUrl?: Maybe<Scalars['String']['output']>;
  gltfUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inspectionReportKey?: Maybe<Scalars['String']['output']>;
  productionFinishNwcUrl?: Maybe<Scalars['String']['output']>;
  productionFinishRvtUrl?: Maybe<Scalars['String']['output']>;
  productionFrameNwcUrl?: Maybe<Scalars['String']['output']>;
  productionFrameRvtUrl?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  rebarNwcUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProjectAssetMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  excelBlankBoQKey?: InputMaybe<SortOrder>;
  excelFinishBoQKey?: InputMaybe<SortOrder>;
  excelFrameBoQKey?: InputMaybe<SortOrder>;
  finishBaseNwcUrl?: InputMaybe<SortOrder>;
  finishGltfUrl?: InputMaybe<SortOrder>;
  formworkNwcUrl?: InputMaybe<SortOrder>;
  gltfUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inspectionReportKey?: InputMaybe<SortOrder>;
  productionFinishNwcUrl?: InputMaybe<SortOrder>;
  productionFinishRvtUrl?: InputMaybe<SortOrder>;
  productionFrameNwcUrl?: InputMaybe<SortOrder>;
  productionFrameRvtUrl?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  rebarNwcUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectAssetOrderByWithAggregationInput {
  _avg?: InputMaybe<ProjectAssetAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProjectAssetCountOrderByAggregateInput>;
  _max?: InputMaybe<ProjectAssetMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProjectAssetMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProjectAssetSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  excelBlankBoQKey?: InputMaybe<SortOrderInput>;
  excelFinishBoQKey?: InputMaybe<SortOrderInput>;
  excelFrameBoQKey?: InputMaybe<SortOrderInput>;
  finishBaseNwcUrl?: InputMaybe<SortOrderInput>;
  finishGltfUrl?: InputMaybe<SortOrderInput>;
  formworkNwcUrl?: InputMaybe<SortOrderInput>;
  gltfUrl?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inspectionReportKey?: InputMaybe<SortOrderInput>;
  productionFinishNwcUrl?: InputMaybe<SortOrderInput>;
  productionFinishRvtUrl?: InputMaybe<SortOrderInput>;
  productionFrameNwcUrl?: InputMaybe<SortOrderInput>;
  productionFrameRvtUrl?: InputMaybe<SortOrderInput>;
  projectId?: InputMaybe<SortOrder>;
  rebarNwcUrl?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectAssetOrderByWithRelationInput {
  Project?: InputMaybe<ProjectOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  excelBlankBoQKey?: InputMaybe<SortOrderInput>;
  excelFinishBoQKey?: InputMaybe<SortOrderInput>;
  excelFrameBoQKey?: InputMaybe<SortOrderInput>;
  finishBaseNwcUrl?: InputMaybe<SortOrderInput>;
  finishGltfUrl?: InputMaybe<SortOrderInput>;
  floorInfo?: InputMaybe<ProjectFloorInfoOrderByRelationAggregateInput>;
  formworkNwcUrl?: InputMaybe<SortOrderInput>;
  gltfUrl?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inspectionReportKey?: InputMaybe<SortOrderInput>;
  productionFinishNwcUrl?: InputMaybe<SortOrderInput>;
  productionFinishRvtUrl?: InputMaybe<SortOrderInput>;
  productionFrameNwcUrl?: InputMaybe<SortOrderInput>;
  productionFrameRvtUrl?: InputMaybe<SortOrderInput>;
  projectId?: InputMaybe<SortOrder>;
  rebarNwcUrl?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectAssetRelationFilter {
  is?: InputMaybe<ProjectAssetWhereInput>;
  isNot?: InputMaybe<ProjectAssetWhereInput>;
}

export enum ProjectAssetScalarFieldEnum {
  CreatedAt = 'createdAt',
  ExcelBlankBoQKey = 'excelBlankBoQKey',
  ExcelFinishBoQKey = 'excelFinishBoQKey',
  ExcelFrameBoQKey = 'excelFrameBoQKey',
  FinishBaseNwcUrl = 'finishBaseNwcUrl',
  FinishGltfUrl = 'finishGltfUrl',
  FormworkNwcUrl = 'formworkNwcUrl',
  GltfUrl = 'gltfUrl',
  Id = 'id',
  InspectionReportKey = 'inspectionReportKey',
  ProductionFinishNwcUrl = 'productionFinishNwcUrl',
  ProductionFinishRvtUrl = 'productionFinishRvtUrl',
  ProductionFrameNwcUrl = 'productionFrameNwcUrl',
  ProductionFrameRvtUrl = 'productionFrameRvtUrl',
  ProjectId = 'projectId',
  RebarNwcUrl = 'rebarNwcUrl',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface ProjectAssetScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectAssetScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectAssetScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectAssetScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  excelBlankBoQKey?: InputMaybe<StringNullableWithAggregatesFilter>;
  excelFinishBoQKey?: InputMaybe<StringNullableWithAggregatesFilter>;
  excelFrameBoQKey?: InputMaybe<StringNullableWithAggregatesFilter>;
  finishBaseNwcUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  finishGltfUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  formworkNwcUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  gltfUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  inspectionReportKey?: InputMaybe<StringNullableWithAggregatesFilter>;
  productionFinishNwcUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  productionFinishRvtUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  productionFrameNwcUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  productionFrameRvtUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  projectId?: InputMaybe<IntWithAggregatesFilter>;
  rebarNwcUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface ProjectAssetSumAggregateOutputType {
  __typename?: 'ProjectAssetSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
}

export interface ProjectAssetSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface ProjectAssetUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  excelBlankBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFinishBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFrameBoQKey?: InputMaybe<Scalars['String']['input']>;
  finishBaseNwcUrl?: InputMaybe<Scalars['String']['input']>;
  finishGltfUrl?: InputMaybe<Scalars['String']['input']>;
  floorInfo?: InputMaybe<ProjectFloorInfoUncheckedCreateNestedManyWithoutProjectAssetInput>;
  formworkNwcUrl?: InputMaybe<Scalars['String']['input']>;
  gltfUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inspectionReportKey?: InputMaybe<Scalars['String']['input']>;
  productionFinishNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFinishRvtUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameRvtUrl?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['Int']['input'];
  rebarNwcUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectAssetUncheckedCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<ProjectAssetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectAssetCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectAssetUncheckedCreateWithoutProjectInput>;
}

export interface ProjectAssetUncheckedCreateWithoutFloorInfoInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  excelBlankBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFinishBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFrameBoQKey?: InputMaybe<Scalars['String']['input']>;
  finishBaseNwcUrl?: InputMaybe<Scalars['String']['input']>;
  finishGltfUrl?: InputMaybe<Scalars['String']['input']>;
  formworkNwcUrl?: InputMaybe<Scalars['String']['input']>;
  gltfUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inspectionReportKey?: InputMaybe<Scalars['String']['input']>;
  productionFinishNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFinishRvtUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameRvtUrl?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['Int']['input'];
  rebarNwcUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectAssetUncheckedCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  excelBlankBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFinishBoQKey?: InputMaybe<Scalars['String']['input']>;
  excelFrameBoQKey?: InputMaybe<Scalars['String']['input']>;
  finishBaseNwcUrl?: InputMaybe<Scalars['String']['input']>;
  finishGltfUrl?: InputMaybe<Scalars['String']['input']>;
  floorInfo?: InputMaybe<ProjectFloorInfoUncheckedCreateNestedManyWithoutProjectAssetInput>;
  formworkNwcUrl?: InputMaybe<Scalars['String']['input']>;
  gltfUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inspectionReportKey?: InputMaybe<Scalars['String']['input']>;
  productionFinishNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFinishRvtUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameNwcUrl?: InputMaybe<Scalars['String']['input']>;
  productionFrameRvtUrl?: InputMaybe<Scalars['String']['input']>;
  rebarNwcUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectAssetUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  excelBlankBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFinishBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFrameBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishBaseNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishGltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  floorInfo?: InputMaybe<ProjectFloorInfoUncheckedUpdateManyWithoutProjectAssetNestedInput>;
  formworkNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  inspectionReportKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  rebarNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectAssetUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  excelBlankBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFinishBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFrameBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishBaseNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishGltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formworkNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  inspectionReportKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  rebarNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<ProjectAssetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectAssetCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectAssetUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProjectAssetUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ProjectAssetUpsertWithoutProjectInput>;
}

export interface ProjectAssetUncheckedUpdateWithoutFloorInfoInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  excelBlankBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFinishBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFrameBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishBaseNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishGltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formworkNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  inspectionReportKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  rebarNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectAssetUncheckedUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  excelBlankBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFinishBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFrameBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishBaseNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishGltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  floorInfo?: InputMaybe<ProjectFloorInfoUncheckedUpdateManyWithoutProjectAssetNestedInput>;
  formworkNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  inspectionReportKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rebarNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectAssetUpdateInput {
  Project?: InputMaybe<ProjectUpdateOneRequiredWithoutProjectAssetNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  excelBlankBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFinishBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFrameBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishBaseNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishGltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  floorInfo?: InputMaybe<ProjectFloorInfoUpdateManyWithoutProjectAssetNestedInput>;
  formworkNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  inspectionReportKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rebarNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectAssetUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  excelBlankBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFinishBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFrameBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishBaseNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishGltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formworkNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  inspectionReportKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rebarNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectAssetUpdateOneWithoutFloorInfoNestedInput {
  connect?: InputMaybe<ProjectAssetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectAssetCreateOrConnectWithoutFloorInfoInput>;
  create?: InputMaybe<ProjectAssetUncheckedCreateWithoutFloorInfoInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProjectAssetUncheckedUpdateWithoutFloorInfoInput>;
  upsert?: InputMaybe<ProjectAssetUpsertWithoutFloorInfoInput>;
}

export interface ProjectAssetUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<ProjectAssetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectAssetCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectAssetUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProjectAssetUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ProjectAssetUpsertWithoutProjectInput>;
}

export interface ProjectAssetUpdateWithoutFloorInfoInput {
  Project?: InputMaybe<ProjectUpdateOneRequiredWithoutProjectAssetNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  excelBlankBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFinishBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFrameBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishBaseNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishGltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formworkNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  inspectionReportKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rebarNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectAssetUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  excelBlankBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFinishBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  excelFrameBoQKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishBaseNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  finishGltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  floorInfo?: InputMaybe<ProjectFloorInfoUpdateManyWithoutProjectAssetNestedInput>;
  formworkNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gltfUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  inspectionReportKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFinishRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  productionFrameRvtUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  rebarNwcUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectAssetUpsertWithoutFloorInfoInput {
  create: ProjectAssetUncheckedCreateWithoutFloorInfoInput;
  update: ProjectAssetUncheckedUpdateWithoutFloorInfoInput;
}

export interface ProjectAssetUpsertWithoutProjectInput {
  create: ProjectAssetUncheckedCreateWithoutProjectInput;
  update: ProjectAssetUncheckedUpdateWithoutProjectInput;
}

export interface ProjectAssetWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectAssetWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectAssetWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectAssetWhereInput>>>;
  Project?: InputMaybe<ProjectWhereInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  excelBlankBoQKey?: InputMaybe<StringNullableFilter>;
  excelFinishBoQKey?: InputMaybe<StringNullableFilter>;
  excelFrameBoQKey?: InputMaybe<StringNullableFilter>;
  finishBaseNwcUrl?: InputMaybe<StringNullableFilter>;
  finishGltfUrl?: InputMaybe<StringNullableFilter>;
  floorInfo?: InputMaybe<ProjectFloorInfoListRelationFilter>;
  formworkNwcUrl?: InputMaybe<StringNullableFilter>;
  gltfUrl?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  inspectionReportKey?: InputMaybe<StringNullableFilter>;
  productionFinishNwcUrl?: InputMaybe<StringNullableFilter>;
  productionFinishRvtUrl?: InputMaybe<StringNullableFilter>;
  productionFrameNwcUrl?: InputMaybe<StringNullableFilter>;
  productionFrameRvtUrl?: InputMaybe<StringNullableFilter>;
  projectId?: InputMaybe<IntFilter>;
  rebarNwcUrl?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ProjectAssetWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectAvgAggregateOutputType {
  __typename?: 'ProjectAvgAggregateOutputType';
  aboveGroundArea?: Maybe<Scalars['Float']['output']>;
  aboveGroundFloors?: Maybe<Scalars['Float']['output']>;
  addressId?: Maybe<Scalars['Float']['output']>;
  budget?: Maybe<Scalars['Float']['output']>;
  buildingArea?: Maybe<Scalars['Float']['output']>;
  estimatedPayment?: Maybe<Scalars['Float']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  landArea?: Maybe<Scalars['Float']['output']>;
  maxHeight?: Maybe<Scalars['Float']['output']>;
  maxUnderGroundArea?: Maybe<Scalars['Float']['output']>;
  mechanicalParkingArea?: Maybe<Scalars['Float']['output']>;
  modelPropertyId?: Maybe<Scalars['Float']['output']>;
  ownerId?: Maybe<Scalars['Float']['output']>;
  parkingArea?: Maybe<Scalars['Float']['output']>;
  projectAssetId?: Maybe<Scalars['Float']['output']>;
  shareRatioWorkId?: Maybe<Scalars['Float']['output']>;
  underGroundArea?: Maybe<Scalars['Float']['output']>;
  underGroundFloors?: Maybe<Scalars['Float']['output']>;
  vatInvoiceId?: Maybe<Scalars['Float']['output']>;
}

export interface ProjectAvgOrderByAggregateInput {
  aboveGroundArea?: InputMaybe<SortOrder>;
  aboveGroundFloors?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  budget?: InputMaybe<SortOrder>;
  buildingArea?: InputMaybe<SortOrder>;
  estimatedPayment?: InputMaybe<SortOrder>;
  floorArea?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  landArea?: InputMaybe<SortOrder>;
  maxHeight?: InputMaybe<SortOrder>;
  maxUnderGroundArea?: InputMaybe<SortOrder>;
  mechanicalParkingArea?: InputMaybe<SortOrder>;
  modelPropertyId?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  parkingArea?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrder>;
  shareRatioWorkId?: InputMaybe<SortOrder>;
  underGroundArea?: InputMaybe<SortOrder>;
  underGroundFloors?: InputMaybe<SortOrder>;
  vatInvoiceId?: InputMaybe<SortOrder>;
}

export interface ProjectBoQ {
  __typename?: 'ProjectBoQ';
  BoQItem: Array<BoQItem>;
  Project: Project;
  ProjectBoQFiles: Array<ProjectBoQFiles>;
  _count: ProjectBoQCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  revision: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
}


export type ProjectBoQBoQItemArgs = {
  cursor?: InputMaybe<BoQItemWhereUniqueInput>;
  distinct?: InputMaybe<BoQItemScalarFieldEnum>;
  orderBy?: InputMaybe<BoQItemOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoQItemWhereInput>;
};


export type ProjectBoQProjectBoQFilesArgs = {
  cursor?: InputMaybe<ProjectBoQFilesWhereUniqueInput>;
  distinct?: InputMaybe<ProjectBoQFilesScalarFieldEnum>;
  orderBy?: InputMaybe<ProjectBoQFilesOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectBoQFilesWhereInput>;
};

export interface ProjectBoQAvgAggregateOutputType {
  __typename?: 'ProjectBoQAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  projectId?: Maybe<Scalars['Float']['output']>;
  revision?: Maybe<Scalars['Float']['output']>;
}

export interface ProjectBoQAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
}

export interface ProjectBoQCountAggregateOutputType {
  __typename?: 'ProjectBoQCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  revision: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface ProjectBoQCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectBoQCountOutputType {
  __typename?: 'ProjectBoQCountOutputType';
  BoQItem: Scalars['Int']['output'];
  ProjectBoQFiles: Scalars['Int']['output'];
}

export interface ProjectBoQCreateInput {
  BoQItem?: InputMaybe<BoQItemCreateNestedManyWithoutProjectBoQInput>;
  Project: ProjectCreateNestedOneWithoutProjectBoQInput;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesCreateNestedManyWithoutProjectBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  revision: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  revision: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQCreateNestedManyWithoutBoQItemInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectBoQWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectBoQCreateOrConnectWithoutBoQItemInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectBoQCreateWithoutBoQItemInput>>>;
}

export interface ProjectBoQCreateNestedOneWithoutProjectBoQFilesInput {
  connect?: InputMaybe<ProjectBoQWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectBoQCreateOrConnectWithoutProjectBoQFilesInput>;
  create?: InputMaybe<ProjectBoQUncheckedCreateWithoutProjectBoQFilesInput>;
}

export interface ProjectBoQCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<ProjectBoQWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectBoQCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectBoQUncheckedCreateWithoutProjectInput>;
}

export interface ProjectBoQCreateOrConnectWithoutBoQItemInput {
  create: ProjectBoQUncheckedCreateWithoutBoQItemInput;
  where: ProjectBoQWhereUniqueInput;
}

export interface ProjectBoQCreateOrConnectWithoutProjectBoQFilesInput {
  create: ProjectBoQUncheckedCreateWithoutProjectBoQFilesInput;
  where: ProjectBoQWhereUniqueInput;
}

export interface ProjectBoQCreateOrConnectWithoutProjectInput {
  create: ProjectBoQUncheckedCreateWithoutProjectInput;
  where: ProjectBoQWhereUniqueInput;
}

export interface ProjectBoQCreateWithoutBoQItemInput {
  Project: ProjectCreateNestedOneWithoutProjectBoQInput;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesCreateNestedManyWithoutProjectBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  revision: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQCreateWithoutProjectBoQFilesInput {
  BoQItem?: InputMaybe<BoQItemCreateNestedManyWithoutProjectBoQInput>;
  Project: ProjectCreateNestedOneWithoutProjectBoQInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  revision: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQCreateWithoutProjectInput {
  BoQItem?: InputMaybe<BoQItemCreateNestedManyWithoutProjectBoQInput>;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesCreateNestedManyWithoutProjectBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  revision: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQFiles {
  __typename?: 'ProjectBoQFiles';
  ProjectBoQ?: Maybe<ProjectBoQ>;
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileKey: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  presignedDownloadUrl?: Maybe<Scalars['String']['output']>;
  projectBoQId?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['String']['output'];
}

export interface ProjectBoQFilesAvgAggregateOutputType {
  __typename?: 'ProjectBoQFilesAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  projectBoQId?: Maybe<Scalars['Float']['output']>;
}

export interface ProjectBoQFilesAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectBoQId?: InputMaybe<SortOrder>;
}

export interface ProjectBoQFilesCountAggregateOutputType {
  __typename?: 'ProjectBoQFilesCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  active: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  fileKey: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  memo: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  projectBoQId: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface ProjectBoQFilesCountOrderByAggregateInput {
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fileKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  projectBoQId?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectBoQFilesCreateInput {
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectBoQFilesInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQFilesCreateManyInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectBoQId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQFilesCreateManyProjectBoQInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQFilesCreateManyProjectBoQInputEnvelope {
  data: ProjectBoQFilesCreateManyProjectBoQInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProjectBoQFilesCreateNestedManyWithoutProjectBoQInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectBoQFilesCreateOrConnectWithoutProjectBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectBoQFilesCreateWithoutProjectBoQInput>>>;
  createMany?: InputMaybe<ProjectBoQFilesCreateManyProjectBoQInputEnvelope>;
}

export interface ProjectBoQFilesCreateOrConnectWithoutProjectBoQInput {
  create: ProjectBoQFilesUncheckedCreateWithoutProjectBoQInput;
  where: ProjectBoQFilesWhereUniqueInput;
}

export interface ProjectBoQFilesCreateWithoutProjectBoQInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQFilesListRelationFilter {
  every?: InputMaybe<ProjectBoQFilesWhereInput>;
  none?: InputMaybe<ProjectBoQFilesWhereInput>;
  some?: InputMaybe<ProjectBoQFilesWhereInput>;
}

export interface ProjectBoQFilesMaxAggregateOutputType {
  __typename?: 'ProjectBoQFilesMaxAggregateOutputType';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileKey?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectBoQId?: Maybe<Scalars['Int']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProjectBoQFilesMaxOrderByAggregateInput {
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fileKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  projectBoQId?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectBoQFilesMinAggregateOutputType {
  __typename?: 'ProjectBoQFilesMinAggregateOutputType';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileKey?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  projectBoQId?: Maybe<Scalars['Int']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProjectBoQFilesMinOrderByAggregateInput {
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fileKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  projectBoQId?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectBoQFilesOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ProjectBoQFilesOrderByWithAggregationInput {
  _avg?: InputMaybe<ProjectBoQFilesAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProjectBoQFilesCountOrderByAggregateInput>;
  _max?: InputMaybe<ProjectBoQFilesMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProjectBoQFilesMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProjectBoQFilesSumOrderByAggregateInput>;
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrderInput>;
  fileKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  projectBoQId?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectBoQFilesOrderByWithRelationInput {
  ProjectBoQ?: InputMaybe<ProjectBoQOrderByWithRelationInput>;
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrderInput>;
  fileKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  projectBoQId?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
}

export enum ProjectBoQFilesScalarFieldEnum {
  Active = 'active',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  FileKey = 'fileKey',
  Id = 'id',
  Memo = 'memo',
  Name = 'name',
  ProjectBoQId = 'projectBoQId',
  Uuid = 'uuid'
}

export interface ProjectBoQFilesScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectBoQFilesScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectBoQFilesScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectBoQFilesScalarWhereInput>>>;
  active?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  fileKey?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  projectBoQId?: InputMaybe<IntNullableFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ProjectBoQFilesScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectBoQFilesScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectBoQFilesScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectBoQFilesScalarWhereWithAggregatesInput>>>;
  active?: InputMaybe<BoolWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  fileKey?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  memo?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  projectBoQId?: InputMaybe<IntNullableWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface ProjectBoQFilesSumAggregateOutputType {
  __typename?: 'ProjectBoQFilesSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  projectBoQId?: Maybe<Scalars['Int']['output']>;
}

export interface ProjectBoQFilesSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectBoQId?: InputMaybe<SortOrder>;
}

export interface ProjectBoQFilesUncheckedCreateInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectBoQId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQFilesUncheckedCreateNestedManyWithoutProjectBoQInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectBoQFilesCreateOrConnectWithoutProjectBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectBoQFilesCreateWithoutProjectBoQInput>>>;
  createMany?: InputMaybe<ProjectBoQFilesCreateManyProjectBoQInputEnvelope>;
}

export interface ProjectBoQFilesUncheckedCreateWithoutProjectBoQInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQFilesUncheckedUpdateInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  projectBoQId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQFilesUncheckedUpdateManyInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  projectBoQId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQFilesUncheckedUpdateManyWithoutProjectBoQFilesInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQFilesUncheckedUpdateManyWithoutProjectBoQNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectBoQFilesCreateOrConnectWithoutProjectBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectBoQFilesCreateWithoutProjectBoQInput>>>;
  createMany?: InputMaybe<ProjectBoQFilesCreateManyProjectBoQInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectBoQFilesScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectBoQFilesUpdateWithWhereUniqueWithoutProjectBoQInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectBoQFilesUpdateManyWithWhereWithoutProjectBoQInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectBoQFilesUpsertWithWhereUniqueWithoutProjectBoQInput>>>;
}

export interface ProjectBoQFilesUncheckedUpdateWithoutProjectBoQInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQFilesUpdateInput {
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectBoQFilesNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQFilesUpdateManyMutationInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQFilesUpdateManyWithWhereWithoutProjectBoQInput {
  data: ProjectBoQFilesUncheckedUpdateManyWithoutProjectBoQFilesInput;
  where: ProjectBoQFilesScalarWhereInput;
}

export interface ProjectBoQFilesUpdateManyWithoutProjectBoQNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectBoQFilesCreateOrConnectWithoutProjectBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectBoQFilesCreateWithoutProjectBoQInput>>>;
  createMany?: InputMaybe<ProjectBoQFilesCreateManyProjectBoQInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectBoQFilesScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectBoQFilesUpdateWithWhereUniqueWithoutProjectBoQInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectBoQFilesUpdateManyWithWhereWithoutProjectBoQInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectBoQFilesUpsertWithWhereUniqueWithoutProjectBoQInput>>>;
}

export interface ProjectBoQFilesUpdateWithWhereUniqueWithoutProjectBoQInput {
  data: ProjectBoQFilesUncheckedUpdateWithoutProjectBoQInput;
  where: ProjectBoQFilesWhereUniqueInput;
}

export interface ProjectBoQFilesUpdateWithoutProjectBoQInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQFilesUpsertWithWhereUniqueWithoutProjectBoQInput {
  create: ProjectBoQFilesUncheckedCreateWithoutProjectBoQInput;
  update: ProjectBoQFilesUncheckedUpdateWithoutProjectBoQInput;
  where: ProjectBoQFilesWhereUniqueInput;
}

export interface ProjectBoQFilesWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectBoQFilesWhereInput>>>;
  ProjectBoQ?: InputMaybe<ProjectBoQWhereInput>;
  active?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  fileKey?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  projectBoQId?: InputMaybe<IntNullableFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ProjectBoQFilesWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQListRelationFilter {
  every?: InputMaybe<ProjectBoQWhereInput>;
  none?: InputMaybe<ProjectBoQWhereInput>;
  some?: InputMaybe<ProjectBoQWhereInput>;
}

export interface ProjectBoQMaxAggregateOutputType {
  __typename?: 'ProjectBoQMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  revision?: Maybe<Scalars['Int']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProjectBoQMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectBoQMinAggregateOutputType {
  __typename?: 'ProjectBoQMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  revision?: Maybe<Scalars['Int']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProjectBoQMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectBoQOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ProjectBoQOrderByWithAggregationInput {
  _avg?: InputMaybe<ProjectBoQAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProjectBoQCountOrderByAggregateInput>;
  _max?: InputMaybe<ProjectBoQMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProjectBoQMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProjectBoQSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectBoQOrderByWithRelationInput {
  BoQItem?: InputMaybe<BoQItemOrderByRelationAggregateInput>;
  Project?: InputMaybe<ProjectOrderByWithRelationInput>;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectBoQRelationFilter {
  is?: InputMaybe<ProjectBoQWhereInput>;
  isNot?: InputMaybe<ProjectBoQWhereInput>;
}

export enum ProjectBoQScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ProjectId = 'projectId',
  Revision = 'revision',
  Uuid = 'uuid'
}

export interface ProjectBoQScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectBoQScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectBoQScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectBoQScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  projectId?: InputMaybe<IntFilter>;
  revision?: InputMaybe<IntFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ProjectBoQScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectBoQScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectBoQScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectBoQScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  projectId?: InputMaybe<IntWithAggregatesFilter>;
  revision?: InputMaybe<IntWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface ProjectBoQSumAggregateOutputType {
  __typename?: 'ProjectBoQSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  revision?: Maybe<Scalars['Int']['output']>;
}

export interface ProjectBoQSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
}

export interface ProjectBoQUncheckedCreateInput {
  BoQItem?: InputMaybe<BoQItemUncheckedCreateNestedManyWithoutProjectBoQInput>;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesUncheckedCreateNestedManyWithoutProjectBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  revision: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQUncheckedCreateNestedManyWithoutBoQItemInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectBoQWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectBoQCreateOrConnectWithoutBoQItemInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectBoQCreateWithoutBoQItemInput>>>;
}

export interface ProjectBoQUncheckedCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<ProjectBoQWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectBoQCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectBoQUncheckedCreateWithoutProjectInput>;
}

export interface ProjectBoQUncheckedCreateWithoutBoQItemInput {
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesUncheckedCreateNestedManyWithoutProjectBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  revision: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQUncheckedCreateWithoutProjectBoQFilesInput {
  BoQItem?: InputMaybe<BoQItemUncheckedCreateNestedManyWithoutProjectBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  revision: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQUncheckedCreateWithoutProjectInput {
  BoQItem?: InputMaybe<BoQItemUncheckedCreateNestedManyWithoutProjectBoQInput>;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesUncheckedCreateNestedManyWithoutProjectBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  revision: Scalars['Int']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectBoQUncheckedUpdateInput {
  BoQItem?: InputMaybe<BoQItemUncheckedUpdateManyWithoutProjectBoQNestedInput>;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesUncheckedUpdateManyWithoutProjectBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQUncheckedUpdateManyWithoutBoQItemNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectBoQWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectBoQCreateOrConnectWithoutBoQItemInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectBoQCreateWithoutBoQItemInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ProjectBoQWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectBoQScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectBoQWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectBoQWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectBoQUpdateWithWhereUniqueWithoutBoQItemInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectBoQUpdateManyWithWhereWithoutBoQItemInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectBoQUpsertWithWhereUniqueWithoutBoQItemInput>>>;
}

export interface ProjectBoQUncheckedUpdateManyWithoutProjectBoQInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<ProjectBoQWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectBoQCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectBoQUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProjectBoQUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ProjectBoQUpsertWithoutProjectInput>;
}

export interface ProjectBoQUncheckedUpdateWithoutBoQItemInput {
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesUncheckedUpdateManyWithoutProjectBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQUncheckedUpdateWithoutProjectBoQFilesInput {
  BoQItem?: InputMaybe<BoQItemUncheckedUpdateManyWithoutProjectBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQUncheckedUpdateWithoutProjectInput {
  BoQItem?: InputMaybe<BoQItemUncheckedUpdateManyWithoutProjectBoQNestedInput>;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesUncheckedUpdateManyWithoutProjectBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQUpdateInput {
  BoQItem?: InputMaybe<BoQItemUpdateManyWithoutProjectBoQNestedInput>;
  Project?: InputMaybe<ProjectUpdateOneRequiredWithoutProjectBoQNestedInput>;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesUpdateManyWithoutProjectBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQUpdateManyWithWhereWithoutBoQItemInput {
  data: ProjectBoQUncheckedUpdateManyWithoutProjectBoQInput;
  where: ProjectBoQScalarWhereInput;
}

export interface ProjectBoQUpdateManyWithoutBoQItemNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectBoQWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectBoQCreateOrConnectWithoutBoQItemInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectBoQCreateWithoutBoQItemInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ProjectBoQWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectBoQScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectBoQWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectBoQWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectBoQUpdateWithWhereUniqueWithoutBoQItemInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectBoQUpdateManyWithWhereWithoutBoQItemInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectBoQUpsertWithWhereUniqueWithoutBoQItemInput>>>;
}

export interface ProjectBoQUpdateOneWithoutProjectBoQFilesNestedInput {
  connect?: InputMaybe<ProjectBoQWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectBoQCreateOrConnectWithoutProjectBoQFilesInput>;
  create?: InputMaybe<ProjectBoQUncheckedCreateWithoutProjectBoQFilesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProjectBoQUncheckedUpdateWithoutProjectBoQFilesInput>;
  upsert?: InputMaybe<ProjectBoQUpsertWithoutProjectBoQFilesInput>;
}

export interface ProjectBoQUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<ProjectBoQWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectBoQCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectBoQUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProjectBoQUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ProjectBoQUpsertWithoutProjectInput>;
}

export interface ProjectBoQUpdateWithWhereUniqueWithoutBoQItemInput {
  data: ProjectBoQUncheckedUpdateWithoutBoQItemInput;
  where: ProjectBoQWhereUniqueInput;
}

export interface ProjectBoQUpdateWithoutBoQItemInput {
  Project?: InputMaybe<ProjectUpdateOneRequiredWithoutProjectBoQNestedInput>;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesUpdateManyWithoutProjectBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQUpdateWithoutProjectBoQFilesInput {
  BoQItem?: InputMaybe<BoQItemUpdateManyWithoutProjectBoQNestedInput>;
  Project?: InputMaybe<ProjectUpdateOneRequiredWithoutProjectBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQUpdateWithoutProjectInput {
  BoQItem?: InputMaybe<BoQItemUpdateManyWithoutProjectBoQNestedInput>;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesUpdateManyWithoutProjectBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  revision?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectBoQUpsertWithWhereUniqueWithoutBoQItemInput {
  create: ProjectBoQUncheckedCreateWithoutBoQItemInput;
  update: ProjectBoQUncheckedUpdateWithoutBoQItemInput;
  where: ProjectBoQWhereUniqueInput;
}

export interface ProjectBoQUpsertWithoutProjectBoQFilesInput {
  create: ProjectBoQUncheckedCreateWithoutProjectBoQFilesInput;
  update: ProjectBoQUncheckedUpdateWithoutProjectBoQFilesInput;
}

export interface ProjectBoQUpsertWithoutProjectInput {
  create: ProjectBoQUncheckedCreateWithoutProjectInput;
  update: ProjectBoQUncheckedUpdateWithoutProjectInput;
}

export interface ProjectBoQWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectBoQWhereInput>>>;
  BoQItem?: InputMaybe<BoQItemListRelationFilter>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectBoQWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectBoQWhereInput>>>;
  Project?: InputMaybe<ProjectWhereInput>;
  ProjectBoQFiles?: InputMaybe<ProjectBoQFilesListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  projectId?: InputMaybe<IntFilter>;
  revision?: InputMaybe<IntFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ProjectBoQWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCountAggregateOutputType {
  __typename?: 'ProjectCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  aboveGroundArea: Scalars['Int']['output'];
  aboveGroundFloors: Scalars['Int']['output'];
  addressId: Scalars['Int']['output'];
  architect: Scalars['Int']['output'];
  budget: Scalars['Int']['output'];
  buildingArea: Scalars['Int']['output'];
  client: Scalars['Int']['output'];
  constructionEndDate: Scalars['Int']['output'];
  constructionEtc: Scalars['Int']['output'];
  constructionOption: Scalars['Int']['output'];
  constructionStartDate: Scalars['Int']['output'];
  constructionType: Scalars['Int']['output'];
  contact: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  desiredCompletionDate: Scalars['Int']['output'];
  estimatedPayment: Scalars['Int']['output'];
  estimatedPaymentDate: Scalars['Int']['output'];
  floorArea: Scalars['Int']['output'];
  forgeUrn: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Int']['output'];
  isFavorite: Scalars['Int']['output'];
  landArea: Scalars['Int']['output'];
  maxHeight: Scalars['Int']['output'];
  maxUnderGroundArea: Scalars['Int']['output'];
  mechanicalParkingArea: Scalars['Int']['output'];
  memo: Scalars['Int']['output'];
  modelPropertyId: Scalars['Int']['output'];
  modelRebarExcepted: Scalars['Int']['output'];
  modelService: Scalars['Int']['output'];
  ownerId: Scalars['Int']['output'];
  ownerType: Scalars['Int']['output'];
  parkingArea: Scalars['Int']['output'];
  paymentImpUid: Scalars['Int']['output'];
  projectAssetId: Scalars['Int']['output'];
  projectLevel: Scalars['Int']['output'];
  projectMode: Scalars['Int']['output'];
  projectType: Scalars['Int']['output'];
  rejectedReason: Scalars['Int']['output'];
  requirement: Scalars['Int']['output'];
  shareRatioWorkId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  structure: Scalars['Int']['output'];
  structureInfo: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  underGroundArea: Scalars['Int']['output'];
  underGroundFloors: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  vatInvoiceId: Scalars['Int']['output'];
}

export interface ProjectCountOrderByAggregateInput {
  aboveGroundArea?: InputMaybe<SortOrder>;
  aboveGroundFloors?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  architect?: InputMaybe<SortOrder>;
  budget?: InputMaybe<SortOrder>;
  buildingArea?: InputMaybe<SortOrder>;
  client?: InputMaybe<SortOrder>;
  constructionEndDate?: InputMaybe<SortOrder>;
  constructionEtc?: InputMaybe<SortOrder>;
  constructionOption?: InputMaybe<SortOrder>;
  constructionStartDate?: InputMaybe<SortOrder>;
  constructionType?: InputMaybe<SortOrder>;
  contact?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  desiredCompletionDate?: InputMaybe<SortOrder>;
  estimatedPayment?: InputMaybe<SortOrder>;
  estimatedPaymentDate?: InputMaybe<SortOrder>;
  floorArea?: InputMaybe<SortOrder>;
  forgeUrn?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isFavorite?: InputMaybe<SortOrder>;
  landArea?: InputMaybe<SortOrder>;
  maxHeight?: InputMaybe<SortOrder>;
  maxUnderGroundArea?: InputMaybe<SortOrder>;
  mechanicalParkingArea?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  modelPropertyId?: InputMaybe<SortOrder>;
  modelRebarExcepted?: InputMaybe<SortOrder>;
  modelService?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  ownerType?: InputMaybe<SortOrder>;
  parkingArea?: InputMaybe<SortOrder>;
  paymentImpUid?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrder>;
  projectLevel?: InputMaybe<SortOrder>;
  projectMode?: InputMaybe<SortOrder>;
  projectType?: InputMaybe<SortOrder>;
  rejectedReason?: InputMaybe<SortOrder>;
  requirement?: InputMaybe<SortOrder>;
  shareRatioWorkId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  structure?: InputMaybe<SortOrder>;
  structureInfo?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  underGroundArea?: InputMaybe<SortOrder>;
  underGroundFloors?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vatInvoiceId?: InputMaybe<SortOrder>;
}

export interface ProjectCountOutputType {
  __typename?: 'ProjectCountOutputType';
  CashReceipt: Scalars['Int']['output'];
  InspectionRejected: Scalars['Int']['output'];
  KakaoSendLog: Scalars['Int']['output'];
  PayTransaction: Scalars['Int']['output'];
  ProjectHistory: Scalars['Int']['output'];
  blueprints: Scalars['Int']['output'];
  sharedProject: Scalars['Int']['output'];
  tasks: Scalars['Int']['output'];
}

export interface ProjectCreateInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateManyAddressInput {
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  architect?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectCreateManyAddressInputEnvelope {
  data: ProjectCreateManyAddressInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProjectCreateManyInput {
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectCreateManyOwnerInput {
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectCreateManyOwnerInputEnvelope {
  data: ProjectCreateManyOwnerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProjectCreateManyShareRatioWorkInput {
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectCreateManyShareRatioWorkInputEnvelope {
  data: ProjectCreateManyShareRatioWorkInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProjectCreateManyVatInvoiceInput {
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateManyVatInvoiceInputEnvelope {
  data: ProjectCreateManyVatInvoiceInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProjectCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ProjectCreateManyAddressInputEnvelope>;
}

export interface ProjectCreateNestedManyWithoutOwnerInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutOwnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutOwnerInput>>>;
  createMany?: InputMaybe<ProjectCreateManyOwnerInputEnvelope>;
}

export interface ProjectCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutShareRatioWorkInput>>>;
  createMany?: InputMaybe<ProjectCreateManyShareRatioWorkInputEnvelope>;
}

export interface ProjectCreateNestedManyWithoutVatInvoiceInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutVatInvoiceInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutVatInvoiceInput>>>;
  createMany?: InputMaybe<ProjectCreateManyVatInvoiceInputEnvelope>;
}

export interface ProjectCreateNestedOneWithoutBlueprintsInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutBlueprintsInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutBlueprintsInput>;
}

export interface ProjectCreateNestedOneWithoutCashReceiptInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutCashReceiptInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutCashReceiptInput>;
}

export interface ProjectCreateNestedOneWithoutInspectionRejectedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutInspectionRejectedInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutInspectionRejectedInput>;
}

export interface ProjectCreateNestedOneWithoutKakaoSendLogInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutKakaoSendLogInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutKakaoSendLogInput>;
}

export interface ProjectCreateNestedOneWithoutModelPropertyInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutModelPropertyInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutModelPropertyInput>;
}

export interface ProjectCreateNestedOneWithoutPayTransactionInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutPayTransactionInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutPayTransactionInput>;
}

export interface ProjectCreateNestedOneWithoutProcessSimulationInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProcessSimulationInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutProcessSimulationInput>;
}

export interface ProjectCreateNestedOneWithoutProjectAssetInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectAssetInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutProjectAssetInput>;
}

export interface ProjectCreateNestedOneWithoutProjectBoQInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectBoQInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutProjectBoQInput>;
}

export interface ProjectCreateNestedOneWithoutProjectHistoryInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectHistoryInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutProjectHistoryInput>;
}

export interface ProjectCreateNestedOneWithoutProjectMilestoneInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectMilestoneInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutProjectMilestoneInput>;
}

export interface ProjectCreateNestedOneWithoutSharedProjectInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutSharedProjectInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutSharedProjectInput>;
}

export interface ProjectCreateNestedOneWithoutTasksInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutTasksInput>;
}

export interface ProjectCreateOrConnectWithoutAddressInput {
  create: ProjectUncheckedCreateWithoutAddressInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutBlueprintsInput {
  create: ProjectUncheckedCreateWithoutBlueprintsInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutCashReceiptInput {
  create: ProjectUncheckedCreateWithoutCashReceiptInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutInspectionRejectedInput {
  create: ProjectUncheckedCreateWithoutInspectionRejectedInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutKakaoSendLogInput {
  create: ProjectUncheckedCreateWithoutKakaoSendLogInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutModelPropertyInput {
  create: ProjectUncheckedCreateWithoutModelPropertyInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutOwnerInput {
  create: ProjectUncheckedCreateWithoutOwnerInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutPayTransactionInput {
  create: ProjectUncheckedCreateWithoutPayTransactionInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutProcessSimulationInput {
  create: ProjectUncheckedCreateWithoutProcessSimulationInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutProjectAssetInput {
  create: ProjectUncheckedCreateWithoutProjectAssetInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutProjectBoQInput {
  create: ProjectUncheckedCreateWithoutProjectBoQInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutProjectHistoryInput {
  create: ProjectUncheckedCreateWithoutProjectHistoryInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutProjectMilestoneInput {
  create: ProjectUncheckedCreateWithoutProjectMilestoneInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutShareRatioWorkInput {
  create: ProjectUncheckedCreateWithoutShareRatioWorkInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutSharedProjectInput {
  create: ProjectUncheckedCreateWithoutSharedProjectInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutTasksInput {
  create: ProjectUncheckedCreateWithoutTasksInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateOrConnectWithoutVatInvoiceInput {
  create: ProjectUncheckedCreateWithoutVatInvoiceInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectCreateWithoutAddressInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutBlueprintsInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutCashReceiptInput {
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutInspectionRejectedInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutKakaoSendLogInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutModelPropertyInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutOwnerInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutPayTransactionInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutProcessSimulationInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutProjectAssetInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutProjectBoQInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutProjectHistoryInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutProjectMilestoneInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutShareRatioWorkInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutSharedProjectInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutTasksInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  VatInvoice?: InputMaybe<VatInvoiceCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectCreateWithoutVatInvoiceInput {
  CashReceipt?: InputMaybe<CashReceiptCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  address: AddressCreateNestedOneWithoutProjectInput;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  owner?: InputMaybe<CustomerCreateNestedOneWithoutOwnedProjectInput>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWork?: InputMaybe<ShareRatioWorkCreateNestedOneWithoutProjectInput>;
  sharedProject?: InputMaybe<SharedProjectCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectFloorInfo {
  __typename?: 'ProjectFloorInfo';
  ProjectAsset?: Maybe<ProjectAsset>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** Floor Name */
  floorName: Scalars['String']['output'];
  /** Floor Number */
  floorNumber: Scalars['Int']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  presignedVdsDownloadUrl?: Maybe<Scalars['String']['output']>;
  projectAssetId?: Maybe<Scalars['Int']['output']>;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Project Unique Key */
  uuid: Scalars['String']['output'];
  /** 2D Draw VDS File URL */
  vdsKey?: Maybe<Scalars['String']['output']>;
}

export interface ProjectFloorInfoAvgAggregateOutputType {
  __typename?: 'ProjectFloorInfoAvgAggregateOutputType';
  floorNumber?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  projectAssetId?: Maybe<Scalars['Float']['output']>;
}

export interface ProjectFloorInfoAvgOrderByAggregateInput {
  floorNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrder>;
}

export interface ProjectFloorInfoCountAggregateOutputType {
  __typename?: 'ProjectFloorInfoCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  floorName: Scalars['Int']['output'];
  floorNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  projectAssetId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
  vdsKey: Scalars['Int']['output'];
}

export interface ProjectFloorInfoCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  floorName?: InputMaybe<SortOrder>;
  floorNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vdsKey?: InputMaybe<SortOrder>;
}

export interface ProjectFloorInfoCreateInput {
  ProjectAsset?: InputMaybe<ProjectAssetCreateNestedOneWithoutFloorInfoInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  floorName: Scalars['String']['input'];
  floorNumber: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vdsKey?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectFloorInfoCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  floorName: Scalars['String']['input'];
  floorNumber: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vdsKey?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectFloorInfoCreateManyProjectAssetInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  floorName: Scalars['String']['input'];
  floorNumber: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vdsKey?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectFloorInfoCreateManyProjectAssetInputEnvelope {
  data: ProjectFloorInfoCreateManyProjectAssetInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProjectFloorInfoCreateNestedManyWithoutProjectAssetInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectFloorInfoCreateOrConnectWithoutProjectAssetInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectFloorInfoCreateWithoutProjectAssetInput>>>;
  createMany?: InputMaybe<ProjectFloorInfoCreateManyProjectAssetInputEnvelope>;
}

export interface ProjectFloorInfoCreateOrConnectWithoutProjectAssetInput {
  create: ProjectFloorInfoUncheckedCreateWithoutProjectAssetInput;
  where: ProjectFloorInfoWhereUniqueInput;
}

export interface ProjectFloorInfoCreateWithoutProjectAssetInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  floorName: Scalars['String']['input'];
  floorNumber: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vdsKey?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectFloorInfoListRelationFilter {
  every?: InputMaybe<ProjectFloorInfoWhereInput>;
  none?: InputMaybe<ProjectFloorInfoWhereInput>;
  some?: InputMaybe<ProjectFloorInfoWhereInput>;
}

export interface ProjectFloorInfoMaxAggregateOutputType {
  __typename?: 'ProjectFloorInfoMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  floorName?: Maybe<Scalars['String']['output']>;
  floorNumber?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectAssetId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  vdsKey?: Maybe<Scalars['String']['output']>;
}

export interface ProjectFloorInfoMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  floorName?: InputMaybe<SortOrder>;
  floorNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vdsKey?: InputMaybe<SortOrder>;
}

export interface ProjectFloorInfoMinAggregateOutputType {
  __typename?: 'ProjectFloorInfoMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  floorName?: Maybe<Scalars['String']['output']>;
  floorNumber?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectAssetId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  vdsKey?: Maybe<Scalars['String']['output']>;
}

export interface ProjectFloorInfoMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  floorName?: InputMaybe<SortOrder>;
  floorNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vdsKey?: InputMaybe<SortOrder>;
}

export interface ProjectFloorInfoOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ProjectFloorInfoOrderByWithAggregationInput {
  _avg?: InputMaybe<ProjectFloorInfoAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProjectFloorInfoCountOrderByAggregateInput>;
  _max?: InputMaybe<ProjectFloorInfoMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProjectFloorInfoMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProjectFloorInfoSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  floorName?: InputMaybe<SortOrder>;
  floorNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vdsKey?: InputMaybe<SortOrderInput>;
}

export interface ProjectFloorInfoOrderByWithRelationInput {
  ProjectAsset?: InputMaybe<ProjectAssetOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  floorName?: InputMaybe<SortOrder>;
  floorNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vdsKey?: InputMaybe<SortOrderInput>;
}

export interface ProjectFloorInfoProjectAssetIdFloorNameCompoundUniqueInput {
  floorName: Scalars['String']['input'];
  projectAssetId: Scalars['Int']['input'];
}

export enum ProjectFloorInfoScalarFieldEnum {
  CreatedAt = 'createdAt',
  FloorName = 'floorName',
  FloorNumber = 'floorNumber',
  Id = 'id',
  ProjectAssetId = 'projectAssetId',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  VdsKey = 'vdsKey'
}

export interface ProjectFloorInfoScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectFloorInfoScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectFloorInfoScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectFloorInfoScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  floorName?: InputMaybe<StringFilter>;
  floorNumber?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  projectAssetId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  vdsKey?: InputMaybe<StringNullableFilter>;
}

export interface ProjectFloorInfoScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectFloorInfoScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectFloorInfoScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectFloorInfoScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  floorName?: InputMaybe<StringWithAggregatesFilter>;
  floorNumber?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  projectAssetId?: InputMaybe<IntNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  vdsKey?: InputMaybe<StringNullableWithAggregatesFilter>;
}

export interface ProjectFloorInfoSumAggregateOutputType {
  __typename?: 'ProjectFloorInfoSumAggregateOutputType';
  floorNumber?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectAssetId?: Maybe<Scalars['Int']['output']>;
}

export interface ProjectFloorInfoSumOrderByAggregateInput {
  floorNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrder>;
}

export interface ProjectFloorInfoUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  floorName: Scalars['String']['input'];
  floorNumber: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vdsKey?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectFloorInfoUncheckedCreateNestedManyWithoutProjectAssetInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectFloorInfoCreateOrConnectWithoutProjectAssetInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectFloorInfoCreateWithoutProjectAssetInput>>>;
  createMany?: InputMaybe<ProjectFloorInfoCreateManyProjectAssetInputEnvelope>;
}

export interface ProjectFloorInfoUncheckedCreateWithoutProjectAssetInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  floorName: Scalars['String']['input'];
  floorNumber: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vdsKey?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectFloorInfoUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorName?: InputMaybe<StringFieldUpdateOperationsInput>;
  floorNumber?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vdsKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProjectFloorInfoUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorName?: InputMaybe<StringFieldUpdateOperationsInput>;
  floorNumber?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vdsKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProjectFloorInfoUncheckedUpdateManyWithoutFloorInfoInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorName?: InputMaybe<StringFieldUpdateOperationsInput>;
  floorNumber?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vdsKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProjectFloorInfoUncheckedUpdateManyWithoutProjectAssetNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectFloorInfoCreateOrConnectWithoutProjectAssetInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectFloorInfoCreateWithoutProjectAssetInput>>>;
  createMany?: InputMaybe<ProjectFloorInfoCreateManyProjectAssetInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectFloorInfoScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectFloorInfoUpdateWithWhereUniqueWithoutProjectAssetInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectFloorInfoUpdateManyWithWhereWithoutProjectAssetInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectFloorInfoUpsertWithWhereUniqueWithoutProjectAssetInput>>>;
}

export interface ProjectFloorInfoUncheckedUpdateWithoutProjectAssetInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorName?: InputMaybe<StringFieldUpdateOperationsInput>;
  floorNumber?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vdsKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProjectFloorInfoUpdateInput {
  ProjectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutFloorInfoNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorName?: InputMaybe<StringFieldUpdateOperationsInput>;
  floorNumber?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vdsKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProjectFloorInfoUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorName?: InputMaybe<StringFieldUpdateOperationsInput>;
  floorNumber?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vdsKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProjectFloorInfoUpdateManyWithWhereWithoutProjectAssetInput {
  data: ProjectFloorInfoUncheckedUpdateManyWithoutFloorInfoInput;
  where: ProjectFloorInfoScalarWhereInput;
}

export interface ProjectFloorInfoUpdateManyWithoutProjectAssetNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectFloorInfoCreateOrConnectWithoutProjectAssetInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectFloorInfoCreateWithoutProjectAssetInput>>>;
  createMany?: InputMaybe<ProjectFloorInfoCreateManyProjectAssetInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectFloorInfoScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectFloorInfoUpdateWithWhereUniqueWithoutProjectAssetInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectFloorInfoUpdateManyWithWhereWithoutProjectAssetInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectFloorInfoUpsertWithWhereUniqueWithoutProjectAssetInput>>>;
}

export interface ProjectFloorInfoUpdateWithWhereUniqueWithoutProjectAssetInput {
  data: ProjectFloorInfoUncheckedUpdateWithoutProjectAssetInput;
  where: ProjectFloorInfoWhereUniqueInput;
}

export interface ProjectFloorInfoUpdateWithoutProjectAssetInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorName?: InputMaybe<StringFieldUpdateOperationsInput>;
  floorNumber?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vdsKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
}

export interface ProjectFloorInfoUpsertWithWhereUniqueWithoutProjectAssetInput {
  create: ProjectFloorInfoUncheckedCreateWithoutProjectAssetInput;
  update: ProjectFloorInfoUncheckedUpdateWithoutProjectAssetInput;
  where: ProjectFloorInfoWhereUniqueInput;
}

export interface ProjectFloorInfoWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectFloorInfoWhereInput>>>;
  ProjectAsset?: InputMaybe<ProjectAssetWhereInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  floorName?: InputMaybe<StringFilter>;
  floorNumber?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  projectAssetId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  vdsKey?: InputMaybe<StringNullableFilter>;
}

export interface ProjectFloorInfoWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  projectAssetId_floorName?: InputMaybe<ProjectFloorInfoProjectAssetIdFloorNameCompoundUniqueInput>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** Project history */
export interface ProjectHistory {
  __typename?: 'ProjectHistory';
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** Member email */
  email: Scalars['String']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** email memeber name */
  name: Scalars['String']['output'];
  /** Project */
  project: Project;
  projectId: Scalars['Int']['output'];
  /** Revision text */
  revision: Scalars['String']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Project Unique Key */
  uuid: Scalars['String']['output'];
}

export interface ProjectHistoryAvgAggregateOutputType {
  __typename?: 'ProjectHistoryAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  projectId?: Maybe<Scalars['Float']['output']>;
}

export interface ProjectHistoryAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface ProjectHistoryCountAggregateOutputType {
  __typename?: 'ProjectHistoryCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  revision: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface ProjectHistoryCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectHistoryCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  project: ProjectCreateNestedOneWithoutProjectHistoryInput;
  revision: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectHistoryCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  revision: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectHistoryCreateManyProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  revision: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectHistoryCreateManyProjectInputEnvelope {
  data: ProjectHistoryCreateManyProjectInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ProjectHistoryCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectHistoryCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectHistoryCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<ProjectHistoryCreateManyProjectInputEnvelope>;
}

export interface ProjectHistoryCreateOrConnectWithoutProjectInput {
  create: ProjectHistoryUncheckedCreateWithoutProjectInput;
  where: ProjectHistoryWhereUniqueInput;
}

export interface ProjectHistoryCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  revision: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectHistoryListRelationFilter {
  every?: InputMaybe<ProjectHistoryWhereInput>;
  none?: InputMaybe<ProjectHistoryWhereInput>;
  some?: InputMaybe<ProjectHistoryWhereInput>;
}

export interface ProjectHistoryMaxAggregateOutputType {
  __typename?: 'ProjectHistoryMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  revision?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProjectHistoryMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectHistoryMinAggregateOutputType {
  __typename?: 'ProjectHistoryMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  revision?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProjectHistoryMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectHistoryOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ProjectHistoryOrderByWithAggregationInput {
  _avg?: InputMaybe<ProjectHistoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProjectHistoryCountOrderByAggregateInput>;
  _max?: InputMaybe<ProjectHistoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProjectHistoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProjectHistorySumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectHistoryOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  revision?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export enum ProjectHistoryScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  ProjectId = 'projectId',
  Revision = 'revision',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface ProjectHistoryScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  projectId?: InputMaybe<IntFilter>;
  revision?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ProjectHistoryScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  projectId?: InputMaybe<IntWithAggregatesFilter>;
  revision?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface ProjectHistorySumAggregateOutputType {
  __typename?: 'ProjectHistorySumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
}

export interface ProjectHistorySumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface ProjectHistoryUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  revision: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectHistoryCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectHistoryCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<ProjectHistoryCreateManyProjectInputEnvelope>;
}

export interface ProjectHistoryUncheckedCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  revision: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectHistoryUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  revision?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectHistoryUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  revision?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectHistoryUncheckedUpdateManyWithoutProjectHistoryInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  revision?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectHistoryCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectHistoryCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<ProjectHistoryCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectHistoryUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectHistoryUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectHistoryUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface ProjectHistoryUncheckedUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  revision?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectHistoryUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutProjectHistoryNestedInput>;
  revision?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectHistoryUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  revision?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectHistoryUpdateManyWithWhereWithoutProjectInput {
  data: ProjectHistoryUncheckedUpdateManyWithoutProjectHistoryInput;
  where: ProjectHistoryScalarWhereInput;
}

export interface ProjectHistoryUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectHistoryCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectHistoryCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<ProjectHistoryCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectHistoryUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectHistoryUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectHistoryUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface ProjectHistoryUpdateWithWhereUniqueWithoutProjectInput {
  data: ProjectHistoryUncheckedUpdateWithoutProjectInput;
  where: ProjectHistoryWhereUniqueInput;
}

export interface ProjectHistoryUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  revision?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectHistoryUpsertWithWhereUniqueWithoutProjectInput {
  create: ProjectHistoryUncheckedCreateWithoutProjectInput;
  update: ProjectHistoryUncheckedUpdateWithoutProjectInput;
  where: ProjectHistoryWhereUniqueInput;
}

export interface ProjectHistoryWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectHistoryWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  project?: InputMaybe<ProjectWhereInput>;
  projectId?: InputMaybe<IntFilter>;
  revision?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ProjectHistoryWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export enum ProjectLevel {
  Basic = 'BASIC',
  Premium = 'PREMIUM',
  Pro = 'PRO'
}

export interface ProjectListRelationFilter {
  every?: InputMaybe<ProjectWhereInput>;
  none?: InputMaybe<ProjectWhereInput>;
  some?: InputMaybe<ProjectWhereInput>;
}

export interface ProjectMaxAggregateOutputType {
  __typename?: 'ProjectMaxAggregateOutputType';
  aboveGroundArea?: Maybe<Scalars['Float']['output']>;
  aboveGroundFloors?: Maybe<Scalars['Int']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  architect?: Maybe<Scalars['String']['output']>;
  budget?: Maybe<Scalars['Float']['output']>;
  buildingArea?: Maybe<Scalars['Float']['output']>;
  client?: Maybe<Scalars['String']['output']>;
  constructionEndDate?: Maybe<Scalars['DateTime']['output']>;
  constructionEtc?: Maybe<Scalars['String']['output']>;
  constructionOption?: Maybe<Scalars['String']['output']>;
  constructionStartDate?: Maybe<Scalars['DateTime']['output']>;
  constructionType?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  desiredCompletionDate?: Maybe<Scalars['String']['output']>;
  estimatedPayment?: Maybe<Scalars['Int']['output']>;
  estimatedPaymentDate?: Maybe<Scalars['DateTime']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  forgeUrn?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  landArea?: Maybe<Scalars['Float']['output']>;
  maxHeight?: Maybe<Scalars['Int']['output']>;
  maxUnderGroundArea?: Maybe<Scalars['Float']['output']>;
  mechanicalParkingArea?: Maybe<Scalars['Int']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  modelPropertyId?: Maybe<Scalars['Int']['output']>;
  modelRebarExcepted?: Maybe<Scalars['Boolean']['output']>;
  modelService?: Maybe<UploadOption>;
  ownerId?: Maybe<Scalars['Int']['output']>;
  ownerType?: Maybe<ProjectOwnerType>;
  parkingArea?: Maybe<Scalars['Int']['output']>;
  paymentImpUid?: Maybe<Scalars['String']['output']>;
  projectAssetId?: Maybe<Scalars['Int']['output']>;
  projectLevel?: Maybe<ProjectLevel>;
  projectMode?: Maybe<ProjectMode>;
  projectType?: Maybe<ProjectType>;
  rejectedReason?: Maybe<Scalars['String']['output']>;
  requirement?: Maybe<Scalars['String']['output']>;
  shareRatioWorkId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ProjectStatus>;
  structure?: Maybe<Scalars['String']['output']>;
  structureInfo?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  underGroundArea?: Maybe<Scalars['Float']['output']>;
  underGroundFloors?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  vatInvoiceId?: Maybe<Scalars['Int']['output']>;
}

export interface ProjectMaxOrderByAggregateInput {
  aboveGroundArea?: InputMaybe<SortOrder>;
  aboveGroundFloors?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  architect?: InputMaybe<SortOrder>;
  budget?: InputMaybe<SortOrder>;
  buildingArea?: InputMaybe<SortOrder>;
  client?: InputMaybe<SortOrder>;
  constructionEndDate?: InputMaybe<SortOrder>;
  constructionEtc?: InputMaybe<SortOrder>;
  constructionOption?: InputMaybe<SortOrder>;
  constructionStartDate?: InputMaybe<SortOrder>;
  constructionType?: InputMaybe<SortOrder>;
  contact?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  desiredCompletionDate?: InputMaybe<SortOrder>;
  estimatedPayment?: InputMaybe<SortOrder>;
  estimatedPaymentDate?: InputMaybe<SortOrder>;
  floorArea?: InputMaybe<SortOrder>;
  forgeUrn?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isFavorite?: InputMaybe<SortOrder>;
  landArea?: InputMaybe<SortOrder>;
  maxHeight?: InputMaybe<SortOrder>;
  maxUnderGroundArea?: InputMaybe<SortOrder>;
  mechanicalParkingArea?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  modelPropertyId?: InputMaybe<SortOrder>;
  modelRebarExcepted?: InputMaybe<SortOrder>;
  modelService?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  ownerType?: InputMaybe<SortOrder>;
  parkingArea?: InputMaybe<SortOrder>;
  paymentImpUid?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrder>;
  projectLevel?: InputMaybe<SortOrder>;
  projectMode?: InputMaybe<SortOrder>;
  projectType?: InputMaybe<SortOrder>;
  rejectedReason?: InputMaybe<SortOrder>;
  requirement?: InputMaybe<SortOrder>;
  shareRatioWorkId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  structure?: InputMaybe<SortOrder>;
  structureInfo?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  underGroundArea?: InputMaybe<SortOrder>;
  underGroundFloors?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vatInvoiceId?: InputMaybe<SortOrder>;
}

/** 간이공정표 */
export interface ProjectMilestone {
  __typename?: 'ProjectMilestone';
  _count: ProjectMilestoneCountOutputType;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** endDate */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** 공정표 사진 및 PDF */
  milestoneFile?: Maybe<Scalars['String']['output']>;
  /** 공정표 사진 및 PDF 파일 이름 */
  milestoneFileName?: Maybe<Scalars['String']['output']>;
  presignedMilestoneFile?: Maybe<Scalars['String']['output']>;
  /** project */
  project: Project;
  /** project uuid */
  projectUuid: Scalars['String']['output'];
  /** startDate */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** 총공사금액 */
  totalConstructionAmount: Scalars['BigInt']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique Key */
  uuid: Scalars['String']['output'];
  /** work items */
  workItem: Array<MilestoneWorkItem>;
}


/** 간이공정표 */
export type ProjectMilestoneWorkItemArgs = {
  cursor?: InputMaybe<MilestoneWorkItemWhereUniqueInput>;
  distinct?: InputMaybe<MilestoneWorkItemScalarFieldEnum>;
  orderBy?: InputMaybe<MilestoneWorkItemOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MilestoneWorkItemWhereInput>;
};

export interface ProjectMilestoneAvgAggregateOutputType {
  __typename?: 'ProjectMilestoneAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  totalConstructionAmount?: Maybe<Scalars['Float']['output']>;
}

export interface ProjectMilestoneAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  totalConstructionAmount?: InputMaybe<SortOrder>;
}

export interface ProjectMilestoneCountAggregateOutputType {
  __typename?: 'ProjectMilestoneCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  endDate: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  milestoneFile: Scalars['Int']['output'];
  milestoneFileName: Scalars['Int']['output'];
  projectUuid: Scalars['Int']['output'];
  startDate: Scalars['Int']['output'];
  totalConstructionAmount: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface ProjectMilestoneCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  milestoneFile?: InputMaybe<SortOrder>;
  milestoneFileName?: InputMaybe<SortOrder>;
  projectUuid?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  totalConstructionAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectMilestoneCountOutputType {
  __typename?: 'ProjectMilestoneCountOutputType';
  workItem: Scalars['Int']['output'];
}

export interface ProjectMilestoneCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  milestoneFile?: InputMaybe<Scalars['String']['input']>;
  milestoneFileName?: InputMaybe<Scalars['String']['input']>;
  project: ProjectCreateNestedOneWithoutProjectMilestoneInput;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  totalConstructionAmount: Scalars['BigInt']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  workItem?: InputMaybe<MilestoneWorkItemCreateNestedManyWithoutProjectMilestoneInput>;
}

export interface ProjectMilestoneCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  milestoneFile?: InputMaybe<Scalars['String']['input']>;
  milestoneFileName?: InputMaybe<Scalars['String']['input']>;
  projectUuid: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  totalConstructionAmount: Scalars['BigInt']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectMilestoneCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<ProjectMilestoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectMilestoneCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectMilestoneUncheckedCreateWithoutProjectInput>;
}

export interface ProjectMilestoneCreateNestedOneWithoutWorkItemInput {
  connect?: InputMaybe<ProjectMilestoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectMilestoneCreateOrConnectWithoutWorkItemInput>;
  create?: InputMaybe<ProjectMilestoneUncheckedCreateWithoutWorkItemInput>;
}

export interface ProjectMilestoneCreateOrConnectWithoutProjectInput {
  create: ProjectMilestoneUncheckedCreateWithoutProjectInput;
  where: ProjectMilestoneWhereUniqueInput;
}

export interface ProjectMilestoneCreateOrConnectWithoutWorkItemInput {
  create: ProjectMilestoneUncheckedCreateWithoutWorkItemInput;
  where: ProjectMilestoneWhereUniqueInput;
}

export interface ProjectMilestoneCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  milestoneFile?: InputMaybe<Scalars['String']['input']>;
  milestoneFileName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  totalConstructionAmount: Scalars['BigInt']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  workItem?: InputMaybe<MilestoneWorkItemCreateNestedManyWithoutProjectMilestoneInput>;
}

export interface ProjectMilestoneCreateWithoutWorkItemInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  milestoneFile?: InputMaybe<Scalars['String']['input']>;
  milestoneFileName?: InputMaybe<Scalars['String']['input']>;
  project: ProjectCreateNestedOneWithoutProjectMilestoneInput;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  totalConstructionAmount: Scalars['BigInt']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectMilestoneMaxAggregateOutputType {
  __typename?: 'ProjectMilestoneMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  milestoneFile?: Maybe<Scalars['String']['output']>;
  milestoneFileName?: Maybe<Scalars['String']['output']>;
  projectUuid?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  totalConstructionAmount?: Maybe<Scalars['BigInt']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProjectMilestoneMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  milestoneFile?: InputMaybe<SortOrder>;
  milestoneFileName?: InputMaybe<SortOrder>;
  projectUuid?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  totalConstructionAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectMilestoneMinAggregateOutputType {
  __typename?: 'ProjectMilestoneMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  milestoneFile?: Maybe<Scalars['String']['output']>;
  milestoneFileName?: Maybe<Scalars['String']['output']>;
  projectUuid?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  totalConstructionAmount?: Maybe<Scalars['BigInt']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ProjectMilestoneMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  milestoneFile?: InputMaybe<SortOrder>;
  milestoneFileName?: InputMaybe<SortOrder>;
  projectUuid?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  totalConstructionAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectMilestoneOrderByWithAggregationInput {
  _avg?: InputMaybe<ProjectMilestoneAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProjectMilestoneCountOrderByAggregateInput>;
  _max?: InputMaybe<ProjectMilestoneMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProjectMilestoneMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProjectMilestoneSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  milestoneFile?: InputMaybe<SortOrderInput>;
  milestoneFileName?: InputMaybe<SortOrderInput>;
  projectUuid?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrderInput>;
  totalConstructionAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ProjectMilestoneOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  milestoneFile?: InputMaybe<SortOrderInput>;
  milestoneFileName?: InputMaybe<SortOrderInput>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectUuid?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrderInput>;
  totalConstructionAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  workItem?: InputMaybe<MilestoneWorkItemOrderByRelationAggregateInput>;
}

export interface ProjectMilestoneRelationFilter {
  is?: InputMaybe<ProjectMilestoneWhereInput>;
  isNot?: InputMaybe<ProjectMilestoneWhereInput>;
}

export enum ProjectMilestoneScalarFieldEnum {
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Id = 'id',
  MilestoneFile = 'milestoneFile',
  MilestoneFileName = 'milestoneFileName',
  ProjectUuid = 'projectUuid',
  StartDate = 'startDate',
  TotalConstructionAmount = 'totalConstructionAmount',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface ProjectMilestoneScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectMilestoneScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectMilestoneScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectMilestoneScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  endDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  milestoneFile?: InputMaybe<StringNullableWithAggregatesFilter>;
  milestoneFileName?: InputMaybe<StringNullableWithAggregatesFilter>;
  projectUuid?: InputMaybe<StringWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  totalConstructionAmount?: InputMaybe<BigIntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface ProjectMilestoneSumAggregateOutputType {
  __typename?: 'ProjectMilestoneSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  totalConstructionAmount?: Maybe<Scalars['BigInt']['output']>;
}

export interface ProjectMilestoneSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  totalConstructionAmount?: InputMaybe<SortOrder>;
}

export interface ProjectMilestoneUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  milestoneFile?: InputMaybe<Scalars['String']['input']>;
  milestoneFileName?: InputMaybe<Scalars['String']['input']>;
  projectUuid: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  totalConstructionAmount: Scalars['BigInt']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  workItem?: InputMaybe<MilestoneWorkItemUncheckedCreateNestedManyWithoutProjectMilestoneInput>;
}

export interface ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<ProjectMilestoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectMilestoneCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectMilestoneUncheckedCreateWithoutProjectInput>;
}

export interface ProjectMilestoneUncheckedCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  milestoneFile?: InputMaybe<Scalars['String']['input']>;
  milestoneFileName?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  totalConstructionAmount: Scalars['BigInt']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  workItem?: InputMaybe<MilestoneWorkItemUncheckedCreateNestedManyWithoutProjectMilestoneInput>;
}

export interface ProjectMilestoneUncheckedCreateWithoutWorkItemInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  milestoneFile?: InputMaybe<Scalars['String']['input']>;
  milestoneFileName?: InputMaybe<Scalars['String']['input']>;
  projectUuid: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  totalConstructionAmount: Scalars['BigInt']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectMilestoneUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  milestoneFile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  milestoneFileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectUuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  totalConstructionAmount?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  workItem?: InputMaybe<MilestoneWorkItemUncheckedUpdateManyWithoutProjectMilestoneNestedInput>;
}

export interface ProjectMilestoneUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  milestoneFile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  milestoneFileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectUuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  totalConstructionAmount?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<ProjectMilestoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectMilestoneCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectMilestoneUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProjectMilestoneUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ProjectMilestoneUpsertWithoutProjectInput>;
}

export interface ProjectMilestoneUncheckedUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  milestoneFile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  milestoneFileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  totalConstructionAmount?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  workItem?: InputMaybe<MilestoneWorkItemUncheckedUpdateManyWithoutProjectMilestoneNestedInput>;
}

export interface ProjectMilestoneUncheckedUpdateWithoutWorkItemInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  milestoneFile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  milestoneFileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectUuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  totalConstructionAmount?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectMilestoneUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  milestoneFile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  milestoneFileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutProjectMilestoneNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  totalConstructionAmount?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  workItem?: InputMaybe<MilestoneWorkItemUpdateManyWithoutProjectMilestoneNestedInput>;
}

export interface ProjectMilestoneUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  milestoneFile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  milestoneFileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  totalConstructionAmount?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectMilestoneUpdateOneRequiredWithoutWorkItemNestedInput {
  connect?: InputMaybe<ProjectMilestoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectMilestoneCreateOrConnectWithoutWorkItemInput>;
  create?: InputMaybe<ProjectMilestoneUncheckedCreateWithoutWorkItemInput>;
  update?: InputMaybe<ProjectMilestoneUncheckedUpdateWithoutWorkItemInput>;
  upsert?: InputMaybe<ProjectMilestoneUpsertWithoutWorkItemInput>;
}

export interface ProjectMilestoneUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<ProjectMilestoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectMilestoneCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ProjectMilestoneUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProjectMilestoneUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ProjectMilestoneUpsertWithoutProjectInput>;
}

export interface ProjectMilestoneUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  milestoneFile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  milestoneFileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  totalConstructionAmount?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  workItem?: InputMaybe<MilestoneWorkItemUpdateManyWithoutProjectMilestoneNestedInput>;
}

export interface ProjectMilestoneUpdateWithoutWorkItemInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  milestoneFile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  milestoneFileName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutProjectMilestoneNestedInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  totalConstructionAmount?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectMilestoneUpsertWithoutProjectInput {
  create: ProjectMilestoneUncheckedCreateWithoutProjectInput;
  update: ProjectMilestoneUncheckedUpdateWithoutProjectInput;
}

export interface ProjectMilestoneUpsertWithoutWorkItemInput {
  create: ProjectMilestoneUncheckedCreateWithoutWorkItemInput;
  update: ProjectMilestoneUncheckedUpdateWithoutWorkItemInput;
}

export interface ProjectMilestoneWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectMilestoneWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectMilestoneWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectMilestoneWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  milestoneFile?: InputMaybe<StringNullableFilter>;
  milestoneFileName?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectWhereInput>;
  projectUuid?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  totalConstructionAmount?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  workItem?: InputMaybe<MilestoneWorkItemListRelationFilter>;
}

export interface ProjectMilestoneWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  projectUuid?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectMinAggregateOutputType {
  __typename?: 'ProjectMinAggregateOutputType';
  aboveGroundArea?: Maybe<Scalars['Float']['output']>;
  aboveGroundFloors?: Maybe<Scalars['Int']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  architect?: Maybe<Scalars['String']['output']>;
  budget?: Maybe<Scalars['Float']['output']>;
  buildingArea?: Maybe<Scalars['Float']['output']>;
  client?: Maybe<Scalars['String']['output']>;
  constructionEndDate?: Maybe<Scalars['DateTime']['output']>;
  constructionEtc?: Maybe<Scalars['String']['output']>;
  constructionOption?: Maybe<Scalars['String']['output']>;
  constructionStartDate?: Maybe<Scalars['DateTime']['output']>;
  constructionType?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  desiredCompletionDate?: Maybe<Scalars['String']['output']>;
  estimatedPayment?: Maybe<Scalars['Int']['output']>;
  estimatedPaymentDate?: Maybe<Scalars['DateTime']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  forgeUrn?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  landArea?: Maybe<Scalars['Float']['output']>;
  maxHeight?: Maybe<Scalars['Int']['output']>;
  maxUnderGroundArea?: Maybe<Scalars['Float']['output']>;
  mechanicalParkingArea?: Maybe<Scalars['Int']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  modelPropertyId?: Maybe<Scalars['Int']['output']>;
  modelRebarExcepted?: Maybe<Scalars['Boolean']['output']>;
  modelService?: Maybe<UploadOption>;
  ownerId?: Maybe<Scalars['Int']['output']>;
  ownerType?: Maybe<ProjectOwnerType>;
  parkingArea?: Maybe<Scalars['Int']['output']>;
  paymentImpUid?: Maybe<Scalars['String']['output']>;
  projectAssetId?: Maybe<Scalars['Int']['output']>;
  projectLevel?: Maybe<ProjectLevel>;
  projectMode?: Maybe<ProjectMode>;
  projectType?: Maybe<ProjectType>;
  rejectedReason?: Maybe<Scalars['String']['output']>;
  requirement?: Maybe<Scalars['String']['output']>;
  shareRatioWorkId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ProjectStatus>;
  structure?: Maybe<Scalars['String']['output']>;
  structureInfo?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  underGroundArea?: Maybe<Scalars['Float']['output']>;
  underGroundFloors?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
  vatInvoiceId?: Maybe<Scalars['Int']['output']>;
}

export interface ProjectMinOrderByAggregateInput {
  aboveGroundArea?: InputMaybe<SortOrder>;
  aboveGroundFloors?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  architect?: InputMaybe<SortOrder>;
  budget?: InputMaybe<SortOrder>;
  buildingArea?: InputMaybe<SortOrder>;
  client?: InputMaybe<SortOrder>;
  constructionEndDate?: InputMaybe<SortOrder>;
  constructionEtc?: InputMaybe<SortOrder>;
  constructionOption?: InputMaybe<SortOrder>;
  constructionStartDate?: InputMaybe<SortOrder>;
  constructionType?: InputMaybe<SortOrder>;
  contact?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  desiredCompletionDate?: InputMaybe<SortOrder>;
  estimatedPayment?: InputMaybe<SortOrder>;
  estimatedPaymentDate?: InputMaybe<SortOrder>;
  floorArea?: InputMaybe<SortOrder>;
  forgeUrn?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isFavorite?: InputMaybe<SortOrder>;
  landArea?: InputMaybe<SortOrder>;
  maxHeight?: InputMaybe<SortOrder>;
  maxUnderGroundArea?: InputMaybe<SortOrder>;
  mechanicalParkingArea?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  modelPropertyId?: InputMaybe<SortOrder>;
  modelRebarExcepted?: InputMaybe<SortOrder>;
  modelService?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  ownerType?: InputMaybe<SortOrder>;
  parkingArea?: InputMaybe<SortOrder>;
  paymentImpUid?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrder>;
  projectLevel?: InputMaybe<SortOrder>;
  projectMode?: InputMaybe<SortOrder>;
  projectType?: InputMaybe<SortOrder>;
  rejectedReason?: InputMaybe<SortOrder>;
  requirement?: InputMaybe<SortOrder>;
  shareRatioWorkId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  structure?: InputMaybe<SortOrder>;
  structureInfo?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  underGroundArea?: InputMaybe<SortOrder>;
  underGroundFloors?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vatInvoiceId?: InputMaybe<SortOrder>;
}

export enum ProjectMode {
  Bha = 'BHA',
  Bhq = 'BHQ',
  Full = 'FULL'
}

export interface ProjectOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ProjectOrderByWithAggregationInput {
  _avg?: InputMaybe<ProjectAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProjectCountOrderByAggregateInput>;
  _max?: InputMaybe<ProjectMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProjectMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProjectSumOrderByAggregateInput>;
  aboveGroundArea?: InputMaybe<SortOrderInput>;
  aboveGroundFloors?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrder>;
  architect?: InputMaybe<SortOrderInput>;
  budget?: InputMaybe<SortOrder>;
  buildingArea?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<SortOrderInput>;
  constructionEndDate?: InputMaybe<SortOrderInput>;
  constructionEtc?: InputMaybe<SortOrderInput>;
  constructionOption?: InputMaybe<SortOrderInput>;
  constructionStartDate?: InputMaybe<SortOrderInput>;
  constructionType?: InputMaybe<SortOrder>;
  contact?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  desiredCompletionDate?: InputMaybe<SortOrderInput>;
  estimatedPayment?: InputMaybe<SortOrderInput>;
  estimatedPaymentDate?: InputMaybe<SortOrderInput>;
  floorArea?: InputMaybe<SortOrderInput>;
  forgeUrn?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isFavorite?: InputMaybe<SortOrder>;
  landArea?: InputMaybe<SortOrderInput>;
  maxHeight?: InputMaybe<SortOrderInput>;
  maxUnderGroundArea?: InputMaybe<SortOrderInput>;
  mechanicalParkingArea?: InputMaybe<SortOrderInput>;
  memo?: InputMaybe<SortOrderInput>;
  modelPropertyId?: InputMaybe<SortOrderInput>;
  modelRebarExcepted?: InputMaybe<SortOrder>;
  modelService?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrderInput>;
  ownerType?: InputMaybe<SortOrderInput>;
  parkingArea?: InputMaybe<SortOrderInput>;
  paymentImpUid?: InputMaybe<SortOrderInput>;
  projectAssetId?: InputMaybe<SortOrderInput>;
  projectLevel?: InputMaybe<SortOrderInput>;
  projectMode?: InputMaybe<SortOrderInput>;
  projectType?: InputMaybe<SortOrderInput>;
  rejectedReason?: InputMaybe<SortOrderInput>;
  requirement?: InputMaybe<SortOrderInput>;
  shareRatioWorkId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  structure?: InputMaybe<SortOrderInput>;
  structureInfo?: InputMaybe<SortOrderInput>;
  title?: InputMaybe<SortOrder>;
  underGroundArea?: InputMaybe<SortOrderInput>;
  underGroundFloors?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vatInvoiceId?: InputMaybe<SortOrderInput>;
}

export interface ProjectOrderByWithRelationInput {
  CashReceipt?: InputMaybe<CashReceiptOrderByRelationAggregateInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedOrderByRelationAggregateInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogOrderByRelationAggregateInput>;
  PayTransaction?: InputMaybe<PayTransactionOrderByRelationAggregateInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQOrderByWithRelationInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryOrderByRelationAggregateInput>;
  VatInvoice?: InputMaybe<VatInvoiceOrderByWithRelationInput>;
  aboveGroundArea?: InputMaybe<SortOrderInput>;
  aboveGroundFloors?: InputMaybe<SortOrderInput>;
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  addressId?: InputMaybe<SortOrder>;
  architect?: InputMaybe<SortOrderInput>;
  blueprints?: InputMaybe<BlueprintOrderByRelationAggregateInput>;
  budget?: InputMaybe<SortOrder>;
  buildingArea?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<SortOrderInput>;
  constructionEndDate?: InputMaybe<SortOrderInput>;
  constructionEtc?: InputMaybe<SortOrderInput>;
  constructionOption?: InputMaybe<SortOrderInput>;
  constructionStartDate?: InputMaybe<SortOrderInput>;
  constructionType?: InputMaybe<SortOrder>;
  contact?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  desiredCompletionDate?: InputMaybe<SortOrderInput>;
  estimatedPayment?: InputMaybe<SortOrderInput>;
  estimatedPaymentDate?: InputMaybe<SortOrderInput>;
  floorArea?: InputMaybe<SortOrderInput>;
  forgeUrn?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isFavorite?: InputMaybe<SortOrder>;
  landArea?: InputMaybe<SortOrderInput>;
  maxHeight?: InputMaybe<SortOrderInput>;
  maxUnderGroundArea?: InputMaybe<SortOrderInput>;
  mechanicalParkingArea?: InputMaybe<SortOrderInput>;
  memo?: InputMaybe<SortOrderInput>;
  modelProperty?: InputMaybe<ModelPropertyOrderByWithRelationInput>;
  modelPropertyId?: InputMaybe<SortOrderInput>;
  modelRebarExcepted?: InputMaybe<SortOrder>;
  modelService?: InputMaybe<SortOrder>;
  owner?: InputMaybe<CustomerOrderByWithRelationInput>;
  ownerId?: InputMaybe<SortOrderInput>;
  ownerType?: InputMaybe<SortOrderInput>;
  parkingArea?: InputMaybe<SortOrderInput>;
  paymentImpUid?: InputMaybe<SortOrderInput>;
  processSimulation?: InputMaybe<ProcessSimulationOrderByWithRelationInput>;
  projectAsset?: InputMaybe<ProjectAssetOrderByWithRelationInput>;
  projectAssetId?: InputMaybe<SortOrderInput>;
  projectLevel?: InputMaybe<SortOrderInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneOrderByWithRelationInput>;
  projectMode?: InputMaybe<SortOrderInput>;
  projectType?: InputMaybe<SortOrderInput>;
  rejectedReason?: InputMaybe<SortOrderInput>;
  requirement?: InputMaybe<SortOrderInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkOrderByWithRelationInput>;
  shareRatioWorkId?: InputMaybe<SortOrderInput>;
  sharedProject?: InputMaybe<SharedProjectOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  structure?: InputMaybe<SortOrderInput>;
  structureInfo?: InputMaybe<SortOrderInput>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  title?: InputMaybe<SortOrder>;
  underGroundArea?: InputMaybe<SortOrderInput>;
  underGroundFloors?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
  vatInvoiceId?: InputMaybe<SortOrderInput>;
}

export enum ProjectOwnerType {
  Company = 'COMPANY',
  Etc = 'ETC',
  Individual = 'INDIVIDUAL'
}

export enum ProjectPermission {
  Readable = 'readable',
  Writable = 'writable'
}

export interface ProjectRelationFilter {
  is?: InputMaybe<ProjectWhereInput>;
  isNot?: InputMaybe<ProjectWhereInput>;
}

export enum ProjectScalarFieldEnum {
  AboveGroundArea = 'aboveGroundArea',
  AboveGroundFloors = 'aboveGroundFloors',
  AddressId = 'addressId',
  Architect = 'architect',
  Budget = 'budget',
  BuildingArea = 'buildingArea',
  Client = 'client',
  ConstructionEndDate = 'constructionEndDate',
  ConstructionEtc = 'constructionEtc',
  ConstructionOption = 'constructionOption',
  ConstructionStartDate = 'constructionStartDate',
  ConstructionType = 'constructionType',
  Contact = 'contact',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  DesiredCompletionDate = 'desiredCompletionDate',
  EstimatedPayment = 'estimatedPayment',
  EstimatedPaymentDate = 'estimatedPaymentDate',
  FloorArea = 'floorArea',
  ForgeUrn = 'forgeUrn',
  Id = 'id',
  IsDeleted = 'isDeleted',
  IsFavorite = 'isFavorite',
  LandArea = 'landArea',
  MaxHeight = 'maxHeight',
  MaxUnderGroundArea = 'maxUnderGroundArea',
  MechanicalParkingArea = 'mechanicalParkingArea',
  Memo = 'memo',
  ModelPropertyId = 'modelPropertyId',
  ModelRebarExcepted = 'modelRebarExcepted',
  ModelService = 'modelService',
  OwnerId = 'ownerId',
  OwnerType = 'ownerType',
  ParkingArea = 'parkingArea',
  PaymentImpUid = 'paymentImpUid',
  ProjectAssetId = 'projectAssetId',
  ProjectLevel = 'projectLevel',
  ProjectMode = 'projectMode',
  ProjectType = 'projectType',
  RejectedReason = 'rejectedReason',
  Requirement = 'requirement',
  ShareRatioWorkId = 'shareRatioWorkId',
  Status = 'status',
  Structure = 'structure',
  StructureInfo = 'structureInfo',
  Title = 'title',
  UnderGroundArea = 'underGroundArea',
  UnderGroundFloors = 'underGroundFloors',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid',
  VatInvoiceId = 'vatInvoiceId'
}

export interface ProjectScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectScalarWhereInput>>>;
  aboveGroundArea?: InputMaybe<FloatNullableFilter>;
  aboveGroundFloors?: InputMaybe<IntNullableFilter>;
  addressId?: InputMaybe<IntFilter>;
  architect?: InputMaybe<StringNullableFilter>;
  budget?: InputMaybe<FloatFilter>;
  buildingArea?: InputMaybe<FloatNullableFilter>;
  client?: InputMaybe<StringNullableFilter>;
  constructionEndDate?: InputMaybe<DateTimeNullableFilter>;
  constructionEtc?: InputMaybe<StringNullableFilter>;
  constructionOption?: InputMaybe<StringNullableFilter>;
  constructionStartDate?: InputMaybe<DateTimeNullableFilter>;
  constructionType?: InputMaybe<StringFilter>;
  contact?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  desiredCompletionDate?: InputMaybe<StringNullableFilter>;
  estimatedPayment?: InputMaybe<IntNullableFilter>;
  estimatedPaymentDate?: InputMaybe<DateTimeNullableFilter>;
  floorArea?: InputMaybe<FloatNullableFilter>;
  forgeUrn?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isFavorite?: InputMaybe<BoolFilter>;
  landArea?: InputMaybe<FloatNullableFilter>;
  maxHeight?: InputMaybe<IntNullableFilter>;
  maxUnderGroundArea?: InputMaybe<FloatNullableFilter>;
  mechanicalParkingArea?: InputMaybe<IntNullableFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  modelPropertyId?: InputMaybe<IntNullableFilter>;
  modelRebarExcepted?: InputMaybe<BoolFilter>;
  modelService?: InputMaybe<EnumUploadOptionFilter>;
  ownerId?: InputMaybe<IntNullableFilter>;
  ownerType?: InputMaybe<EnumProjectOwnerTypeNullableFilter>;
  parkingArea?: InputMaybe<IntNullableFilter>;
  paymentImpUid?: InputMaybe<StringNullableFilter>;
  projectAssetId?: InputMaybe<IntNullableFilter>;
  projectLevel?: InputMaybe<EnumProjectLevelNullableFilter>;
  projectMode?: InputMaybe<EnumProjectModeNullableFilter>;
  projectType?: InputMaybe<EnumProjectTypeNullableFilter>;
  rejectedReason?: InputMaybe<StringNullableFilter>;
  requirement?: InputMaybe<StringNullableFilter>;
  shareRatioWorkId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumProjectStatusFilter>;
  structure?: InputMaybe<StringNullableFilter>;
  structureInfo?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  underGroundArea?: InputMaybe<FloatNullableFilter>;
  underGroundFloors?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  vatInvoiceId?: InputMaybe<IntNullableFilter>;
}

export interface ProjectScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectScalarWhereWithAggregatesInput>>>;
  aboveGroundArea?: InputMaybe<FloatNullableWithAggregatesFilter>;
  aboveGroundFloors?: InputMaybe<IntNullableWithAggregatesFilter>;
  addressId?: InputMaybe<IntWithAggregatesFilter>;
  architect?: InputMaybe<StringNullableWithAggregatesFilter>;
  budget?: InputMaybe<FloatWithAggregatesFilter>;
  buildingArea?: InputMaybe<FloatNullableWithAggregatesFilter>;
  client?: InputMaybe<StringNullableWithAggregatesFilter>;
  constructionEndDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  constructionEtc?: InputMaybe<StringNullableWithAggregatesFilter>;
  constructionOption?: InputMaybe<StringNullableWithAggregatesFilter>;
  constructionStartDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  constructionType?: InputMaybe<StringWithAggregatesFilter>;
  contact?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  desiredCompletionDate?: InputMaybe<StringNullableWithAggregatesFilter>;
  estimatedPayment?: InputMaybe<IntNullableWithAggregatesFilter>;
  estimatedPaymentDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  floorArea?: InputMaybe<FloatNullableWithAggregatesFilter>;
  forgeUrn?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  isDeleted?: InputMaybe<BoolWithAggregatesFilter>;
  isFavorite?: InputMaybe<BoolWithAggregatesFilter>;
  landArea?: InputMaybe<FloatNullableWithAggregatesFilter>;
  maxHeight?: InputMaybe<IntNullableWithAggregatesFilter>;
  maxUnderGroundArea?: InputMaybe<FloatNullableWithAggregatesFilter>;
  mechanicalParkingArea?: InputMaybe<IntNullableWithAggregatesFilter>;
  memo?: InputMaybe<StringNullableWithAggregatesFilter>;
  modelPropertyId?: InputMaybe<IntNullableWithAggregatesFilter>;
  modelRebarExcepted?: InputMaybe<BoolWithAggregatesFilter>;
  modelService?: InputMaybe<EnumUploadOptionWithAggregatesFilter>;
  ownerId?: InputMaybe<IntNullableWithAggregatesFilter>;
  ownerType?: InputMaybe<EnumProjectOwnerTypeNullableWithAggregatesFilter>;
  parkingArea?: InputMaybe<IntNullableWithAggregatesFilter>;
  paymentImpUid?: InputMaybe<StringNullableWithAggregatesFilter>;
  projectAssetId?: InputMaybe<IntNullableWithAggregatesFilter>;
  projectLevel?: InputMaybe<EnumProjectLevelNullableWithAggregatesFilter>;
  projectMode?: InputMaybe<EnumProjectModeNullableWithAggregatesFilter>;
  projectType?: InputMaybe<EnumProjectTypeNullableWithAggregatesFilter>;
  rejectedReason?: InputMaybe<StringNullableWithAggregatesFilter>;
  requirement?: InputMaybe<StringNullableWithAggregatesFilter>;
  shareRatioWorkId?: InputMaybe<IntNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumProjectStatusWithAggregatesFilter>;
  structure?: InputMaybe<StringNullableWithAggregatesFilter>;
  structureInfo?: InputMaybe<StringNullableWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  underGroundArea?: InputMaybe<FloatNullableWithAggregatesFilter>;
  underGroundFloors?: InputMaybe<IntNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
  vatInvoiceId?: InputMaybe<IntNullableWithAggregatesFilter>;
}

export enum ProjectStatus {
  CompletedModeling = 'COMPLETED_MODELING',
  CompletedPayment = 'COMPLETED_PAYMENT',
  CompletedQuotation = 'COMPLETED_QUOTATION',
  ConfirmationQuotation = 'CONFIRMATION_QUOTATION',
  InspectionNotPassed = 'INSPECTION_NOT_PASSED',
  PassInspection = 'PASS_INSPECTION',
  RegisterComplete = 'REGISTER_COMPLETE',
  ServiceSelectionComplete = 'SERVICE_SELECTION_COMPLETE',
  UnderInspection = 'UNDER_INSPECTION',
  UploadComplete = 'UPLOAD_COMPLETE',
  WaitingForConfirmPayment = 'WAITING_FOR_CONFIRM_PAYMENT',
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  WorkingOnModeling = 'WORKING_ON_MODELING',
  WorkingOnQuotation = 'WORKING_ON_QUOTATION'
}

export interface ProjectStatusMessages {
  __typename?: 'ProjectStatusMessages';
  message?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

export interface ProjectSumAggregateOutputType {
  __typename?: 'ProjectSumAggregateOutputType';
  aboveGroundArea?: Maybe<Scalars['Float']['output']>;
  aboveGroundFloors?: Maybe<Scalars['Int']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  budget?: Maybe<Scalars['Float']['output']>;
  buildingArea?: Maybe<Scalars['Float']['output']>;
  estimatedPayment?: Maybe<Scalars['Int']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  landArea?: Maybe<Scalars['Float']['output']>;
  maxHeight?: Maybe<Scalars['Int']['output']>;
  maxUnderGroundArea?: Maybe<Scalars['Float']['output']>;
  mechanicalParkingArea?: Maybe<Scalars['Int']['output']>;
  modelPropertyId?: Maybe<Scalars['Int']['output']>;
  ownerId?: Maybe<Scalars['Int']['output']>;
  parkingArea?: Maybe<Scalars['Int']['output']>;
  projectAssetId?: Maybe<Scalars['Int']['output']>;
  shareRatioWorkId?: Maybe<Scalars['Int']['output']>;
  underGroundArea?: Maybe<Scalars['Float']['output']>;
  underGroundFloors?: Maybe<Scalars['Int']['output']>;
  vatInvoiceId?: Maybe<Scalars['Int']['output']>;
}

export interface ProjectSumOrderByAggregateInput {
  aboveGroundArea?: InputMaybe<SortOrder>;
  aboveGroundFloors?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  budget?: InputMaybe<SortOrder>;
  buildingArea?: InputMaybe<SortOrder>;
  estimatedPayment?: InputMaybe<SortOrder>;
  floorArea?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  landArea?: InputMaybe<SortOrder>;
  maxHeight?: InputMaybe<SortOrder>;
  maxUnderGroundArea?: InputMaybe<SortOrder>;
  mechanicalParkingArea?: InputMaybe<SortOrder>;
  modelPropertyId?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  parkingArea?: InputMaybe<SortOrder>;
  projectAssetId?: InputMaybe<SortOrder>;
  shareRatioWorkId?: InputMaybe<SortOrder>;
  underGroundArea?: InputMaybe<SortOrder>;
  underGroundFloors?: InputMaybe<SortOrder>;
  vatInvoiceId?: InputMaybe<SortOrder>;
}

export enum ProjectType {
  Full = 'FULL',
  Viewer = 'VIEWER'
}

export interface ProjectUncheckedCreateInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ProjectCreateManyAddressInputEnvelope>;
}

export interface ProjectUncheckedCreateNestedManyWithoutOwnerInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutOwnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutOwnerInput>>>;
  createMany?: InputMaybe<ProjectCreateManyOwnerInputEnvelope>;
}

export interface ProjectUncheckedCreateNestedManyWithoutShareRatioWorkInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutShareRatioWorkInput>>>;
  createMany?: InputMaybe<ProjectCreateManyShareRatioWorkInputEnvelope>;
}

export interface ProjectUncheckedCreateNestedManyWithoutVatInvoiceInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutVatInvoiceInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutVatInvoiceInput>>>;
  createMany?: InputMaybe<ProjectCreateManyVatInvoiceInputEnvelope>;
}

export interface ProjectUncheckedCreateWithoutAddressInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutBlueprintsInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutCashReceiptInput {
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutInspectionRejectedInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutKakaoSendLogInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutModelPropertyInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutOwnerInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutPayTransactionInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutProcessSimulationInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutProjectAssetInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutProjectBoQInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutProjectHistoryInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutProjectMilestoneInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutShareRatioWorkInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutSharedProjectInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutTasksInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  vatInvoiceId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProjectUncheckedCreateWithoutVatInvoiceInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedCreateNestedManyWithoutProjectInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedCreateNestedManyWithoutProjectInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedCreateNestedManyWithoutProjectInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedCreateNestedManyWithoutProjectInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedCreateNestedOneWithoutProjectInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedCreateNestedManyWithoutProjectInput>;
  aboveGroundArea?: InputMaybe<Scalars['Float']['input']>;
  aboveGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  addressId: Scalars['Int']['input'];
  architect?: InputMaybe<Scalars['String']['input']>;
  blueprints?: InputMaybe<BlueprintUncheckedCreateNestedManyWithoutProjectInput>;
  budget: Scalars['Float']['input'];
  buildingArea?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<Scalars['String']['input']>;
  constructionEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionEtc?: InputMaybe<Scalars['String']['input']>;
  constructionOption?: InputMaybe<Scalars['String']['input']>;
  constructionStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  constructionType: Scalars['String']['input'];
  contact?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredCompletionDate?: InputMaybe<Scalars['String']['input']>;
  estimatedPayment?: InputMaybe<Scalars['Int']['input']>;
  estimatedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  forgeUrn?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  landArea?: InputMaybe<Scalars['Float']['input']>;
  maxHeight?: InputMaybe<Scalars['Int']['input']>;
  maxUnderGroundArea?: InputMaybe<Scalars['Float']['input']>;
  mechanicalParkingArea?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedCreateNestedOneWithoutProjectInput>;
  modelPropertyId?: InputMaybe<Scalars['Int']['input']>;
  modelRebarExcepted?: InputMaybe<Scalars['Boolean']['input']>;
  modelService?: InputMaybe<UploadOption>;
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<ProjectOwnerType>;
  parkingArea?: InputMaybe<Scalars['Int']['input']>;
  paymentImpUid?: InputMaybe<Scalars['String']['input']>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedCreateNestedOneWithoutProjectInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedCreateNestedOneWithoutProjectInput>;
  projectAssetId?: InputMaybe<Scalars['Int']['input']>;
  projectLevel?: InputMaybe<ProjectLevel>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedCreateNestedOneWithoutProjectInput>;
  projectMode?: InputMaybe<ProjectMode>;
  projectType?: InputMaybe<ProjectType>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  requirement?: InputMaybe<Scalars['String']['input']>;
  shareRatioWorkId?: InputMaybe<Scalars['Int']['input']>;
  sharedProject?: InputMaybe<SharedProjectUncheckedCreateNestedManyWithoutProjectInput>;
  status: ProjectStatus;
  structure?: InputMaybe<Scalars['String']['input']>;
  structureInfo?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUncheckedCreateNestedManyWithoutProjectInput>;
  title: Scalars['String']['input'];
  underGroundArea?: InputMaybe<Scalars['Float']['input']>;
  underGroundFloors?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ProjectUncheckedUpdateInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateManyInput {
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ProjectCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface ProjectUncheckedUpdateManyWithoutOwnedProjectInput {
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateManyWithoutOwnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutOwnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutOwnerInput>>>;
  createMany?: InputMaybe<ProjectCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectUpdateWithWhereUniqueWithoutOwnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectUpdateManyWithWhereWithoutOwnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectUpsertWithWhereUniqueWithoutOwnerInput>>>;
}

export interface ProjectUncheckedUpdateManyWithoutProjectInput {
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutShareRatioWorkInput>>>;
  createMany?: InputMaybe<ProjectCreateManyShareRatioWorkInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface ProjectUncheckedUpdateManyWithoutVatInvoiceNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutVatInvoiceInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutVatInvoiceInput>>>;
  createMany?: InputMaybe<ProjectCreateManyVatInvoiceInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectUpdateWithWhereUniqueWithoutVatInvoiceInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectUpdateManyWithWhereWithoutVatInvoiceInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectUpsertWithWhereUniqueWithoutVatInvoiceInput>>>;
}

export interface ProjectUncheckedUpdateWithoutAddressInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutBlueprintsInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutCashReceiptInput {
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutInspectionRejectedInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutKakaoSendLogInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutModelPropertyInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutOwnerInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutPayTransactionInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutProcessSimulationInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutProjectAssetInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutProjectBoQInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutProjectHistoryInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutProjectMilestoneInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutShareRatioWorkInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutSharedProjectInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutTasksInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  vatInvoiceId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
}

export interface ProjectUncheckedUpdateWithoutVatInvoiceInput {
  CashReceipt?: InputMaybe<CashReceiptUncheckedUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUncheckedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUncheckedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUncheckedUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUncheckedUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUncheckedUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  addressId?: InputMaybe<IntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUncheckedUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUncheckedUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUncheckedUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUncheckedUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWorkId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUncheckedUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUncheckedUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateManyMutationInput {
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateManyWithWhereWithoutAddressInput {
  data: ProjectUncheckedUpdateManyWithoutProjectInput;
  where: ProjectScalarWhereInput;
}

export interface ProjectUpdateManyWithWhereWithoutOwnerInput {
  data: ProjectUncheckedUpdateManyWithoutOwnedProjectInput;
  where: ProjectScalarWhereInput;
}

export interface ProjectUpdateManyWithWhereWithoutShareRatioWorkInput {
  data: ProjectUncheckedUpdateManyWithoutProjectInput;
  where: ProjectScalarWhereInput;
}

export interface ProjectUpdateManyWithWhereWithoutVatInvoiceInput {
  data: ProjectUncheckedUpdateManyWithoutProjectInput;
  where: ProjectScalarWhereInput;
}

export interface ProjectUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<ProjectCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface ProjectUpdateManyWithoutOwnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutOwnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutOwnerInput>>>;
  createMany?: InputMaybe<ProjectCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectUpdateWithWhereUniqueWithoutOwnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectUpdateManyWithWhereWithoutOwnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectUpsertWithWhereUniqueWithoutOwnerInput>>>;
}

export interface ProjectUpdateManyWithoutShareRatioWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutShareRatioWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutShareRatioWorkInput>>>;
  createMany?: InputMaybe<ProjectCreateManyShareRatioWorkInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectUpdateWithWhereUniqueWithoutShareRatioWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectUpdateManyWithWhereWithoutShareRatioWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectUpsertWithWhereUniqueWithoutShareRatioWorkInput>>>;
}

export interface ProjectUpdateManyWithoutVatInvoiceNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ProjectCreateOrConnectWithoutVatInvoiceInput>>>;
  create?: InputMaybe<Array<InputMaybe<ProjectCreateWithoutVatInvoiceInput>>>;
  createMany?: InputMaybe<ProjectCreateManyVatInvoiceInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ProjectUpdateWithWhereUniqueWithoutVatInvoiceInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ProjectUpdateManyWithWhereWithoutVatInvoiceInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ProjectUpsertWithWhereUniqueWithoutVatInvoiceInput>>>;
}

export interface ProjectUpdateOneRequiredWithoutBlueprintsNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutBlueprintsInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutBlueprintsInput>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutBlueprintsInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutBlueprintsInput>;
}

export interface ProjectUpdateOneRequiredWithoutCashReceiptNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutCashReceiptInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutCashReceiptInput>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutCashReceiptInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutCashReceiptInput>;
}

export interface ProjectUpdateOneRequiredWithoutInspectionRejectedNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutInspectionRejectedInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutInspectionRejectedInput>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutInspectionRejectedInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutInspectionRejectedInput>;
}

export interface ProjectUpdateOneRequiredWithoutModelPropertyNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutModelPropertyInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutModelPropertyInput>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutModelPropertyInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutModelPropertyInput>;
}

export interface ProjectUpdateOneRequiredWithoutProcessSimulationNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProcessSimulationInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutProcessSimulationInput>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutProcessSimulationInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutProcessSimulationInput>;
}

export interface ProjectUpdateOneRequiredWithoutProjectAssetNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectAssetInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutProjectAssetInput>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutProjectAssetInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutProjectAssetInput>;
}

export interface ProjectUpdateOneRequiredWithoutProjectBoQNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectBoQInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutProjectBoQInput>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutProjectBoQInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutProjectBoQInput>;
}

export interface ProjectUpdateOneRequiredWithoutProjectHistoryNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectHistoryInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutProjectHistoryInput>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutProjectHistoryInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutProjectHistoryInput>;
}

export interface ProjectUpdateOneRequiredWithoutProjectMilestoneNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutProjectMilestoneInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutProjectMilestoneInput>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutProjectMilestoneInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutProjectMilestoneInput>;
}

export interface ProjectUpdateOneRequiredWithoutSharedProjectNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutSharedProjectInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutSharedProjectInput>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutSharedProjectInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutSharedProjectInput>;
}

export interface ProjectUpdateOneRequiredWithoutTasksNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutTasksInput>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutTasksInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutTasksInput>;
}

export interface ProjectUpdateOneWithoutKakaoSendLogNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutKakaoSendLogInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutKakaoSendLogInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutKakaoSendLogInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutKakaoSendLogInput>;
}

export interface ProjectUpdateOneWithoutPayTransactionNestedInput {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutPayTransactionInput>;
  create?: InputMaybe<ProjectUncheckedCreateWithoutPayTransactionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProjectUncheckedUpdateWithoutPayTransactionInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutPayTransactionInput>;
}

export interface ProjectUpdateWithWhereUniqueWithoutAddressInput {
  data: ProjectUncheckedUpdateWithoutAddressInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectUpdateWithWhereUniqueWithoutOwnerInput {
  data: ProjectUncheckedUpdateWithoutOwnerInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectUpdateWithWhereUniqueWithoutShareRatioWorkInput {
  data: ProjectUncheckedUpdateWithoutShareRatioWorkInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectUpdateWithWhereUniqueWithoutVatInvoiceInput {
  data: ProjectUncheckedUpdateWithoutVatInvoiceInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectUpdateWithoutAddressInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutBlueprintsInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutCashReceiptInput {
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutInspectionRejectedInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutKakaoSendLogInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutModelPropertyInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutOwnerInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutPayTransactionInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutProcessSimulationInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutProjectAssetInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutProjectBoQInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutProjectHistoryInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutProjectMilestoneInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutShareRatioWorkInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutSharedProjectInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutTasksInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  VatInvoice?: InputMaybe<VatInvoiceUpdateOneWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpdateWithoutVatInvoiceInput {
  CashReceipt?: InputMaybe<CashReceiptUpdateManyWithoutProjectNestedInput>;
  InspectionRejected?: InputMaybe<InspectionRejectedUpdateManyWithoutProjectNestedInput>;
  KakaoSendLog?: InputMaybe<KakaoSendLogUpdateManyWithoutProjectNestedInput>;
  PayTransaction?: InputMaybe<PayTransactionUpdateManyWithoutProjectNestedInput>;
  ProjectBoQ?: InputMaybe<ProjectBoQUpdateOneWithoutProjectNestedInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryUpdateManyWithoutProjectNestedInput>;
  aboveGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  aboveGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  address?: InputMaybe<AddressUpdateOneRequiredWithoutProjectNestedInput>;
  architect?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  blueprints?: InputMaybe<BlueprintUpdateManyWithoutProjectNestedInput>;
  budget?: InputMaybe<FloatFieldUpdateOperationsInput>;
  buildingArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  client?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionEndDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionEtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionOption?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  constructionStartDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  constructionType?: InputMaybe<StringFieldUpdateOperationsInput>;
  contact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  desiredCompletionDate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  estimatedPayment?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  estimatedPaymentDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  floorArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  forgeUrn?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  landArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  maxHeight?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  maxUnderGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mechanicalParkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelProperty?: InputMaybe<ModelPropertyUpdateOneWithoutProjectNestedInput>;
  modelPropertyId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  modelRebarExcepted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  modelService?: InputMaybe<EnumUploadOptionFieldUpdateOperationsInput>;
  owner?: InputMaybe<CustomerUpdateOneWithoutOwnedProjectNestedInput>;
  ownerType?: InputMaybe<NullableEnumProjectOwnerTypeFieldUpdateOperationsInput>;
  parkingArea?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentImpUid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  processSimulation?: InputMaybe<ProcessSimulationUpdateOneWithoutProjectNestedInput>;
  projectAsset?: InputMaybe<ProjectAssetUpdateOneWithoutProjectNestedInput>;
  projectAssetId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectLevel?: InputMaybe<NullableEnumProjectLevelFieldUpdateOperationsInput>;
  projectMilestone?: InputMaybe<ProjectMilestoneUpdateOneWithoutProjectNestedInput>;
  projectMode?: InputMaybe<NullableEnumProjectModeFieldUpdateOperationsInput>;
  projectType?: InputMaybe<NullableEnumProjectTypeFieldUpdateOperationsInput>;
  rejectedReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  requirement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareRatioWork?: InputMaybe<ShareRatioWorkUpdateOneWithoutProjectNestedInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateManyWithoutProjectNestedInput>;
  status?: InputMaybe<EnumProjectStatusFieldUpdateOperationsInput>;
  structure?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  structureInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutProjectNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  underGroundArea?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  underGroundFloors?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ProjectUpsertWithWhereUniqueWithoutAddressInput {
  create: ProjectUncheckedCreateWithoutAddressInput;
  update: ProjectUncheckedUpdateWithoutAddressInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectUpsertWithWhereUniqueWithoutOwnerInput {
  create: ProjectUncheckedCreateWithoutOwnerInput;
  update: ProjectUncheckedUpdateWithoutOwnerInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectUpsertWithWhereUniqueWithoutShareRatioWorkInput {
  create: ProjectUncheckedCreateWithoutShareRatioWorkInput;
  update: ProjectUncheckedUpdateWithoutShareRatioWorkInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectUpsertWithWhereUniqueWithoutVatInvoiceInput {
  create: ProjectUncheckedCreateWithoutVatInvoiceInput;
  update: ProjectUncheckedUpdateWithoutVatInvoiceInput;
  where: ProjectWhereUniqueInput;
}

export interface ProjectUpsertWithoutBlueprintsInput {
  create: ProjectUncheckedCreateWithoutBlueprintsInput;
  update: ProjectUncheckedUpdateWithoutBlueprintsInput;
}

export interface ProjectUpsertWithoutCashReceiptInput {
  create: ProjectUncheckedCreateWithoutCashReceiptInput;
  update: ProjectUncheckedUpdateWithoutCashReceiptInput;
}

export interface ProjectUpsertWithoutInspectionRejectedInput {
  create: ProjectUncheckedCreateWithoutInspectionRejectedInput;
  update: ProjectUncheckedUpdateWithoutInspectionRejectedInput;
}

export interface ProjectUpsertWithoutKakaoSendLogInput {
  create: ProjectUncheckedCreateWithoutKakaoSendLogInput;
  update: ProjectUncheckedUpdateWithoutKakaoSendLogInput;
}

export interface ProjectUpsertWithoutModelPropertyInput {
  create: ProjectUncheckedCreateWithoutModelPropertyInput;
  update: ProjectUncheckedUpdateWithoutModelPropertyInput;
}

export interface ProjectUpsertWithoutPayTransactionInput {
  create: ProjectUncheckedCreateWithoutPayTransactionInput;
  update: ProjectUncheckedUpdateWithoutPayTransactionInput;
}

export interface ProjectUpsertWithoutProcessSimulationInput {
  create: ProjectUncheckedCreateWithoutProcessSimulationInput;
  update: ProjectUncheckedUpdateWithoutProcessSimulationInput;
}

export interface ProjectUpsertWithoutProjectAssetInput {
  create: ProjectUncheckedCreateWithoutProjectAssetInput;
  update: ProjectUncheckedUpdateWithoutProjectAssetInput;
}

export interface ProjectUpsertWithoutProjectBoQInput {
  create: ProjectUncheckedCreateWithoutProjectBoQInput;
  update: ProjectUncheckedUpdateWithoutProjectBoQInput;
}

export interface ProjectUpsertWithoutProjectHistoryInput {
  create: ProjectUncheckedCreateWithoutProjectHistoryInput;
  update: ProjectUncheckedUpdateWithoutProjectHistoryInput;
}

export interface ProjectUpsertWithoutProjectMilestoneInput {
  create: ProjectUncheckedCreateWithoutProjectMilestoneInput;
  update: ProjectUncheckedUpdateWithoutProjectMilestoneInput;
}

export interface ProjectUpsertWithoutSharedProjectInput {
  create: ProjectUncheckedCreateWithoutSharedProjectInput;
  update: ProjectUncheckedUpdateWithoutSharedProjectInput;
}

export interface ProjectUpsertWithoutTasksInput {
  create: ProjectUncheckedCreateWithoutTasksInput;
  update: ProjectUncheckedUpdateWithoutTasksInput;
}

export interface ProjectWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ProjectWhereInput>>>;
  CashReceipt?: InputMaybe<CashReceiptListRelationFilter>;
  InspectionRejected?: InputMaybe<InspectionRejectedListRelationFilter>;
  KakaoSendLog?: InputMaybe<KakaoSendLogListRelationFilter>;
  NOT?: InputMaybe<Array<InputMaybe<ProjectWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ProjectWhereInput>>>;
  PayTransaction?: InputMaybe<PayTransactionListRelationFilter>;
  ProjectBoQ?: InputMaybe<ProjectBoQWhereInput>;
  ProjectHistory?: InputMaybe<ProjectHistoryListRelationFilter>;
  VatInvoice?: InputMaybe<VatInvoiceWhereInput>;
  aboveGroundArea?: InputMaybe<FloatNullableFilter>;
  aboveGroundFloors?: InputMaybe<IntNullableFilter>;
  address?: InputMaybe<AddressWhereInput>;
  addressId?: InputMaybe<IntFilter>;
  architect?: InputMaybe<StringNullableFilter>;
  blueprints?: InputMaybe<BlueprintListRelationFilter>;
  budget?: InputMaybe<FloatFilter>;
  buildingArea?: InputMaybe<FloatNullableFilter>;
  client?: InputMaybe<StringNullableFilter>;
  constructionEndDate?: InputMaybe<DateTimeNullableFilter>;
  constructionEtc?: InputMaybe<StringNullableFilter>;
  constructionOption?: InputMaybe<StringNullableFilter>;
  constructionStartDate?: InputMaybe<DateTimeNullableFilter>;
  constructionType?: InputMaybe<StringFilter>;
  contact?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  desiredCompletionDate?: InputMaybe<StringNullableFilter>;
  estimatedPayment?: InputMaybe<IntNullableFilter>;
  estimatedPaymentDate?: InputMaybe<DateTimeNullableFilter>;
  floorArea?: InputMaybe<FloatNullableFilter>;
  forgeUrn?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isFavorite?: InputMaybe<BoolFilter>;
  landArea?: InputMaybe<FloatNullableFilter>;
  maxHeight?: InputMaybe<IntNullableFilter>;
  maxUnderGroundArea?: InputMaybe<FloatNullableFilter>;
  mechanicalParkingArea?: InputMaybe<IntNullableFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  modelProperty?: InputMaybe<ModelPropertyWhereInput>;
  modelPropertyId?: InputMaybe<IntNullableFilter>;
  modelRebarExcepted?: InputMaybe<BoolFilter>;
  modelService?: InputMaybe<EnumUploadOptionFilter>;
  owner?: InputMaybe<CustomerWhereInput>;
  ownerId?: InputMaybe<IntNullableFilter>;
  ownerType?: InputMaybe<EnumProjectOwnerTypeNullableFilter>;
  parkingArea?: InputMaybe<IntNullableFilter>;
  paymentImpUid?: InputMaybe<StringNullableFilter>;
  processSimulation?: InputMaybe<ProcessSimulationWhereInput>;
  projectAsset?: InputMaybe<ProjectAssetWhereInput>;
  projectAssetId?: InputMaybe<IntNullableFilter>;
  projectLevel?: InputMaybe<EnumProjectLevelNullableFilter>;
  projectMilestone?: InputMaybe<ProjectMilestoneWhereInput>;
  projectMode?: InputMaybe<EnumProjectModeNullableFilter>;
  projectType?: InputMaybe<EnumProjectTypeNullableFilter>;
  rejectedReason?: InputMaybe<StringNullableFilter>;
  requirement?: InputMaybe<StringNullableFilter>;
  shareRatioWork?: InputMaybe<ShareRatioWorkWhereInput>;
  shareRatioWorkId?: InputMaybe<IntNullableFilter>;
  sharedProject?: InputMaybe<SharedProjectListRelationFilter>;
  status?: InputMaybe<EnumProjectStatusFilter>;
  structure?: InputMaybe<StringNullableFilter>;
  structureInfo?: InputMaybe<StringNullableFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  underGroundArea?: InputMaybe<FloatNullableFilter>;
  underGroundFloors?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
  vatInvoiceId?: InputMaybe<IntNullableFilter>;
}

export interface ProjectWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface Query {
  __typename?: 'Query';
  aggregateAccessLog?: Maybe<AggregateAccessLog>;
  aggregateAddress?: Maybe<AggregateAddress>;
  aggregateAdmin?: Maybe<AggregateAdmin>;
  aggregateAdminSchema?: Maybe<AggregateAdminSchema>;
  aggregateArchitect?: Maybe<AggregateArchitect>;
  aggregateArchitectureSRW?: Maybe<AggregateArchitectureSrw>;
  aggregateBOQUpdate?: Maybe<AggregateBoqUpdate>;
  aggregateBlueprint?: Maybe<AggregateBlueprint>;
  aggregateBlueprintComment?: Maybe<AggregateBlueprintComment>;
  aggregateBoQItem?: Maybe<AggregateBoQItem>;
  aggregateCashReceipt?: Maybe<AggregateCashReceipt>;
  aggregateCivilWorkSRW?: Maybe<AggregateCivilWorkSrw>;
  aggregateCommunicationSRW?: Maybe<AggregateCommunicationSrw>;
  aggregateConstruction?: Maybe<AggregateConstruction>;
  aggregateCustomer?: Maybe<AggregateCustomer>;
  aggregateElectricalSRW?: Maybe<AggregateElectricalSrw>;
  aggregateEventLogs?: Maybe<AggregateEventLogs>;
  aggregateEventResult?: Maybe<AggregateEventResult>;
  aggregateFindProjectHistory?: Maybe<AggregateProjectHistory>;
  aggregateFireProtectionSRW?: Maybe<AggregateFireProtectionSrw>;
  aggregateGeneralConstruction?: Maybe<AggregateGeneralConstruction>;
  aggregateIdentity?: Maybe<AggregateIdentity>;
  aggregateInquiry?: Maybe<AggregateInquiry>;
  aggregateInspectionRejected?: Maybe<AggregateInspectionRejected>;
  aggregateKakaoMessageTemplate?: Maybe<AggregateKakaoMessageTemplate>;
  aggregateKakaoSendLog?: Maybe<AggregateKakaoSendLog>;
  aggregateLandscapingSRW?: Maybe<AggregateLandscapingSrw>;
  aggregateMaterialPrice?: Maybe<AggregateMaterialPrice>;
  aggregateMechanicalSRW?: Maybe<AggregateMechanicalSrw>;
  aggregateMember?: Maybe<AggregateMember>;
  aggregateMemberEvent?: Maybe<AggregateMemberEvent>;
  aggregateMilestoneWorkItem?: Maybe<AggregateMilestoneWorkItem>;
  aggregateModelProperty?: Maybe<AggregateModelProperty>;
  aggregateModeler?: Maybe<AggregateModeler>;
  aggregateMyInquiry?: Maybe<AggregateInquiry>;
  aggregateMyPartner?: Maybe<AggregatePartner>;
  aggregateMyProject?: Maybe<AggregateProject>;
  aggregateNotice?: Maybe<AggregateNotice>;
  aggregateNoticeComment?: Maybe<AggregateNoticeComment>;
  aggregateNoticeTopic?: Maybe<AggregateNoticeTopic>;
  aggregatePartner?: Maybe<AggregatePartner>;
  aggregatePayTransaction?: Maybe<AggregatePayTransaction>;
  aggregatePersonnel?: Maybe<AggregatePersonnel>;
  aggregateProcessSimulation?: Maybe<AggregateProcessSimulation>;
  aggregateProcessTaskCell?: Maybe<AggregateProcessTaskCell>;
  aggregateProfile?: Maybe<AggregateProfile>;
  aggregateProject?: Maybe<AggregateProject>;
  aggregateProjectAsset?: Maybe<AggregateProjectAsset>;
  aggregateProjectBoQ?: Maybe<AggregateProjectBoQ>;
  aggregateProjectBoQFiles?: Maybe<AggregateProjectBoQFiles>;
  aggregateProjectFloorInfo?: Maybe<AggregateProjectFloorInfo>;
  aggregateProjectHistory?: Maybe<AggregateProjectHistory>;
  aggregateProjectMilestone?: Maybe<AggregateProjectMilestone>;
  aggregateReply?: Maybe<AggregateReply>;
  aggregateShareMember?: Maybe<AggregateShareProjectByMember>;
  aggregateShareProjectByMember?: Maybe<AggregateShareProjectByMember>;
  aggregateShareRatioWork?: Maybe<AggregateShareRatioWork>;
  aggregateSharedMember?: Maybe<AggregateSharedProjectByMember>;
  aggregateSharedProject?: Maybe<AggregateSharedProject>;
  aggregateSharedProjectByMember?: Maybe<AggregateSharedProjectByMember>;
  aggregateSharedProjectToken?: Maybe<AggregateSharedProjectToken>;
  aggregateSpaceDesign?: Maybe<AggregateSpaceDesign>;
  aggregateStandardBoQ?: Maybe<AggregateStandardBoQ>;
  aggregateStandardBoQFiles?: Maybe<AggregateStandardBoQFiles>;
  aggregateSupplier?: Maybe<AggregateSupplier>;
  aggregateTask?: Maybe<AggregateTask>;
  aggregateVatInvoice?: Maybe<AggregateVatInvoice>;
  aggregateWithdrawReason?: Maybe<AggregateWithdrawReason>;
  apiTest?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Moved to AWS Lambda `bhpflGetIdentity` */
  certification?: Maybe<Certification>;
  findAllManyArchitect: Array<Architect>;
  findAllManyConstruction: Array<Construction>;
  findAllManyGeneralConstruction: Array<GeneralConstruction>;
  findAllManyModeler: Array<Modeler>;
  findAllManySpaceDesign: Array<SpaceDesign>;
  findAllManySupplier: Array<Supplier>;
  findExistMember?: Maybe<Member>;
  findFirstAccessLog?: Maybe<AccessLog>;
  findFirstAddress?: Maybe<Address>;
  findFirstAdmin?: Maybe<Admin>;
  findFirstAdminSchema?: Maybe<AdminSchema>;
  findFirstArchitect?: Maybe<Architect>;
  findFirstArchitectureSRW?: Maybe<ArchitectureSrw>;
  findFirstBOQUpdate?: Maybe<BoqUpdate>;
  findFirstBlueprint?: Maybe<Blueprint>;
  findFirstBlueprintComment?: Maybe<BlueprintComment>;
  findFirstBoQItem?: Maybe<BoQItem>;
  findFirstCashReceipt?: Maybe<CashReceipt>;
  findFirstCivilWorkSRW?: Maybe<CivilWorkSrw>;
  findFirstCommunicationSRW?: Maybe<CommunicationSrw>;
  findFirstConstruction?: Maybe<Construction>;
  findFirstCustomer?: Maybe<Customer>;
  findFirstElectricalSRW?: Maybe<ElectricalSrw>;
  findFirstEventLogs?: Maybe<EventLogs>;
  findFirstEventResult?: Maybe<EventResult>;
  findFirstFireProtectionSRW?: Maybe<FireProtectionSrw>;
  findFirstGeneralConstruction?: Maybe<GeneralConstruction>;
  findFirstIdentity?: Maybe<Identity>;
  findFirstInquiry?: Maybe<Inquiry>;
  findFirstInspectionRejected?: Maybe<InspectionRejected>;
  findFirstKakaoMessageTemplate?: Maybe<KakaoMessageTemplate>;
  findFirstKakaoSendLog?: Maybe<KakaoSendLog>;
  findFirstLandscapingSRW?: Maybe<LandscapingSrw>;
  findFirstMaterialPrice?: Maybe<MaterialPrice>;
  findFirstMechanicalSRW?: Maybe<MechanicalSrw>;
  findFirstMember?: Maybe<Member>;
  findFirstMemberEvent?: Maybe<MemberEvent>;
  findFirstMilestoneWorkItem?: Maybe<MilestoneWorkItem>;
  findFirstModelProperty?: Maybe<ModelProperty>;
  findFirstModeler?: Maybe<Modeler>;
  findFirstNotice?: Maybe<Notice>;
  findFirstNoticeComment?: Maybe<NoticeComment>;
  findFirstNoticeTopic?: Maybe<NoticeTopic>;
  findFirstPartner?: Maybe<Partner>;
  findFirstPayTransaction?: Maybe<PayTransaction>;
  findFirstPayTransactionAPIOnly?: Maybe<PayTransaction>;
  findFirstPersonnel?: Maybe<Personnel>;
  findFirstProcessSimulation?: Maybe<ProcessSimulation>;
  findFirstProcessTaskCell?: Maybe<ProcessTaskCell>;
  findFirstProfile?: Maybe<Profile>;
  findFirstProject?: Maybe<Project>;
  findFirstProjectAPIOnly?: Maybe<Project>;
  findFirstProjectAsset?: Maybe<ProjectAsset>;
  findFirstProjectBoQ?: Maybe<ProjectBoQ>;
  findFirstProjectBoQFiles?: Maybe<ProjectBoQFiles>;
  findFirstProjectFloorInfo?: Maybe<ProjectFloorInfo>;
  findFirstProjectHistory?: Maybe<ProjectHistory>;
  findFirstProjectMilestone?: Maybe<ProjectMilestone>;
  findFirstReply?: Maybe<Reply>;
  findFirstShareProjectByMember?: Maybe<ShareProjectByMember>;
  findFirstShareRatioWork?: Maybe<ShareRatioWork>;
  findFirstSharedProject?: Maybe<SharedProject>;
  findFirstSharedProjectByMember?: Maybe<SharedProjectByMember>;
  findFirstSharedProjectToken?: Maybe<SharedProjectToken>;
  findFirstSpaceDesign?: Maybe<SpaceDesign>;
  findFirstStandardBoQ?: Maybe<StandardBoQ>;
  findFirstStandardBoQFiles?: Maybe<StandardBoQFiles>;
  findFirstSupplier?: Maybe<Supplier>;
  findFirstTask?: Maybe<Task>;
  findFirstVatInvoice?: Maybe<VatInvoice>;
  findFirstWithdrawReason?: Maybe<WithdrawReason>;
  findManyAccessLog: Array<AccessLog>;
  findManyAccessLogCount: Scalars['Int']['output'];
  findManyAddress: Array<Address>;
  findManyAddressCount: Scalars['Int']['output'];
  findManyAdmin: Array<Admin>;
  findManyAdminCount: Scalars['Int']['output'];
  findManyAdminSchema: Array<AdminSchema>;
  findManyAdminSchemaCount: Scalars['Int']['output'];
  findManyArchitect: Array<Architect>;
  findManyArchitectCount: Scalars['Int']['output'];
  findManyArchitectureSRW: Array<ArchitectureSrw>;
  findManyArchitectureSRWCount: Scalars['Int']['output'];
  findManyBOQUpdate: Array<BoqUpdate>;
  findManyBOQUpdateCount: Scalars['Int']['output'];
  findManyBlueprint: Array<Blueprint>;
  findManyBlueprintComment: Array<BlueprintComment>;
  findManyBlueprintCommentCount: Scalars['Int']['output'];
  findManyBlueprintCount: Scalars['Int']['output'];
  findManyBoQItem: Array<BoQItem>;
  findManyBoQItemCount: Scalars['Int']['output'];
  findManyCashReceipt: Array<CashReceipt>;
  findManyCashReceiptCount: Scalars['Int']['output'];
  findManyCivilWorkSRW: Array<CivilWorkSrw>;
  findManyCivilWorkSRWCount: Scalars['Int']['output'];
  findManyCommunicationSRW: Array<CommunicationSrw>;
  findManyCommunicationSRWCount: Scalars['Int']['output'];
  findManyConstruction: Array<Construction>;
  findManyConstructionCount: Scalars['Int']['output'];
  findManyCustomer: Array<Customer>;
  findManyCustomerCount: Scalars['Int']['output'];
  findManyElectricalSRW: Array<ElectricalSrw>;
  findManyElectricalSRWCount: Scalars['Int']['output'];
  findManyEventLogs: Array<EventLogs>;
  findManyEventLogsCount: Scalars['Int']['output'];
  findManyEventResult: Array<EventResult>;
  findManyEventResultCount: Scalars['Int']['output'];
  findManyFireProtectionSRW: Array<FireProtectionSrw>;
  findManyFireProtectionSRWCount: Scalars['Int']['output'];
  findManyGeneralConstruction: Array<GeneralConstruction>;
  findManyGeneralConstructionCount: Scalars['Int']['output'];
  findManyIdentity: Array<Identity>;
  findManyIdentityCount: Scalars['Int']['output'];
  findManyInquiry: Array<Inquiry>;
  findManyInquiryCount: Scalars['Int']['output'];
  findManyInspectionRejected: Array<InspectionRejected>;
  findManyInspectionRejectedCount: Scalars['Int']['output'];
  findManyKakaoMessageTemplate: Array<KakaoMessageTemplate>;
  findManyKakaoMessageTemplateCount: Scalars['Int']['output'];
  findManyKakaoSendLog: Array<KakaoSendLog>;
  findManyKakaoSendLogCount: Scalars['Int']['output'];
  findManyLandscapingSRW: Array<LandscapingSrw>;
  findManyLandscapingSRWCount: Scalars['Int']['output'];
  findManyMaterialPrice: Array<MaterialPrice>;
  findManyMaterialPriceCount: Scalars['Int']['output'];
  findManyMechanicalSRW: Array<MechanicalSrw>;
  findManyMechanicalSRWCount: Scalars['Int']['output'];
  findManyMember: Array<Member>;
  findManyMemberCount: Scalars['Int']['output'];
  findManyMemberEvent: Array<MemberEvent>;
  findManyMemberEventCount: Scalars['Int']['output'];
  findManyMilestoneWorkItem: Array<MilestoneWorkItem>;
  findManyMilestoneWorkItemCount: Scalars['Int']['output'];
  findManyModelProperty: Array<ModelProperty>;
  findManyModelPropertyCount: Scalars['Int']['output'];
  findManyModeler: Array<Modeler>;
  findManyModelerCount: Scalars['Int']['output'];
  findManyNotice: Array<Notice>;
  findManyNoticeComment: Array<NoticeComment>;
  findManyNoticeCommentCount: Scalars['Int']['output'];
  findManyNoticeCount: Scalars['Int']['output'];
  findManyNoticeTopic: Array<NoticeTopic>;
  findManyNoticeTopicCount: Scalars['Int']['output'];
  findManyPartner: Array<Partner>;
  findManyPartnerCount: Scalars['Int']['output'];
  findManyPayTransaction: Array<PayTransaction>;
  findManyPayTransactionCount: Scalars['Int']['output'];
  findManyPersonnel: Array<Personnel>;
  findManyPersonnelCount: Scalars['Int']['output'];
  findManyProcessSimulation: Array<ProcessSimulation>;
  findManyProcessSimulationCount: Scalars['Int']['output'];
  findManyProcessTaskCell: Array<ProcessTaskCell>;
  findManyProcessTaskCellCount: Scalars['Int']['output'];
  findManyProfile: Array<Profile>;
  findManyProfileCount: Scalars['Int']['output'];
  findManyProject: Array<Project>;
  findManyProjectAsset: Array<ProjectAsset>;
  findManyProjectAssetCount: Scalars['Int']['output'];
  findManyProjectBoQ: Array<ProjectBoQ>;
  findManyProjectBoQCount: Scalars['Int']['output'];
  findManyProjectBoQFiles: Array<ProjectBoQFiles>;
  findManyProjectBoQFilesCount: Scalars['Int']['output'];
  findManyProjectCount: Scalars['Int']['output'];
  findManyProjectFloorInfo: Array<ProjectFloorInfo>;
  findManyProjectFloorInfoCount: Scalars['Int']['output'];
  findManyProjectHistory: Array<ProjectHistory>;
  findManyProjectHistoryCount: Scalars['Int']['output'];
  findManyProjectMilestone: Array<ProjectMilestone>;
  findManyProjectMilestoneCount: Scalars['Int']['output'];
  findManyReply: Array<Reply>;
  findManyReplyCount: Scalars['Int']['output'];
  findManyShareProjectByMember: Array<ShareProjectByMember>;
  findManyShareProjectByMemberCount: Scalars['Int']['output'];
  findManyShareRatioWork: Array<ShareRatioWork>;
  findManyShareRatioWorkCount: Scalars['Int']['output'];
  findManySharedProject: Array<SharedProject>;
  findManySharedProjectByMember: Array<SharedProjectByMember>;
  findManySharedProjectByMemberCount: Scalars['Int']['output'];
  findManySharedProjectCount: Scalars['Int']['output'];
  findManySharedProjectToken: Array<SharedProjectToken>;
  findManySharedProjectTokenCount: Scalars['Int']['output'];
  findManySpaceDesign: Array<SpaceDesign>;
  findManySpaceDesignCount: Scalars['Int']['output'];
  findManyStandardBoQ: Array<StandardBoQ>;
  findManyStandardBoQCount: Scalars['Int']['output'];
  findManyStandardBoQFiles: Array<StandardBoQFiles>;
  findManyStandardBoQFilesCount: Scalars['Int']['output'];
  findManySupplier: Array<Supplier>;
  findManySupplierCount: Scalars['Int']['output'];
  findManyTask: Array<Task>;
  findManyTaskCount: Scalars['Int']['output'];
  findManyVatInvoice: Array<VatInvoice>;
  findManyVatInvoiceCount: Scalars['Int']['output'];
  findManyWithdrawReason: Array<WithdrawReason>;
  findManyWithdrawReasonCount: Scalars['Int']['output'];
  findMyInquiryFileQuery?: Maybe<Scalars['String']['output']>;
  findMyManyInquiry: Array<Inquiry>;
  findMyManyPartner: Array<Partner>;
  findMyManyProject: Array<Project>;
  findMyOneInquiryQuery?: Maybe<Inquiry>;
  findMyOneProject?: Maybe<Project>;
  findProjectHistory: Array<ProjectHistory>;
  findProjectShareInfo?: Maybe<Project>;
  findUniqueAccessLog?: Maybe<AccessLog>;
  findUniqueAddress?: Maybe<Address>;
  findUniqueAdmin?: Maybe<Admin>;
  findUniqueAdminSchema?: Maybe<AdminSchema>;
  findUniqueArchitect?: Maybe<Architect>;
  findUniqueArchitectureSRW?: Maybe<ArchitectureSrw>;
  findUniqueBOQUpdate?: Maybe<BoqUpdate>;
  findUniqueBlueprint?: Maybe<Blueprint>;
  findUniqueBlueprintComment?: Maybe<BlueprintComment>;
  findUniqueBoQItem?: Maybe<BoQItem>;
  findUniqueCashReceipt?: Maybe<CashReceipt>;
  findUniqueCivilWorkSRW?: Maybe<CivilWorkSrw>;
  findUniqueCommunicationSRW?: Maybe<CommunicationSrw>;
  findUniqueConstruction?: Maybe<Construction>;
  findUniqueCustomer?: Maybe<Customer>;
  findUniqueElectricalSRW?: Maybe<ElectricalSrw>;
  findUniqueEventLogs?: Maybe<EventLogs>;
  findUniqueEventResult?: Maybe<EventResult>;
  findUniqueFireProtectionSRW?: Maybe<FireProtectionSrw>;
  findUniqueGeneralConstruction?: Maybe<GeneralConstruction>;
  findUniqueIdentity?: Maybe<Identity>;
  findUniqueInquiry?: Maybe<Inquiry>;
  findUniqueInspectionRejected?: Maybe<InspectionRejected>;
  findUniqueKakaoMessageTemplate?: Maybe<KakaoMessageTemplate>;
  findUniqueKakaoSendLog?: Maybe<KakaoSendLog>;
  findUniqueLandscapingSRW?: Maybe<LandscapingSrw>;
  findUniqueMaterialPrice?: Maybe<MaterialPrice>;
  findUniqueMechanicalSRW?: Maybe<MechanicalSrw>;
  findUniqueMember?: Maybe<Member>;
  findUniqueMemberEvent?: Maybe<MemberEvent>;
  findUniqueMilestoneWorkItem?: Maybe<MilestoneWorkItem>;
  findUniqueModelProperty?: Maybe<ModelProperty>;
  findUniqueModeler?: Maybe<Modeler>;
  findUniqueNotice?: Maybe<Notice>;
  findUniqueNoticeComment?: Maybe<NoticeComment>;
  findUniqueNoticeTopic?: Maybe<NoticeTopic>;
  findUniquePartner?: Maybe<Partner>;
  findUniquePayTransaction?: Maybe<PayTransaction>;
  findUniquePayTransactionAPIOnly?: Maybe<PayTransaction>;
  findUniquePersonnel?: Maybe<Personnel>;
  findUniqueProcessSimulation?: Maybe<ProcessSimulation>;
  findUniqueProcessTaskCell?: Maybe<ProcessTaskCell>;
  findUniqueProfile?: Maybe<Profile>;
  findUniqueProject?: Maybe<Project>;
  findUniqueProjectAsset?: Maybe<ProjectAsset>;
  findUniqueProjectBoQ?: Maybe<ProjectBoQ>;
  findUniqueProjectBoQFiles?: Maybe<ProjectBoQFiles>;
  findUniqueProjectFloorInfo?: Maybe<ProjectFloorInfo>;
  findUniqueProjectHistory?: Maybe<ProjectHistory>;
  findUniqueProjectMilestone?: Maybe<ProjectMilestone>;
  findUniqueReply?: Maybe<Reply>;
  findUniqueShareProjectByMember?: Maybe<ShareProjectByMember>;
  findUniqueShareRatioWork?: Maybe<ShareRatioWork>;
  findUniqueSharedProject?: Maybe<SharedProject>;
  findUniqueSharedProjectByMember?: Maybe<SharedProjectByMember>;
  findUniqueSharedProjectToken?: Maybe<SharedProjectToken>;
  findUniqueSpaceDesign?: Maybe<SpaceDesign>;
  findUniqueStandardBoQ?: Maybe<StandardBoQ>;
  findUniqueStandardBoQFiles?: Maybe<StandardBoQFiles>;
  findUniqueSupplier?: Maybe<Supplier>;
  findUniqueTask?: Maybe<Task>;
  findUniqueVatInvoice?: Maybe<VatInvoice>;
  findUniqueWithdrawReason?: Maybe<WithdrawReason>;
  /** Autodesk Forge API 관련 Queries 모음 */
  forge?: Maybe<ForgeQueries>;
  /** Get cost ratio */
  getCostRatio?: Maybe<GetCostRatioResult>;
  getMe?: Maybe<Member>;
  getSchema: Schema;
  /** get share member */
  getShareMember?: Maybe<Array<Maybe<ShareProjectByMember>>>;
  /** get shared member */
  getSharedMember?: Maybe<Array<Maybe<SharedProjectByMember>>>;
  /** Get Project ID by token */
  getSharedProjectByToken?: Maybe<SharedProjectTokenType>;
  /** Member endpoint */
  me?: Maybe<Member>;
  /** Query OpenSearch */
  opensearchQuery?: Maybe<Array<Maybe<Result>>>;
  /** Send Project Status Alarm Message */
  projectStatus?: Maybe<ProjectStatusMessages>;
  resolveOriginalFileName?: Maybe<Scalars['String']['output']>;
}


export type QueryAggregateAccessLogArgs = {
  cursor?: InputMaybe<AccessLogWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<AccessLogOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccessLogWhereInput>;
};


export type QueryAggregateAddressArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<AddressOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryAggregateAdminArgs = {
  cursor?: InputMaybe<AdminWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<AdminOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminWhereInput>;
};


export type QueryAggregateAdminSchemaArgs = {
  cursor?: InputMaybe<AdminSchemaWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<AdminSchemaOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminSchemaWhereInput>;
};


export type QueryAggregateArchitectArgs = {
  cursor?: InputMaybe<ArchitectWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ArchitectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectWhereInput>;
};


export type QueryAggregateArchitectureSrwArgs = {
  cursor?: InputMaybe<ArchitectureSrwWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ArchitectureSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectureSrwWhereInput>;
};


export type QueryAggregateBoqUpdateArgs = {
  cursor?: InputMaybe<BoqUpdateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<BoqUpdateOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoqUpdateWhereInput>;
};


export type QueryAggregateBlueprintArgs = {
  cursor?: InputMaybe<BlueprintWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<BlueprintOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlueprintWhereInput>;
};


export type QueryAggregateBlueprintCommentArgs = {
  cursor?: InputMaybe<BlueprintCommentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<BlueprintCommentOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlueprintCommentWhereInput>;
};


export type QueryAggregateBoQItemArgs = {
  cursor?: InputMaybe<BoQItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<BoQItemOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoQItemWhereInput>;
};


export type QueryAggregateCashReceiptArgs = {
  cursor?: InputMaybe<CashReceiptWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CashReceiptOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CashReceiptWhereInput>;
};


export type QueryAggregateCivilWorkSrwArgs = {
  cursor?: InputMaybe<CivilWorkSrwWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CivilWorkSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CivilWorkSrwWhereInput>;
};


export type QueryAggregateCommunicationSrwArgs = {
  cursor?: InputMaybe<CommunicationSrwWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CommunicationSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommunicationSrwWhereInput>;
};


export type QueryAggregateConstructionArgs = {
  cursor?: InputMaybe<ConstructionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ConstructionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ConstructionWhereInput>;
};


export type QueryAggregateCustomerArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CustomerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryAggregateElectricalSrwArgs = {
  cursor?: InputMaybe<ElectricalSrwWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ElectricalSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ElectricalSrwWhereInput>;
};


export type QueryAggregateEventLogsArgs = {
  cursor?: InputMaybe<EventLogsWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<EventLogsOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventLogsWhereInput>;
};


export type QueryAggregateEventResultArgs = {
  cursor?: InputMaybe<EventResultWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<EventResultOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventResultWhereInput>;
};


export type QueryAggregateFindProjectHistoryArgs = {
  cursor?: InputMaybe<ProjectHistoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectHistoryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectHistoryWhereInput>;
};


export type QueryAggregateFireProtectionSrwArgs = {
  cursor?: InputMaybe<FireProtectionSrwWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<FireProtectionSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FireProtectionSrwWhereInput>;
};


export type QueryAggregateGeneralConstructionArgs = {
  cursor?: InputMaybe<GeneralConstructionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<GeneralConstructionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GeneralConstructionWhereInput>;
};


export type QueryAggregateIdentityArgs = {
  cursor?: InputMaybe<IdentityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<IdentityOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IdentityWhereInput>;
};


export type QueryAggregateInquiryArgs = {
  cursor?: InputMaybe<InquiryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryAggregateInspectionRejectedArgs = {
  cursor?: InputMaybe<InspectionRejectedWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<InspectionRejectedOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InspectionRejectedWhereInput>;
};


export type QueryAggregateKakaoMessageTemplateArgs = {
  cursor?: InputMaybe<KakaoMessageTemplateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<KakaoMessageTemplateOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KakaoMessageTemplateWhereInput>;
};


export type QueryAggregateKakaoSendLogArgs = {
  cursor?: InputMaybe<KakaoSendLogWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<KakaoSendLogOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KakaoSendLogWhereInput>;
};


export type QueryAggregateLandscapingSrwArgs = {
  cursor?: InputMaybe<LandscapingSrwWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<LandscapingSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LandscapingSrwWhereInput>;
};


export type QueryAggregateMaterialPriceArgs = {
  cursor?: InputMaybe<MaterialPriceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<MaterialPriceOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MaterialPriceWhereInput>;
};


export type QueryAggregateMechanicalSrwArgs = {
  cursor?: InputMaybe<MechanicalSrwWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<MechanicalSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MechanicalSrwWhereInput>;
};


export type QueryAggregateMemberArgs = {
  cursor?: InputMaybe<MemberWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<MemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberWhereInput>;
};


export type QueryAggregateMemberEventArgs = {
  cursor?: InputMaybe<MemberEventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<MemberEventOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberEventWhereInput>;
};


export type QueryAggregateMilestoneWorkItemArgs = {
  cursor?: InputMaybe<MilestoneWorkItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<MilestoneWorkItemOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MilestoneWorkItemWhereInput>;
};


export type QueryAggregateModelPropertyArgs = {
  cursor?: InputMaybe<ModelPropertyWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ModelPropertyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelPropertyWhereInput>;
};


export type QueryAggregateModelerArgs = {
  cursor?: InputMaybe<ModelerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ModelerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelerWhereInput>;
};


export type QueryAggregateMyInquiryArgs = {
  cursor?: InputMaybe<InquiryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryAggregateMyPartnerArgs = {
  cursor?: InputMaybe<PartnerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<PartnerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnerWhereInput>;
};


export type QueryAggregateMyProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryAggregateNoticeArgs = {
  cursor?: InputMaybe<NoticeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeWhereInput>;
};


export type QueryAggregateNoticeCommentArgs = {
  cursor?: InputMaybe<NoticeCommentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeCommentOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeCommentWhereInput>;
};


export type QueryAggregateNoticeTopicArgs = {
  cursor?: InputMaybe<NoticeTopicWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeTopicOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeTopicWhereInput>;
};


export type QueryAggregatePartnerArgs = {
  cursor?: InputMaybe<PartnerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<PartnerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnerWhereInput>;
};


export type QueryAggregatePayTransactionArgs = {
  cursor?: InputMaybe<PayTransactionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<PayTransactionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PayTransactionWhereInput>;
};


export type QueryAggregatePersonnelArgs = {
  cursor?: InputMaybe<PersonnelWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<PersonnelOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PersonnelWhereInput>;
};


export type QueryAggregateProcessSimulationArgs = {
  cursor?: InputMaybe<ProcessSimulationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProcessSimulationOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProcessSimulationWhereInput>;
};


export type QueryAggregateProcessTaskCellArgs = {
  cursor?: InputMaybe<ProcessTaskCellWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProcessTaskCellOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProcessTaskCellWhereInput>;
};


export type QueryAggregateProfileArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProfileOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};


export type QueryAggregateProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryAggregateProjectAssetArgs = {
  cursor?: InputMaybe<ProjectAssetWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectAssetOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectAssetWhereInput>;
};


export type QueryAggregateProjectBoQArgs = {
  cursor?: InputMaybe<ProjectBoQWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectBoQOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectBoQWhereInput>;
};


export type QueryAggregateProjectBoQFilesArgs = {
  cursor?: InputMaybe<ProjectBoQFilesWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectBoQFilesOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectBoQFilesWhereInput>;
};


export type QueryAggregateProjectFloorInfoArgs = {
  cursor?: InputMaybe<ProjectFloorInfoWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectFloorInfoOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectFloorInfoWhereInput>;
};


export type QueryAggregateProjectHistoryArgs = {
  cursor?: InputMaybe<ProjectHistoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectHistoryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectHistoryWhereInput>;
};


export type QueryAggregateProjectMilestoneArgs = {
  cursor?: InputMaybe<ProjectMilestoneWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectMilestoneOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectMilestoneWhereInput>;
};


export type QueryAggregateReplyArgs = {
  cursor?: InputMaybe<ReplyWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ReplyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReplyWhereInput>;
};


export type QueryAggregateShareMemberArgs = {
  cursor?: InputMaybe<ShareProjectByMemberWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ShareProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareProjectByMemberWhereInput>;
};


export type QueryAggregateShareProjectByMemberArgs = {
  cursor?: InputMaybe<ShareProjectByMemberWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ShareProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareProjectByMemberWhereInput>;
};


export type QueryAggregateShareRatioWorkArgs = {
  cursor?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ShareRatioWorkOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};


export type QueryAggregateSharedMemberArgs = {
  cursor?: InputMaybe<SharedProjectByMemberWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectByMemberWhereInput>;
};


export type QueryAggregateSharedProjectArgs = {
  cursor?: InputMaybe<SharedProjectWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectWhereInput>;
};


export type QueryAggregateSharedProjectByMemberArgs = {
  cursor?: InputMaybe<SharedProjectByMemberWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectByMemberWhereInput>;
};


export type QueryAggregateSharedProjectTokenArgs = {
  cursor?: InputMaybe<SharedProjectTokenWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectTokenOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectTokenWhereInput>;
};


export type QueryAggregateSpaceDesignArgs = {
  cursor?: InputMaybe<SpaceDesignWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<SpaceDesignOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpaceDesignWhereInput>;
};


export type QueryAggregateStandardBoQArgs = {
  cursor?: InputMaybe<StandardBoQWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<StandardBoQOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardBoQWhereInput>;
};


export type QueryAggregateStandardBoQFilesArgs = {
  cursor?: InputMaybe<StandardBoQFilesWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<StandardBoQFilesOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardBoQFilesWhereInput>;
};


export type QueryAggregateSupplierArgs = {
  cursor?: InputMaybe<SupplierWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<SupplierOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SupplierWhereInput>;
};


export type QueryAggregateTaskArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<TaskOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};


export type QueryAggregateVatInvoiceArgs = {
  cursor?: InputMaybe<VatInvoiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<VatInvoiceOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VatInvoiceWhereInput>;
};


export type QueryAggregateWithdrawReasonArgs = {
  cursor?: InputMaybe<WithdrawReasonWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<WithdrawReasonOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WithdrawReasonWhereInput>;
};


export type QueryCertificationArgs = {
  impUid: Scalars['String']['input'];
};


export type QueryFindAllManyArchitectArgs = {
  cursor?: InputMaybe<ArchitectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ArchitectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ArchitectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectWhereInput>;
};


export type QueryFindAllManyConstructionArgs = {
  cursor?: InputMaybe<ConstructionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ConstructionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ConstructionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ConstructionWhereInput>;
};


export type QueryFindAllManyGeneralConstructionArgs = {
  cursor?: InputMaybe<GeneralConstructionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<GeneralConstructionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GeneralConstructionWhereInput>;
};


export type QueryFindAllManyModelerArgs = {
  cursor?: InputMaybe<ModelerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ModelerScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ModelerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelerWhereInput>;
};


export type QueryFindAllManySpaceDesignArgs = {
  cursor?: InputMaybe<SpaceDesignWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SpaceDesignScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SpaceDesignOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpaceDesignWhereInput>;
};


export type QueryFindAllManySupplierArgs = {
  cursor?: InputMaybe<SupplierWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SupplierScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SupplierOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SupplierWhereInput>;
};


export type QueryFindExistMemberArgs = {
  where: MemberWhereUniqueInput;
};


export type QueryFindFirstAccessLogArgs = {
  cursor?: InputMaybe<AccessLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AccessLogScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AccessLogOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccessLogWhereInput>;
};


export type QueryFindFirstAddressArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AddressScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AddressOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryFindFirstAdminArgs = {
  cursor?: InputMaybe<AdminWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AdminScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AdminOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminWhereInput>;
};


export type QueryFindFirstAdminSchemaArgs = {
  cursor?: InputMaybe<AdminSchemaWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AdminSchemaScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AdminSchemaOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminSchemaWhereInput>;
};


export type QueryFindFirstArchitectArgs = {
  cursor?: InputMaybe<ArchitectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ArchitectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ArchitectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectWhereInput>;
};


export type QueryFindFirstArchitectureSrwArgs = {
  cursor?: InputMaybe<ArchitectureSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ArchitectureSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ArchitectureSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectureSrwWhereInput>;
};


export type QueryFindFirstBoqUpdateArgs = {
  cursor?: InputMaybe<BoqUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BoqUpdateScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BoqUpdateOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoqUpdateWhereInput>;
};


export type QueryFindFirstBlueprintArgs = {
  cursor?: InputMaybe<BlueprintWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BlueprintScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BlueprintOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlueprintWhereInput>;
};


export type QueryFindFirstBlueprintCommentArgs = {
  cursor?: InputMaybe<BlueprintCommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BlueprintCommentOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlueprintCommentWhereInput>;
};


export type QueryFindFirstBoQItemArgs = {
  cursor?: InputMaybe<BoQItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BoQItemScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BoQItemOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoQItemWhereInput>;
};


export type QueryFindFirstCashReceiptArgs = {
  cursor?: InputMaybe<CashReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CashReceiptScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CashReceiptOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CashReceiptWhereInput>;
};


export type QueryFindFirstCivilWorkSrwArgs = {
  cursor?: InputMaybe<CivilWorkSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CivilWorkSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CivilWorkSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CivilWorkSrwWhereInput>;
};


export type QueryFindFirstCommunicationSrwArgs = {
  cursor?: InputMaybe<CommunicationSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CommunicationSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CommunicationSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommunicationSrwWhereInput>;
};


export type QueryFindFirstConstructionArgs = {
  cursor?: InputMaybe<ConstructionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ConstructionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ConstructionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ConstructionWhereInput>;
};


export type QueryFindFirstCustomerArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CustomerScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CustomerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryFindFirstElectricalSrwArgs = {
  cursor?: InputMaybe<ElectricalSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ElectricalSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ElectricalSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ElectricalSrwWhereInput>;
};


export type QueryFindFirstEventLogsArgs = {
  cursor?: InputMaybe<EventLogsWhereUniqueInput>;
  distinct?: InputMaybe<EventLogsScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<EventLogsOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventLogsWhereInput>;
};


export type QueryFindFirstEventResultArgs = {
  cursor?: InputMaybe<EventResultWhereUniqueInput>;
  distinct?: InputMaybe<EventResultScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<EventResultOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventResultWhereInput>;
};


export type QueryFindFirstFireProtectionSrwArgs = {
  cursor?: InputMaybe<FireProtectionSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<FireProtectionSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<FireProtectionSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FireProtectionSrwWhereInput>;
};


export type QueryFindFirstGeneralConstructionArgs = {
  cursor?: InputMaybe<GeneralConstructionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<GeneralConstructionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GeneralConstructionWhereInput>;
};


export type QueryFindFirstIdentityArgs = {
  cursor?: InputMaybe<IdentityWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<IdentityScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<IdentityOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IdentityWhereInput>;
};


export type QueryFindFirstInquiryArgs = {
  cursor?: InputMaybe<InquiryWhereUniqueInput>;
  distinct?: InputMaybe<InquiryScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryFindFirstInspectionRejectedArgs = {
  cursor?: InputMaybe<InspectionRejectedWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<InspectionRejectedScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<InspectionRejectedOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InspectionRejectedWhereInput>;
};


export type QueryFindFirstKakaoMessageTemplateArgs = {
  cursor?: InputMaybe<KakaoMessageTemplateWhereUniqueInput>;
  distinct?: InputMaybe<KakaoMessageTemplateScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<KakaoMessageTemplateOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KakaoMessageTemplateWhereInput>;
};


export type QueryFindFirstKakaoSendLogArgs = {
  cursor?: InputMaybe<KakaoSendLogWhereUniqueInput>;
  distinct?: InputMaybe<KakaoSendLogScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<KakaoSendLogOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KakaoSendLogWhereInput>;
};


export type QueryFindFirstLandscapingSrwArgs = {
  cursor?: InputMaybe<LandscapingSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<LandscapingSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<LandscapingSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LandscapingSrwWhereInput>;
};


export type QueryFindFirstMaterialPriceArgs = {
  cursor?: InputMaybe<MaterialPriceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<MaterialPriceScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<MaterialPriceOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MaterialPriceWhereInput>;
};


export type QueryFindFirstMechanicalSrwArgs = {
  cursor?: InputMaybe<MechanicalSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<MechanicalSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<MechanicalSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MechanicalSrwWhereInput>;
};


export type QueryFindFirstMemberArgs = {
  cursor?: InputMaybe<MemberWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<MemberScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<MemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberWhereInput>;
};


export type QueryFindFirstMemberEventArgs = {
  cursor?: InputMaybe<MemberEventWhereUniqueInput>;
  distinct?: InputMaybe<MemberEventScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<MemberEventOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberEventWhereInput>;
};


export type QueryFindFirstMilestoneWorkItemArgs = {
  cursor?: InputMaybe<MilestoneWorkItemWhereUniqueInput>;
  distinct?: InputMaybe<MilestoneWorkItemScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<MilestoneWorkItemOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MilestoneWorkItemWhereInput>;
};


export type QueryFindFirstModelPropertyArgs = {
  cursor?: InputMaybe<ModelPropertyWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ModelPropertyScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ModelPropertyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelPropertyWhereInput>;
};


export type QueryFindFirstModelerArgs = {
  cursor?: InputMaybe<ModelerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ModelerScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ModelerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelerWhereInput>;
};


export type QueryFindFirstNoticeArgs = {
  cursor?: InputMaybe<NoticeWhereUniqueInput>;
  distinct?: InputMaybe<NoticeScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeWhereInput>;
};


export type QueryFindFirstNoticeCommentArgs = {
  cursor?: InputMaybe<NoticeCommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<NoticeCommentScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeCommentOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeCommentWhereInput>;
};


export type QueryFindFirstNoticeTopicArgs = {
  cursor?: InputMaybe<NoticeTopicWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<NoticeTopicScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeTopicOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeTopicWhereInput>;
};


export type QueryFindFirstPartnerArgs = {
  cursor?: InputMaybe<PartnerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<PartnerScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<PartnerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnerWhereInput>;
};


export type QueryFindFirstPayTransactionArgs = {
  cursor?: InputMaybe<PayTransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<PayTransactionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<PayTransactionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PayTransactionWhereInput>;
};


export type QueryFindFirstPayTransactionApiOnlyArgs = {
  cursor?: InputMaybe<PayTransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<PayTransactionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<PayTransactionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PayTransactionWhereInput>;
};


export type QueryFindFirstPersonnelArgs = {
  cursor?: InputMaybe<PersonnelWhereUniqueInput>;
  distinct?: InputMaybe<PersonnelScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<PersonnelOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PersonnelWhereInput>;
};


export type QueryFindFirstProcessSimulationArgs = {
  cursor?: InputMaybe<ProcessSimulationWhereUniqueInput>;
  distinct?: InputMaybe<ProcessSimulationScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProcessSimulationOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProcessSimulationWhereInput>;
};


export type QueryFindFirstProcessTaskCellArgs = {
  cursor?: InputMaybe<ProcessTaskCellWhereUniqueInput>;
  distinct?: InputMaybe<ProcessTaskCellScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProcessTaskCellOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProcessTaskCellWhereInput>;
};


export type QueryFindFirstProfileArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<ProfileScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProfileOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};


export type QueryFindFirstProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindFirstProjectApiOnlyArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindFirstProjectAssetArgs = {
  cursor?: InputMaybe<ProjectAssetWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectAssetScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectAssetOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectAssetWhereInput>;
};


export type QueryFindFirstProjectBoQArgs = {
  cursor?: InputMaybe<ProjectBoQWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectBoQScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectBoQOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectBoQWhereInput>;
};


export type QueryFindFirstProjectBoQFilesArgs = {
  cursor?: InputMaybe<ProjectBoQFilesWhereUniqueInput>;
  distinct?: InputMaybe<ProjectBoQFilesScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectBoQFilesOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectBoQFilesWhereInput>;
};


export type QueryFindFirstProjectFloorInfoArgs = {
  cursor?: InputMaybe<ProjectFloorInfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectFloorInfoScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectFloorInfoOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectFloorInfoWhereInput>;
};


export type QueryFindFirstProjectHistoryArgs = {
  cursor?: InputMaybe<ProjectHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectHistoryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectHistoryWhereInput>;
};


export type QueryFindFirstProjectMilestoneArgs = {
  cursor?: InputMaybe<ProjectMilestoneWhereUniqueInput>;
  distinct?: InputMaybe<ProjectMilestoneScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectMilestoneOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectMilestoneWhereInput>;
};


export type QueryFindFirstReplyArgs = {
  cursor?: InputMaybe<ReplyWhereUniqueInput>;
  distinct?: InputMaybe<ReplyScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ReplyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReplyWhereInput>;
};


export type QueryFindFirstShareProjectByMemberArgs = {
  cursor?: InputMaybe<ShareProjectByMemberWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ShareProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareProjectByMemberWhereInput>;
};


export type QueryFindFirstShareRatioWorkArgs = {
  cursor?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ShareRatioWorkOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};


export type QueryFindFirstSharedProjectArgs = {
  cursor?: InputMaybe<SharedProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SharedProjectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectWhereInput>;
};


export type QueryFindFirstSharedProjectByMemberArgs = {
  cursor?: InputMaybe<SharedProjectByMemberWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectByMemberWhereInput>;
};


export type QueryFindFirstSharedProjectTokenArgs = {
  cursor?: InputMaybe<SharedProjectTokenWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SharedProjectTokenScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectTokenOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectTokenWhereInput>;
};


export type QueryFindFirstSpaceDesignArgs = {
  cursor?: InputMaybe<SpaceDesignWhereUniqueInput>;
  distinct?: InputMaybe<SpaceDesignScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<SpaceDesignOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpaceDesignWhereInput>;
};


export type QueryFindFirstStandardBoQArgs = {
  cursor?: InputMaybe<StandardBoQWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<StandardBoQScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<StandardBoQOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardBoQWhereInput>;
};


export type QueryFindFirstStandardBoQFilesArgs = {
  cursor?: InputMaybe<StandardBoQFilesWhereUniqueInput>;
  distinct?: InputMaybe<StandardBoQFilesScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<StandardBoQFilesOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardBoQFilesWhereInput>;
};


export type QueryFindFirstSupplierArgs = {
  cursor?: InputMaybe<SupplierWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SupplierScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SupplierOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SupplierWhereInput>;
};


export type QueryFindFirstTaskArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<TaskScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<TaskOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};


export type QueryFindFirstVatInvoiceArgs = {
  cursor?: InputMaybe<VatInvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<VatInvoiceScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<VatInvoiceOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VatInvoiceWhereInput>;
};


export type QueryFindFirstWithdrawReasonArgs = {
  cursor?: InputMaybe<WithdrawReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<WithdrawReasonScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<WithdrawReasonOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WithdrawReasonWhereInput>;
};


export type QueryFindManyAccessLogArgs = {
  cursor?: InputMaybe<AccessLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AccessLogScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AccessLogOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccessLogWhereInput>;
};


export type QueryFindManyAccessLogCountArgs = {
  cursor?: InputMaybe<AccessLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AccessLogScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AccessLogOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccessLogWhereInput>;
};


export type QueryFindManyAddressArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AddressScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AddressOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryFindManyAddressCountArgs = {
  cursor?: InputMaybe<AddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AddressScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AddressOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AddressWhereInput>;
};


export type QueryFindManyAdminArgs = {
  cursor?: InputMaybe<AdminWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AdminScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AdminOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminWhereInput>;
};


export type QueryFindManyAdminCountArgs = {
  cursor?: InputMaybe<AdminWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AdminScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AdminOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminWhereInput>;
};


export type QueryFindManyAdminSchemaArgs = {
  cursor?: InputMaybe<AdminSchemaWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AdminSchemaScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AdminSchemaOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminSchemaWhereInput>;
};


export type QueryFindManyAdminSchemaCountArgs = {
  cursor?: InputMaybe<AdminSchemaWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<AdminSchemaScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<AdminSchemaOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminSchemaWhereInput>;
};


export type QueryFindManyArchitectArgs = {
  cursor?: InputMaybe<ArchitectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ArchitectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ArchitectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectWhereInput>;
};


export type QueryFindManyArchitectCountArgs = {
  cursor?: InputMaybe<ArchitectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ArchitectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ArchitectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectWhereInput>;
};


export type QueryFindManyArchitectureSrwArgs = {
  cursor?: InputMaybe<ArchitectureSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ArchitectureSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ArchitectureSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectureSrwWhereInput>;
};


export type QueryFindManyArchitectureSrwCountArgs = {
  cursor?: InputMaybe<ArchitectureSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ArchitectureSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ArchitectureSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectureSrwWhereInput>;
};


export type QueryFindManyBoqUpdateArgs = {
  cursor?: InputMaybe<BoqUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BoqUpdateScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BoqUpdateOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoqUpdateWhereInput>;
};


export type QueryFindManyBoqUpdateCountArgs = {
  cursor?: InputMaybe<BoqUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BoqUpdateScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BoqUpdateOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoqUpdateWhereInput>;
};


export type QueryFindManyBlueprintArgs = {
  cursor?: InputMaybe<BlueprintWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BlueprintScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BlueprintOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlueprintWhereInput>;
};


export type QueryFindManyBlueprintCommentArgs = {
  cursor?: InputMaybe<BlueprintCommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BlueprintCommentOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlueprintCommentWhereInput>;
};


export type QueryFindManyBlueprintCommentCountArgs = {
  cursor?: InputMaybe<BlueprintCommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BlueprintCommentScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BlueprintCommentOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlueprintCommentWhereInput>;
};


export type QueryFindManyBlueprintCountArgs = {
  cursor?: InputMaybe<BlueprintWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BlueprintScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BlueprintOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BlueprintWhereInput>;
};


export type QueryFindManyBoQItemArgs = {
  cursor?: InputMaybe<BoQItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BoQItemScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BoQItemOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoQItemWhereInput>;
};


export type QueryFindManyBoQItemCountArgs = {
  cursor?: InputMaybe<BoQItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<BoQItemScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<BoQItemOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoQItemWhereInput>;
};


export type QueryFindManyCashReceiptArgs = {
  cursor?: InputMaybe<CashReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CashReceiptScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CashReceiptOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CashReceiptWhereInput>;
};


export type QueryFindManyCashReceiptCountArgs = {
  cursor?: InputMaybe<CashReceiptWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CashReceiptScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CashReceiptOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CashReceiptWhereInput>;
};


export type QueryFindManyCivilWorkSrwArgs = {
  cursor?: InputMaybe<CivilWorkSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CivilWorkSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CivilWorkSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CivilWorkSrwWhereInput>;
};


export type QueryFindManyCivilWorkSrwCountArgs = {
  cursor?: InputMaybe<CivilWorkSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CivilWorkSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CivilWorkSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CivilWorkSrwWhereInput>;
};


export type QueryFindManyCommunicationSrwArgs = {
  cursor?: InputMaybe<CommunicationSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CommunicationSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CommunicationSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommunicationSrwWhereInput>;
};


export type QueryFindManyCommunicationSrwCountArgs = {
  cursor?: InputMaybe<CommunicationSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CommunicationSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CommunicationSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommunicationSrwWhereInput>;
};


export type QueryFindManyConstructionArgs = {
  cursor?: InputMaybe<ConstructionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ConstructionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ConstructionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ConstructionWhereInput>;
};


export type QueryFindManyConstructionCountArgs = {
  cursor?: InputMaybe<ConstructionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ConstructionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ConstructionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ConstructionWhereInput>;
};


export type QueryFindManyCustomerArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CustomerScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CustomerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryFindManyCustomerCountArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<CustomerScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<CustomerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryFindManyElectricalSrwArgs = {
  cursor?: InputMaybe<ElectricalSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ElectricalSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ElectricalSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ElectricalSrwWhereInput>;
};


export type QueryFindManyElectricalSrwCountArgs = {
  cursor?: InputMaybe<ElectricalSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ElectricalSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ElectricalSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ElectricalSrwWhereInput>;
};


export type QueryFindManyEventLogsArgs = {
  cursor?: InputMaybe<EventLogsWhereUniqueInput>;
  distinct?: InputMaybe<EventLogsScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<EventLogsOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventLogsWhereInput>;
};


export type QueryFindManyEventLogsCountArgs = {
  cursor?: InputMaybe<EventLogsWhereUniqueInput>;
  distinct?: InputMaybe<EventLogsScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<EventLogsOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventLogsWhereInput>;
};


export type QueryFindManyEventResultArgs = {
  cursor?: InputMaybe<EventResultWhereUniqueInput>;
  distinct?: InputMaybe<EventResultScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<EventResultOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventResultWhereInput>;
};


export type QueryFindManyEventResultCountArgs = {
  cursor?: InputMaybe<EventResultWhereUniqueInput>;
  distinct?: InputMaybe<EventResultScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<EventResultOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EventResultWhereInput>;
};


export type QueryFindManyFireProtectionSrwArgs = {
  cursor?: InputMaybe<FireProtectionSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<FireProtectionSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<FireProtectionSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FireProtectionSrwWhereInput>;
};


export type QueryFindManyFireProtectionSrwCountArgs = {
  cursor?: InputMaybe<FireProtectionSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<FireProtectionSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<FireProtectionSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FireProtectionSrwWhereInput>;
};


export type QueryFindManyGeneralConstructionArgs = {
  cursor?: InputMaybe<GeneralConstructionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<GeneralConstructionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GeneralConstructionWhereInput>;
};


export type QueryFindManyGeneralConstructionCountArgs = {
  cursor?: InputMaybe<GeneralConstructionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<GeneralConstructionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<GeneralConstructionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GeneralConstructionWhereInput>;
};


export type QueryFindManyIdentityArgs = {
  cursor?: InputMaybe<IdentityWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<IdentityScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<IdentityOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IdentityWhereInput>;
};


export type QueryFindManyIdentityCountArgs = {
  cursor?: InputMaybe<IdentityWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<IdentityScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<IdentityOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IdentityWhereInput>;
};


export type QueryFindManyInquiryArgs = {
  cursor?: InputMaybe<InquiryWhereUniqueInput>;
  distinct?: InputMaybe<InquiryScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryFindManyInquiryCountArgs = {
  cursor?: InputMaybe<InquiryWhereUniqueInput>;
  distinct?: InputMaybe<InquiryScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryFindManyInspectionRejectedArgs = {
  cursor?: InputMaybe<InspectionRejectedWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<InspectionRejectedScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<InspectionRejectedOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InspectionRejectedWhereInput>;
};


export type QueryFindManyInspectionRejectedCountArgs = {
  cursor?: InputMaybe<InspectionRejectedWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<InspectionRejectedScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<InspectionRejectedOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InspectionRejectedWhereInput>;
};


export type QueryFindManyKakaoMessageTemplateArgs = {
  cursor?: InputMaybe<KakaoMessageTemplateWhereUniqueInput>;
  distinct?: InputMaybe<KakaoMessageTemplateScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<KakaoMessageTemplateOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KakaoMessageTemplateWhereInput>;
};


export type QueryFindManyKakaoMessageTemplateCountArgs = {
  cursor?: InputMaybe<KakaoMessageTemplateWhereUniqueInput>;
  distinct?: InputMaybe<KakaoMessageTemplateScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<KakaoMessageTemplateOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KakaoMessageTemplateWhereInput>;
};


export type QueryFindManyKakaoSendLogArgs = {
  cursor?: InputMaybe<KakaoSendLogWhereUniqueInput>;
  distinct?: InputMaybe<KakaoSendLogScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<KakaoSendLogOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KakaoSendLogWhereInput>;
};


export type QueryFindManyKakaoSendLogCountArgs = {
  cursor?: InputMaybe<KakaoSendLogWhereUniqueInput>;
  distinct?: InputMaybe<KakaoSendLogScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<KakaoSendLogOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KakaoSendLogWhereInput>;
};


export type QueryFindManyLandscapingSrwArgs = {
  cursor?: InputMaybe<LandscapingSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<LandscapingSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<LandscapingSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LandscapingSrwWhereInput>;
};


export type QueryFindManyLandscapingSrwCountArgs = {
  cursor?: InputMaybe<LandscapingSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<LandscapingSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<LandscapingSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LandscapingSrwWhereInput>;
};


export type QueryFindManyMaterialPriceArgs = {
  cursor?: InputMaybe<MaterialPriceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<MaterialPriceScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<MaterialPriceOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MaterialPriceWhereInput>;
};


export type QueryFindManyMaterialPriceCountArgs = {
  cursor?: InputMaybe<MaterialPriceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<MaterialPriceScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<MaterialPriceOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MaterialPriceWhereInput>;
};


export type QueryFindManyMechanicalSrwArgs = {
  cursor?: InputMaybe<MechanicalSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<MechanicalSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<MechanicalSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MechanicalSrwWhereInput>;
};


export type QueryFindManyMechanicalSrwCountArgs = {
  cursor?: InputMaybe<MechanicalSrwWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<MechanicalSrwScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<MechanicalSrwOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MechanicalSrwWhereInput>;
};


export type QueryFindManyMemberArgs = {
  cursor?: InputMaybe<MemberWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<MemberScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<MemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberWhereInput>;
};


export type QueryFindManyMemberCountArgs = {
  cursor?: InputMaybe<MemberWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<MemberScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<MemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberWhereInput>;
};


export type QueryFindManyMemberEventArgs = {
  cursor?: InputMaybe<MemberEventWhereUniqueInput>;
  distinct?: InputMaybe<MemberEventScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<MemberEventOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberEventWhereInput>;
};


export type QueryFindManyMemberEventCountArgs = {
  cursor?: InputMaybe<MemberEventWhereUniqueInput>;
  distinct?: InputMaybe<MemberEventScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<MemberEventOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MemberEventWhereInput>;
};


export type QueryFindManyMilestoneWorkItemArgs = {
  cursor?: InputMaybe<MilestoneWorkItemWhereUniqueInput>;
  distinct?: InputMaybe<MilestoneWorkItemScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<MilestoneWorkItemOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MilestoneWorkItemWhereInput>;
};


export type QueryFindManyMilestoneWorkItemCountArgs = {
  cursor?: InputMaybe<MilestoneWorkItemWhereUniqueInput>;
  distinct?: InputMaybe<MilestoneWorkItemScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<MilestoneWorkItemOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MilestoneWorkItemWhereInput>;
};


export type QueryFindManyModelPropertyArgs = {
  cursor?: InputMaybe<ModelPropertyWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ModelPropertyScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ModelPropertyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelPropertyWhereInput>;
};


export type QueryFindManyModelPropertyCountArgs = {
  cursor?: InputMaybe<ModelPropertyWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ModelPropertyScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ModelPropertyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelPropertyWhereInput>;
};


export type QueryFindManyModelerArgs = {
  cursor?: InputMaybe<ModelerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ModelerScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ModelerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelerWhereInput>;
};


export type QueryFindManyModelerCountArgs = {
  cursor?: InputMaybe<ModelerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ModelerScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ModelerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelerWhereInput>;
};


export type QueryFindManyNoticeArgs = {
  cursor?: InputMaybe<NoticeWhereUniqueInput>;
  distinct?: InputMaybe<NoticeScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeWhereInput>;
};


export type QueryFindManyNoticeCommentArgs = {
  cursor?: InputMaybe<NoticeCommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<NoticeCommentScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeCommentOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeCommentWhereInput>;
};


export type QueryFindManyNoticeCommentCountArgs = {
  cursor?: InputMaybe<NoticeCommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<NoticeCommentScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeCommentOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeCommentWhereInput>;
};


export type QueryFindManyNoticeCountArgs = {
  cursor?: InputMaybe<NoticeWhereUniqueInput>;
  distinct?: InputMaybe<NoticeScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeWhereInput>;
};


export type QueryFindManyNoticeTopicArgs = {
  cursor?: InputMaybe<NoticeTopicWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<NoticeTopicScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeTopicOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeTopicWhereInput>;
};


export type QueryFindManyNoticeTopicCountArgs = {
  cursor?: InputMaybe<NoticeTopicWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<NoticeTopicScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<NoticeTopicOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NoticeTopicWhereInput>;
};


export type QueryFindManyPartnerArgs = {
  cursor?: InputMaybe<PartnerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<PartnerScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<PartnerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnerWhereInput>;
};


export type QueryFindManyPartnerCountArgs = {
  cursor?: InputMaybe<PartnerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<PartnerScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<PartnerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnerWhereInput>;
};


export type QueryFindManyPayTransactionArgs = {
  cursor?: InputMaybe<PayTransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<PayTransactionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<PayTransactionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PayTransactionWhereInput>;
};


export type QueryFindManyPayTransactionCountArgs = {
  cursor?: InputMaybe<PayTransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<PayTransactionScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<PayTransactionOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PayTransactionWhereInput>;
};


export type QueryFindManyPersonnelArgs = {
  cursor?: InputMaybe<PersonnelWhereUniqueInput>;
  distinct?: InputMaybe<PersonnelScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<PersonnelOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PersonnelWhereInput>;
};


export type QueryFindManyPersonnelCountArgs = {
  cursor?: InputMaybe<PersonnelWhereUniqueInput>;
  distinct?: InputMaybe<PersonnelScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<PersonnelOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PersonnelWhereInput>;
};


export type QueryFindManyProcessSimulationArgs = {
  cursor?: InputMaybe<ProcessSimulationWhereUniqueInput>;
  distinct?: InputMaybe<ProcessSimulationScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProcessSimulationOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProcessSimulationWhereInput>;
};


export type QueryFindManyProcessSimulationCountArgs = {
  cursor?: InputMaybe<ProcessSimulationWhereUniqueInput>;
  distinct?: InputMaybe<ProcessSimulationScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProcessSimulationOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProcessSimulationWhereInput>;
};


export type QueryFindManyProcessTaskCellArgs = {
  cursor?: InputMaybe<ProcessTaskCellWhereUniqueInput>;
  distinct?: InputMaybe<ProcessTaskCellScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProcessTaskCellOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProcessTaskCellWhereInput>;
};


export type QueryFindManyProcessTaskCellCountArgs = {
  cursor?: InputMaybe<ProcessTaskCellWhereUniqueInput>;
  distinct?: InputMaybe<ProcessTaskCellScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProcessTaskCellOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProcessTaskCellWhereInput>;
};


export type QueryFindManyProfileArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<ProfileScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProfileOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};


export type QueryFindManyProfileCountArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<ProfileScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProfileOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};


export type QueryFindManyProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindManyProjectAssetArgs = {
  cursor?: InputMaybe<ProjectAssetWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectAssetScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectAssetOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectAssetWhereInput>;
};


export type QueryFindManyProjectAssetCountArgs = {
  cursor?: InputMaybe<ProjectAssetWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectAssetScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectAssetOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectAssetWhereInput>;
};


export type QueryFindManyProjectBoQArgs = {
  cursor?: InputMaybe<ProjectBoQWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectBoQScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectBoQOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectBoQWhereInput>;
};


export type QueryFindManyProjectBoQCountArgs = {
  cursor?: InputMaybe<ProjectBoQWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectBoQScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectBoQOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectBoQWhereInput>;
};


export type QueryFindManyProjectBoQFilesArgs = {
  cursor?: InputMaybe<ProjectBoQFilesWhereUniqueInput>;
  distinct?: InputMaybe<ProjectBoQFilesScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectBoQFilesOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectBoQFilesWhereInput>;
};


export type QueryFindManyProjectBoQFilesCountArgs = {
  cursor?: InputMaybe<ProjectBoQFilesWhereUniqueInput>;
  distinct?: InputMaybe<ProjectBoQFilesScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectBoQFilesOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectBoQFilesWhereInput>;
};


export type QueryFindManyProjectCountArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindManyProjectFloorInfoArgs = {
  cursor?: InputMaybe<ProjectFloorInfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectFloorInfoScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectFloorInfoOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectFloorInfoWhereInput>;
};


export type QueryFindManyProjectFloorInfoCountArgs = {
  cursor?: InputMaybe<ProjectFloorInfoWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectFloorInfoScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectFloorInfoOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectFloorInfoWhereInput>;
};


export type QueryFindManyProjectHistoryArgs = {
  cursor?: InputMaybe<ProjectHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectHistoryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectHistoryWhereInput>;
};


export type QueryFindManyProjectHistoryCountArgs = {
  cursor?: InputMaybe<ProjectHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectHistoryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectHistoryWhereInput>;
};


export type QueryFindManyProjectMilestoneArgs = {
  cursor?: InputMaybe<ProjectMilestoneWhereUniqueInput>;
  distinct?: InputMaybe<ProjectMilestoneScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectMilestoneOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectMilestoneWhereInput>;
};


export type QueryFindManyProjectMilestoneCountArgs = {
  cursor?: InputMaybe<ProjectMilestoneWhereUniqueInput>;
  distinct?: InputMaybe<ProjectMilestoneScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectMilestoneOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectMilestoneWhereInput>;
};


export type QueryFindManyReplyArgs = {
  cursor?: InputMaybe<ReplyWhereUniqueInput>;
  distinct?: InputMaybe<ReplyScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ReplyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReplyWhereInput>;
};


export type QueryFindManyReplyCountArgs = {
  cursor?: InputMaybe<ReplyWhereUniqueInput>;
  distinct?: InputMaybe<ReplyScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<ReplyOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReplyWhereInput>;
};


export type QueryFindManyShareProjectByMemberArgs = {
  cursor?: InputMaybe<ShareProjectByMemberWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ShareProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareProjectByMemberWhereInput>;
};


export type QueryFindManyShareProjectByMemberCountArgs = {
  cursor?: InputMaybe<ShareProjectByMemberWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ShareProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareProjectByMemberWhereInput>;
};


export type QueryFindManyShareRatioWorkArgs = {
  cursor?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ShareRatioWorkOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};


export type QueryFindManyShareRatioWorkCountArgs = {
  cursor?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ShareRatioWorkOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareRatioWorkWhereInput>;
};


export type QueryFindManySharedProjectArgs = {
  cursor?: InputMaybe<SharedProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SharedProjectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectWhereInput>;
};


export type QueryFindManySharedProjectByMemberArgs = {
  cursor?: InputMaybe<SharedProjectByMemberWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectByMemberWhereInput>;
};


export type QueryFindManySharedProjectByMemberCountArgs = {
  cursor?: InputMaybe<SharedProjectByMemberWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectByMemberWhereInput>;
};


export type QueryFindManySharedProjectCountArgs = {
  cursor?: InputMaybe<SharedProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SharedProjectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectWhereInput>;
};


export type QueryFindManySharedProjectTokenArgs = {
  cursor?: InputMaybe<SharedProjectTokenWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SharedProjectTokenScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectTokenOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectTokenWhereInput>;
};


export type QueryFindManySharedProjectTokenCountArgs = {
  cursor?: InputMaybe<SharedProjectTokenWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SharedProjectTokenScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectTokenOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectTokenWhereInput>;
};


export type QueryFindManySpaceDesignArgs = {
  cursor?: InputMaybe<SpaceDesignWhereUniqueInput>;
  distinct?: InputMaybe<SpaceDesignScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<SpaceDesignOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpaceDesignWhereInput>;
};


export type QueryFindManySpaceDesignCountArgs = {
  cursor?: InputMaybe<SpaceDesignWhereUniqueInput>;
  distinct?: InputMaybe<SpaceDesignScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<SpaceDesignOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpaceDesignWhereInput>;
};


export type QueryFindManyStandardBoQArgs = {
  cursor?: InputMaybe<StandardBoQWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<StandardBoQScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<StandardBoQOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardBoQWhereInput>;
};


export type QueryFindManyStandardBoQCountArgs = {
  cursor?: InputMaybe<StandardBoQWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<StandardBoQScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<StandardBoQOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardBoQWhereInput>;
};


export type QueryFindManyStandardBoQFilesArgs = {
  cursor?: InputMaybe<StandardBoQFilesWhereUniqueInput>;
  distinct?: InputMaybe<StandardBoQFilesScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<StandardBoQFilesOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardBoQFilesWhereInput>;
};


export type QueryFindManyStandardBoQFilesCountArgs = {
  cursor?: InputMaybe<StandardBoQFilesWhereUniqueInput>;
  distinct?: InputMaybe<StandardBoQFilesScalarFieldEnum>;
  orderBy?: InputMaybe<Array<InputMaybe<StandardBoQFilesOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardBoQFilesWhereInput>;
};


export type QueryFindManySupplierArgs = {
  cursor?: InputMaybe<SupplierWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SupplierScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SupplierOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SupplierWhereInput>;
};


export type QueryFindManySupplierCountArgs = {
  cursor?: InputMaybe<SupplierWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SupplierScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SupplierOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SupplierWhereInput>;
};


export type QueryFindManyTaskArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<TaskScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<TaskOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};


export type QueryFindManyTaskCountArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<TaskScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<TaskOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};


export type QueryFindManyVatInvoiceArgs = {
  cursor?: InputMaybe<VatInvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<VatInvoiceScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<VatInvoiceOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VatInvoiceWhereInput>;
};


export type QueryFindManyVatInvoiceCountArgs = {
  cursor?: InputMaybe<VatInvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<VatInvoiceScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<VatInvoiceOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VatInvoiceWhereInput>;
};


export type QueryFindManyWithdrawReasonArgs = {
  cursor?: InputMaybe<WithdrawReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<WithdrawReasonScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<WithdrawReasonOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WithdrawReasonWhereInput>;
};


export type QueryFindManyWithdrawReasonCountArgs = {
  cursor?: InputMaybe<WithdrawReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<WithdrawReasonScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<WithdrawReasonOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WithdrawReasonWhereInput>;
};


export type QueryFindMyInquiryFileQueryArgs = {
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryFindMyManyInquiryArgs = {
  cursor?: InputMaybe<InquiryWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<InquiryScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryFindMyManyPartnerArgs = {
  cursor?: InputMaybe<PartnerWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<PartnerScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<PartnerOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PartnerWhereInput>;
};


export type QueryFindMyManyProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindMyOneInquiryQueryArgs = {
  cursor?: InputMaybe<InquiryWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<InquiryScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<InquiryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InquiryWhereInput>;
};


export type QueryFindMyOneProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindProjectHistoryArgs = {
  cursor?: InputMaybe<ProjectHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ProjectHistoryScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectHistoryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectHistoryWhereInput>;
};


export type QueryFindProjectShareInfoArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryFindUniqueAccessLogArgs = {
  where: AccessLogWhereUniqueInput;
};


export type QueryFindUniqueAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type QueryFindUniqueAdminArgs = {
  where: AdminWhereUniqueInput;
};


export type QueryFindUniqueAdminSchemaArgs = {
  where: AdminSchemaWhereUniqueInput;
};


export type QueryFindUniqueArchitectArgs = {
  where: ArchitectWhereUniqueInput;
};


export type QueryFindUniqueArchitectureSrwArgs = {
  where: ArchitectureSrwWhereUniqueInput;
};


export type QueryFindUniqueBoqUpdateArgs = {
  where: BoqUpdateWhereUniqueInput;
};


export type QueryFindUniqueBlueprintArgs = {
  where: BlueprintWhereUniqueInput;
};


export type QueryFindUniqueBlueprintCommentArgs = {
  where: BlueprintCommentWhereUniqueInput;
};


export type QueryFindUniqueBoQItemArgs = {
  where: BoQItemWhereUniqueInput;
};


export type QueryFindUniqueCashReceiptArgs = {
  where: CashReceiptWhereUniqueInput;
};


export type QueryFindUniqueCivilWorkSrwArgs = {
  where: CivilWorkSrwWhereUniqueInput;
};


export type QueryFindUniqueCommunicationSrwArgs = {
  where: CommunicationSrwWhereUniqueInput;
};


export type QueryFindUniqueConstructionArgs = {
  where: ConstructionWhereUniqueInput;
};


export type QueryFindUniqueCustomerArgs = {
  where: CustomerWhereUniqueInput;
};


export type QueryFindUniqueElectricalSrwArgs = {
  where: ElectricalSrwWhereUniqueInput;
};


export type QueryFindUniqueEventLogsArgs = {
  where: EventLogsWhereUniqueInput;
};


export type QueryFindUniqueEventResultArgs = {
  where: EventResultWhereUniqueInput;
};


export type QueryFindUniqueFireProtectionSrwArgs = {
  where: FireProtectionSrwWhereUniqueInput;
};


export type QueryFindUniqueGeneralConstructionArgs = {
  where: GeneralConstructionWhereUniqueInput;
};


export type QueryFindUniqueIdentityArgs = {
  where: IdentityWhereUniqueInput;
};


export type QueryFindUniqueInquiryArgs = {
  where: InquiryWhereUniqueInput;
};


export type QueryFindUniqueInspectionRejectedArgs = {
  where: InspectionRejectedWhereUniqueInput;
};


export type QueryFindUniqueKakaoMessageTemplateArgs = {
  where: KakaoMessageTemplateWhereUniqueInput;
};


export type QueryFindUniqueKakaoSendLogArgs = {
  where: KakaoSendLogWhereUniqueInput;
};


export type QueryFindUniqueLandscapingSrwArgs = {
  where: LandscapingSrwWhereUniqueInput;
};


export type QueryFindUniqueMaterialPriceArgs = {
  where: MaterialPriceWhereUniqueInput;
};


export type QueryFindUniqueMechanicalSrwArgs = {
  where: MechanicalSrwWhereUniqueInput;
};


export type QueryFindUniqueMemberArgs = {
  where: MemberWhereUniqueInput;
};


export type QueryFindUniqueMemberEventArgs = {
  where: MemberEventWhereUniqueInput;
};


export type QueryFindUniqueMilestoneWorkItemArgs = {
  where: MilestoneWorkItemWhereUniqueInput;
};


export type QueryFindUniqueModelPropertyArgs = {
  where: ModelPropertyWhereUniqueInput;
};


export type QueryFindUniqueModelerArgs = {
  where: ModelerWhereUniqueInput;
};


export type QueryFindUniqueNoticeArgs = {
  where: NoticeWhereUniqueInput;
};


export type QueryFindUniqueNoticeCommentArgs = {
  where: NoticeCommentWhereUniqueInput;
};


export type QueryFindUniqueNoticeTopicArgs = {
  where: NoticeTopicWhereUniqueInput;
};


export type QueryFindUniquePartnerArgs = {
  where: PartnerWhereUniqueInput;
};


export type QueryFindUniquePayTransactionArgs = {
  where: PayTransactionWhereUniqueInput;
};


export type QueryFindUniquePayTransactionApiOnlyArgs = {
  where: PayTransactionWhereUniqueInput;
};


export type QueryFindUniquePersonnelArgs = {
  where: PersonnelWhereUniqueInput;
};


export type QueryFindUniqueProcessSimulationArgs = {
  where: ProcessSimulationWhereUniqueInput;
};


export type QueryFindUniqueProcessTaskCellArgs = {
  where: ProcessTaskCellWhereUniqueInput;
};


export type QueryFindUniqueProfileArgs = {
  where: ProfileWhereUniqueInput;
};


export type QueryFindUniqueProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryFindUniqueProjectAssetArgs = {
  where: ProjectAssetWhereUniqueInput;
};


export type QueryFindUniqueProjectBoQArgs = {
  where: ProjectBoQWhereUniqueInput;
};


export type QueryFindUniqueProjectBoQFilesArgs = {
  where: ProjectBoQFilesWhereUniqueInput;
};


export type QueryFindUniqueProjectFloorInfoArgs = {
  where: ProjectFloorInfoWhereUniqueInput;
};


export type QueryFindUniqueProjectHistoryArgs = {
  where: ProjectHistoryWhereUniqueInput;
};


export type QueryFindUniqueProjectMilestoneArgs = {
  where: ProjectMilestoneWhereUniqueInput;
};


export type QueryFindUniqueReplyArgs = {
  where: ReplyWhereUniqueInput;
};


export type QueryFindUniqueShareProjectByMemberArgs = {
  where: ShareProjectByMemberWhereUniqueInput;
};


export type QueryFindUniqueShareRatioWorkArgs = {
  where: ShareRatioWorkWhereUniqueInput;
};


export type QueryFindUniqueSharedProjectArgs = {
  where: SharedProjectWhereUniqueInput;
};


export type QueryFindUniqueSharedProjectByMemberArgs = {
  where: SharedProjectByMemberWhereUniqueInput;
};


export type QueryFindUniqueSharedProjectTokenArgs = {
  where: SharedProjectTokenWhereUniqueInput;
};


export type QueryFindUniqueSpaceDesignArgs = {
  where: SpaceDesignWhereUniqueInput;
};


export type QueryFindUniqueStandardBoQArgs = {
  where: StandardBoQWhereUniqueInput;
};


export type QueryFindUniqueStandardBoQFilesArgs = {
  where: StandardBoQFilesWhereUniqueInput;
};


export type QueryFindUniqueSupplierArgs = {
  where: SupplierWhereUniqueInput;
};


export type QueryFindUniqueTaskArgs = {
  where: TaskWhereUniqueInput;
};


export type QueryFindUniqueVatInvoiceArgs = {
  where: VatInvoiceWhereUniqueInput;
};


export type QueryFindUniqueWithdrawReasonArgs = {
  where: WithdrawReasonWhereUniqueInput;
};


export type QueryGetCostRatioArgs = {
  data: GetCostRatioInputType;
};


export type QueryGetShareMemberArgs = {
  cursor?: InputMaybe<ShareProjectByMemberWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ShareProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShareProjectByMemberWhereInput>;
};


export type QueryGetSharedMemberArgs = {
  cursor?: InputMaybe<SharedProjectByMemberWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<SharedProjectByMemberOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectByMemberWhereInput>;
};


export type QueryGetSharedProjectByTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryOpensearchQueryArgs = {
  index?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['Json']['input']>;
};


export type QueryResolveOriginalFileNameArgs = {
  fileKey: Scalars['String']['input'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

/** Shared message */
export interface ReSharedMessage {
  __typename?: 'ReSharedMessage';
  error: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
}

export enum ReactionType {
  Bad = 'BAD',
  Good = 'GOOD'
}

export enum RejectionStatus {
  Idle = 'idle',
  Passed = 'passed',
  Rejected = 'rejected'
}

export interface Reply {
  __typename?: 'Reply';
  Inquiry: Inquiry;
  content: Scalars['String']['output'];
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  inquiryId: Scalars['Int']['output'];
  originalFileName?: Maybe<Scalars['String']['output']>;
  reaction?: Maybe<ReactionType>;
  /** 저장 상태 false: 임시저장 true: 완료  */
  storageStatus: Scalars['Boolean']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  /** Unique ID */
  uuid: Scalars['String']['output'];
}

export interface ReplyAvgAggregateOutputType {
  __typename?: 'ReplyAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  inquiryId?: Maybe<Scalars['Float']['output']>;
}

export interface ReplyAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  inquiryId?: InputMaybe<SortOrder>;
}

export interface ReplyCountAggregateOutputType {
  __typename?: 'ReplyCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  inquiryId: Scalars['Int']['output'];
  reaction: Scalars['Int']['output'];
  storageStatus: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface ReplyCountOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inquiryId?: InputMaybe<SortOrder>;
  reaction?: InputMaybe<SortOrder>;
  storageStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ReplyCreateInput {
  Inquiry: InquiryCreateNestedOneWithoutReplyInput;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  reaction?: InputMaybe<ReactionType>;
  storageStatus?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ReplyCreateManyInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inquiryId: Scalars['Int']['input'];
  reaction?: InputMaybe<ReactionType>;
  storageStatus?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ReplyCreateNestedOneWithoutInquiryInput {
  connect?: InputMaybe<ReplyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReplyCreateOrConnectWithoutInquiryInput>;
  create?: InputMaybe<ReplyUncheckedCreateWithoutInquiryInput>;
}

export interface ReplyCreateOrConnectWithoutInquiryInput {
  create: ReplyUncheckedCreateWithoutInquiryInput;
  where: ReplyWhereUniqueInput;
}

export interface ReplyCreateWithoutInquiryInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  reaction?: InputMaybe<ReactionType>;
  storageStatus?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ReplyMaxAggregateOutputType {
  __typename?: 'ReplyMaxAggregateOutputType';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inquiryId?: Maybe<Scalars['Int']['output']>;
  reaction?: Maybe<ReactionType>;
  storageStatus?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ReplyMaxOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inquiryId?: InputMaybe<SortOrder>;
  reaction?: InputMaybe<SortOrder>;
  storageStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ReplyMinAggregateOutputType {
  __typename?: 'ReplyMinAggregateOutputType';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inquiryId?: Maybe<Scalars['Int']['output']>;
  reaction?: Maybe<ReactionType>;
  storageStatus?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ReplyMinOrderByAggregateInput {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inquiryId?: InputMaybe<SortOrder>;
  reaction?: InputMaybe<SortOrder>;
  storageStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ReplyOrderByWithAggregationInput {
  _avg?: InputMaybe<ReplyAvgOrderByAggregateInput>;
  _count?: InputMaybe<ReplyCountOrderByAggregateInput>;
  _max?: InputMaybe<ReplyMaxOrderByAggregateInput>;
  _min?: InputMaybe<ReplyMinOrderByAggregateInput>;
  _sum?: InputMaybe<ReplySumOrderByAggregateInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inquiryId?: InputMaybe<SortOrder>;
  reaction?: InputMaybe<SortOrderInput>;
  storageStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ReplyOrderByWithRelationInput {
  Inquiry?: InputMaybe<InquiryOrderByWithRelationInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inquiryId?: InputMaybe<SortOrder>;
  reaction?: InputMaybe<SortOrderInput>;
  storageStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ReplyRelationFilter {
  is?: InputMaybe<ReplyWhereInput>;
  isNot?: InputMaybe<ReplyWhereInput>;
}

export enum ReplyScalarFieldEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  Id = 'id',
  InquiryId = 'inquiryId',
  Reaction = 'reaction',
  StorageStatus = 'storageStatus',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  Uuid = 'uuid'
}

export interface ReplyScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ReplyScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ReplyScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ReplyScalarWhereWithAggregatesInput>>>;
  content?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  inquiryId?: InputMaybe<IntWithAggregatesFilter>;
  reaction?: InputMaybe<EnumReactionTypeNullableWithAggregatesFilter>;
  storageStatus?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  url?: InputMaybe<StringNullableWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface ReplySumAggregateOutputType {
  __typename?: 'ReplySumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  inquiryId?: Maybe<Scalars['Int']['output']>;
}

export interface ReplySumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  inquiryId?: InputMaybe<SortOrder>;
}

export interface ReplyUncheckedCreateInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inquiryId: Scalars['Int']['input'];
  reaction?: InputMaybe<ReactionType>;
  storageStatus?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ReplyUncheckedCreateNestedOneWithoutInquiryInput {
  connect?: InputMaybe<ReplyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReplyCreateOrConnectWithoutInquiryInput>;
  create?: InputMaybe<ReplyUncheckedCreateWithoutInquiryInput>;
}

export interface ReplyUncheckedCreateWithoutInquiryInput {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reaction?: InputMaybe<ReactionType>;
  storageStatus?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ReplyUncheckedUpdateInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  inquiryId?: InputMaybe<IntFieldUpdateOperationsInput>;
  reaction?: InputMaybe<NullableEnumReactionTypeFieldUpdateOperationsInput>;
  storageStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ReplyUncheckedUpdateManyInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  inquiryId?: InputMaybe<IntFieldUpdateOperationsInput>;
  reaction?: InputMaybe<NullableEnumReactionTypeFieldUpdateOperationsInput>;
  storageStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ReplyUncheckedUpdateOneWithoutInquiryNestedInput {
  connect?: InputMaybe<ReplyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReplyCreateOrConnectWithoutInquiryInput>;
  create?: InputMaybe<ReplyUncheckedCreateWithoutInquiryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReplyUncheckedUpdateWithoutInquiryInput>;
  upsert?: InputMaybe<ReplyUpsertWithoutInquiryInput>;
}

export interface ReplyUncheckedUpdateWithoutInquiryInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  reaction?: InputMaybe<NullableEnumReactionTypeFieldUpdateOperationsInput>;
  storageStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ReplyUpdateInput {
  Inquiry?: InputMaybe<InquiryUpdateOneRequiredWithoutReplyNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  reaction?: InputMaybe<NullableEnumReactionTypeFieldUpdateOperationsInput>;
  storageStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ReplyUpdateManyMutationInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  reaction?: InputMaybe<NullableEnumReactionTypeFieldUpdateOperationsInput>;
  storageStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ReplyUpdateOneWithoutInquiryNestedInput {
  connect?: InputMaybe<ReplyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReplyCreateOrConnectWithoutInquiryInput>;
  create?: InputMaybe<ReplyUncheckedCreateWithoutInquiryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReplyUncheckedUpdateWithoutInquiryInput>;
  upsert?: InputMaybe<ReplyUpsertWithoutInquiryInput>;
}

export interface ReplyUpdateWithoutInquiryInput {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  reaction?: InputMaybe<NullableEnumReactionTypeFieldUpdateOperationsInput>;
  storageStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ReplyUpsertWithoutInquiryInput {
  create: ReplyUncheckedCreateWithoutInquiryInput;
  update: ReplyUncheckedUpdateWithoutInquiryInput;
}

export interface ReplyWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ReplyWhereInput>>>;
  Inquiry?: InputMaybe<InquiryWhereInput>;
  NOT?: InputMaybe<Array<InputMaybe<ReplyWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ReplyWhereInput>>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inquiryId?: InputMaybe<IntFilter>;
  reaction?: InputMaybe<EnumReactionTypeNullableFilter>;
  storageStatus?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ReplyWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  inquiryId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface Result {
  __typename?: 'Result';
  _id?: Maybe<Scalars['String']['output']>;
  _index?: Maybe<Scalars['String']['output']>;
  _score?: Maybe<Scalars['Float']['output']>;
  _source?: Maybe<OpenSearchDocument>;
}

export interface Schema {
  __typename?: 'Schema';
  enums: Array<Enum>;
  models: Array<Model>;
}

export interface ShareProjectByMember {
  __typename?: 'ShareProjectByMember';
  count: Scalars['Int']['output'];
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  /** ID String CUID */
  id: Scalars['Int']['output'];
  member: Member;
  memberId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sharedAt: Scalars['DateTime']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}

export interface ShareProjectByMemberAvgAggregateOutputType {
  __typename?: 'ShareProjectByMemberAvgAggregateOutputType';
  count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  memberId?: Maybe<Scalars['Float']['output']>;
}

export interface ShareProjectByMemberAvgOrderByAggregateInput {
  count?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface ShareProjectByMemberCountAggregateOutputType {
  __typename?: 'ShareProjectByMemberCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  sharedAt: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface ShareProjectByMemberCountOrderByAggregateInput {
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sharedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ShareProjectByMemberCreateInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  member: MemberCreateNestedOneWithoutShareProjectByMemberInput;
  name?: InputMaybe<Scalars['String']['input']>;
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ShareProjectByMemberCreateManyInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memberId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ShareProjectByMemberCreateManyMemberInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ShareProjectByMemberCreateManyMemberInputEnvelope {
  data: ShareProjectByMemberCreateManyMemberInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ShareProjectByMemberCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareProjectByMemberCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareProjectByMemberCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<ShareProjectByMemberCreateManyMemberInputEnvelope>;
}

export interface ShareProjectByMemberCreateOrConnectWithoutMemberInput {
  create: ShareProjectByMemberUncheckedCreateWithoutMemberInput;
  where: ShareProjectByMemberWhereUniqueInput;
}

export interface ShareProjectByMemberCreateWithoutMemberInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ShareProjectByMemberEmailMemberIdCompoundUniqueInput {
  email: Scalars['String']['input'];
  memberId: Scalars['Int']['input'];
}

export interface ShareProjectByMemberListRelationFilter {
  every?: InputMaybe<ShareProjectByMemberWhereInput>;
  none?: InputMaybe<ShareProjectByMemberWhereInput>;
  some?: InputMaybe<ShareProjectByMemberWhereInput>;
}

export interface ShareProjectByMemberMaxAggregateOutputType {
  __typename?: 'ShareProjectByMemberMaxAggregateOutputType';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sharedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface ShareProjectByMemberMaxOrderByAggregateInput {
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sharedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ShareProjectByMemberMinAggregateOutputType {
  __typename?: 'ShareProjectByMemberMinAggregateOutputType';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sharedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface ShareProjectByMemberMinOrderByAggregateInput {
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sharedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ShareProjectByMemberOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ShareProjectByMemberOrderByWithAggregationInput {
  _avg?: InputMaybe<ShareProjectByMemberAvgOrderByAggregateInput>;
  _count?: InputMaybe<ShareProjectByMemberCountOrderByAggregateInput>;
  _max?: InputMaybe<ShareProjectByMemberMaxOrderByAggregateInput>;
  _min?: InputMaybe<ShareProjectByMemberMinOrderByAggregateInput>;
  _sum?: InputMaybe<ShareProjectByMemberSumOrderByAggregateInput>;
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  sharedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface ShareProjectByMemberOrderByWithRelationInput {
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberOrderByWithRelationInput>;
  memberId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  sharedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export enum ShareProjectByMemberScalarFieldEnum {
  Count = 'count',
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  MemberId = 'memberId',
  Name = 'name',
  SharedAt = 'sharedAt',
  UpdatedAt = 'updatedAt'
}

export interface ShareProjectByMemberScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarWhereInput>>>;
  count?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  memberId?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  sharedAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface ShareProjectByMemberScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarWhereWithAggregatesInput>>>;
  count?: InputMaybe<IntWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  memberId?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  sharedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface ShareProjectByMemberSumAggregateOutputType {
  __typename?: 'ShareProjectByMemberSumAggregateOutputType';
  count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
}

export interface ShareProjectByMemberSumOrderByAggregateInput {
  count?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface ShareProjectByMemberUncheckedCreateInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memberId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ShareProjectByMemberUncheckedCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareProjectByMemberCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareProjectByMemberCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<ShareProjectByMemberCreateManyMemberInputEnvelope>;
}

export interface ShareProjectByMemberUncheckedCreateWithoutMemberInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ShareProjectByMemberUncheckedUpdateInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ShareProjectByMemberUncheckedUpdateManyInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memberId?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ShareProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareProjectByMemberCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareProjectByMemberCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<ShareProjectByMemberCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareProjectByMemberUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareProjectByMemberUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareProjectByMemberUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface ShareProjectByMemberUncheckedUpdateManyWithoutShareProjectByMemberInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ShareProjectByMemberUncheckedUpdateWithoutMemberInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ShareProjectByMemberUpdateInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateOneRequiredWithoutShareProjectByMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ShareProjectByMemberUpdateManyMutationInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ShareProjectByMemberUpdateManyWithWhereWithoutMemberInput {
  data: ShareProjectByMemberUncheckedUpdateManyWithoutShareProjectByMemberInput;
  where: ShareProjectByMemberScalarWhereInput;
}

export interface ShareProjectByMemberUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareProjectByMemberCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareProjectByMemberCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<ShareProjectByMemberCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareProjectByMemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareProjectByMemberUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareProjectByMemberUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareProjectByMemberUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface ShareProjectByMemberUpdateWithWhereUniqueWithoutMemberInput {
  data: ShareProjectByMemberUncheckedUpdateWithoutMemberInput;
  where: ShareProjectByMemberWhereUniqueInput;
}

export interface ShareProjectByMemberUpdateWithoutMemberInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface ShareProjectByMemberUpsertWithWhereUniqueWithoutMemberInput {
  create: ShareProjectByMemberUncheckedCreateWithoutMemberInput;
  update: ShareProjectByMemberUncheckedUpdateWithoutMemberInput;
  where: ShareProjectByMemberWhereUniqueInput;
}

export interface ShareProjectByMemberWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ShareProjectByMemberWhereInput>>>;
  count?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  member?: InputMaybe<MemberWhereInput>;
  memberId?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  sharedAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface ShareProjectByMemberWhereUniqueInput {
  email_memberId?: InputMaybe<ShareProjectByMemberEmailMemberIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** Share ratio of Works */
export interface ShareRatioWork {
  __typename?: 'ShareRatioWork';
  Project: Array<Project>;
  _count: ShareRatioWorkCountOutputType;
  architecture: Array<ArchitectureSrw>;
  architectureSRW: Scalars['Float']['output'];
  civilWork: Array<CivilWorkSrw>;
  civilWorkSRW: Scalars['Float']['output'];
  communication: Array<CommunicationSrw>;
  communicationSRW: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  electrical: Array<ElectricalSrw>;
  electricalSRW: Scalars['Float']['output'];
  fireProtection: Array<FireProtectionSrw>;
  fireProtectionSRW: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  landscaping: Array<LandscapingSrw>;
  landscapingSRW: Scalars['Float']['output'];
  mechanical: Array<MechanicalSrw>;
  mechanicalSRW: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
}


/** Share ratio of Works */
export type ShareRatioWorkProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<ProjectScalarFieldEnum>;
  orderBy?: InputMaybe<ProjectOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


/** Share ratio of Works */
export type ShareRatioWorkArchitectureArgs = {
  cursor?: InputMaybe<ArchitectureSrwWhereUniqueInput>;
  distinct?: InputMaybe<ArchitectureSrwScalarFieldEnum>;
  orderBy?: InputMaybe<ArchitectureSrwOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchitectureSrwWhereInput>;
};


/** Share ratio of Works */
export type ShareRatioWorkCivilWorkArgs = {
  cursor?: InputMaybe<CivilWorkSrwWhereUniqueInput>;
  distinct?: InputMaybe<CivilWorkSrwScalarFieldEnum>;
  orderBy?: InputMaybe<CivilWorkSrwOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CivilWorkSrwWhereInput>;
};


/** Share ratio of Works */
export type ShareRatioWorkCommunicationArgs = {
  cursor?: InputMaybe<CommunicationSrwWhereUniqueInput>;
  distinct?: InputMaybe<CommunicationSrwScalarFieldEnum>;
  orderBy?: InputMaybe<CommunicationSrwOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommunicationSrwWhereInput>;
};


/** Share ratio of Works */
export type ShareRatioWorkElectricalArgs = {
  cursor?: InputMaybe<ElectricalSrwWhereUniqueInput>;
  distinct?: InputMaybe<ElectricalSrwScalarFieldEnum>;
  orderBy?: InputMaybe<ElectricalSrwOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ElectricalSrwWhereInput>;
};


/** Share ratio of Works */
export type ShareRatioWorkFireProtectionArgs = {
  cursor?: InputMaybe<FireProtectionSrwWhereUniqueInput>;
  distinct?: InputMaybe<FireProtectionSrwScalarFieldEnum>;
  orderBy?: InputMaybe<FireProtectionSrwOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FireProtectionSrwWhereInput>;
};


/** Share ratio of Works */
export type ShareRatioWorkLandscapingArgs = {
  cursor?: InputMaybe<LandscapingSrwWhereUniqueInput>;
  distinct?: InputMaybe<LandscapingSrwScalarFieldEnum>;
  orderBy?: InputMaybe<LandscapingSrwOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LandscapingSrwWhereInput>;
};


/** Share ratio of Works */
export type ShareRatioWorkMechanicalArgs = {
  cursor?: InputMaybe<MechanicalSrwWhereUniqueInput>;
  distinct?: InputMaybe<MechanicalSrwScalarFieldEnum>;
  orderBy?: InputMaybe<MechanicalSrwOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MechanicalSrwWhereInput>;
};

export interface ShareRatioWorkAvgAggregateOutputType {
  __typename?: 'ShareRatioWorkAvgAggregateOutputType';
  architectureSRW?: Maybe<Scalars['Float']['output']>;
  civilWorkSRW?: Maybe<Scalars['Float']['output']>;
  communicationSRW?: Maybe<Scalars['Float']['output']>;
  electricalSRW?: Maybe<Scalars['Float']['output']>;
  fireProtectionSRW?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  landscapingSRW?: Maybe<Scalars['Float']['output']>;
  mechanicalSRW?: Maybe<Scalars['Float']['output']>;
}

export interface ShareRatioWorkAvgOrderByAggregateInput {
  architectureSRW?: InputMaybe<SortOrder>;
  civilWorkSRW?: InputMaybe<SortOrder>;
  communicationSRW?: InputMaybe<SortOrder>;
  electricalSRW?: InputMaybe<SortOrder>;
  fireProtectionSRW?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  landscapingSRW?: InputMaybe<SortOrder>;
  mechanicalSRW?: InputMaybe<SortOrder>;
}

export interface ShareRatioWorkCountAggregateOutputType {
  __typename?: 'ShareRatioWorkCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  architectureSRW: Scalars['Int']['output'];
  civilWorkSRW: Scalars['Int']['output'];
  communicationSRW: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  electricalSRW: Scalars['Int']['output'];
  fireProtectionSRW: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  landscapingSRW: Scalars['Int']['output'];
  mechanicalSRW: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface ShareRatioWorkCountOrderByAggregateInput {
  architectureSRW?: InputMaybe<SortOrder>;
  civilWorkSRW?: InputMaybe<SortOrder>;
  communicationSRW?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  electricalSRW?: InputMaybe<SortOrder>;
  fireProtectionSRW?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  landscapingSRW?: InputMaybe<SortOrder>;
  mechanicalSRW?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ShareRatioWorkCountOutputType {
  __typename?: 'ShareRatioWorkCountOutputType';
  Project: Scalars['Int']['output'];
  architecture: Scalars['Int']['output'];
  civilWork: Scalars['Int']['output'];
  communication: Scalars['Int']['output'];
  electrical: Scalars['Int']['output'];
  fireProtection: Scalars['Int']['output'];
  landscaping: Scalars['Int']['output'];
  mechanical: Scalars['Int']['output'];
}

export interface ShareRatioWorkCreateInput {
  Project?: InputMaybe<ProjectCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  landscaping?: InputMaybe<LandscapingSrwCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkCreateManyInput {
  architectureSRW: Scalars['Float']['input'];
  civilWorkSRW: Scalars['Float']['input'];
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electricalSRW: Scalars['Float']['input'];
  fireProtectionSRW: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  landscapingSRW: Scalars['Float']['input'];
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkCreateNestedManyWithoutArchitectureInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutArchitectureInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutArchitectureInput>>>;
}

export interface ShareRatioWorkCreateNestedManyWithoutCivilWorkInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutCivilWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutCivilWorkInput>>>;
}

export interface ShareRatioWorkCreateNestedManyWithoutCommunicationInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutCommunicationInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutCommunicationInput>>>;
}

export interface ShareRatioWorkCreateNestedManyWithoutElectricalInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutElectricalInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutElectricalInput>>>;
}

export interface ShareRatioWorkCreateNestedManyWithoutFireProtectionInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutFireProtectionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutFireProtectionInput>>>;
}

export interface ShareRatioWorkCreateNestedManyWithoutLandscapingInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutLandscapingInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutLandscapingInput>>>;
}

export interface ShareRatioWorkCreateNestedManyWithoutMechanicalInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutMechanicalInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutMechanicalInput>>>;
}

export interface ShareRatioWorkCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ShareRatioWorkCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ShareRatioWorkUncheckedCreateWithoutProjectInput>;
}

export interface ShareRatioWorkCreateOrConnectWithoutArchitectureInput {
  create: ShareRatioWorkUncheckedCreateWithoutArchitectureInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkCreateOrConnectWithoutCivilWorkInput {
  create: ShareRatioWorkUncheckedCreateWithoutCivilWorkInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkCreateOrConnectWithoutCommunicationInput {
  create: ShareRatioWorkUncheckedCreateWithoutCommunicationInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkCreateOrConnectWithoutElectricalInput {
  create: ShareRatioWorkUncheckedCreateWithoutElectricalInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkCreateOrConnectWithoutFireProtectionInput {
  create: ShareRatioWorkUncheckedCreateWithoutFireProtectionInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkCreateOrConnectWithoutLandscapingInput {
  create: ShareRatioWorkUncheckedCreateWithoutLandscapingInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkCreateOrConnectWithoutMechanicalInput {
  create: ShareRatioWorkUncheckedCreateWithoutMechanicalInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkCreateOrConnectWithoutProjectInput {
  create: ShareRatioWorkUncheckedCreateWithoutProjectInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkCreateWithoutArchitectureInput {
  Project?: InputMaybe<ProjectCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  landscaping?: InputMaybe<LandscapingSrwCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkCreateWithoutCivilWorkInput {
  Project?: InputMaybe<ProjectCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  landscaping?: InputMaybe<LandscapingSrwCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkCreateWithoutCommunicationInput {
  Project?: InputMaybe<ProjectCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  landscaping?: InputMaybe<LandscapingSrwCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkCreateWithoutElectricalInput {
  Project?: InputMaybe<ProjectCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  landscaping?: InputMaybe<LandscapingSrwCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkCreateWithoutFireProtectionInput {
  Project?: InputMaybe<ProjectCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtectionSRW: Scalars['Float']['input'];
  landscaping?: InputMaybe<LandscapingSrwCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkCreateWithoutLandscapingInput {
  Project?: InputMaybe<ProjectCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkCreateWithoutMechanicalInput {
  Project?: InputMaybe<ProjectCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  landscaping?: InputMaybe<LandscapingSrwCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkCreateWithoutProjectInput {
  architecture?: InputMaybe<ArchitectureSrwCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  landscaping?: InputMaybe<LandscapingSrwCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkListRelationFilter {
  every?: InputMaybe<ShareRatioWorkWhereInput>;
  none?: InputMaybe<ShareRatioWorkWhereInput>;
  some?: InputMaybe<ShareRatioWorkWhereInput>;
}

export interface ShareRatioWorkMaxAggregateOutputType {
  __typename?: 'ShareRatioWorkMaxAggregateOutputType';
  architectureSRW?: Maybe<Scalars['Float']['output']>;
  civilWorkSRW?: Maybe<Scalars['Float']['output']>;
  communicationSRW?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  electricalSRW?: Maybe<Scalars['Float']['output']>;
  fireProtectionSRW?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  landscapingSRW?: Maybe<Scalars['Float']['output']>;
  mechanicalSRW?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ShareRatioWorkMaxOrderByAggregateInput {
  architectureSRW?: InputMaybe<SortOrder>;
  civilWorkSRW?: InputMaybe<SortOrder>;
  communicationSRW?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  electricalSRW?: InputMaybe<SortOrder>;
  fireProtectionSRW?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  landscapingSRW?: InputMaybe<SortOrder>;
  mechanicalSRW?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ShareRatioWorkMinAggregateOutputType {
  __typename?: 'ShareRatioWorkMinAggregateOutputType';
  architectureSRW?: Maybe<Scalars['Float']['output']>;
  civilWorkSRW?: Maybe<Scalars['Float']['output']>;
  communicationSRW?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  electricalSRW?: Maybe<Scalars['Float']['output']>;
  fireProtectionSRW?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  landscapingSRW?: Maybe<Scalars['Float']['output']>;
  mechanicalSRW?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface ShareRatioWorkMinOrderByAggregateInput {
  architectureSRW?: InputMaybe<SortOrder>;
  civilWorkSRW?: InputMaybe<SortOrder>;
  communicationSRW?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  electricalSRW?: InputMaybe<SortOrder>;
  fireProtectionSRW?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  landscapingSRW?: InputMaybe<SortOrder>;
  mechanicalSRW?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ShareRatioWorkOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface ShareRatioWorkOrderByWithAggregationInput {
  _avg?: InputMaybe<ShareRatioWorkAvgOrderByAggregateInput>;
  _count?: InputMaybe<ShareRatioWorkCountOrderByAggregateInput>;
  _max?: InputMaybe<ShareRatioWorkMaxOrderByAggregateInput>;
  _min?: InputMaybe<ShareRatioWorkMinOrderByAggregateInput>;
  _sum?: InputMaybe<ShareRatioWorkSumOrderByAggregateInput>;
  architectureSRW?: InputMaybe<SortOrder>;
  civilWorkSRW?: InputMaybe<SortOrder>;
  communicationSRW?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  electricalSRW?: InputMaybe<SortOrder>;
  fireProtectionSRW?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  landscapingSRW?: InputMaybe<SortOrder>;
  mechanicalSRW?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ShareRatioWorkOrderByWithRelationInput {
  Project?: InputMaybe<ProjectOrderByRelationAggregateInput>;
  architecture?: InputMaybe<ArchitectureSrwOrderByRelationAggregateInput>;
  architectureSRW?: InputMaybe<SortOrder>;
  civilWork?: InputMaybe<CivilWorkSrwOrderByRelationAggregateInput>;
  civilWorkSRW?: InputMaybe<SortOrder>;
  communication?: InputMaybe<CommunicationSrwOrderByRelationAggregateInput>;
  communicationSRW?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  electrical?: InputMaybe<ElectricalSrwOrderByRelationAggregateInput>;
  electricalSRW?: InputMaybe<SortOrder>;
  fireProtection?: InputMaybe<FireProtectionSrwOrderByRelationAggregateInput>;
  fireProtectionSRW?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  landscaping?: InputMaybe<LandscapingSrwOrderByRelationAggregateInput>;
  landscapingSRW?: InputMaybe<SortOrder>;
  mechanical?: InputMaybe<MechanicalSrwOrderByRelationAggregateInput>;
  mechanicalSRW?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface ShareRatioWorkRelationFilter {
  is?: InputMaybe<ShareRatioWorkWhereInput>;
  isNot?: InputMaybe<ShareRatioWorkWhereInput>;
}

export enum ShareRatioWorkScalarFieldEnum {
  ArchitectureSrw = 'architectureSRW',
  CivilWorkSrw = 'civilWorkSRW',
  CommunicationSrw = 'communicationSRW',
  CreatedAt = 'createdAt',
  ElectricalSrw = 'electricalSRW',
  FireProtectionSrw = 'fireProtectionSRW',
  Id = 'id',
  LandscapingSrw = 'landscapingSRW',
  MechanicalSrw = 'mechanicalSRW',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface ShareRatioWorkScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  architectureSRW?: InputMaybe<FloatFilter>;
  civilWorkSRW?: InputMaybe<FloatFilter>;
  communicationSRW?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  electricalSRW?: InputMaybe<FloatFilter>;
  fireProtectionSRW?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
  landscapingSRW?: InputMaybe<FloatFilter>;
  mechanicalSRW?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ShareRatioWorkScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereWithAggregatesInput>>>;
  architectureSRW?: InputMaybe<FloatWithAggregatesFilter>;
  civilWorkSRW?: InputMaybe<FloatWithAggregatesFilter>;
  communicationSRW?: InputMaybe<FloatWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  electricalSRW?: InputMaybe<FloatWithAggregatesFilter>;
  fireProtectionSRW?: InputMaybe<FloatWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  landscapingSRW?: InputMaybe<FloatWithAggregatesFilter>;
  mechanicalSRW?: InputMaybe<FloatWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface ShareRatioWorkSumAggregateOutputType {
  __typename?: 'ShareRatioWorkSumAggregateOutputType';
  architectureSRW?: Maybe<Scalars['Float']['output']>;
  civilWorkSRW?: Maybe<Scalars['Float']['output']>;
  communicationSRW?: Maybe<Scalars['Float']['output']>;
  electricalSRW?: Maybe<Scalars['Float']['output']>;
  fireProtectionSRW?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  landscapingSRW?: Maybe<Scalars['Float']['output']>;
  mechanicalSRW?: Maybe<Scalars['Float']['output']>;
}

export interface ShareRatioWorkSumOrderByAggregateInput {
  architectureSRW?: InputMaybe<SortOrder>;
  civilWorkSRW?: InputMaybe<SortOrder>;
  communicationSRW?: InputMaybe<SortOrder>;
  electricalSRW?: InputMaybe<SortOrder>;
  fireProtectionSRW?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  landscapingSRW?: InputMaybe<SortOrder>;
  mechanicalSRW?: InputMaybe<SortOrder>;
}

export interface ShareRatioWorkUncheckedCreateInput {
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkUncheckedCreateNestedManyWithoutArchitectureInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutArchitectureInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutArchitectureInput>>>;
}

export interface ShareRatioWorkUncheckedCreateNestedManyWithoutCivilWorkInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutCivilWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutCivilWorkInput>>>;
}

export interface ShareRatioWorkUncheckedCreateNestedManyWithoutCommunicationInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutCommunicationInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutCommunicationInput>>>;
}

export interface ShareRatioWorkUncheckedCreateNestedManyWithoutElectricalInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutElectricalInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutElectricalInput>>>;
}

export interface ShareRatioWorkUncheckedCreateNestedManyWithoutFireProtectionInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutFireProtectionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutFireProtectionInput>>>;
}

export interface ShareRatioWorkUncheckedCreateNestedManyWithoutLandscapingInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutLandscapingInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutLandscapingInput>>>;
}

export interface ShareRatioWorkUncheckedCreateNestedManyWithoutMechanicalInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutMechanicalInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutMechanicalInput>>>;
}

export interface ShareRatioWorkUncheckedCreateWithoutArchitectureInput {
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkUncheckedCreateWithoutCivilWorkInput {
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkUncheckedCreateWithoutCommunicationInput {
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkUncheckedCreateWithoutElectricalInput {
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkUncheckedCreateWithoutFireProtectionInput {
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtectionSRW: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkUncheckedCreateWithoutLandscapingInput {
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkUncheckedCreateWithoutMechanicalInput {
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkUncheckedCreateWithoutProjectInput {
  architecture?: InputMaybe<ArchitectureSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  architectureSRW: Scalars['Float']['input'];
  civilWork?: InputMaybe<CivilWorkSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  civilWorkSRW: Scalars['Float']['input'];
  communication?: InputMaybe<CommunicationSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  communicationSRW: Scalars['Float']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  electrical?: InputMaybe<ElectricalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  electricalSRW: Scalars['Float']['input'];
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  fireProtectionSRW: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  landscapingSRW: Scalars['Float']['input'];
  mechanical?: InputMaybe<MechanicalSrwUncheckedCreateNestedManyWithoutShareRatioWorkInput>;
  mechanicalSRW: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface ShareRatioWorkUncheckedUpdateInput {
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUncheckedUpdateManyInput {
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUncheckedUpdateManyWithoutArchitectureNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutArchitectureInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutArchitectureInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutArchitectureInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutArchitectureInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutArchitectureInput>>>;
}

export interface ShareRatioWorkUncheckedUpdateManyWithoutCivilWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutCivilWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutCivilWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutCivilWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutCivilWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutCivilWorkInput>>>;
}

export interface ShareRatioWorkUncheckedUpdateManyWithoutCommunicationNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutCommunicationInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutCommunicationInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutCommunicationInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutCommunicationInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutCommunicationInput>>>;
}

export interface ShareRatioWorkUncheckedUpdateManyWithoutElectricalNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutElectricalInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutElectricalInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutElectricalInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutElectricalInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutElectricalInput>>>;
}

export interface ShareRatioWorkUncheckedUpdateManyWithoutFireProtectionNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutFireProtectionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutFireProtectionInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutFireProtectionInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutFireProtectionInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutFireProtectionInput>>>;
}

export interface ShareRatioWorkUncheckedUpdateManyWithoutLandscapingNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutLandscapingInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutLandscapingInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutLandscapingInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutLandscapingInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutLandscapingInput>>>;
}

export interface ShareRatioWorkUncheckedUpdateManyWithoutMechanicalNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutMechanicalInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutMechanicalInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutMechanicalInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutMechanicalInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutMechanicalInput>>>;
}

export interface ShareRatioWorkUncheckedUpdateManyWithoutShareRatioWorkInput {
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUncheckedUpdateWithoutArchitectureInput {
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUncheckedUpdateWithoutCivilWorkInput {
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUncheckedUpdateWithoutCommunicationInput {
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUncheckedUpdateWithoutElectricalInput {
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUncheckedUpdateWithoutFireProtectionInput {
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUncheckedUpdateWithoutLandscapingInput {
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUncheckedUpdateWithoutMechanicalInput {
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUncheckedUpdateWithoutProjectInput {
  architecture?: InputMaybe<ArchitectureSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUncheckedUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUpdateInput {
  Project?: InputMaybe<ProjectUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUpdateManyMutationInput {
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUpdateManyWithWhereWithoutArchitectureInput {
  data: ShareRatioWorkUncheckedUpdateManyWithoutShareRatioWorkInput;
  where: ShareRatioWorkScalarWhereInput;
}

export interface ShareRatioWorkUpdateManyWithWhereWithoutCivilWorkInput {
  data: ShareRatioWorkUncheckedUpdateManyWithoutShareRatioWorkInput;
  where: ShareRatioWorkScalarWhereInput;
}

export interface ShareRatioWorkUpdateManyWithWhereWithoutCommunicationInput {
  data: ShareRatioWorkUncheckedUpdateManyWithoutShareRatioWorkInput;
  where: ShareRatioWorkScalarWhereInput;
}

export interface ShareRatioWorkUpdateManyWithWhereWithoutElectricalInput {
  data: ShareRatioWorkUncheckedUpdateManyWithoutShareRatioWorkInput;
  where: ShareRatioWorkScalarWhereInput;
}

export interface ShareRatioWorkUpdateManyWithWhereWithoutFireProtectionInput {
  data: ShareRatioWorkUncheckedUpdateManyWithoutShareRatioWorkInput;
  where: ShareRatioWorkScalarWhereInput;
}

export interface ShareRatioWorkUpdateManyWithWhereWithoutLandscapingInput {
  data: ShareRatioWorkUncheckedUpdateManyWithoutShareRatioWorkInput;
  where: ShareRatioWorkScalarWhereInput;
}

export interface ShareRatioWorkUpdateManyWithWhereWithoutMechanicalInput {
  data: ShareRatioWorkUncheckedUpdateManyWithoutShareRatioWorkInput;
  where: ShareRatioWorkScalarWhereInput;
}

export interface ShareRatioWorkUpdateManyWithoutArchitectureNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutArchitectureInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutArchitectureInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutArchitectureInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutArchitectureInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutArchitectureInput>>>;
}

export interface ShareRatioWorkUpdateManyWithoutCivilWorkNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutCivilWorkInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutCivilWorkInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutCivilWorkInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutCivilWorkInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutCivilWorkInput>>>;
}

export interface ShareRatioWorkUpdateManyWithoutCommunicationNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutCommunicationInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutCommunicationInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutCommunicationInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutCommunicationInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutCommunicationInput>>>;
}

export interface ShareRatioWorkUpdateManyWithoutElectricalNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutElectricalInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutElectricalInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutElectricalInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutElectricalInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutElectricalInput>>>;
}

export interface ShareRatioWorkUpdateManyWithoutFireProtectionNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutFireProtectionInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutFireProtectionInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutFireProtectionInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutFireProtectionInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutFireProtectionInput>>>;
}

export interface ShareRatioWorkUpdateManyWithoutLandscapingNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutLandscapingInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutLandscapingInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutLandscapingInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutLandscapingInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutLandscapingInput>>>;
}

export interface ShareRatioWorkUpdateManyWithoutMechanicalNestedInput {
  connect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateOrConnectWithoutMechanicalInput>>>;
  create?: InputMaybe<Array<InputMaybe<ShareRatioWorkCreateWithoutMechanicalInput>>>;
  delete?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateWithWhereUniqueWithoutMechanicalInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpdateManyWithWhereWithoutMechanicalInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<ShareRatioWorkUpsertWithWhereUniqueWithoutMechanicalInput>>>;
}

export interface ShareRatioWorkUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<ShareRatioWorkWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ShareRatioWorkCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ShareRatioWorkUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ShareRatioWorkUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<ShareRatioWorkUpsertWithoutProjectInput>;
}

export interface ShareRatioWorkUpdateWithWhereUniqueWithoutArchitectureInput {
  data: ShareRatioWorkUncheckedUpdateWithoutArchitectureInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpdateWithWhereUniqueWithoutCivilWorkInput {
  data: ShareRatioWorkUncheckedUpdateWithoutCivilWorkInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpdateWithWhereUniqueWithoutCommunicationInput {
  data: ShareRatioWorkUncheckedUpdateWithoutCommunicationInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpdateWithWhereUniqueWithoutElectricalInput {
  data: ShareRatioWorkUncheckedUpdateWithoutElectricalInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpdateWithWhereUniqueWithoutFireProtectionInput {
  data: ShareRatioWorkUncheckedUpdateWithoutFireProtectionInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpdateWithWhereUniqueWithoutLandscapingInput {
  data: ShareRatioWorkUncheckedUpdateWithoutLandscapingInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpdateWithWhereUniqueWithoutMechanicalInput {
  data: ShareRatioWorkUncheckedUpdateWithoutMechanicalInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpdateWithoutArchitectureInput {
  Project?: InputMaybe<ProjectUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUpdateWithoutCivilWorkInput {
  Project?: InputMaybe<ProjectUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUpdateWithoutCommunicationInput {
  Project?: InputMaybe<ProjectUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUpdateWithoutElectricalInput {
  Project?: InputMaybe<ProjectUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUpdateWithoutFireProtectionInput {
  Project?: InputMaybe<ProjectUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUpdateWithoutLandscapingInput {
  Project?: InputMaybe<ProjectUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUpdateWithoutMechanicalInput {
  Project?: InputMaybe<ProjectUpdateManyWithoutShareRatioWorkNestedInput>;
  architecture?: InputMaybe<ArchitectureSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUpdateWithoutProjectInput {
  architecture?: InputMaybe<ArchitectureSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  architectureSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  civilWork?: InputMaybe<CivilWorkSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  civilWorkSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communication?: InputMaybe<CommunicationSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  communicationSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  electrical?: InputMaybe<ElectricalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  electricalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fireProtection?: InputMaybe<FireProtectionSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  fireProtectionSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  landscaping?: InputMaybe<LandscapingSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  landscapingSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  mechanical?: InputMaybe<MechanicalSrwUpdateManyWithoutShareRatioWorkNestedInput>;
  mechanicalSRW?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface ShareRatioWorkUpsertWithWhereUniqueWithoutArchitectureInput {
  create: ShareRatioWorkUncheckedCreateWithoutArchitectureInput;
  update: ShareRatioWorkUncheckedUpdateWithoutArchitectureInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpsertWithWhereUniqueWithoutCivilWorkInput {
  create: ShareRatioWorkUncheckedCreateWithoutCivilWorkInput;
  update: ShareRatioWorkUncheckedUpdateWithoutCivilWorkInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpsertWithWhereUniqueWithoutCommunicationInput {
  create: ShareRatioWorkUncheckedCreateWithoutCommunicationInput;
  update: ShareRatioWorkUncheckedUpdateWithoutCommunicationInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpsertWithWhereUniqueWithoutElectricalInput {
  create: ShareRatioWorkUncheckedCreateWithoutElectricalInput;
  update: ShareRatioWorkUncheckedUpdateWithoutElectricalInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpsertWithWhereUniqueWithoutFireProtectionInput {
  create: ShareRatioWorkUncheckedCreateWithoutFireProtectionInput;
  update: ShareRatioWorkUncheckedUpdateWithoutFireProtectionInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpsertWithWhereUniqueWithoutLandscapingInput {
  create: ShareRatioWorkUncheckedCreateWithoutLandscapingInput;
  update: ShareRatioWorkUncheckedUpdateWithoutLandscapingInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpsertWithWhereUniqueWithoutMechanicalInput {
  create: ShareRatioWorkUncheckedCreateWithoutMechanicalInput;
  update: ShareRatioWorkUncheckedUpdateWithoutMechanicalInput;
  where: ShareRatioWorkWhereUniqueInput;
}

export interface ShareRatioWorkUpsertWithoutProjectInput {
  create: ShareRatioWorkUncheckedCreateWithoutProjectInput;
  update: ShareRatioWorkUncheckedUpdateWithoutProjectInput;
}

export interface ShareRatioWorkWhereInput {
  AND?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<ShareRatioWorkWhereInput>>>;
  Project?: InputMaybe<ProjectListRelationFilter>;
  architecture?: InputMaybe<ArchitectureSrwListRelationFilter>;
  architectureSRW?: InputMaybe<FloatFilter>;
  civilWork?: InputMaybe<CivilWorkSrwListRelationFilter>;
  civilWorkSRW?: InputMaybe<FloatFilter>;
  communication?: InputMaybe<CommunicationSrwListRelationFilter>;
  communicationSRW?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  electrical?: InputMaybe<ElectricalSrwListRelationFilter>;
  electricalSRW?: InputMaybe<FloatFilter>;
  fireProtection?: InputMaybe<FireProtectionSrwListRelationFilter>;
  fireProtectionSRW?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
  landscaping?: InputMaybe<LandscapingSrwListRelationFilter>;
  landscapingSRW?: InputMaybe<FloatFilter>;
  mechanical?: InputMaybe<MechanicalSrwListRelationFilter>;
  mechanicalSRW?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface ShareRatioWorkWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** Shared message */
export interface SharedMessage {
  __typename?: 'SharedMessage';
  error: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  sharedProjectToken?: Maybe<SharedProjectToken>;
}

/** Shared project */
export interface SharedProject {
  __typename?: 'SharedProject';
  /** Shared tokens */
  SharedProjectToken: Array<SharedProjectToken>;
  _count: SharedProjectCountOutputType;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** Customer */
  customer?: Maybe<Customer>;
  /** CustomerID */
  customerId?: Maybe<Scalars['Int']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** isFavorite */
  isFavorite: Scalars['Boolean']['output'];
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['Int']['output']>;
  /** permission */
  permission: ProjectPermission;
  /** Project */
  project: Project;
  /** ProjectID */
  projectId: Scalars['Int']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique ID */
  uuid: Scalars['String']['output'];
}


/** Shared project */
export type SharedProjectSharedProjectTokenArgs = {
  cursor?: InputMaybe<SharedProjectTokenWhereUniqueInput>;
  distinct?: InputMaybe<SharedProjectTokenScalarFieldEnum>;
  orderBy?: InputMaybe<SharedProjectTokenOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SharedProjectTokenWhereInput>;
};

export interface SharedProjectAvgAggregateOutputType {
  __typename?: 'SharedProjectAvgAggregateOutputType';
  customerId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  memberId?: Maybe<Scalars['Float']['output']>;
  projectId?: Maybe<Scalars['Float']['output']>;
}

export interface SharedProjectAvgOrderByAggregateInput {
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface SharedProjectByMember {
  __typename?: 'SharedProjectByMember';
  count: Scalars['Int']['output'];
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  /** ID String CUID */
  id: Scalars['Int']['output'];
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shareEmail: Scalars['String']['output'];
  sharedAt: Scalars['DateTime']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}

export interface SharedProjectByMemberAvgAggregateOutputType {
  __typename?: 'SharedProjectByMemberAvgAggregateOutputType';
  count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  memberId?: Maybe<Scalars['Float']['output']>;
}

export interface SharedProjectByMemberAvgOrderByAggregateInput {
  count?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface SharedProjectByMemberCountAggregateOutputType {
  __typename?: 'SharedProjectByMemberCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  shareEmail: Scalars['Int']['output'];
  sharedAt: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface SharedProjectByMemberCountOrderByAggregateInput {
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shareEmail?: InputMaybe<SortOrder>;
  sharedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SharedProjectByMemberCreateInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  member?: InputMaybe<MemberCreateNestedOneWithoutSharedProjectByMemberInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  shareEmail: Scalars['String']['input'];
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SharedProjectByMemberCreateManyInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memberId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shareEmail: Scalars['String']['input'];
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SharedProjectByMemberCreateManyMemberInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shareEmail: Scalars['String']['input'];
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SharedProjectByMemberCreateManyMemberInputEnvelope {
  data: SharedProjectByMemberCreateManyMemberInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SharedProjectByMemberCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectByMemberCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectByMemberCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<SharedProjectByMemberCreateManyMemberInputEnvelope>;
}

export interface SharedProjectByMemberCreateOrConnectWithoutMemberInput {
  create: SharedProjectByMemberUncheckedCreateWithoutMemberInput;
  where: SharedProjectByMemberWhereUniqueInput;
}

export interface SharedProjectByMemberCreateWithoutMemberInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  shareEmail: Scalars['String']['input'];
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SharedProjectByMemberEmailShareEmailCompoundUniqueInput {
  email: Scalars['String']['input'];
  shareEmail: Scalars['String']['input'];
}

export interface SharedProjectByMemberListRelationFilter {
  every?: InputMaybe<SharedProjectByMemberWhereInput>;
  none?: InputMaybe<SharedProjectByMemberWhereInput>;
  some?: InputMaybe<SharedProjectByMemberWhereInput>;
}

export interface SharedProjectByMemberMaxAggregateOutputType {
  __typename?: 'SharedProjectByMemberMaxAggregateOutputType';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shareEmail?: Maybe<Scalars['String']['output']>;
  sharedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface SharedProjectByMemberMaxOrderByAggregateInput {
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shareEmail?: InputMaybe<SortOrder>;
  sharedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SharedProjectByMemberMinAggregateOutputType {
  __typename?: 'SharedProjectByMemberMinAggregateOutputType';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shareEmail?: Maybe<Scalars['String']['output']>;
  sharedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface SharedProjectByMemberMinOrderByAggregateInput {
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  shareEmail?: InputMaybe<SortOrder>;
  sharedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SharedProjectByMemberOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface SharedProjectByMemberOrderByWithAggregationInput {
  _avg?: InputMaybe<SharedProjectByMemberAvgOrderByAggregateInput>;
  _count?: InputMaybe<SharedProjectByMemberCountOrderByAggregateInput>;
  _max?: InputMaybe<SharedProjectByMemberMaxOrderByAggregateInput>;
  _min?: InputMaybe<SharedProjectByMemberMinOrderByAggregateInput>;
  _sum?: InputMaybe<SharedProjectByMemberSumOrderByAggregateInput>;
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrderInput>;
  shareEmail?: InputMaybe<SortOrder>;
  sharedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SharedProjectByMemberOrderByWithRelationInput {
  count?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberOrderByWithRelationInput>;
  memberId?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrderInput>;
  shareEmail?: InputMaybe<SortOrder>;
  sharedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export enum SharedProjectByMemberScalarFieldEnum {
  Count = 'count',
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  MemberId = 'memberId',
  Name = 'name',
  ShareEmail = 'shareEmail',
  SharedAt = 'sharedAt',
  UpdatedAt = 'updatedAt'
}

export interface SharedProjectByMemberScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarWhereInput>>>;
  count?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  memberId?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  shareEmail?: InputMaybe<StringFilter>;
  sharedAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface SharedProjectByMemberScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarWhereWithAggregatesInput>>>;
  count?: InputMaybe<IntWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  memberId?: InputMaybe<IntNullableWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  shareEmail?: InputMaybe<StringWithAggregatesFilter>;
  sharedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface SharedProjectByMemberSumAggregateOutputType {
  __typename?: 'SharedProjectByMemberSumAggregateOutputType';
  count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
}

export interface SharedProjectByMemberSumOrderByAggregateInput {
  count?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
}

export interface SharedProjectByMemberUncheckedCreateInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memberId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shareEmail: Scalars['String']['input'];
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SharedProjectByMemberUncheckedCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectByMemberCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectByMemberCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<SharedProjectByMemberCreateManyMemberInputEnvelope>;
}

export interface SharedProjectByMemberUncheckedCreateWithoutMemberInput {
  count?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shareEmail: Scalars['String']['input'];
  sharedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SharedProjectByMemberUncheckedUpdateInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SharedProjectByMemberUncheckedUpdateManyInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SharedProjectByMemberUncheckedUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectByMemberCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectByMemberCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<SharedProjectByMemberCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SharedProjectByMemberUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SharedProjectByMemberUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SharedProjectByMemberUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface SharedProjectByMemberUncheckedUpdateManyWithoutSharedProjectByMemberInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SharedProjectByMemberUncheckedUpdateWithoutMemberInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SharedProjectByMemberUpdateInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateOneWithoutSharedProjectByMemberNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SharedProjectByMemberUpdateManyMutationInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SharedProjectByMemberUpdateManyWithWhereWithoutMemberInput {
  data: SharedProjectByMemberUncheckedUpdateManyWithoutSharedProjectByMemberInput;
  where: SharedProjectByMemberScalarWhereInput;
}

export interface SharedProjectByMemberUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectByMemberCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectByMemberCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<SharedProjectByMemberCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SharedProjectByMemberScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SharedProjectByMemberUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SharedProjectByMemberUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SharedProjectByMemberUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface SharedProjectByMemberUpdateWithWhereUniqueWithoutMemberInput {
  data: SharedProjectByMemberUncheckedUpdateWithoutMemberInput;
  where: SharedProjectByMemberWhereUniqueInput;
}

export interface SharedProjectByMemberUpdateWithoutMemberInput {
  count?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shareEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  sharedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SharedProjectByMemberUpsertWithWhereUniqueWithoutMemberInput {
  create: SharedProjectByMemberUncheckedCreateWithoutMemberInput;
  update: SharedProjectByMemberUncheckedUpdateWithoutMemberInput;
  where: SharedProjectByMemberWhereUniqueInput;
}

export interface SharedProjectByMemberWhereInput {
  AND?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SharedProjectByMemberWhereInput>>>;
  count?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  member?: InputMaybe<MemberWhereInput>;
  memberId?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  shareEmail?: InputMaybe<StringFilter>;
  sharedAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface SharedProjectByMemberWhereUniqueInput {
  email_shareEmail?: InputMaybe<SharedProjectByMemberEmailShareEmailCompoundUniqueInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
}

export interface SharedProjectCountAggregateOutputType {
  __typename?: 'SharedProjectCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isFavorite: Scalars['Int']['output'];
  memberId: Scalars['Int']['output'];
  permission: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface SharedProjectCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isFavorite?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  permission?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface SharedProjectCountOutputType {
  __typename?: 'SharedProjectCountOutputType';
  SharedProjectToken: Scalars['Int']['output'];
}

export interface SharedProjectCreateInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenCreateNestedManyWithoutSharedProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutSharedProjectInput>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  member?: InputMaybe<MemberCreateNestedOneWithoutSharedProjectInput>;
  permission?: InputMaybe<ProjectPermission>;
  project: ProjectCreateNestedOneWithoutSharedProjectInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectCreateManyCustomerInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<ProjectPermission>;
  projectId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectCreateManyCustomerInputEnvelope {
  data: SharedProjectCreateManyCustomerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SharedProjectCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<ProjectPermission>;
  projectId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectCreateManyMemberInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  permission?: InputMaybe<ProjectPermission>;
  projectId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectCreateManyMemberInputEnvelope {
  data: SharedProjectCreateManyMemberInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SharedProjectCreateManyProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<ProjectPermission>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectCreateManyProjectInputEnvelope {
  data: SharedProjectCreateManyProjectInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SharedProjectCreateNestedManyWithoutCustomerInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyCustomerInputEnvelope>;
}

export interface SharedProjectCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyMemberInputEnvelope>;
}

export interface SharedProjectCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyProjectInputEnvelope>;
}

export interface SharedProjectCreateNestedOneWithoutSharedProjectTokenInput {
  connect?: InputMaybe<SharedProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SharedProjectCreateOrConnectWithoutSharedProjectTokenInput>;
  create?: InputMaybe<SharedProjectUncheckedCreateWithoutSharedProjectTokenInput>;
}

export interface SharedProjectCreateOrConnectWithoutCustomerInput {
  create: SharedProjectUncheckedCreateWithoutCustomerInput;
  where: SharedProjectWhereUniqueInput;
}

export interface SharedProjectCreateOrConnectWithoutMemberInput {
  create: SharedProjectUncheckedCreateWithoutMemberInput;
  where: SharedProjectWhereUniqueInput;
}

export interface SharedProjectCreateOrConnectWithoutProjectInput {
  create: SharedProjectUncheckedCreateWithoutProjectInput;
  where: SharedProjectWhereUniqueInput;
}

export interface SharedProjectCreateOrConnectWithoutSharedProjectTokenInput {
  create: SharedProjectUncheckedCreateWithoutSharedProjectTokenInput;
  where: SharedProjectWhereUniqueInput;
}

export interface SharedProjectCreateWithoutCustomerInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenCreateNestedManyWithoutSharedProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  member?: InputMaybe<MemberCreateNestedOneWithoutSharedProjectInput>;
  permission?: InputMaybe<ProjectPermission>;
  project: ProjectCreateNestedOneWithoutSharedProjectInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectCreateWithoutMemberInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenCreateNestedManyWithoutSharedProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutSharedProjectInput>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  permission?: InputMaybe<ProjectPermission>;
  project: ProjectCreateNestedOneWithoutSharedProjectInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectCreateWithoutProjectInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenCreateNestedManyWithoutSharedProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutSharedProjectInput>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  member?: InputMaybe<MemberCreateNestedOneWithoutSharedProjectInput>;
  permission?: InputMaybe<ProjectPermission>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectCreateWithoutSharedProjectTokenInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutSharedProjectInput>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  member?: InputMaybe<MemberCreateNestedOneWithoutSharedProjectInput>;
  permission?: InputMaybe<ProjectPermission>;
  project: ProjectCreateNestedOneWithoutSharedProjectInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectListRelationFilter {
  every?: InputMaybe<SharedProjectWhereInput>;
  none?: InputMaybe<SharedProjectWhereInput>;
  some?: InputMaybe<SharedProjectWhereInput>;
}

export interface SharedProjectMaxAggregateOutputType {
  __typename?: 'SharedProjectMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  permission?: Maybe<ProjectPermission>;
  projectId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface SharedProjectMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isFavorite?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  permission?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface SharedProjectMinAggregateOutputType {
  __typename?: 'SharedProjectMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  permission?: Maybe<ProjectPermission>;
  projectId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface SharedProjectMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isFavorite?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  permission?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface SharedProjectOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface SharedProjectOrderByWithAggregationInput {
  _avg?: InputMaybe<SharedProjectAvgOrderByAggregateInput>;
  _count?: InputMaybe<SharedProjectCountOrderByAggregateInput>;
  _max?: InputMaybe<SharedProjectMaxOrderByAggregateInput>;
  _min?: InputMaybe<SharedProjectMinOrderByAggregateInput>;
  _sum?: InputMaybe<SharedProjectSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isFavorite?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrderInput>;
  permission?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface SharedProjectOrderByWithRelationInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isFavorite?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberOrderByWithRelationInput>;
  memberId?: InputMaybe<SortOrderInput>;
  permission?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface SharedProjectRelationFilter {
  is?: InputMaybe<SharedProjectWhereInput>;
  isNot?: InputMaybe<SharedProjectWhereInput>;
}

export enum SharedProjectScalarFieldEnum {
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  IsFavorite = 'isFavorite',
  MemberId = 'memberId',
  Permission = 'permission',
  ProjectId = 'projectId',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface SharedProjectScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFavorite?: InputMaybe<BoolFilter>;
  memberId?: InputMaybe<IntNullableFilter>;
  permission?: InputMaybe<EnumProjectPermissionFilter>;
  projectId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface SharedProjectScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  customerId?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  isFavorite?: InputMaybe<BoolWithAggregatesFilter>;
  memberId?: InputMaybe<IntNullableWithAggregatesFilter>;
  permission?: InputMaybe<EnumProjectPermissionWithAggregatesFilter>;
  projectId?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface SharedProjectSumAggregateOutputType {
  __typename?: 'SharedProjectSumAggregateOutputType';
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
}

export interface SharedProjectSumOrderByAggregateInput {
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memberId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

/** Shared project token */
export interface SharedProjectToken {
  __typename?: 'SharedProjectToken';
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** email */
  email: Scalars['String']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Shared project */
  sharedProject: SharedProject;
  /** Shared project ID */
  sharedProjectId: Scalars['Int']['output'];
  /** token */
  token: Scalars['String']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique ID */
  uuid: Scalars['String']['output'];
}

export interface SharedProjectTokenAvgAggregateOutputType {
  __typename?: 'SharedProjectTokenAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  sharedProjectId?: Maybe<Scalars['Float']['output']>;
}

export interface SharedProjectTokenAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  sharedProjectId?: InputMaybe<SortOrder>;
}

export interface SharedProjectTokenCountAggregateOutputType {
  __typename?: 'SharedProjectTokenCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  sharedProjectId: Scalars['Int']['output'];
  token: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface SharedProjectTokenCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sharedProjectId?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface SharedProjectTokenCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  sharedProject: SharedProjectCreateNestedOneWithoutSharedProjectTokenInput;
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectTokenCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  sharedProjectId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectTokenCreateManySharedProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectTokenCreateManySharedProjectInputEnvelope {
  data: SharedProjectTokenCreateManySharedProjectInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SharedProjectTokenCreateNestedManyWithoutSharedProjectInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectTokenCreateOrConnectWithoutSharedProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectTokenCreateWithoutSharedProjectInput>>>;
  createMany?: InputMaybe<SharedProjectTokenCreateManySharedProjectInputEnvelope>;
}

export interface SharedProjectTokenCreateOrConnectWithoutSharedProjectInput {
  create: SharedProjectTokenUncheckedCreateWithoutSharedProjectInput;
  where: SharedProjectTokenWhereUniqueInput;
}

export interface SharedProjectTokenCreateWithoutSharedProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectTokenListRelationFilter {
  every?: InputMaybe<SharedProjectTokenWhereInput>;
  none?: InputMaybe<SharedProjectTokenWhereInput>;
  some?: InputMaybe<SharedProjectTokenWhereInput>;
}

export interface SharedProjectTokenMaxAggregateOutputType {
  __typename?: 'SharedProjectTokenMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sharedProjectId?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface SharedProjectTokenMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sharedProjectId?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface SharedProjectTokenMinAggregateOutputType {
  __typename?: 'SharedProjectTokenMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sharedProjectId?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface SharedProjectTokenMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sharedProjectId?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface SharedProjectTokenOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface SharedProjectTokenOrderByWithAggregationInput {
  _avg?: InputMaybe<SharedProjectTokenAvgOrderByAggregateInput>;
  _count?: InputMaybe<SharedProjectTokenCountOrderByAggregateInput>;
  _max?: InputMaybe<SharedProjectTokenMaxOrderByAggregateInput>;
  _min?: InputMaybe<SharedProjectTokenMinOrderByAggregateInput>;
  _sum?: InputMaybe<SharedProjectTokenSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sharedProjectId?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface SharedProjectTokenOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sharedProject?: InputMaybe<SharedProjectOrderByWithRelationInput>;
  sharedProjectId?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export enum SharedProjectTokenScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  SharedProjectId = 'sharedProjectId',
  Token = 'token',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface SharedProjectTokenScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<SharedProjectTokenScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SharedProjectTokenScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SharedProjectTokenScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  sharedProjectId?: InputMaybe<IntFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface SharedProjectTokenScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<SharedProjectTokenScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SharedProjectTokenScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SharedProjectTokenScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  sharedProjectId?: InputMaybe<IntWithAggregatesFilter>;
  token?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface SharedProjectTokenSumAggregateOutputType {
  __typename?: 'SharedProjectTokenSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  sharedProjectId?: Maybe<Scalars['Int']['output']>;
}

export interface SharedProjectTokenSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  sharedProjectId?: InputMaybe<SortOrder>;
}

/** 프로젝트 공유 토큰 확인 후 Payload Data */
export interface SharedProjectTokenType {
  __typename?: 'SharedProjectTokenType';
  /** Shared member email */
  email: Scalars['EmailAddress']['output'];
  /** Project UUID */
  projectId: Scalars['String']['output'];
  /** Shared project title */
  title: Scalars['String']['output'];
}

export interface SharedProjectTokenUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  sharedProjectId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectTokenUncheckedCreateNestedManyWithoutSharedProjectInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectTokenCreateOrConnectWithoutSharedProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectTokenCreateWithoutSharedProjectInput>>>;
  createMany?: InputMaybe<SharedProjectTokenCreateManySharedProjectInputEnvelope>;
}

export interface SharedProjectTokenUncheckedCreateWithoutSharedProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectTokenUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  sharedProjectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectTokenUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  sharedProjectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectTokenUncheckedUpdateManyWithoutSharedProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectTokenCreateOrConnectWithoutSharedProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectTokenCreateWithoutSharedProjectInput>>>;
  createMany?: InputMaybe<SharedProjectTokenCreateManySharedProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SharedProjectTokenScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SharedProjectTokenUpdateWithWhereUniqueWithoutSharedProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SharedProjectTokenUpdateManyWithWhereWithoutSharedProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SharedProjectTokenUpsertWithWhereUniqueWithoutSharedProjectInput>>>;
}

export interface SharedProjectTokenUncheckedUpdateManyWithoutSharedProjectTokenInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectTokenUncheckedUpdateWithoutSharedProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectTokenUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  sharedProject?: InputMaybe<SharedProjectUpdateOneRequiredWithoutSharedProjectTokenNestedInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectTokenUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectTokenUpdateManyWithWhereWithoutSharedProjectInput {
  data: SharedProjectTokenUncheckedUpdateManyWithoutSharedProjectTokenInput;
  where: SharedProjectTokenScalarWhereInput;
}

export interface SharedProjectTokenUpdateManyWithoutSharedProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectTokenCreateOrConnectWithoutSharedProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectTokenCreateWithoutSharedProjectInput>>>;
  createMany?: InputMaybe<SharedProjectTokenCreateManySharedProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SharedProjectTokenScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SharedProjectTokenUpdateWithWhereUniqueWithoutSharedProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SharedProjectTokenUpdateManyWithWhereWithoutSharedProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SharedProjectTokenUpsertWithWhereUniqueWithoutSharedProjectInput>>>;
}

export interface SharedProjectTokenUpdateWithWhereUniqueWithoutSharedProjectInput {
  data: SharedProjectTokenUncheckedUpdateWithoutSharedProjectInput;
  where: SharedProjectTokenWhereUniqueInput;
}

export interface SharedProjectTokenUpdateWithoutSharedProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectTokenUpsertWithWhereUniqueWithoutSharedProjectInput {
  create: SharedProjectTokenUncheckedCreateWithoutSharedProjectInput;
  update: SharedProjectTokenUncheckedUpdateWithoutSharedProjectInput;
  where: SharedProjectTokenWhereUniqueInput;
}

export interface SharedProjectTokenWhereInput {
  AND?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SharedProjectTokenWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  sharedProject?: InputMaybe<SharedProjectWhereInput>;
  sharedProjectId?: InputMaybe<IntFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface SharedProjectTokenWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectUncheckedCreateInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUncheckedCreateNestedManyWithoutSharedProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<ProjectPermission>;
  projectId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectUncheckedCreateNestedManyWithoutCustomerInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyCustomerInputEnvelope>;
}

export interface SharedProjectUncheckedCreateNestedManyWithoutMemberInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyMemberInputEnvelope>;
}

export interface SharedProjectUncheckedCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyProjectInputEnvelope>;
}

export interface SharedProjectUncheckedCreateWithoutCustomerInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUncheckedCreateNestedManyWithoutSharedProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<ProjectPermission>;
  projectId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectUncheckedCreateWithoutMemberInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUncheckedCreateNestedManyWithoutSharedProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  permission?: InputMaybe<ProjectPermission>;
  projectId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectUncheckedCreateWithoutProjectInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUncheckedCreateNestedManyWithoutSharedProjectInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<ProjectPermission>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectUncheckedCreateWithoutSharedProjectTokenInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<ProjectPermission>;
  projectId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface SharedProjectUncheckedUpdateInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUncheckedUpdateManyWithoutSharedProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUncheckedUpdateManyWithoutCustomerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SharedProjectUpdateWithWhereUniqueWithoutCustomerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SharedProjectUpdateManyWithWhereWithoutCustomerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SharedProjectUpsertWithWhereUniqueWithoutCustomerInput>>>;
}

export interface SharedProjectUncheckedUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SharedProjectUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SharedProjectUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SharedProjectUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface SharedProjectUncheckedUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SharedProjectUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SharedProjectUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SharedProjectUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface SharedProjectUncheckedUpdateManyWithoutSharedProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUncheckedUpdateWithoutCustomerInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUncheckedUpdateManyWithoutSharedProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUncheckedUpdateWithoutMemberInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUncheckedUpdateManyWithoutSharedProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUncheckedUpdateWithoutProjectInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUncheckedUpdateManyWithoutSharedProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUncheckedUpdateWithoutSharedProjectTokenInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  memberId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUpdateInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUpdateManyWithoutSharedProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutSharedProjectNestedInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateOneWithoutSharedProjectNestedInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutSharedProjectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUpdateManyWithWhereWithoutCustomerInput {
  data: SharedProjectUncheckedUpdateManyWithoutSharedProjectInput;
  where: SharedProjectScalarWhereInput;
}

export interface SharedProjectUpdateManyWithWhereWithoutMemberInput {
  data: SharedProjectUncheckedUpdateManyWithoutSharedProjectInput;
  where: SharedProjectScalarWhereInput;
}

export interface SharedProjectUpdateManyWithWhereWithoutProjectInput {
  data: SharedProjectUncheckedUpdateManyWithoutSharedProjectInput;
  where: SharedProjectScalarWhereInput;
}

export interface SharedProjectUpdateManyWithoutCustomerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SharedProjectUpdateWithWhereUniqueWithoutCustomerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SharedProjectUpdateManyWithWhereWithoutCustomerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SharedProjectUpsertWithWhereUniqueWithoutCustomerInput>>>;
}

export interface SharedProjectUpdateManyWithoutMemberNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutMemberInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutMemberInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyMemberInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SharedProjectUpdateWithWhereUniqueWithoutMemberInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SharedProjectUpdateManyWithWhereWithoutMemberInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SharedProjectUpsertWithWhereUniqueWithoutMemberInput>>>;
}

export interface SharedProjectUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SharedProjectCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<SharedProjectCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<SharedProjectCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SharedProjectScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SharedProjectWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SharedProjectUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SharedProjectUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SharedProjectUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface SharedProjectUpdateOneRequiredWithoutSharedProjectTokenNestedInput {
  connect?: InputMaybe<SharedProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SharedProjectCreateOrConnectWithoutSharedProjectTokenInput>;
  create?: InputMaybe<SharedProjectUncheckedCreateWithoutSharedProjectTokenInput>;
  update?: InputMaybe<SharedProjectUncheckedUpdateWithoutSharedProjectTokenInput>;
  upsert?: InputMaybe<SharedProjectUpsertWithoutSharedProjectTokenInput>;
}

export interface SharedProjectUpdateWithWhereUniqueWithoutCustomerInput {
  data: SharedProjectUncheckedUpdateWithoutCustomerInput;
  where: SharedProjectWhereUniqueInput;
}

export interface SharedProjectUpdateWithWhereUniqueWithoutMemberInput {
  data: SharedProjectUncheckedUpdateWithoutMemberInput;
  where: SharedProjectWhereUniqueInput;
}

export interface SharedProjectUpdateWithWhereUniqueWithoutProjectInput {
  data: SharedProjectUncheckedUpdateWithoutProjectInput;
  where: SharedProjectWhereUniqueInput;
}

export interface SharedProjectUpdateWithoutCustomerInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUpdateManyWithoutSharedProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateOneWithoutSharedProjectNestedInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutSharedProjectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUpdateWithoutMemberInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUpdateManyWithoutSharedProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutSharedProjectNestedInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutSharedProjectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUpdateWithoutProjectInput {
  SharedProjectToken?: InputMaybe<SharedProjectTokenUpdateManyWithoutSharedProjectNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutSharedProjectNestedInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateOneWithoutSharedProjectNestedInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUpdateWithoutSharedProjectTokenInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutSharedProjectNestedInput>;
  isFavorite?: InputMaybe<BoolFieldUpdateOperationsInput>;
  member?: InputMaybe<MemberUpdateOneWithoutSharedProjectNestedInput>;
  permission?: InputMaybe<EnumProjectPermissionFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutSharedProjectNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface SharedProjectUpsertWithWhereUniqueWithoutCustomerInput {
  create: SharedProjectUncheckedCreateWithoutCustomerInput;
  update: SharedProjectUncheckedUpdateWithoutCustomerInput;
  where: SharedProjectWhereUniqueInput;
}

export interface SharedProjectUpsertWithWhereUniqueWithoutMemberInput {
  create: SharedProjectUncheckedCreateWithoutMemberInput;
  update: SharedProjectUncheckedUpdateWithoutMemberInput;
  where: SharedProjectWhereUniqueInput;
}

export interface SharedProjectUpsertWithWhereUniqueWithoutProjectInput {
  create: SharedProjectUncheckedCreateWithoutProjectInput;
  update: SharedProjectUncheckedUpdateWithoutProjectInput;
  where: SharedProjectWhereUniqueInput;
}

export interface SharedProjectUpsertWithoutSharedProjectTokenInput {
  create: SharedProjectUncheckedCreateWithoutSharedProjectTokenInput;
  update: SharedProjectUncheckedUpdateWithoutSharedProjectTokenInput;
}

export interface SharedProjectWhereInput {
  AND?: InputMaybe<Array<InputMaybe<SharedProjectWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SharedProjectWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SharedProjectWhereInput>>>;
  SharedProjectToken?: InputMaybe<SharedProjectTokenListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isFavorite?: InputMaybe<BoolFilter>;
  member?: InputMaybe<MemberWhereInput>;
  memberId?: InputMaybe<IntNullableFilter>;
  permission?: InputMaybe<EnumProjectPermissionFilter>;
  project?: InputMaybe<ProjectWhereInput>;
  projectId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface SharedProjectWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export interface SortOrderInput {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
}

/** Partner: 공간디자인 전문가 */
export interface SpaceDesign {
  __typename?: 'SpaceDesign';
  _count: SpaceDesignCountOutputType;
  /** Partner user address */
  address?: Maybe<Address>;
  addressDetail?: Maybe<Scalars['String']['output']>;
  /** Partner user addressId */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** Avatar URL */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** Partner businessNumber (사업자등록번호) */
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Name */
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partner: Partner;
  partnerId: Scalars['Int']['output'];
  partnerStatus: PartnerRegistrationStatus;
  profile: Array<Profile>;
  /** 사업자등록증 사진 키 */
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail: Scalars['String']['output'];
  responsibilityName: Scalars['String']['output'];
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  /** Partner user role */
  role: PartnerRole;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}


/** Partner: 공간디자인 전문가 */
export type SpaceDesignProfileArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<ProfileScalarFieldEnum>;
  orderBy?: InputMaybe<ProfileOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};

export interface SpaceDesignAvgAggregateOutputType {
  __typename?: 'SpaceDesignAvgAggregateOutputType';
  addressId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  partnerId?: Maybe<Scalars['Float']['output']>;
}

export interface SpaceDesignAvgOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface SpaceDesignCountAggregateOutputType {
  __typename?: 'SpaceDesignCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  addressDetail: Scalars['Int']['output'];
  addressId: Scalars['Int']['output'];
  avatarUrl: Scalars['Int']['output'];
  businessNumber: Scalars['Int']['output'];
  companyContact: Scalars['Int']['output'];
  companyDescription: Scalars['Int']['output'];
  companyWebsite: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  ownerName: Scalars['Int']['output'];
  partnerId: Scalars['Int']['output'];
  partnerStatus: Scalars['Int']['output'];
  registrationPhotoKey: Scalars['Int']['output'];
  responsibilityEmail: Scalars['Int']['output'];
  responsibilityName: Scalars['Int']['output'];
  responsibilityPosition: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface SpaceDesignCountOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SpaceDesignCountOutputType {
  __typename?: 'SpaceDesignCountOutputType';
  profile: Scalars['Int']['output'];
}

export interface SpaceDesignCreateInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutSpaceDesignInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutSpaceDesignInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutSpaceDesignInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SpaceDesignCreateManyAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SpaceDesignCreateManyAddressInputEnvelope {
  data: SpaceDesignCreateManyAddressInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SpaceDesignCreateManyInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SpaceDesignCreateManyPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SpaceDesignCreateManyPartnerInputEnvelope {
  data: SpaceDesignCreateManyPartnerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SpaceDesignCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SpaceDesignCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<SpaceDesignCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<SpaceDesignCreateManyAddressInputEnvelope>;
}

export interface SpaceDesignCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SpaceDesignCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SpaceDesignCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<SpaceDesignCreateManyPartnerInputEnvelope>;
}

export interface SpaceDesignCreateNestedOneWithoutProfileInput {
  connect?: InputMaybe<SpaceDesignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SpaceDesignCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<SpaceDesignUncheckedCreateWithoutProfileInput>;
}

export interface SpaceDesignCreateOrConnectWithoutAddressInput {
  create: SpaceDesignUncheckedCreateWithoutAddressInput;
  where: SpaceDesignWhereUniqueInput;
}

export interface SpaceDesignCreateOrConnectWithoutPartnerInput {
  create: SpaceDesignUncheckedCreateWithoutPartnerInput;
  where: SpaceDesignWhereUniqueInput;
}

export interface SpaceDesignCreateOrConnectWithoutProfileInput {
  create: SpaceDesignUncheckedCreateWithoutProfileInput;
  where: SpaceDesignWhereUniqueInput;
}

export interface SpaceDesignCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutSpaceDesignInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutSpaceDesignInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SpaceDesignCreateWithoutPartnerInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutSpaceDesignInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutSpaceDesignInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SpaceDesignCreateWithoutProfileInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutSpaceDesignInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutSpaceDesignInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SpaceDesignListRelationFilter {
  every?: InputMaybe<SpaceDesignWhereInput>;
  none?: InputMaybe<SpaceDesignWhereInput>;
  some?: InputMaybe<SpaceDesignWhereInput>;
}

export interface SpaceDesignMaxAggregateOutputType {
  __typename?: 'SpaceDesignMaxAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface SpaceDesignMaxOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SpaceDesignMinAggregateOutputType {
  __typename?: 'SpaceDesignMinAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface SpaceDesignMinOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SpaceDesignOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface SpaceDesignOrderByWithAggregationInput {
  _avg?: InputMaybe<SpaceDesignAvgOrderByAggregateInput>;
  _count?: InputMaybe<SpaceDesignCountOrderByAggregateInput>;
  _max?: InputMaybe<SpaceDesignMaxOrderByAggregateInput>;
  _min?: InputMaybe<SpaceDesignMinOrderByAggregateInput>;
  _sum?: InputMaybe<SpaceDesignSumOrderByAggregateInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SpaceDesignOrderByWithRelationInput {
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partner?: InputMaybe<PartnerOrderByWithRelationInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByRelationAggregateInput>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SpaceDesignRelationFilter {
  is?: InputMaybe<SpaceDesignWhereInput>;
  isNot?: InputMaybe<SpaceDesignWhereInput>;
}

export enum SpaceDesignScalarFieldEnum {
  AddressDetail = 'addressDetail',
  AddressId = 'addressId',
  AvatarUrl = 'avatarUrl',
  BusinessNumber = 'businessNumber',
  CompanyContact = 'companyContact',
  CompanyDescription = 'companyDescription',
  CompanyWebsite = 'companyWebsite',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OwnerName = 'ownerName',
  PartnerId = 'partnerId',
  PartnerStatus = 'partnerStatus',
  RegistrationPhotoKey = 'registrationPhotoKey',
  ResponsibilityEmail = 'responsibilityEmail',
  ResponsibilityName = 'responsibilityName',
  ResponsibilityPosition = 'responsibilityPosition',
  Role = 'role',
  UpdatedAt = 'updatedAt'
}

export interface SpaceDesignScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<SpaceDesignScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SpaceDesignScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SpaceDesignScalarWhereInput>>>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface SpaceDesignScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<SpaceDesignScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SpaceDesignScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SpaceDesignScalarWhereWithAggregatesInput>>>;
  addressDetail?: InputMaybe<StringNullableWithAggregatesFilter>;
  addressId?: InputMaybe<IntNullableWithAggregatesFilter>;
  avatarUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  businessNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyContact?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyDescription?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyWebsite?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  ownerName?: InputMaybe<StringNullableWithAggregatesFilter>;
  partnerId?: InputMaybe<IntWithAggregatesFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusWithAggregatesFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableWithAggregatesFilter>;
  responsibilityEmail?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityName?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityPosition?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumPartnerRoleWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface SpaceDesignSumAggregateOutputType {
  __typename?: 'SpaceDesignSumAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
}

export interface SpaceDesignSumOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface SpaceDesignUncheckedCreateInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutSpaceDesignInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SpaceDesignUncheckedCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SpaceDesignCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<SpaceDesignCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<SpaceDesignCreateManyAddressInputEnvelope>;
}

export interface SpaceDesignUncheckedCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SpaceDesignCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SpaceDesignCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<SpaceDesignCreateManyPartnerInputEnvelope>;
}

export interface SpaceDesignUncheckedCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutSpaceDesignInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SpaceDesignUncheckedCreateWithoutPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutSpaceDesignInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SpaceDesignUncheckedCreateWithoutProfileInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SpaceDesignUncheckedUpdateInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutSpaceDesignNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SpaceDesignUncheckedUpdateManyInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SpaceDesignUncheckedUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SpaceDesignCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<SpaceDesignCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<SpaceDesignCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SpaceDesignScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SpaceDesignUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SpaceDesignUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SpaceDesignUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface SpaceDesignUncheckedUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SpaceDesignCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SpaceDesignCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<SpaceDesignCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SpaceDesignScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SpaceDesignUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SpaceDesignUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SpaceDesignUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface SpaceDesignUncheckedUpdateManyWithoutSpaceDesignInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SpaceDesignUncheckedUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutSpaceDesignNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SpaceDesignUncheckedUpdateWithoutPartnerInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutSpaceDesignNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SpaceDesignUncheckedUpdateWithoutProfileInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SpaceDesignUpdateInput {
  address?: InputMaybe<AddressUpdateOneWithoutSpaceDesignNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutSpaceDesignNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutSpaceDesignNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SpaceDesignUpdateManyMutationInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SpaceDesignUpdateManyWithWhereWithoutAddressInput {
  data: SpaceDesignUncheckedUpdateManyWithoutSpaceDesignInput;
  where: SpaceDesignScalarWhereInput;
}

export interface SpaceDesignUpdateManyWithWhereWithoutPartnerInput {
  data: SpaceDesignUncheckedUpdateManyWithoutSpaceDesignInput;
  where: SpaceDesignScalarWhereInput;
}

export interface SpaceDesignUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SpaceDesignCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<SpaceDesignCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<SpaceDesignCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SpaceDesignScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SpaceDesignUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SpaceDesignUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SpaceDesignUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface SpaceDesignUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SpaceDesignCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SpaceDesignCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<SpaceDesignCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SpaceDesignScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SpaceDesignWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SpaceDesignUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SpaceDesignUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SpaceDesignUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface SpaceDesignUpdateOneWithoutProfileNestedInput {
  connect?: InputMaybe<SpaceDesignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SpaceDesignCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<SpaceDesignUncheckedCreateWithoutProfileInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SpaceDesignUncheckedUpdateWithoutProfileInput>;
  upsert?: InputMaybe<SpaceDesignUpsertWithoutProfileInput>;
}

export interface SpaceDesignUpdateWithWhereUniqueWithoutAddressInput {
  data: SpaceDesignUncheckedUpdateWithoutAddressInput;
  where: SpaceDesignWhereUniqueInput;
}

export interface SpaceDesignUpdateWithWhereUniqueWithoutPartnerInput {
  data: SpaceDesignUncheckedUpdateWithoutPartnerInput;
  where: SpaceDesignWhereUniqueInput;
}

export interface SpaceDesignUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutSpaceDesignNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutSpaceDesignNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SpaceDesignUpdateWithoutPartnerInput {
  address?: InputMaybe<AddressUpdateOneWithoutSpaceDesignNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutSpaceDesignNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SpaceDesignUpdateWithoutProfileInput {
  address?: InputMaybe<AddressUpdateOneWithoutSpaceDesignNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutSpaceDesignNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SpaceDesignUpsertWithWhereUniqueWithoutAddressInput {
  create: SpaceDesignUncheckedCreateWithoutAddressInput;
  update: SpaceDesignUncheckedUpdateWithoutAddressInput;
  where: SpaceDesignWhereUniqueInput;
}

export interface SpaceDesignUpsertWithWhereUniqueWithoutPartnerInput {
  create: SpaceDesignUncheckedCreateWithoutPartnerInput;
  update: SpaceDesignUncheckedUpdateWithoutPartnerInput;
  where: SpaceDesignWhereUniqueInput;
}

export interface SpaceDesignUpsertWithoutProfileInput {
  create: SpaceDesignUncheckedCreateWithoutProfileInput;
  update: SpaceDesignUncheckedUpdateWithoutProfileInput;
}

export interface SpaceDesignWhereInput {
  AND?: InputMaybe<Array<InputMaybe<SpaceDesignWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SpaceDesignWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SpaceDesignWhereInput>>>;
  address?: InputMaybe<AddressWhereInput>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partner?: InputMaybe<PartnerWhereInput>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  profile?: InputMaybe<ProfileListRelationFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface SpaceDesignWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export interface StandardBoQ {
  __typename?: 'StandardBoQ';
  BoQItem: Array<BoQItem>;
  StandardBoQFiles: Array<StandardBoQFiles>;
  _count: StandardBoQCountOutputType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  uuid: Scalars['String']['output'];
}


export type StandardBoQBoQItemArgs = {
  cursor?: InputMaybe<BoQItemWhereUniqueInput>;
  distinct?: InputMaybe<BoQItemScalarFieldEnum>;
  orderBy?: InputMaybe<BoQItemOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoQItemWhereInput>;
};


export type StandardBoQStandardBoQFilesArgs = {
  cursor?: InputMaybe<StandardBoQFilesWhereUniqueInput>;
  distinct?: InputMaybe<StandardBoQFilesScalarFieldEnum>;
  orderBy?: InputMaybe<StandardBoQFilesOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardBoQFilesWhereInput>;
};

export interface StandardBoQAvgAggregateOutputType {
  __typename?: 'StandardBoQAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
}

export interface StandardBoQAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface StandardBoQCountAggregateOutputType {
  __typename?: 'StandardBoQCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface StandardBoQCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface StandardBoQCountOutputType {
  __typename?: 'StandardBoQCountOutputType';
  BoQItem: Scalars['Int']['output'];
  StandardBoQFiles: Scalars['Int']['output'];
}

export interface StandardBoQCreateInput {
  BoQItem?: InputMaybe<BoQItemCreateNestedManyWithoutStandardBoQInput>;
  StandardBoQFiles?: InputMaybe<StandardBoQFilesCreateNestedManyWithoutStandardBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQCreateNestedManyWithoutBoQItemInput {
  connect?: InputMaybe<Array<InputMaybe<StandardBoQWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<StandardBoQCreateOrConnectWithoutBoQItemInput>>>;
  create?: InputMaybe<Array<InputMaybe<StandardBoQCreateWithoutBoQItemInput>>>;
}

export interface StandardBoQCreateNestedOneWithoutStandardBoQFilesInput {
  connect?: InputMaybe<StandardBoQWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardBoQCreateOrConnectWithoutStandardBoQFilesInput>;
  create?: InputMaybe<StandardBoQUncheckedCreateWithoutStandardBoQFilesInput>;
}

export interface StandardBoQCreateOrConnectWithoutBoQItemInput {
  create: StandardBoQUncheckedCreateWithoutBoQItemInput;
  where: StandardBoQWhereUniqueInput;
}

export interface StandardBoQCreateOrConnectWithoutStandardBoQFilesInput {
  create: StandardBoQUncheckedCreateWithoutStandardBoQFilesInput;
  where: StandardBoQWhereUniqueInput;
}

export interface StandardBoQCreateWithoutBoQItemInput {
  StandardBoQFiles?: InputMaybe<StandardBoQFilesCreateNestedManyWithoutStandardBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQCreateWithoutStandardBoQFilesInput {
  BoQItem?: InputMaybe<BoQItemCreateNestedManyWithoutStandardBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQFiles {
  __typename?: 'StandardBoQFiles';
  StandardBoQ?: Maybe<StandardBoQ>;
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileKey: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  presignedDownloadUrl?: Maybe<Scalars['String']['output']>;
  standardBoQId?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['String']['output'];
}

export interface StandardBoQFilesAvgAggregateOutputType {
  __typename?: 'StandardBoQFilesAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  standardBoQId?: Maybe<Scalars['Float']['output']>;
}

export interface StandardBoQFilesAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  standardBoQId?: InputMaybe<SortOrder>;
}

export interface StandardBoQFilesCountAggregateOutputType {
  __typename?: 'StandardBoQFilesCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  active: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  fileKey: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  memo: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  standardBoQId: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface StandardBoQFilesCountOrderByAggregateInput {
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fileKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  standardBoQId?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface StandardBoQFilesCreateInput {
  StandardBoQ?: InputMaybe<StandardBoQCreateNestedOneWithoutStandardBoQFilesInput>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQFilesCreateManyInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  standardBoQId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQFilesCreateManyStandardBoQInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQFilesCreateManyStandardBoQInputEnvelope {
  data: StandardBoQFilesCreateManyStandardBoQInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface StandardBoQFilesCreateNestedManyWithoutStandardBoQInput {
  connect?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<StandardBoQFilesCreateOrConnectWithoutStandardBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<StandardBoQFilesCreateWithoutStandardBoQInput>>>;
  createMany?: InputMaybe<StandardBoQFilesCreateManyStandardBoQInputEnvelope>;
}

export interface StandardBoQFilesCreateOrConnectWithoutStandardBoQInput {
  create: StandardBoQFilesUncheckedCreateWithoutStandardBoQInput;
  where: StandardBoQFilesWhereUniqueInput;
}

export interface StandardBoQFilesCreateWithoutStandardBoQInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQFilesListRelationFilter {
  every?: InputMaybe<StandardBoQFilesWhereInput>;
  none?: InputMaybe<StandardBoQFilesWhereInput>;
  some?: InputMaybe<StandardBoQFilesWhereInput>;
}

export interface StandardBoQFilesMaxAggregateOutputType {
  __typename?: 'StandardBoQFilesMaxAggregateOutputType';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileKey?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  standardBoQId?: Maybe<Scalars['Int']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface StandardBoQFilesMaxOrderByAggregateInput {
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fileKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  standardBoQId?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface StandardBoQFilesMinAggregateOutputType {
  __typename?: 'StandardBoQFilesMinAggregateOutputType';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileKey?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  standardBoQId?: Maybe<Scalars['Int']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface StandardBoQFilesMinOrderByAggregateInput {
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fileKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  standardBoQId?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface StandardBoQFilesOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface StandardBoQFilesOrderByWithAggregationInput {
  _avg?: InputMaybe<StandardBoQFilesAvgOrderByAggregateInput>;
  _count?: InputMaybe<StandardBoQFilesCountOrderByAggregateInput>;
  _max?: InputMaybe<StandardBoQFilesMaxOrderByAggregateInput>;
  _min?: InputMaybe<StandardBoQFilesMinOrderByAggregateInput>;
  _sum?: InputMaybe<StandardBoQFilesSumOrderByAggregateInput>;
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrderInput>;
  fileKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  standardBoQId?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
}

export interface StandardBoQFilesOrderByWithRelationInput {
  StandardBoQ?: InputMaybe<StandardBoQOrderByWithRelationInput>;
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrderInput>;
  fileKey?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  memo?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  standardBoQId?: InputMaybe<SortOrderInput>;
  uuid?: InputMaybe<SortOrder>;
}

export enum StandardBoQFilesScalarFieldEnum {
  Active = 'active',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  FileKey = 'fileKey',
  Id = 'id',
  Memo = 'memo',
  Name = 'name',
  StandardBoQId = 'standardBoQId',
  Uuid = 'uuid'
}

export interface StandardBoQFilesScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<StandardBoQFilesScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<StandardBoQFilesScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<StandardBoQFilesScalarWhereInput>>>;
  active?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  fileKey?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  standardBoQId?: InputMaybe<IntNullableFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface StandardBoQFilesScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<StandardBoQFilesScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<StandardBoQFilesScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<StandardBoQFilesScalarWhereWithAggregatesInput>>>;
  active?: InputMaybe<BoolWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  fileKey?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  memo?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  standardBoQId?: InputMaybe<IntNullableWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface StandardBoQFilesSumAggregateOutputType {
  __typename?: 'StandardBoQFilesSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  standardBoQId?: Maybe<Scalars['Int']['output']>;
}

export interface StandardBoQFilesSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  standardBoQId?: InputMaybe<SortOrder>;
}

export interface StandardBoQFilesUncheckedCreateInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  standardBoQId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQFilesUncheckedCreateNestedManyWithoutStandardBoQInput {
  connect?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<StandardBoQFilesCreateOrConnectWithoutStandardBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<StandardBoQFilesCreateWithoutStandardBoQInput>>>;
  createMany?: InputMaybe<StandardBoQFilesCreateManyStandardBoQInputEnvelope>;
}

export interface StandardBoQFilesUncheckedCreateWithoutStandardBoQInput {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileKey: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQFilesUncheckedUpdateInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  standardBoQId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQFilesUncheckedUpdateManyInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  standardBoQId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQFilesUncheckedUpdateManyWithoutStandardBoQFilesInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQFilesUncheckedUpdateManyWithoutStandardBoQNestedInput {
  connect?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<StandardBoQFilesCreateOrConnectWithoutStandardBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<StandardBoQFilesCreateWithoutStandardBoQInput>>>;
  createMany?: InputMaybe<StandardBoQFilesCreateManyStandardBoQInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<StandardBoQFilesScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<StandardBoQFilesUpdateWithWhereUniqueWithoutStandardBoQInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<StandardBoQFilesUpdateManyWithWhereWithoutStandardBoQInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<StandardBoQFilesUpsertWithWhereUniqueWithoutStandardBoQInput>>>;
}

export interface StandardBoQFilesUncheckedUpdateWithoutStandardBoQInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQFilesUpdateInput {
  StandardBoQ?: InputMaybe<StandardBoQUpdateOneWithoutStandardBoQFilesNestedInput>;
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQFilesUpdateManyMutationInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQFilesUpdateManyWithWhereWithoutStandardBoQInput {
  data: StandardBoQFilesUncheckedUpdateManyWithoutStandardBoQFilesInput;
  where: StandardBoQFilesScalarWhereInput;
}

export interface StandardBoQFilesUpdateManyWithoutStandardBoQNestedInput {
  connect?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<StandardBoQFilesCreateOrConnectWithoutStandardBoQInput>>>;
  create?: InputMaybe<Array<InputMaybe<StandardBoQFilesCreateWithoutStandardBoQInput>>>;
  createMany?: InputMaybe<StandardBoQFilesCreateManyStandardBoQInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<StandardBoQFilesScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<StandardBoQFilesUpdateWithWhereUniqueWithoutStandardBoQInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<StandardBoQFilesUpdateManyWithWhereWithoutStandardBoQInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<StandardBoQFilesUpsertWithWhereUniqueWithoutStandardBoQInput>>>;
}

export interface StandardBoQFilesUpdateWithWhereUniqueWithoutStandardBoQInput {
  data: StandardBoQFilesUncheckedUpdateWithoutStandardBoQInput;
  where: StandardBoQFilesWhereUniqueInput;
}

export interface StandardBoQFilesUpdateWithoutStandardBoQInput {
  active?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fileKey?: InputMaybe<StringFieldUpdateOperationsInput>;
  memo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQFilesUpsertWithWhereUniqueWithoutStandardBoQInput {
  create: StandardBoQFilesUncheckedCreateWithoutStandardBoQInput;
  update: StandardBoQFilesUncheckedUpdateWithoutStandardBoQInput;
  where: StandardBoQFilesWhereUniqueInput;
}

export interface StandardBoQFilesWhereInput {
  AND?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<StandardBoQFilesWhereInput>>>;
  StandardBoQ?: InputMaybe<StandardBoQWhereInput>;
  active?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  fileKey?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  standardBoQId?: InputMaybe<IntNullableFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface StandardBoQFilesWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQListRelationFilter {
  every?: InputMaybe<StandardBoQWhereInput>;
  none?: InputMaybe<StandardBoQWhereInput>;
  some?: InputMaybe<StandardBoQWhereInput>;
}

export interface StandardBoQMaxAggregateOutputType {
  __typename?: 'StandardBoQMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface StandardBoQMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface StandardBoQMinAggregateOutputType {
  __typename?: 'StandardBoQMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface StandardBoQMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface StandardBoQOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface StandardBoQOrderByWithAggregationInput {
  _avg?: InputMaybe<StandardBoQAvgOrderByAggregateInput>;
  _count?: InputMaybe<StandardBoQCountOrderByAggregateInput>;
  _max?: InputMaybe<StandardBoQMaxOrderByAggregateInput>;
  _min?: InputMaybe<StandardBoQMinOrderByAggregateInput>;
  _sum?: InputMaybe<StandardBoQSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface StandardBoQOrderByWithRelationInput {
  BoQItem?: InputMaybe<BoQItemOrderByRelationAggregateInput>;
  StandardBoQFiles?: InputMaybe<StandardBoQFilesOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface StandardBoQRelationFilter {
  is?: InputMaybe<StandardBoQWhereInput>;
  isNot?: InputMaybe<StandardBoQWhereInput>;
}

export enum StandardBoQScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Uuid = 'uuid'
}

export interface StandardBoQScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<StandardBoQScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<StandardBoQScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<StandardBoQScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface StandardBoQScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<StandardBoQScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<StandardBoQScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<StandardBoQScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface StandardBoQSumAggregateOutputType {
  __typename?: 'StandardBoQSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
}

export interface StandardBoQSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
}

export interface StandardBoQUncheckedCreateInput {
  BoQItem?: InputMaybe<BoQItemUncheckedCreateNestedManyWithoutStandardBoQInput>;
  StandardBoQFiles?: InputMaybe<StandardBoQFilesUncheckedCreateNestedManyWithoutStandardBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQUncheckedCreateNestedManyWithoutBoQItemInput {
  connect?: InputMaybe<Array<InputMaybe<StandardBoQWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<StandardBoQCreateOrConnectWithoutBoQItemInput>>>;
  create?: InputMaybe<Array<InputMaybe<StandardBoQCreateWithoutBoQItemInput>>>;
}

export interface StandardBoQUncheckedCreateWithoutBoQItemInput {
  StandardBoQFiles?: InputMaybe<StandardBoQFilesUncheckedCreateNestedManyWithoutStandardBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQUncheckedCreateWithoutStandardBoQFilesInput {
  BoQItem?: InputMaybe<BoQItemUncheckedCreateNestedManyWithoutStandardBoQInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StandardBoQUncheckedUpdateInput {
  BoQItem?: InputMaybe<BoQItemUncheckedUpdateManyWithoutStandardBoQNestedInput>;
  StandardBoQFiles?: InputMaybe<StandardBoQFilesUncheckedUpdateManyWithoutStandardBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQUncheckedUpdateManyWithoutBoQItemNestedInput {
  connect?: InputMaybe<Array<InputMaybe<StandardBoQWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<StandardBoQCreateOrConnectWithoutBoQItemInput>>>;
  create?: InputMaybe<Array<InputMaybe<StandardBoQCreateWithoutBoQItemInput>>>;
  delete?: InputMaybe<Array<InputMaybe<StandardBoQWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<StandardBoQScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<StandardBoQWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<StandardBoQWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<StandardBoQUpdateWithWhereUniqueWithoutBoQItemInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<StandardBoQUpdateManyWithWhereWithoutBoQItemInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<StandardBoQUpsertWithWhereUniqueWithoutBoQItemInput>>>;
}

export interface StandardBoQUncheckedUpdateManyWithoutStandardBoQInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQUncheckedUpdateWithoutBoQItemInput {
  StandardBoQFiles?: InputMaybe<StandardBoQFilesUncheckedUpdateManyWithoutStandardBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQUncheckedUpdateWithoutStandardBoQFilesInput {
  BoQItem?: InputMaybe<BoQItemUncheckedUpdateManyWithoutStandardBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQUpdateInput {
  BoQItem?: InputMaybe<BoQItemUpdateManyWithoutStandardBoQNestedInput>;
  StandardBoQFiles?: InputMaybe<StandardBoQFilesUpdateManyWithoutStandardBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQUpdateManyWithWhereWithoutBoQItemInput {
  data: StandardBoQUncheckedUpdateManyWithoutStandardBoQInput;
  where: StandardBoQScalarWhereInput;
}

export interface StandardBoQUpdateManyWithoutBoQItemNestedInput {
  connect?: InputMaybe<Array<InputMaybe<StandardBoQWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<StandardBoQCreateOrConnectWithoutBoQItemInput>>>;
  create?: InputMaybe<Array<InputMaybe<StandardBoQCreateWithoutBoQItemInput>>>;
  delete?: InputMaybe<Array<InputMaybe<StandardBoQWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<StandardBoQScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<StandardBoQWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<StandardBoQWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<StandardBoQUpdateWithWhereUniqueWithoutBoQItemInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<StandardBoQUpdateManyWithWhereWithoutBoQItemInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<StandardBoQUpsertWithWhereUniqueWithoutBoQItemInput>>>;
}

export interface StandardBoQUpdateOneWithoutStandardBoQFilesNestedInput {
  connect?: InputMaybe<StandardBoQWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardBoQCreateOrConnectWithoutStandardBoQFilesInput>;
  create?: InputMaybe<StandardBoQUncheckedCreateWithoutStandardBoQFilesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StandardBoQUncheckedUpdateWithoutStandardBoQFilesInput>;
  upsert?: InputMaybe<StandardBoQUpsertWithoutStandardBoQFilesInput>;
}

export interface StandardBoQUpdateWithWhereUniqueWithoutBoQItemInput {
  data: StandardBoQUncheckedUpdateWithoutBoQItemInput;
  where: StandardBoQWhereUniqueInput;
}

export interface StandardBoQUpdateWithoutBoQItemInput {
  StandardBoQFiles?: InputMaybe<StandardBoQFilesUpdateManyWithoutStandardBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQUpdateWithoutStandardBoQFilesInput {
  BoQItem?: InputMaybe<BoQItemUpdateManyWithoutStandardBoQNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface StandardBoQUpsertWithWhereUniqueWithoutBoQItemInput {
  create: StandardBoQUncheckedCreateWithoutBoQItemInput;
  update: StandardBoQUncheckedUpdateWithoutBoQItemInput;
  where: StandardBoQWhereUniqueInput;
}

export interface StandardBoQUpsertWithoutStandardBoQFilesInput {
  create: StandardBoQUncheckedCreateWithoutStandardBoQFilesInput;
  update: StandardBoQUncheckedUpdateWithoutStandardBoQFilesInput;
}

export interface StandardBoQWhereInput {
  AND?: InputMaybe<Array<InputMaybe<StandardBoQWhereInput>>>;
  BoQItem?: InputMaybe<BoQItemListRelationFilter>;
  NOT?: InputMaybe<Array<InputMaybe<StandardBoQWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<StandardBoQWhereInput>>>;
  StandardBoQFiles?: InputMaybe<StandardBoQFilesListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface StandardBoQWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface StringFieldUpdateOperationsInput {
  set?: InputMaybe<Scalars['String']['input']>;
}

export interface StringFilter {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
}

export interface StringNullableFilter {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
}

export interface StringNullableWithAggregatesFilter {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
}

export interface StringWithAggregatesFilter {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
}

/** Partner: 자재업체 */
export interface Supplier {
  __typename?: 'Supplier';
  _count: SupplierCountOutputType;
  /** Partner user address */
  address?: Maybe<Address>;
  addressDetail?: Maybe<Scalars['String']['output']>;
  /** Partner user addressId */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** Avatar URL */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** Partner businessNumber (사업자등록번호) */
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Name */
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partner: Partner;
  partnerId: Scalars['Int']['output'];
  partnerStatus: PartnerRegistrationStatus;
  profile: Array<Profile>;
  /** 사업자등록증 사진 키 */
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail: Scalars['String']['output'];
  responsibilityName: Scalars['String']['output'];
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  /** Partner user role */
  role: PartnerRole;
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}


/** Partner: 자재업체 */
export type SupplierProfileArgs = {
  cursor?: InputMaybe<ProfileWhereUniqueInput>;
  distinct?: InputMaybe<ProfileScalarFieldEnum>;
  orderBy?: InputMaybe<ProfileOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProfileWhereInput>;
};

export interface SupplierAvgAggregateOutputType {
  __typename?: 'SupplierAvgAggregateOutputType';
  addressId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  partnerId?: Maybe<Scalars['Float']['output']>;
}

export interface SupplierAvgOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface SupplierCountAggregateOutputType {
  __typename?: 'SupplierCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  addressDetail: Scalars['Int']['output'];
  addressId: Scalars['Int']['output'];
  avatarUrl: Scalars['Int']['output'];
  businessNumber: Scalars['Int']['output'];
  companyContact: Scalars['Int']['output'];
  companyDescription: Scalars['Int']['output'];
  companyWebsite: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  ownerName: Scalars['Int']['output'];
  partnerId: Scalars['Int']['output'];
  partnerStatus: Scalars['Int']['output'];
  registrationPhotoKey: Scalars['Int']['output'];
  responsibilityEmail: Scalars['Int']['output'];
  responsibilityName: Scalars['Int']['output'];
  responsibilityPosition: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface SupplierCountOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SupplierCountOutputType {
  __typename?: 'SupplierCountOutputType';
  profile: Scalars['Int']['output'];
}

export interface SupplierCreateInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutSupplierInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutSupplierInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutSupplierInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SupplierCreateManyAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SupplierCreateManyAddressInputEnvelope {
  data: SupplierCreateManyAddressInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SupplierCreateManyInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SupplierCreateManyPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SupplierCreateManyPartnerInputEnvelope {
  data: SupplierCreateManyPartnerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface SupplierCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SupplierCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<SupplierCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<SupplierCreateManyAddressInputEnvelope>;
}

export interface SupplierCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SupplierCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SupplierCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<SupplierCreateManyPartnerInputEnvelope>;
}

export interface SupplierCreateNestedOneWithoutProfileInput {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<SupplierUncheckedCreateWithoutProfileInput>;
}

export interface SupplierCreateOrConnectWithoutAddressInput {
  create: SupplierUncheckedCreateWithoutAddressInput;
  where: SupplierWhereUniqueInput;
}

export interface SupplierCreateOrConnectWithoutPartnerInput {
  create: SupplierUncheckedCreateWithoutPartnerInput;
  where: SupplierWhereUniqueInput;
}

export interface SupplierCreateOrConnectWithoutProfileInput {
  create: SupplierUncheckedCreateWithoutProfileInput;
  where: SupplierWhereUniqueInput;
}

export interface SupplierCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutSupplierInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutSupplierInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SupplierCreateWithoutPartnerInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutSupplierInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileCreateNestedManyWithoutSupplierInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SupplierCreateWithoutProfileInput {
  address?: InputMaybe<AddressCreateNestedOneWithoutSupplierInput>;
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partner: PartnerCreateNestedOneWithoutSupplierInput;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SupplierListRelationFilter {
  every?: InputMaybe<SupplierWhereInput>;
  none?: InputMaybe<SupplierWhereInput>;
  some?: InputMaybe<SupplierWhereInput>;
}

export interface SupplierMaxAggregateOutputType {
  __typename?: 'SupplierMaxAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface SupplierMaxOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SupplierMinAggregateOutputType {
  __typename?: 'SupplierMinAggregateOutputType';
  addressDetail?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['Int']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  businessNumber?: Maybe<Scalars['String']['output']>;
  companyContact?: Maybe<Scalars['String']['output']>;
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyWebsite?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerStatus?: Maybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: Maybe<Scalars['String']['output']>;
  responsibilityEmail?: Maybe<Scalars['String']['output']>;
  responsibilityName?: Maybe<Scalars['String']['output']>;
  responsibilityPosition?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PartnerRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface SupplierMinOrderByAggregateInput {
  addressDetail?: InputMaybe<SortOrder>;
  addressId?: InputMaybe<SortOrder>;
  avatarUrl?: InputMaybe<SortOrder>;
  businessNumber?: InputMaybe<SortOrder>;
  companyContact?: InputMaybe<SortOrder>;
  companyDescription?: InputMaybe<SortOrder>;
  companyWebsite?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerName?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrder>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SupplierOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface SupplierOrderByWithAggregationInput {
  _avg?: InputMaybe<SupplierAvgOrderByAggregateInput>;
  _count?: InputMaybe<SupplierCountOrderByAggregateInput>;
  _max?: InputMaybe<SupplierMaxOrderByAggregateInput>;
  _min?: InputMaybe<SupplierMinOrderByAggregateInput>;
  _sum?: InputMaybe<SupplierSumOrderByAggregateInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SupplierOrderByWithRelationInput {
  address?: InputMaybe<AddressOrderByWithRelationInput>;
  addressDetail?: InputMaybe<SortOrderInput>;
  addressId?: InputMaybe<SortOrderInput>;
  avatarUrl?: InputMaybe<SortOrderInput>;
  businessNumber?: InputMaybe<SortOrderInput>;
  companyContact?: InputMaybe<SortOrderInput>;
  companyDescription?: InputMaybe<SortOrderInput>;
  companyWebsite?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  ownerName?: InputMaybe<SortOrderInput>;
  partner?: InputMaybe<PartnerOrderByWithRelationInput>;
  partnerId?: InputMaybe<SortOrder>;
  partnerStatus?: InputMaybe<SortOrder>;
  profile?: InputMaybe<ProfileOrderByRelationAggregateInput>;
  registrationPhotoKey?: InputMaybe<SortOrderInput>;
  responsibilityEmail?: InputMaybe<SortOrder>;
  responsibilityName?: InputMaybe<SortOrder>;
  responsibilityPosition?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface SupplierRelationFilter {
  is?: InputMaybe<SupplierWhereInput>;
  isNot?: InputMaybe<SupplierWhereInput>;
}

export enum SupplierScalarFieldEnum {
  AddressDetail = 'addressDetail',
  AddressId = 'addressId',
  AvatarUrl = 'avatarUrl',
  BusinessNumber = 'businessNumber',
  CompanyContact = 'companyContact',
  CompanyDescription = 'companyDescription',
  CompanyWebsite = 'companyWebsite',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  OwnerName = 'ownerName',
  PartnerId = 'partnerId',
  PartnerStatus = 'partnerStatus',
  RegistrationPhotoKey = 'registrationPhotoKey',
  ResponsibilityEmail = 'responsibilityEmail',
  ResponsibilityName = 'responsibilityName',
  ResponsibilityPosition = 'responsibilityPosition',
  Role = 'role',
  UpdatedAt = 'updatedAt'
}

export interface SupplierScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<SupplierScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SupplierScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SupplierScalarWhereInput>>>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface SupplierScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<SupplierScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SupplierScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SupplierScalarWhereWithAggregatesInput>>>;
  addressDetail?: InputMaybe<StringNullableWithAggregatesFilter>;
  addressId?: InputMaybe<IntNullableWithAggregatesFilter>;
  avatarUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  businessNumber?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyContact?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyDescription?: InputMaybe<StringNullableWithAggregatesFilter>;
  companyWebsite?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  ownerName?: InputMaybe<StringNullableWithAggregatesFilter>;
  partnerId?: InputMaybe<IntWithAggregatesFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusWithAggregatesFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableWithAggregatesFilter>;
  responsibilityEmail?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityName?: InputMaybe<StringWithAggregatesFilter>;
  responsibilityPosition?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<EnumPartnerRoleWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export interface SupplierSumAggregateOutputType {
  __typename?: 'SupplierSumAggregateOutputType';
  addressId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
}

export interface SupplierSumOrderByAggregateInput {
  addressId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
}

export interface SupplierUncheckedCreateInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutSupplierInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SupplierUncheckedCreateNestedManyWithoutAddressInput {
  connect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SupplierCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<SupplierCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<SupplierCreateManyAddressInputEnvelope>;
}

export interface SupplierUncheckedCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SupplierCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SupplierCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<SupplierCreateManyPartnerInputEnvelope>;
}

export interface SupplierUncheckedCreateWithoutAddressInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutSupplierInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SupplierUncheckedCreateWithoutPartnerInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  profile?: InputMaybe<ProfileUncheckedCreateNestedManyWithoutSupplierInput>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SupplierUncheckedCreateWithoutProfileInput {
  addressDetail?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  businessNumber?: InputMaybe<Scalars['String']['input']>;
  companyContact?: InputMaybe<Scalars['String']['input']>;
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyWebsite?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['Int']['input'];
  partnerStatus?: InputMaybe<PartnerRegistrationStatus>;
  registrationPhotoKey?: InputMaybe<Scalars['String']['input']>;
  responsibilityEmail?: InputMaybe<Scalars['String']['input']>;
  responsibilityName?: InputMaybe<Scalars['String']['input']>;
  responsibilityPosition?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PartnerRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface SupplierUncheckedUpdateInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutSupplierNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SupplierUncheckedUpdateManyInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SupplierUncheckedUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SupplierCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<SupplierCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<SupplierCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SupplierScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SupplierUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SupplierUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SupplierUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface SupplierUncheckedUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SupplierCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SupplierCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<SupplierCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SupplierScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SupplierUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SupplierUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SupplierUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface SupplierUncheckedUpdateManyWithoutSupplierInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SupplierUncheckedUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutSupplierNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SupplierUncheckedUpdateWithoutPartnerInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUncheckedUpdateManyWithoutSupplierNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SupplierUncheckedUpdateWithoutProfileInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addressId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SupplierUpdateInput {
  address?: InputMaybe<AddressUpdateOneWithoutSupplierNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutSupplierNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutSupplierNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SupplierUpdateManyMutationInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SupplierUpdateManyWithWhereWithoutAddressInput {
  data: SupplierUncheckedUpdateManyWithoutSupplierInput;
  where: SupplierScalarWhereInput;
}

export interface SupplierUpdateManyWithWhereWithoutPartnerInput {
  data: SupplierUncheckedUpdateManyWithoutSupplierInput;
  where: SupplierScalarWhereInput;
}

export interface SupplierUpdateManyWithoutAddressNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SupplierCreateOrConnectWithoutAddressInput>>>;
  create?: InputMaybe<Array<InputMaybe<SupplierCreateWithoutAddressInput>>>;
  createMany?: InputMaybe<SupplierCreateManyAddressInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SupplierScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SupplierUpdateWithWhereUniqueWithoutAddressInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SupplierUpdateManyWithWhereWithoutAddressInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SupplierUpsertWithWhereUniqueWithoutAddressInput>>>;
}

export interface SupplierUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<SupplierCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<SupplierCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<SupplierCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<SupplierScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<SupplierWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<SupplierUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<SupplierUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<SupplierUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface SupplierUpdateOneWithoutProfileNestedInput {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutProfileInput>;
  create?: InputMaybe<SupplierUncheckedCreateWithoutProfileInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SupplierUncheckedUpdateWithoutProfileInput>;
  upsert?: InputMaybe<SupplierUpsertWithoutProfileInput>;
}

export interface SupplierUpdateWithWhereUniqueWithoutAddressInput {
  data: SupplierUncheckedUpdateWithoutAddressInput;
  where: SupplierWhereUniqueInput;
}

export interface SupplierUpdateWithWhereUniqueWithoutPartnerInput {
  data: SupplierUncheckedUpdateWithoutPartnerInput;
  where: SupplierWhereUniqueInput;
}

export interface SupplierUpdateWithoutAddressInput {
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutSupplierNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutSupplierNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SupplierUpdateWithoutPartnerInput {
  address?: InputMaybe<AddressUpdateOneWithoutSupplierNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  profile?: InputMaybe<ProfileUpdateManyWithoutSupplierNestedInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SupplierUpdateWithoutProfileInput {
  address?: InputMaybe<AddressUpdateOneWithoutSupplierNestedInput>;
  addressDetail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  avatarUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  businessNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyContact?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  companyWebsite?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ownerName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  partner?: InputMaybe<PartnerUpdateOneRequiredWithoutSupplierNestedInput>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFieldUpdateOperationsInput>;
  registrationPhotoKey?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsibilityEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityName?: InputMaybe<StringFieldUpdateOperationsInput>;
  responsibilityPosition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumPartnerRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface SupplierUpsertWithWhereUniqueWithoutAddressInput {
  create: SupplierUncheckedCreateWithoutAddressInput;
  update: SupplierUncheckedUpdateWithoutAddressInput;
  where: SupplierWhereUniqueInput;
}

export interface SupplierUpsertWithWhereUniqueWithoutPartnerInput {
  create: SupplierUncheckedCreateWithoutPartnerInput;
  update: SupplierUncheckedUpdateWithoutPartnerInput;
  where: SupplierWhereUniqueInput;
}

export interface SupplierUpsertWithoutProfileInput {
  create: SupplierUncheckedCreateWithoutProfileInput;
  update: SupplierUncheckedUpdateWithoutProfileInput;
}

export interface SupplierWhereInput {
  AND?: InputMaybe<Array<InputMaybe<SupplierWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<SupplierWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<SupplierWhereInput>>>;
  address?: InputMaybe<AddressWhereInput>;
  addressDetail?: InputMaybe<StringNullableFilter>;
  addressId?: InputMaybe<IntNullableFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  businessNumber?: InputMaybe<StringNullableFilter>;
  companyContact?: InputMaybe<StringNullableFilter>;
  companyDescription?: InputMaybe<StringNullableFilter>;
  companyWebsite?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerName?: InputMaybe<StringNullableFilter>;
  partner?: InputMaybe<PartnerWhereInput>;
  partnerId?: InputMaybe<IntFilter>;
  partnerStatus?: InputMaybe<EnumPartnerRegistrationStatusFilter>;
  profile?: InputMaybe<ProfileListRelationFilter>;
  registrationPhotoKey?: InputMaybe<StringNullableFilter>;
  responsibilityEmail?: InputMaybe<StringFilter>;
  responsibilityName?: InputMaybe<StringFilter>;
  responsibilityPosition?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumPartnerRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface SupplierWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

/** Task */
export interface Task {
  __typename?: 'Task';
  /** Partner of this task */
  Partner?: Maybe<Partner>;
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** Task description */
  description?: Maybe<Scalars['String']['output']>;
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** Partner ID of this task */
  partnerId?: Maybe<Scalars['Int']['output']>;
  /** Related project */
  project: Project;
  /** Related project ID */
  projectId: Scalars['Int']['output'];
  /** Task status */
  status: TaskStatus;
  /** Task title */
  title: Scalars['String']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
}

export interface TaskAvgAggregateOutputType {
  __typename?: 'TaskAvgAggregateOutputType';
  id?: Maybe<Scalars['Float']['output']>;
  partnerId?: Maybe<Scalars['Float']['output']>;
  projectId?: Maybe<Scalars['Float']['output']>;
}

export interface TaskAvgOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface TaskCountAggregateOutputType {
  __typename?: 'TaskCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  partnerId: Scalars['Int']['output'];
  projectId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
}

export interface TaskCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface TaskCreateInput {
  Partner?: InputMaybe<PartnerCreateNestedOneWithoutTasksInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  project: ProjectCreateNestedOneWithoutTasksInput;
  status: TaskStatus;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface TaskCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  status: TaskStatus;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface TaskCreateManyPartnerInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  status: TaskStatus;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface TaskCreateManyPartnerInputEnvelope {
  data: TaskCreateManyPartnerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface TaskCreateManyProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  status: TaskStatus;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface TaskCreateManyProjectInputEnvelope {
  data: TaskCreateManyProjectInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface TaskCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TaskCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<TaskCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<TaskCreateManyPartnerInputEnvelope>;
}

export interface TaskCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TaskCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<TaskCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<TaskCreateManyProjectInputEnvelope>;
}

export interface TaskCreateOrConnectWithoutPartnerInput {
  create: TaskUncheckedCreateWithoutPartnerInput;
  where: TaskWhereUniqueInput;
}

export interface TaskCreateOrConnectWithoutProjectInput {
  create: TaskUncheckedCreateWithoutProjectInput;
  where: TaskWhereUniqueInput;
}

export interface TaskCreateWithoutPartnerInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  project: ProjectCreateNestedOneWithoutTasksInput;
  status: TaskStatus;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface TaskCreateWithoutProjectInput {
  Partner?: InputMaybe<PartnerCreateNestedOneWithoutTasksInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  status: TaskStatus;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface TaskListRelationFilter {
  every?: InputMaybe<TaskWhereInput>;
  none?: InputMaybe<TaskWhereInput>;
  some?: InputMaybe<TaskWhereInput>;
}

export interface TaskMaxAggregateOutputType {
  __typename?: 'TaskMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TaskStatus>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface TaskMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface TaskMinAggregateOutputType {
  __typename?: 'TaskMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TaskStatus>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface TaskMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface TaskOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface TaskOrderByWithAggregationInput {
  _avg?: InputMaybe<TaskAvgOrderByAggregateInput>;
  _count?: InputMaybe<TaskCountOrderByAggregateInput>;
  _max?: InputMaybe<TaskMaxOrderByAggregateInput>;
  _min?: InputMaybe<TaskMinOrderByAggregateInput>;
  _sum?: InputMaybe<TaskSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrderInput>;
  projectId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export interface TaskOrderByWithRelationInput {
  Partner?: InputMaybe<PartnerOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrderInput>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  projectId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
}

export enum TaskScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  PartnerId = 'partnerId',
  ProjectId = 'projectId',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export interface TaskScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<TaskScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<TaskScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskScalarWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  partnerId?: InputMaybe<IntNullableFilter>;
  projectId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumTaskStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface TaskScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<TaskScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<TaskScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  partnerId?: InputMaybe<IntNullableWithAggregatesFilter>;
  projectId?: InputMaybe<IntWithAggregatesFilter>;
  status?: InputMaybe<EnumTaskStatusWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
}

export enum TaskStatus {
  Assigned = 'ASSIGNED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Destroyed = 'DESTROYED',
  Validated = 'VALIDATED'
}

export interface TaskSumAggregateOutputType {
  __typename?: 'TaskSumAggregateOutputType';
  id?: Maybe<Scalars['Int']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
}

export interface TaskSumOrderByAggregateInput {
  id?: InputMaybe<SortOrder>;
  partnerId?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
}

export interface TaskUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  status: TaskStatus;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface TaskUncheckedCreateNestedManyWithoutPartnerInput {
  connect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TaskCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<TaskCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<TaskCreateManyPartnerInputEnvelope>;
}

export interface TaskUncheckedCreateNestedManyWithoutProjectInput {
  connect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TaskCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<TaskCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<TaskCreateManyProjectInputEnvelope>;
}

export interface TaskUncheckedCreateWithoutPartnerInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  status: TaskStatus;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface TaskUncheckedCreateWithoutProjectInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  status: TaskStatus;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface TaskUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface TaskUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface TaskUncheckedUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TaskCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<TaskCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<TaskCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<TaskScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<TaskUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<TaskUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<TaskUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface TaskUncheckedUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TaskCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<TaskCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<TaskCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<TaskScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<TaskUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<TaskUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<TaskUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface TaskUncheckedUpdateManyWithoutTasksInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface TaskUncheckedUpdateWithoutPartnerInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  projectId?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface TaskUncheckedUpdateWithoutProjectInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  partnerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface TaskUpdateInput {
  Partner?: InputMaybe<PartnerUpdateOneWithoutTasksNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutTasksNestedInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface TaskUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface TaskUpdateManyWithWhereWithoutPartnerInput {
  data: TaskUncheckedUpdateManyWithoutTasksInput;
  where: TaskScalarWhereInput;
}

export interface TaskUpdateManyWithWhereWithoutProjectInput {
  data: TaskUncheckedUpdateManyWithoutTasksInput;
  where: TaskScalarWhereInput;
}

export interface TaskUpdateManyWithoutPartnerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TaskCreateOrConnectWithoutPartnerInput>>>;
  create?: InputMaybe<Array<InputMaybe<TaskCreateWithoutPartnerInput>>>;
  createMany?: InputMaybe<TaskCreateManyPartnerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<TaskScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<TaskUpdateWithWhereUniqueWithoutPartnerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<TaskUpdateManyWithWhereWithoutPartnerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<TaskUpsertWithWhereUniqueWithoutPartnerInput>>>;
}

export interface TaskUpdateManyWithoutProjectNestedInput {
  connect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<TaskCreateOrConnectWithoutProjectInput>>>;
  create?: InputMaybe<Array<InputMaybe<TaskCreateWithoutProjectInput>>>;
  createMany?: InputMaybe<TaskCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<TaskScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<TaskWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<TaskUpdateWithWhereUniqueWithoutProjectInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<TaskUpdateManyWithWhereWithoutProjectInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<TaskUpsertWithWhereUniqueWithoutProjectInput>>>;
}

export interface TaskUpdateWithWhereUniqueWithoutPartnerInput {
  data: TaskUncheckedUpdateWithoutPartnerInput;
  where: TaskWhereUniqueInput;
}

export interface TaskUpdateWithWhereUniqueWithoutProjectInput {
  data: TaskUncheckedUpdateWithoutProjectInput;
  where: TaskWhereUniqueInput;
}

export interface TaskUpdateWithoutPartnerInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutTasksNestedInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface TaskUpdateWithoutProjectInput {
  Partner?: InputMaybe<PartnerUpdateOneWithoutTasksNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTaskStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
}

export interface TaskUpsertWithWhereUniqueWithoutPartnerInput {
  create: TaskUncheckedCreateWithoutPartnerInput;
  update: TaskUncheckedUpdateWithoutPartnerInput;
  where: TaskWhereUniqueInput;
}

export interface TaskUpsertWithWhereUniqueWithoutProjectInput {
  create: TaskUncheckedCreateWithoutProjectInput;
  update: TaskUncheckedUpdateWithoutProjectInput;
  where: TaskWhereUniqueInput;
}

export interface TaskWhereInput {
  AND?: InputMaybe<Array<InputMaybe<TaskWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<TaskWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<TaskWhereInput>>>;
  Partner?: InputMaybe<PartnerWhereInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  partnerId?: InputMaybe<IntNullableFilter>;
  project?: InputMaybe<ProjectWhereInput>;
  projectId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumTaskStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
}

export interface TaskWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export enum ThirdPartyId {
  EmailPassword = 'emailPassword',
  Google = 'google',
  Kakao = 'kakao',
  Naver = 'naver'
}

export enum TransactionIsolationLevel {
  ReadCommitted = 'ReadCommitted',
  ReadUncommitted = 'ReadUncommitted',
  RepeatableRead = 'RepeatableRead',
  Serializable = 'Serializable'
}

export interface UpdateFieldInput {
  create?: InputMaybe<Scalars['Boolean']['input']>;
  editor?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isId?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<KindEnum>;
  list?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  relationField?: InputMaybe<Scalars['Boolean']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<Scalars['Boolean']['input']>;
  upload?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface UpdateModelInput {
  create?: InputMaybe<Scalars['Boolean']['input']>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  displayFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fields?: InputMaybe<Array<InputMaybe<UpdateFieldInput>>>;
  idField?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  update?: InputMaybe<Scalars['Boolean']['input']>;
}

export enum UploadOption {
  Autocad = 'autocad',
  Builderhub = 'builderhub',
  Combined = 'combined'
}

export enum UploadStatus {
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Success = 'SUCCESS',
  Updated = 'UPDATED'
}

/** 세금계산서 발행정보 */
export interface VatInvoice {
  __typename?: 'VatInvoice';
  /** 고객 정보 */
  Customer?: Maybe<Customer>;
  Project: Array<Project>;
  _count: VatInvoiceCountOutputType;
  /** 사업자 주소 */
  address: Scalars['String']['output'];
  /** 종목 */
  businessItem: Scalars['String']['output'];
  /** 업태 */
  businessType: Scalars['String']['output'];
  /** 사업자(주민)등록번호 */
  corporationRegistrationNumber: Scalars['String']['output'];
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['Int']['output']>;
  /** 세금계산서 수신 이메일 */
  email: Scalars['String']['output'];
  /** ID integer autoincrement */
  id: Scalars['Int']['output'];
  /** 상호(법인명) */
  nameOfCompany: Scalars['String']['output'];
  /** 성명(대표자) */
  nameOfRepresentative: Scalars['String']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Unique ID */
  uuid: Scalars['String']['output'];
}


/** 세금계산서 발행정보 */
export type VatInvoiceProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<ProjectScalarFieldEnum>;
  orderBy?: InputMaybe<ProjectOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};

export interface VatInvoiceAvgAggregateOutputType {
  __typename?: 'VatInvoiceAvgAggregateOutputType';
  customerId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
}

export interface VatInvoiceAvgOrderByAggregateInput {
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
}

export interface VatInvoiceCountAggregateOutputType {
  __typename?: 'VatInvoiceCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  address: Scalars['Int']['output'];
  businessItem: Scalars['Int']['output'];
  businessType: Scalars['Int']['output'];
  corporationRegistrationNumber: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  nameOfCompany: Scalars['Int']['output'];
  nameOfRepresentative: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  uuid: Scalars['Int']['output'];
}

export interface VatInvoiceCountOrderByAggregateInput {
  address?: InputMaybe<SortOrder>;
  businessItem?: InputMaybe<SortOrder>;
  businessType?: InputMaybe<SortOrder>;
  corporationRegistrationNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  nameOfCompany?: InputMaybe<SortOrder>;
  nameOfRepresentative?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface VatInvoiceCountOutputType {
  __typename?: 'VatInvoiceCountOutputType';
  Project: Scalars['Int']['output'];
}

export interface VatInvoiceCreateInput {
  Customer?: InputMaybe<CustomerCreateNestedOneWithoutInvoiceInput>;
  Project?: InputMaybe<ProjectCreateNestedManyWithoutVatInvoiceInput>;
  address: Scalars['String']['input'];
  businessItem: Scalars['String']['input'];
  businessType: Scalars['String']['input'];
  corporationRegistrationNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  nameOfCompany: Scalars['String']['input'];
  nameOfRepresentative: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface VatInvoiceCreateManyCustomerInput {
  address: Scalars['String']['input'];
  businessItem: Scalars['String']['input'];
  businessType: Scalars['String']['input'];
  corporationRegistrationNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  nameOfCompany: Scalars['String']['input'];
  nameOfRepresentative: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface VatInvoiceCreateManyCustomerInputEnvelope {
  data: VatInvoiceCreateManyCustomerInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface VatInvoiceCreateManyInput {
  address: Scalars['String']['input'];
  businessItem: Scalars['String']['input'];
  businessType: Scalars['String']['input'];
  corporationRegistrationNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  nameOfCompany: Scalars['String']['input'];
  nameOfRepresentative: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface VatInvoiceCreateNestedManyWithoutCustomerInput {
  connect?: InputMaybe<Array<InputMaybe<VatInvoiceWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<VatInvoiceCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<VatInvoiceCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<VatInvoiceCreateManyCustomerInputEnvelope>;
}

export interface VatInvoiceCreateNestedOneWithoutProjectInput {
  connect?: InputMaybe<VatInvoiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VatInvoiceCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<VatInvoiceUncheckedCreateWithoutProjectInput>;
}

export interface VatInvoiceCreateOrConnectWithoutCustomerInput {
  create: VatInvoiceUncheckedCreateWithoutCustomerInput;
  where: VatInvoiceWhereUniqueInput;
}

export interface VatInvoiceCreateOrConnectWithoutProjectInput {
  create: VatInvoiceUncheckedCreateWithoutProjectInput;
  where: VatInvoiceWhereUniqueInput;
}

export interface VatInvoiceCreateWithoutCustomerInput {
  Project?: InputMaybe<ProjectCreateNestedManyWithoutVatInvoiceInput>;
  address: Scalars['String']['input'];
  businessItem: Scalars['String']['input'];
  businessType: Scalars['String']['input'];
  corporationRegistrationNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  nameOfCompany: Scalars['String']['input'];
  nameOfRepresentative: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface VatInvoiceCreateWithoutProjectInput {
  Customer?: InputMaybe<CustomerCreateNestedOneWithoutInvoiceInput>;
  address: Scalars['String']['input'];
  businessItem: Scalars['String']['input'];
  businessType: Scalars['String']['input'];
  corporationRegistrationNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  nameOfCompany: Scalars['String']['input'];
  nameOfRepresentative: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface VatInvoiceListRelationFilter {
  every?: InputMaybe<VatInvoiceWhereInput>;
  none?: InputMaybe<VatInvoiceWhereInput>;
  some?: InputMaybe<VatInvoiceWhereInput>;
}

export interface VatInvoiceMaxAggregateOutputType {
  __typename?: 'VatInvoiceMaxAggregateOutputType';
  address?: Maybe<Scalars['String']['output']>;
  businessItem?: Maybe<Scalars['String']['output']>;
  businessType?: Maybe<Scalars['String']['output']>;
  corporationRegistrationNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  nameOfCompany?: Maybe<Scalars['String']['output']>;
  nameOfRepresentative?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface VatInvoiceMaxOrderByAggregateInput {
  address?: InputMaybe<SortOrder>;
  businessItem?: InputMaybe<SortOrder>;
  businessType?: InputMaybe<SortOrder>;
  corporationRegistrationNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  nameOfCompany?: InputMaybe<SortOrder>;
  nameOfRepresentative?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface VatInvoiceMinAggregateOutputType {
  __typename?: 'VatInvoiceMinAggregateOutputType';
  address?: Maybe<Scalars['String']['output']>;
  businessItem?: Maybe<Scalars['String']['output']>;
  businessType?: Maybe<Scalars['String']['output']>;
  corporationRegistrationNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  nameOfCompany?: Maybe<Scalars['String']['output']>;
  nameOfRepresentative?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface VatInvoiceMinOrderByAggregateInput {
  address?: InputMaybe<SortOrder>;
  businessItem?: InputMaybe<SortOrder>;
  businessType?: InputMaybe<SortOrder>;
  corporationRegistrationNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  nameOfCompany?: InputMaybe<SortOrder>;
  nameOfRepresentative?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface VatInvoiceOrderByRelationAggregateInput {
  _count?: InputMaybe<SortOrder>;
}

export interface VatInvoiceOrderByWithAggregationInput {
  _avg?: InputMaybe<VatInvoiceAvgOrderByAggregateInput>;
  _count?: InputMaybe<VatInvoiceCountOrderByAggregateInput>;
  _max?: InputMaybe<VatInvoiceMaxOrderByAggregateInput>;
  _min?: InputMaybe<VatInvoiceMinOrderByAggregateInput>;
  _sum?: InputMaybe<VatInvoiceSumOrderByAggregateInput>;
  address?: InputMaybe<SortOrder>;
  businessItem?: InputMaybe<SortOrder>;
  businessType?: InputMaybe<SortOrder>;
  corporationRegistrationNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  nameOfCompany?: InputMaybe<SortOrder>;
  nameOfRepresentative?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface VatInvoiceOrderByWithRelationInput {
  Customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  Project?: InputMaybe<ProjectOrderByRelationAggregateInput>;
  address?: InputMaybe<SortOrder>;
  businessItem?: InputMaybe<SortOrder>;
  businessType?: InputMaybe<SortOrder>;
  corporationRegistrationNumber?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  nameOfCompany?: InputMaybe<SortOrder>;
  nameOfRepresentative?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uuid?: InputMaybe<SortOrder>;
}

export interface VatInvoiceRelationFilter {
  is?: InputMaybe<VatInvoiceWhereInput>;
  isNot?: InputMaybe<VatInvoiceWhereInput>;
}

export enum VatInvoiceScalarFieldEnum {
  Address = 'address',
  BusinessItem = 'businessItem',
  BusinessType = 'businessType',
  CorporationRegistrationNumber = 'corporationRegistrationNumber',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Email = 'email',
  Id = 'id',
  NameOfCompany = 'nameOfCompany',
  NameOfRepresentative = 'nameOfRepresentative',
  UpdatedAt = 'updatedAt',
  Uuid = 'uuid'
}

export interface VatInvoiceScalarWhereInput {
  AND?: InputMaybe<Array<InputMaybe<VatInvoiceScalarWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<VatInvoiceScalarWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<VatInvoiceScalarWhereInput>>>;
  address?: InputMaybe<StringFilter>;
  businessItem?: InputMaybe<StringFilter>;
  businessType?: InputMaybe<StringFilter>;
  corporationRegistrationNumber?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<IntNullableFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  nameOfCompany?: InputMaybe<StringFilter>;
  nameOfRepresentative?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface VatInvoiceScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<VatInvoiceScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<VatInvoiceScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<VatInvoiceScalarWhereWithAggregatesInput>>>;
  address?: InputMaybe<StringWithAggregatesFilter>;
  businessItem?: InputMaybe<StringWithAggregatesFilter>;
  businessType?: InputMaybe<StringWithAggregatesFilter>;
  corporationRegistrationNumber?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  customerId?: InputMaybe<IntNullableWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  nameOfCompany?: InputMaybe<StringWithAggregatesFilter>;
  nameOfRepresentative?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  uuid?: InputMaybe<StringWithAggregatesFilter>;
}

export interface VatInvoiceSumAggregateOutputType {
  __typename?: 'VatInvoiceSumAggregateOutputType';
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
}

export interface VatInvoiceSumOrderByAggregateInput {
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
}

export interface VatInvoiceUncheckedCreateInput {
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutVatInvoiceInput>;
  address: Scalars['String']['input'];
  businessItem: Scalars['String']['input'];
  businessType: Scalars['String']['input'];
  corporationRegistrationNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  nameOfCompany: Scalars['String']['input'];
  nameOfRepresentative: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface VatInvoiceUncheckedCreateNestedManyWithoutCustomerInput {
  connect?: InputMaybe<Array<InputMaybe<VatInvoiceWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<VatInvoiceCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<VatInvoiceCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<VatInvoiceCreateManyCustomerInputEnvelope>;
}

export interface VatInvoiceUncheckedCreateWithoutCustomerInput {
  Project?: InputMaybe<ProjectUncheckedCreateNestedManyWithoutVatInvoiceInput>;
  address: Scalars['String']['input'];
  businessItem: Scalars['String']['input'];
  businessType: Scalars['String']['input'];
  corporationRegistrationNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  nameOfCompany: Scalars['String']['input'];
  nameOfRepresentative: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface VatInvoiceUncheckedCreateWithoutProjectInput {
  address: Scalars['String']['input'];
  businessItem: Scalars['String']['input'];
  businessType: Scalars['String']['input'];
  corporationRegistrationNumber: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  nameOfCompany: Scalars['String']['input'];
  nameOfRepresentative: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

export interface VatInvoiceUncheckedUpdateInput {
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutVatInvoiceNestedInput>;
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessItem?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessType?: InputMaybe<StringFieldUpdateOperationsInput>;
  corporationRegistrationNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  nameOfCompany?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfRepresentative?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface VatInvoiceUncheckedUpdateManyInput {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessItem?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessType?: InputMaybe<StringFieldUpdateOperationsInput>;
  corporationRegistrationNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  nameOfCompany?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfRepresentative?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface VatInvoiceUncheckedUpdateManyWithoutCustomerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<VatInvoiceWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<VatInvoiceCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<VatInvoiceCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<VatInvoiceCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<VatInvoiceWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<VatInvoiceScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<VatInvoiceWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<VatInvoiceWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<VatInvoiceUpdateWithWhereUniqueWithoutCustomerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<VatInvoiceUpdateManyWithWhereWithoutCustomerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<VatInvoiceUpsertWithWhereUniqueWithoutCustomerInput>>>;
}

export interface VatInvoiceUncheckedUpdateManyWithoutInvoiceInput {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessItem?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessType?: InputMaybe<StringFieldUpdateOperationsInput>;
  corporationRegistrationNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  nameOfCompany?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfRepresentative?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface VatInvoiceUncheckedUpdateWithoutCustomerInput {
  Project?: InputMaybe<ProjectUncheckedUpdateManyWithoutVatInvoiceNestedInput>;
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessItem?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessType?: InputMaybe<StringFieldUpdateOperationsInput>;
  corporationRegistrationNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  nameOfCompany?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfRepresentative?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface VatInvoiceUncheckedUpdateWithoutProjectInput {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessItem?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessType?: InputMaybe<StringFieldUpdateOperationsInput>;
  corporationRegistrationNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<IntFieldUpdateOperationsInput>;
  nameOfCompany?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfRepresentative?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface VatInvoiceUpdateInput {
  Customer?: InputMaybe<CustomerUpdateOneWithoutInvoiceNestedInput>;
  Project?: InputMaybe<ProjectUpdateManyWithoutVatInvoiceNestedInput>;
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessItem?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessType?: InputMaybe<StringFieldUpdateOperationsInput>;
  corporationRegistrationNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfCompany?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfRepresentative?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface VatInvoiceUpdateManyMutationInput {
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessItem?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessType?: InputMaybe<StringFieldUpdateOperationsInput>;
  corporationRegistrationNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfCompany?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfRepresentative?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface VatInvoiceUpdateManyWithWhereWithoutCustomerInput {
  data: VatInvoiceUncheckedUpdateManyWithoutInvoiceInput;
  where: VatInvoiceScalarWhereInput;
}

export interface VatInvoiceUpdateManyWithoutCustomerNestedInput {
  connect?: InputMaybe<Array<InputMaybe<VatInvoiceWhereUniqueInput>>>;
  connectOrCreate?: InputMaybe<Array<InputMaybe<VatInvoiceCreateOrConnectWithoutCustomerInput>>>;
  create?: InputMaybe<Array<InputMaybe<VatInvoiceCreateWithoutCustomerInput>>>;
  createMany?: InputMaybe<VatInvoiceCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<InputMaybe<VatInvoiceWhereUniqueInput>>>;
  deleteMany?: InputMaybe<Array<InputMaybe<VatInvoiceScalarWhereInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<VatInvoiceWhereUniqueInput>>>;
  set?: InputMaybe<Array<InputMaybe<VatInvoiceWhereUniqueInput>>>;
  update?: InputMaybe<Array<InputMaybe<VatInvoiceUpdateWithWhereUniqueWithoutCustomerInput>>>;
  updateMany?: InputMaybe<Array<InputMaybe<VatInvoiceUpdateManyWithWhereWithoutCustomerInput>>>;
  upsert?: InputMaybe<Array<InputMaybe<VatInvoiceUpsertWithWhereUniqueWithoutCustomerInput>>>;
}

export interface VatInvoiceUpdateOneWithoutProjectNestedInput {
  connect?: InputMaybe<VatInvoiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VatInvoiceCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<VatInvoiceUncheckedCreateWithoutProjectInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<VatInvoiceUncheckedUpdateWithoutProjectInput>;
  upsert?: InputMaybe<VatInvoiceUpsertWithoutProjectInput>;
}

export interface VatInvoiceUpdateWithWhereUniqueWithoutCustomerInput {
  data: VatInvoiceUncheckedUpdateWithoutCustomerInput;
  where: VatInvoiceWhereUniqueInput;
}

export interface VatInvoiceUpdateWithoutCustomerInput {
  Project?: InputMaybe<ProjectUpdateManyWithoutVatInvoiceNestedInput>;
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessItem?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessType?: InputMaybe<StringFieldUpdateOperationsInput>;
  corporationRegistrationNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfCompany?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfRepresentative?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface VatInvoiceUpdateWithoutProjectInput {
  Customer?: InputMaybe<CustomerUpdateOneWithoutInvoiceNestedInput>;
  address?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessItem?: InputMaybe<StringFieldUpdateOperationsInput>;
  businessType?: InputMaybe<StringFieldUpdateOperationsInput>;
  corporationRegistrationNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfCompany?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameOfRepresentative?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  uuid?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface VatInvoiceUpsertWithWhereUniqueWithoutCustomerInput {
  create: VatInvoiceUncheckedCreateWithoutCustomerInput;
  update: VatInvoiceUncheckedUpdateWithoutCustomerInput;
  where: VatInvoiceWhereUniqueInput;
}

export interface VatInvoiceUpsertWithoutProjectInput {
  create: VatInvoiceUncheckedCreateWithoutProjectInput;
  update: VatInvoiceUncheckedUpdateWithoutProjectInput;
}

export interface VatInvoiceWhereInput {
  AND?: InputMaybe<Array<InputMaybe<VatInvoiceWhereInput>>>;
  Customer?: InputMaybe<CustomerWhereInput>;
  NOT?: InputMaybe<Array<InputMaybe<VatInvoiceWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<VatInvoiceWhereInput>>>;
  Project?: InputMaybe<ProjectListRelationFilter>;
  address?: InputMaybe<StringFilter>;
  businessItem?: InputMaybe<StringFilter>;
  businessType?: InputMaybe<StringFilter>;
  corporationRegistrationNumber?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<IntNullableFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  nameOfCompany?: InputMaybe<StringFilter>;
  nameOfRepresentative?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uuid?: InputMaybe<StringFilter>;
}

export interface VatInvoiceWhereUniqueInput {
  id?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}

/** 탈퇴 사유 */
export interface WithdrawReason {
  __typename?: 'WithdrawReason';
  /** createdAt */
  createdAt: Scalars['DateTime']['output'];
  /** ID String CUID */
  id: Scalars['String']['output'];
  /** updatedAt */
  updatedAt: Scalars['DateTime']['output'];
  /** Reason */
  withdrawReason: Scalars['String']['output'];
}

export interface WithdrawReasonCountAggregateOutputType {
  __typename?: 'WithdrawReasonCountAggregateOutputType';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  withdrawReason: Scalars['Int']['output'];
}

export interface WithdrawReasonCountOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  withdrawReason?: InputMaybe<SortOrder>;
}

export interface WithdrawReasonCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  withdrawReason: Scalars['String']['input'];
}

export interface WithdrawReasonCreateManyInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  withdrawReason: Scalars['String']['input'];
}

export interface WithdrawReasonMaxAggregateOutputType {
  __typename?: 'WithdrawReasonMaxAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  withdrawReason?: Maybe<Scalars['String']['output']>;
}

export interface WithdrawReasonMaxOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  withdrawReason?: InputMaybe<SortOrder>;
}

export interface WithdrawReasonMinAggregateOutputType {
  __typename?: 'WithdrawReasonMinAggregateOutputType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  withdrawReason?: Maybe<Scalars['String']['output']>;
}

export interface WithdrawReasonMinOrderByAggregateInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  withdrawReason?: InputMaybe<SortOrder>;
}

export interface WithdrawReasonOrderByWithAggregationInput {
  _count?: InputMaybe<WithdrawReasonCountOrderByAggregateInput>;
  _max?: InputMaybe<WithdrawReasonMaxOrderByAggregateInput>;
  _min?: InputMaybe<WithdrawReasonMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  withdrawReason?: InputMaybe<SortOrder>;
}

export interface WithdrawReasonOrderByWithRelationInput {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  withdrawReason?: InputMaybe<SortOrder>;
}

export enum WithdrawReasonScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  WithdrawReason = 'withdrawReason'
}

export interface WithdrawReasonScalarWhereWithAggregatesInput {
  AND?: InputMaybe<Array<InputMaybe<WithdrawReasonScalarWhereWithAggregatesInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<WithdrawReasonScalarWhereWithAggregatesInput>>>;
  OR?: InputMaybe<Array<InputMaybe<WithdrawReasonScalarWhereWithAggregatesInput>>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  withdrawReason?: InputMaybe<StringWithAggregatesFilter>;
}

export interface WithdrawReasonUncheckedCreateInput {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  withdrawReason: Scalars['String']['input'];
}

export interface WithdrawReasonUncheckedUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface WithdrawReasonUncheckedUpdateManyInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface WithdrawReasonUpdateInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface WithdrawReasonUpdateManyMutationInput {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  withdrawReason?: InputMaybe<StringFieldUpdateOperationsInput>;
}

export interface WithdrawReasonWhereInput {
  AND?: InputMaybe<Array<InputMaybe<WithdrawReasonWhereInput>>>;
  NOT?: InputMaybe<Array<InputMaybe<WithdrawReasonWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<WithdrawReasonWhereInput>>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  withdrawReason?: InputMaybe<StringFilter>;
}

export interface WithdrawReasonWhereUniqueInput {
  id?: InputMaybe<Scalars['String']['input']>;
}

export interface PresignedUploadPublicReturn {
  __typename?: 'presignedUploadPublicReturn';
  cdnUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface PresignedUploadWithMetadataReturn {
  __typename?: 'presignedUploadWithMetadataReturn';
  hashedKey?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface SendKakaoMessageResponse {
  __typename?: 'sendKakaoMessageResponse';
  messageId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
}

/** Shared message */
export interface UpdateSharedMessage {
  __typename?: 'updateSharedMessage';
  error: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
}

export type GetMyInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyInfoQuery = { __typename?: 'Query', me?: { __typename?: 'Member', userId: string, userHash?: string | null, email: string, customerId?: number | null, partnerId?: number | null, customer?: { __typename?: 'Customer', id: number, name?: string | null, role: CustomerRole, fieldName?: string | null } | null, identity?: { __typename?: 'Identity', uniqueKey: string, name: string, phoneNumber: string } | null } | null };

export type UpdateMeMutationVariables = Exact<{
  data: MemberUpdateInput;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', updateMe?: { __typename?: 'Member', uniqueKey?: string | null } | null };

export type GetIdentityDataQueryVariables = Exact<{
  impUid: Scalars['String']['input'];
}>;


export type GetIdentityDataQuery = { __typename?: 'Query', certification?: { __typename?: 'Certification', code?: number | null, message?: string | null, response?: { __typename?: 'CertificationAnnotation', name?: string | null, gender?: string | null, birthday?: string | null, phone?: string | null, unique_key?: string | null } | null, meta: { __typename?: 'CheckIdentity', isNotHaveUniqueKey: boolean, email?: string | null } } | null };

export type GetInquiryQueryVariables = Exact<{
  where?: InputMaybe<InquiryWhereInput>;
}>;


export type GetInquiryQuery = { __typename?: 'Query', findMyInquiryFileQuery?: string | null, findMyOneInquiryQuery?: { __typename?: 'Inquiry', inquiryType: InquiryType, title: string, createdAt: any, state: boolean, content: string, url?: string | null, fileName?: string | null, fileSize?: number | null, reply?: { __typename?: 'Reply', createdAt: any, content: string, url?: string | null, reaction?: ReactionType | null, storageStatus: boolean, originalFileName?: string | null } | null } | null };

export type UpdateMyReplyMutationVariables = Exact<{
  data: InquiryUpdateInput;
  where: InquiryWhereUniqueInput;
}>;


export type UpdateMyReplyMutation = { __typename?: 'Mutation', updateMyInquiry: { __typename?: 'Inquiry', id: number, reply?: { __typename?: 'Reply', reaction?: ReactionType | null } | null } };

export type CreateMyInquiryMutationVariables = Exact<{
  data: InquiryCreateInput;
}>;


export type CreateMyInquiryMutation = { __typename?: 'Mutation', createMyInquiry: { __typename?: 'Inquiry', id: number } };

export type PresignedUploadMutationVariables = Exact<{
  fileKey: Scalars['String']['input'];
}>;


export type PresignedUploadMutation = { __typename?: 'Mutation', presignedUploadWithMetadata?: { __typename?: 'presignedUploadWithMetadataReturn', url?: string | null, hashedKey?: string | null } | null };

export type GetInquiryListQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetInquiryListQuery = { __typename?: 'Query', getMe?: { __typename?: 'Member', InquiryAggregate: { __typename?: 'AggregateInquiry', _count?: { __typename?: 'InquiryCountAggregateOutputType', id: number } | null }, Inquiry: Array<{ __typename?: 'Inquiry', id: number, uuid: string, inquiryType: InquiryType, title: string, createdAt: any, state: boolean }> } | null };

export type GetNoticeQueryVariables = Exact<{
  where?: InputMaybe<NoticeWhereInput>;
}>;


export type GetNoticeQuery = { __typename?: 'Query', findFirstNotice?: { __typename?: 'Notice', content: string, createdAt: any, important: boolean, title: string, url?: string | null, views: number } | null };

export type GetNoticeListQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetNoticeListQuery = { __typename?: 'Query', findManyNotice: Array<{ __typename?: 'Notice', id: number, uuid: string, createdAt: any, title: string, views: number, important: boolean }>, aggregateNotice?: { __typename?: 'AggregateNotice', _count?: { __typename?: 'NoticeCountAggregateOutputType', id: number } | null } | null };

export type IncrementViewMutationVariables = Exact<{
  where: NoticeWhereUniqueInput;
}>;


export type IncrementViewMutation = { __typename?: 'Mutation', updateOneNotice: { __typename?: 'Notice', id: number } };


export const GetMyInfoDocument = gql`
    query getMyInfo {
  me {
    userId
    userHash
    email
    customerId
    customer {
      id
      name
      role
      fieldName
    }
    partnerId
    identity {
      uniqueKey
      name
      phoneNumber
    }
  }
}
    `;

/**
 * __useGetMyInfoQuery__
 *
 * To run a query within a React component, call `useGetMyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyInfoQuery, GetMyInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMyInfoQuery, GetMyInfoQueryVariables>(GetMyInfoDocument, options);
      }
export function useGetMyInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyInfoQuery, GetMyInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMyInfoQuery, GetMyInfoQueryVariables>(GetMyInfoDocument, options);
        }
export function useGetMyInfoSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetMyInfoQuery, GetMyInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetMyInfoQuery, GetMyInfoQueryVariables>(GetMyInfoDocument, options);
        }
export type GetMyInfoQueryHookResult = ReturnType<typeof useGetMyInfoQuery>;
export type GetMyInfoLazyQueryHookResult = ReturnType<typeof useGetMyInfoLazyQuery>;
export type GetMyInfoSuspenseQueryHookResult = ReturnType<typeof useGetMyInfoSuspenseQuery>;
export type GetMyInfoQueryResult = Apollo.QueryResult<GetMyInfoQuery, GetMyInfoQueryVariables>;
export const UpdateMeDocument = gql`
    mutation updateMe($data: MemberUpdateInput!) {
  updateMe(data: $data) {
    uniqueKey
  }
}
    `;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const GetIdentityDataDocument = gql`
    query getIdentityData($impUid: String!) {
  certification(impUid: $impUid) {
    code
    message
    response {
      name
      gender
      birthday
      phone
      unique_key
    }
    meta {
      isNotHaveUniqueKey
      email
    }
  }
}
    `;

/**
 * __useGetIdentityDataQuery__
 *
 * To run a query within a React component, call `useGetIdentityDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityDataQuery({
 *   variables: {
 *      impUid: // value for 'impUid'
 *   },
 * });
 */
export function useGetIdentityDataQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetIdentityDataQuery, GetIdentityDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetIdentityDataQuery, GetIdentityDataQueryVariables>(GetIdentityDataDocument, options);
      }
export function useGetIdentityDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIdentityDataQuery, GetIdentityDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetIdentityDataQuery, GetIdentityDataQueryVariables>(GetIdentityDataDocument, options);
        }
export function useGetIdentityDataSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetIdentityDataQuery, GetIdentityDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetIdentityDataQuery, GetIdentityDataQueryVariables>(GetIdentityDataDocument, options);
        }
export type GetIdentityDataQueryHookResult = ReturnType<typeof useGetIdentityDataQuery>;
export type GetIdentityDataLazyQueryHookResult = ReturnType<typeof useGetIdentityDataLazyQuery>;
export type GetIdentityDataSuspenseQueryHookResult = ReturnType<typeof useGetIdentityDataSuspenseQuery>;
export type GetIdentityDataQueryResult = Apollo.QueryResult<GetIdentityDataQuery, GetIdentityDataQueryVariables>;
export const GetInquiryDocument = gql`
    query getInquiry($where: InquiryWhereInput) {
  findMyOneInquiryQuery(where: $where) {
    inquiryType
    title
    createdAt
    state
    content
    url
    fileName
    fileSize
    reply {
      createdAt
      content
      url
      reaction
      storageStatus
      originalFileName
    }
  }
  findMyInquiryFileQuery(where: $where)
}
    `;

/**
 * __useGetInquiryQuery__
 *
 * To run a query within a React component, call `useGetInquiryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInquiryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInquiryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetInquiryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInquiryQuery, GetInquiryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetInquiryQuery, GetInquiryQueryVariables>(GetInquiryDocument, options);
      }
export function useGetInquiryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInquiryQuery, GetInquiryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetInquiryQuery, GetInquiryQueryVariables>(GetInquiryDocument, options);
        }
export function useGetInquirySuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetInquiryQuery, GetInquiryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetInquiryQuery, GetInquiryQueryVariables>(GetInquiryDocument, options);
        }
export type GetInquiryQueryHookResult = ReturnType<typeof useGetInquiryQuery>;
export type GetInquiryLazyQueryHookResult = ReturnType<typeof useGetInquiryLazyQuery>;
export type GetInquirySuspenseQueryHookResult = ReturnType<typeof useGetInquirySuspenseQuery>;
export type GetInquiryQueryResult = Apollo.QueryResult<GetInquiryQuery, GetInquiryQueryVariables>;
export const UpdateMyReplyDocument = gql`
    mutation updateMyReply($data: InquiryUpdateInput!, $where: InquiryWhereUniqueInput!) {
  updateMyInquiry(data: $data, where: $where) {
    id
    reply {
      reaction
    }
  }
}
    `;
export type UpdateMyReplyMutationFn = Apollo.MutationFunction<UpdateMyReplyMutation, UpdateMyReplyMutationVariables>;

/**
 * __useUpdateMyReplyMutation__
 *
 * To run a mutation, you first call `useUpdateMyReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyReplyMutation, { data, loading, error }] = useUpdateMyReplyMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateMyReplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMyReplyMutation, UpdateMyReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateMyReplyMutation, UpdateMyReplyMutationVariables>(UpdateMyReplyDocument, options);
      }
export type UpdateMyReplyMutationHookResult = ReturnType<typeof useUpdateMyReplyMutation>;
export type UpdateMyReplyMutationResult = Apollo.MutationResult<UpdateMyReplyMutation>;
export type UpdateMyReplyMutationOptions = Apollo.BaseMutationOptions<UpdateMyReplyMutation, UpdateMyReplyMutationVariables>;
export const CreateMyInquiryDocument = gql`
    mutation createMyInquiry($data: InquiryCreateInput!) {
  createMyInquiry(data: $data) {
    id
  }
}
    `;
export type CreateMyInquiryMutationFn = Apollo.MutationFunction<CreateMyInquiryMutation, CreateMyInquiryMutationVariables>;

/**
 * __useCreateMyInquiryMutation__
 *
 * To run a mutation, you first call `useCreateMyInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyInquiryMutation, { data, loading, error }] = useCreateMyInquiryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMyInquiryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMyInquiryMutation, CreateMyInquiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateMyInquiryMutation, CreateMyInquiryMutationVariables>(CreateMyInquiryDocument, options);
      }
export type CreateMyInquiryMutationHookResult = ReturnType<typeof useCreateMyInquiryMutation>;
export type CreateMyInquiryMutationResult = Apollo.MutationResult<CreateMyInquiryMutation>;
export type CreateMyInquiryMutationOptions = Apollo.BaseMutationOptions<CreateMyInquiryMutation, CreateMyInquiryMutationVariables>;
export const PresignedUploadDocument = gql`
    mutation presignedUpload($fileKey: String!) {
  presignedUploadWithMetadata(fileKey: $fileKey) {
    url
    hashedKey
  }
}
    `;
export type PresignedUploadMutationFn = Apollo.MutationFunction<PresignedUploadMutation, PresignedUploadMutationVariables>;

/**
 * __usePresignedUploadMutation__
 *
 * To run a mutation, you first call `usePresignedUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePresignedUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [presignedUploadMutation, { data, loading, error }] = usePresignedUploadMutation({
 *   variables: {
 *      fileKey: // value for 'fileKey'
 *   },
 * });
 */
export function usePresignedUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PresignedUploadMutation, PresignedUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PresignedUploadMutation, PresignedUploadMutationVariables>(PresignedUploadDocument, options);
      }
export type PresignedUploadMutationHookResult = ReturnType<typeof usePresignedUploadMutation>;
export type PresignedUploadMutationResult = Apollo.MutationResult<PresignedUploadMutation>;
export type PresignedUploadMutationOptions = Apollo.BaseMutationOptions<PresignedUploadMutation, PresignedUploadMutationVariables>;
export const GetInquiryListDocument = gql`
    query getInquiryList($take: Int, $skip: Int) {
  getMe {
    InquiryAggregate {
      _count {
        id
      }
    }
    Inquiry(orderBy: {createdAt: desc}, take: $take, skip: $skip) {
      id
      uuid
      inquiryType
      title
      createdAt
      state
    }
  }
}
    `;

/**
 * __useGetInquiryListQuery__
 *
 * To run a query within a React component, call `useGetInquiryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInquiryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInquiryListQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetInquiryListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInquiryListQuery, GetInquiryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetInquiryListQuery, GetInquiryListQueryVariables>(GetInquiryListDocument, options);
      }
export function useGetInquiryListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInquiryListQuery, GetInquiryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetInquiryListQuery, GetInquiryListQueryVariables>(GetInquiryListDocument, options);
        }
export function useGetInquiryListSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetInquiryListQuery, GetInquiryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetInquiryListQuery, GetInquiryListQueryVariables>(GetInquiryListDocument, options);
        }
export type GetInquiryListQueryHookResult = ReturnType<typeof useGetInquiryListQuery>;
export type GetInquiryListLazyQueryHookResult = ReturnType<typeof useGetInquiryListLazyQuery>;
export type GetInquiryListSuspenseQueryHookResult = ReturnType<typeof useGetInquiryListSuspenseQuery>;
export type GetInquiryListQueryResult = Apollo.QueryResult<GetInquiryListQuery, GetInquiryListQueryVariables>;
export const GetNoticeDocument = gql`
    query getNotice($where: NoticeWhereInput) {
  findFirstNotice(where: $where) {
    content
    createdAt
    important
    title
    url
    views
  }
}
    `;

/**
 * __useGetNoticeQuery__
 *
 * To run a query within a React component, call `useGetNoticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoticeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetNoticeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNoticeQuery, GetNoticeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNoticeQuery, GetNoticeQueryVariables>(GetNoticeDocument, options);
      }
export function useGetNoticeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNoticeQuery, GetNoticeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNoticeQuery, GetNoticeQueryVariables>(GetNoticeDocument, options);
        }
export function useGetNoticeSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetNoticeQuery, GetNoticeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetNoticeQuery, GetNoticeQueryVariables>(GetNoticeDocument, options);
        }
export type GetNoticeQueryHookResult = ReturnType<typeof useGetNoticeQuery>;
export type GetNoticeLazyQueryHookResult = ReturnType<typeof useGetNoticeLazyQuery>;
export type GetNoticeSuspenseQueryHookResult = ReturnType<typeof useGetNoticeSuspenseQuery>;
export type GetNoticeQueryResult = Apollo.QueryResult<GetNoticeQuery, GetNoticeQueryVariables>;
export const GetNoticeListDocument = gql`
    query getNoticeList($take: Int, $skip: Int) {
  findManyNotice(
    orderBy: [{important: desc}, {createdAt: desc}]
    take: $take
    skip: $skip
  ) {
    id
    uuid
    createdAt
    title
    views
    important
  }
  aggregateNotice {
    _count {
      id
    }
  }
}
    `;

/**
 * __useGetNoticeListQuery__
 *
 * To run a query within a React component, call `useGetNoticeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoticeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoticeListQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetNoticeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNoticeListQuery, GetNoticeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNoticeListQuery, GetNoticeListQueryVariables>(GetNoticeListDocument, options);
      }
export function useGetNoticeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNoticeListQuery, GetNoticeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNoticeListQuery, GetNoticeListQueryVariables>(GetNoticeListDocument, options);
        }
export function useGetNoticeListSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetNoticeListQuery, GetNoticeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetNoticeListQuery, GetNoticeListQueryVariables>(GetNoticeListDocument, options);
        }
export type GetNoticeListQueryHookResult = ReturnType<typeof useGetNoticeListQuery>;
export type GetNoticeListLazyQueryHookResult = ReturnType<typeof useGetNoticeListLazyQuery>;
export type GetNoticeListSuspenseQueryHookResult = ReturnType<typeof useGetNoticeListSuspenseQuery>;
export type GetNoticeListQueryResult = Apollo.QueryResult<GetNoticeListQuery, GetNoticeListQueryVariables>;
export const IncrementViewDocument = gql`
    mutation incrementView($where: NoticeWhereUniqueInput!) {
  updateOneNotice(data: {views: {increment: 1}}, where: $where) {
    id
  }
}
    `;
export type IncrementViewMutationFn = Apollo.MutationFunction<IncrementViewMutation, IncrementViewMutationVariables>;

/**
 * __useIncrementViewMutation__
 *
 * To run a mutation, you first call `useIncrementViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementViewMutation, { data, loading, error }] = useIncrementViewMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useIncrementViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IncrementViewMutation, IncrementViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<IncrementViewMutation, IncrementViewMutationVariables>(IncrementViewDocument, options);
      }
export type IncrementViewMutationHookResult = ReturnType<typeof useIncrementViewMutation>;
export type IncrementViewMutationResult = Apollo.MutationResult<IncrementViewMutation>;
export type IncrementViewMutationOptions = Apollo.BaseMutationOptions<IncrementViewMutation, IncrementViewMutationVariables>;