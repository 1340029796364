import { GetNoticeListQuery } from "generated/types";
import { isNull, isUndefined } from "lodash";
import moment from "moment";

export const getNotice = (
  data: GetNoticeListQuery | undefined,
  page: number,
) => {
  if (isNull(data) || isUndefined(data)) return defaultList;
  const { findManyNotice, aggregateNotice } = data;
  const getTotalCount = () => {
    if (isNull(aggregateNotice) || isUndefined(aggregateNotice)) return 0;
    const { _count } = aggregateNotice;
    if (isNull(_count) || isUndefined(_count)) return 0;
    const { id } = _count;
    return id;
  };
  const list = findManyNotice.map((notice, index) => {
    const { id, uuid, title, createdAt, views, important } = notice;
    return {
      id,
      uuid,
      title,
      createdAt: moment(createdAt).format("YYYY.MM.DD"),
      views,
      isNew: moment().diff(createdAt, "days") < 3,
      important,
      num: getTotalCount() - (page * 20 + index),
    };
  });
  return {
    totalCount: getTotalCount(),
    list,
  };
};
export type GetNotice = ReturnType<typeof getNotice>;

export const defaultList = {
  totalCount: 0,
  list: [],
};
export interface ListType {
  id: number;
  uuid: string;
  title: string;
  createdAt: string;
  views: number;
  important: boolean;
  isNew: boolean;
  num: number;
}

export interface TablePropType {
  goDetail(uuid: string): void;
  list: ListType[];
}
