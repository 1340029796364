import * as React from "react";
import { SVGProps } from "react";

export const BuilderhubSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.85 0H6.15A6.15 6.15 0 0 0 0 6.15v27.7A6.15 6.15 0 0 0 6.15 40h27.7A6.15 6.15 0 0 0 40 33.85V6.15A6.15 6.15 0 0 0 33.85 0Z"
      fill="#664AE3"
    />
    <path
      d="M13.133 9.733H9.011a.55.55 0 0 0-.55.55v19.44c0 .303.246.55.55.55h4.122a.55.55 0 0 0 .55-.55v-19.44a.55.55 0 0 0-.55-.55ZM32.917 26.594h-3.7c.095 0 .333-.339.4-.41a2.9 2.9 0 0 0 .311-.418c.889-1.383.95-3.172.406-4.688-.6-1.673-1.934-2.728-3.628-3.19.1.028.344-.116.433-.16 1.411-.728 1.6-2.717 1.273-4.09a4.657 4.657 0 0 0-.65-1.505c-.523-.767-1.267-1.361-2.24-1.778-.972-.416-2.138-.627-3.494-.627h-6.4c-.3 0-.55.244-.55.55v2.588c0 .3.245.55.55.55h5.006c.872 0 1.56.2 2.06.606.5.405.756.961.756 1.672 0 0 .05.833-.716 1.456-.528.428-1.44.428-2.484.428h-4.616c-.3 0-.55.244-.55.55v2.205c0 .3.244.55.55.55h5.989c.61 0 1.244.056 1.827.278.645.25 1.345.839 1.545 1.522.316 1.072.205 2.506-.745 3.233-.572.44-1.394.662-2.466.662h-6.145c-.3 0-.55.244-.55.55v2.588c0 .3.245.55.55.55h17.3c.3 0 .55-.244.55-.55V27.14c0-.3-.244-.55-.55-.55l-.022.005Z"
      fill="#fff"
    />
  </svg>
);

export const BuilderhubSymbolWithBackground = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6175 0H3.3825C1.5144 0 0 1.5144 0 3.3825V18.6175C0 20.4856 1.5144 22 3.3825 22H18.6175C20.4856 22 22 20.4856 22 18.6175V3.3825C22 1.5144 20.4856 0 18.6175 0ZM7.22324 5.35355H4.95602C4.78895 5.35355 4.65352 5.48898 4.65352 5.65605V16.3474C4.65352 16.5145 4.78895 16.6499 4.95602 16.6499H7.22324C7.3903 16.6499 7.52574 16.5145 7.52574 16.3474V5.65605C7.52574 5.48898 7.3903 5.35355 7.22324 5.35355ZM16.0694 14.6268H18.1044L18.1166 14.6238C18.2846 14.6238 18.4191 14.7613 18.4191 14.9263V16.344C18.4191 16.5121 18.2816 16.6465 18.1166 16.6465H8.60158C8.43352 16.6465 8.29908 16.509 8.29908 16.344V14.9201C8.29908 14.7521 8.43658 14.6176 8.60158 14.6176H11.981C12.5707 14.6176 13.023 14.4954 13.3377 14.254C13.8602 13.8538 13.9213 13.0654 13.7471 12.4757C13.6371 12.0999 13.2521 11.776 12.8977 11.6385C12.5769 11.5163 12.2285 11.4857 11.8924 11.4857H8.59852C8.43047 11.4857 8.29602 11.3482 8.29602 11.1832V9.97015C8.29602 9.80209 8.43352 9.66765 8.59852 9.66765H11.1377C11.7121 9.66765 12.2132 9.66765 12.5035 9.43237C12.9252 9.09015 12.8977 8.63181 12.8977 8.63181C12.8977 8.2407 12.7571 7.93515 12.4821 7.71209C12.2071 7.48903 11.8282 7.37903 11.3485 7.37903H8.59547C8.42741 7.37903 8.29297 7.24153 8.29297 7.07653V5.65265C8.29297 5.48459 8.43047 5.35015 8.59547 5.35015H12.1155C12.861 5.35015 13.5027 5.46626 14.0374 5.69542C14.5721 5.92459 14.9816 6.25153 15.2688 6.6732C15.4369 6.92376 15.556 7.20792 15.6263 7.50126C15.8066 8.25598 15.7027 9.34987 14.9266 9.75015C14.919 9.75393 14.9094 9.75902 14.8984 9.76488C14.838 9.7969 14.7347 9.85167 14.6882 9.83876C15.6202 10.0924 16.3535 10.6729 16.6835 11.5926C16.983 12.4268 16.9494 13.4107 16.4605 14.1715C16.4085 14.254 16.3535 14.3304 16.2894 14.4007C16.2806 14.4102 16.2663 14.4282 16.249 14.45C16.1942 14.5191 16.1088 14.6268 16.0694 14.6268Z"
      fill="#664AE3"
    />
  </svg>
);
