import * as React from "react";
import { signOutThunk, useAppDispatch, useAppSelector } from "store";
import { useRouter } from "next/router";
import { ContentBox } from "./styles";
import { CustomToolbar } from "./Toolbar";
import { CustomDrawer } from "./Drawer";
import { HomeAppBar } from "./HomeAppBar";
import { getRuntimeVariable } from "@builderhub/env";

interface PropType {
  children: React.ReactNode;
}

export function Layout(props: PropType): JSX.Element {
  const { children } = props;
  const { authenticated, name, partnerId } = useAppSelector(
    (state) => state.auth,
  );
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [state, setState] = React.useState({
    drawerOpen: false,
    popperOpen: false,
  });
  const mainRef = React.useRef<HTMLDivElement>(null);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleDrawerToggle = () => {
    setState({ ...state, drawerOpen: !state.drawerOpen });
  };
  const handleDrawerClose = () => {
    setState({ ...state, drawerOpen: false });
  };
  const handlePopperToggle = () => {
    setState({ ...state, popperOpen: !state.popperOpen });
  };
  const handlePopperClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setState({ ...state, popperOpen: false });
  };
  const goSignIn = () => {
    router.push(
      `${getRuntimeVariable(
        "NEXT_PUBLIC_AUTH_URL",
      )}/signin?forward=${getRuntimeVariable("NEXT_PUBLIC_HOST_URL")}`,
    );
  };

  const goSignUp = () => {
    router.push(
      `${getRuntimeVariable(
        "NEXT_PUBLIC_AUTH_URL",
      )}/signup?forward=${getRuntimeVariable("NEXT_PUBLIC_HOST_URL")}`,
    );
  };
  const goHomeApp = () => {
    handleDrawerClose();
    router.push("/");
  };
  const goPartner = () => {
    handleDrawerClose();
    router.push(`${getRuntimeVariable("NEXT_PUBLIC_PARTNER_URL")}`);
  };
  const goCustomer = () => {
    handleDrawerClose();
    router.push(`${getRuntimeVariable("NEXT_PUBLIC_CUSTOMER_URL")}`);
  };
  const goMyPage = () => {
    router.push(
      `${getRuntimeVariable(
        "NEXT_PUBLIC_AUTH_URL",
      )}/mypage?forward=${getRuntimeVariable("NEXT_PUBLIC_HOST_URL")}`,
    );
  };
  const goSignOut = () => {
    handleDrawerToggle();
    dispatch(signOutThunk());
    router.push("/");
  };
  const goRegist = () => {
    handleDrawerClose();
    if (
      window.location.href ===
      `${getRuntimeVariable("NEXT_PUBLIC_HOST_URL")}/partner-registration`
    ) {
      window.location.reload();
    }
    router.push("/partner-registration");
  };

  const goHelp = () => {
    handleDrawerClose();
    router.push("/help/notice");
  };
  // if (!hydrated) {
  //   // Returns null on first render, so the client and server match
  //   return null;
  // }
  if (router.asPath.includes("/content")) {
    return <main>{children}</main>;
  }
  return (
    <div>
      <HomeAppBar
        component="div"
        sx={{
          background: "none",
          boxShadow: "none",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <CustomToolbar
          goHomeApp={goHomeApp}
          goPartner={goPartner}
          goCustomer={goCustomer}
          authenticated={authenticated}
          name={name}
          anchorRef={anchorRef}
          open={state.popperOpen}
          handlePopperToggle={handlePopperToggle}
          handlePopperClose={handlePopperClose}
          goMyPage={goMyPage}
          goSignOut={goSignOut}
          goSignIn={goSignIn}
          goSignUp={goSignUp}
          goRegist={goRegist}
          handleDrawerToggle={handleDrawerToggle}
          partnerId={partnerId}
          goHelp={goHelp}
        />
      </HomeAppBar>
      <CustomDrawer
        authenticated={authenticated}
        open={state.drawerOpen}
        name={name}
        goSignIn={goSignIn}
        handleDrawerToggle={handleDrawerToggle}
        goRegist={goRegist}
        goCustomer={goCustomer}
        goMyPage={goMyPage}
        goSignOut={goSignOut}
        partnerId={partnerId}
        goPartner={goPartner}
        goHelp={goHelp}
      />
      <ContentBox ref={mainRef} component="main">
        {children}
      </ContentBox>
    </div>
  );
}
