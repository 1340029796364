/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { colors, theme } from "@builderhub/mui-theme";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { RegBtn } from "../Drawer/styles";
export const NavBarWrapper = styled(Box)(() => ({
  maxWidth: 1400,
  width: "100%",
  marginRight: 8,
  justifyContent: "space-between",
  alignItems: "center",
}));
export const GnbWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const NavBarWrapperApp = styled(Box)(() => ({
  maxWidth: 1400,
  width: "100%",
  marginRight: 1,
  justifyContent: "space-between",
  alignItems: "center",
  flexGrow: 1,
}));
export const CustomerBtn = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.gray._70,
  cursor: "pointer",
  marginLeft: 40,
  ":hover": { cursor: "pointer" },
  [theme.breakpoints.down("lg")]: {
    marginLeft: 16,
  },
}));
export const HelpBtn = styled(CustomerBtn)((props: { path: string }) => ({
  color: props.path.startsWith("/help") ? colors.purple._60 : colors.gray._70,
}));
export const RegBtnRound = styled(RegBtn)(() => ({
  borderRadius: 9999,
  marginTop: 0,
  fontSize: 16,
  padding: "10px 20px",
  height: 40,
}));
export const DashboardBtn = styled(Button)(() => ({
  borderRadius: 20,
  backgroundColor: colors.white,
  border: `1px solid ${colors.purple._60}`,
  fontSize: 16,
  fontWeight: 500,
  width: 126,
}));

export const BuilderhubButton = styled(Button)(() => ({
  backgroundColor: "transparent",
  padding: "6px 10px",
  "&:hover": {
    backgroundColor: "transparent",
    "& .MuiTypography-root": {
      color: colors.grayScale.label,
    },
  },
}));
export const MenuDiv = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 24,
  svg: {
    zIndex: 1,
    marginBottom: -2,
    filter: `drop-shadow(0px -3px 2px rgb(0 0 0 / 0.08))`,
  },
}));
export const CustomMenuItem = styled(MenuItem)(() => ({
  ":hover": {
    background: "#EBE7FD",
  },
}));
export const MenuItemTypo = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 14,
  color: colors.gray._70,
  width: "100%",
  textAlign: "center",
}));
export const MenuPaper = styled(Paper)(() => ({
  boxShadow:
    "2px 2px 6px rgba(0, 0, 0, 0.08), 0px -2px 2px rgba(0, 0, 0, 0.05)",
  borderRadius: 4,
  background: colors.white,
  "& .MuiMenuItem-root": {
    padding: "8px 28px",
  },
}));

export const GnbSignInUpTypo = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
  color: colors.gray._70,
  height: 20,
  cursor: "pointer",
}));
export const GnbInfoWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const BuilderhubToolbar = styled(Toolbar)((props: { path: string }) => ({
  display: "flex",
  justifyContent: "center",
  background: props.path === "/" ? `rgba(255, 255, 255, 0.80)` : "none",
  backdropFilter: "blur(4px)",
  borderBottom:
    props.path === "/"
      ? `solid 1px ${colors.gray._20}`
      : `solid 1px ${colors.gray._10}`,
  minHeight: 72,
  maxHeight: 72,
  "&.MuiToolbar-root": {
    padding: 0,
  },
  [theme.breakpoints.down("lg")]: {
    minHeight: 52,
    maxHeight: 52,
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: 48,
    maxHeight: 48,
  },
}));
