import React from "react";
import { IconButton, Box } from "@mui/material";
import { DrawerIcon, BuilderhubLogoIcon, BuilderhubLogo } from "components";
import { CursorDiv } from "../styles";
import {
  NavBarWrapper,
  GnbWrapper,
  NavBarWrapperApp,
  GnbInfoWrapper,
  BuilderhubToolbar,
} from "./styles";
import { PropType } from "./types";
import { LeftBox } from "./LeftBox";
import { RightBox } from "./RightBox";
import { useRouter } from "next/router";

export const CustomToolbar = (props: PropType) => {
  const {
    goHomeApp,
    goPartner,
    goCustomer,
    authenticated,
    handleDrawerToggle,
    goMyPage,
    goSignOut,
    goSignIn,
    goSignUp,
    goHelp,
  } = props;
  const router = useRouter();

  return (
    <BuilderhubToolbar path={router.pathname}>
      <Box
        component="div"
        sx={{
          maxWidth: 1200,
          width: "100%",
          px: { xs: "20px", sm: "40px", lg: "16px" },
        }}
      >
        <NavBarWrapper
          sx={{
            display: { xs: "none", lg: "flex" },
          }}
        >
          <GnbWrapper>
            <CursorDiv onClick={goHomeApp}>
              <BuilderhubLogoIcon width={27} height={27} />
              <BuilderhubLogo style={{ marginLeft: 8 }} />
            </CursorDiv>
          </GnbWrapper>
          <GnbInfoWrapper>
            <LeftBox
              goCustomer={goCustomer}
              goPartner={goPartner}
              goHelp={goHelp}
              path={router.pathname}
            />
            <RightBox
              authenticated={authenticated}
              goMyPage={goMyPage}
              goSignOut={goSignOut}
              goSignIn={goSignIn}
              goSignUp={goSignUp}
            />
          </GnbInfoWrapper>
        </NavBarWrapper>
        <NavBarWrapperApp
          sx={{
            display: { xs: "flex", lg: "none" },
          }}
        >
          <CursorDiv>
            <BuilderhubLogoIcon
              onClick={goHomeApp}
              style={{ cursor: "pointer" }}
            />
            <Box sx={{ display: { xs: "none", sm: "flex" } }}>
              <BuilderhubLogo style={{ marginLeft: 8 }} />
            </Box>
          </CursorDiv>
          <IconButton
            aria-haspopup="true"
            onClick={handleDrawerToggle}
            sx={{ p: 0 }}
          >
            <DrawerIcon />
          </IconButton>
        </NavBarWrapperApp>
      </Box>
    </BuilderhubToolbar>
  );
};
