import { InquiryType } from "generated/types";
import { ItemBox, Sub, Wrap } from "./styes";
import { getType } from "views/Help/Inquiry/types";
import { CheckIcon } from "components/Icons";

interface PropType {
  type: InquiryType;
  value: InquiryType | null;
  handleType(val: InquiryType): void;
  sub: JSX.Element;
}
export const SelectBox = (props: PropType) => {
  const { type, value, handleType, sub } = props;
  return (
    <ItemBox>
      <Wrap type={type} value={value} onClick={() => handleType(type)}>
        <CheckIcon /> {getType(type)}
      </Wrap>
      <Sub type={type} value={value}>
        {sub}
      </Sub>
    </ItemBox>
  );
};
