import * as React from "react";
import { SVGProps } from "react";

export const QuestionMark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ? props.width : 60}
    height={89}
    viewBox="0 0 60 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.6}
      d="M21.9481 63.2644C19.4137 63.2644 17.3585 61.2092 17.3585 58.6747V57.0455C17.3585 53.1054 17.7951 49.8789 18.6789 47.3657C19.5628 44.8526 21.4263 42.7442 24.2802 41.051C26.5164 39.688 28.529 38.474 30.2861 37.3878C32.0538 36.3017 33.4807 35.2794 34.5669 34.3316C36.1962 32.8408 37.2823 31.4671 37.8254 30.1999C38.3685 28.9434 38.6454 27.4312 38.6454 25.6635C38.6454 23.2889 37.8254 21.4147 36.1962 20.0623C34.5669 18.6992 32.5969 18.0284 30.2861 18.0284C27.9753 18.0284 25.7817 18.5928 23.9181 19.7109C22.0439 20.829 20.8087 22.4476 20.2017 24.5454C19.4563 26.9946 18.0932 28.8582 16.1232 30.1467C14.1532 31.4352 11.7466 31.9783 8.8927 31.7759C7.73198 31.712 6.61386 31.4565 5.52769 31.0092C4.44151 30.5726 3.50442 29.9444 2.72706 29.1244C1.9497 28.3044 1.32142 27.3035 0.842226 26.1214C0.384328 24.9394 0.182001 23.5551 0.245894 22.0004C0.309787 18.8803 1.16169 15.9732 2.79095 13.2897C4.42021 10.6062 6.59256 8.28475 9.308 6.31472C12.0234 4.3447 15.1861 2.80063 18.7854 1.68251C22.3847 0.564385 26.2183 0 30.2967 0C34.3752 0 38.4005 0.52179 41.9678 1.57602C45.5351 2.63025 48.602 4.20627 51.1896 6.31472C53.7666 8.42318 55.7899 11.0854 57.2488 14.312C58.7077 17.5385 59.4424 21.3295 59.4424 25.6742C59.4424 29.135 59.0378 32.1274 58.2178 34.6405C57.3979 37.1536 56.0774 39.4963 54.2458 41.6687C52.6805 43.4364 50.7637 45.204 48.4848 46.9717C46.206 48.7394 43.81 50.4326 41.2969 52.0619C39.87 53.0096 38.9542 54.0106 38.5495 55.0648C38.1449 56.119 37.9426 57.3223 37.9426 58.6854C37.9426 61.2198 35.8873 63.275 33.3529 63.275H21.9374L21.9481 63.2644ZM16.7515 78.343C16.7515 75.1484 17.6992 72.6353 19.6054 70.8037C21.5115 68.9721 24.195 68.0563 27.6558 68.0563C31.1167 68.0563 33.8002 68.9934 35.7063 70.8569C37.6124 72.7311 38.5602 75.2229 38.5602 78.343C38.5602 81.4631 37.6124 83.9656 35.7063 85.8291C33.8002 87.6926 31.1167 88.6297 27.6558 88.6297C24.195 88.6297 21.5115 87.714 19.6054 85.8824C17.6992 84.0508 16.7515 81.5377 16.7515 78.343Z"
      fill="#A291EE"
    />
  </svg>
);
