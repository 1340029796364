/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { colors, theme } from "@builderhub/mui-theme";
import { Box, Chip, styled, Typography } from "@mui/material";

export const CustomizedChip = styled(Chip)(() => ({
  width: "fit-content",
  padding: "4px 15px 4px 15px",
  color: colors.purple._60,
  background: "#F5F3FE",
  fontSize: 16,
  fontWeight: 500,
  borderRadius: 8,
}));

export const MainTypo = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 28,
  color: colors.gray._100,
  [theme.breakpoints.down("lg")]: {
    fontSize: 18,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
}));

export const SubTypo = styled(Typography)(() => ({
  color: colors.gray._60,
  fontSize: 20,
  [theme.breakpoints.down("lg")]: {
    fontSize: 14,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
}));

export const ImageDiv = styled(`div`)(() => ({
  width: "100vw",
  maxWidth: 504,
}));

//

export const Wrap = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));
export const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: 1200,
  width: "100%",
  alignItems: "center",
  padding: "0 16px",
  [theme.breakpoints.down("lg")]: {
    padding: "0 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 20px",
  },
}));
export const ContentBox = styled(Box)(() => ({
  display: "flex",
  gap: "64px",
  flexDirection: "row",
  marginTop: 128,
  [theme.breakpoints.down("lg")]: {
    marginTop: 80,
    gap: "36px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "16px",
  },
}));
export const ReverseBox = styled(Box)(() => ({
  display: "flex",
  gap: "64px",
  flexDirection: "row",
  marginTop: 128,
  textAlign: "end",
  [theme.breakpoints.down("lg")]: {
    marginTop: 80,
    gap: "36px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    gap: "16px",
  },
}));

export const ImgBox = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  [theme.breakpoints.down("lg")]: {
    img: {
      // width: "100%",
      width: 320,
      height: 230,
    },
  },
  [theme.breakpoints.down("sm")]: {
    alignSelf: "center",
    img: {
      // width: "100%",
      width: 180,
      height: 150,
    },
  },
}));

export const Info = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  justifyContent: "center",
  minWidth: 504,
  [theme.breakpoints.down("lg")]: {
    minWidth: 320,
  },
  [theme.breakpoints.down("md")]: {
    alignItems: "center",
    textAlign: "center",
    minWidth: 0,
  },
}));

export const LeftInfo = styled(Info)(() => ({
  alignItems: "flex-end",
}));
export const RightInfo = styled(Info)(() => ({
  alignItems: "flex-start",
}));
