/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { colors, theme } from "@builderhub/mui-theme";
import { styled, Button, Typography, Box } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#F5F3FE",
  minWidth: 360,
}));

export const EndingDiv = styled("div")(() => ({
  width: "100%",
  maxWidth: 720,
  minWidth: 250,
  padding: "140px 16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  textAlign: "center",
  gap: 80,
  [theme.breakpoints.down("lg")]: {
    padding: "90px 40px",
    gap: 52,
  },
  [theme.breakpoints.down("sm")]: {
    padding: "60px 20px",
    gap: 48,
  },
}));

export const TextBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  [theme.breakpoints.down("lg")]: {
    gap: 10,
  },
  [theme.breakpoints.down("sm")]: {
    gap: 8,
  },
}));

export const MainTypo = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 36,
  color: colors.purple._60,
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    fontSize: 24,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
}));

export const SubTypo = styled(Typography)(() => ({
  fontSize: 32,
  color: colors.gray._60,
  [theme.breakpoints.down("lg")]: {
    fontSize: 21,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
}));

export const ImageDiv = styled(Box)(() => ({
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    img: {
      width: 386,
      height: 295,
    },
  },
  [theme.breakpoints.down("sm")]: {
    img: {
      width: 182,
      height: 162,
    },
  },
}));

export const EndingButton = styled(Button)(() => ({
  width: "fix-content",
  fontWeight: 500,
  fontSize: 24,
  padding: "16px 76px",
  border: "none",
  borderRadius: 8,
  maxWidth: 332,
  maxHeight: 64,
  whiteSpace: "nowrap",
  color: colors.white,
  backgroundColor: colors.purple._60,
  "&:hover": {
    backgroundColor: colors.purple._80,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    padding: "10px 48px",
  },
  [theme.breakpoints.down("sm")]: {
    fontWeight: 700,
    padding: "8px 40px",
    borderRadius: 4,
  },
}));
