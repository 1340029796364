/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { colors, theme } from "@builderhub/mui-theme";
import { Box, Typography, styled } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

export const Wrap = styled(Box)(() => ({
  width: "100%",
  maxWidth: 1200,
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "224px 16px",
  [theme.breakpoints.down("lg")]: {
    padding: "144px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "80px 20px",
  },
}));

export const PartnerTypo = styled(Typography)(() => ({
  textAlign: "center",
  fontSize: 24,
  fontWeight: 500,
  color: colors.gray._70,
  letterSpacing: -0.3,
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
}));
export const BannerWrap = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: 32,
  marginTop: 32,
  img: {
    cursor: "pointer",
  },
  [theme.breakpoints.down("lg")]: {
    gap: 20,
    marginTop: 20,
    img: {
      width: 205,
      height: 72,
    },
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    marginTop: 28,
    img: {
      width: 183,
      height: 64,
    },
  },
}));
export const LogoBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
}));

export const LogoText = styled(Box)(() => ({
  fontSize: 14,
  fontWeight: 500,
  color: "#0086BA",
  letterSpacing: -0.3,
  display: "flex",
  marginTop: 20,
}));
