import * as React from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { EmptyBox, EmptyTypo } from "../Notice/styles";
import { EmptyListIcon } from "components";
import { PresenterPropType } from "./types";
import { TableBody } from "./TableBody";
import {
  CustomPagination,
  HeaderBox,
  PaginationBox,
  RegBtn,
  Title,
} from "./styles";
import { Head as TableHead } from "./TableHead";

export const Presenter = (props: PresenterPropType) => {
  const { goRegist, goDetail, list, totalCount, page, handleSetPage } = props;
  return (
    <>
      <HeaderBox>
        <Title>문의내역</Title>
        <RegBtn onClick={goRegist}>문의 등록</RegBtn>
      </HeaderBox>
      <TableContainer>
        <Table>
          <TableHead />
          <TableBody goDetail={goDetail} list={list} />
        </Table>
      </TableContainer>
      {list.length === 0 ? (
        <EmptyBox>
          <EmptyListIcon />
          <EmptyTypo>등록된 문의가 없어요.</EmptyTypo>
        </EmptyBox>
      ) : (
        <PaginationBox>
          <CustomPagination
            count={Math.ceil(totalCount / 20)}
            page={page + 1}
            onChange={handleSetPage}
          />
        </PaginationBox>
      )}
    </>
  );
};
