import React from "react";
import { CircularProgress, Grid } from "@mui/material";

export const Loader = () => {
  return (
    <Grid
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Grid>
  );
};

export const ListLoader = () => {
  return (
    <div
      style={{
        minHeight: 300,
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export const PageLoader = () => {
  return (
    <div
      style={{
        height: "calc(100vh - 72px)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};
