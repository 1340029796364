import { colors, theme } from "@builderhub/mui-theme";
import { Box, styled } from "@mui/material";

export const TabBox = styled(Box)(() => ({
  width: "100%",
  background: colors.white,
  borderRadius: "32px 32px 0px 0px",
  display: "flex",
  justifyContent: "center",
  // alignItems: "center",
  minWidth: 360,
}));
export const Wrap = styled(Box)(() => ({
  maxWidth: 1200,
  width: "100%",
  padding: "48px 16px",
  [theme.breakpoints.down("lg")]: {
    padding: "48px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "32px 20px",
  },
}));
