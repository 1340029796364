import * as React from "react";
import { SVGProps } from "react";

export const BuilderhubFooterLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="216"
    height="34"
    viewBox="0 0 216 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5413 2H4.45875C1.99625 2 0 3.99625 0 6.45875V26.5413C0 29.0037 1.99625 31 4.45875 31H24.5413C27.0037 31 29 29.0037 29 26.5413V6.45875C29 3.99625 27.0037 2 24.5413 2ZM9.5216 9.05691H6.53299C6.31277 9.05691 6.13424 9.23543 6.13424 9.45566V23.5488C6.13424 23.7691 6.31277 23.9476 6.53299 23.9476H9.5216C9.74183 23.9476 9.92035 23.7691 9.92035 23.5488V9.45566C9.92035 9.23543 9.74183 9.05691 9.5216 9.05691ZM21.1823 21.2807H23.8648L23.8809 21.2767C24.1025 21.2767 24.2797 21.458 24.2797 21.6755V23.5443C24.2797 23.7659 24.0984 23.9431 23.8809 23.9431H11.3384C11.1169 23.9431 10.9397 23.7618 10.9397 23.5443V21.6674C10.9397 21.4459 11.1209 21.2687 11.3384 21.2687H15.7932C16.5705 21.2687 17.1666 21.1075 17.5815 20.7893C18.2703 20.2617 18.3508 19.2225 18.1212 18.4452C17.9762 17.9498 17.4687 17.5228 17.0015 17.3416C16.5786 17.1805 16.1194 17.1402 15.6764 17.1402H11.3344C11.1129 17.1402 10.9357 16.9589 10.9357 16.7414V15.1424C10.9357 14.9209 11.1169 14.7437 11.3344 14.7437H14.6815C15.4387 14.7437 16.0993 14.7437 16.4819 14.4335C17.0378 13.9824 17.0015 13.3782 17.0015 13.3782C17.0015 12.8627 16.8162 12.4599 16.4537 12.1659C16.0912 11.8718 15.5918 11.7268 14.9594 11.7268H11.3304C11.1089 11.7268 10.9316 11.5456 10.9316 11.3281V9.45115C10.9316 9.22962 11.1129 9.0524 11.3304 9.0524H15.9704C16.9532 9.0524 17.799 9.20546 18.5039 9.50754C19.2087 9.80962 19.7484 10.2406 20.1271 10.7964C20.3486 11.1267 20.5057 11.5013 20.5983 11.888C20.8359 12.8828 20.699 14.3248 19.6759 14.8524C19.666 14.8574 19.6533 14.8641 19.6388 14.8718C19.5592 14.914 19.4231 14.9862 19.3618 14.9692C20.5903 15.3035 21.5569 16.0688 21.9919 17.2811C22.3866 18.3807 22.3423 19.6777 21.6979 20.6806C21.6294 20.7893 21.5569 20.89 21.4723 20.9827C21.4607 20.9952 21.442 21.0189 21.4192 21.0477C21.3469 21.1388 21.2344 21.2807 21.1823 21.2807Z"
      fill="white"
    />
    <path
      d="M51.3795 18.2767C50.6536 17.8421 49.799 17.576 48.8204 17.4829V17.4297C49.5509 17.3277 50.2079 17.0661 50.7822 16.6448C51.3565 16.2235 51.8114 15.7003 52.1468 15.075C52.4776 14.4498 52.6476 13.789 52.6476 13.0928V13.0662C52.6476 12.064 52.3995 11.1905 51.8987 10.441C51.3979 9.69161 50.6949 9.11513 49.7852 8.70717C48.8756 8.2992 47.8096 8.09521 46.5829 8.09521H38.3635V27.9615H46.6243C48.0899 27.9615 49.3442 27.7353 50.3917 27.2874C51.4392 26.8396 52.2433 26.1966 52.8084 25.3585C53.3735 24.5248 53.6583 23.5226 53.6583 22.3564V22.3298C53.6583 21.4473 53.4608 20.6624 53.0657 19.9662C52.6705 19.2744 52.11 18.7068 51.3841 18.2722L51.3795 18.2767ZM45.8065 10.614C46.9689 10.614 47.8648 10.8579 48.4942 11.3457C49.1282 11.8334 49.4453 12.5341 49.4453 13.452V13.4786C49.4453 14.4719 49.0731 15.2347 48.3334 15.7668C47.5937 16.2989 46.5186 16.565 45.1081 16.565H41.5566V10.614H45.8065V10.614ZM50.3733 22.21C50.3733 23.2654 49.9966 24.0681 49.2477 24.6179C48.4942 25.1678 47.4053 25.4427 45.9811 25.4427H41.5566V18.9418H45.7513C46.7667 18.9418 47.6213 19.0616 48.3058 19.3055C48.9904 19.5494 49.505 19.9086 49.8542 20.3875C50.2033 20.8664 50.3733 21.4606 50.3733 22.179V22.2056V22.21Z"
      fill="white"
    />
    <path
      d="M69.8629 20.7334C69.8629 21.6957 69.6654 22.5382 69.2656 23.261C68.8659 23.9838 68.287 24.5426 67.5244 24.9461C66.7617 25.3496 65.8382 25.5536 64.7401 25.5536C63.6421 25.5536 62.7186 25.3496 61.9513 24.9461C61.1841 24.5426 60.6006 23.9794 60.1963 23.261C59.792 22.5382 59.5898 21.7001 59.5898 20.7334V8.09082H56.3921V20.9817C56.3921 22.4318 56.7275 23.7089 57.4029 24.8086C58.0782 25.9084 59.0385 26.7642 60.2836 27.3762C61.5286 27.9881 63.0126 28.2941 64.7356 28.2941C66.4585 28.2941 67.9425 27.9881 69.1875 27.3717C70.4326 26.7554 71.3929 25.8995 72.0636 24.8042C72.7344 23.7089 73.0698 22.4318 73.0698 20.9817V8.09082H69.8583V20.7334H69.8629Z"
      fill="white"
    />
    <path d="M80.0488 8.09082H76.8511V27.9615H80.0488V8.09082Z" fill="white" />
    <path
      d="M87.0415 8.09082H83.8438V27.9615H96.6438V25.292H87.0415V8.09082Z"
      fill="white"
    />
    <path
      d="M111.874 9.25704C110.423 8.48101 108.677 8.09521 106.632 8.09521H99.3684V27.9615H106.632C108.686 27.9615 110.441 27.5713 111.888 26.7819C113.34 25.997 114.447 24.8574 115.219 23.3674C115.991 21.8774 116.377 20.0815 116.377 17.9884V17.9618C116.377 15.8688 115.991 14.0861 115.215 12.6183C114.438 11.1505 113.326 10.0286 111.874 9.2526V9.25704ZM113.11 18.0239C113.11 19.5848 112.848 20.9063 112.325 21.9883C111.801 23.0703 111.034 23.8951 110.027 24.4538C109.021 25.0126 107.785 25.292 106.334 25.292H102.566V10.7647H106.334C107.79 10.7647 109.017 11.0441 110.023 11.5984C111.024 12.1527 111.792 12.9687 112.32 14.0507C112.848 15.1282 113.11 16.4453 113.11 17.9973V18.0239V18.0239Z"
      fill="white"
    />
    <path
      d="M119.427 28.1222H132.499V25.4526H122.625V19.2976H131.97V16.7079H122.625V10.9254H132.499V8.25146H119.427V28.1222Z"
      fill="white"
    />
    <path
      d="M149.581 17.9052C150.288 16.8942 150.642 15.7146 150.642 14.3666V14.3399C150.642 13.0894 150.362 12.0119 149.806 11.1028C149.25 10.1937 148.459 9.49311 147.43 8.99645C146.401 8.49979 145.188 8.25146 143.792 8.25146H135.788V28.1222H138.986V20.5082H143.383L147.577 28.1222H151.271L146.663 20.0249C147.899 19.6214 148.873 18.9163 149.581 17.9052V17.9052ZM143.479 17.9584H138.986V10.8412H143.383C144.609 10.8412 145.579 11.156 146.286 11.7857C146.994 12.4154 147.348 13.2757 147.348 14.3666V14.3932C147.348 15.5328 147.008 16.4108 146.337 17.0316C145.661 17.6525 144.71 17.9629 143.484 17.9629L143.479 17.9584Z"
      fill="white"
    />
    <path
      d="M168.69 16.6503H158.132V8.25146H154.934V28.1222H158.132V19.3242H168.69V28.1222H171.901V8.25146H168.69V16.6503Z"
      fill="white"
    />
    <path
      d="M189.162 20.894C189.162 21.8563 188.965 22.6989 188.565 23.4217C188.165 24.1445 187.586 24.7032 186.824 25.1068C186.061 25.5103 185.133 25.7143 184.039 25.7143C182.946 25.7143 182.018 25.5103 181.251 25.1068C180.483 24.7032 179.9 24.14 179.496 23.4217C179.091 22.6989 178.889 21.8607 178.889 20.894V8.25146H175.691V21.1424C175.691 22.5924 176.027 23.8695 176.702 24.9693C177.378 26.069 178.338 26.9249 179.583 27.5368C180.828 28.1488 182.312 28.4547 184.035 28.4547C185.758 28.4547 187.242 28.1488 188.487 27.5324C189.732 26.916 190.692 26.0602 191.363 24.9649C192.034 23.8695 192.369 22.5924 192.369 21.1424V8.25146H189.158V20.894H189.162Z"
      fill="white"
    />
    <path
      d="M210.857 20.1313C210.462 19.4395 209.902 18.8719 209.176 18.4373C208.45 18.0027 207.595 17.7367 206.617 17.6436V17.5903C207.347 17.4884 208.004 17.2267 208.578 16.8054C209.153 16.3842 209.608 15.8609 209.943 15.2357C210.278 14.6104 210.444 13.9497 210.444 13.2535V13.2269C210.444 12.2247 210.196 11.3511 209.695 10.6017C209.194 9.85226 208.491 9.27578 207.581 8.86781C206.672 8.45984 205.606 8.25586 204.379 8.25586H196.16V28.1221H204.42C205.886 28.1221 207.14 27.896 208.188 27.4481C209.235 27.0002 210.039 26.3572 210.605 25.5191C211.17 24.6854 211.454 23.6833 211.454 22.517V22.4904C211.454 21.6079 211.257 20.823 210.862 20.1268L210.857 20.1313ZM203.603 10.7746C204.765 10.7746 205.661 11.0185 206.29 11.5063C206.924 11.9941 207.241 12.6947 207.241 13.6127V13.6393C207.241 14.6326 206.869 15.3953 206.13 15.9274C205.39 16.4596 204.315 16.7256 202.904 16.7256H199.353V10.7746H203.603V10.7746ZM208.169 22.3707C208.169 23.4261 207.793 24.2287 207.044 24.7786C206.29 25.3284 205.201 25.6034 203.777 25.6034H199.353V19.1025H203.547C204.563 19.1025 205.417 19.2222 206.102 19.4661C206.787 19.71 207.301 20.0692 207.65 20.5481C207.999 21.027 208.169 21.6212 208.169 22.3396V22.3662V22.3707Z"
      fill="white"
    />
  </svg>
);
