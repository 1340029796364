import { styled } from "@mui/material";

const ScrollDotsWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-start",
  top: "50%",
  position: "fixed",
  marginLeft: "3%",
  zIndex: "100",
  right: 30,
}));

const StyledScrollDots = styled("div")(() => ({
  zIndex: "300",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  animation: "slideIn 1s ease-in",
}));

export const ScrollDot = styled("div")(
  (props: { bullet: number; item: number }) => ({
    cursor: "pointer",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    border: "1px solid #bebdc3",
    marginBottom: "12px",
    backgroundColor: props.bullet === props.item ? "#bebdc3" : "white",
  }),
);

interface Props {
  bullet: number;
}

export const ScrollDots = (props: Props) => {
  const goToBullet = (percent: number) => {
    const body = document.body,
      html = document.documentElement;
    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    window.scrollTo(0, height * percent);
  };
  const { bullet } = props;
  return (
    <ScrollDotsWrapper>
      <StyledScrollDots>
        {SCROLL_INDICATOR.map((item, index) => {
          return (
            <ScrollDot
              bullet={bullet}
              item={item.id}
              key={index}
              onClick={() => goToBullet(item.percent)}
            />
          );
        })}
      </StyledScrollDots>
    </ScrollDotsWrapper>
  );
};

const SCROLL_INDICATOR = [
  { id: 1, percent: 0 },
  { id: 2, percent: 0.06 },
  { id: 3, percent: 0.27 },
  { id: 4, percent: 0.34 },
  { id: 5, percent: 0.45 },
  { id: 6, percent: 0.6 },
  { id: 7, percent: 0.75 },
  { id: 8, percent: 0.85 },
];
