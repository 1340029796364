import React from "react";
import type { AppProps } from "next/app";
import { CssBaseline } from "@mui/material";
import { Layout } from "components/Layout";
import { Provider } from "react-redux";
import { wrapper } from "store";
import { RootProvider } from "../context";
import SuperTokens from "supertokens-web-js";
import { getConfig } from "config";
import { appWithTranslation } from "next-i18next";
// import Script from "next/script";
import "styles/root.css";
import "styles/notion.css";
import "katex/dist/katex.min.css";
import "prismjs/themes/prism-tomorrow.css";

if (typeof window !== "undefined") {
  SuperTokens.init(getConfig());
}
function MyApp({ Component, ...rest }: AppProps) {
  const {
    store,
    props: { pageProps },
  } = wrapper.useWrappedStore(rest);
  return (
    <Provider store={store}>
      <RootProvider>
        <CssBaseline />
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </RootProvider>
    </Provider>
  );
}

export default appWithTranslation(MyApp);
