import { colors, theme } from "@builderhub/mui-theme";
import { Box, Typography, styled } from "@mui/material";
import { InquiryType } from "generated/types";

export const ItemBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: 240,
  gap: 8,
}));

export const Wrap = styled(Box)(
  (props: { type: InquiryType; value: InquiryType | null }) => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: 16,
    gap: 8,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: -0.3,
    color: props.type === props.value ? colors.purple._60 : colors.gray._40,
    borderRadius: 8,
    border:
      props.type === props.value
        ? `1px solid ${colors.purple._10}`
        : `1px solid ${colors.gray._20}`,
    background: colors.white,
    boxShadow:
      props.type === props.value
        ? "0px 0px 25px 10px rgba(102, 73, 226, 0.25)"
        : "none",
    ":hover": { cursor: "pointer" },
    ".point": {
      fill: props.type === props.value ? colors.purple._60 : colors.gray._20,
    },
  }),
);

export const Sub = styled(Typography)(
  (props: { type: InquiryType; value: InquiryType | null }) => ({
    color: props.type === props.value ? colors.purple._60 : "#93919C",
    fontSize: 12,
    letterSpacing: -0.3,
    paddingLeft: 8,
    br: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      br: {
        display: "block",
      },
    },
  }),
);
