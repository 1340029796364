import { colors } from "@builderhub/mui-theme";
import { Box, Button, Typography, styled } from "@mui/material";

export const DrawerWrapper = styled(Box)(() => ({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
}));
export const DrawerHeader = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  height: 56,
  alignItems: "center",
  padding: 16,
  borderBottom: `1px solid #F2F2F2`,
  p: {
    height: 20,
    fontSize: 14,
    color: colors.gray._60,
    marginLeft: 8,
    fontWeight: 500,
    letterSpacing: -0.3,
    lineHeight: "22px",
  },
}));
export const RegBox = styled(Box)(() => ({
  height: 138,
  backgroundColor: "#F9F9FD",
  padding: "20px 16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  marginBottom: 16,
  span: {
    marginLeft: 4,
  },
}));
export const RegTypo = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 500,
  letterSpacing: -0.3,
  color: colors.gray._60,
}));
export const RegSubTypo = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: -0.3,
  color: colors.gray._60,
  marginTop: 4,
}));
export const RegBtn = styled(Button)(() => ({
  background: colors.purple._60,
  color: colors.white,
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: -0.3,
  marginTop: 24,
  boxShadow: "none",
  width: 116,
  height: 32,
  padding: "8px 22.5px",
  ":hover": {
    background: colors.purple._80,
    boxShadow: "none",
  },
}));

export const DrawerList = styled(Box)(() => ({
  padding: "14px 16px",
  textAlign: "left",
  span: {
    fontSize: 16,
    color: colors.gray._100,
    letterSpacing: -0.3,
    cursor: "pointer",
  },
}));
export const DrawerFooter = styled(Box)(() => ({
  display: "flex",
  height: 56,
  width: "100%",
  padding: 16,
  borderTop: `1px solid #F2F2F2`,
  position: "fixed",
  alignItems: "center",
  bottom: 0,
  cursor: "pointer",
  p: {
    height: 20,
    fontSize: 16,
    color: colors.gray._60,
    marginLeft: 4,
    fontWeight: 400,
    letterSpacing: -0.3,
  },
}));
