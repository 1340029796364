import * as React from "react";
import { CertificationDialog } from "components";
import { useGetInquiryListLazyQuery } from "generated/types";
import { ListType, defaultList, getInquiry } from "./types";
import { useRouter } from "next/router";
import { getIdentityPopup, resetIdentityResult, useAppDispatch } from "store";
import { useSnackbar } from "notistack";
import { useUpdateMe } from "hooks";
import { Presenter } from "./Presenter";

export const Inquiry = (props: { type: string; name: string }) => {
  const { type, name } = props;
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = React.useState<ListType[]>(defaultList.list);
  const [state, setState] = React.useState({
    dialog: false,
    page: 0,
  });
  const router = useRouter();
  const [getList, { data }] = useGetInquiryListLazyQuery({
    fetchPolicy: "network-only",
    onCompleted(data) {
      setList(getInquiry(data, state.page).list);
    },
  });
  const goDetail = (uuid: string) => {
    router.push(`${type}/${uuid}`);
  };
  const goRegist = () => {
    if (name.length === 0) {
      handleDialogOpen();
    } else {
      router.push("inquiry/regist");
    }
  };
  const handleDialogOpen = () => setState({ ...state, dialog: true });
  const handleDialogClose = () => setState({ ...state, dialog: false });
  const getSnackbar = React.useCallback(
    (errorString: string, variant: "success" | "error" | "warning") => {
      enqueueSnackbar(`본인인증을 ${errorString}`, {
        variant: variant,
        autoHideDuration: 2500,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    },
    [enqueueSnackbar],
  );
  const goCertification = () => {
    dispatch(resetIdentityResult());
    dispatch(getIdentityPopup("/identity")).then((data) => {
      if (data.type === "auth/identity/popup/rejected") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((data as any).error.message) {
          getSnackbar("실패하였습니다.", "error");
        } else {
          getSnackbar("취소하였습니다.", "error");
        }
      } else if (data.type === "auth/identity/popup/fulfilled") {
        getSnackbar("성공하였습니다.", "success");
      }
    });
  };
  const handleSetPage = (_event: React.ChangeEvent<unknown>, value: number) => {
    setState({ ...state, page: value - 1 });
  };
  useUpdateMe(handleDialogClose);
  React.useEffect(() => {
    getList({ variables: { take: 20, skip: state.page * 20 } });
  }, [getList, state.page]);
  return (
    <div>
      <CertificationDialog
        showPrompt={state.dialog}
        handleClose={handleDialogClose}
        cancel={handleDialogClose}
        certificate={goCertification}
      />
      <Presenter
        goDetail={goDetail}
        goRegist={goRegist}
        list={list}
        totalCount={getInquiry(data, state.page).totalCount}
        page={state.page}
        handleSetPage={handleSetPage}
      />
    </div>
  );
};
