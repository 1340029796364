/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { theme } from "@builderhub/mui-theme";
import { styled, Box } from "@mui/material";

export const Wrapper = styled(Box)(() => ({
  width: "100%",
  marginTop: 96,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: 24,
  marginBottom: 64,
  [theme.breakpoints.down("lg")]: {
    gap: 16,
    marginBottom: 40,
  },
  [theme.breakpoints.down("sm")]: {
    gap: 8,
    marginBottom: 64,
  },
}));
export const Wrap = styled(Box)(() => ({
  width: "100%",
  maxWidth: 1200,
  display: "flex",
  justifyContent: "center",
  gap: 80,
  padding: "0 16px",
  [theme.breakpoints.down("lg")]: {
    gap: 0,
    justifyContent: "space-between",
    padding: "0 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 20px",
  },
}));

export const LogoDiv = styled(Box)(() => ({
  maxWidth: 140,
  width: "100%",
  padding: "0 12px",
  [theme.breakpoints.down("lg")]: {
    img: {
      width: 88,
      height: 44,
    },
  },
  [theme.breakpoints.down("sm")]: {
    img: {
      width: 56,
      height: 28,
    },
  },
}));
