import * as React from "react";

export const ReplyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      d="M6 5V13H15"
      stroke="#664AE3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4738 13.1206C17.8262 13.4227 17.6126 14.0002 17.1484 14.0002L12 14.0002C11.7239 14.0002 11.5 13.7764 11.5 13.5002L11.5 9.08735C11.5 8.66018 12.0011 8.42972 12.3254 8.70773L17.4738 13.1206Z"
      fill="#664AE3"
    />
  </svg>
);
