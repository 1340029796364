import { GetMyInfoQuery, CustomerRole } from "generated/types";

export function getMyInfoData(data: GetMyInfoQuery | undefined) {
  if (!data) return defaultGetMyInfoData;
  const { me } = data;
  if (!me)
    return {
      ...defaultGetMyInfoData,
      error: true,
      message: "로그인 되지 않았습니다.",
    };
  const { customer, identity, email, userId, userHash, partnerId } = me;
  if (!customer)
    return {
      ...defaultGetMyInfoData,
      error: true,
      message: "고객 사용자가 아닙니다.",
    };
  const { name: organization, role, fieldName, id: customerId } = customer;
  return {
    error: false,
    message: "",
    name: identity?.name || "",
    email,
    phone: identity?.phoneNumber || "",
    organization: organization || "",
    field: role,
    fieldName: fieldName || "",
    customerId,
    identity: identity?.uniqueKey || "",
    userId: userId || "",
    userHash: userHash || "",
    partnerId,
  };
}

const defaultGetMyInfoData = {
  error: false,
  message: "",
  name: "",
  email: "",
  phone: "",
  organization: "",
  field: CustomerRole.Design,
  fieldName: "",
  customerId: null,
  identity: "",
  userId: "",
  userHash: "",
  partnerId: null,
};

export type GetMyInfoData = ReturnType<typeof getMyInfoData>;
